<template>
    <v-main>
     <div style="margin-left:-4vw;">    
     
       <div  class="margin_align" id="topDiv" >
 
         <div >
         
         <div :class="expanded ? 'leftPanelExpand' : 'left'">
           <loading :active.sync="loading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
           <div v-if="comingSoon" style="font-family: Ubuntu;margin-bottom: -50px;margin-right: -5px;margin-top:30px">
            <v-row>
              <v-col cols="12" md="12" lg="12" sm="12" class="Rectangle-721">
                  <p class="No_article" style="text-align:center;padding-top:30vh">
                   Coming Soon...
                  </p>
              </v-col>
            </v-row>
          </div>
           <div  style="font-family: Ubuntu;margin-bottom: -50px;margin-right: -5px;margin-top:30px">
             <!-- <v-row style="padding-bottom:15px;margin-top:6vh">
              <v-col
              cols="12"
              md="1"
              lg="1"
              sm="1"
              style="text-align:right;cursor: pointer;"
              @click="goBack()"
            >
              <v-icon
                color="#ff6750"
                style="text-align: center"
              >
                mdi-arrow-left
              </v-icon>
            </v-col>
               <v-col cols="12" md="6" lg="6" sm="6" xs="6">
                   <span class="My-Modules"> {{"Sample Module"}}</span>  
               </v-col>
           </v-row> -->
             <div v-if="!comingSoon" id="gist" style="font-family: Ubuntu;margin-bottom: -50px;margin-right: -5px;margin-top:30px">
               <div  v-for="(pan,kp) in panels" :key="kp" style="">
                 <div :id="pan.my_panel_id">
                   <v-row wrap v-if="pan.Panel_Info !== 'Full_Panel' && pan.Panel_Info == '' && (pan.Right.Display.plot_display == true && (pan.Right.Display.Display_Type == 'Plot' || pan.Right.Display.Display_Type == 'Table' || pan.Right.Display.Display_Type == 'Text Box' || pan.Right.Display.Display_Type == 'Card' || pan.Right.Display.Display_Type == 'Financial_Cards'))" :class="kp%2==0 ? 'even_panels': 'odd_panels'" :style="kp==0 ? 'padding-top:30px;margin-top:2vh' : 'padding-top:20px;padding-bottom:10vh'"> 
                     <v-col :cols="!isTabletMob? '4' : '12'" :style="!isTabletMob ? 'margin-top:0vh;padding-left:40px': 'margin-top:0vh'">
                       <v-row>
                         <v-col cols="12" md="12" lg="12" sm="12" xs="12">
                           <div class="news_heading">{{pan.Left.Header}}</div>
                         </v-col>
                       </v-row>
                       <v-row>
                         <v-col v-if="!isTabletMob" cols="12" md="3" lg="3" sm="3" xs="3" align="center" justify="center">
                           <!-- <div class="Path-499">
                             <span >
                               <img src="../assets/images/share.png" alt="share" style="height: 1.8vh; width: 1.8vh;" @click="show_share_modal(pan.Panel_Id)"/>
                             </span>
                           
                             <span >
                               <a @click="showExpand()" v-show="!expanded">
                                 <img src="../assets/Icons/expand.svg" alt="expandPanel"
                                         style="eight: 1.8vh; width: 1.8vh; margin-left: 1.4vw" />
                               </a>
                               <a @click="showShrink(pan)" v-show="expanded">
                                 <img src="../assets/Icons/Path 533.svg" alt="expandPanel"
                                   style="eight: 1.8vh; width: 1.8vh; margin-left: 1.4vw" />
                               </a>
                             </span>
                           </div> -->
                         </v-col>
                         <v-col v-if="!isTabletMob" cols="12" md="8" lg="8" sm="8" xs="8"></v-col>
                       </v-row>
                       <div v-if="pan.Left.First_Text.length > 0 && pan.Left.First_Text[0] !== ''"> 
                         <div style="font-size: 15px;color:#384348" class="pre-formatted" v-for="(pa,a) in pan.Left.First_Text" :key="a">
                           <span v-html="pa"></span>
                         </div>
                         <div v-if="pan.Left.First_Text.length > 0 && pan.Left.First_Text[0] !== ''" class="line"></div>
                       </div>
                       <div v-if="pan.Left.Second_Text.length > 0 && pan.Left.Second_Text[0] !== ''">
                         <div style="font-size: 15px;color:#384348" class="pre-formatted" v-for="(pa,b) in pan.Left.Second_Text" :key="b">
                           <span v-html="pa"></span>
                         </div>
                         <div v-if="pan.Left.Second_Text.length > 0 && pan.Left.Second_Text[0] !== ''" class="line"></div>
                       </div>
                       <div v-if="pan.Left.Third_Text.length > 0 && pan.Left.Third_Text[0] !== ''">
                         <div style="font-size: 15px;color:#384348" class="pre-formatted" v-for="(pa,c) in pan.Left.Third_Text" :key="c">
                           <span v-html="pa"></span>
                         </div>
                         <div v-if="pan.Left.Third_Text.length > 0 && pan.Left.Third_Text[0] !== ''" class="line"></div>
                       </div>
                       <div v-if="pan.Left.Forth_Text.length > 0 && pan.Left.Forth_Text[0] !== ''">
                         <div style="font-size: 15px;color:#384348" class="pre-formatted" v-for="(pa,d) in pan.Left.Forth_Text" :key="d">
                           <span v-html="pa"></span>
                         </div>
                         <div v-if="pan.Left.Forth_Text.length > 0 && pan.Left.Forth_Text[0] !== ''" class="line"></div>
                       </div>
                       <div v-if="pan.Left.Fifth_Text.length > 0 && pan.Left.Fifth_Text[0] !== ''">
                         <div style="font-size: 15px;color:#384348" class="pre-formatted" v-for="(pa,e) in pan.Left.Fifth_Text" :key="e">
                           <span v-html="pa"></span>
                         </div>
                         <div v-if="pan.Left.Fifth_Text.length > 0 && pan.Left.Fifth_Text[0] !== ''" class="line"></div>
                       </div>
                       <div>
                         <p class="linktitle" :style="!isTabletMob ? '': 'margin:0vh;height:auto'">{{pan.Left.Link_Title}}</p>
                         <v-sheet outlined v-if="pa.Link !== '' || pa.Link_Text !== ''" class="links" v-for="(pa,ke) in pan.Left.Links" :key="ke" style="">
                        
                           <v-row wrap class="ma-0 pa-0" style="padding:0vh">
                             <v-col  cols="12" md="1" lg="1" sm="1" xs="1">
                               <div class="Rectangle-718">
                                 <img alt="Link" 
                                   src="../assets/Icons/Path 344.svg"
                                   style="text-align: center;height:15px"
                                   >
                               </div>
                               </v-col>
                             <v-col  cols="12" md="10" lg="10" sm="10" xs="10">
                               <div style="margin-top:2px;margin-left:2vh">
                                 <a class="link_content" @click="takemeto(pa.Link)" style="text-decoration: none">{{pa.Link_Text}}</a>
                               </div>
                             </v-col>
                           </v-row>
                         </v-sheet>
                       </div>
                     </v-col>
                     
                     <v-col :cols="!isTabletMob ? '7' : '12'" :style="!isTabletMob ? 'margin-left:70px' : 'margin-left:0vh'">
                       <div>
                         <v-row>
                           <div v-if="pan.Right.Display.Display_Type == 'Card'">
                             <v-row wrap>
                               <v-col  cols="12" md="4" lg="4" sm="4" xs="4"
                                 v-for="(pa,key1) in pan.Right.Display.Display_Info" :key="key1">
                                 <v-card style="height:280px;width:95%;padding:0vh 2vh 0vh 2vh;margin-right: 4vh; ">
                                   <!-- card title -->
                                   <v-row wrap style="height:80px">
                                     <v-col class="mt-4" cols="12" md="3" lg="3" sm="3" xs="3">
                                       <img :src="pa.Logo" basic style="height: 22px; width: 22px;margin-left:1vh" />
                                     </v-col>
                                     <v-col class="mt-4" cols="12" md="9" lg="9" sm="9" xs="9">
                                       <p class="cardTitles">{{pa.Title}}</p>
                                     </v-col>
                                   </v-row>
                                   <!-- card contents  if Image-->
                                   <div v-if="pa.Value_Type == 'image'">
                                     <div class="centerAlign" style="height: 190px;">
                                       <img :src="pa.myimage.src" alt="ChannelLogo" />
                                     </div>
                                   </div>
                                   <!-- if text -->
                                   <div v-if="pa.Value_Type == 'text'" >
                                     <div style="margin-top:20px">
                                       <v-row>
                                         <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                                         <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4">
                                           <div style="margin-top: 10px">
                                             <hr class="hrLine">
                                             <span class="itemCountDesign centerAlign">{{pa.count}}</span>
                                             <hr class="hrLine">
                                           </div>
 
                                         </v-col>
                                         <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                                       </v-row>
                                     </div>
                                     <div class="titleCenter" style="margin-top:40px">
                                       <span class="title1">{{pa.rem_text}}</span>
                                     </div>
                                   </div>
                                 </v-card>
                               </v-col>
 
                             </v-row>
                           </div>
                           <div v-if="pan.Right.Display.Display_Type == 'Text Box'">
                             <div>
                               <div v-for="(pa,key3) in pan.Right.Display.Display_Info" :key="key3">
                                 <h5 class="texttitle">{{pa.Title}}</h5>
                                   <p v-if="!pa.textLength" class="textbox_content pre-formatted" v-html="pa.newText"></p>
                                   <p v-if="pa.textLength" class="textbox_content pre-formatted" v-html="pa.Text"></p>
                                   <span @click="changetextLength(pa)" class="pointer" v-if="!pa.textLength && !pa.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;">Read More</span>
                                   <span @click="changetextLength(pa)" class="pointer" v-if="pa.textLength && !pa.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;">Show Less</span>                               
                               </div>
                             </div>
                           </div>
                           <div v-if="pan.Right.Display.Display_Type == 'Table'">
                             <v-row v-for="(pa,key4) in pan.Right.Display.Display_Info" :key="key4">
                               <v-row wrap>
                                 <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2" v-for="(pbtn,keyyy) in pa.Dropdownnames" :key="keyyy">                       
                                     <v-btn :style="pbtn.selected ? 'background-color: #3fa6a1; color: #ffffff;font-size: 13px;font-weight:700; margin-top:0vh;font-family: Ubuntu;width:9vw;' : 'background-color: #ffffff; color: #3fa6a1; font-size: 13px;font-weight:700; margin-top:0vh; font-family: Ubuntu;width:9vw;'" @click="onChangetrials(pbtn,pa.myTable)">{{pbtn.name}}</v-btn>
                                 </v-col>
                                 <v-col cols="12" sm="7" md="7" lg="7" xs="7" xl="7"></v-col>
                               </v-row>
                               <table class="pl-0" :id="pa.myTable">
                                 <span v-if="pa.show_dropdown == true">
                                   <tr>
                                     <th :class="(pa.Table_Data.Table_Type == 'Dual Header' && key7 == 0)? 'dualHeaderRow': 'dualHeaderAnotherRow'" v-for="(p,key7) in pa.Table_Data.Columns" :key="key7" style="min-width: 9.5vw">{{p}} <span @click="sort_rows(key7, pa.myTable)" v-show="pa.Table_Data.Sort_Enable == 'True'"><i class="fa fa-arrow-down" style="margin-left: 2vh !important;cursor: pointer;"></i></span>
                                     </th>
                                   </tr>
                                   <tr v-for="(p,key5) in pa.Table_Data.show_rows" :key="key5">
                                     <td v-for="(x,k1) in p" :key="k1" :class="(pa.Table_Data.Table_Type == 'Dual Header' && k1 == 0)? 'dualHeaderData':'dualHeaderAnotherData'">
                                       <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                     </td>
                                   </tr>
                                 </span>
                                 <tr v-if="pa.show_dropdown !== true">
                                   <th
                                     :class="(pa.Table_Data.Table_Type == 'Dual Header' && key77 == 0)? 'dualHeaderRow': 'dualHeaderAnotherRow'"
                                     v-for="(p,key77) in pa.Table_Data.Columns" :key="key77">
                                     <v-row wrap style="padding:0">
                                       <v-col cols="12" sm="8" md="8" lg="8" xs="8" style="padding-top:0vh">
                                         <span style="white-space:normal;margin-right:2px">{{p}}</span>
                                       </v-col>
                                       <v-col cols="12" sm="2" md="2" lg="2" xs="2" @click="sort_rows(key77, pa.myTable)" style="padding-top:0vh" v-show="pa.Table_Data.Sort_Enable == 'True'">
                                           <i class="fa fa-arrow-down"
                                             style="cursor: pointer;padding:0px"></i>
                                       </v-col>
                                     </v-row>
                                   </th>
                                 </tr>
                                 <tr v-for="(p,key55) in pa.Table_Data.Rows" :key="key55" v-if="pa.show_dropdown !== true">
                                   <td v-for="(x,k11) in p" :key="k11"
                                     :class="(pa.Table_Data.Table_Type == 'Dual Header' && k11 == 0)? 'dualHeaderData':'dualHeaderAnotherData'">
                                     <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                   </td>
                                 </tr>
                               </table>
                             </v-row>
                           </div>
                           
                           <div v-if="pan.Right.Display.Display_Type == 'Plot'">
                             <v-row>
                             <v-col :cols="(pan.Right.Display.Display_Info.length == 1 || key6 ==2 ) ? '12' : '6'"  v-for="(pa,key6) in pan.Right.Display.Display_Info" :key="key6" style="">                             
                                 <span v-html="pa.div64"></span>
                             </v-col>
                             </v-row>
                           </div>
                           <div v-if="pan.Right.Display.Display_Type == 'Financial_Cards'">
                             <div v-for="(pa,kfin) in pan.Right.Display.Display_Info" :key="kfin" style="padding:5vh">
                               <v-row class="mt-3">
                                 <v-col cols="12" md="6" lg="6" sm="6" xs="6" v-for="(p,fin) in pa.Fin_Card_Data" :key="fin">
                                   <v-card class="fincard">
                                     <div v-for="(pf,fink) in p" :key="fink">
                                       <p style="margin-top:2vh; margin-bottom:2vh;font-family:Ubuntu;font-size:16px;font-weight: 700">{{pf.Header}}</p>
                                       <v-row>
                                         <v-col cols="12" md="6" lg="6" sm="6" xs="6">
                                           <span class="finance_text">{{pf.name}}</span>
                                         </v-col>
                                         <v-col cols="12" md="6" lg="6" sm="6" xs="6">
                                           <span class="finance_text">{{pf.val}}</span>
                                         </v-col>
                                         <hr>
                                       </v-row>
                                     </div>
                                   </v-card>
                                 </v-col>
                               </v-row>
                               <v-row v-if="pa.Plot == 'Table'">
                                   <table style="">
                                     <tr>
                                       <th class="table_header" v-for="(pfi,ft) in pa.Columns" :key="ft">{{pfi}}</th>
                                     </tr>
                                     <tr v-for="(p,key) in pa.Rows" :key="key">
                                       <td v-for="(x,k) in p" :key="k" class="finaTableWrap">
                                         <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                       </td>
                                     </tr>
                                   </table>
                               </v-row>
                             </div>
                           </div>  
                         </v-row>
                       </div>
                     </v-col>
                   </v-row>
                   <!-- full panel -->
                   <v-row wrap style="" v-if="pan.Panel_Info == 'Full_Panel'" :class="kp%2==0 ? 'even_panels_full': 'odd_panels_full'">
                    <!-- <div>
                      <v-row>
                       
                        <v-col v-if="!isTabletMob" cols="12" md="11" lg="11" sm="11" xs="11"></v-col>
                        <v-col cols="12" md="1" lg="1" sm="1" xs="1" align="center" justify="center">
                          <div :class="!isTabletMob ? 'Path-499' : 'Path-499_111'">
                            <span >
                              <img src="../assets/images/share.png" alt="share" :style="!isTabletMob ? 'height: 1.8vh; width: 1.8vh;' : 'height: 2.1vh; width: 2.1vh;'" @click="show_share_modal(pan.Panel_Id)"/>
                            </span>
                          
                            <span >
                              <a @click="showExpand()" v-show="!expanded">
                                <img src="../assets/Icons/expand.svg" alt="expandPanel"
                                :style="!isTabletMob ? 'height: 1.8vh; width: 1.8vh; margin-left: 1.4vw' : 'height: 2.1vh; width: 2.1vh;margin-left: 3vw'" />
                              </a>
                              <a @click="showShrink(pan.my_panel_id)" v-show="expanded">
                                <img src="../assets/Icons/Path 533.svg" alt="expandPanel"
                                :style="!isTabletMob ? 'height: 1.8vh; width: 1.8vh; margin-left: 1.4vw' : 'height: 2.1vh; width: 2.1vh;margin-left: 3vw'" />
                              </a>
                            </span>
                          </div>
                        </v-col>
                      </v-row>
                    </div> -->
                    <br>
                   
                    <div v-if="pan.Right.Display.Display_Type == 'Plot'">
                      <v-row>
                        <div class="news_heading">{{pan.Left.Header}}</div>
                      </v-row>
                      <div v-for="(pa,keyy) in pan.Right.Display.Display_Info" :key="keyy" style="padding:5vh">
                        <v-row class="mt-3">
                          <v-col>
                            <span v-html="pa.div64"></span>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                    <div v-if="pan.Left.First_Text.length > 0 ">
                      <v-row style="margin-top:-5vh">
                        <div class="news_heading">{{pan.Left.Header}}</div> 
                        <v-col class="mt-4" cols="12" md="2" lg="2" sm="2" xs="2"></v-col>            
                        <v-col class="mt-4" cols="12" md="8" lg="8" sm="8" xs="8">
                            <div v-if="pan.Left.First_Text.length > 0 && pan.Left.First_Text[0] !== ''"> 
                              <div style="font-size: 16px;" v-for="(ppaf,a) in pan.Left.First_Text" :key="a">
                                <span v-html="ppaf"></span>
                              </div>
                              <hr class="line" />
                            </div>
                            <div v-if="pan.Left.Second_Text.length > 0 && pan.Left.Second_Text[0] !== ''">
                              <div style="font-size: 16px;" class="" v-for="(ppas,b) in pan.Left.Second_Text" :key="b">
                                <span v-html="ppas"></span>
                              </div>
                              <hr class="line"/>
                            </div>
                            <div v-if="pan.Left.Third_Text.length > 0 && pan.Left.Third_Text[0] !== ''">
                              <div style="font-size: 16px;" class="" v-for="(ppat,c) in pan.Left.Third_Text" :key="c">
                                <span v-html="ppat"></span>
                              </div>
                              <hr class="line"/>
                            </div>
                            <div v-if="pan.Left.Forth_Text.length > 0 && pan.Left.Forth_Text[0] !== ''">
                              <div style="font-size: 16px;" class="" v-for="(ppafo,d) in pan.Left.Forth_Text" :key="d">
                                <span v-html="ppafo"></span>
                              </div>
                              <hr class="line"/>
                            </div>
                            <div v-if="pan.Left.Fifth_Text.length > 0 && pan.Left.Fifth_Text[0] !== ''">
                              <div style="font-size: 16px;" class="" v-for="(ppafi,e) in pan.Left.Fifth_Text" :key="e">
                                <span v-html="ppafi"></span>
                              </div>
                              <hr class="line"/>
                            </div>
                            <div v-if="pan.Left.Sixth_Text.length > 0 && pan.Left.Sixth_Text[0] !== ''">
                              <div style="font-size: 16px;" class="" v-for="(ppafs,f) in pan.Left.Sixth_Text" :key="f">
                                <span v-html="ppafs"></span>
                              </div>
                              <hr class="line"/>
                            </div>
                            <div v-if="pan.Left.Seventh_Text.length > 0 && pan.Left.Seventh_Text[0] !== ''">
                              <div style="font-size: 16px;" class="" v-for="(ppafss,g) in pan.Left.Seventh_Text" :key="g">
                                <span v-html="ppafss"></span>
                              </div>
                              <hr class="line"/>
                            </div>
                            <div v-if="pan.Left.Eighth_Text.length > 0 && pan.Left.Eighth_Text[0] !== ''">
                              <div style="font-size: 16px;" class="" v-for="(ppafse,h) in pan.Left.Eighth_Text" :key="h">
                                <span v-html="ppafse"></span>
                              </div>
                              <hr class="line"/>
                            </div>
                            <div v-if="pan.Left.Ninth_Text.length > 0 && pan.Left.Ninth_Text[0] !== ''">
                              <div style="font-size: 16px;" class="" v-for="(ppafn,i) in pan.Left.Ninth_Text" :key="i">
                                <span v-html="ppafn"></span>
                              </div>
                              <hr class="line"/>
                            </div>
                            <div v-if="pan.Left.Tenth_Text.length > 0 && pan.Left.Tenth_Text[0] !== ''">
                              <div style="font-size: 16px;" class="" v-for="(ppaft,j) in pan.Left.Tenth_Text" :key="j">
                                <span v-html="ppaft"></span>
                              </div>
                              <hr class="line"/>
                            </div>
                        </v-col>
                        <v-col class="mt-4" cols="12" md="2" lg="2" sm="2" xs="2"></v-col>
                      </v-row>
                    </div>
                    <div v-if="pan.Right.Display.Display_Type == 'Table'">
                      <v-row>
                        <div class="news_heading">{{pan.Left.Header}}</div>
                      </v-row>
                      <v-row v-for="(pa,keytt) in pan.Right.Display.Display_Info" :key="keytt">
                        <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2" v-for="(pbtn,keyyy) in pa.Dropdownnames" :key="keyyy">                       
                          <v-btn :style="pbtn.selected ? 'background-color: #3fa6a1; color: #ffffff;font-size: 14px;margin-top:2vh;font-weight:700;font-family: Ubuntu;' : 'background-color: #ffffff; color: #3fa6a1;font-size: 14px;margin-top:2vh;font-weight:700;font-family: Ubuntu;'" @click="onChangetrials(pbtn,pa.myTable)">{{pbtn.name}}</v-btn>                     
                        </v-col>                     
                        <table class="pl-0" :id="pa.myTable">
                                <span v-if="pa.show_dropdown == true">
                                  <tr>
                                    <th :class="(pa.Table_Data.Table_Type == 'Dual Header' && key7 == 0)? 'dualHeaderRow': 'dualHeaderAnotherRow'" v-for="(p,key7) in pa.Table_Data.Columns" :key="key7">{{p}} <span @click="sort_rows(key7, pa.myTable)" v-show="pa.Table_Data.Sort_Enable == 'True'"><i class="fa fa-arrow-down" style="margin-left: 2vh !important;cursor: pointer;"></i></span></th>
                                  </tr>
                                  <tr v-for="(p,key5) in pa.Table_Data.show_rows" :key="key5">
                                    <td v-for="(x,k1) in p" :key="k1" :class="(pa.Table_Data.Table_Type == 'Dual Header' && k1 == 0)? 'dualHeaderData':'dualHeaderAnotherData'">
                                      <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                    </td>
                                  </tr>
                                </span>
                                
                          <tr v-if="pa.show_dropdown !== true">
                            <th :class="(pa.Table_Data.Table_Type == 'Dual Header' && key77 == 0)? 'dualHeaderRow': 'dualHeaderAnotherRow'"
                              v-for="(p,key77) in pa.Table_Data.Columns" :key="key77">{{p}}<span
                              @click="sort_rows(key77, pa.myTable)" v-show="pa.Table_Data.Sort_Enable == 'True'"><i class="fa fa-arrow-down" style="margin-left: 2vh !important;cursor: pointer;"></i></span>
                            </th>
                            </tr>
                            <tr v-for="(p,key55) in pa.Table_Data.Rows" :key="key55" v-if="pa.show_dropdown !== true">
                              <td v-for="(x,k11) in p" :key="k11" :class="(pa.Table_Data.Table_Type == 'Dual Header' && k11 == 0)? 'dualHeaderData':'dualHeaderAnotherData'">
                                <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                              </td>
                          </tr>
                                
                        </table>
                      </v-row>
                    </div>  
                  </v-row>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div class="right notes_section" v-if="!expanded" >
           <v-row wrap style="margin-top:8vh">
             <v-col cols="12" md="10" lg="10" sm="10">
               <div style="font-size: 18px;font-weight: 700;margin-left:4vh;color: #49575d;">Notes</div>
             </v-col>
             <v-col cols="12" md="1" lg="1" sm="1">
               <v-icon color="#009ea5" style="cursor:pointer" @click="close_notes_section()">mdi-close</v-icon>
             </v-col>
           </v-row>
           <v-row v-if="all_notes == null" style="margin-top: 20vh">
             <v-col cols="12" md="2" lg="2" sm="2"></v-col>
             <v-col cols="12" md="8" lg="8" sm="8">
               <div>
                 <img src="../assets/Icons/Group 598.svg" style="display: block;margin-left: auto;margin-right: auto;">
               </div>
               <div>
                 <p
                   style="text-align: center;margin-top:5vh;margin-bottom:28vh;font-size:16px;font-weight:700;color:#8c8e8e">
                   You have not added any notes yet. Please start making notes for this article </p>
               </div>
             </v-col>
             <v-col cols="12" md="2" lg="2" sm="2"></v-col>
           </v-row>
           <v-row v-if="all_notes != null">
             <v-list v-chat-scroll class="comments_list mb-auto">
               <v-list-item>
                 <v-row no-gutters>
                   <v-col cols="12" sm="11" md="11" lg="11">
                     <v-list v-for="(all, key) in all_notes" v-bind:data="all" v-bind:key="key" class="Rectangle-913">
                       <v-row>
                         <v-col cols="12" sm="12" md="12" lg="12" xs="12">
                           <div class="text_notes">{{ all.Note}}</div>
                           <span class="date_notes">{{ all.notes_date }}</span>
                         </v-col>
                       </v-row>
                     </v-list>
                   </v-col>
                 </v-row>
               </v-list-item>
             </v-list>
           </v-row>
           <v-row>
             <div class="noteBox">
               <v-text-field 
                 style="width: 90% !important;
                 font-weight: 600;
                 font-size: 14px;
                 font-family: Ubuntu;
                 color: #383838;position: absolute;margin-left:4vh !important" v-model="mynote"
                 class="mx-2 mb-3 shrink" placeholder=" Make your notes here...." solo
                 prepend-inner-icon="mdi-note-plus-outline"></v-text-field>
             </div>
           </v-row>
         </div>
     
      
         <template>
           <div class="text-center">
             <v-dialog width="600" v-model="show_notes_modal">
                 <v-card>
                   <v-card-title class="text-h5">
                     <span style="color: #49575d;margin-left: 2vh;;font-size: 18px;">Notes</span>
                     <v-spacer></v-spacer>
                     <v-btn icon @click="show_notes_modal = false">
                       <v-icon color="#009ea5">mdi-close</v-icon>
                     </v-btn>
                   </v-card-title>
                   <v-card-text>
                     <v-row>
                       <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                         <v-textarea
                           name="input-7-1"
                           class="mt-2"
                           v-model="mynote"
                           type="text"
                           placeholder="Enter notes here"
                           outlined
                           dense
                           style="margin-left:2vh;margin-right:2vh;font-weight: 500"
                         ></v-textarea>
                       </v-col>
                     </v-row>
                   </v-card-text>
                   <v-card-actions>
                   
                   <v-row style="margin-top: -5vh;margin-bottom: 2vh;margin-right:2vh">
                     <v-col sm="9" md="9" lg="9" xs="9" xl="9">
                       <p style="margin-left:5vh;margin-right:2vh;text-align: left;font-family:Ubuntu;font-size: 15px">{{notes_msg}}</p>
                     </v-col>
                     <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                       <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh;" @click="savenote(notes_panel)" class="{ disabled: isDisabled }" :disabled="isDisabled">Save</v-btn>
                     </v-col>
                     <!-- <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                       <v-btn style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 2.2vh;font-family: Ubuntu;" @click="show_notes_modal = false">Cancel
                       </v-btn>
                     </v-col> -->
                   </v-row>
                 </v-card-actions>  
                 </v-card>
               </v-dialog>
           </div>
         </template>
 
         <template>
           <div class="text-center">
             <v-dialog width="600" v-model="dialog">
                 <v-card>
                   <v-card-title class="text-h5">
                     <span style="color: #49575d;margin-left: 2vh;;font-size: 18px;">Share</span>
                     <v-spacer></v-spacer>
                     <v-btn icon @click="close_share()">
                       <v-icon color="#009ea5">mdi-close</v-icon>
                     </v-btn>
                   </v-card-title>
                   <v-card-text>
                     <v-row style="margin-top:-3vh">
                       
                       <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                         <v-textarea
                           name="input-7-1"
                           class="mt-2"
                           v-model="quote_text"
                           type="text"
                           placeholder="Enter text and choose your social media platform to share"
                           outlined
                           dense
                           v-on:keyup="validateQuote"
                           style="margin-left:2vh;margin-right:2vh"
                         ></v-textarea>
                         <!-- <p style="font-size: 16px;font-weight:500;text-align:center">{{send_msg}}</p> -->
                       </v-col>
                       
                     </v-row>
                     <v-row wrap style="margin-top:-5vh">
                       <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
                       <v-col cols="12" sm="8" md="8" lg="8" xs="8" xl="8">
                         <v-row wrap>
                           <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2" v-for="network in networks"
                             :key="network.network"
                           >
                           <ShareNetwork
                             :network="network.name"
                             :url= "myurl"
                             title="Knolens" 
                             :description="quote_text"
                             quote=""
                             hashtags=""
                           >
                             <div @click="share_article(network.name)">
                               <v-avatar
                               size="45"
                               class="mx-3 mb-3"
                               :color="network.color"
                               >
                                 <i class="icon" :class="network.icon" color="white"></i>
                               </v-avatar>
                             </div>
                             </ShareNetwork>
                           </v-col>
                         </v-row>
                       </v-col>
                       <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
                     </v-row>
                   </v-card-text>
                 </v-card>
               </v-dialog>
           </div>
         </template>
         <template>
           <div class="text-center">
             <v-dialog
               v-model="msg_modal"
               width="600"
             >
             <v-card class="Path-359" style="padding:3vh 2vh 3vh 2vh">
               <v-card-text>
                 <v-row>
                   <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                     <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;margin-right: 3vh;">
                     <span class="Active_Companies_title">Sign Up !</span>
                   </v-col>
                   <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                   <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                     <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="msg_modal = false">mdi-close</v-icon>
                   </v-col>
                 </v-row>
                 <v-row wrap>
                                         
                   <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                     <!-- <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;"> -->
                     <span class="com_nm1" style="margin-left:0vh">Please sign up to get this functionality.</span>
                   </v-col>
                 </v-row>
                 </v-card-text>
                 <v-card-actions>
                   <v-spacer></v-spacer>
                   <v-row>
                     <v-col sm="6" md="6" lg="6" xs="6" xl="6"></v-col>
                     <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                       <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh;" @click="goto_login()">Sign Up</v-btn>
                     </v-col>
                     <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                       <v-btn style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 2.2vh;font-family: Ubuntu;" @click="msg_modal = false">Cancel
                       </v-btn>
                     </v-col>
                   </v-row>
                 </v-card-actions>  
               </v-card>
             </v-dialog>
           </div>
         </template>
       </div>
       
   </div>
 </div>
 </v-main>
 </template>
     
 
 <script>
 import { EventBus } from "../event-bus.js";
 import axios from "axios";
 import Loading from 'vue-loading-overlay';
 import 'vue-loading-overlay/dist/vue-loading.css';
 import postscribe from "postscribe";
 export default {
   
   data() {
     return {
       auth_token: '',
        no_authentication: false,
        myjson: {},
        panels: [],
        page_type:'',
        compare_panels: [],
        article_content:'',
        page_title:'',
        expanded: true,
        loading: false,
       fullPage: true,
       quote_text:'',
         msg_modal: false,
           dialog: false,
            networks: [
         // {
         //   network: "facebook",
         //   name: "Facebook",
         //   icon: "fab fa-facebook-f",
         //   color: "#1877f2",
         // },
 
         {
           network: "linkedin",
           name: "LinkedIn",
           icon: "fab fa-linkedin",
           color: "#007bb5",
         },
         {
           network: "whatsapp",
           name: "Whatsapp",
           icon: "fab fa-whatsapp",
           color: "#25d366",
         },
         // {
         //   network: "messenger",
         //   name: "Messenger",
         //   icon: "fab fa-facebook-messenger",
         //   color: "#0084ff",
         // },
         {
           network: "skype",
           name: "Skype",
           icon: "fab fa-skype",
           color: "#00aff0",
         },
         // {
         //   network: "telegram",
         //   name: "Telegram",
         //   icon: "fab fa-telegram-plane",
         //   color: "#0088cc",
         // },
         {
           network: "twitter",
           name: "Twitter",
           icon: "fab fa-twitter",
           color: "#1da1f2",
         },
         {
           network: "email",
           name: "Email",
           icon: "fa fa-envelope",
           color: "#1da1f2",
         },
       ],
        myurl: "",
        mynote: "",
         show_notes_modal: false,
          notes_msg: "",
          moduleName:"",
          comingSoon: false,
     }
   },
   components: {
    Loading
   },
    computed: {
      isDisabled() {
       if (this.mynote !== '' && this.mynote !== ' ') {
         return false;
       } else {
         return true;
       }
      },
      isTabletMob : function() {
        const iPad = /iPad/i.test(navigator.userAgent);
        const iPhone = /iPhone/i.test(navigator.userAgent);
        const Android = /Android/i.test(navigator.userAgent);
        const webOS = /webOS/i.test(navigator.userAgent);
        const iPod = /iPod/i.test(navigator.userAgent);
        const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
        const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);

        return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
      }
     
    },
    created() {
    },
    mounted() {
      console.log("geknowme.........",)
         localStorage.setItem("selected_article", "Sample");

      var auth_token = localStorage.getItem("authtoken");
      if (auth_token !== null && auth_token !== "" && auth_token !== undefined && auth_token !== "undefined") {
        this.auth_token = auth_token
      }
      
      let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/plotly.js/2.8.3/plotly.min.js')
        document.head.appendChild(recaptchaScript)
        
      //console.log(article_title, disesase, "page dataaaaa")
      //this.get_basic_values()
   
      
      //  check if this.$route.query.sample is null or undefined
      if(this.$route.query.sample !== null && this.$route.query.sample !== undefined && this.$route.query.sample !== ""){
        
      this.showSampleModule()
      }else{
        this.comingSoon = true
      }
      
      //console.log("geknowme.........",)
     
   },
   methods: {
       
     showSampleModule() {
       // console.log("showGeknowMeModule")
           this.loading=true
         axios
         .get("/userProfile?token=" + this.auth_token)
         .then((res) => {
         if (res.data.Status !== 1) {
             this.user = res.data.Data;
             this.user_approved = res.data.Data.User_approved
             if (this.user_approved == undefined && this.user_approved == null && this.user_approved == '') {
               this.user_approved = false
             }
             //console.log(this.user_approved,"user_approved")
         } else {
           console.log("invalid user")
           this.logout()
         }
       })
       .catch((error) => {
         console.log(error.message);
         if (error.message == "Request failed with status code 500") {
           this.logout()             
         }
       });
       setTimeout(e => {
         axios.post("/getSampleModule?s3key="+this.$route.query.sample)
           .then((res) => {
           //console.log(res.data.Data,"res.data.Data")
             
               fetch(res.data.Data.Url)
                 .then(res => res.json())
                 .then((out) => {
                   this.myjson = out
                   //this.get_versions(this.article_id)
                   this.get_module_content(this.myjson)
                   //this.show_all_notes()
                 })
                 .catch(err => { throw err });
 
            
           })
           .catch((error) => {
             console.log(error.message);
             if (error.message == "Request failed with status code 500") {
               this.logout()
             }
           }); 
       },500)
     },
     get_module_content(myjson) {
       this.loading = false
       var scripts = ''
       //console.log("hello json")
       var len = Object.keys(myjson).length;
       var myval = Object.values(myjson)
       //this.version = myval[0]
       this.page_type = myjson.Type
       this.article_content = myjson.Article
       this.page_title = this.article_content.Header
       this.compare_panels = this.article_content.Panel
       this.panels = this.article_content.Panel
       
       
       //console.log(this.page_title,"this.panels")
       scripts = `<script type="text/javascript">
           setTimeout(function(){`
 
           
 
       //var weblink = "https://www.knolens.io/"
       //var weblink1 = "http://localhost:8080/"
 
       for (var j = 0; j < this.panels.length; j++) {
         //this.panels[j].my_panel_id = this.article_prefix + "_" + j
 
         this.panels[j].my_panel_id = this.panels[j].Panel_Id
         if (this.panels[j].Left.First_Text!=null && this.panels[j].Left.First_Text.length>0){
          for (var q = 0; q < this.panels[j].Left.First_Text.length; q++) {
            if (this.panels[j].Left.First_Text[q].includes("<br>")) {
              var txt1 = this.panels[j].Left.First_Text[q].split("<br>").join("\n"); 
              this.panels[j].Left.First_Text[q] = txt1
            }
          }
        }
        if (this.panels[j].Left.Second_Text!=null && this.panels[j].Left.Second_Text.length>0){
         for (var q = 0; q < this.panels[j].Left.Second_Text.length; q++) {
           if (this.panels[j].Left.Second_Text[q].includes("<br>")) {
             var txt1 = this.panels[j].Left.Second_Text[q].split("<br>").join("\n"); 
             this.panels[j].Left.Second_Text[q] = txt1
           }
         }
        }
        if (this.panels[j].Left.Third_Text!=null && this.panels[j].Left.Third_Text.length>0){
         for (var q = 0; q < this.panels[j].Left.Third_Text.length; q++) {
           if (this.panels[j].Left.Third_Text[q].includes("<br>")) {
             var txt1 = this.panels[j].Left.Third_Text[q].split("<br>").join("\n"); 
             this.panels[j].Left.Third_Text[q] = txt1
           }
         }
        }
         //console.log(this.panels[j].Left.Forth_Text,"Forth_Text")
         if (this.panels[j].Left.Forth_Text!=null && this.panels[j].Left.Forth_Text.length>0){
         for (var q = 0; q < this.panels[j].Left.Forth_Text.length; q++) {
           if (this.panels[j].Left.Forth_Text[q].includes("<br>")) {
             //console.log("found")
             var txt1 = this.panels[j].Left.Forth_Text[q].split("<br>").join("\n"); 
             this.panels[j].Left.Forth_Text[q] = txt1
           }
         }
        }
        if (this.panels[j].Left.Fifth_Text!=null && this.panels[j].Left.Fifth_Text.length>0){
         for (var q = 0; q < this.panels[j].Left.Fifth_Text.length; q++) {
           if (this.panels[j].Left.Fifth_Text[q].includes("<br>")) {
             var txt1 = this.panels[j].Left.Fifth_Text[q].split("<br>").join("\n"); 
             this.panels[j].Left.Fifth_Text[q] = txt1
           }
         }
        }
         for (var i = 0; i < this.panels[j].Right.Display.Display_Info.length; i++) {
           // to separate count and text for infographic panel 0
           if (this.panels[j].Right.Display.Display_Type == 'Card') {
             this.panels[j].Right.Display.plot_display = true
             if (this.panels[j].Right.Display.Display_Info[i].Text != "" && this.panels[j].Right.Display.Display_Info[i].Value_Type == "text") {
                 var count = this.panels[j].Right.Display.Display_Info[i].Text.substring(0, this.panels[j].Right.Display.Display_Info[i].Text.indexOf(' '));
                 this.panels[j].Right.Display.Display_Info[i].count = count
                 this.panels[j].Right.Display.Display_Info[i].count = count              
                 var rem = this.panels[j].Right.Display.Display_Info[i].Text.substring(this.panels[j].Right.Display.Display_Info[i].Text.indexOf(' ') + 1);
                 this.panels[j].Right.Display.Display_Info[i].rem_text = rem
               }
               //console.log(this.panels[j].Right.Display.plot_display,"hiiiii card")
           }
 
           if (this.panels[j].Right.Display.Display_Type == 'Text Box' && this.panels[j].Right.Display.Display_Info[i].Text !== '') {
             if (this.panels[j].Right.Display.Display_Info[i].Text.length > 1050) {
               var newText = this.panels[j].Right.Display.Display_Info[i].Text.substring(0, 1050);
               this.panels[j].Right.Display.Display_Info[i].newText = newText
               this.panels[j].Right.Display.Display_Info[i].textLength = false
               this.panels[j].Right.Display.Display_Info[i].no_show = false  
             } else {
               var newText = this.panels[j].Right.Display.Display_Info[i].Text
               this.panels[j].Right.Display.Display_Info[i].newText = newText
               this.panels[j].Right.Display.Display_Info[i].textLength = false
               this.panels[j].Right.Display.Display_Info[i].no_show = true
             }
             this.panels[j].Right.Display.plot_display = true
           }
 
           
           // to get base64 image
           if (this.panels[j].Right.Display.Display_Info[i].Value_Type == "image") {
             //console.log("image")
             var image = new Image();
             image.src = 'data:image/png;base64,'+this.panels[j].Right.Display.Display_Info[i].Plot_Data            
             this.panels[j].Right.Display.Display_Info[i].myimage = image
             //console.log(image,"myimage")
           }
 
           if (this.panels[j].Right.Display.Display_Type == "Table") {
             this.panels[j].Right.Display.Display_Info[i].table_type = this.panels[j].Right.Display.Display_Info[i].Table_Data.Table_Type
             if (this.panels[j].Right.Display.Display_Info[i].Additional_Info !== undefined) {
               var myObj = this.panels[j].Right.Display.Display_Info[i].Additional_Info
               if (Object.keys(myObj).length > 0) {
                 this.panels[j].Right.Display.Display_Info[i].show_dropdown = true
                 var drpdons = this.panels[j].Right.Display.Display_Info[i].Additional_Info.Dropdowns
                 this.panels[j].Right.Display.Display_Info[i].Dropdownnames = []
                 for (var d = 0; d < drpdons.length; d++) {
                   var drp = {}
                   if (d == 0) {
                     drp.selected = true
                   } else {
                     drp.selected = false
                   }
                   drp.name = drpdons[d]
                   this.panels[j].Right.Display.Display_Info[i].Dropdownnames.push(drp)
                 }
 
                 this.panels[j].Right.Display.Display_Info[i].N_Rows = this.panels[j].Right.Display.Display_Info[i].Additional_Info.N_Rows
                 var first_half = this.panels[j].Right.Display.Display_Info[i].N_Rows/2
                 var all_rows = this.panels[j].Right.Display.Display_Info[i].Table_Data.Rows
                 var first_half_rows = []
                 for (var v = 0; v < first_half; v++) {
                    first_half_rows.push(all_rows[v])
                 }
                 this.panels[j].Right.Display.Display_Info[i].Table_Data.first_half_rows = first_half_rows
                 var second_half_rows = []
                 for (var w = first_half; w < all_rows.length; w++) {
                    second_half_rows.push(all_rows[w])
                 }
                 this.panels[j].Right.Display.Display_Info[i].Table_Data.second_half_rows = second_half_rows
                 this.panels[j].Right.Display.Display_Info[i].Table_Data.show_rows = this.panels[j].Right.Display.Display_Info[i].Table_Data.first_half_rows
 
               } else {
                 this.panels[j].Right.Display.Display_Info[i].show_dropdown = false
               }
             } else {
               this.panels[j].Right.Display.Display_Info[i].show_dropdown = false
             }
             this.panels[j].Right.Display.Display_Info[i].myTable = "mytable" + j
             this.panels[j].Right.Display.Display_Info[i].search_found = false
             var row_data = this.panels[j].Right.Display.Display_Info[i].Table_Data.Rows
             if (row_data.length > 0) {
               this.panels[j].Right.Display.plot_display = true
               for (var k = 0; k < row_data.length; k++) {
                 var mysrch1 = "\n"
                 var matstr = ","
                 for (var l = 0; l < row_data[k].length; l++) {
                   if (row_data[k][l].includes(mysrch1)) {
                     const pieces = row_data[k][l].split(mysrch1);
                     const resultingString = pieces.join("\n");
                     // if (resultingString.includes(matstr)) {
                     //   const pieces1 = resultingString.split(matstr);
                     //   const resultingString1 = pieces.join(", ");
                     //   resultingString = resultingString1
                     // }
                     row_data[k][l] = resultingString
                   }
                 }
               }
             }
             //this.panels[j].Right.Display.Display_Info[i].Table_Data.Rows = row_data
           }
 
 
           // to display financial cards info
 
           if (this.panels[j].Right.Display.Display_Type == 'Financial_Cards' && (this.panels[j].Right.Display.Display_Info[0].Fin_Card_Data !== undefined || this.panels[j].Right.Display.Display_Info[1].Table_Data !== undefined))  {
             //console.log("hi finance")
             var finance_data = this.panels[j].Right.Display.Display_Info[i].Table_Data
             this.panels[j].Right.Display.plot_display = true
             if (finance_data !== undefined){
               //console.log(finance_data.Columns, "columns")
               this.panels[j].Right.Display.Display_Info[i].Columns = finance_data.Columns 
               this.panels[j].Right.Display.Display_Info[i].Rows = finance_data.Rows
              }
           }
 
           // to display plot
 
           if (this.panels[j].Right.Display.Display_Type == 'Plot' && this.panels[j].Right.Display.Display_Info[i].Plot_Data !== "") {
               //console.log("Plooot")
                var double_encode = this.panels[j].Right.Display.Display_Info[i].Plot_Data.split('.')[0]
                var plt_data = window.atob(double_encode);
 
                //console.log(plt_data,"plt_data")
 
                var obj = JSON.parse(plt_data)
 
                //console.log(obj,"obj")
 
                var plot_data = obj.div64.split('.')[0]
                var div = window.atob(plot_data);
 
                div = div.replace(/\\"/g, '"')
                div = div.replace(/\\'/g, "'")
                div = div.replace(/\r?\n|\r/g, "\n")
 
                this.panels[j].Right.Display.Display_Info[i].div64 = div
                this.panels[j].Right.Display.plot_display = true
 
                //console.log(this.panels[j].Right.Display.Display_Info[i].div64,"div")
 
                var legend = obj.legend
 
                //console.log(legend,"legend")
             
                if (legend !== undefined) {
                 var colors = {}   
                 var code = Object.keys(legend);
                 var name = Object.values(legend);
                 var color_name = []
                 var color_code = []
                 for (var z=0; z < name.length; z++) {
                   if (name[z] == "Phase 1") {
                     color_name[0] = name[z]
                     color_code[0] = code[z]
                   } else if (name[z] == "Phase 2") {
                     color_name[1] = name[z]
                     color_code[1] = code[z]
                   } else if (name[z] == "Phase 3") {
                     color_name[2] = name[z]
                     color_code[2] = code[z]
                   } else if (name[z] == "Phase 4") {
                     color_name[3] = name[z]
                     color_code[3] = code[z]
                   } else if (name[z] == "Early Phase 1") {
                     color_name[4] = name[z]
                     color_code[4] = code[z]
                   } else {
                     color_name.push(name[z])
                     color_code.push(code[z])
                   }
                 }
 
                 this.panels[j].Right.Display.Display_Info[i].code = color_code
                 this.panels[j].Right.Display.Display_Info[i].name = color_name
               }
 
                var script_data = obj.script64.split('.')[0]
                var script = window.atob(script_data);
 
                script = script.replace(/\\"/g, '"')
                script = script.replace(/\\'/g, "'")
                script = script.replace(/\r?\n|\r/g, "\n")
 
                this.panels[j].Right.Display.Display_Info[i].script64 = script
 
                // console.log(this.panels[j].Right.Display.Display_Info[i].script64,"script")
                //console.log(this.panels[j].Panel_Id,"panels id")
 
                var str = this.panels[j].Right.Display.Display_Info[i].script64.replace('<script type="text/javascript">', "\n")
                var str1 = str.replace("window.PLOTLYENV=window.PLOTLYENV || {};","\n")
                var str2 = str1.replace("<\/script>", "\n")
                scripts += str2
            }
         }
 
         if (this.panels[j].Panel_Info == 'Full_Panel') {
           this.all_phases = []
           this.all_companies = []
           if (this.panels[j].Right.Display.Display_Type == "Table") {
             for (var q = 0; q < this.panels[j].Right.Display.Display_Info.length; q++) {
               this.all_companies = this.panels[j].Right.Display.Display_Info[q].Table_Data.Columns
               var rw = this.panels[j].Right.Display.Display_Info[q].Table_Data.Rows
               for (var r = 0; r < rw.length; r++) {
                 var phases = {}
                 var key = Object.keys(rw[r]);
                 var value = rw[r][key];
                 phases.names = key
                 phases.details = value
                 this.all_phases.push(phases)
               }
             }
           }
           //console.log(this.all_phases, "this.all_phases")
         }
 
       }
       scripts = scripts + `}, 2000);<\/script>`
       //console.log(this.panels,"panels")
       postscribe('#gist', scripts)
 
       if (this.$route.query.panel !== undefined) {
         //var count = this.$route.query.panel.substring(this.$route.query.panel.indexOf('_') + 1)
 
         if (this.panels.length > 0) {
           //var panelid = this.article_prefix + '_' + count
           var panelid = this.$route.query.panel
           if (this.$route.query.panel !== undefined && this.$route.query.panel !== null && this.$route.query.panel !== '') {
             const myTimeout = setTimeout(function () {
               var element = document.getElementById(panelid);
               if (element !== null) {
                 element.scrollIntoView();
               }
             }, 2000)
           }
         }
         
       }
       var myDiv = document.getElementById('topDiv');
       myDiv.scrollTop = 0;
     },
       showExpand() {
       if (!this.no_authentication) {
         this.expanded = true;
       } else {
         this.msg_modal = true
       }      
     },
      showShrink(panell) {
       // if (!this.no_authentication) {
       //   this.expanded = false;
       // } else {
       //   this.msg_modal = true
       // }
 
       if (!this.no_authentication) {
 
         const my_panel = panell
 
         localStorage.setItem("my_panel", JSON.stringify(my_panel));
 
         var display_info = panell.Right.Display.Display_Info
 
         for (var d = 0; d < display_info.length; d++) {
           if (display_info[d].hasOwnProperty("div64")) {
             delete display_info[d].div64
           } else {
             
           }
 
           if (display_info[d].hasOwnProperty("script64") ){
             delete display_info[d].script64
           } else {
             
           }
         }
 
         panell.Right.Display.Display_Info = display_info     
 
         this.show_notes_modal = true;
         this.mynote = ""
         this.notes_msg = ""
         this.notes_panel = panell
         var selectedpan = localStorage.getItem("my_panel");
         var mypan = JSON.parse(selectedpan)
         for (var k = 0; k < this.panels.length; k++) {
           if (mypan.my_panel_id == this.panels[k].my_panel_id) {
             this.panels[k] = mypan
           }
         } 
         this.$forceUpdate();
         localStorage.removeItem("my_panel");
       } else {
         this.msg_modal = true
       }
     },
     goBack(){
    
        this.$router.push({ name: 'Modules_Library' })
    
    },
      show_share_modal(panelid) {
       if (!this.no_authentication) {
         this.quote_text = ''
         this.send_msg = ''
         this.dialog = true
         this.shared_article_id = this.article_id
         this.panel_shared = panelid
         this.myurl= this.domain_origin +"/#/sharewebarticle/" + this.shared_article_id + "/" + panelid
       } else {
         this.msg_modal = true
       }
     },
     close_share() {
       this.dialog = false
       this.network_items = false
     },
     share_article(social) {
       this.dialog = false
       var medium = "social media " + social
       var duration = 0
       var panel_duration = 0
       axios
         .post("/SaveActivity?id=" + this.shared_article_id + '&token=' + this.auth_token + "&activity_name=" + medium + "&disease=" + this.disesase + "&panel_number=" + this.panel_shared + "&page_name" + this.article_title + "&panel_duration=" + panel_duration + "&duration=" + duration)
         .then((res) => {
           if (res.data.Status != 1) {
           console.log("Activity saved successfully")
           this.network_items = false
         } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
           this.logout()
           //this.$router.push("/").catch(() => {})
         } else {
           console.log("Activity save failed")
         }
       })
       .catch((error) => {
         console.log(error.message);
         if (error.message == "Request failed with status code 500") {
           this.logout()             
         }
       });
     },
       savenote(notespanel) {
       //console.log(this.mynote,this.disesase,this.article_id,notespanel,"this.note")
       var notespanel = JSON.stringify(notespanel)
 
       let senddata = {
         article_id : this.article_id,
         disease : this.disesase,
         note: this.mynote,
         notes_panel: notespanel
       }
 
       let url = `/createnote?token=` + this.auth_token
       let config = { headers: { 'Content-Type': 'application/json' } }
       axios.post(url, JSON.stringify(senddata), config).then((res) => {
           if (res.data.Status != 1) {
             console.log("note saved successfully")
             //this.show_all_notes()
             this.show_notes_modal = false
             this.notes_msg = "Your note have been saved successfully !"
           } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
             this.logout()
             //this.$router.push("/").catch(() => { })
           } else {             
             console.log("could not save notes")
             this.notes_msg = "Could not save your note !"
           }
         })
         .catch((error) => {
           console.log(error.message);
           if (error.message == "Request failed with status code 500") {
             this.logout()             
           }
         });
     },
     show_all_notes() {
       this.notes_count = 0
       this.all_notes = []
       this.mynote = ''
       axios
         .get("/getallnotes?token=" + this.auth_token + "&article_id=" + this.article_id + "&disease=" + this.disesase)
         .then((res) => {
           if (res.data.Status != 1) {
             this.all_notes = res.data.Data.notes
             //console.log(this.all_notes,"this.all_notes")
             if (this.all_notes != null) {
               this.notes_count = this.all_notes.length
               for (var i = 0; i < this.all_notes.length; i++) {
                 const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                 var curr_d = new Date(this.all_notes[i].Created_at),
                   curr_month = '' + (curr_d.getMonth() + 1),
                   curr_day = '' + curr_d.getDate(),
                   curr_year = curr_d.getFullYear();
                 this.all_notes[i].notes_date = [curr_day, monthNames[curr_d.getMonth()], curr_year].join(' ')
               }
             }
           } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
             this.logout()
             //this.$router.push("/").catch(() => { })
           } else {
             console.log("notes not found")
           }
         })
         .catch((error) => {
           console.log(error.message);
           if (error.message == "Request failed with status code 500") {
             this.logout()             
           }
         });
     },
     close_notes_section() {
       this.expanded = true
     },
      changetextLength(obj) {
       if (!obj.textLength) {
         obj.textLength = true
       } else {
         obj.textLength = false
       } 
       this.$forceUpdate();
     },
      validateQuote(e) {
       if (e.keyCode === 13) {
         this.quotetext = this.quote_text
         //this.send_msg = "Your message has been added. Please choose social media platform"
       } else {
         console.log("entering quote");
       }
     },
        onCancel() {
       console.log('User cancelled the loader.')
     },
     takemeto(link) {
      console.log(link,"link")
      if (!this.no_authentication) {
        //var weblink = "https://www.knolens.io/#"
        //var weblink = "http://localhost:8080/#"
        var link1 = ''
        if (link.includes("%20")) {
          var pieces = link.split("%20");
          var result = pieces.join(" ");
          link1 = result
        } else {
          link1 = link
        }
        var rem = link1.substring(link1.indexOf('&panel=') + 7);

        if (link1.includes(this.article_title)) {
          document.getElementById(rem).scrollIntoView();
        } else {
          //var results = link1.replace(weblink, "");
          var results = link1
        //  console.log(results, "results")
          this.$router.push(results).catch(() => { })
          location.reload()
        }
      } else {
        this.msg_modal = true
      }
    },
   }
 }
 </script>
 
 
 <style lang="scss" scoped>
 .margin_align {
   overflow-x: hidden;
   overflow-y: auto;
   height: 100vh !important;
 }
 .left {
   height: 100%;
   width: 100%;
   position: absolute;
   left: 0;
   padding-left: 0%;
   padding-right: 1.5%;
   padding-bottom: 20px;
   padding-top: 0px;
   margin: 0vh 0vh 5vh -5vh !important;
   overflow-y: auto
 }
 
 .leftPanelExpand {
   width: 100%;
   padding-left: 2%;
   padding-right: 1%;
   padding-bottom: 20px;
   padding-top: 20px;
 }
 
 .right {
   width: 40%;
   position: absolute;
   right: 0;
   overflow-y: hidden;
   background: white;
   padding-bottom: 5px;
   box-shadow: -5px 3px 6px 0 rgba(0, 0, 0, 0.16);
 }
 
 .v-card-text {
   font-family: Ubuntu;
   font-size: 16px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: normal;
   float: left;
   color: #49575d;
   padding: 16px 0px 8px;
   background: white;
 }
 
 .col-md-2 {
   flex: 1 0 auto;
   width: 12.66666667%;
 }
 .My-Modules {
   font-family: Ubuntu;
   font-size: 20px;
   
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.43;
   letter-spacing: normal;
   text-align: left;
   color: #2c2c48;
   margin-left:3vh
 }
 
 .aa {
   background: #009ea5 !important;
   font-family: Ubuntu;
   font-size: 18px;
   font-weight: 500 !important;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: normal;
   text-align: left;
   color: #fff !important;
   text-transform: none;
 }
 
 .bb {
   background: #009ea5 !important;
   font-family: Ubuntu;
   font-size: 18px;
   font-weight: 500 !important;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: normal;
   text-align: left;
   color: #fff;
 }
 
 table tbody {
   display: table;
   width: 100%;
 }
 
 table {
   display: block;
   overflow-x: auto;
   white-space: nowrap;
   width: 98%;
   margin: 2vh;
   max-height: 90vh;
   overflow-y: auto;
   table-layout:fixed; 
   border-collapse:collapse;
 }
 
 td {
   padding: 1vh;
   font-size: 16px;
   color: #333;
   border: 1px solid #D7DBDD;
 }
 
 tr {
   border-top: 1px solid #D7DBDD;
   font-size: 16px;
   color: #333;
   white-space: pre;
   background-color: #ffffff;
 }
 
 th {
   text-align: center;
   padding: 1vh;
   background-color: #ebf3f7;
   font-family: Ubuntu;
   border: 1px solid #D7DBDD; 
 }
 
 tr:nth-child(odd) {
   background-color: #f3fbfc;
 }
 
 .line {
   border: 1px solid #797C7D;
   background-color: #797C7D !important;
   margin: 0.5rem 0;
 }
 
 .pre-formatted {
   white-space: pre-wrap;
   /* 👈 this is the important part */
 }
 
 .news_heading {
   margin: 0px 0px 18px 0px;
   font-family: Ubuntu;
   font-size: 20px;
   font-weight: 700;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: normal;
   text-align: left;
   color: #374246;
 }
 
 .title1 {
   font-family: Ubuntu;
   font-size: 14px !important;
   font-weight: 500;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.4;
   letter-spacing: normal;
   text-align: left;
  
   color: #384348;
 }
 
 .titleCenter {
   text-align: center;
   margin:0 0.5vw;
 }
 
 .cardTitles {
   font-family: Ubuntu;
   font-size: 13px;
   font-weight: 700;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.22;
   letter-spacing: normal;
   color: #49575d;
 }
 
 .cardTitles1 {
   font-family: Ubuntu;
   font-size: 15px;
   font-weight: 500;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.22;
   letter-spacing: normal;
   color: #49575d;
 }
 
 .itemCountDesign {
   font-family: Ubuntu;
   font-size: 25px;
   font-weight: 500;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: normal;
   text-align: left;
   color: #374246;
   margin-left: 4px;
 }
 
 .pharmaContent {
   font-family: Ubuntu;
   font-size: 18px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.46;
   letter-spacing: normal;
   text-align: left;
   color: #49575d;
   margin-top: -5vh;
 }
 
 .header_text {
   text-transform: capitalize;
   font-family: Ubuntu;
   font-size: 15px;
   font-weight: 500;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.46;
   letter-spacing: normal;
   text-align: left;
   color: #49575d;
   margin-left: 15vh
 }
 
 .linktitle {
   margin: 5vh 45px 10px 0;
   font-family: Ubuntu;
   font-size: 17px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: normal;
   text-align: left;
   color: #374246;
 }
 
 .hrLine {
   border-bottom: 2px solid #374246;
   margin: 0rem 0;
   max-width: 5vw;
   justify-content: center;
   display: flex;
   color:#374246
 }
 
 .Rectangle-718 {
   width: 30px;
   height: 30px;
   padding: 3px 5px 5px 9px!important;
   background-color: #d5f8fa;
 }
 
 
 .titleCenter {
   margin-top: 3vh;
   text-align: center;
 }
 
 .table_header {
   background-color: #3fa6a1;
   color: #ffffff;
   font-size: 16px;
   font-weight: 700;
   text-transform: capitalize;
   word-wrap:break-word !important;
   white-space: -o-pre-wrap; 
   word-wrap: break-word;
   white-space: pre-wrap; 
   white-space: -moz-pre-wrap; 
   white-space: -pre-wrap; 
   word-wrap:break-word !important;
 }
 
 .circle {
   border-radius: 50%;
   height: 10px;
   width: 10px;
   border: 2px solid;
   float: left;
   margin: 10px 3px 0px 0px;
 }
 
 .text_left {
   font-weight: 600;
   color: #49575d;
   font-size: 16px
 }
 
 .appBarBox {
   height: 90px;
   padding-left: 2%;
   padding-right: 1%;
   margin-bottom: 15px;
   margin-top: -3vh !important;
   margin-left: -3vh;
   background-color: #f8fafb;
 }
 
 .optionMenu {
   color: #009ea5;
   font-family: Ubuntu;
   font-size: 16px;
   font-weight: 600;
 }
 
 .popUpVersion {
   font-family: Ubuntu;
   font-size: 16px;
   font-weight: 600;
   text-align: left;
   color: #404040;
   opacity: 0.86;
   text-align: center !important
 }
 
 .popUpDates {
   opacity: 0.86;
   font-family: Ubuntu;
   font-size: 16px;
   font-weight: 300;
   text-align: left;
   color: #404040;
   float: left;
 }
 
 .breadcrums {
   font-family: Ubuntu;
   font-size: 15px;
   font-weight: 700;
   text-align: left;
   text-decoration: none;
 }
 
 .v-breadcrumbs__item {
   color: #009ea5 !important
 }
 
 .v-breadcrumbs__item v-breadcrumbs__item--disabled {
   color: #333333 !important
 }
 
 Rectangle-913 {
   margin: 2vh;
   padding: 23.1px 30.5px 23.9px 23.5px;
   border-radius: 18px;
   border: solid 1px #ccc !important;
   background-color: #f9f9f9 !important;
 }
 
 .notes_section {
   height: 100vh !important;
   background-color: #f9f9fb;
   z-index: 2;
   margin-top:0vh;
   font-family:Ubuntu
 }
 
 .comments_list {
   padding: 1vh 1vh 5vh 1vh;
   height: 75vh;
   max-height: 75vh;
   overflow-y: auto;
   background: inherit;
 }
 
 .Rectangle-913 {
   margin: 2vh;
   padding: 0vh 1vh 1vh 1vh;
   border-radius: 18px;
   border: solid 1px #ccc;
   background-color: #fff;
 }
 
 .text_notes {
   font-size: 16px;
   font-weight: 500;
   color: #49575d;
   margin-bottom:1vh !important;
 }
 
 .date_notes {
   font-size: 16px;
   font-weight: 500;
   color: #8c8e91 !important
 }
 
 .tablePhases1 {
   color: #333;
   background-color: #f8fafb;
   font-size: 16px;
   border: none !important;
   border-top: 1px solid #f8fafb !important;
 }
 
 .tablePhases2 {
   background-color: #f3fbfc;
 }
 
 .mech2Btn {
   background-color: rgba(255, 103, 80, .1);
   border-color: #ff6750;
   font-size: 16px;
   font-weight: 500;
   color: #ff6750;
   font-family: Ubuntu;
   border-radius: 10px;
   padding: 1vh !important;
   height: 100% !important;
 }
 
 .tablePhases {
   position: sticky;
   z-index: 1;
   left: 0;
   border-top: 1px solid #D7DBDD !important;
   border-bottom: 1px solid #D7DBDD !important;
   border-left: 1px solid #D7DBDD !important;
 }
 
 .tablenonphase {
   background-color: inherit;
   font-size: 16px;
   font-weight: 700
 }
 
 .fix {
   position: absolute;
   margin-left: -100px;
   width: 100px;
 }
 
 .dualHeaderRow {
   background-color: #3fa6a1;
   color: #ffffff;
   font-size: 13px;
   font-weight: 700;
   text-transform: capitalize;
   border-bottom: 1px solid #D7DBDD !important;
   border-left: 1px solid #D7DBDD !important;
   position: sticky;
   z-index: 1;
   left: 0;
 }
 
 .dualHeaderAnotherRow {
   background-color: #3fa6a1;
   color: #ffffff;
   font-size: 13px;
   font-weight: 700;
   text-transform: capitalize;
 }
 
 .dualHeaderData {  
   background-color: #d2edee;
   font-size: 13px;
   font-weight: 700;
   border-top: 1px solid #D7DBDD !important;
   border-bottom: 1px solid #D7DBDD !important;
   border-left: 1px solid #D7DBDD !important;
   position: sticky;
   z-index: 1;
   left: 0;
   word-wrap:break-word !important;
   white-space: -o-pre-wrap; 
   word-wrap: break-word;
   white-space: pre-wrap; 
   white-space: -moz-pre-wrap; 
   white-space: -pre-wrap; 
   word-wrap:break-word !important;
 }
 
 .dualHeaderAnotherData {
  
   background-color: inherit;
   font-size: 13px;
   font-weight: 500;
   
   //width: 100%;
   word-wrap:break-word !important;
   white-space: -o-pre-wrap; 
   word-wrap: break-word;
   white-space: pre-wrap; 
   white-space: -moz-pre-wrap; 
   white-space: -pre-wrap; 
   word-wrap:break-word !important;
 }
 
 .finaTableWrap{
   word-wrap:break-word !important;
   white-space: -o-pre-wrap; 
   word-wrap: break-word;
   white-space: pre-wrap; 
   white-space: -moz-pre-wrap; 
   white-space: -pre-wrap; 
   word-wrap:break-word !important;
 }
 
 .finathWrap{
   word-wrap:break-word !important;
   white-space: -o-pre-wrap; 
   word-wrap: break-word;
   white-space: pre-wrap; 
   white-space: -moz-pre-wrap; 
   white-space: -pre-wrap; 
   word-wrap:break-word !important;
 }
 
 .icon {
   font-size: 30px;
 }
 .finance_text {
   font-family: Ubuntu;
   font-weight: 500;
   font-size: 16px
 }
 .fincard {
   margin: 0.5vh;
   padding:2vh;
   height:70vh;
   overflow-y:scroll;
 }
 
 .Path-359 {
   box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
   border: solid 1px #ff6750 !important;
   background-color: #fff;
   border-radius: 20px !important;
 }
 
 .Active_Companies_title {
   margin: 2vh 2vh 2vh 0vh;
   font-family: Ubuntu;
   font-size: 2.8vh;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.24;
   letter-spacing: normal;
   text-align: left;
   color: #ff6750;
 }
 .Path-85 {
   box-shadow: none !important;
   margin: 2vh;
   padding: 1vh 3vh 2vh 3vh;
   border: solid 0.6px #d3d3d3 !important;
   background-color: #fdfefe;
   height: 40vh;
   border-radius: 7px !important;
 }
 .com_nm {
   margin-top: 3vh;
   font-size: 2.2vh;
   font-weight: 700;
   font-family: Ubuntu;
   text-align: center;
   color: #383838 !important;
   height: 4vh;
 }
 .com_nm1 {
   margin-top: 2vh;
   font-size: 2.3vh;
   font-weight: 500;
   font-family: Ubuntu;
   text-align: center;
   color: #383838 !important;
 }
 .noteBox {
   position:absolute;
   float:bottom;
   bottom:80px;
 }
 .even_panels {
   background-color:#f8fafb;
  //  margin:0px -10px 6px 0px;
   margin:0px -10px 6px -14px;
   padding: 0px 30px 40px 30px;
   box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.16) !important;
 }
 .odd_panels {
   background-color:#fff;
  //  margin:0px -10px 6px 0px;
   margin:0px -10px 6px -14px;
   padding: 0px 30px 40px 30px;
   box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
 }
 
 .Path-499 {
   height: 30px;
   margin: -25px 5.5px 15px 0px;
   padding: 2px 3px 3px 2px;
   box-shadow: 0 2px 3px 0 rgba(88, 88, 88, 0.16) !important;
   border: solid 0.7px #cfd3d5;
   border-radius: 7px;
   background-color: #fff !important;
 }
 .link_content {
   font-family: Ubuntu;
   font-weight: 500;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.34;
   letter-spacing: normal;
   text-align: left;
   color:#32393c !important;
   font-size: 13px;
   text-decoration: none;
 }
 .textbox_content {
   font-family: Ubuntu;
   font-size: 15px !important;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.67;
   letter-spacing: normal;
   text-align: left;
   color: #384348;
 }
 .texttitle {
   font-family: Ubuntu;
   font-size: 20px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: normal;
   text-align: left;
   color: #374246;
   margin-top:2vh
 }
 .No_article{
  font-family: Ubuntu;
  font-size: 34px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #676772;
}
.even_panels_full {
  background-color:#f8fafb;
 //  margin:0px -10px 6px 0px;
 margin:0px -10px 6px -14px;
 //  padding: 0px 6vw 40px 9vw;
 padding: 7vw 9vw 50px 9vw;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.16) !important;
 }
 .odd_panels_full {
  background-color:#fff;
 //  margin:0px -10px 6px 0px;
  margin:0px -10px 6px -14px;
  //  padding: 0px 6vw 40px 9vw;
  padding: 7vw 9vw 50px 9vw;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
 } 
 </style>