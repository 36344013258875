<template>
<!-- <v-main> -->
    <div > 
        <div >
            <v-row>
                 <v-col align="center" style="margin-top:20vh;font-size:28px;color:#676772">
                    <div style="font-size:3vh"> You have no Events.</div>
                </v-col>
            </v-row>
            </div>
        </div>
    <!-- </v-main> -->
</template>
<script>
import App from '../App.vue';

export default {
  components: { App },
    name:'Events',
  data() {
    return {
     
       
    };
  },

  
  computed: {
  },
  created() {
  },
  mounted() {
      console.log("hellllooooo")
  },
  methods: {
      
  }
};
</script>