<template>
  <div class="margin_align">
    <div class="row" v-if="!isTabletMob">
      <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" class="first_sec">
        <div>
          <img
            src="../assets/Icons/Knolense_Dark-014.svg"
            style="
              height: 6vh;
              margin: 5vh 0vh 2vh 17vh !important;
              cursor: pointer;
            "
            @click="goBack()"
          />
        </div>
        <div style="margin-top: 15vh; margin-bottom: 20vh">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
              <span :class="verify ? 'new_login_11' : 'new_login'"
                >Registration </span
              ><span :class="verify ? 'Ellipse-298' : 'Ellipse-297'"></span>
              <div :class="verify ? 'Line-291_0' : 'Line-291'"></div>
            </v-col>
          </v-row>
          <v-row style="margin-top: 5vh">
            <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
              <span :class="verify ? 'new_login' : 'verification'"
                >Verification</span
              ><span :class="verify ? 'Ellipse-297' : 'Ellipse-297_2'"></span>
              <div :class="verify ? 'Line-291_3' : 'Line-291_26'"></div>
            </v-col>
          </v-row>
          <v-row style="margin-top: 5vh">
            <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
              <span class="verification">Payment</span
              ><span class="Ellipse-297_2"></span>>
              <div class="Line-291_22"></div>
            </v-col>
          </v-row>
        </div>
        <div style="bottom: 2vh; position: fixed; left: 7%">
          <v-row>
            <v-col align="center">
              <span class="if-you">If you have an account, Login here</span>
              <br />
              <div>
                <v-btn
                  style="widtyh: fit-content"
                  class="Path-820"
                  @click="go_to_login()"
                  >Login</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="8"
        md="8"
        lg="8"
        xs="8"
        xl="8"
        v-if="!show_terms_modal"
      >
        <v-row>
          <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
            <div style="margin-top: 5vh">
              <span class="welcome">Welcome !</span>
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
        </v-row>

        <div
          v-if="!got_token || !agree_to_terms"
          style="
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 100vh;
            height: 85vh;
            margin: 2vh 0vh;
          "
        >
          <v-row>
            <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3"> </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6" class="pb-0">
              <span class="label Email_input">Email</span>
              <v-text-field
                style="
                  margin-top: 2vh;
                  font-family: Ubuntu;
                  font-size: 1.8vh;
                  color: #aaaaaa;
                "
                v-model="email"
                type="email"
                outlined
                dense
                :rules="emailRules"
                placeholder="Enter Email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
          </v-row>
          <v-row v-if="invalid_email">
            <v-col align="center">
              <span
                style="
                  font-size: 2.2vh;
                  color: #009ea5;
                  font-family: Ubuntu;
                  font-weight: 400;
                "
              >
                {{ message }}
              </span>
            </v-col>
          </v-row>

          <v-row v-if="user_exist == false">
            <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3"> </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6" class="pb-0">
              <span class="label Email_input">Full Name</span>
              <v-text-field
                :disabled="email == ''"
                style="
                  margin-top: 2vh;
                  font-family: Ubuntu;
                  font-size: 1.8vh;
                  color: #aaaaaa;
                "
                v-model="user_name"
                type="text"
                outlined
                dense
                :rules="nameRules"
                placeholder="Enter Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
          </v-row>

          <v-row v-if="user_exist == false">
            <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3"> </v-col>
            <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3" class="pb-0">
              <span class="label Email_input">Company</span>

              <v-menu
                offset-y
                :close-on-content-click="closeOnContentClick"
                style="width: 20vh"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    style="
                      border: solid 1px #b3b3b3;
                      height: 5vh;
                      border-radius: 4px;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: space-between;
                      padding: 1vh;
                    "
                  >
                    <span>
                      {{ company_name }}
                    </span>
                    <v-icon>mdi-menu-down</v-icon>
                    <!-- add dropdown arrow -->
                  </div>
                </template>
                <v-row>
                  <v-text-field
                    type="text"
                    v-model="searchComp"
                    label="Search"
                    style="
                      background-color: white;
                      font-size: 14px !important;
                      font-family: Ubuntu;
                      font-weight: normal;
                      margin-left: 1vh;
                      margin-right: 1vh;
                    "
                    @input="searchCompany"
                    @click="stayopen()"
                  ></v-text-field>
                </v-row>

                <v-list style="max-height: 30vh; overflow-y: scroll">
                  <!-- <v-list-subheader class="v-list-subheader" v-if=" display_diseases_my.length>0" >Purchased</v-list-subheader> -->
                  <v-list-item
                    v-for="(my, key) in companies"
                    :key="key"
                    item-value="my"
                    class="dropdownStyle"
                    @click="onChangeCompany(my)"
                  >
                    <v-list-item-title
                      style="text-transform: capitalize; font-size: 13px"
                      >{{ my }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
              <div v-show="oth_comp_flag" style="margin-top: 2vh">
                <div class="label Email_input">Enter Your Company Name</div>
                <v-text-field
                  class="mt-3"
                  height="40"
                  v-model="other_company"
                  type="text"
                  placeholder="Company Name"
                  outlined
                  dense
                  style="font-family: Ubuntu; font-size: 1.8vh; color: #aaaaaa"
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3">
              <span class="label Email_input">Role</span>
              <!-- on hovering show full name -->

              <v-menu
                offset-y
                :close-on-content-click="closeOnContentClick"
                style="width: 20vh"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    style="
                      border: solid 1px #b3b3b3;
                      height: 5vh;
                      border-radius: 4px;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: space-between;
                      padding: 1vh;
                    "
                  >
                    <span>
                      {{ position }}
                    </span>
                    <v-icon>mdi-menu-down</v-icon>
                    <!-- add dropdown arrow -->
                  </div>
                </template>
                <v-row>
                  <v-text-field
                    type="text"
                    v-model="searchTerm"
                    label="Search"
                    style="
                      background-color: white;
                      font-size: 14px !important;
                      font-family: Ubuntu;
                      font-weight: normal;
                      margin-left: 1vh;
                      margin-right: 1vh;
                    "
                    @input="searchPosition"
                    @click="stayopen()"
                  ></v-text-field>
                </v-row>

                <v-list style="max-height: 30vh; overflow-y: scroll">
                  <!-- <v-list-subheader class="v-list-subheader" v-if=" display_diseases_my.length>0" >Purchased</v-list-subheader> -->
                  <v-list-item
                    v-for="(my, key) in positions"
                    :key="key"
                    item-value="my"
                    class="dropdownStyle"
                    @click="onChangePosition(my)"
                  >
                    <v-list-item-title
                      style="text-transform: capitalize; font-size: 13px"
                      >{{ my }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
              <div v-show="oth_posi_flag">
                <div class="label Email_input" style="margin-top: 2vh">
                  Enter Your Position
                </div>
                <v-text-field
                  class="mt-3"
                  height="40"
                  v-model="other_position"
                  type="text"
                  placeholder="Position"
                  outlined
                  dense
                  style="font-family: Ubuntu; font-size: 1.8vh; color: #aaaaaa"
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
          </v-row>
          <v-row v-if="spinner">
            <v-col cols="12" sm="5" md="5" lg="5" xs="5" xl="5"></v-col>
            <v-col cols="6" sm="2" md="2" lg="2" xs="2" xl="2" class="">
              <div slot="spinner" v-if="spinner" style="margin-left: 2vh">
                <b-spinner
                  style="color: #12888e"
                  label="Text Centered"
                ></b-spinner>
              </div>
            </v-col>
            <v-col cols="12" sm="5" md="5" lg="5" xs="5" xl="5"></v-col>
          </v-row>
          <v-row
            style="margin-top: 2vh"
            v-if="user_exist == false && !show_otp_modal && !show_terms_modal"
          >
            <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
              <v-btn
                style="width: auto !important"
                :class="{ disabled: isDisabled }"
                :disabled="isDisabled"
                @click="send_email()"
                :style="
                  !isDisabled
                    ? 'height:40px;padding: 10px;border: solid 1px #f16753;background-color: #fff;color: #f16753;font-family: Ubuntu;font-size: 15px;font-weight: normal;font-stretch: normal;font-style: normal;line-height: 1.88;letter-spacing: normal;text-align: left !important;box-shadow: none;'
                    : 'border:none;width:auto!important;height:40px;'
                "
              >
                Send Verification Code to Registered Email ID
              </v-btn>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3"> </v-col>
          </v-row>

          <v-row
            style="margin-bottom: 2vh"
            v-if="user_exist == true && !invalid_email"
          >
            <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6" align="center">
              <span class="try_again_msg" style="color: #f16753">
                {{ "Account already exists!" }}
              </span>
              <v-btn
                @click="go_to_login()"
                class="Path-820"
                style="width: 170px; margin-left: 0vh; margin-top: 2vh"
              >
                Log in
              </v-btn>
              <div style="margin-top: 2vh">
                <span
                  @click="registerAgain()"
                  style="cursor: pointer"
                  class="try_again_msg"
                >
                  {{ "Register with another email" }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3"> </v-col>
          </v-row>
          <v-row
            v-if="show_otp_modal"
            style="margin-bottom: 2vh; margin-top: -2vh"
          >
            <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
            <v-col cols="12" sm="9" md="9" lg="9" xs="9" xl="9">
              <div class="path_1145">
                <v-row wrap>
                  <img
                    src="../assets/Icons/Group 12390.svg"
                    style="width: 40px; height: 40px; margin-left: 1vh"
                  />
                  <span class="Confirmation">Confirmation</span>
                </v-row>
                <v-row>
                  <span class="We-have"
                    >We have just emailed you the verification code to
                    {{ email }} . Please check email and enter code below.</span
                  >
                  <div>
                    <span class="We-have">If you don't get it</span
                    ><span class="otpRESEND" @click="resend_otp()">RESEND</span>
                  </div>
                  <div
                    slot="spinner"
                    v-if="resend_spinner"
                    style="margin-left: 2vh; margin-top: 2vh"
                  >
                    <b-spinner
                      style="color: #12888e; width: 3vh; height: 3vh"
                      label="Text Centered"
                    ></b-spinner>
                  </div>
                  <div
                    v-if="incorrect_code && otp != ''"
                    style="
                      margin-left: 1vh;
                      margin-top: 2vh;
                      margin-bottom: 2vh;
                    "
                  >
                    <span class="try_again_msg" style="font-size: 15px">
                      {{ otp_message }}
                    </span>
                  </div>

                  <v-otp-input
                    v-if="!isTabletMob"
                    v-model="otp"
                    style="margin-top: 1vh; margin-left: 1vh"
                    plain
                  ></v-otp-input>
                  <v-text-field
                    v-model="otp"
                    v-else
                    label="Enter otp here"
                  ></v-text-field>
                  <v-btn
                    class="Path-820"
                    style="
                      width: 170px;
                      margin-left: 2.5vh;
                      margin-top: 2vh;
                      margin-bottom: 2vh;
                    "
                    :class="{ disabled: isDisabled }"
                    :disabled="isDisabled"
                    @click="verifyOtp()"
                    >Verify & Proceed</v-btn
                  >
                </v-row>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="8"
        md="8"
        lg="8"
        xs="8"
        xl="8"
        v-if="show_terms_modal"
        style="margin: 1vh 0vh"
      >
        <v-row>
          <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
            <div style="margin-top: 2vh; margin-bottom: 2vh">
              <span class="welcome">Terms and Conditions </span>
            </div>
            <div
              id="terms"
              ref="terms"
              @scroll="scrolling"
              style="
                overflow-y: auto;
                overflow-x: hidden;
                max-height: 100vh;
                height: 70vh;
              "
            >
              <Terms></Terms>
            </div>
            <v-row wrap no-gutters>
              <v-col align="center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <img
                        src="../assets/images/scroll.png"
                        style="cursor: pointer"
                      />
                    </div>
                  </template>
                  <span>Scroll</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row
              wrap
              style="
                border: solid 1px#03a6ad;
                background-color: #f5f7f9;
                border-radius: 5px;
                margin-top: 3vh;
                margin-bottom: 2vh;
                padding: 0px 10px 0px 10px;
              "
            >
              <v-col
                cols="12"
                sm="1"
                md="1"
                lg="1"
                xs="1"
                xl="1"
                style="margin-top: -10px !important; cursor: pointer"
              >
                <v-checkbox
                  v-model="agree_to_terms"
                  color="#3fa6a1"
                  :disabled="!showRow"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="10"
                md="10"
                lg="10"
                xs="10"
                xl="10"
                style="margin-top: 10px !important"
              >
                <v-row wrap>
                  <v-col
                    cols="12"
                    sm="8"
                    md="8"
                    lg="8"
                    xs="8"
                    xl="8"
                    style="margin-top: -1.5vh"
                  >
                    <span
                      style="
                        font-size: 1.8vh;
                        margin-left: 1vh;
                        font-family: Ubuntu;
                      "
                      >I agree to the Terms and Conditions</span
                    >
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    lg="4"
                    xs="4"
                    xl="4"
                    align="right"
                  >
                    <v-btn
                      :disabled="!agree_to_terms"
                      @click="
                        go_to_pricing(agree_to_terms),
                          (show_terms_modal = false)
                      "
                      class="Path-820"
                      style="width: 140px; margin-top: -2vh; font-size: 1.8vh"
                      >Agree & Proceed</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
        </v-row>
      </v-col>
    </div>
    <div class="row" v-if="isTabletMob" style="height: 100vh; overflow: hidden">
      <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
        <div
          style="
            background-color: #013d48;
            margin-top: -3vh;
            position: fixed;
            width: 100%;
            left: 0;
            z-index: 1;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
          "
        >
          <img
            src="../assets/Icons/Knolense_Dark-01.svg"
            style="
              height: 4vh;
              margin: 3vh 0vh 2vh 2vh !important;
              cursor: pointer;
            "
            @click="goToHome()"
          />
          <span class="Path-68717 pull-right" @click="go_to_login()"
            >Login</span
          >
        </div>

        <div style="overflow: hidden; margin-top: 5vh">
          <v-row v-if="show_otp_modal" wrap no-gutters>
            <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
              <div style="text-align: left; margin-top: 2vh; margin-left: 5vh">
                <span @click="show_otp_modal = false" style="color: #03a6ad"
                  >Back</span
                >
              </div>
            </v-col>
          </v-row>
          <v-row wrap no-gutters>
            <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
              <div style="text-align: center; margin-top: 4vh">
                <span class="welcome">Registration</span>
              </div>
            </v-col>
          </v-row>
          <div
            id="register"
            style="
              max-height: 62vh;
              overflow-y: auto;
              overflow-x: hidden;
              margin-bottom: 0vh;
            "
            v-if="!show_terms_modal"
          >
            <div
              v-if="!show_otp_modal"
              style="
                margin-top: 3vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 7vh;
              "
            >
              <div class="label Email_input" style="display: flex">Email</div>
              <div align="center">
                <v-text-field
                  style="
                    margin-top: 0.5vh;
                    max-width: 80vw !important;
                    font-family: Ubuntu;
                    font-size: 1.8vh;
                    color: #aaaaaa;
                  "
                  hide-details
                  v-model="email"
                  type="email"
                  outlined
                  dense
                  :rules="emailRules"
                  placeholder="Enter Email"
                ></v-text-field>
              </div>
              <v-row v-if="invalid_email">
                <v-col align="center">
                  <span
                    style="
                      font-size: 2.2vh;
                      color: #009ea5;
                      font-family: Ubuntu;
                      font-weight: 400;
                    "
                  >
                    {{ message }}
                  </span>
                </v-col>
              </v-row>
              <v-row v-if="user_exist == false" style="margin-top: 2vh">
                <div class="label Email_input" style="display: flex">
                  Full Name
                </div>

                <div align="center">
                  <v-text-field
                    style="
                      margin-top: 0.5vh;

                      max-width: 80vw !important;
                      font-family: Ubuntu;
                      font-size: 1.8vh;
                      color: #aaaaaa;
                    "
                    v-model="user_name"
                    type="text"
                    outlined
                    dense
                    :rules="nameRules"
                    placeholder="Enter Name"
                  ></v-text-field>
                </div>
                <div class="label Email_input" style="display: flex">
                  Company
                </div>
                <div
                  id="company"
                  align="center"
                  style="
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <v-menu
                    offset-y
                    :close-on-content-click="closeOnContentClick"
                    style="width: 10vh"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        style="
                          border: solid 1px #b3b3b3;
                          height: 5vh;
                          border-radius: 4px;
                          display: flex;
                          width: 100% !important;
                          align-items: center;
                          justify-content: space-between;
                          padding: 1vh;
                        "
                      >
                        <span>
                          {{ company_name }}
                        </span>
                        <v-icon>mdi-menu-down</v-icon>
                        <!-- add dropdown arrow -->
                      </div>
                    </template>
                    <v-row>
                      <v-text-field
                        type="text"
                        v-model="searchComp"
                        label="Search"
                        style="
                          background-color: white;
                          font-size: 14px !important;
                          font-family: Ubuntu;
                          font-weight: normal;
                          margin-left: 1vh;
                          margin-right: 1vh;
                        "
                        @input="searchCompany"
                        @click="stayopen()"
                      ></v-text-field>
                    </v-row>

                    <v-list style="max-height: 30vh; overflow-y: scroll">
                      <!-- <v-list-subheader class="v-list-subheader" v-if=" display_diseases_my.length>0" >Purchased</v-list-subheader> -->
                      <v-list-item
                        v-for="(my, key) in companies"
                        :key="key"
                        item-value="my"
                        class="dropdownStyle"
                        @click="onChangeCompany(my)"
                      >
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 13px"
                          >{{ my }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <div
                    v-show="oth_comp_flag"
                    style="margin-top: 2vh; width: 100%"
                  >
                    <div class="label Email_input" style="display: flex">
                      Enter Your Company Name
                    </div>
                    <div align="center">
                      <v-text-field
                        style="
                          margin-top: 0.5vh;

                          max-width: 80vw !important;
                          font-family: Ubuntu;
                          font-size: 1.8vh;
                          color: #aaaaaa;
                        "
                        v-model="other_company"
                        type="text"
                        outlined
                        dense
                        :rules="nameRules"
                        placeholder="Enter Name"
                      ></v-text-field>
                    </div>
                  </div>
                </div>

                <div class="label Email_input" style="display: flex">Role</div>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <v-menu
                    offset-y
                    :close-on-content-click="closeOnContentClick"
                    style="width: 20vh"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        style="
                          border: solid 1px #b3b3b3;
                          height: 5vh;
                          border-radius: 4px;
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          justify-content: space-between;
                          padding: 1vh;
                          width: 100% !important;
                        "
                      >
                        <span>
                          {{ position }}
                        </span>
                        <v-icon>mdi-menu-down</v-icon>
                        <!-- add dropdown arrow -->
                      </div>
                    </template>
                    <v-row>
                      <v-text-field
                        type="text"
                        v-model="searchTerm"
                        label="Search"
                        style="
                          background-color: white;
                          font-size: 14px !important;
                          font-family: Ubuntu;
                          font-weight: normal;
                          margin-left: 1vh;
                          margin-right: 1vh;
                        "
                        @input="searchPosition"
                        @click="stayopen()"
                      ></v-text-field>
                    </v-row>

                    <v-list style="max-height: 30vh; overflow-y: scroll">
                      <!-- <v-list-subheader class="v-list-subheader" v-if=" display_diseases_my.length>0" >Purchased</v-list-subheader> -->
                      <v-list-item
                        v-for="(my, key) in positions"
                        :key="key"
                        item-value="my"
                        class="dropdownStyle"
                        @click="onChangePosition(my)"
                      >
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 13px"
                          >{{ my }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <div
                    v-show="oth_posi_flag"
                    style="margin-top: 2vh; width: 100% !important"
                  >
                    <div class="label Email_input" style="display: flex">
                      Enter Your Position
                    </div>
                    <div align="center">
                      <v-text-field
                        style="
                          margin-top: 0.5vh;

                          max-width: 80vw !important;
                          font-family: Ubuntu;
                          font-size: 1.8vh;
                          color: #aaaaaa;
                        "
                        v-model="other_position"
                        type="text"
                        outlined
                        dense
                        :rules="nameRules"
                        placeholder="Enter Name"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </v-row>

              <v-row
                style="margin-bottom: 2vh"
                v-if="user_exist == true && !invalid_email"
              >
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xs="12"
                  xl="12"
                  align="center"
                >
                  <span class="try_again_msg" style="color: #f16753">
                    {{ "Account already exists!" }}
                  </span>
                  <v-btn
                    @click="go_to_login()"
                    class="Path-820"
                    style="width: 170px; margin-left: 0vh; margin-top: 2vh"
                  >
                    Log in
                  </v-btn>
                  <div style="margin-top: 2vh">
                    <span
                      @click="registerAgain()"
                      style="cursor: pointer"
                      class="try_again_msg"
                    >
                      Register with another email
                    </span>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-row v-if="spinner">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xs="12"
              xl="12"
              align="center"
            >
              <div slot="spinner" v-if="spinner" style="margin-left: 2vh">
                <b-spinner
                  style="color: #12888e"
                  label="Text Centered"
                ></b-spinner>
              </div>
            </v-col>
          </v-row>
          <v-row
            style="margin-top: 1vh"
            v-if="user_exist == false && !show_otp_modal && !show_terms_modal"
          >
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xs="12"
              xl="12"
              align="center"
            >
              <v-btn
                style="width: 80vw !important"
                :class="{ disabled: isDisabled }"
                :disabled="isDisabled"
                @click="send_email()"
                :style="
                  !isDisabled
                    ? 'height:45px;padding: 10px;border: solid 1px #f16753;background-color: #fff;color: #f16753;font-family: Ubuntu;font-size: 1.5vh;font-weight: normal;font-stretch: normal;font-style: normal;line-height: 1.88;letter-spacing: normal;text-align: left !important;box-shadow: none;'
                    : 'border:none;height:40px;margin-left:0vh;margin-right:0vh;font-size:1.5vh'
                "
              >
                Send Verification Code to Registered Email ID
              </v-btn>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3"> </v-col>
          </v-row>
          <v-row v-if="show_otp_modal" style="margin-bottom: 5vh">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xs="12"
              xl="12"
              align="center"
            >
              <div class="path_1145_1">
                <v-row wrap>
                  <img
                    src="../assets/Icons/Group 12390.svg"
                    style="width: 40px; height: 40px; margin-left: 1vh"
                  />
                  <span class="Confirmation">Confirmation</span>
                </v-row>
                <v-row>
                  <span class="We-have"
                    >We have just emailed you the verification code to
                    {{ email }} . Please check email and enter code below.</span
                  >
                  <div align="left">
                    <span class="We-have">If you don't get it</span
                    ><span class="otpRESEND" @click="resend_otp()">RESEND</span>
                  </div>
                  <div
                    slot="spinner"
                    v-if="resend_spinner"
                    style="margin-left: 2vh; margin-top: 2vh"
                  >
                    <b-spinner
                      style="color: #12888e; width: 3vh; height: 3vh"
                      label="Text Centered"
                    ></b-spinner>
                  </div>
                  <div
                    v-if="incorrect_code && otp != ''"
                    style="
                      margin-left: 1vh;
                      margin-top: 2vh;
                      margin-bottom: 2vh;
                    "
                  >
                    <span class="try_again_msg" style="font-size: 15px">
                      {{ otp_message }}
                    </span>
                  </div>

                  <v-otp-input
                    v-model="otp"
                    style="margin-top: 1vh; margin-left: 1vh"
                    plain
                  ></v-otp-input>
                  <div align="center">
                    <v-btn
                      class="Path-820"
                      style="
                        width: 170px;
                        margin-left: 2.5vh;
                        margin-top: 2vh;
                        margin-bottom: 2vh;
                      "
                      @click="verifyOtp()"
                      >Verify & Proceed</v-btn
                    >
                  </div>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </div>
        <div
          style="max-height: 100vh; overflow-y: auto"
          v-if="show_terms_modal"
        >
          <v-row style="margin: 0vh 4vh 4vh 4vh">
            <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
              <div style="margin-top: 3vh; margin-bottom: 2vh">
                <span class="welcome">Terms and Conditions</span>
              </div>
              <div
                style="
                  overflow-y: auto;
                  overflow-x: hidden;
                  max-height: 70vh;
                  height: 35vh;
                "
              >
                <Terms></Terms>
              </div>

              <v-row
                wrap
                style="
                  border: solid 1px#03a6ad;
                  background-color: #f5f7f9;
                  border-radius: 5px;
                  margin-top: 5vh;
                  margin-bottom: 5vh;
                "
              >
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xs="12"
                  xl="12"
                  style="margin-top: -2vh"
                >
                  <v-checkbox
                    v-model="agree_to_terms"
                    color="#3fa6a1"
                    style="height: 1vh"
                  >
                  </v-checkbox>
                  <span
                    style="
                      font-size: 1.8vh;
                      font-family: Ubuntu;
                      margin-left: 6vh;
                    "
                    >I agree to the Terms and Conditions</span
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xs="12"
                  xl="12"
                  align="center"
                >
                  <v-btn
                    :disabled="!agree_to_terms"
                    @click="
                      go_to_pricing(agree_to_terms), (show_terms_modal = false)
                    "
                    class="Path-820"
                    style="
                      height: 5vh;
                      width: 60%;
                      margin-top: -1vh;
                      font-size: 2vh;
                    "
                    >Agree & Proceed</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </div>
  </div>
</template>

<script>
import Terms from "../components/Terms.vue";
import axios from "axios";
import { EventBus } from "../event-bus.js";

export default {
  data() {
    return {
      flag: true,
      auth_token: "",
      error: false,
      userid: "",
      error: "",
      otp: "",
      loading: false,
      searchdisease: "",
      login_loading: false,
      companies: [],
      positions: [],
      company_name: "",
      position: "",
      diseases: [],
      other_company: "",
      other_position: "",
      other_diseases: [],
      oth_comp_flag: false,
      oth_posi_flag: false,
      oth_dise_flag: false,
      open_diseases_modal: false,
      other_disease: "",
      my_diseases: [],
      str_other_diseases: "",
      message: "",
      my_selected_dis: "",
      searchTerm: "",
      positionsCopy: [],
      searchComp: "",
      companiesCopy: [],
      got_token: false,
      error: false,
      email: "",
      user_name: "",
      error: "",
      otp: "",
      email_valid: false,
      spinner: false,
      resend_spinner: false,
      show_resend: false,
      show_getstarted: false,
      otp_message: "",
      my_plan: 0,
      extra_diseases: false,
      new_dis_len: 0,
      agree_to_terms: false,
      show_terms_modal: false,
      sub_plan: "",
      user_exist: false,
      has_my_team_plan: false,
      show_otp_modal: false,
      invalid_email: false,
      e1: 1,
      incorrect_code: false,
      verify: false,
      showRow: false,
      emailRules: [
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      nameRules: [
        (v) =>
          /^[a-zA-Z ]+$/.test(v) ||
          "Name must contain only alphabetic characters.",
      ],
      closeOnContentClick: false,
    };
  },
  components: {
    Terms,
  },
  watch: {
    //watch show_terms_modal
    show_terms_modal: function (val) {
      if (val) {
        const termsElement = this.$refs.terms;
        if (termsElement) {
          termsElement.addEventListener("scroll", this.handleScroll);
        }
      }
    },
  },

  created() {
    //this.get_release_version()
  },

  mounted() {
    this.my_plan = localStorage.getItem("plan");

    console.log(this.my_plan, "plan");

    //For new basic plan

    //this.my_plan =  "4"

    this.getdiseases();
    this.getpositions();
    this.getcompanies();
    console.log("this.my_plan", this.my_plan);
    var auth_token = localStorage.getItem("authtoken");
    if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
      this.auth_token = auth_token;
      this.got_token = true;
      // this.getdiseases(this.auth_token);
      // this.getpositions(this.auth_token);
      // this.getcompanies(this.auth_token);
    } else {
      this.auth_token = "";
      this.got_token = false;
    }
  },
  // watch:{
  //   //create watch on email
  //   email: function (val) {
  //    //call validateEmailText method and pass email value
  //    var isvalid=this.validateEmailText(val);

  //    if (isvalid) {
  //     this.email_valid = true;
  //   } else {
  //     this.email_valid = false;
  //     this.message = "Invalid email, please try again"
  //   }

  //   },
  // },

  computed: {
    isDisabled() {
      if (
        this.email !== "" &&
        this.position !== "" &&
        this.company_name !== "" &&
        this.user_name !== ""
      ) {
        //check if emailRules matches email
        if (this.emailRules[0](this.email) != true) {
          return true;
        }
        if (this.nameRules[0](this.user_name) != true) {
          return true;
        }

        console.log("this.oth_posi_flag", this.oth_posi_flag);
        console.log(
          (this.oth_posi_flag && this.other_position != "") ||
            this.oth_posi_flag == false,
          "orrrrr",
          (this.oth_comp_flag && this.other_company != "") ||
            this.oth_comp_flag == false
        );
        if (
          ((this.oth_posi_flag && this.other_position != "") ||
            this.oth_posi_flag == false) &&
          ((this.oth_comp_flag && this.other_company != "") ||
            this.oth_comp_flag == false)
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    isDisabledprofile() {
      //for new basic plan
      if (this.my_plan == "4") {
        if (
          this.company_name !== "" &&
          this.position !== ""
          // this.agree_to_terms == true
        ) {
          return false;
        } else {
          return true;
        }
      } else if (this.my_plan == "0") {
        if (
          this.company_name !== "" &&
          this.position !== "" &&
          this.agree_to_terms == true &&
          this.my_diseases.length !== 0
        ) {
          return false;
        } else {
          return true;
        }
      } else if (this.my_plan == "1") {
        if (
          this.company_name !== "" &&
          this.position !== "" &&
          this.agree_to_terms == true &&
          this.my_diseases.length !== 0
        ) {
          return false;
        } else {
          return true;
        }
      } else if (this.my_plan == "2") {
        if (
          this.company_name !== "" &&
          this.position !== "" &&
          this.agree_to_terms == true
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    isDisablednext() {
      if (this.my_plan == "0" && this.my_diseases.length == 1) {
        return false;
      } else if (this.my_plan == "1" && this.my_diseases.length == 3) {
        return false;
      } else if (this.my_plan == "2") {
        return false;
      } else if (this.my_plan == "4") {
        return false;
      }
      return true;
    },
    filteredDisesesList() {
      return this.diseases.filter((item) => {
        return (
          item.name.toLowerCase().indexOf(this.searchdisease.toLowerCase()) > -1
        );
      });
    },
    isTabletMob: function () {
      const iPad = /iPad/i.test(navigator.userAgent);
      const iPhone = /iPhone/i.test(navigator.userAgent);
      const Android = /Android/i.test(navigator.userAgent);
      const webOS = /webOS/i.test(navigator.userAgent);
      const iPod = /iPod/i.test(navigator.userAgent);
      const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
      const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);
      return (
        iPad ||
        iPhone ||
        Android ||
        webOS ||
        iPod ||
        BlackBerry ||
        Windows_Phone
      );
    },
    isScrolled: function () {
      // Get a reference to the #terms element
      const termsElement = this.$refs.terms;

      if (termsElement) {
        console.log("termsElement.scrollHeight", termsElement.scrollHeight);
        // Check if the scroll position is near the bottom (you can adjust the threshold as needed)
        const scrollThreshold = 10; // Adjust this value if needed
        return (
          termsElement.scrollHeight -
            termsElement.scrollTop -
            scrollThreshold <=
          termsElement.clientHeight
        );
      }

      return false;
    },
  },
  methods: {
    stayopen() {
      this.closeOnContentClick = false;
    },
    goBack() {
      this.$router.push("/").catch(() => {});
    },
    scrolling(event) {
      //find terms id and check if it is scrolled to bottom
      const termsElement = this.$refs.terms;
      if (termsElement) {
        console.log(
          "termsElement.scrollHeight",
          termsElement.scrollHeight - termsElement.scrollTop
        );
        const isScrolledToBottom =
          termsElement.scrollHeight - termsElement.scrollTop < 900;
        this.showRow = isScrolledToBottom;
        console.log("isScrolledToBottom", isScrolledToBottom);
      }
    },

    validateInput(v) {
      return (
        /^[a-zA-Z]*$/.test(v) || "Invalid input. Only letters are allowed."
      );
    },

    // async get_release_version() {
    //   await axios
    //     .get("/getReleaseVersion")
    //     .then((res) => {
    //       if (res.data.Status != 1) {
    //         var version_number = res.data.Data
    //         var current_local_version = localStorage.getItem("version_number");

    //         if (current_local_version == null || current_local_version == undefined || current_local_version == '') {
    //           localStorage.setItem("version_number", version_number);
    //         } else {
    //           //console.log("version number not null")
    //         }
    //         if (version_number != current_local_version) {
    //           //console.log("version number not matching")
    //           localStorage.removeItem("old_selected_article");
    //           localStorage.removeItem("selected_disease");
    //           localStorage.removeItem("selected_article");
    //           localStorage.removeItem("diseases");
    //           localStorage.removeItem("user");
    //           localStorage.removeItem("unselected_diseases");
    //           localStorage.removeItem("version_number");
    //           localStorage.setItem("version_number", version_number);
    //           window.location.reload(true);
    //         } else {
    //           //console.log("version number matching")
    //         }
    //       } else {
    //         console.log("Invalid Release Version")
    //       }
    //   })
    //   .catch((error) => {
    //       console.log(error.message);
    //       if (error.message == "Request failed with status code 500") {
    //         this.logout()
    //       }
    //   });
    // },
    searchPosition(e) {
      if (!this.searchTerm) {
        this.positions = this.positionsCopy;
      }

      this.positions = this.positionsCopy.filter((position) => {
        return (
          position.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
        );
      });
    },
    searchCompany(e) {
      if (!this.searchComp) {
        this.companies = this.companiesCopy;
      }

      this.companies = this.companiesCopy.filter((compn) => {
        return compn.toLowerCase().indexOf(this.searchComp.toLowerCase()) > -1;
      });
      //convert to lowercase and make 1st letter capital
    },
    onChangeCompany(company_name) {
      this.company_name = company_name;
      this.closeOnContentClick = true;
      if (company_name == "Other") {
        this.oth_comp_flag = true;
      } else {
        this.oth_comp_flag = false;
      }
    },
    onChangePosition(position) {
      this.position = position;
      this.closeOnContentClick = true;
      if (position == "Other") {
        this.oth_posi_flag = true;
      } else {
        this.oth_posi_flag = false;
      }
    },
    go_to_login() {
      console.log("go to login");
      this.$router.push("/Login");
    },
    getOtp(e) {
      console.log("get otp");
      if (e.keyCode === 13) {
        this.verifyOtp();
      }
    },
    verifyOtp() {
      console.log("verify otp");
      this.send_otp();

      this.$forceUpdate();
    },
    beforeDestroy() {
      // Remove the scroll event listener to avoid memory leaks
      const termsElement = this.$refs.terms;
      if (termsElement) {
        termsElement.removeEventListener("scroll", this.handleScroll);
      }
    },
    handleScroll() {
      console.log("handleScroll");
      // Check if the #terms element is scrolled to the bottom
      const termsElement = this.$refs.terms;
      if (termsElement) {
        // console.log("termsElement.scrollHeight", termsElement.scrollHeight)
        // const isScrolledToBottom = termsElement.scrollHeight - termsElement.scrollTop === termsElement.clientHeight;
        this.showRow = true;
        // console.log("isScrolledToBottom", isScrolledToBottom)
      }
    },
    open_diseases() {
      this.open_diseases_modal = true;
    },
    close_diseases_modal() {
      this.open_diseases_modal = false;
      this.my_diseases = [];
      //make all deselected
      for (var d = 0; d < this.diseases.length; d++) {
        this.diseases[d].status = "Not-selected";
      }
      if (this.my_diseases.length == 0) {
        this.new_dis_len = 0;
      }

      //this.oth_dise_flag = false;
    },
    send_other_diseases(others) {
      this.str_other_diseases = others;
    },
    get_disease(dis) {
      if (this.my_plan == "0") {
        this.my_diseases = [];
        this.my_diseases.push(dis);
        console.log(this.my_diseases, "my disease");
        if (dis.status == "Selected") {
          this.my_diseases = [];
          for (var d = 0; d < this.diseases.length; d++) {
            if (this.diseases[d].name == dis.name) {
              this.diseases[d].status = "Not-selected";
            }
          }
        } else {
          for (var d = 0; d < this.diseases.length; d++) {
            if (this.diseases[d].name == dis.name) {
              this.diseases[d].status = "Selected";
            } else {
              this.diseases[d].status = "Not-selected";
            }
          }
        }
        this.new_dis_len = this.my_diseases.length;
      } else if (this.my_plan == "1") {
        if (dis.status == "Selected") {
          dis.status = "Not-selected";
          for (var j = 0; j < this.my_diseases.length; j++) {
            if (this.my_diseases[j].name == dis.name) {
              var spliced = this.my_diseases.splice(j, 1);
              break;
            }
          }
        } else {
          if (this.my_diseases.length == 3) {
            this.extra_diseases = true;
            return;
          }
          dis.status = "Selected";
          this.my_diseases.push(dis);
        }

        var md = this.my_diseases.sort(function (a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        this.my_diseases = md;

        var wishlist_array = [];
        for (var f = 0; f < this.my_diseases.length; f++) {
          wishlist_array.push(this.my_diseases[f].name);
        }
        this.new_dis_len = this.my_diseases.length;
      }
    },
    go_to_pricing(a) {
      //create get request to update profile
      if (a) {
        axios.get(`/updateAgreeTerms?token=` + this.auth_token).then((res) => {
          if (res.data.Status != 1) {
            console.log("terms updated");

            this.e1 = 3;
            this.$router.push("/plan_payment").catch(() => {});
          } else {
            console.log("terms updated");
          }
        });
      }
    },
    submit_diseases(email) {
      let url = `/createDiseaseCart?email=` + email;
      let config = { headers: { "Content-Type": "application/json" } };
      axios
        .post(url, JSON.stringify(this.my_diseases), config)
        .then((res) => {
          if (res.data.Status != 1) {
            this.loading = false;
            this.new_dis_len = 0;
            this.update_profile(email);
          } else if (
            res.data.Status == 1 &&
            res.data.Data.error == "Invalid User"
          ) {
            console.log("Invalid User");
          } else {
            this.loading = false;
            console.log("disease cart creation failed");
          }
        })
        .catch((error) => {
          console.log(error.message);
          // if (error.message == "Request failed with status code 500") {
          //   this.logOut()
          // }
        });
    },

    update_profile() {
      console.log(this.my_plan);
      if (this.my_plan == "0") {
        this.my_plan = "Trial";
      } else if (this.my_plan == "1") {
        this.my_plan = "First Mover Plan";
      } else if (this.my_plan == "2") {
        this.my_plan = "My Team Plan";
      } else if (this.my_plan == "4") {
        //for new basic plan
        this.my_plan = "Basic Plan";
      }

      // localStorage.setItem("diseases", this.my_diseases);
      console.log(this.my_diseases, "my_diseases");
      var wishlist_array = [];
      for (var f = 0; f < this.my_diseases.length; f++) {
        wishlist_array.push(this.my_diseases[f].name);
      }

      var wishlist = wishlist_array.join();
      console.log(wishlist, "wishlist");

      var article_id = "";
      var activity_name = "Activity User Registration";

      // if(this.my_plan == "Trial"){
      //   //to save registration activity
      //   axios
      //     .post("/SaveActivity?id=" + article_id + '&token=' + this.auth_token + "&activity_name=" + activity_name + "&disease=" + wishlist)
      //     .then((res) => {
      //       if (res.data.Status != 1) {
      //         console.log("Activity saved successfully")
      //       } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
      //         this.$router.push("/").catch(() => { })
      //       } else {
      //         console.log("Activity save failed")
      //       }
      //   })
      // }else{
      //   wishlist_array = []
      // }

      let senddata = {
        company_name: this.company_name,
        user_position: this.position,
        diseases: wishlist_array,
        other_company: this.other_company,
        other_position: this.other_position,
        other_diseases: this.other_diseases,
        plan: this.my_plan,
        subplan: this.sub_plan,
        user_name: this.user_name,
      };

      let url = `/userProfile?onRegister=true` + `&email=` + this.email;

      let config = { headers: { "Content-Type": "application/json" } };
      axios.put(url, JSON.stringify(senddata), config).then((res) => {
        if (res.data.Status == 0) {
          console.log(res.data);
          //Check here if user belongs to My Team Plan i.e someone has already included him/her to My Team Plan
          console.log(res.data.Data, "res.data.Dataaaaaa");
          console.log(res.data.Data.Has_my_team_plan, "has team plannnnn");
          // if(res.data.Data != null && res.data.Data.Has_my_team_plan){

          //   console.log("has team plannnnn",res.data.Data.Has_my_team_plan)
          //   // this.has_my_team_plan=true
          // }else if(this.my_plan == "Trial"){
          //   this.$router.push("/Payment").catch(() => { });
          // }else if (this.my_plan == "First Mover Plan"){
          //   this.$router.push("/plan_payment").catch(() => { });
          // }else if(this.my_plan == "My Team Plan"){
          //   this.$router.push("/plan_payment").catch(() => { });
          // }else if (this.my_plan == "Basic Plan"){
          //    //for new basic plan
          //   this.$router.push("/plan_payment").catch(() => { });
          // }
        } else {
          console.log("Error while profile updation");
          this.message = "Please try again";
        }
      });
    },

    getdiseases() {
      this.diseases = [];
      axios.get("/getalldiseases").then((res) => {
        //console.log(res, "diseases");
        if (res.data.Status != 1) {
          for (var i = 0; i < res.data.Data.length; i++) {
            var dis = {};
            dis.status = "Not-selected";
            dis.name = res.data.Data[i].Name;
            dis.ID = res.data.Data[i].ID;
            dis.Name = res.data.Data[i].Name;
            this.diseases.push(dis);
          }
          var dis = this.diseases.sort(function (a, b) {
            var textA = a.name.toUpperCase();
            var textB = b.name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });

          this.diseases = dis;
        } else {
          console.log("Diseases not found");
        }
      });
      console.log(this.diseases, "diseases");
    },
    getpositions() {
      axios.get("/getallpositions").then((res) => {
        //console.log(res, "positions");
        if (res.data.Status != 1) {
          for (var i = 0; i < res.data.Data.length; i++) {
            this.positions.push(res.data.Data[i].Name);
          }

          var pos = this.positions.sort(function (a, b) {
            var textA = a.toUpperCase();
            var textB = b.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });

          this.positions = pos;
          this.positions.unshift("Other");
          this.positionsCopy = [...this.positions];
          //convert all positions to lowercase and first letter to uppercase
          for (var i = 0; i < this.positions.length; i++) {
            if (this.positions[i] != "CXO") {
              this.positions[i] = this.positions[i].toLowerCase();
              this.positions[i] =
                this.positions[i].charAt(0).toUpperCase() +
                this.positions[i].slice(1);
            }
          }
        } else {
          console.log("Positions not found");
        }
      });
      console.log(this.positions, "positions");
    },
    getcompanies(token) {
      axios.get("/getallcompanies").then((res) => {
        //console.log(res, "companies");
        if (res.data.Status != 1) {
          for (var i = 0; i < res.data.Data.length; i++) {
            this.companies.push(res.data.Data[i].Name);
          }

          var com = this.companies.sort(function (a, b) {
            var textA = a.toUpperCase();
            var textB = b.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });

          this.companies = com;
          this.companies.unshift("Other");
          this.companiesCopy = [...this.companies];
          //convert all companies to lowercase and first letter to uppercase
          for (var i = 0; i < this.companies.length; i++) {
            this.companies[i] = this.companies[i].toLowerCase();
            this.companies[i] =
              this.companies[i].charAt(0).toUpperCase() +
              this.companies[i].slice(1);
          }
        } else {
          console.log("Companies not found");
        }
      });
    },
    next_process() {
      this.open_diseases_modal = false;
    },
    ValidateEmailtext(emailtext) {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (String(emailtext).match(mailformat)) {
        return true;
      } else {
        return false;
      }
    },
    validateEmail(e) {
      if (e.keyCode === 13) {
        this.send_email();
      } else {
        console.log();
      }
    },
    validateOTP(e) {
      if (e.keyCode === 13) {
        this.send_otp();
      } else {
        //console.log("entering OTP");
      }
    },
    send_email() {
      this.verify = true;
      console.log(this.email, "send email");
      this.spinner = true;
      var validmsg = this.ValidateEmailtext(this.email);
      //console.log(validmsg,"validmsg")
      if (this.email != "") {
        if (validmsg == true) {
          this.show_resend = true;
          axios.post("/loginapi?email=" + this.email).then((res) => {
            console.log(res.data.Data.Newuser, "Newuser");
            if (res.data.Status == 0 && res.data.Data.Newuser == true) {
              //console.log("new user created")
              this.spinner = false;
              // this.message = "We have sent Multifactor Authentication Code to your email address. Please check and enter the code."
              this.email_valid = true;
              this.show_resend = true;
              this.user_exist = false;
              this.show_otp_modal = true;
              setTimeout(() => {
                document.getElementById("register").scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "nearest",
                });
              }, 100);

              this.invalid_email = false;
              this.e1 = 2;
              this.submit_diseases(this.email);
            } else if (res.data.Status == 0 && res.data.Data.Newuser == false) {
              //console.log("User already created")
              this.user_exist = true;
              this.spinner = false;
              // this.message = "Account is already exists with this email-id. Please Log In to continue."
            } else {
              this.spinner = false;
              this.message = "Invalid email, please try again";
              this.invalid_email = true;
            }
          });
        } else {
          this.spinner = false;
          this.message = "Invalid email, please try again";
          this.invalid_email = true;
        }
      } else {
        this.spinner = false;
        this.message = "Please enter email";
      }
    },
    send_otp() {
      if (this.otp != "") {
        axios
          .post("/validateOTP?email=" + this.email + "&otp=" + this.otp)
          .then((res) => {
            //console.log(res, "response otp")
            if (res.data.Status == 0) {
              this.auth_token = res.data.Data.Authentication_token;
              this.got_token = true;
              this.incorrect_code = false;
              this.show_terms_modal = true;
              this.show_otp_modal = false;
              localStorage.setItem("authtoken", this.auth_token);
              //EventBus.$emit("login", this.auth_token);
            } else if (res.data.Status == 1) {
              this.incorrect_code = true;
              this.otp_message = "Incorrect Multifactor Authentication Code";
            } else {
              this.otp_message = "Please try again";
            }
          });
      } else {
        this.message = "Please enter Multifactor Authentication Code";
      }
    },
    resend_otp() {
      this.otp = "";
      this.incorrect_code = false;
      this.resend_spinner = true;
      this.message = "";
      var validmsg = this.ValidateEmailtext(this.email);

      if (this.email != "") {
        if (validmsg == true) {
          axios.post("/resendOTP?email=" + this.email).then((res) => {
            console.log(res.data.Data.Exists, "resend otp");
            if (res.data.Status == 0) {
              if (res.data.Data.Exists == true) {
                console.log("exissttssss");
                //this.user_exist = true
                this.resend_spinner = false;
                this.spinner = false;
                this.email_valid = true;
                this.message =
                  "Account is already exists with this email-id. Please Log In to continue.";
              } else {
                console.log("elseeee");
                this.resend_spinner = false;
                this.message =
                  "We have sent Multifactor Authentication Code to your email address. Please check and enter the code.";
                this.email_valid = true;
              }
              //console.log("success")
            } else {
              if (res.data.Data.error == "User not found") {
                this.resend_spinner = false;
                this.message =
                  "We have sent Multifactor Authentication Code to your email address. Please check and enter the code.";
                this.email_valid = true;
              } else {
                this.resend_spinner = false;
                this.message = "Invalid email, please try again";
              }
            }
          });
        } else {
          this.resend_spinner = false;
          this.message = "Invalid email, please try again";
        }
      } else {
        this.resend_spinner = false;
        this.message = "Please enter email";
      }
    },
    send_default_email(email) {
      //console.log(email)
      this.isDisabled = true;
    },
    show_terms() {
      this.show_terms_modal = true;
    },
    GoToMyPage() {
      this.has_my_team_plan = false;
      this.$router.push("/").catch(() => {});
    },
    close_terms_modal() {
      this.show_otp_modal = false;
      this.otp = "";
      this.incorrect_code = false;
    },
    registerAgain() {
      this.verify = false;
      this.user_exist = false;
      this.email = "";
      this.user_name = "";
      this.position = "";
      this.company_name = "";
    },
    goToHome() {
      this.$router.push("/").catch(() => {});
    },
  },
};
</script>

<style scoped>
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 45px !important;
}
.margin_align {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh !important;
}

.split {
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

/* Control the left side */
.left {
  left: 0;
  background-color: #fff;
}

image {
  height: 100%;
}

/* Control the right side */
.right_section {
  margin-left: 5vh;
  background-color: #ffffff;
  height: 100%;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.v-carousel__controls {
  bottom: 0;
  height: 50px;
  list-style-type: none;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.v-btn__content {
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  line-height: normal;
  position: relative;
  transition: inherit;
  transition-property: opacity;
}

.PageView {
  font-family: Ubuntu;
}

.picture {
  height: 100%;
}

.title {
  font-family: Ubuntu;
  font-size: 35px;
  font-weight: bold;
}

.centered p {
  margin-bottom: 4vh;
  opacity: 0.7;
  font-size: 19px;
  font-weight: 300;
  text-align: left;
  color: #4b4b4b;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.logInBox {
  margin-top: 3vh;
  margin-bottom: 1vh;
  width: 75vw;
  font-family: Ubuntu;
}

h6 {
  opacity: 0.81;
  font-size: 13.5px;
  font-weight: normal;
  margin-top: 0.5vh;
  color: #4b4b4b;
}

.v-divider--vertical {
  border: solid;
  border-width: 0 thin 0 0;
  border-color: black !important;
  float: left;
  margin-right: 10px !important;
  height: 4.5vh;
}

.subHead {
  font-size: 3vh;
  font-weight: 600;
  color: #4b4b4b;
}

.v-text-field--outlined {
  max-width: 33vw;
  max-height: 7.5vh !important;
}

.signUp {
  margin-top: 3vh;
  width: 100px;
  border: 2px solid #009ea5;
  font-size: 16px;
  font-weight: 600;
  color: #009ea5;
}

.Rectangle-939 {
  margin: 1vh 1vh 1vh 0vh;
  padding: 1vh;
  background-color: #d4eeef;
  text-align: center;
  min-height: 8vh !important;
}

.Path-359 {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #ff6750 !important;
  background-color: #fff;
  border-radius: 20px !important;
}

.view_cart {
  margin: 1vh;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: #03a6ad;
}
.Cat-Scratch-Disease {
  margin: 0vh 2vh 2vh 0vh;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}
.dotted_box1 {
  padding: 0vh 1vh 1vh 1vh;
  border-top: 1px dotted #384348;
  background-color: inherit;
}
.dotted_box2 {
  padding: 0vh 1vh 1vh 1vh;
  border-top: 1px dotted #384348;
  background-color: #f0feff;
}
.Path-8 {
  margin: 2vh;
  padding: 1vh;
  border: solid 1px #008489;
  background-color: #fff;
  border-radius: 7px;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #008489;
}

.Select-Diseasee {
  margin: 2vh 2vh 2vh 2vh;
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.27;
  letter-spacing: normal;
  text-align: left;
  color: #44446c;
}
.circle {
  width: 20px;
  height: 20px;
  margin: 0vh 2vh 0vh 2vh;
  border-radius: 20px;
  border: solid 1px #676772;
  background-color: inherit;
}

.further_section {
  margin: 2vh 2vh 2vh 2vh;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.78;
  letter-spacing: normal;
  text-align: center;
  color: #4e5558;
}

.vl {
  border-left: 1px solid #676772;
  height: 60vh;
}

.v-application .error--text {
  color: #008489 !important;
  caret-color: #008489 !important;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.first_sec {
  height: 110vh;
  padding: 10px 40px 10px 10px;
  margin-bottom: 5vh;
  background-color: #013d48;
}
.Ellipse-297 {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #f16753;
  float: right;
  margin-top: 6px;
  margin-right: 2vh;
}
.Ellipse-298 {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #03a6ad;
  float: right;
  margin-top: 6px;
  margin-right: 2vh;
}
.Ellipse-297_2 {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #939393;
  float: right;
  margin-top: 6px;
  margin-right: 2vh;
}
.step1 {
  margin-left: 10px;
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  float: right;
}
.new_login {
  font-family: Ubuntu;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  color: #f16753;
  text-align: right !important;
  float: right;
}
.new_login_11 {
  font-family: Ubuntu;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  color: #03a6ad;
  text-align: right !important;
  float: right;
}
.step2 {
  margin-left: 10px;
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #939393;
  float: right;
}
.verification {
  font-family: Ubuntu;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  color: #939393;
  text-align: right !important;
  float: right;
}
.Line-291 {
  margin-top: 3vh;
  /* margin-left: 200px !important;
  margin-right:-40px !important; */
  margin-right: -40px;
  width: 120% !important;
  height: 3px !important;
  background-color: #f16753;
  float: right;
}
.Line-291_0 {
  margin-top: 3vh;
  /* margin-left: 200px !important;
  margin-right:-40px !important; */
  margin-right: -40px;
  width: 120% !important;
  height: 3px !important;
  background-color: #03a6ad;
  float: right;
}
.Line-291_26 {
  margin-top: 3vh;
  /* margin-left: 200px !important;
  margin-right:-38px !important; */
  margin-right: -40px;
  width: 120% !important;
  height: 3px !important;
  background-color: #939393;
  float: right;
}
.Line-291_22 {
  margin-top: 3vh;
  /* margin-left: 200px !important;
  margin-right:-112px !important; */
  margin-right: -40px;
  width: 120% !important;
  height: 3px !important;
  background-color: #939393;
  float: right;
}
.Line-291_3 {
  margin-top: 3vh;
  /* margin-left: 200px !important;
  margin-right:-38px !important; */
  margin-right: -40px;
  width: 120% !important;
  height: 3px !important;
  background-color: #f16753;
  float: right;
}
.Line-291_33 {
  margin-top: 3vh;
  /* margin-left: 200px !important;
  margin-right:-112px !important; */
  margin-right: -40px;
  width: 120% !important;
  height: 3px !important;
  background-color: #f16753;
  float: right;
}
.if-you {
  margin: 0px 0px 0px 0px !important;
  font-family: Ubuntu;
  font-size: 2vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.Path-820 {
  margin-top: 10px;
  padding: 10px 20px 10px 20px;
  background-color: #03a6ad !important;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #ffffff;
  border-radius: 5px !important;
}
.welcome {
  font-family: Ubuntu;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #202527;
}
.Email_input {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #49494d;
}
.try_again_msg {
  margin: 0px 0px 0px 0px !important;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left !important;
  color: #03a6ad;
}
.Path-822 {
  margin-top: 10px;
  padding: 10px 20px 10px 20px;
  border: solid 1px #f16753;
  background-color: #fff;
  color: #f16753;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left !important;
}
.path_1145 {
  width: 33vw;
  margin-top: 2vh;
  padding: 20px;
  background-color: #f5f7f9;
  border-radius: 5px;
}
.path_1145_1 {
  width: 80vw;
  margin-top: 3vh;
  padding: 20px;
  background-color: #f5f7f9;
  border-radius: 5px;
}
.Confirmation {
  margin-top: -30px;
  margin-left: 5vh;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #202527;
}
.We-have {
  margin: 10px;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #676772;
}
.otpRESEND {
  margin-left: 10px;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #f16753;
  cursor: pointer;
}
.step3 {
  margin-left: 10px;
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #03a6ad;
  float: right;
}

.Path-68717 {
  padding: 5px 20px 5px 20px;
  background-color: #d04d3b;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  font-size: 2vh;
  float: right !important;
  font-family: Ubuntu;
  cursor: pointer;
  color: #ffffff;
  margin: 2vh;
}

@keyframes blink_input_opacity_to_prevent_scrolling_when_focus {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.v-otp-input--focused {
  animation: blink_input_opacity_to_prevent_scrolling_when_focus 0.01s;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff; /* Adjust the transparency as needed */
  z-index: 1000; /* Ensure it's above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
