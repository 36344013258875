<template>
   
    
  <!-- <v-main> -->
     <div >  
          <!-- <template> -->
              <div class="text-center">
                <v-dialog
                 v-model="queans_modal"
                 fullscreen
                :scrim="false"
                 style="overflow-y:hidden"
                >
                <v-card class="Path-350">
                  <v-card-text style="padding:0;border-top-left-radius:none !important;">
                    <!-- <v-row>
                      <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                        <span class="know_more">Know More</span>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                        <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="close_question_modal()">mdi-close</v-icon>
                      </v-col>
                    </v-row> -->
                    <!-- <div>
                      <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right !important" @click="close_question_modal()">mdi-close</v-icon>
                    </div> -->
                    <v-row  class="Rectangle-1271" wrap justify="center" align="center">
                      <v-col col="12" sm="2" md="2" lg="2" xs="2" xl="2" style="font-size:20px;">
                        <v-row wrap justify="center" align="center">
                          <span style="margin-bottom:2vh"> Ask me anything ! </span>
                            </v-row>
                           
                      </v-col>
                      <v-col col="12" sm="3" md="3" lg="3" xs="3" xl="3"  >
                        <button style="padding:0;" @click="toggle('ana')" :class="[mode=='ana'?'active_toggle':'inactive']">Analysis</button>
                        <button style="padding:0;" @click="toggle('qna')"  :class="[mode=='qna'?'active_toggle':'inactive']">Research</button>
                      </v-col>
                      <v-col v-if="mode=='qna'" col="12" sm="6" md="6" lg="6" xs="6" xl="6" style="text-align:left">
                        <span style="font-size:14px;">Choose any article from left hand side and ask me anything!.</span>
                      </v-col>
                      <v-col v-if="mode=='ana'" col="12" sm="6" md="6" lg="6" xs="6" xl="6" style="text-align:left">
                        <span style="font-size:14px;line-height: 1.30px;">Ask Question <b>"Create a phase wise bar chart for clinical studies of indication multiple sclerosis"</b> and Hit Enter. <br><i> It will take approximately <b>2 minutes</b> for an answer to arrive.</i></span>
                      </v-col>
                      <v-col col="12" sm="1" md="1" lg="1" xs="1" xl="1" style="padding-right:0">
                        <v-icon color="#009ea5" style="cursor:pointer;" @click="close_question_modal()">mdi-close</v-icon>
                      </v-col>
                    </v-row>
                    <!-- qna mode -->
                    <v-row style="margin:0"  v-if="mode=='qna'" >
                      <v-col col="12" sm="5" md="5" lg="5" xs="5" xl="5"  style="height:92vh;background-color: #edf7f9 !important;box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.26);padding:3vh">
                        <v-row no-gutters wrap justify="center" align="center">
                          <v-col col="12" sm="3" md="3" lg="3" xs="3" xl="3" >
                            <!-- create checkbox with label Select All -->
                            <v-checkbox 
                              class="chkbox_content"
                              style=" color:#2C2C48;font-family: Ubuntu;font-size: 14px;font-weight: normal;"
                              v-model="check_all"
                              color="#00b2ba"
                              label="Select All"
                              @change="check_all_articles(check_all)"
                              :disabled="my_word==''"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                          <v-col col="12" sm="9" md="9" lg="9" xs="9" xl="9">
                            <div><v-text-field flat solo hide-details v-model="my_word" class="search_context" @change="editSearch()" >
                              <template v-slot:append >  
                                <img src="../assets/Icons/search.png" alt="user" @click="editSearch()" style="height: 22px; width: 26px; margin-right:5px;cursor:pointer"/>
                            </template>
                            </v-text-field></div>
                          </v-col>
                        </v-row>
                        <div >
                          
                          <v-row wrap style="margin-top: 0vh;margin-bottom:1vh;color:none" >    
                            <v-col col="12" sm="6" md="6" lg="6" xs="6" xl="6"  v-for="(opt,kopt) in option_lables" :key="kopt"  > 
                              <v-btn  text @click="choose_option(opt)" style="font-weight: normal;" :style="opt.status == 'selected' ? 'color:#fff;font-family: Ubuntu;background-color:#03a6ad;':''">{{opt.name}}</v-btn>
                              <!-- :disabled="loadingModal" :style="opt.status == 'selected' ? 'color:#fff;font-family: Ubuntu;font-weight: normal;' : 'color:#202527; font-family: Ubuntu;font-weight: normal;'" :class="opt.status == 'selected' ? 'Label_btn' : 'Label_btnn'"  -->
                              <!-- <v-tabs color="#fff"  hide-slider active-class="active">
                               
                               
                                <v-tab style="margin-left:16vh" v-for="(opt,kopt) in option_lables" :key="kopt" @click="choose_option(opt)" >{{opt.name}}</v-tab>
                              </v-tabs> -->
                          </v-col>
                          
                          </v-row>
                          <v-row v-if="option=='loadingdocuments'" style="margin-bottom:3vh" no-gutters justify="center" align="center">
                            <v-btn style="width:30vh;" @click="clickUploader"> Browse</v-btn>
                          </v-row>
                          <v-row wrap :style="option=='loadingdocuments'?'max-height: 45vh;overflow-y: scroll;margin-top:-1vh':'max-height: 63vh;overflow-y: scroll;margin-top:-1vh'">
                            <v-col style="padding-top:0" col="12" align="left">
                              <div v-if="option=='loadingdocuments'">
                                <input ref="uploader" type="file" multiple class="d-none" @change="uploadFiles($event)">
                                <div class="Path-846" v-for="(val,keyv) in uploadedFiles" :key="keyv">
                                  <span style="padding-left:5vh;font-family: Ubuntu;font-size: 12px !important;">{{val}}</span>
                                </div>
                              </div>
                              <div v-else style="margin-top:0" class="Path-846" v-for="(chk,kchk) in checkboxData" :key="kchk">
                                 <v-checkbox
                                  class="chkbox_content"
                                  :label= "chk.text"
                                  color="#00b2ba"
                                  v-model="chk.Checkbox"
                                  @change="addCheckedValue(chk, oldQuestionIds)"
                                  style="margin-top:0;"
                                  hide-details="true"
                                  
                                ></v-checkbox> 
                               <a v-if="option=='clinical'" target=”_blank” :href="(`https://clinicaltrials.gov/ct2/show/${chk.id}`)" style="font-size:12px;margin-left:5.5vh;">{{chk.id}}</a> 
                                <a v-else-if="option=='fda'" target=”_blank” :href="(`https://www.accessdata.fda.gov/spl/data/${chk.link_id}/${chk.link_id}.xml`)" style="font-size:12px;margin-left:5.5vh;">{{chk.id}}</a> 
                              
                              </div>
                              <div v-if="checkboxData.length == 0 && option!='loadingdocuments' && !loadingModal" style="margin-top: 4vh;text-align: center !important">
                                <span class="coming_soon">No Data !</span>
                              </div>
                              <div v-else-if="loadingModal " style="margin-top: 16vh;text-align: center !important">
                                <v-progress-circular indeterminate color="#8c8e91"></v-progress-circular>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                      <v-col col="12" sm="6" md="6" lg="6" xs="6" xl="6" style="margin-left:5vh;font-family: Ubuntu;font-weight: normal;font-stretch: normal;font-style: normal;line-height: 1.89;letter-spacing: normal;">
                          <div style="font-size: 18px;text-align: left;color: #202527;margin-bottom:1vh;margin-top:1vh">
                            <span>Ask Question</span>
                           </div>
                         <v-row v-if="queans.length==0">
                          <v-col cols="12" align=center style="margin-top:20vh">
                            <div style=" display: flex;
                            justify-content: center;
                            align-items: center;">
                              <img  style="padding-bottom:10px" src="../assets/Icons/Group 1095.svg" >
                            </div>
                            <div style=" display: flex;
                            justify-content: center;
                            align-items: center;"> 
                              <span style="font-family: Ubuntu;
                              font-size: 26px;
                              font-weight: 500;
                              font-stretch: normal;
                              font-style: normal;
                              line-height: 1.31;
                              letter-spacing: normal;
                              text-align: center;
                              color: #227c80;">
                                {{"KnolChat"}}
                              </span>
                            </div>
                        </v-col>
                       </v-row>
                        <div style="max-height: 70vh;overflow-y: scroll;overflow-x:hidden; margin-top:-1vh" >
                        <div v-for="(que,kque) in queans" :key="kque" style="margin-bottom:1vh">
                          <div  :id="que.id"  style="padding: 13.8px 18.5px 13.6px 15px;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border: solid 1px #dbdbdb;background-color: #f7f7f7;overflow:hidden">
                            <v-row  >
                              <v-col col="12" sm="1" md="1" lg="1" xs="1" xl="1" style="padding-right:0; padding-top:0.5vh">
                                <div class="Ellipse-154-qna">
                                  ?
                                </div>
                              </v-col>
                              <v-col col="12" sm="10" md="10" lg="10" xs="10" xl="10" style="padding:0vh 0vh 1vh 0vh;">
                                <span style="font-size: 14px;line-height: 1.57;text-align: left;color: #202527;">{{que.Question}}</span>
                              </v-col>
                              <v-col col="12" sm="1" md="1" lg="1" xs="1" xl="1" style="padding-left:0; padding-top:0.5vh">
                                  <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right !important" @click="RemovefactualQuestion(que.id)" >mdi-close</v-icon>
                              </v-col>
                            </v-row>
                          </div>
                          <div>
                            <v-row style="padding: 0px 0px 0px 20px;">
                              <v-col col="12" sm="1" md="1" lg="1" xs="1" xl="1" style="padding-right:0">
                                <img src="../assets/Icons/Knolense_Dark111.svg" style="width: 21.2px;height: 20.2px;">
                              </v-col>
                              <v-col style="padding-left:0" v-if="que.Answer.length>0 ">
                                <span style="font-size: 14px;line-height: 1.57;text-align: left;color: #202527;">{{que.Answer}}</span>
                              </v-col>
                              <v-col style="padding-left:0" v-else-if="que.Answer.length==0  && loadingQue">
                                <span class="text-class"  > <v-progress-circular indeterminate :size="15" :width="2" color="#8c8e91"></v-progress-circular><i> {{"Fetching Answer..."}}</i></span>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                        </div>
                        <v-row>
                          <div class="queBoxNew">
                            <v-text-field  
                              style="width: 100% !important;margin-left:12px !important;
                                margin-right:12px !important;
                              font-weight: 600;
                              font-size: 12px;
                              font-family: Ubuntu;
                              color: #383838;" v-model="question"
                              class="mx-2 mb-3 shrink" placeholder="Ask Question" solo
                              v-on:keyup="savequestion"
                              hide-details
                              :disabled="questionIds.length == 0 || loadingQue"
                              >
                            <template v-slot:append v-if="loadingAnswer">  
                                <v-progress-circular indeterminate color="#8c8e91"></v-progress-circular>
                            </template>
                              </v-text-field>
                            
                          </div>
                        </v-row>
                      </v-col>
                      <!-- <v-col col="12" sm="8" md="8" lg="8" xs="8" xl="8" >
                        <span class="ask_que_sec">Ask Question <br><span class="que_txt">Select entries from left panel for precise answer</span><br></span>
                        <div class="Rectangle-1232"  >
                          <div v-if="show_que" style="overflow-y:scroll;max-height:320px">
                            <v-row v-for="(que,kque) in queans" :key="kque">
                                <v-col>
                                  <v-row wrap class="Path-868">
                                    <v-col col="12" sm="1" md="1" lg="1" xs="1" xl="1" align="right">
                                      <span ><span>?</span></span>
                                    </v-col>
                                    <v-col col="12" sm="10" md="10" lg="10" xs="10" xl="10" >
                                      <span class="question">{{que.Question}}</span>
                                      
                                    </v-col>
                                  </v-row>
                                </v-col>
                              
                              <v-row wrap style="padding: 2vh;" >
                                <v-col col="12" sm="2" md="2" lg="2" xs="2" xl="2">
                                  <img src="../assets/Icons/Knolense_Dark111.svg" style="">
                                </v-col>
                                <v-col col="12" sm="10" md="10" lg="10" xs="10" xl="10" style="margin-left: -3vh;" >
                                  <span class="answer" >{{que.Answer}}</span>
                                </v-col>  
                              </v-row>
                          </v-row>
                          </div>
                          <div v-if="!show_que">
                            <span></span>
                          </div>
                          <v-row>
                            <div class="queBox">
                              <v-text-field  
                                style="width: 100% !important;margin:0px !important;
                                font-weight: 600;
                                font-size: 14px;
                                font-family: Ubuntu;
                                color: #383838;position: absolute;float:bottom;" v-model="question"
                                class="mx-2 mb-3 shrink" placeholder="Ask Question" solo
                                v-on:keyup="savequestion"
                                :disabled="questionIds.length == 0" 
                                >
                              <template v-slot:append v-if="loadingAnswer">  
                                  <v-progress-circular indeterminate color="#8c8e91"></v-progress-circular>
                              </template>
                                </v-text-field>
                                <span v-if="loadingAnswer">
                                  <loading :active.sync="loadingAnswer" 
                                  :can-cancel="true" 
                                  :on-cancel="onCancel"
                                  ></loading>
    
                                </span> 
                            </div>
                          </v-row>
                        </div>
                      </v-col>    -->
                      <!-- <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4" v-else>
                        <span class="ask_que_sec">Analytics </span>
                        <div class="Rectangle-1232"  >
                          <div v-if="show_que" style="overflow-y:scroll;max-height:320px">
                            <v-row v-for="(que,kque) in queans" :key="kque">
                                <v-col>
                                  <v-row wrap class="Path-868">
                                    <v-col col="12" sm="1" md="1" lg="1" xs="1" xl="1">
                                      <span class="circle"><span>?</span></span>
                                    </v-col>
                                    <v-col col="12" sm="10" md="10" lg="10" xs="10" xl="10" style="margin-left:2vh">
                                      <span class="question">{{que.Question}}</span>
                                      
                                    </v-col>
                                  </v-row>
                                </v-col>
                              
                              <v-row wrap style="padding: 2vh;" >
                                <v-col col="12" sm="2" md="2" lg="2" xs="2" xl="2">
                                  <img src="../assets/Icons/Knolense_Dark111.svg" style="">
                                </v-col>
                                <v-col col="12" sm="10" md="10" lg="10" xs="10" xl="10" style="margin-left: -3vh;" >
                                  <span class="answer" >{{que.Answer}}</span>
                                </v-col>  
                              </v-row>
                          </v-row>
                          </div>
                          <div v-if="!show_que">
                            <span></span>
                          </div>
                          <v-row>
                            <div class="queBox">
                              <v-text-field 
                                style="width: 88% !important;
                                font-weight: 600;
                                font-size: 14px;
                                font-family: Ubuntu;
                                color: #383838;position: absolute;float:bottom;margin-left:2vh !important;" v-model="question"
                                class="mx-2 mb-3 shrink" placeholder="Ask Question" solo
                                v-on:keyup="savequestion"
                                >
                              <template v-slot:append v-if="loadingAnswer">  
                                  <v-progress-circular indeterminate color="#8c8e91"></v-progress-circular>
                              </template>
                                </v-text-field>
                                <span v-if="loadingAnswer">
                                 
    
                                </span> 
                            </div>
                          </v-row>
                        </div>
                      </v-col>    -->
                                       
                    </v-row>
                    <!-- analytical view -->
                    <v-row  v-if="mode=='ana'" no-gutters>
                      <!-- <AnalyticalChats></AnalyticalChats> -->
                    </v-row>

                    </v-card-text>
                    <v-card-actions>
                    </v-card-actions>  
                  </v-card>
                </v-dialog>
              </div>
            
        <template>
          <v-dialog v-model="user_plan" width="550">
            <v-card class="Path-359" style="padding:3vh;">
              <v-row>
                <v-col cols=12 xs="2" md="2" xl="2" sm="2" align="right" >
                  <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;">
                </v-col>
                <v-col cols=12 xs="8" md="8" xl="8" sm="8" align="left" >
                  
                  <span  style="font-family: Ubuntu;font-size:18px">
                    Please Subscribe to a plan to continue
                  </span>
                </v-col>
                <v-col cols=12 xs="2" md="2" xl="2" sm="2" align="right" >
    
                  <v-btn
                    icon
                    color="#009ea5"
                    @click="user_plan=false"
                    style="padding-bottom:5px"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                
                <v-col sm="4" md="4" lg="4" xs="4" xl="4">
                  <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 1.8vh;" @click="go_to_pricing()"> 
                    
                     Subscribe
                    
                  </v-btn>
                </v-col>
                <v-col sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
              </v-row>
            </v-card>
          </v-dialog>
        </template>
       
          <!-- </template> -->
       </div> 
      <!-- </v-main> -->
  </template>
  <script>
  import App from '../App.vue';
  import axios from 'axios'
  import AppBar from "../components/AppBar.vue";
  import postscribe from "postscribe";
  
  import { EventBus } from "../event-bus.js";
  export default {
    components: { App,AppBar},
      name:'QnAModal',
    data() {
      return {
      auth_token: "",
      my_word: "",
      queans: [],
      queansana:[],
      myquestion: "",
      show_que: false,
      pageX: 0,
      pageY: 0,
      outputcpy: "",
      checkboxData:[],
      loadingModal: true,
      questionIds: [],
      option:"fda",
      question: "",
      loadingAnswer: false,
      mode:'ana',
      uploadedFiles:[...new Set()],
      Files:[ ...new Set()],
      option_lables: [
        {
          name: "Labels",
          status: "selected"
        },
        {
          name: "Clinical Studies",
          status: "notselected"
        },
        // {
        //   name: "Local Documents",
        //   status: "notselected"
        // },
      ],
      resetCheckbox: false,
      oldQuestionIds: [],
      queans_modal:false,
      //analytical view
      previousChat:[],
      myquestionana:"",
      questionana:"",
      loadingAnswerana:false,  
      showprev:false,
      prev:{},
      show_queana:true,
      question_id:'',
      addnewChat:true,
      key:'',
      check_all:false,
      controller:null,
      currentRequest:0,
      loadingQue:false,
      currentRequestSearch:0,
      currentRequestChats:0,
      user_plan:false,
      trial_mode:false,
      show_json_template: false,
      jsonfile_content: {},
      footnote_available: false,
      items: [],
      text:'',
      show : false,
      finallist:[],
      showlist:false,
      finalword:'',
      new_plot_count : 0,
      disable_qna : false,
    
      };
    },
  
    
    computed: {
    },
    created() {
    },
    mounted() {      
      console.log("mounted....aaaanaaaaa")
      var auth_token = localStorage.getItem("authtoken");
      if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
        this.auth_token = auth_token
        this.disable_qna=true
        console.log("logged in")
        this.loadingModal = false
        
        // this.get_user_plan()
      
      }else{
          console.log("not logged in")
      }
    },
  
    methods: {
    
    
      get_user_plan(){
       
        //create get request to get user plan
        axios.get('/getUserPlan?token='+this.auth_token).then((res) => {
          //console.log(res)
          if (res.data.Status != 1) {
            console.log(res.data,"user plan")
            if (res.data.Data.Plan==false){
              console.log("no plan")
              this.user_plan=true

              console.log(this.user_plan,"getttt user plannnnn")
              this.$forceUpdate();
            }
            
          }
        }).catch((err) => {
          console.log(err)
        })

      },
      toggle(view){
        this.mode=view
        if(view=="ana"){
         
        } else{
          this.previousChat=[]
        }
      },
      clickUploader(){
        this.$refs.uploader.click()
      },
      uploadFiles(event){
        var files = [].slice.call(event.target.files);
        for(let it=0;it<files.length;it++){ 
            this.uploadedFiles.push(files[it].name)
            this.Files.push(files[it])
        }
        this.uploadedFiles = [ ...new Set(this.uploadedFiles)]
        this.Files=[ ...new Set(this.Files)]
        // console.log(this.Files)
      },
      choose_option(opt) {
          this.check_all=false
          this.questionIds = []
          this.oldQuestionIds = []
          this.loadingAnswer = false
          this.loadingQue=false
    
          this.loadingModal =true
          this.checkboxData = []
          // console.log(opt.name)
          if (opt.name=="Labels"){
            this.option="fda"
          }else if (opt.name=="Clinical Studies"){
            this.option="clinical"
          } else if(opt.name=="Local Documents"){
            this.option="loadingdocuments"
            for (var o = 0; o < this.option_lables.length; o++) {
              if (opt.name == this.option_lables[o].name) {
                this.option_lables[o].status = "selected"
              } else {
                this.option_lables[o].status = "notselected"
                
              }
            }
            this.loadingModal=false
            return
          }
          var newSplitRequest = this.currentRequestSearch + 1
            this.currentRequestSearch = newSplitRequest

          this.queans= []
            for (var o = 0; o < this.option_lables.length; o++) {
              if (opt.name == this.option_lables[o].name) {
                this.option_lables[o].status = "selected"
              } else {
                this.option_lables[o].status = "notselected"
              }
            }
          axios.post('https://ai.knolens.app/factual/search', {
              "query": this.outputcpy,
              "database":this.option
            }).then((res) => {
              if (this.currentRequestSearch != newSplitRequest) {
                    return
                  }
              this.checkboxData = []
              if (res.data.Status != 1) {
                this.loadingModal =false
                console.log("......")
                var d ={}
                for(let i=0;i<res.data.length;i++){
                  console.log(res.data[i],"......")
                  d = res.data[i]
                  //d.text=trimFunction(1000, d.text);
                  d.Checkbox=false
                  this.checkboxData.push(d) 
                }
                // console.log(this.checkboxData)
              
              } else {
                console.log("Article link not found")
              }
          }).catch((error) => {
            this.checkboxData = []
            this.loadingModal =false
            console.log(error.message);
            
          });
        
      },
      check_all_articles(c){
        if (!c){
         
          //append all question ids to questionIds
          for (var i = 0; i < this.checkboxData.length; i++) {
            this.checkboxData[i].Checkbox = false
            this.questionIds = this.questionIds.filter(item => item !== this.checkboxData[i].id)
           
          }
        }else{
          this.questionIds=[]  
          //remove all question ids from questionIds
          for (var i = 0; i < this.checkboxData.length; i++) {
            this.checkboxData[i].Checkbox = true
            this.questionIds.push(this.checkboxData[i].id)
            
          }
        }
        // console.log(this.questionIds)
      },
      analytical_Chat(q){
       
        // this.get_user_plan()
        if (q){
          this.mode='ana'
          this.disable_qna=true
        
          console.log("callAnalytical")
          this.queans_modal=q
          this.$forceUpdate()
        }else{
          this.user_plan=true
        }
      },
      showquesModal(outputcpy,show){
      
        // this.get_user_plan()
       
        if (show){
          this.mode='qna'
          this.disable_qna=false
          this.queans_modal = true
          this.outputcpy=outputcpy
          this.my_word = this.outputcpy
          this.loadingModal = true
        
          var newSplitRequest = this.currentRequestSearch + 1
          this.currentRequestSearch = newSplitRequest

          if (this.outputcpy !== "") {
          
              this.queans_modal = true
            
            axios.post('https://ai.knolens.app/factual/search', {
              "query": this.outputcpy,
              "database":this.option

            }).then((res) => {
            

              this.loadingModal = false
              this.checkboxData = []
              console.log(res)
              if (res.data.Status != 1) {
                var d={}
                this.loadingModal = false
              
                for(let i=0;i<res.data.length;i++){
                
                  d=res.data[i]
                 
                  d.Status=false
                  this.checkboxData.push(d)
                }
                this.selectedText = ""
              
              
              } else {
                console.log("Article link not found")
                this.selectedText = ""
              }
            }).catch((err) => {
              
              this.checkboxData = []
              this.loadingModal = false
              console.log(err)
              this.selectedText = ""
            })
          }
        }else{
          this.user_plan=true
        }
      
      },
      editSearch(){
        this.check_all=false
        this.loadingModal = true
        this.quean=[]
        this.checkboxData = []
        console.log("editSearch")
        // localStorage.setItem("my_word", this.my_word);
        let selectedText = this.my_word.toString();
        this.outputcpy = selectedText;
        // console.log(this.outputcpy,"outputcpy")
        var newSplitRequest = this.currentRequestSearch + 1
        this.currentRequestSearch = newSplitRequest
        if (this.outputcpy !== "") {
          axios.post('https://ai.knolens.app/factual/search', {
            "query": this.outputcpy,
            "database":this.option
          }).then((res) => {
            if (this.currentRequestSearch != newSplitRequest) {
              return
            }
            this.loadingModal = false
          
          
            if (res.data.Status != 1) {
              var d={}
              for(let i=0;i<res.data.length;i++){
                d=res.data[i]
                d.Checkbox=false
                this.checkboxData.push(res.data[i])
              }
              this.selectedText = ""
            
            
            } else {
              console.log("Article link not found")
              this.selectedText = ""
            }
          }).catch((err) => {
            this.loadingModal = false
            console.log(err)
            this.selectedText = ""
          })
        }
      },
      addCheckedValue(m, oldID){
        
        var oldIDs = oldID 
        console.log(oldIDs, ".............")
        this.oldQuestionIds = []
        if(this.resetCheckbox){
          console.log("resetCheckbox")
        
        
        this.resetCheckbox=false
      
        this.$forceUpdate()
        

      }else{
        this.resetCheckbox=true
        
      }
        console.log(m)
        if (m.Checkbox){
          if (m.link_id!=null || m.link_id!=undefined || m.link_id!=""){
            console.log("khfd")
            this.questionIds.push(m.id)
          }else{
            console.log("m.id")
            this.questionIds.push(m.id)
          }
        }else{
          this.check_all=false
          this.questionIds.splice(this.questionIds.indexOf(m.id),1)
        }
        if(this.questionIds.length==this.checkboxData.length && this.checkboxData.length!=0){
          console.log("check all",this.questionIds.length,this.checkboxData.length)
          this.check_all=true
        }
        console.log(this.questionIds)
        this.oldQuestionIds = []
        console.log(this.oldQuestionIds)
        this.oldQuestionIds = oldIDs
        console.log(oldIDs, ".........")
        

      },
      getSelectedText() {
        let selection = document.getSelection();
        console.log(selection,"selection")
        let selRange = selection.getRangeAt(0);
        console.log(selRange,"selRange")
        return selRange;  
      },
      savequestion(e) {
        console.log(this.oldQuestionIds,"ksdjfksdhf")
        let oldIDs = this.oldQuestionIds  
        this.my_word = localStorage.getItem("my_word");
        if (e.keyCode == 13) {      
          this.show_answer(oldIDs)
        }
      },
      close_question_modal () {
        this.option="fda"
       
        this.show_que = false
        this.question = ""
        this.myquestion = ""
        this.answer = ""
        this.checkboxData = []
        this.queans=[]
        this.queansana=[]
        this.loadingAnswer=false
        this.loadingAnswerana=false
        this.mode='qna'
        this.finallist=[]
        this.showlist=false
        this.finalword=''
        this.questionana=''
        this.queans_modal = false
        this.$forceUpdate();
        
        localStorage.removeItem("my_word");
        // var menu = document.querySelector(".menu");
        // menu.style.display = "none";
        for (var o = 0; o < this.option_lables.length; o++) {
          if ("Labels" == this.option_lables[o].name) {
          
            this.option_lables[o].status = "selected"
          } else {
            this.option_lables[o].status = "notselected"
          }
        }
        var chat=false
        EventBus.$emit("chatselected",chat );
        var queana=false
        EventBus.$emit("queana",queana );
        // this.$router.push("/")
        this.$forceUpdate();
      },   
      scrollToEnd (){
        this.$el.scrollTop = this.$el.lastElementChild.offsetTop;
      },
      show_answer (a) {
        if (this.question.trim() == "") {
          return
        }
        
          this.loadingQue=true
          let originalQuestions = this.questionIds 
          let oldQIds = a
          console.log(originalQuestions,"questionIds")
          console.log(oldQIds,"oldQuestionIds")
          if (oldQIds != undefined &&  oldQIds.length > 0){
              const sortedArray1 = originalQuestions.sort();
              const sortedArray2 = oldQIds.sort();
              var is_same= sortedArray1.every((element, index) => element === sortedArray2[index])
              if (!is_same){
                  console.log("not same")
                  this.resetCheckbox=false
              }else{
                  console.log("same")
                  this.resetCheckbox=true
              }
          }else{
            this.resetCheckbox = true
          }
          this.resetCheckbox = false
          //console.log(this.resetCheckbox,"resetCheckbox")
        this.myquestion = this.question
        this.question = ""
        this.loadingAnswer = true
        this.show_que = true
        this.my_word =  localStorage.getItem("my_word");
        
        var newSplitRequest = this.currentRequestChats + 1
        this.currentRequestChats = newSplitRequest

        var app = this
        var abc = originalQuestions
        this.oldQuestionIds = abc
        var qs={
          Question:"",
          Answer:[]
        }
       

        qs.Question=app.myquestion
        qs.Answer=[]
        qs.Req_number = this.currentRequestChats
        qs.id= "qna_" + this.currentRequestChats.toString()
        app.queans.push(qs)
        if (app.queans.length > 4) {
            setTimeout(() => {
            document.getElementById(qs.id).scrollIntoView({
              behavior: 'smooth',block: "nearest", inline: "nearest"
            });
          }, 500);
        }
      

        //create post request 
        axios.post('https://ai.knolens.app/factual/chat', {
            "ids": originalQuestions,
            "database":app.option,
            "query": app.myquestion,
            "reset":app.resetCheckbox,
          }).then((res) => {
            console.log(res)
            if (res.data.Status != 1) {
              if (this.currentRequestChats != newSplitRequest) {
                return
              }
                var goahed = false
                var index_to_delete = 0
              for (let i = 0; i < app.queans.length; i++) {
                if (app.queans[i].Req_number == newSplitRequest){     
                  goahed = true
                  index_to_delete = i
                  break
                }
              }
                if (goahed){
                console.log(index_to_delete)
                
                  var pop = app.queans.splice(index_to_delete, 1)
              
              //  console.log(res.data)
              
                  qs.Question=app.myquestion
                  qs.Answer=res.data
                  qs.Answer=qs.Answer.toString();
                  qs.id= "qna_" + this.currentRequestChats.toString()
                // this.queans.push(qs)
                  app.queans.push(qs)
                  console.log(app.queans,"queans")
                  setTimeout(() => {
                    document.getElementById(qs.id).scrollIntoView({
                      behavior: 'smooth',block: "nearest", inline: "nearest"
                    });
                  }, 500);
                
                  app.loadingAnswer = false
                  app.myquestion = ""
                  this.loadingQue=false
              }
                
              
            } else {
              console.log(" not found")
              app.loadingAnswer = false
              
            
            }
          


          }).catch((err) => {
            if (this.currentRequest != newSplitRequest) {
              return
            }

            var goahed = false
            var index_to_delete = 0
            for (let i = 0; i < app.queans.length; i++) {
              if (app.queans[i].Req_number == newSplitRequest){     
                goahed = true
                index_to_delete = i
                break
              }
            }
              if (goahed){
                console.log(index_to_delete)
                
                  var pop = app.queans.splice(index_to_delete, 1)
              }
            qs.Question=app.myquestion
            qs.Answer="Unable to fetch answer!"
            qs.id= "qna_" + this.currentRequestChats.toString()
            app.queans.push(qs)
            setTimeout(() => {
                document.getElementById(qs.id).scrollIntoView({
                behavior: 'smooth',block: "nearest", inline: "nearest"
              });
            }, 500);
                
           
            console.log("inside catch!!!")
            app.loadingModal = false
            app.loadingAnswer = false
            console.log(err)
          
          })
          app.scrollToEnd()

        //this.answer = "Used for the treatment of acute myeloid leukemia (AML) in adults . Can cause myocardial toxicity leading to congestive heart failure. Severe myelosuppression occurs when used at effective therapeutic doses. Skin reactions associated with Idamycin PFS may occur. Adverse experiences reported include infection, nausea & vomiting, hair loss, abdominal cramps/diarrhea, hemorrhage, mucositis, dermatologic, mental status, pulmonary-clinical, fever, headache, cardiac-clinical, neurologic-peripheral nerves, pulmonary allergy, seizure, cerebellar"
      },  
   
      RemovefactualQuestion(id){
        //iterate over queans and remove the question with id
        for (let i = 0; i < this.queans.length; i++) {
          if (this.queans[i].id == id){
            this.queans.splice(i, 1)
            break
          }
        }
        this.loadingAnswer=false
        this.loadingQue=false
      },
      go_to_pricing(){
        this.user_plan=false
        this.$router.push('/User_pricing')
      },
    }
  };
  </script>
  <style>
  .ask_que_sec {
      font-family: Ubuntu;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.89;
      letter-spacing: normal;
      text-align: left;
      color: #202527;
      margin-left: 2vh
    }
    
    .Rectangle-1232 {
      margin: 2.5vh -5vh 0vh 0vh;
      padding: 2vh;
      background-color: #f7f7f7;
      height:66vh;
      border-radius: 1.5vh;
    }
    
    .que-text {
      font-family: Ubuntu;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: normal;
      text-align: left;
      color: #9b9b9b;
    }
    .que_txt {
      font-family: Ubuntu;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: 2.43;
      letter-spacing: normal;
      text-align: left;
      color: #676772;
      margin-left: 2vh
    }
    
    .Path-868 {
      margin: 0vh 2vh 0vh 1vh;
      padding: 1vh;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px #b1b1b1;
      background-color: #fff;
    }
      .circle {
        width: 32px;
    height: 32px;
    margin: 29.8px 10.7px 445.2px 12px;
    padding: 5.9px 5.4px 5.9px 5.4px;
    border: solid 1px #e3e3e3;
    background-color: #fff;
  }
    .answer {
      font-family: Ubuntu;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #202527;
    }
    
    .queBox {
      position:absolute;
      float:bottom;
      bottom: 30px;
    }
    .Searching-For {
      font-family: Ubuntu;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.89;
      letter-spacing: normal;
      text-align: left;
      color: #676772;
    }
    
    .Rectangle-1204 {
      padding: 0 2vh 0 2vh;
      margin-bottom: 2vh;
      border-radius: 15px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px #b1b1b1;
      background-color: #f7f7f7;
    }
    .search_context {
      font-family: Ubuntu;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.55;
      letter-spacing: normal;
      text-align: left;
      color: #2c2c48;
      padding: 0 2vh 2vh 2vh;
      background-color: #f7f7f7;
      border-radius: 15px;
      border: solid 1px #b1b1b1;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
     
    }
    .Rectangle-1229 {
      border-radius: 2vh;
      margin: 19px 27px 0 0;
      padding: 4vh 4vh 5vh 4vh;
      box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.26);
      background-color: #edf7f9;
      height: 77vh;
    }
    .Path-846 {
      margin-bottom:2vh;
      padding: 1vh 1vh 2vh 1vh;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
      border-radius: 2vh;

    }
     .chkbox_content .v-label{
      font-family: Ubuntu;
      font-size: 14px !important;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.58;
      letter-spacing: normal;
      text-align: left;
      color: #2c2c48;
      padding: 2px;
      padding-bottom: 0;
    }
  .Label_btn {
      font-family: Ubuntu;
      font-size: 11px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      background-color: #00b2ba !important;
      border-radius: 9px;
    }
  .Label_btnn {
      font-family: Ubuntu;
      font-size: 11px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: center;
      color: #202527;
      background-color: transparent;
    }
    .Path-350 {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
      overflow: hidden;
      padding:0vh 2vh 0vh 0vh
    }
    .menu {
      display: none;
      position: absolute;
      background: #fff;
      border-radius: 6px;
      border: 1px solid #333;
      padding:1vh 2vh 1vh 2vh;
      cursor: pointer;
      z-index: 1;
    }
    .menu i {
      color: #03a6ad;
      cursor: pointer;
      margin: 8px;
    }
    .question {
      font-family: Ubuntu;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #202527;
    }
    .v-dialog{
      margin-top:48px !important;
    }

    .active_toggle{
      width: 139.5px;
      height: 30.7px;
      margin: 0 4px 0 4px;
      padding: 8.7px 0.5px 9px 0;
      border-radius: 25px;
      background-color: #03a6ad;
      color: #fff;
    }
    .inactive{
     width: 139.5px;
      height: 30.7px;
      margin: 0 4px 0 4px;
      padding: 8.7px 0.5px 9px 0;
      opacity: 0.81;
      border-radius: 25px;
      background-color: #c6cdd5;
    }
    .Rectangle-1271{
      font-family: Ubuntu;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.83 !important;
      letter-spacing: normal;
      text-align: center;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;    
      z-index: 1;
    }
   
    .Ellipse-154-qna {
      width: 25px;
      height: 25px;
      background-color: #eaeaea;
      text-align: center;
      text-decoration: none;
      border-radius: 50%;
      font-size:14px;
      margin:auto;
    }
    .coming_soon{
      font-family: Ubuntu;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.55;
      letter-spacing: normal;
      text-align: left;
      color: #2C2C48;
    }
    .OP{
      font-family: Ubuntu;
      font-size: 15px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: center;
      color: #676772;
    }
    .queBoxNew {
      position:absolute;
      float:bottom;
      bottom:4vh;
      padding: 0 5vh 0 5vh;
    }
    .Path-359 {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px #ff6750 !important;
      background-color: #fff;
      border-radius: 20px !important;
    }
    .tree-view-item-key {
      color: #03a6ad;;
    }
    .list1:hover{
      background-color:#f9e5de;
    }
    .v-dialog {
      margin-top: 0px !important;
  }
  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
    </style>
  
  
  