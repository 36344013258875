import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import Vuex from "vuex";
import "vuetify/dist/vuetify.min.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import router from "./router";
import Vuebar from "vuebar";
import VueSocialSharing from "vue-social-sharing";
import "./css/style.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import moment from "moment";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueLazyload from "vue-lazyload";
import VueChatScroll from "vue-chat-scroll";
import { VueMasonryPlugin } from "vue-masonry";
import TreeView from "vue-json-tree-view";

Vue.prototype.$User = {};
Vue.prototype.$AuthToken = "";

Vue.prototype.moment = moment;

// Vue.config.errorHandler = err => {
//   console.log('Exception: ', err)
// }

Vue.use(vuetify, {});

Vue.use(BootstrapVue);

Vue.use(IconsPlugin);

Vue.use(Vuex);

Vue.use(VueMaterial);

Vue.use(Vuebar);
Vue.use(VueLazyload);

// Vue.use(VueSocialSharing)
Vue.use(VueSocialSharing, {
  networks: {
    newNetwork:
      "https://newnetwork.com/share?url=@url&title=@title&summary=Knolens,%20Your%20personalized%20digital%20assistant%20that%20automates%20ongoing%20research,%20saving%20you%20time%20and%20money",
  },
});

Vue.use(VueChatScroll);

Vue.use(VueMasonryPlugin);

Vue.use(TreeView);

const mainUrl = window.location;

axios.defaults.baseURL = "http://localhost:20000";

if (
  mainUrl.host == "localhost:8000" ||
  mainUrl.host == "localhost:20000" ||
  mainUrl.host.indexOf("8080") > -1
) {
  axios.defaults.baseURL = "http://localhost:20000";
  console.log("Connected to local");
} else if (mainUrl.host.indexOf("knolens.io") > -1) {
  axios.defaults.baseURL = "https://api.knolens.io";
  console.log("Connected to Staging");
} else {
  axios.defaults.baseURL = "";
  //axios.defaults.baseURL = "https://knolens-hlpc4jxnba-uc.a.run.app"
  // axios.defaults.baseURL = "https://helloworld-hlpc4jxnba-uc.a.run.app"
  console.log("Connected to Production");
}

var firebaseConfig = {
  apiKey: "AIzaSyBpurbr4Ik-u7ZDtKZIwilmMST5zp5qaiQ",
  authDomain: "knolens-firebase.firebaseapp.com",
  projectId: "knolens-firebase",
  storageBucket: "knolens-firebase.appspot.com",
  messagingSenderId: "496408939694",
  appId: "1:496408939694:web:2868e46fbd971a7fb5f13f",
  measurementId: "G-EKCRKGYP36",
};

//check if device is ios or mac

var fcm_supported = firebase.messaging.isSupported();
// check if firebase is supported by browser
if (fcm_supported) {
  // firebaseConfig = {
  //   messagingSenderId: "496408939694",
  //   apiKey: "AIzaSyBpurbr4Ik-u7ZDtKZIwilmMST5zp5qaiQ",
  //   authDomain: "knolens-firebase.firebaseapp.com",
  //   projectId: "knolens-firebase",
  //   // storageBucket: "knolens-firebase.appspot.com",
  //   appId: "1:496408939694:web:2868e46fbd971a7fb5f13f",
  //   // measurementId: "G-EKCRKGYP36",
  // };

  firebase.initializeApp(firebaseConfig);

  const messaging = firebase.messaging();

  messaging.usePublicVapidKey(
    "BHBLzDGTd9zCJmEBuVLFKYj5Kog60HERu1SexnsO59GwWuGYRCnB9CzTbF4H2OYg6QiQaGDkNyfj-THSR01B4Vg"
  );

  Vue.prototype.$messaging = messaging;
}

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
