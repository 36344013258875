<template> 
  <div>
  <loading :active.sync="loading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage" class="loading-overlay"></loading>
  <div class="row margin_align" v-if="!isTabletMob">
     
        <v-row v-if="!loading">
          <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" class="first_sec">
          <div>
            <img src="../assets/Icons/Knolense_Dark-014.svg" style="height: 6vh;margin: 5vh 0vh 2vh 17vh !important;cursor:pointer" @click="goBack()">
          </div>
          <div style="margin-top:15vh;margin-bottom:20vh">
            <v-row v-if="registered==false">          
              <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                <span class="new_login_11">Registration & Verification</span><span class="Ellipse-298"></span>
                <div class="Line-291_0"></div>
              </v-col>
            </v-row>
            <v-row style="margin-top:5vh" v-if="registered==false">
              <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                <span class="new_login">Verification</span><span class="Ellipse-298"></span>
                <div class="Line-291_26"></div>
              </v-col>
            </v-row>
            <v-row :style="registered==false? 'margin-top:5vh' : 'margin-top:25vh'" >
              <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                <span class="payment">Payment</span><span class="Ellipse-297_2"></span>
                <div class="Line-291_22"></div>
              </v-col>
            </v-row>
          </div>
          <!-- <v-row v-if="registered==false" style="bottom:20px;position: fixed;margin-left:4vh;">
            <span class="if-you">If you have an account, Login here</span>
            <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" ></v-col>
            <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" >
              <div><v-btn class="Path-820" @click="go_to_login()">Login</v-btn></div>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" ></v-col>
          </v-row> -->
          </v-col>
          <v-col class="mt-4" cols="12" sm="8" md="8" lg="8" xs="8" xl="8">
            <v-row>
              <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3" ></v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6" >
                <v-card class="trans_Rectangle-1151">
                  <v-row align="center" justify="center">
                    <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3">
                      <img src="../assets/Icons/Group 1331.svg"
                        style="height: 5vh;object-fit: contain;">
                    </v-col>
                    <v-col cols="12" sm="9" md="9" lg="9" xs="9" xl="9">
                    </v-col>
                  </v-row>
                  <div style="margin-top: 2vh;">
                    <span class="Error-Occurred">Transaction Failed !</span>
                    <p class="No-Need-to-Worry">No Need to Worry!</p>
                    <hr style="margin-bottom:0px;border-top:1px solid #ffffff">
                    <!-- <span class="contact_person">Our Customer Service Representative will contact you or you can send an email to</span>
                    <br> -->
                    <p class="No-Need-to-Worry">An error occured while processing your payment.</p>
                    <v-row>
                      <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4">
                        <v-btn class="Path-703" @click="goback()">Try Again</v-btn>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                    </v-row>

                    <v-row style="position:fixed;bottom: 20vh;">
                      <hr style="margin-bottom:0px;border-top:1px solid #ffffff">
                      <v-row>
                        <v-col cols="12" sm="10" md="10" lg="10" xs="10" xl="10">
                          <div>
                            <p class="description1">If the issue persists, contact our support team for assistance.</p>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
                      </v-row>
                      
                      <v-row>
                        <v-col cols="12" sm="5" md="5" lg="5" xs="5" xl="5">
                          <v-btn class="supportknolenscom">support@pienomial.com</v-btn>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6"></v-col>
                      </v-row>                     
                    </v-row>            
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3" ></v-col>
            </v-row>
          </v-col>
        </v-row>
  </div>
  <div class="row" v-else>
    <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
          <div style="background-color:#013d48;margin-top:-4vh">
            <img src="../assets/Icons/Knolense_Dark-01.svg" style="height: 5vh;margin: 3vh 0vh 2vh 2vh !important;cursor:pointer" @click="goToHome()">
          </div>
        </v-col>
      </v-row>
      <v-row style="max-height:80vh;overflow-y: auto;margin:4vh">
        <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12" >
          <v-card class="trans_Rectangle-11513333">
            <v-row align="center" justify="center">
              <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3">
                <img src="../assets/Icons/Group 1331.svg"
                  style="height: 5vh;object-fit: contain;">
              </v-col>
              <v-col cols="12" sm="9" md="9" lg="9" xs="9" xl="9">
                <span class="Error-Occurred">Transaction Failed !</span>
              </v-col>
            </v-row>
                  <div style="margin-top: 2vh;">     
                    <p class="No-Need-to-Worry">No Need to Worry!</p>
                    <hr style="margin-bottom:0px;border-top:1px solid #ffffff">
                    <!-- <span class="contact_person">Our Customer Service Representative will contact you or you can send an email to</span>
                    <br> -->
                    <p class="No-Need-to-Worry">An error occured while processing your payment.</p>
                    <v-row>
                      <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4">
                        <v-btn class="Path-703" @click="goback()">Try Again</v-btn>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                    </v-row>

                    <v-row>
                      <hr style="margin-bottom:0px;border-top:1px solid #ffffff">
                      <v-row>
                        <v-col cols="12" sm="10" md="10" lg="10" xs="10" xl="10">
                          <div>
                            <p class="description1">If the issue persists, contact our support team for assistance.</p>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
                      </v-row>
                      
                      <v-row>
                        <v-col cols="12" sm="5" md="5" lg="5" xs="5" xl="5">
                          <v-btn class="supportknolenscom">support@pienomial.com</v-btn>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6"></v-col>
                      </v-row>                     
                    </v-row>            
                  </div>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </div>
</div>
</template>
  
  <script>
  import axios from "axios";
  import { EventBus } from "../event-bus.js";
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  export default {
    data() {
      return {
        auth_token: '',
        disesase: '',
        loading: true,
        fullPage: true,
        myplan: '',
        user: {},
        registered: false,
      };
    },
    components: {
      Loading,
    },
    created () {
    },
    mounted() {
        var sessionId = this.$route.query.session_id
        var customerId = this.$route.query.customer_id  
        var paymentId = this.$route.query.payid
        var auth_token = localStorage.getItem("authtoken");
        if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
        this.auth_token =  auth_token
        localStorage.setItem("selected_article", "Transaction Failure") 
        this.isRegistered()
        this.updateSubscriptionFailureData(sessionId, customerId, paymentId)
        this.send_failed_email()
        } else {
        this.auth_token = ""
        }
    },
    computed: {
      isTabletMob : function() {
          const iPad = /iPad/i.test(navigator.userAgent);
          const iPhone = /iPhone/i.test(navigator.userAgent);
          const Android = /Android/i.test(navigator.userAgent);
          const webOS = /webOS/i.test(navigator.userAgent);
          const iPod = /iPod/i.test(navigator.userAgent);
          const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
          const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);
          return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
      }, 
    },
    methods: {
      goBack(){
        this.$router.push("/").catch(() => {})
      },
        onCancel() {
         console.log('User cancelled the loader.')
        },
        isRegistered(){
          var activity_name="Activity User Registration"
          axios
          .get("/getactivity?activity_name=" + activity_name)
            .then((res) => {
              console.log(res,"ressssssssss activity")
              if (res.data.Data.activities != null) {
                  this.registered = true
                  
              }
            })
            .catch((error) => {
              console.log(error.message);
            });
        },
        goback() {
            console.log("hiiiii")
            // var disesase = localStorage.getItem("selected_disease")
            // if (disesase !== null && disesase !== "undefined" && disesase !== "") {
            //     disesase = disesase
            // } else {
            //     disesase = "Multiple sclerosis"
            // }
            var disesase="Multiple sclerosis"
            //this.$router.push("/").catch(() => {}) 
            // to redirect to user plan page
            axios
              .get("/userProfile?token=" + this.auth_token)
              .then((res) => {
                if (res.data.Status !== 1) {
                  this.user = res.data.Data;
                  this.myplan = this.user.Plan  
                  console.log(this.myplan,"this.myplan111111")       
                }
              })
              .catch((err) => {
                console.log(err);
              }); 

            setTimeout(() => {

              var plan=localStorage.getItem("plan")
              this.myplan=plan
              if (this.myplan=="4"){
                this.myplan="Basic Plan"
              }
              
               
              this.$router.push('/User_plan_payment/'+this.myplan)
              //remove plan from local storage
              


              this.l1l2_page_data = {}
              this.l1l2_page_data.article_title = "Events"
              this.l1l2_page_data.disease = disesase
              EventBus.$emit("l1l2_page_data", this.l1l2_page_data);
            }, 500);
              
            
        },
        updateSubscriptionFailureData(sessionId, customerId, paymentId){
            if (sessionId){
                axios.put("/updateSubscriptionFailureData?token="+this.auth_token+"&sid="+sessionId+"&cid="+customerId+"&pid="+paymentId)
                .then((res) => {
                    this.loading = false
                    axios.get("/getPaymentUser?token=" + this.auth_token + "&payment_id=" + paymentId)
                      .then((res) => {
                      if (res.data.Status != 1) {
                        var payment = res.data.Data.Payment
                        if (payment.Plan == 0) {
                          this.delete_cart()
                        }                        
                      } else {
                        console.log("Payment not found")
                      }         
                    })
                    .catch((error) => {
                      console.log(error.message);
                      // if (error.message == "Request failed with status code 500") {
                      //   this.logOut()             
                      // }
                    });  
                })
                .catch((error) => {
                    this.loading = false
                    console.log(error.message);
                    // if (error.message == "Request failed with status code 500") {
                    // this.logOut()             
                    // }
                });
            }
        },
        delete_cart() {
          axios.post("/deletediseasecart?token=" + this.auth_token)
          .then((res) => {
            if (res.data.Status != 1) {
              console.log("Cart deletion Successful")
            } else {
              console.log("Cart deletion failed")
            }
          })
          .catch((error) => {
            console.log(error.message);
            // if (error.message == "Request failed with status code 500") {
            //   this.logOut()             
            // }
          });
        },
        logOut() {
            EventBus.$emit("logout", this.auth_token)
            localStorage.removeItem("selectedColorList");
        },
        send_failed_email() {
          axios
            .post("/send_transaction_failed_email?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status != 1) {
                console.log("email send successfully")               
              } else {
                console.log("email failed")
              }
          })
        },
        go_to_login() {
          console.log("go to login")
          this.$router.push("/Login");
        },
    }
  };
  </script>
  
  <style scoped>
    .margin_align {
      overflow-x: hidden;
      overflow-y: hidden;
      height: 100vh !important;
      background-color: #f7f7f7 !important;
    }
  
    .Error-Occurred {
      margin: 0;
      font-family: Ubuntu;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.32;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
  
  
    .description {
      margin: 2vh;
      font-family: Ubuntu;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.89;
      letter-spacing: normal;
      text-align: center;
      color: #676772;
    }
  
    .Path-703 {
      padding: 10px 20px 10px 20px;
      border: solid 1px #fff;
      background-color: #fff;
      font-family: Ubuntu;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: center;
      color: #f16753;
      border-radius: 5px
    }
  
    .Rectangle-1203 {
      margin-top: 5vh;
      padding: 3vh;
      border-radius: 31px;
      box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px #707070;
      background-color: #fff;
    }
  
    .No-Need-to-Worry {
      margin-top:2vh;
      font-family: Ubuntu;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.39;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
  
    .contact_person {
      margin: 2vh;
      font-family: Ubuntu;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.89;
      letter-spacing: normal;
      text-align: center;
      color: #676772;
    }
    .supportknolenscom {
      font-family: Ubuntu;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: center;
      background-color: #f16753 !important;
      color: #ffffff;
      border-radius: 5px;
      border: 1px solid #ffffff;
      padding: 10px 20px 10px 20px;
      box-shadow: none;
    }


  .first_sec {
    height:110vh;
    padding: 10px 40px 10px 10px;
    margin-bottom:5vh;
    background-color: #013d48;
  }
  .Ellipse-297 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #f16753;
    float: right;
    margin-top:6px;
  }
  .Ellipse-297_2 {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #f16753;
    float: right;
    margin-top:6px;
    margin-right:2vh;
  }
  .Ellipse-298 {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #03a6ad;
    float: right;
    margin-top:6px;
    margin-right:2vh;
  }
  .step1 {
    margin-left: 10px;
    font-family: Ubuntu;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #fff;
    float: right;
  }
  .new_login {
    font-family: Ubuntu;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    color: #03a6ad;
    text-align: right !important;
    float:right;
  }
  .new_login_11 {
    font-family: Ubuntu;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    color: #03a6ad;
    text-align: right !important;
    float:right;
  }
  .Line-291 {
    margin-top: 3vh;
    /* margin-left: 200px !important;
    margin-right:-40px !important; */
    margin-right:-40px;
    width: 120% !important;
    height: 3px !important;
    background-color: #f16753;
    float: right;
  }
  .Line-291_0 {
    margin-top: 3vh;
    /* margin-left: 200px !important;
    margin-right:-40px !important; */
    margin-right:-40px;
    width: 120% !important;
    height: 3px !important;
    background-color: #03a6ad;
    float: right;
  }
  .Line-291_26 {
    margin-top: 3vh;
    /* margin-left: 200px !important;
    margin-right:-38px !important; */
    margin-right:-40px;
    width: 120% !important;
    height: 3px !important;
    background-color: #03a6ad;
    float: right;
  }
  .Line-291_22 {
    margin-top: 3vh;
    /* margin-left: 200px !important;
    margin-right:-112px !important; */
    margin-right:-40px;
    width: 120% !important;
    height: 3px !important;
    background-color: #f16753;
    float: right;
  }
  .Line-291_3 {
    margin-top: 3vh;
    /* margin-left: 200px !important;
    margin-right:-38px !important; */
    margin-right:-40px;
    width: 120% !important;
    height: 3px !important;
    background-color: #f16753;
    float: right;
  }
  .Line-291_33 {
    margin-top: 3vh;
    /* margin-left: 200px !important;
    margin-right:-112px !important; */
    margin-right:-40px;
    width: 120% !important;
    height: 3px !important;
    background-color: #f16753;
    float: right;
  }
  .step2 {
    margin-left: 10px;
    font-family: Ubuntu;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #939393;
    float: right;
  }
  .payment {
    font-family: Ubuntu;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    color: #f16753;
    text-align: right !important;
    float:right;
  }
  .if-you {
    margin: 0px 0px 0px 0px !important;
    font-family: Ubuntu;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .Path-820 {
    margin-top:10px;
    padding: 10px 20px 10px 20px;
    background-color: #03a6ad !important;
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color:#ffffff;
    border-radius: 5px !important;
  }
  .welcome {
    font-family: Ubuntu;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #202527;
  }
  .Email_input {
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #49494d;
  }
  .try_again_msg{
    margin: 0px 0px 0px 0px !important;
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left !important;
    color: #03a6ad;
  }
  .Path-822 {
    margin-top: 10px;
    padding: 10px 20px 10px 20px;
    border: solid 1px #f16753;
    background-color: #fff;
    color: #f16753;
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left !important;
  }
  .path_1145 {
    width:33vw;
    margin-top: 2vh;
    padding: 20px;
    background-color: #f5f7f9;
    border-radius: 5px;
  }
  .Confirmation {
    margin-top:-30px;
    margin-left: 5vh;
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #202527;
  }
  .We-have {
    margin: 10px;
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #676772;
  }
  .otpRESEND {
    margin-left: 10px;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #f16753;
    cursor: pointer;
  }
  .step3 {
    margin-left: 10px;
    font-family: Ubuntu;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #03a6ad;
    float: right;
  }

  .Rectangle-1151 {
    margin: 10px 0px 10px 0px;
    padding: 20px 30px 20px 30px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
  }
  .Plan_heading {
    font-family: Ubuntu;
    font-size: 3.5vh;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: left;
    color: #005290;
  }
  .plan_details {
    margin: 2vh 0vh 0vh 2vh;
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #909090;
  }
  .trans_Rectangle-1151 {
    margin: 10px;
    padding: 20px 20px 20px 30px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #f16753;
    height:80vh !important;
    overflow-y: auto;
  }
  .trans_Rectangle-11513333 {
    margin: 10px;
    padding: 20px 20px 20px 30px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #f16753;
  }
  .description1 {
    margin-top:2vh;
    font-family: Ubuntu;
    font-size: 1.8vh;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    word-break: break-all;
  }
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color:#fff; /* Adjust the transparency as needed */
    z-index: 1000; /* Ensure it's above other content */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  </style>
  
  