<template>
  <div>
    <div class="margin_align">
      <div v-if="loading">
        <loading
          :active.sync="loading"
          :can-cancel="true"
          class="loading-overlay"
        ></loading>
      </div>
      <div>
        <iframe
          v-if="email != '' && path != ''"
          style="height: 92vh"
          :src="path"
          width="100%"
          height="100%"
          frameborder="0"
          @load="hideLoadingforothermodule"
        ></iframe>
      </div>
    </div>
    <template>
      <v-dialog v-model="showpopup" width="100%" :retain-focus="false">
        <v-card class="notification_dialog" style="border-radius: 0">
          <div
            style="100%;
        height: 8px;
        margin: 0 0 0px;
        background-color: #029ea5;"
          ></div>
          <v-row style="padding: 1vh; padding-bottom: 2vh">
            <v-col cols="12" aliugn="center">
              <div style="margin-bottom: 1vh">
                <span
                  style="
                    font-family: Ubuntu;
                    font-size: 2.5vh;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.55;
                    letter-spacing: normal;
                    text-align: left;
                    color: #0a5053;
                  "
                >
                  This page is viewed best on a desktop or laptop
                </span>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import { EventBus } from "../event-bus.js";
import Loading from "vue-loading-overlay";
export default {
  data() {
    return {
      email: "",
      token: "",
      showpopup: true,
      pageViewDuration: null,
      path: "",
      module_id: "",
      loading: true,
    };
  },
  components: {
    Loading,
  },
  mounted() {
    var auth_token = localStorage.getItem("authtoken");
    if (
      auth_token !== null &&
      auth_token !== "" &&
      auth_token !== undefined &&
      auth_token !== "undefined"
    ) {
      this.token = auth_token;
      this.module_id = this.$route.query.moduleId;

      // Listen for Flutter to be ready
      window.addEventListener(
        "message",
        (event) => {
          if (event.data === "flutterReady") {
            console.log("flutterReady Event recieved...");
            this.hideLoadingIndicator();
          }
        },
        false
      );

      if (this.isTabletMob == false) {
        this.showpopup = false;
        this.$forceUpdate();
      }
      //emit event to disable close button
      EventBus.$emit("hide_close", true);
      this.getEmail(auth_token);
    }
  },
  computed: {
    isTabletMob: function () {
      const iPad = /iPad/i.test(navigator.userAgent);
      const iPhone = /iPhone/i.test(navigator.userAgent);
      const Android = /Android/i.test(navigator.userAgent);
      const webOS = /webOS/i.test(navigator.userAgent);
      const iPod = /iPod/i.test(navigator.userAgent);
      const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
      const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);

      return (
        iPad ||
        iPhone ||
        Android ||
        webOS ||
        iPod ||
        BlackBerry ||
        Windows_Phone
      );
    },
  },
  methods: {
    hideLoadingIndicator() {
      this.loading = false;
    },
    hideLoadingforothermodule() {
      if (
        this.module_id == "6513d86202de48681550a619" ||
        this.module_id == "66728951f86ef5b9db1ccc98" ||  this.module_id == "66a9c05de4eee7485d3cdea6"
      ) {
        this.loading = false;
      }
    },
    getEmail(auth_token) {
      //create a get request to get email

      axios
        .get("/getEmail?token=" + this.token)
        .then((res) => {
          //console.log(res)
          if (res.data.Status != 1) {
            this.email = res.data.Data.Email;
            //convert email to base64
            this.email = btoa(this.email);
            var at = btoa(auth_token);
            this.path =
              process.env.BASE_URL +
              this.module_id +
              "/web/index.html?email=" +
              this.email +
              "&at=" +
              at;
            // this.path =
            // "https://flutter-web-app.herokuapp.com/" +
            // this.module_id +
            // "/web/index.html";
          } else {
            console.log("not logged in");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.margin_align {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}
.iframe-loading {
  position: relative;
}

/* Style the loading spinner */
.iframe-loading::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #03a6ad;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Animation for the loading spinner */
@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
