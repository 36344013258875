<template>
  <div class="margin_align" style="margin-top:1.5vh;">
        <div>
          <loading
          :active.sync="loading"
          :can-cancel="true"
          :on-cancel="onCancel"
          :is-full-page="fullPage"
          class="loading-overlay"
        ></loading>
        </div>
        <div v-if="!isTabletMob">
          <div class="row"  style="margin-top:6vh">
            <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" class="first_sec">
              <div style="margin-left: 5vh;
                position: absolute;
                top: 30%;">
                  <v-row>          
                    <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                      <!-- <img src="../assets/Icons/Knolense_Dark-014.svg" style="height: 6vh;margin: 5vh 0vh 2vh 17vh !important"> -->
                       <span class="Pricing-Plans_title">My Plan</span>
                        <p class="Get-access">We are delighted to have you on board and hope you have a wonderful experience using knolens!</p>
                    </v-col>
                  </v-row>
              </div>
              
              
              <v-row style="bottom:20px;position: fixed;left:10%"> 
                <v-col align="center">
                  <span style="font-size:2vh;font-family:Ubuntu;color:#ffffff">Contact us</span>
                  <br>
                  <span style="font-size: 2vh;font-family:Ubuntu;color:#f16753"><a style="color:#f16753" href="mailto:support@pienomial.com">support@pienomial.com</a></span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="8" md="8" lg="8" xs="8" xl="8"> 
              <v-row style="margin-top:15vh">
                <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4" >
                  <v-row v-if="!show_data && !loading && noplan_true" style="margin-top: 3vh;margin-bottom: 3vh;">
                    <v-row>
                      <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                      <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4" align="center" justify="center">
                      <div class="Ellipse-153"><img src="../assets/Icons/Path 827.svg" style="height: 40px;object-fit: contain;"></div>
                      </v-col>
                      <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                    </v-row>
                    <p style="text-align: center;font-size: 18px;font-family: Ubuntu;">It seems like your subscription is empty!</p>
                  </v-row>
                  <div v-else class="Rectangle-1151" :style="isTabletMob?'max-height:70vh':''" >  
                  <div>
                    <v-row  v-if="planData.Current_plan == 'Basic Plan' || planData.Current_plan == 'My Team Plan'">
                      <v-col>
                      <v-row>
                        <v-col col="12" sm="3" md="3" lg="3" xs="3" xl="3">
                          <img src="../assets/images/Group 1339.png" style="width:70px;margin-top:-1vh;margin-left:2vh">
                        </v-col>
                        <v-col col="12" sm="9" md="9" lg="9" xs="9" xl="9">
                          <span class="Plan_heading">
                            Starter Plan
                          </span>                          
                        </v-col>
                      </v-row>
                      <v-row>
                        <div class="">
                          <div v-for="(paym, kpa) in current_payments" :key="kpa">
                            <v-row style="margin-left:1vh;margin-top:2vh">
                              <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                                <div class="Active" v-if="paym.Active"> Active</div>
                                <div class="Active" v-if="!paym.Active">Deactive</div>
                              </v-col>
                            </v-row>
                            <hr class="dotted">
                            <v-row style="margin-top:1.5vh;margin-left:1vh">
                              <v-col col="12" sm="6" md="6" lg="6" xs="6" xl="6">
                                <span class="start_date">Plan Start Date</span>
                              </v-col>
                              <v-col col="12" sm="6" md="6" lg="6" xs="6" xl="6">
                                <span class="start_date">Plan End Date</span>
                              </v-col>
                            </v-row>
                            <v-row style="margin-top:-1vh;margin-left:1vh;" >
                              <v-col col="12" sm="6" md="6" lg="6" xs="6" xl="6">
                                <span class="plan_date">{{paym.start_date}}</span>
                              </v-col>
                              <v-col col="12" sm="6" md="6" lg="6" xs="6" xl="6">
                                <span class="plan_date">{{paym.end_date}}</span>
                              </v-col>
                            </v-row>
                            <hr class="dotted" style="margin-bottom:2vh !important">
                            <div >
                              <p class="plan_details" >All Diseases Included</p>
                              <p class="plan_details">
                                The starter plan gives you unlimited access to KnolChat.
                                <br><br>
                                Access to Overview by Indications Analysis Module
                              </p>
                              <hr  class="dotted">
                              <v-row  style="margin:2vh 0">
                                <v-col col="12" align="center">
                                  <span id="download_receipt" v-show="user.Payment_receipt_key!='' && user.Payment_receipt_key!=undefined" @click="downloadReceipt()" class="Active" style="cursor:pointer" >Download Payment Receipt</span>
                                </v-col>
                              </v-row>
                              
                            </div>
                          </div>
                        </div>
                      </v-row>
                    </v-col>
                    </v-row>
                  </div>
                </div>   
                </v-col>
                <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
              </v-row>   
            </v-col> 
            </div> 
        
        </div>
        <div v-if="isTabletMob">
          <div class="row">
            <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
              <div style="padding: 8vh 8vh; margin-top: 7vh; display: flex; flex-direction: column; align-items: center;">
        
                <!-- If subscription is empty -->
                <div v-if="show_data==false && loading==false && noplan_true" style="display: flex; flex-direction: column; align-items: center; margin-top: 3vh; margin-bottom: 3vh;">
                  <div style="flex: 1;"></div>
                  <div style="display: flex; align-items: center;">
                    <div class="Ellipse-153" style="margin-right: 8px;"><img src="../assets/Icons/Path 827.svg" style="height: 40px; object-fit: contain;"></div>
                  </div>
                  <div style="flex: 1;"></div>
                  <p style="text-align: center; font-size: 18px; font-family: Ubuntu;">It seems like your subscription is empty!</p>
                </div>
        
                <!-- If subscription data is available -->
                <div class="Rectangle-1151" v-else>
                  <div v-if="planData.Current_plan == 'Basic Plan' || planData.Current_plan == 'My Team Plan'" style="display: flex; flex-direction: column; align-items: center;">
        
                    <div style="display: flex; align-items: center;">
                      <img src="../assets/images/Group 1339.png">
                      <span class="Plan_heading">Starter Plan</span>
                    </div>
        
                    <div v-for="(paym, kpa) in current_payments" :key="kpa" style="margin-left: 1vh; margin-top: 2vh;">
                      <div style="display: flex;">
                        <div style="flex: 1;"></div>
                        <div class="Active" v-if="paym.Active">Active</div>
                        <div class="Active" v-if="!paym.Active">Deactive</div>
                        <div style="flex: 1;"></div>
                      </div>
                      <hr class="dotted">
                      <br>
        
                      <div style="display:flex;flex-direction:column;align-items:flex-start;justify-content:space-between">
                        <span class="start_date">Plan Start Date: {{ paym.start_date }}</span>
                        <span style="margin-top:2vh" class="start_date">Plan End Date: {{ paym.end_date }}</span>
                      </div>
        
                     
        
                      <hr class="dotted" style="margin-bottom: 2vh !important">
        
                      <div>
                        <p class="plan_details" style="margin:0vh;">All Diseases Included</p>
                        <br>
                        <p class="plan_details" style="margin:0vh">
                          The starter plan gives you unlimited access to KnolChat.
                          <br><br>
                          Access to Overview by Indications Analysis Module
                        </p>
                        <hr class="dotted">
                        <br>
                        <div style="display:flex;align-items:center;justify-content:center">
                          <span id="download_receipt" v-show="user.Payment_receipt_key!='' && user.Payment_receipt_key!=undefined" @click="downloadReceipt()" class="Active" style="cursor:pointer">Download Payment Receipt</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </div>
        </div>

        <template>
          <div class="text-center">
            <v-dialog
              v-model="msg_modal"
              width="600"
            >
            <v-card class="Path-359" style="padding:3vh 2vh 3vh 2vh">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                    <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;margin-right: 3vh;">
                    <span class="Active_Companies_title">Unsubscribe !</span>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                    <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="msg_modal = false">mdi-close</v-icon>
                  </v-col>
                </v-row>
                <v-row wrap>
                                        
                  <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                    <!-- <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;"> -->
                    <span class="com_nm1" style="margin-left:0vh">Are you sure you want to unsubscribe from this plan? Once unsubscribed, the features associated with this plan will no longer be available to you.</span>
                  </v-col>
                </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-row>
                    <v-col sm="6" md="6" lg="6" xs="6" xl="6"></v-col>
                    <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                      <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh;" @click="confirm()">Yes</v-btn>
                    </v-col>
                    <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                      <v-btn style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 2.2vh;font-family: Ubuntu;" @click="msg_modal = false">No
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>  
              </v-card>
            </v-dialog>
          </div>
      </template>
      <template>
          <div class="text-center">
            <v-dialog
              v-model="show_extra_info_popup"
              width="600"
            >
            <v-card class="Path-359" style="padding:3vh 2vh 3vh 2vh">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                    <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;margin-right: 3vh;">
                    <span class="Active_Companies_title">Unsubscribe !</span>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                    <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="show_extra_info_popup = false">mdi-close</v-icon>
                  </v-col>
                </v-row>
                <v-row wrap>
                                        
                  <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                    <!-- <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;"> -->
                    <span class="com_nm1" style="margin-left:0vh">By unsubscribing from your current plan, your Add-On plans will also be unsubscribed. As a result, the respective features of these plans will be no longer available. Are you sure you want to proceed?</span>
                  </v-col>
                </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-row>
                    <v-col sm="6" md="6" lg="6" xs="6" xl="6"></v-col>
                    <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                      <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh;" @click="confirm_parent_plan()">Yes</v-btn>
                    </v-col>
                    <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                      <v-btn style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 2.2vh;font-family: Ubuntu;" @click="show_extra_info_popup = false">No
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>  
              </v-card>
            </v-dialog>
          </div>
      </template>    
    </div>
</template>
<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { EventBus } from "../event-bus.js";
export default {
  data() {
    return {
      auth_token: "",
      loading: true,
      fullPage: true,
      user_email: '',
      show_data: false,
      planData: {},
      disesase_count: 0,
      start_date: '',
      end_date: '',
      plan_count: 0,
      payments: [],
      plan_diseases: 0,
      trial_disease: '',
      current_payments: [],
      subscribed_diseases: [],
      subscription_id: '',
      msg_modal: false,
      diseases_all: [],
      show_team: false,
      show_extra_info_popup: false,
      noplan_true: false,
    };
  },
  components: {
    Loading,
  },
  computed: {
    isTabletMob : function() {

      const iPad = /iPad/i.test(navigator.userAgent);
      const iPhone = /iPhone/i.test(navigator.userAgent);
      const Android = /Android/i.test(navigator.userAgent);
      const webOS = /webOS/i.test(navigator.userAgent);
      const iPod = /iPod/i.test(navigator.userAgent);
      const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
      const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);

      return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
    }

  },
  mounted() {
    //console.log("profile setting")

    // var disesase = localStorage.getItem("selected_disease")
    // if (disesase !== null && disesase !== "undefined" && disesase !== "") {
    //   this.disesase = disesase
    // } else {
    //   this.disesase = "Multiple sclerosis"
    // }
    console.log(this.isTabletMob,"view")

    var auth_token = localStorage.getItem("authtoken");
    EventBus.$emit("module_name","" );
    localStorage.setItem("selected_module","")
    
    if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
      this.auth_token = auth_token
      this.get_page_activity()
      this.getdiseases()
      this.get_subscription_info()
    }
  },
  methods: {
    onCancel() {
      console.log('User cancelled the loader.')
    },
    gotohome() {
      this.$router.push("/").catch(() => {}) 
    },
    get_subscription_info() {
      // this.loading = true
      this.planData = {}
      axios.get("/getUserPlans?token=" + this.auth_token)
      .then((res) => {
          if (res.data.Status !== 1) {
            if (res.data.Data != undefined) {
              this.planData = res.data.Data
              
              //console.log(this.planData,"this.planData")
              if (this.planData.Current_plan == "Trial") {
                if (this.planData.Diseases.length > 0) {
                  this.show_data = true
                  this.disesase_count = 1
                  this.plan_diseases = 1
                  this.plan_count = 1
                  this.trial_disease = this.planData.Diseases[0]
                  this.getuser()
                } else {
                  this.show_data = false
                  this.noplan_true=true
                }
             
              } else {
                this.getuser()
                if (this.planData.Current_plan == "No Plan") {
                  this.show_data = false       
                  this.noplan_true=true       
                } else {
                  this.get_other_plans()
                }
              }

             //wait for 200ms to make loading false
              setTimeout(() => {
                this.loading = false
              }, 200);
              this.$forceUpdate()
            }
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            setTimeout(() => {
                this.loading = false
              }, 200);
            console.log("Invalid User")
          } else {
            setTimeout(() => {
                this.loading = false
              }, 200);
            this.noplan_true=true
            console.log("User plans not found");         
          } 
      })           
      .catch((error) => {
        setTimeout(() => {
                this.loading = false
              }, 200);
          this.noplan_true=true
          console.log(error.message);
          // if (error.message == "Request failed with status code 500") {
          //   this.logOut()             
          // }
      });
    },
    getuser() {
      axios
        .get("/userProfile?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status != 1) {
            this.user = res.data.Data;
            //console.log(this.user,"this.user")
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
            var start_dt = new Date(this.user.Plan_started),
            month = '' + (start_dt.getMonth() + 1),
            day = '' + start_dt.getDate(),
            year = start_dt.getFullYear();
            this.start_date = [day, monthNames[start_dt.getMonth()], year].join('-')
            var end_dt = new Date(this.user.Plan_started),
            e_month = '' + (end_dt.setMonth(end_dt.getMonth()+1)),
            e_day = '' + (end_dt.getDate()),
            e_year = end_dt.getFullYear();
            this.end_date = [e_day, monthNames[end_dt.getMonth()], e_year].join('-')

            //this.check_user_team()

          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
            this.logOut()
          } else {
            console.log("User not found")
          }
      })
      .catch((error) => {
        console.log(error.message);
        if (error.message == "Request failed with status code 500") {
          this.logOut()             
        }
      });
    },
    get_other_plans() {
      this.payments = []
      this.current_payments = []
      axios.get("/getUserPayments?token=" + this.auth_token + "&plan=" + this.planData.Current_plan)
      .then((res) => {
          if (res.data.Status !== 1) {
            //console.log(res.data.Data.Payments,"res.data.Data.Payments")
            if (res.data.Data.Payments != null) {
              this.show_data = true
              this.payments = res.data.Data.Payments
              //console.log(this.payments,"this.payments")
              const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
              for (var i = 0; i < this.payments.length; i++){
                if (this.payments[i].Active == true && this.payments[i].Unsubscribed == false) {
                  var start_dt = new Date(this.payments[i].Starts_from),
                  month = '' + (start_dt.getMonth() + 1),
                  day = '' + start_dt.getDate(),
                  year = start_dt.getFullYear();
                  var start_date = [day, monthNames[start_dt.getMonth()], year].join('-')
                  var end_dt = new Date(this.payments[i].Ends_at),
                  e_month = '' + (end_dt.getMonth() + 1),
                  e_day = '' + end_dt.getDate(),
                  e_year = end_dt.getFullYear();
                  var end_date = [e_day, monthNames[end_dt.getMonth()], e_year].join('-')
                  this.payments[i].start_date = start_date
                  this.payments[i].end_date = end_date
                  this.current_payments.push(this.payments[i])
                }            
              }

              this.plan_count = this.current_payments.length

              console.log(this.current_payments,"this.current_payments")

                axios.get("getuserdiseases/?token=" + this.auth_token)
                  .then((res) => {
                      if (res.data.Status !== 1) {
                        //console.log(res.data.Data,"datatat")
                        if (res.data.Data != undefined) {
                          var userdiseases = res.data.Data.userdiseases
                          for (var p = 0; p < this.current_payments.length; p++) {
                            var diseases = []
                            for (var d = 0; d < userdiseases.length; d++) {
                              if (this.current_payments[p].ID == userdiseases[d].Payment_id) {
                                diseases.push(userdiseases[d].Disease)
                                this.current_payments[p].mydiseases = diseases
                              }
                            }
                          }
                          this.plan_diseases = 0
                          if (this.current_payments !== undefined) {
                            for (var i = 0; i < this.current_payments.length; i++) {
                              if (this.current_payments[i].mydiseases !== undefined) {
                                this.current_payments[i].disease_count = this.current_payments[i].mydiseases.length
                                this.plan_diseases = this.plan_diseases + this.current_payments[i].mydiseases.length
                              }

                              if (this.diseases_all.length == this.current_payments[i].mydiseases.length) {
                                this.current_payments[i].All_diseases_present = true
                              } else {
                                this.current_payments[i].All_diseases_present = false
                              }
                            }
                            //console.log(this.plan_diseases,"plan_diseases")

                          }
                          this.$forceUpdate()
                          
                        }
                      } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
                        console.log("Invalid User")
                      } else {
                        console.log("Diseases not found");         
                      } 
                  })           
                  .catch((error) => {
                      console.log(error.message);
                      // if (error.message == "Request failed with status code 500") {
                      //   this.logOut()             
                      // }
                  });

                  if (this.planData.Current_plan == 'My Team Plan') {
                    this.plan_count = 1
                    this.get_payment_user()
                  }
            } else {
              this.show_data = false
              this.noplan_true=true
            }
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
          } else {
            console.log("Dates not found");         
          } 
      })           
      .catch((error) => {
          console.log(error.message);
          // if (error.message == "Request failed with status code 500") {
          //   this.logOut()             
          // }
      });
    },
    firstmover_unsubscribe_plan(type, sub_id, my_dis) {
      console.log(type,"type")
      //console.log(this.current_payments.length,"lenn")
      if (type == 0 || (type == 1 && my_dis.length == 3)) {
          // check other active payment plans
          if(this.current_payments.length > 1) {
            console.log("11111")         
            for( var c = 0 ; c < this.current_payments.length; c++) {
                if (this.current_payments[c].Stripe_subscription_id == sub_id && (this.current_payments[c].Subplan == 0 || this.current_payments[c].Subplan == 1)) {
                  if (this.current_payments.length > 1) {
                    this.show_extra_info_popup = true
                    this.subscription_id = sub_id
                    this.subscribed_diseases = my_dis
                  }
                }
            }
          } else {
            console.log("22222")         
            this.subscription_id = sub_id
            this.subscribed_diseases = my_dis
            this.msg_modal = true
          }        
      } else {
        console.log("33333")        
        this.subscription_id = sub_id
        this.subscribed_diseases = my_dis
        this.msg_modal = true
      }
    },
    confirm_parent_plan() {
       //console.log(this.subscription_id,this.subscribed_diseases,"delete parent plan 444444")
       this.show_extra_info_popup = false
       this.confirm()
    },
    unsubscribe_plan(sub_id,my_dis) {
      this.subscription_id = sub_id
      this.subscribed_diseases = my_dis
      this.msg_modal = true
    },
    confirm() {
      // console.log(this.subscription_id,"subid")
      // console.log(this.subscribed_diseases,"my_dis")
      this.msg_modal = false
      this.loading = true
      axios.post("/unsubscribePlan?token=" + this.auth_token + "&subscription_id=" + this.subscription_id + "&diseases=" + this.subscribed_diseases)
      .then((res) => {
        if (res.data.Status !== 1) {
          this.loading = false
          if (res.data.Data.Message == "Success") {
            this.get_subscription_info() 
            //emit event updateProfile
            EventBus.$emit("updateprofile", true);
            //location.reload()   
          }        
        } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
          console.log("Invalid User")
        } else {
          this.loading = false
          console.log("Plan unscription failed");         
        } 
      })
      .catch((error) => {
          console.log(error.message);
          // if (error.message == "Request failed with status code 500") {
          //   this.logOut()             
          // }
      });

    },
    goto_teams() {
      this.$router.push("/plan_users").catch(() => {})
    },
    getdiseases() {
      this.diseases_all = []
      axios
        .get("/getalldiseases?token=" + this.auth_token)
        .then((res) => {
          //console.log(res.status,"ressss")
          if (res.data.Status != 1) {
            if (res.data.Data != undefined) {
              var alldis = []
              for (var i = 0 ; i < res.data.Data.length; i++) {
                alldis.push(res.data.Data[i].Name)
              }             
              this.diseases_all = alldis
            } 

          }
      }) 
      .catch((error) => {
          console.log(error.message);
          // if (error.message == "Request failed with status code 500") {
          //   this.logOut()             
          // }
      });   
    },
    get_payment_user(){
      //console.log(this.current_payments,"current_payments")
      if (this.current_payments != undefined) {
        for (var j = 0; j < this.current_payments.length; j++) {
          if (this.current_payments[j].Plan == 1) {
            var pay_id = this.current_payments[j].ID

            axios.get("/getPaymentUser?token=" + this.auth_token + "&payment_id=" + pay_id)
              .then((res) => {
              if (res.data.Status != 1) {
                //console.log(res.data.Data,"res.data.Data")
                if (res.data.Data.Message == "Success") {
                  this.show_team = true                  
                }
              } else {
                console.log("Payment not found")
              }         
            })
            .catch((error) => {
              console.log(error.message);
              // if (error.message == "Request failed with status code 500") {
              //   this.logOut()             
              // }
            });  
          }
        }
      } 
    },
    logout() {
      localStorage.removeItem("authtoken");
      localStorage.removeItem("selectedColorList");
      // localStorage.setItem("selected_article", "Industry Updates");
      this.disesase = "Multiple sclerosis"
      delete axios.defaults.headers.common["Authorization"];
      this.auth_token = null;        

      this.$router.push("/").catch(() => {})
    },
    contactUs(){
      let route = this.$router.resolve({path: '/EnterprisePlan'});
      // let route = this.$router.resolve('/link/to/page'); // This also works.
      window.open(route.href, '_blank');
    },
    downloadReceipt(){
    //create get request to get s3link
    axios.get("/getPaymentReceipt?token=" + this.auth_token+"&attchment="+"receipt")
    .then((res) => {
      if (res.data.Status != 1) {
        fetch(res.data.Data.Url)
          .then(response => response.blob()) // Convert response to Blob
          .then(blob => {
            console.log(res.data.Data.Url,"urlll");
            var a = document.createElement("a");
        
            document.body.appendChild(a);
            a.style = "display: none";
            var url = window.URL.createObjectURL(blob);
            

            a.href = url;
            a.download = "PaymentReceipt.pdf";
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch(() => console.log("Error downloading the file"));
      } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
        console.log("Invalid User");
      } else {
        console.log("Article link not found");
      }
    })
    .catch((error) => {
      console.log(error.message);
    });
  
    },
    get_page_activity(){
    axios
            .get("/fetchIndicationSelected?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status != 1) {
                console.log(res.data,"response.data")
                this.disesase = res.data.Data.Indication
                this.$forceUpdate();          
              } 
          })
  },
  },
};
</script>
<style scoped>
  .margin_align {
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100vh !important;
  }
  .wholepage {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 20px;
    padding-top: 0px;
    margin: 0vh 0vh 5vh 5vh !important;
    overflow-y: auto
  }

  .BACK {
    margin: 2vh;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.43;
    letter-spacing: normal;
    text-align: left;
    color: #03a6ad;
    cursor: pointer;
  }
  .mycart {
    margin: 2vh;
    font-family: Ubuntu;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #32324a;
  }
  .Ellipse-153 {
    width: 90px;
    height: 90px;
    margin: 2vh;
    padding: 25px;
    border-radius: 90px;
    border: solid 1px #d6d6d6;
    background-color: #f8f8f8;
  }
  .subscription_plans{
    font-family: Ubuntu;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: center;
    background-color: #f16753 !important;
    color: #fff;
    margin-left:2vh;
    padding:2vh;
    border-radius: 7px;
  
  }
  .subscribed_diseases{
    font-family: Ubuntu;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: center;
    background-color: #fff !important;
    color:  #f16753;
    padding:1.9vh;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    border-right: solid 1px #f16753;
    border-top: solid 1px #f16753;
    border-bottom: solid 1px #f16753;
  }
  .Path-822 {
    margin: 0vh -2vh 2vh 2vh;
    padding: 2vh;
    border: solid 1px #03a6ad;
    background-color: #fff;
    border-radius: 10px;
  }
  .Active{
    font-family: Ubuntu;
    font-size: 2vh;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: #03a6ad;
  }
  .Platinum-Plan {
    margin-top: 2vh;
    font-family: Ubuntu;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: normal;
    text-align: left;
    color: #202527;
  }
  .start_date{
    font-family: Ubuntu;
    font-size: 1.8vh;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #727272;
  }
  .plan_date {
    font-family: Ubuntu;
    font-size: 1.8vh;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #202527;
  }
  .disease_included{
    margin-top:1vh;
    font-family: Ubuntu;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 4.17;
    letter-spacing: normal;
    text-align: left;
    color: #727272;
  }
  .disease_name {
    font-family: Ubuntu;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.79;
    letter-spacing: normal;
    text-align: left;
    color: #202527;
  }
  .Path-878 {
    margin-top: 3vh;
    padding: 1vh;
    background-color: #f5f7f9;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .Path-820 {
    padding: 1vh;
    border: solid 1.4px #f16753;
    background-color: #fff !important;
    box-shadow: none;
    color:#f16753;
    font-size: 14px;
    font-weight: normal;
    border-radius: 5px;
  }

  .Active_Companies_title {
    margin: 2vh 2vh 2vh 0vh;
    font-family: Ubuntu;
    font-size: 2.8vh;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: normal;
    text-align: left;
    color: #ff6750;
  }

  .Path-359 {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ff6750 !important;
    background-color: #fff;
    border-radius: 20px !important;
  }

  .com_nm1 {
    margin-top: 5vh;
    font-size: 16px;
    font-weight: 700;
    font-family: Ubuntu;
    text-align: center;
    line-height: 1.50;
  }


  .Rectangle-1151 {
      margin: 10px 0px 10px 0px;
      padding: 20px 20px 20px 20px;
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
     
      overflow-y: auto ;
      overflow-x: hidden;
    }
    .Plan_heading {
      font-family: Ubuntu;
      font-size: 3.5vh;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.32;
      letter-spacing: normal;
      text-align: left;
      color: #005290;
    }
    .plan_details {
      margin: 2vh 0vh 0vh 2vh;
      font-family: Ubuntu;
      font-size: 1.8vh;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
      color: #909090;
    }

    .Path-820_1 {
      margin-top:5px;
      padding: 10px 30px 10px 30px;
      background-color: #03a6ad !important;
      font-family: Ubuntu;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color:#ffffff;
      border-radius: 5px !important;
      font-size:1.7vh;
      margin-top:5px
    }

    .supportknolenscom {
      font-family: Ubuntu;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: center;
      background-color: #f16753 !important;
      color: #ffffff;
      border-radius: 5px;
      border: 1px solid #ffffff;
      padding: 10px 20px 10px 20px;
      box-shadow: none;
    }
    .first_sec {
      height:110vh;
  
      padding: 10px 40px 10px 10px;
      margin-bottom:5vh;
      background-color: #013d48;
    }
    .Ellipse-297 {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #f16753;
      float: right;
      margin-top:6px;
    }
    .Ellipse-297_2 {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #f16753;
      float: right;
      margin-top:6px;
      margin-right:2vh;
    }
    .Ellipse-298 {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #03a6ad;
      float: right;
      margin-top:6px;
      margin-right:2vh;
    }
    .step1 {
      margin-left: 10px;
      font-family: Ubuntu;
      font-size: 13px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #fff;
      float: right;
    }
    .new_login {
      font-family: Ubuntu;
      font-size: 25px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.15;
      letter-spacing: normal;
      color: #03a6ad;
      text-align: right !important;
      float:right;
    }
    .new_login_11 {
      font-family: Ubuntu;
      font-size: 25px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.15;
      letter-spacing: normal;
      color: #03a6ad;
      text-align: right !important;
      float:right;
    }
    .Line-291 {
      margin-top: 3vh;
      /* margin-left: 200px !important;
      margin-right:-40px !important; */
      margin-right:-40px;
      width: 120% !important;
      height: 3px !important;
      background-color: #f16753;
      float: right;
    }
    .Line-291_0 {
      margin-top: 3vh;
      /* margin-left: 200px !important;
      margin-right:-40px !important; */
      margin-right:-40px;
      width: 120% !important;
      height: 3px !important;
      background-color: #03a6ad;
      float: right;
    }
    .Line-291_26 {
      margin-top: 3vh;
      /* margin-left: 200px !important;
      margin-right:-38px !important; */
      margin-right:-40px;
      width: 120% !important;
      height: 3px !important;
      background-color: #03a6ad;
      float: right;
    }
    .Line-291_22 {
      margin-top: 3vh;
      /* margin-left: 200px !important;
      margin-right:-112px !important; */
      margin-right:-40px;
      width: 120% !important;
      height: 3px !important;
      background-color: #f16753;
      float: right;
    }
    .Line-291_3 {
      margin-top: 3vh;
      /* margin-left: 200px !important;
      margin-right:-38px !important; */
      margin-right:-40px;
      width: 120% !important;
      height: 3px !important;
      background-color: #f16753;
      float: right;
    }
    .Line-291_33 {
      margin-top: 3vh;
      /* margin-left: 200px !important;
      margin-right:-112px !important; */
      margin-right:-40px;
      width: 120% !important;
      height: 3px !important;
      background-color: #f16753;
      float: right;
    }
    .step2 {
      margin-left: 10px;
      font-family: Ubuntu;
      font-size: 13px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #939393;
      float: right;
    }
    .verification {
      font-family: Ubuntu;
      font-size: 25px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.15;
      letter-spacing: normal;
      color: #f16753;
      text-align: right !important;
      float:right;
    }
    .if-you {
      margin: 0px 0px 0px 0px !important;
      font-family: Ubuntu;
      font-size: 2vh;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
    .Pricing-Plans_title {
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      font-family: Ubuntu;
      font-size: 3vh;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.32;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
    .Get-access{
      margin-top:5vh;
      font-family: Ubuntu;
      font-size: 2.1vh;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.64;
      letter-spacing: normal;
      text-align: left;
      color: #d0d0d0;
      margin-right:10vh
    }
    .loading-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color:#fff; /* Adjust the transparency as needed */
      z-index: 1000; /* Ensure it's above other content */
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
</style>
        