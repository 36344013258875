<template>
  <div :style="!isTabletMob ? 'overflow-y: auto;height: 100vh !important;background-color: #f8fafb;' : 'overflow-y: hidden;height: 100%;position:fixed;background-color: #f8fafb;padding-bottom:10vh'">    
      <div class="appLayout">
        <div style="margin-top:10vh !important">
          <loading
            :active.sync="loading"
            :can-cancel="true"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
            class="loading-overlay"
          ></loading>
          <div>
            <v-row>
              <v-col  v-if="!isTabletMob" cols="12" md="3" lg="3" sm="3"> </v-col>
              <!-- <v-col  v-if="isTabletMob" cols="12" md="1" lg="1" sm="1"
              style="padding:4vh 0vh 2vh 5vh;margin-right: 8vh;"
              @click="goToHome()">
                <v-icon
                  color="#ff6750"
                  style="text-align: center"
                >
                  mdi-arrow-left
                </v-icon>
               </v-col> -->
              <v-col cols="12" md="6" lg="6" sm="6">
                <v-row>
                  <v-col
                  v-if="!isTabletMob"
                    cols="12"
                    md="4"
                    lg="4"
                    sm="4"
                    style="text-align: left; padding-left: 3.2vh;cursor: pointer;"
                    @click="goToHome()"
                  >
                    <v-icon
                      v-if="!isTabletMob"
                      color="#ff6750"
                      style="text-align: center"
                      
                    >
                      mdi-arrow-left
                    </v-icon>

                    <span
                      v-if="!isTabletMob"
                      style="
                        font-size: 19px;
                        vertical-align: middle;
                        color: #ff6750;
                        padding-left: 6px;
                        font-weight: 500;
                      "
                      >Back</span
                    >
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    lg="4"
                    sm="4"
                    class="align-center justify-center"
                    :style="!isTabletMob ? '' : 'width:auto;margin-left:18vh'"
                    style=""
                  >
                    <h4
                      style="
                        text-align: center;
                        font-weight: 500;
                        letter-spacing: 0.05px;
                        font-size: 20px;
                      "
                    >
                      Update Profile
                    </h4>
                  </v-col>
                  <v-col v-if="!isTabletMob" cols="12" md="4" lg="4" sm="4"> </v-col>
                </v-row>
              </v-col>
              <v-col v-if="!isTabletMob" cols="12" md="3" lg="3" sm="3"> </v-col>
            </v-row>
          </div>
          <v-row :style="isTabletMob?'max-height:60vh;overflow-y:scroll':''">
            <v-col v-if="!isTabletMob" cols="12" md="3" lg="3" sm="3"> </v-col>
            <v-col v-if="!isTabletMob" cols="12" md="6" lg="6" sm="6"  style="padding:2vh 2vh">
              <v-card
                class="mt-1 mb-4"
                style="padding: 3vh ;0vh 5vh 0vh;font-size: 17px;"
              >
                <div style="font-family: Ubuntu;" >
                  <v-row > 
                    <!-- display user email -->
                    <v-col cols="12" md="4" lg="4" sm="4" >
                      <div class="label" style="font-size: 17px;padding-top:3vh">
                        Email :
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" sm="6">
                      <v-card class="pa-4" outlined>
                        {{ email }}
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <!-- display user email -->
                    <v-col cols="12" md="4" lg="4" sm="4">
                      <div class="label" style="font-size: 17px;padding-top:3vh">
                        Name :
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" sm="6">
                      <v-card class="pa-4" outlined>
                        {{ name }}
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4" lg="4" sm="4">
                      <div class="label" style="font-size: 17px;padding-top:3vh">
                        Company Name :
                      </div>
                    </v-col>
                   
                    <v-col cols="12" md="6" lg="6" sm="6">
                      <v-card
                        class="pa-4"
                        outlined
                        v-if="!comp_edit && !oth_comp_flag"
                      >
                        <span
                          style="text-transform: capitalize !important"
                          v-show="company_name !== 'Other'"
                          >{{ company_name }}</span
                        >
                        <span
                          style="text-transform: capitalize !important"
                          v-show="company_name == 'Other'"
                          >{{ other_company }}</span
                        >
                      </v-card>
                      <v-select
                        v-if="comp_edit"
                        class="mt-2"
                        height="40"
                        style="text-transform: capitalize"
                        outlined
                        v-model="company_name"
                        :items="companies"
                        attach
                        label=""
                        @change="onChangeCompany(company_name)"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item>
                            <v-list-item-content>
                              <v-text-field
                                v-model="searchComp"
                                placeholder="Search"
                                @input="searchCompany"
                              >
                              </v-text-field>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider style="margin-top: -2vh"></v-divider>
                        </template>
                      </v-select>
                      <div v-show="oth_comp_flag">
                        <v-text-field
                          class="mt-2"
                          v-model="other_company"
                          type="text"
                          placeholder="Enter Company Name"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="12" md="2" lg="2" sm="2">
                      <v-icon style="margin-top: 2vh" @click="comp_edit = true"
                        >edit</v-icon
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4" lg="4" sm="4">
                      <div class="label" style="font-size: 17px;padding-top:3vh">
                        Position :
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" sm="6">
                      <v-card
                        class="pa-4"
                        outlined
                        v-show="!pos_edit && !oth_posi_flag"
                      >
                        <span v-if="position_name !== 'Other'">{{
                          position_name
                        }}</span>
                        <span v-if="position_name == 'Other'">{{
                          other_position
                        }}</span>
                      </v-card>
                      <v-select
                        v-if="pos_edit"
                        class="mt-2"
                        height="40"
                        style="text-transform: capitalize"
                        outlined
                        v-model="position_name"
                        :items="positions"
                        attach
                        label=""
                        @change="onChangePosition(position_name)"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item>
                            <v-list-item-content>
                              <v-text-field
                                v-model="searchTerm"
                                placeholder="Search Position"
                                @input="searchPosition"
                              >
                              </v-text-field>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider style="margin-top: -2vh"></v-divider>
                        </template>
                      </v-select>
                      <div v-show="oth_posi_flag">
                        <v-text-field
                          class="mt-2"
                          v-model="other_position"
                          type="text"
                          placeholder="Enter Position"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="12" md="2" lg="2" sm="2">
                      <v-icon style="margin-top: 2vh" @click="pos_edit = true"
                        >edit</v-icon
                      >
                    </v-col>
                  </v-row>
                 
                  <v-row v-show="user.Agreement_key!='' && user.Agreement_key!=undefined">
                    <v-col align="center">
                      <span @click="downloadReceipt()" class="Active" style="cursor:pointer" >Download Agreement Copy</span>
                    </v-col>
                  </v-row>

                  <!-- <v-row v-show="my_diseases.length > 0">
                    <v-col cols="12" md="4" lg="4" sm="4">
                      <div class="label" style="font-size: 17px">
                        Selected Diseases :
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" sm="6" style="max-height: 60vh;overflow-y: auto">
                      <v-card class="pa-4" outlined>
                        <v-row wrap>
                          <v-col
                            class="Path-8"
                            v-for="(my, key) in my_diseases"
                            :key="key"
                            >{{ my }}
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                 
                  </v-row> -->
                  <!-- <v-row
                    v-show="
                      other_diseases.length > 0 && other_diseases[0] != ''
                    "
                  >
                    <v-col cols="12" md="4" lg="4" sm="4">
                      <div class="label" style="font-size: 17px">
                        Other Diseases:
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" sm="6">
                      <v-card class="pa-4" outlined v-show="!othdis_edit">
                        <v-row wrap>
                          <v-col
                            class="Rectangle-939"
                            v-for="(oth, key) in other_diseases"
                            :key="key"
                            >{{ oth }}</v-col
                          >
                        </v-row>
                      </v-card>
                      <div v-show="othdis_edit">
                        <v-text-field
                          class="mt-2"
                          v-model="other_disease"
                          type="text"
                          placeholder="Enter other disease"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="12" md="2" lg="2" sm="2">
                      <v-icon
                        style="margin-top: 2vh"
                        @click="othdis_edit = true"
                        >edit</v-icon
                      >
                    </v-col>
                  </v-row> -->
                </div>
                <v-row>
                  <v-col cols="12" md="4" lg="4" sm="4"></v-col>
                  <v-col cols="12" md="4" lg="4" sm="4">
                    <v-btn
                      style="
                        background-color: #3fa6a1;
                        color: #ffffff;
                        font-size: 17px;
                        height: 6vh !important;
                        margin-top: 3vh;
                        /* width: auto; */
                      "
                      @click="update_profile()"
                      >Update Profile</v-btn
                    >
                  </v-col>
                  <v-col cols="12" md="4" lg="4" sm="4"></v-col>
                </v-row>
                <v-row>
                  <p
                    style="
                      font-size: 17px;
                      margin-top: 3vh;
                      margin-bottom: 3vh;
                      text-align: center;
                    "
                  >
                    {{ message }}
                  </p>
                </v-row>
              </v-card>
            </v-col>
            <v-col v-if="isTabletMob" cols="12"  style="padding:2vh 1.5vh 2vh 6vh">
              <v-card
                class="mt-1 mb-4"
                style="padding: 2vh ;0vh 5vh 0vh;font-size: 17px;"
              >
                <div style="font-family: Ubuntu;" >
                  <v-row > 
                    <!-- display user email -->
                    <v-col cols="12" md="4" lg="4" sm="4" >
                      <div class="label" style="font-size: 17px;padding-top:3vh">
                        Email :
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" sm="6">
                      <v-card class="pa-4" outlined>
                        {{ email }}
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <!-- display user email -->
                    <v-col cols="12" md="4" lg="4" sm="4">
                      <div class="label" style="font-size: 17px;padding-top:3vh">
                        Name :
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" sm="6">
                      <v-card class="pa-4" outlined>
                        {{ name }}
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4" lg="4" sm="4">
                      <div class="label" style="font-size: 17px;padding-top:3vh">
                        Company Name :
                      </div>
                    </v-col>
                    <!-- <v-col cols="12" md="2" lg="2" sm="2">
                      <v-icon style="margin-top: 3vh" @click="comp_edit = true"
                        >edit</v-icon
                      >
                    </v-col> -->
                    <v-col cols="10" md="6" lg="6" sm="6">
                      <v-card
                        class="pa-4"
                        outlined
                        v-if="!comp_edit && !oth_comp_flag"
                      >
                        <span
                          style="text-transform: capitalize !important"
                          v-show="company_name !== 'Other'"
                          >{{ company_name }}</span
                        >
                        <span
                          style="text-transform: capitalize !important"
                          v-show="company_name == 'Other'"
                          >{{ other_company }}</span
                        >
                      </v-card>
                      <v-select
                        v-if="comp_edit"
                        class="mt-2"
                        height="40"
                        style="text-transform: capitalize"
                        outlined
                        v-model="company_name"
                        :items="companies"
                        attach
                        label=""
                        @change="onChangeCompany(company_name)"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item>
                            <v-list-item-content>
                              <v-text-field
                                v-model="searchComp"
                                placeholder="Search"
                                @input="searchCompany"
                              >
                              </v-text-field>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider style="margin-top: -2vh"></v-divider>
                        </template>
                      </v-select>
                      <div v-show="oth_comp_flag">
                        <v-text-field
                          class="mt-2"
                          v-model="other_company"
                          type="text"
                          placeholder="Enter Company Name"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="2" md="2" lg="2" sm="2">
                      <v-icon style="margin-top: 2vh;" @click="comp_edit = true" align="center"
                        >edit</v-icon
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4" lg="4" sm="4">
                      <div class="label" style="font-size: 17px;padding-top:3vh">
                        Position :
                      </div>
                    </v-col>
                    
                    <v-col cols="10" md="6" lg="6" sm="6">
                      <v-card
                        class="pa-4"
                        outlined
                        v-show="!pos_edit && !oth_posi_flag"
                      >
                        <span v-if="position_name !== 'Other'">{{
                          position_name
                        }}</span>
                        <span v-if="position_name == 'Other'">{{
                          other_position
                        }}</span>
                      </v-card>
                      <v-select
                        v-if="pos_edit"
                        class="mt-2"
                        height="40"
                        style="text-transform: capitalize"
                        outlined
                        v-model="position_name"
                        :items="positions"
                        attach
                        label=""
                        @change="onChangePosition(position_name)"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item>
                            <v-list-item-content>
                              <v-text-field
                                v-model="searchTerm"
                                placeholder="Search Position"
                                @input="searchPosition"
                              >
                              </v-text-field>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider style="margin-top: -2vh"></v-divider>
                        </template>
                      </v-select>
                      <div v-show="oth_posi_flag">
                        <v-text-field
                          class="mt-2"
                          v-model="other_position"
                          type="text"
                          placeholder="Enter Position"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="2" md="2" lg="2" sm="2">
                      <v-icon style="margin-top: 2vh" @click="pos_edit = true"
                        >edit</v-icon
                      >
                    </v-col>
                  </v-row>
                 
                  <v-row v-show="user.Agreement_key!='' && user.Agreement_key!=undefined">
                    <v-col align="center">
                      <span @click="downloadReceipt()" class="Active" style="cursor:pointer" >Download Agreement Copy</span>
                    </v-col>
                  </v-row>

                  <!-- <v-row v-show="my_diseases.length > 0">
                    <v-col cols="12" md="4" lg="4" sm="4">
                      <div class="label" style="font-size: 17px">
                        Selected Diseases :
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" sm="6" style="max-height: 60vh;overflow-y: auto">
                      <v-card class="pa-4" outlined>
                        <v-row wrap>
                          <v-col
                            class="Path-8"
                            v-for="(my, key) in my_diseases"
                            :key="key"
                            >{{ my }}
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                 
                  </v-row> -->
                  <!-- <v-row
                    v-show="
                      other_diseases.length > 0 && other_diseases[0] != ''
                    "
                  >
                    <v-col cols="12" md="4" lg="4" sm="4">
                      <div class="label" style="font-size: 17px">
                        Other Diseases:
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" sm="6">
                      <v-card class="pa-4" outlined v-show="!othdis_edit">
                        <v-row wrap>
                          <v-col
                            class="Rectangle-939"
                            v-for="(oth, key) in other_diseases"
                            :key="key"
                            >{{ oth }}</v-col
                          >
                        </v-row>
                      </v-card>
                      <div v-show="othdis_edit">
                        <v-text-field
                          class="mt-2"
                          v-model="other_disease"
                          type="text"
                          placeholder="Enter other disease"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="12" md="2" lg="2" sm="2">
                      <v-icon
                        style="margin-top: 2vh"
                        @click="othdis_edit = true"
                        >edit</v-icon
                      >
                    </v-col>
                  </v-row> -->
                </div>
                <v-row>
                  <v-col align="center" :cols="!isTabletMob ? '12' :'3'" md="4" lg="4" sm="4"></v-col>
                  <v-col align="center" :cols="!isTabletMob ? '12' :'8'" md="4" lg="4" sm="4" style="width:auto">
                    <v-btn
                      style="
                        background-color: #3fa6a1;
                        color: #ffffff;
                        font-size: 17px;
                        height: 6vh !important;
                        margin-top: 3vh;
                        /* width: auto; */
                      "
                      @click="update_profile()"
                      >Update Profile</v-btn
                    >
                  </v-col>
                  <v-col :cols="!isTabletMob ? '12' :'2'" md="4" lg="4" sm="4"></v-col>
                </v-row>
                <v-row>
                  <p
                    style="
                      font-size: 17px;
                      margin-top: 3vh;
                      margin-bottom: 3vh;
                      text-align: center;
                    "
                  >
                    {{ message }}
                  </p>
                </v-row>
              </v-card>
              </v-col>
            <v-col v-if="!isTabletMob" cols="12" md="3" lg="3" sm="3"> </v-col>
          </v-row>

          <template>
            <div class="text-center">
              <v-dialog v-model="dis_edit" width="700">
                <v-card style="padding: 3vh 2vh 3vh 2vh">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                        <span class="Select-Diseases">Select Diseases</span>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                        lg="4"
                        xs="4"
                        xl="4"
                      ></v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                        <v-icon
                          color="#ff6750"
                          style="cursor: pointer; margin-left: 8vh"
                          @click="close_diseases_modal()"
                          >mdi-close</v-icon
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                        <v-text-field
                          type="text"
                          v-model="searchdisease"
                          label="Search"
                          style="
                            background-color: white;
                            font-family: Ubuntu;
                            font-weight: 500;
                            margin-left: 1vh;
                            margin-right: 1vh;
                            margin-top: -1vh;
                          "
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row
                      no-gutters
                      wrap
                      style="max-height: 40vh; overflow-y: scroll"
                    >
                      <v-col
                        cols="12"
                        sm="4"
                        v-for="(dis, key) in filteredDisesesList"
                        :key="key"

                        @click="get_disease(dis)"
                      >
                        <!-- <div class="Rectangle-836" :style="dis.status == 'Not-selected' ? 'background-color:inherit;text-transform: capitalize': 'background-color: #d4eeef;text-transform: capitalize'">{{dis.name}}</div> -->
                        <v-card
                          class="d-flex align-center justify-center"
                          outlined
                          tile
                          :style="
                            dis.status == 'Not-selected'
                              ? 'background-color:inherit;text-transform: capitalize;height:12vh;color: #4a4a4a;text-align:center;align:center'
                              : 'background-color: #d4eeef;text-transform: capitalize;height:12vh;color: #4a4a4a;text-align:center;align:center'
                          "
                        >
                          {{ dis.name }}
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <div v-show="oth_dise_flag">
                        <div class="label mt-6">
                          Enter Diseases (Separate with a comma)
                        </div>
                        <v-row>
                          <v-col cols="12" sm="6" md="6" lg="6">
                            <v-text-field
                              class="mt-6"
                              v-model="other_disease"
                              type="text"
                              placeholder="Diseases"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <!--<v-col cols="12" sm="4" md="4" lg="4">
                              <div style="margin-top:4vh !important"><a style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 16px;" @click="send_other_diseases(other_disease)">Send</a></div>
                            </v-col>-->
                        </v-row>
                      </div>
                    </v-row>
                    <v-divider></v-divider>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-row>
                      <v-col sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                      <v-col sm="4" md="4" lg="4" xs="4" xl="4">
                        <a
                          style="
                            text-decoration: none;
                            color: #009ea5;
                            font-weight: 600;
                            font-size: 16px;
                            font-family: Ubuntu;
                          "
                          @click="oth_dise_flag = true"
                        >
                          Other Diseases
                        </a>
                      </v-col>
                      <v-col sm="4" md="4" lg="4" xs="4" xl="4">
                        <v-btn
                          style="
                            background-color: #009ea5;
                            color: #ffffff;
                            font-size: 17px;
                          "
                          @click="dis_edit = false"
                        >
                          Next
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </template>
        </div>
      </div>
  </div>
</template>
<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { EventBus } from "../event-bus.js";
export default {
  data() {
    return {
      auth_token: "",
      loading: true,
      fullPage: true,
      user: {},
      email: "",
      company_name: "",
      position_name: "",
      other_company: "",
      other_position: "",
      other_diseases: [],
      my_diseases: [],
      diseases: [],
      companies: [],
      positions: [],
      searchComp: "",
      searchTerm: "",
      companiesCopy: [],
      positionsCopy: [],
      comp_edit: false,
      pos_edit: false,
      dis_edit: false,
      othdis_edit: false,
      oth_posi_flag: false,
      oth_comp_flag: false,
      searchdisease: "",
      oth_dise_flag: false,
      other_disease: '',
      message: '',
      alldiseases: [],
      my_selected_diseases: [],
      unselected: [],
      disnm: '',
      plan: '',
      name: '',
    };
  },
  components: {
    Loading,
  },
  computed: {
    isTabletMob : function() {

      const iPad = /iPad/i.test(navigator.userAgent);
      const iPhone = /iPhone/i.test(navigator.userAgent);
      const Android = /Android/i.test(navigator.userAgent);
      const webOS = /webOS/i.test(navigator.userAgent);
      const iPod = /iPod/i.test(navigator.userAgent);
      const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
      const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);

      return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
    },
    filteredDisesesList() {
      return this.unselected.filter((item) => {
        return (
          item.name.toLowerCase().indexOf(this.searchdisease.toLowerCase()) > -1
        );
      });
    },
  },
  mounted() {
    //console.log("profile setting")
    var auth_token = localStorage.getItem("authtoken");
    EventBus.$emit("module_name","" );
    localStorage.setItem("selected_module","")
    
    if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
      this.auth_token = auth_token
      this.get_page_activity()
      this.getdiseases(this.auth_token)
      this.getpositions(this.auth_token)
      this.getcompanies(this.auth_token)
      // this.save_page_activity()
    }
  },
  methods: {
    save_page_activity() {
      var disease = ""
      var articleid = ""
      var duration = "0"
      var panel_duration = "0"
      var activity_name = "Page Change"
      var panel_number = "0"
      var pagename = "Update Profile Page"
      axios
        .post("/SaveActivity?id=" + articleid + '&token=' + this.auth_token + "&activity_name=" + activity_name + "&disease=" + disease + "&page_name=" + pagename + "&duration=" + duration + "&panel_duration=" + panel_duration)
        .then((res) => {
          if (res.data.Status != 1) {
          console.log("Activity saved successfully")
        } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
          console.log("Invalid User");
        } else {
          console.log("Activity save failed")
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);          
        }
      });
    },
    onCancel() {
      console.log("User cancelled the loader.");
    },
    getuser() {
      
      axios
        .get("/user?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status != 1) {
            this.email = res.data.Data.Email
            console.log(this.email,"this.user_email")
            this.get_profile()
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
            this.logOut()
          } else {
            console.log("User not found")
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);          
          }
          this.logOut()
        });
    },
    get_profile() {
      axios.get("/userProfile?token=" + this.auth_token).then((res) => {
        if (res.data.Status !== 1) {
          this.user = res.data.Data;
        console.log(this.user,"user")
          
         
          this.company_name = this.user.Company_name;
          this.other_company = this.user.Other_company;
          this.position_name = this.user.User_position;
          this.other_position = this.user.Other_position;

          console.log(this.user.Name,"this.user.Name")
          
          if (this.user.Name !== null && this.user.Name !== undefined && this.user.Name !== "") {
            console.log("dsfgddgf")
            this.name = this.user.Name 
          } else {
            
            //split email to get name with @
        
            this.name = this.email.split("@")[0]
          }
          
          console.log(this.name,"this.name")
          this.plan = this.user.Plan
        
          
          this.other_diseases = this.user.Other_diseases;

          for (var a = 0; a < this.alldiseases.length; a++) {
            for (var b = 0; b < this.user.Diseases.length; b++) {
              if (this.alldiseases[a].Name == this.user.Diseases[b]) {
                this.my_diseases.push(this.alldiseases[a].Name)
              }
            }
          }

          var unique_diseases = this.my_diseases.filter(this.onlyUnique);
          this.my_diseases = unique_diseases
          this.my_selected_diseases = this.my_diseases

          for (var h = 0; h < this.diseases.length; h++) {
            for (var k = 0; k < this.my_diseases.length; k++) {
              if (this.diseases[h].name == this.my_diseases[k]) {
                this.diseases[h].status = "Selected";
              }
            }
          }

          for (var m = 0; m < this.diseases.length; m++) {
            if (this.diseases[m].status == 'Not-selected') {
                this.unselected.push(this.diseases[m])
            }
          }
          this.loading=false

        } else if (
          res.data.Status == 1 &&
          res.data.Data.error == "Invalid User"
        ) {
          console.log("Invalid User");
        } else {
          console.log("Profile not found");
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);             
        }
      });
    },
    get_disease(dis) {
      if (dis.status == "Selected") {
        dis.status = "Not-selected";
        for (var j = 0; j < this.my_diseases.length; j++) {
          if (this.my_diseases[j] == dis.name) {
            var spliced = this.my_diseases.splice(j, 1);
            break;
          }
        }
      } else {
        dis.status = "Selected";
        this.my_diseases.push(dis.name);
      }

      var md = this.my_diseases.sort(function (a, b) {
        var textA = a.toUpperCase();
        var textB = b.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      this.my_diseases = md;
    },
    update_profile() {
      this.message = ''
      this.str_other_diseases = this.other_disease
      if (this.str_other_diseases !== "" || this.str_other_diseases !== null) {
        var comma_separate = this.str_other_diseases.includes(",");
        if (comma_separate == true) {
          this.other_diseases = this.str_other_diseases.split(",");
        } else {
          if (this.other_diseases[0] !== "") {
            this.other_diseases.push(this.str_other_diseases);
          } else {
            this.other_diseases = [];
            this.other_diseases.push(this.str_other_diseases);
          }
        }
      }
      //console.log(this.other_diseases, "other_diseases")
      // localStorage.setItem("diseases", this.my_diseases);
      //localStorage.setItem("selected_disease",this.my_diseases[0]);

      if (this.company_name !== "Other") {
        this.other_company = "";
      }

      if (this.position_name !== "Other") {
        this.other_position = "";
      } 
      
      let senddata = {
        company_name: this.company_name,
        user_position: this.position_name,
        diseases: this.my_diseases,
        other_company: this.other_company,
        other_position: this.other_position,
        other_diseases: this.other_diseases,
        plan: this.plan,
      };

      let url = `/userProfilesettings?token=` + this.auth_token;
      let config = { headers: { "Content-Type": "application/json" } };
      axios.put(url, JSON.stringify(senddata), config).then((res) => {
        //console.log(res, "res");
        if (res.data.Status == 0) {
          //localStorage.setItem("user", JSON.stringify(senddata));
          this.message = "Your profile updated successfully !";
        } else {
          console.log("Error while profile updation");
          this.message = "Profile updation failed. Please try again";
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);      
        }
      });
    },
    getdiseases(token) {
      this.diseases = []
      this.alldiseases = []
      var all_diseases = []
      var mydis = this.my_selected_diseases
      //console.log(mydis,"mydis")
      axios
        .get("/getalldiseases?token=" + this.auth_token)
        .then((res) => {
          //console.log(res,"ressss diseases")
          if (res.data.Status != 1) {
            this.alldiseases = res.data.Data
            for (var i = 0 ; i < res.data.Data.length; i++) {
              all_diseases.push(res.data.Data[i].Name)
            }
            var array3 = all_diseases.filter(function(obj) { return mydis.indexOf(obj) == -1; });
            for (var j = 0; j < array3.length; j++) {
              var dis = {}
              dis.status = "Not-selected"
              dis.name = array3[j]
              this.diseases.push(dis)
            }
            var dis = this.diseases.sort(function(a, b) {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

          this.diseases = dis;
          this.getuser()
        

        } else {
          console.log("Diseases not found");
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);          
        }
      });
    },
    getpositions(token) {
      axios.get("/getallpositions?token=" + this.auth_token).then((res) => {
        //console.log(res,"ressss positions")
        if (res.data.Status != 1) {
          for (var i = 0; i < res.data.Data.length; i++) {
            this.positions.push(res.data.Data[i].Name);
          }

          var pos = this.positions.sort(function (a, b) {
            var textA = a.toUpperCase();
            var textB = b.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });

          this.positions = pos;
          this.positions.unshift("Other");
          this.positionsCopy = [...this.positions];
        } else {
          console.log("Positions not found");
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message); 
        }
      });
    },
    getcompanies(token) {
      axios.get("/getallcompanies?token=" + this.auth_token).then((res) => {
        //console.log(res,"ressss companies")
        if (res.data.Status != 1) {
          for (var i = 0; i < res.data.Data.length; i++) {
            this.companies.push(res.data.Data[i].Name);
          }

          var com = this.companies.sort(function (a, b) {
            var textA = a.toUpperCase();
            var textB = b.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });

          this.companies = com;
          this.companies.unshift("Other");
          this.companiesCopy = [...this.companies];
        } else {
          console.log("Companies not found");
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);        
        }
      });
    },
    searchPosition(e) {
      if (!this.searchTerm) {
        this.positions = this.positionsCopy;
      }

      this.positions = this.positionsCopy.filter((position) => {
        return (
          position.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
        );
      });
    },
    searchCompany(e) {
      if (!this.searchComp) {
        this.companies = this.companiesCopy;
      }

      this.companies = this.companiesCopy.filter((compn) => {
        return compn.toLowerCase().indexOf(this.searchComp.toLowerCase()) > -1;
      });
      //console.log(this.companies,"this.companies")
    },
    onChangeCompany(company_name) {
      this.comp_edit = false;
      if (company_name == "Other") {
        this.oth_comp_flag = true;
      } else {
        this.oth_comp_flag = false;
      }
    },
    onChangePosition(position_name) {
      this.pos_edit = false;
      if (position_name == "Other") {
        this.oth_posi_flag = true;
      } else {
        this.oth_posi_flag = false;
      }
    },
    close_diseases_modal() {
      this.dis_edit = false;
    },

    goToHome() {
      //this.$router.push("/").catch(() => {});
      this.disnm = ""
      // var disesase = localStorage.getItem("selected_disease")
      var disesase=this.disesase
      if (disesase !== undefined && disesase !== null && disesase !== '') {
        this.disnm = disesase
      } else {
        this.disnm = this.user.Diseases[0]
      }

      this.$router.push("/").catch(() => {})
      
      this.l1l2_page_data = {}
      this.l1l2_page_data.article_title = "Events"
      this.l1l2_page_data.disease = this.disnm
      EventBus.$emit("l1l2_page_data", this.l1l2_page_data);
    },
    next_process() {
      this.dis_edit = false
      this.str_other_diseases = this.other_disease
      if (this.str_other_diseases !== "") {
        var comma_separate = this.str_other_diseases.includes(",");
        if (comma_separate == true) {
          this.other_diseases = this.str_other_diseases.split(",");
        } else {
          if (this.other_diseases[0] !== "") {
            this.other_diseases.push(this.str_other_diseases);
          } else {
            this.other_diseases = [];
            this.other_diseases.push(this.str_other_diseases);
          }
        }
      }
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    logout() {
      localStorage.removeItem("authtoken");
      localStorage.removeItem("selectedColorList");
      // localStorage.setItem("selected_article", "Industry Updates");
      this.disesase = "Multiple sclerosis"
      delete axios.defaults.headers.common["Authorization"];
      this.auth_token = null;        

      this.$router.push("/").catch(() => {})
    },
    get_page_activity(){
    axios
            .get("/fetchIndicationSelected?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status != 1) {
                console.log(res.data,"response.data")
                this.disesase = res.data.Data.Indication
                this.$forceUpdate();          
              } 
          })
  },
    downloadReceipt(){
    //create get request to get s3link
    axios.get("/getPaymentReceipt?token=" + this.auth_token+"&attchment="+"agreement")
    .then((res) => {
      if (res.data.Status != 1) {
        fetch(res.data.Data.Url)
          .then(response => response.blob()) // Convert response to Blob
          .then(blob => {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = "Agreement.pdf";
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch(() => console.log("Error downloading the file"));
      } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
        console.log("Invalid User");
      } else {
        console.log("Article link not found");
      }
    })
    .catch((error) => {
      console.log(error.message);
    });
  
    }
  },
};
</script>
<style lang="scss" scoped>
  .Rectangle-939 {
    margin: 1vh;
    padding: 1vh;
    background-color: #d4eeef;
    text-align: center;
    text-transform: capitalize;
  }
  .Path-8 {
    margin:1vh;
    padding: 1vh;
    border: solid 1px #008489;
    background-color: #fff;
    border-radius: 7px;
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #008489;
  }
  .Active{
    font-family: Ubuntu;
    font-size: 2vh;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: #03a6ad;
  }
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color:#fff; /* Adjust the transparency as needed */
    z-index: 1000; /* Ensure it's above other content */
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
        