<template>
  <v-row>
    <span style="margin-top:3vh;font-family:Ubuntu;font-size: 15px;line-height: 1.5;">SOFTWARE AS A SERVICE AGREEMENT</span>
    <div style="font-weight: 300;font-family:Ubuntu">
      <div style="margin-top: 3vh"><span style="font-size: 14px;">PLEASE READ THIS AGREEMENT BEFORE USING PIENOMIAL’S SERVICES.  BY ACCESSING OR USING PIENOMIAL’S KNOLENS™ SOFTWARE OR SERVICES OFFERING, YOU (“Customer”) SIGNIFY ACCEPTANCE OF AND AGREE TO THE TERMS AND CONDITIONS OF THIS AGREEMENT.  IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS OF THIS AGREEMENT, DO NOT ACCESS OR USE THE SERVICES.</span></div>
      <div style="margin-top: 3vh"><span style="font-size: 14px;">This Software as a Service Agreement (“Agreement”) is entered into between Customer and Pienomial, Inc., a Maryland corporation (“Pienomial”), with its principal place of business at 16 Fairwood Court, Rockville, Maryland 20850.</span></div>
      <div style="margin-top: 3vh"><span style="font-size: 14px;">WHEREAS, Pienomial provides access to its SaaS Services to its customers;</span></div>
      <div style="margin-top: 3vh"><span style="font-size: 14px;">WHEREAS, Customer desires to access Pienomial’s SaaS Services as described herein, and Pienomial desires to provide Customer access to such offerings, subject to the terms and conditions set forth in this Agreement.</span></div>
      <div style="margin-top: 3vh">
        <span style="font-size: 14px;">NOW, THEREFORE, in consideration of the mutual covenants, terms, and conditions set forth herein, and for other good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, the parties agree as follows:</span>
        <ol>
          <li style="margin-top:2vh;margin-bottom: 2vh;">
            <span><b>DEFINITIONS.</b></span>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>“Authorized Users”</b></span><span> mean Customer’s employees, consultants, contractors, and agents who are at least 18 years of age and authorized by Customer to access and use the SaaS Services under the rights granted to Customer pursuant to this Agreement and for whom access to the SaaS Services has been purchased hereunder.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>“Customer Content”</b></span><span> means all data and materials provided by Customer to Pienomial for use in connection with the SaaS Services, and includes the results from the specific KnoLens models for which the Customer has set its own parameters and included their own data and materials.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>“Documentation”</b></span><span> means the user guides, online help, release notes, training materials and other documentation provided or made available by Pienomial to Customer regarding the use or operation of the SaaS Services.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>“Knolens Content”</b></span><span> includes the results, models, and other information and content made available to customers via the SaaS Services, excluding any Customer Content.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>“SaaS Services”</b></span><span> refers to the specific Pienomial internet-accessible services identified in a Subscription Order that includes use of Pienomial’s KnoLens software that is hosted by Pienomial’s service provider and made available to Customer over a network on a term-use basis.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>“Subscription Order”</b></span><span> is a written document attached to this Agreement under Exhibit A or executed separately by Pienomial and Customer for the purpose of purchasing SaaS Services under the terms and conditions of this Agreement.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>“Subscription Term”</b></span><span> shall mean that period specified in a Subscription Order during which Customer will have on-line access and use of the KnoLens Software through Pienomial’s SaaS Services.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>“Technology”</b></span><span> shall mean all proprietary technology (including software, hardware, products, processes, algorithms, user interfaces, know-how, techniques, designs and other tangible or intangible technical material or information) made available to Customer by Pienomial in providing the SaaS Services.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>“Third Party Content”</b></span><span> includes information, materials, or other publicly available content obtained from third party sources.</span></div>
          </li>
          <li style="margin-top:2vh;margin-bottom: 2vh;">
            <span><b>SAAS SERVICES.</b></span>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>2.1 Access & Use.</b></span><span> Subject to and conditioned on Customer’s and its Authorized Users’ compliance with the terms and conditions of this Agreement, during the Subscription Term: (a) Customer will receive a non-exclusive, non-assignable, royalty free, right to access and use the SaaS Services, Technology, and KnoLens Content solely for its internal business operations subject to the terms of this Agreement, and (b) Pienomial will use industry standard efforts to provide Customer the SaaS Services, Technology, and KnoLens Content in accordance with the Subscription Order. </span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>2.2 Delivery of Services.</b></span><span> Customer acknowledges that this Agreement is a services agreement and Pienomial will not be delivering any copies of its KnoLens software to Customer as part of the SaaS Services.</span></div>
          </li>
          <li style="margin-top:2vh;margin-bottom: 2vh;">
            <span><b>RESTRICTIONS.</b></span>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>3.1 Use Restrictions.</b></span><span> Customer shall not, and shall not permit anyone to: (a) copy or republish the SaaS Services; (b) make the SaaS Services available to any person other than Customer’s Authorized Users; (c) modify or create derivative works based upon the SaaS Services, Documentation, or KnoLens Content; (e) remove, modify or obscure any copyright, trademark or other proprietary notices contained in the software used to provide the SaaS Services or on KnoLens Content; (f) reverse engineer, decompile, disassemble, or otherwise attempt to derive the source code of the KnoLens software used to provide the SaaS Services, except and only to the extent such activity is expressly permitted by applicable law or otherwise authorized by Pienomial; or (g) access the SaaS Services or use the Documentation in order to develop a similar product or competitive product.  Customer agrees that the KnoLens Content may not be shared publicly and may only be used by Customer and all Authorized Users for Customer’s internal business purposes. </span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>3.2 Reservation of Rights.</b></span><span> Subject to the limited licenses granted herein, Pienomial shall own all right, title and interest in and to the SaaS Software, KnoLens Content, Technology, and Documentation provided under this Agreement, including all modifications, improvements, upgrades, derivative works and feedback directly arising from the provision of SaaS Services and intellectual property rights therein. Any unauthorized use of the SaaS Services or KnoLens Content may infringe Pienomial’s or a third party’s intellectual property rights.  Customer owns all rights and interest in and to the Customer Content, including results from specific models for which the Customer has set parameters and has included their own data and materials, subject to the rights of KnoLens and any third parties.</span></div>
          </li>
          <li style="margin-top:2vh;margin-bottom: 2vh;">
            <span><b>CUSTOMER OBLIGATIONS.</b></span>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>4.1 Customer Input.</b></span><span>Customer is solely responsible for collecting, inputting, and updating all Customer Content used or stored by the SaaS Services, and for ensuring that the Customer Content does not (i) include anything that infringes or misappropriates the copyright, trade secret, trademark, or other intellectual property right of any third party, or (ii) contain anything that is obscene, defamatory, harassing, offensive or malicious. Customer should contact Pienomial via email at <a href="mailto:support@pienomial.com">support@pienomial.com</a> to correct or update Customer Content.  Pienomial does not assume any responsibility or liability for any information Customer submits, or Customer’s or third parties’ use or misuse of information transmitted or received using the SaaS Services.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>4.2 Customer Results.</b></span><span>The SaaS Services are a tool for Customer to use, but interpretation and use of data and results, including the KnoLens Content, remains the sole responsibility of Customer.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>4.3 Data Retention.</b></span><span> Customer is solely responsible for the retention of all Customer Content.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>4.4 Assistance.</b></span><span> Customer shall provide commercially reasonable information and assistance to Pienomial to enable Pienomial to deliver the SaaS Services. Upon request from Pienomial, Customer will promptly deliver Customer Content to Pienomial in an electronic file format specified and accessible by Pienomial.  Customer acknowledges that Pienomial’s ability to deliver the SaaS Services in the manner provided in this Agreement may depend upon the form, accuracy and timeliness of such information and assistance. </span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>4.5 User Access.</b></span><span> Customer shall be solely responsible for the acts and omissions of its Authorized Users.  Pienomial shall not be liable for any loss of data or functionality caused directly by Customer except for incidents due to Pienomial’s gross negligence or omission. Customer is responsible for taking all reasonable steps to ensure that no unauthorized person shall have access to Customer’s user accounts or passwords used in connection with the SaaS Services. It is Customer’s sole responsibility to: (a) control the dissemination and use of account names and passwords; (b) authorize, monitor, and control access to and use of Customer’s account and password used in connection with the SaaS Services; and (c) promptly inform Pienomial if Customer believes its account or password has been compromised.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>4.6 Customer Content License.</b></span><span> Subject to the terms and conditions of this Agreement, Customer shall grant to Pienomial a non-exclusive, perpetual, limited, worldwide and royalty-free license to Customer Content for the purposes of: (a) copying, storing, configuring, performing, displaying and transmitting solely as necessary to provide SaaS Services to customers, (b) monitoring, improving or creating new SaaS Services and Technology, and (c) using, reproducing and disclosing SaaS Service-related information, data and material that is anonymized, de-identified or otherwise rendered not reasonably associated or linked to Customer for use in conjunction with the KnoLens Software.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b> 4.7 Non-Solicitation.</b></span><span> During the Term and for two (2) years after, Customer shall not, and shall not assist any other person to, directly or indirectly, recruit or solicit for employment or engagement as an independent contractor any person then or within the prior twelve (12) months employed or engaged by Pienomial involved in any respect with the SaaS Services or the performance of this Agreement. In the event of a violation of this Section 4.7, Pienomial will immediately terminate Customer’s SaaS Services. </span></div>
          </li>
          <li style="margin-top:2vh;margin-bottom: 2vh;">
            <span><b>PIENOMIAL OBLIGATIONS.</b></span>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>5.1 Corrections & Updates.</b></span><span>Pienomial may, at its sole discretion, modify, update, or make corrections or clarifications to the KnoLens Content and the SaaS Services.  Non-material changes will be made without notice.  If Pienomial identifies and makes changes to address errors that are material to the SaaS Services or KnoLens Content provided to Customer, Pienomial will notify Customer of such changes within thirty (30) days. This Section applies only to SaaS Services and KnoLens Content original to Pienomial and not content belonging to any third parties that may be made available through the SaaS Services.  If Customer finds an error in the KnoLens Content, it may contact Pienomial via email at <a href="mailto:support@pienomial.com">support@pienomial.com.</a></span></div>
          </li>
          <li style="margin-top:2vh;margin-bottom: 2vh;">
            <span><b>DATA SECURITY & PRIVACY.</b></span>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>6.1 Privacy.</b></span><span> The personal information you submit to Pienomial in connection with the SaaS Services is governed by the Pienomial Privacy Policy.  The SaaS Services are not intended or designed for children and all Authorized Users must be at least 18 years of age.  Pienomial does not collect personal information from any known child under the age of 13. </span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>6.2 Data Security.</b></span><span> Pienomial uses and shall maintain reasonable industry standard security requirements to protect Customer Content and to ensure that no computer viruses, malware, or similar items are introduced into Customer’s computer and network environment while performing the SaaS Services.  Customer shall employ physical, administrative, and technical controls, screening, and security procedures and other safeguards necessary to: (a) securely administer the distribution and use of the SaaS Services and protect against any unauthorized access to or use of the SaaS Services; and (b) control the content and use of Customer Content. </span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>6.3 Unauthorized Use & Data Breach.</b></span><span> Each party shall: (a) notify the other party immediately of any known or suspected breach of security; (b) report to the other party immediately and use reasonable efforts to stop any unauthorized use of the SaaS Services that is known or suspected by Customer; and (c) not provide false information to the other party pertaining to or use the SaaS Services.</span></div>
          </li>
          <li style="margin-top:2vh;margin-bottom: 2vh;">
            <span><b>INTELLECTUAL PROPERTY.</b></span>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>7.1 Ownership and Restrictions.</b></span><span> Customer retains ownership and intellectual property rights in and to the Customer Content. </span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>7.2 Pienomial’s Intellectual Property.</b></span><span> Pienomial alone (and its licensors, where applicable) shall own all right, title and interest, including all related intellectual property rights in and to the SaaS Services, the KnoLens software, the KnoLens Content, and the Technology relating to the SaaS Services, subject to the rights of any third parties in Third Party Content and to the rights of Customer in the Customer Content. This Agreement is not a sale and does not convey to Customer any rights of ownership in or related to the SaaS Services, KnoLens software, KnoLens Content, Pienomial’s Technology, or the intellectual property rights owned by Pienomial. The Pienomial names and logos, such as KnoLens, associated with the SaaS Services are trademarks of Pienomial or third parties, and no right or license is granted to Customer to use them.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>7.3 Third Party Content.</b></span><span> The SaaS Services and KnoLens Content may include publicly available Third Party Content obtained from third party sources, or links to Third Party Content.  Third Party Content is not approved, guaranteed, or endorsed by Pienomial.  While Pienomial shall make reasonable efforts to include accurate and up to date information when providing the SaaS Services and KnoLens Content, under no circumstances shall Pienomial be liable to Customer for any Third Party Content or links to Third Party Content provided via the SaaS Services, including but not limited to any errors or omissions.  By using the SaaS Services, Customer agrees to evaluate, and bear all risks associated with, the use of any Third Party Content, including any reliance on the accuracy, completeness, or usefulness of such Third Party Content.</span></div>
          </li>
          <li style="margin-top:2vh;margin-bottom: 2vh;">
            <span><b>ORDERS AND PAYMENT.</b></span>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>1 Orders.</b></span><span> Customer shall order SaaS Services pursuant to the Subscription Order.  All services subscribed by Customer shall be governed exclusively by this Agreement and the applicable Subscription Order (for offline orders).  In the event of a conflict between the terms of a Subscription Order and this Agreement, the terms of this Agreement shall take precedence. </span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>2 Invoicing and Payment.</b></span><span> For all online orders, you commit in advance to purchase a specific quantity of Online Services for use during a Subscription Term and to pay upfront or on a periodic basis in advance of use.  For all offline orders, unless otherwise provided in the Subscription Order, Pienomial shall invoice Customer in advance for all fees on the Subscription Order effective date. Customer shall pay all undisputed invoices within forty-five (45) days after Customer receives the invoice. Unless otherwise provided in the Subscription Order, all fees are stated in United States Dollars and must be paid by Customer to Pienomial in United States Dollars.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>3 Commitment and Refunds Policy.</b></span><span> All Subscription Orders are considered fully committed for the duration of the Subscription Term, unless otherwise provided in the Subscription Order. For all Subscriptions, if you terminate a Subscription before the end of the Term, no refunds will be provided for of any portion of the Subscription fee you have paid for the remainder of the Term.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>4 Renewal:</b></span><span> You may choose to have a Subscription automatically renew or terminate upon expiration of the Term. Automatic renewal is pre-selected. You can change your selection at any time during the Term. Upon renewal of your Subscription, this agreement will terminate, and your Subscription will thereafter be governed, by the terms and conditions set forth in the Portal on the date on which your Subscription is renewed (the "Renewal Terms"). If you do not agree to any Renewal Terms, you may decline to renew your Subscription.</span></div>

          </li>
          <li style="margin-top:2vh;margin-bottom: 2vh;">
            <span><b>TERM AND TERMINATION.</b></span>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>9.1 Term.</b></span><span> The term of this Agreement commences as of the date of the last signature to this Agreement and, unless terminated earlier pursuant any of the Agreement’s express provisions, will continue for one (1) year.  Any renewal of the Subscription Term shall be addressed in a mutually agreed upon Subscription Order between the parties.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>9.2 Termination.</b></span><span> Pienomial may terminate this Agreement, effective on written notice to Customer, if Customer: (a) fails to pay any amount when due hereunder, and such failure continues more than forty-45 (45) days after Pienomial’s delivery of written notice thereof; or (b) breaches any of its obligations under this Section 3, Section 4.5, Section 4.7, or Section 13.  If either party makes a determination, in its commercially reasonable judgment, that the other party is not in material compliance with the material provisions of this Agreement, it shall notify such party in writing and specify such deficiencies.  If the breaching party has not corrected such deficiencies within 30 days after receipt of such notice, the non-breaching party may terminate this Agreement immediately upon written notice to the other party. Notwithstanding anything to the contrary, Customer may terminate this Agreement or any Subscription Order on sixty (60) days’ written notice to Pienomial and for any or no reason. If Customer elects to terminate this Agreement, Customer shall be obligated to pay Pienomial all monies owed for the remainder of the initial term of this Agreement within thirty days (30) of sending of written notice.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>9.3 Suspension for Non-Payment.</b></span><span> Pienomial reserves the right to suspend delivery of the SaaS Services if Customer fails to timely pay any undisputed amounts due to Pienomial under this Agreement, but only after Pienomial notifies Customer of such failure in writing and such failure continues for thirty (30) days.  Suspension of the SaaS Services due to Customer’s fault shall not release Customer of its payment obligations under this Agreement. </span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>9.4 Suspension for Ongoing Harm.</b></span><span> Pienomial reserves the right to suspend delivery of the SaaS Services upon written notice if Pienomial reasonably concludes that Customer’s use of the SaaS Services is causing immediate and ongoing harm to Pienomial or others. In the extraordinary case that Pienomial must suspend delivery of the SaaS Services, Pienomial shall immediately notify Customer of the suspension and the parties shall diligently attempt to resolve the issue. Pienomial shall not be liable to Customer or to any third party for any liabilities, claims or expenses directly resulting from any suspension of the SaaS Services in accordance with this Section 9.4. Nothing in this Section 9.4 will limit Pienomial’s rights under Section 9.5 below. </span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>9.5 Effect of Termination.</b></span><span> Upon termination of this Agreement or expiration of the Subscription Term, Pienomial shall immediately cease providing the SaaS Services and all usage rights granted under this Agreement shall terminate. If Pienomial terminates this Agreement due to a material breach by Customer, then Customer shall immediately pay to Pienomial all amounts then due under this Agreement for the then current term of the Agreement.  If Customer terminates this Agreement due to a material breach by Pienomial, then Pienomial shall repay to Customer all pre-paid amounts for any unperformed SaaS Services scheduled to be delivered after the termination date within thirty (3) days. Upon termination of this Agreement and upon subsequent written request by the disclosing party, the receiving party of tangible Confidential Information shall immediately return or destroy such information. In no event shall this section limit either party’s ability to seek any and all other remedies to such party is entitled in law or equity.</span></div>
          </li>
          <li style="margin-top:2vh;margin-bottom: 2vh;">
            <span><b>NO MEDICAL ADVICE.</b></span>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>10.1  No Medical Advice.</b></span><span> The KnoLens Content or any other content or information provided to Customer through the SaaS Services is for informational purposes only and is not professional medical advice. Such content and information are not intended to be a substitute for professional medical advice, diagnosis, or treatment. </span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>10.2  Acknowledgement.</b></span><span> Customer acknowledges and agrees that the KnoLens Content or any other content or information provided to Customer through the SaaS Services are solely for informational purposes.  Customer acknowledges and agrees that Pienomial is not responsible for any of Customer’s actions or decisions made as a result of the KnoLens Content or any other content or information provided to Customer through the SaaS Services. </span></div>
          </li>
          <li style="margin-top:2vh;margin-bottom: 2vh;">
            <span><b>WARRANTIES & LIMITATIONS OF LIABILITY.</b></span>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>11.1  Warranty.</b></span><span> Pienomial represents and warrants that it will provide the SaaS Services in a professional manner with reasonable skill and care by means of appropriate qualified and skilled personnel consistent with general industry standards and that the SaaS Services will perform substantially in accordance with any Documentation provided to Customer. If during this time period the SaaS Services does not perform as warranted, Pienomial shall, within a reasonable period of time, at its option, correct the SaaS Services or replace such SaaS Services free of charge. If Pienomial is unable to or unwilling to correct or replace the SaaS Services within a reasonable period of time, then Customer’s remedy shall be as provided in Section 9, Term and Termination. </span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span> PIENOMIAL DOES NOT GUARANTEE THAT THE SAAS SERVICES WILL BE PERFORMED ERROR-FREE OR UNINTERRUPTED, OR THAT PIENOMIAL WILL CORRECT ALL SAAS SERVICES ERRORS. CUSTOMER ACKNOWLEDGES THAT THE SAAS SERVICES MAY BE SUBJECT TO LIMITATIONS AND DELAYS. THIS SECTION SETS FORTH THE SOLE AND EXCLUSIVE WARRANTY GIVEN BY PIENOMIAL (EXPRESS OR IMPLIED) WITH RESPECT TO THE SUBJECT MATTER OF THIS AGREEMENT. NEITHER PIENOMIAL NOR ANY OF ITS LICENSORS OR OTHER SUPPLIERS WARRANT OR GUARANTEE THAT THE SAAS SERVICES WILL BE UNINTERRUPTED, VIRUS-FREE OR ERROR-FREE, NOR SHALL PIENOMIAL OR ANY OF ITS SERVICE PROVIDERS BE LIABLE FOR UNAUTHORIZED ALTERATION, THEFT OR DESTRUCTION OF CUSTOMER’S OR ANY USER’S CONTENT, DATA, FILES, OR PROGRAMS. THE SAAS SERVICES AND KNOLENS CONTENT ARE PROVIDED ON AN “AS IS” BASIS.  EXCEPT AS SPECIFICALLY PROVIDED FOR HEREIN, PIENOMIAL DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO THE SAAS SERVICES AND KNOLENS CONTENT, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND/OR NON-INFRINGEMENT. </span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span>Without limiting the foregoing, Pienomial, its licensors, and its suppliers make no representations or warranties about: (1) the accuracy, reliability, completeness, or timeliness of the KnoLens Content, software, links, Third Party Content, or other information, materials, or communications provided on or through the use of the SaaS Services; or (2) the satisfaction of any government regulations requiring disclosure of information relating to pharmaceutical products or their approval or compliance with applicable laws and regulations. </span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>11.2  Limitations of Liability. </b></span><span> EXCEPT FOR DAMAGES ARISING FROM A CLAIM OF GROSS NEGLIGENCE, WILLFUL MISCONDUCT, FRAUD OR INFORMATION SECURITY BREACH, NEITHER PARTY (NOR ANY LICENSOR OR OTHER SUPPLIER OF PIENOMIAL) SHALL BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST BUSINESS, PROFITS, DATA OR USE OF ANY SERVICE, INCURRED BY EITHER PARTY OR ANY THIRD PARTY IN CONNECTION WITH THIS AGREEMENT, REGARDLESS OF THE NATURE OF THE CLAIM (INCLUDING NEGLIGENCE), EVEN IF FORESEEABLE OR THE OTHER PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  EXCEPT FOR DAMAGES ARISING FROM A CLAIM WHERE PROTECTION IS PROVIDED UNDER THE INDEMNITY SECTION HEREIN OR A CLAIM OF GROSS NEGLIGENCE, WILLFUL MISCONDUCT, FRAUD, OR INFORMATION SECURITY BREACH, NEITHER PARTY’S AGGREGATE LIABILITY FOR DAMAGES UNDER THIS AGREEMENT, REGARDLESS OF THE NATURE OF THE CLAIM (INCLUDING NEGLIGENCE), SHALL EXCEED THE FEES PAID OR PAYABLE BY CUSTOMER UNDER THIS AGREEMENT DURING THE 12 MONTHS PRECEDING THE DATE THE CLAIM AROSE.</span></div>
          </li>
          <li style="margin-top:2vh;margin-bottom: 2vh;">
            <span><b>INDEMNIFICATION.</b></span>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>12.1 Indemnification by Pienomial.</b></span><span>  If a third party makes a claim against Customer that the SaaS Services infringes any patent, copyright or trademark, or misappropriates any trade secret, or that Pienomial’s gross negligence or willful misconduct has caused bodily injury, death or property damage of any kind, Pienomial shall indemnify, defend and hold Customer and its parent, subsidiaries and affiliates and their respective officers, agents, employees, successors and assigns harmless against the claim at Pienomial’s expense and Pienomial shall pay all losses, damages and expenses (including reasonable attorneys’ fees) incurred and finally awarded against such parties or agreed to in a written settlement agreement signed by Pienomial, to the extent arising from the claim (such written settlement shall not materially obligate or require  Customer to pay any fees) without Customer’s express written consent).  Pienomial shall have no liability for any claim based on (a) the Customer Content, (b) modification of the SaaS Services not authorized or provided by Pienomial, (c) use of the SaaS Services other than in accordance with the Documentation and this Agreement, or (d) actions or inaction taken by Customer based upon the Customer Content. </span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>12.2 Indemnification by Customer. </b></span><span> If a third party makes a claim against Pienomial that the Customer Content infringes any patent, copyright or trademark, or misappropriates any trade secret, or that use of the SaaS Services by Customer directly caused any other third-party injury or loss, Customer shall defend Pienomial and its directors, officers and employees against the claim at Customer’s expense and Customer shall pay all losses, damages and expenses (including reasonable attorneys’ fees) finally awarded against such parties or agreed to in a written settlement agreement signed by Customer, to the extent arising from the claim.  Customer agrees to defend, indemnify, and hold Pienomial, its officers, directors, employees, agents, licensors, and suppliers, harmless from and against any claims, actions or demands, liabilities and settlements including without limitation, reasonable legal and accounting fees, resulting from, or alleged to result from: (a) your use of the SaaS Services; (b) any Customer Content Customer provides or uploads; (c) Customer’s use of or reliance on any Customer Content; or (d) Customer’s violation of this Agreement. </span></div>
          </li>
          <li style="margin-top:2vh;margin-bottom: 2vh;">
            <span><b>CONFIDENTIALITY. </b></span>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>13.1 Confidential Information.</b></span><span> “Confidential Information” means any information disclosed by a party to the other party, directly or indirectly, in any form or medium (whether oral, written, electronic, or other) that the party disclosing the information considers confidential or proprietary, including information consisting of or relating to the party’s technology, trade secrets, know-how, business operations, plans, strategies, customers, and pricing, and information with respect to which the party disclosing the information has contractual or other confidentiality obligations, in each case whether or not marked, designated, or otherwise identified as “confidential.”  Without limiting the foregoing: all KnoLens Content, Technology, and Documentation are the Confidential Information of Pienomial.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>13.2 Confidentiality.</b></span><span> During the term of this Agreement and for 5 years thereafter, each party shall treat as confidential all Confidential Information of the other party, shall not use such Confidential Information except to exercise its rights and perform its obligations under this Agreement, and shall not disclose such Confidential Information to any third party. Without limiting the foregoing, each party shall use at least the same degree of care, but not less than a reasonable degree of care, it uses to prevent the disclosure of its own confidential information to prevent the disclosure of Confidential Information of the other party.  Each party shall promptly notify the other party of any actual or suspected misuse or unauthorized disclosure of the other party’s Confidential Information. Neither party shall reverse engineer, disassemble, or decompile any prototypes, software or other tangible objects which embody the other party’s Confidential Information, and which are provided to the party hereunder. Each party may disclose Confidential Information of the other party on a need-to-know basis to its contractors who are subject to confidentiality agreements requiring them to maintain such information in confidence and use it only to facilitate the performance of their services on behalf of the receiving party.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>13.3 Exceptions.</b></span><span> Confidential Information excludes information that: (a) is known publicly at the time of the disclosure or becomes known publicly after disclosure through no fault of the receiving party; (b) is known to the receiving party, without restriction, at the time of disclosure or becomes known to the receiving party, without restriction, from a source other than the disclosing party not bound by confidentiality obligations to the disclosing party; or (c) is independently developed by the receiving party without use of the Confidential Information as demonstrated by the written records of the receiving party. The receiving party may disclose Confidential Information of the other party to the extent such disclosure is required by law or order of a court or other governmental authority, provided that the receiving party shall use reasonable efforts to promptly notify the other party prior to such disclosure to enable the disclosing party to seek a protective order or otherwise prevent or restrict such disclosure.  Each party may disclose the existence of this Agreement and the relationship of the parties but agrees that the specific terms of this Agreement will be treated as Confidential Information; provided, however, that each party may disclose the terms of this Agreement to those with a need to know and under a duty of confidentiality such as accountants, lawyers, and investors. The parties expressly agrees that a party shall be entitled to injunctive and/or other equitable relief in any court of competent jurisdiction to prevent or otherwise restrain a breach of this Section of this Agreement, provided that the foregoing shall not preclude such party from seeking any and all damages suffered as a result of any unauthorized disclosure.</span></div>
          </li>
          <li style="margin-top:2vh;margin-bottom: 2vh;">
            <span><b>GENERAL PROVISIONS. </b></span>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>1 Compliance with Laws.</b></span><span> Customer shall comply with all applicable local, state, national and foreign laws in connection with its use of the SaaS Services, including those laws related to data privacy, international communications, and the transmission of technical or personal information. Customer acknowledges that Pienomial exercises no control over the content of the information transmitted by Customer through the SaaS Services. Customer shall not upload, post, reproduce or distribute any information, software or other material protected by copyright, privacy rights, or any other intellectual property right without first obtaining the permission of the owner of such rights.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>2 Non-Exclusive Service.</b></span><span> Customer acknowledges that the SaaS Services are provided on a non-exclusive basis.  Nothing shall be deemed to prevent or restrict Pienomial’s ability to provide the SaaS Services or other Technology, including any features or functionality first developed for Customer or KnoLens Content, to other parties.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>3 Assignment.</b></span><span> Neither party may assign this Agreement or any right under this Agreement, without the consent of the other party, which consent shall not be unreasonably withheld or delayed; provided however, that either party may assign this Agreement to an acquirer of all or substantially all of the business of such party to which this Agreement relates, whether by merger, asset sale or otherwise. This Agreement shall be binding upon and inure to the benefit of the parties’ successors and permitted assigns. </span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>4 Notices.</b></span><span> Except as otherwise permitted in this Agreement, any notice, request, consent, claim, demand, waiver, or other communications under this Agreement have legal effect only if in writing and addressed to a party as identified in the Subscription Order (or to such other address or such other person that such party may designate from time to time). Notices under this Agreement shall be in writing and shall be deemed to have been given: (a) when received, if delivered by hand, with signed confirmation of receipt; (b) when received, if sent by a nationally recognized overnight courier, signature required; (c) when sent, if by facsimile or email, (in each case, with confirmation of transmission), if sent during the addressee’s normal business hours, and on the next business day, if sent after the addressee’s normal business hours; and (d) on the fifth day after the date mailed by certified or registered mail, return receipt requested, postage prepaid.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>5 Force Majeure.</b></span><span> Pienomial shall be excused from performance for any period during which, and to the extent that, it is prevented from providing the SaaS Services or performing any obligations under this Agreement, in whole or in part, as a result of causes beyond its reasonable control, and without its fault or negligence, including without limitation, acts of God, strikes, global pandemic, lockouts, riots, acts of terrorism or war, epidemics, communication line failures, and power failures. The parties shall cooperate to ascertain the facts and the effect of the delay on and make appropriate adjustments where necessary.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>6 Waiver.</b></span><span>  No waiver shall be effective unless it is in writing and signed by the waiving party. The waiver by either party of any breach of this Agreement shall not constitute a waiver of any other or subsequent breach.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>7 Severability.</b></span><span>  If any term of this Agreement is held to be invalid or unenforceable, that term shall be reformed to achieve as nearly as possible the same effect as the original term, and the remainder of this Agreement shall remain in full force.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>8 Entire Agreement & Amendment.</b></span><span>  This Agreement (including all Subscription Orders) contains the entire agreement of the parties and supersedes all previous oral and written communications by the parties, concerning the subject matter of this Agreement. This Agreement may be amended solely in a writing signed by both parties.  Standard or printed terms contained in any Subscription Order or sales confirmation are deemed rejected and shall be void unless specifically accepted in writing by the party against whom their enforcement is sought; mere commencement of work or payment against such forms shall not be deemed acceptance of the terms. </span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>9 Survival.</b></span><span>  Notwithstanding the termination or expiration of this Agreement, and except as otherwise stated in this Agreement, those obligations contained herein that by their terms or nature are intended to survive such termination or expiration shall do so including, and without limitation, the Indemnification, Limitation of Liability, and Confidentiality provisions herein. </span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>10 Publicity.</b></span><span>  Pienomial may not use Customer’s name, logo and/or trademark or other intellectual property in Pienomial’s marketing materials without prior express written consent of Customer. Notwithstanding anything herein to the contrary, Customer acknowledges that Pienomial may disclose the existence and terms and conditions of this Agreement to its advisors, actual and potential sources of financing and to third parties for purposes of due diligence.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>11 International Laws & Regulations.</b></span><span> Pienomial makes no claims that the SaaS Services and KnoLens Content may be used outside of the United States.  If Customer accesses or uses the SaaS Services and/or the KnoLens Content from outside the United States, Customer agrees and acknowledges that it does so at its own risk and is responsible for compliance with the laws of Customer’s jurisdiction.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>12 No Third Party Beneficiaries.</b></span><span>  Agreement is an agreement between the parties, and confers no rights upon either party’s employees, agents, contractors, partners of customers or upon any other person or entity.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>13 Relationship of the Parties.</b></span><span>  The relationship between the parties is that of independent contractors. Nothing contained in this Agreement shall be construed as creating any agency, partnership, joint venture, or other form of joint enterprise, employment, or fiduciary relationship between the parties, and neither party shall have authority to contract for or bind the other party in any manner whatsoever.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>14 Statistical Information.</b></span><span>  Pienomial may anonymously compile statistical information related to the performance of the SaaS Services for purposes of improving or marketing the SaaS Services, provided that such information does not identify Customer. </span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>15 Governing Law.</b></span><span>  This Agreement is governed by and construed in accordance with the internal laws of the State of Maryland without giving effect to any choice or conflict of law provision or rule that would require or permit the application of the laws of any jurisdiction other than those of the State of Maryland. Any legal suit, action, or proceeding arising out of or related to this Agreement or the licenses granted hereunder will be instituted in the state or federal courts of the United States or the courts of the Maryland, specifically the state and federal courts in Rockville, Maryland, and each party irrevocably submits to the exclusive jurisdiction of such courts in any such suit, action, or proceeding. Service of process, summons, notice, or other document by mail to such party’s address set forth herein shall be effective service of process for any suit, action, or other proceeding brought in any such court.</span></div>
            <div style="margin-top: 2vh;font-size: 14px;line-height: 1.5;"><span><b>16 Counterparts.</b></span><span>  This Agreement may be executed in multiple counterparts, each of which when executed will be an original, and all of which, when taken together, will constitute one agreement. A signed copy of this Agreement delivered by facsimile, email, or other means of electronic transmission is deemed to have the same legal effect as delivery of an original signed copy of this Agreement. </span></div>
          </li>
        </ol>
        <!-- <span>IN WITNESS WHEREOF, the undersigned have executed this Agreement as of the dates set forth below.</span> -->
      </div>
    </div>
    <div style="margin-top: 3vh"><span style="font-size: 14px;"></span></div>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
   
    };
  },
};
</script>

<style lang="scss" scoped>
  .Path-359 {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ff6750 !important;
    background-color: #fff;
    border-radius: 20px !important;
  }

</style>