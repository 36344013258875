<template>
  <div class="margin_align" style="overflow-y:hidden" >
    <div :class="expanded ? 'leftPanelExpand' : 'left'" style="padding-top:0" >
      <loading :active.sync="loading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
      <div style="font-family: Ubuntu;margin-bottom: -10px;margin-right: -5px;margin-top:0px;">
        <v-row wrap justify="center" align="center" > 
          <v-col cols="12" md="2" lg="2" sm="2" xs="2">
            <span class="Notes-Centre-101" :style="!isTabletMob ? '' : 'margin-left:1vh'">Notes Center ({{TotalCount}})</span>
          </v-col>
          <v-col  v-if="!isTabletMob" cols="12" md="4" lg="4" sm="4" xs="4">
            <v-text-field type="text" v-model="searchme" label="Search" v-on:keyup.enter="show_all_notes(1)"
            :style="!isTabletMob ? 'background-color: white;font-size:13px !important;font-family: Ubuntu;font-weight: 500;' : ' margin-right: 3vh; margin-left: 2vh;'"
            
            >
            <template v-slot:append>
                    
              <img @click="show_all_notes(1)" src="../assets/Icons/search.png" alt="user" style="height: 22px; width: 26px; margin-right:5px;cursor:pointer"/>
            </template>
          </v-text-field>
          </v-col>
          <v-row v-if="isTabletMob" >
            <v-col cols="12" md="4" lg="4" sm="4" xs="4">
              <v-text-field type="text" v-model="searchme" label="Search" v-on:keyup.enter="show_all_notes(1)"
              :style="!isTabletMob ? 'background-color: white;font-size:13px !important;font-family: Ubuntu;font-weight: 500;' : ' margin-right: 3vh;margin-bottom:2vh;'"
              >
              <template v-slot:append>
                    
                <img @click="show_all_notes(1)" src="../assets/Icons/search.png" alt="user" style="height: 22px; width: 26px; margin-right:5px;cursor:pointer"/>
              </template>
            </v-text-field>
            </v-col>
          </v-row>
          <v-col v-if="!isTabletMob" cols="12" md="2" lg="2" sm="2" xs="2" class="d-flex">
             
          </v-col>
          <v-col v-if="!isTabletMob" cols="12" md="3" lg="3" sm="3" xs="3">
           
          </v-col>
         
        </v-row>
        <div v-show="!loading">
         
          <v-row v-show="show_message" wrap style="height:100%;margin-top:20vh">
            <v-col cols="12" md="2" lg="2" sm="2"></v-col>
            <v-col cols="12" md="8" lg="8" sm="8">
              <div>
                <img src="../assets/Icons/Group 910.svg" style="display: block;margin-left: auto;margin-right: auto;width:15vh">
              </div>
              <div>
                <p
                  style="text-align: center;margin-top:5vh;margin-bottom:28vh;font-size:16px;font-weight:700;color:#8c8e8e">
                  You have not added any note yet.</p>
              </div>
            </v-col>
            <v-col cols="12" md="2" lg="2" sm="2"></v-col>
          </v-row>
          <div id="infinite-list1"  style="overflow-y: auto;overflow-x: hidden;padding-bottom:8vh;" :style="!isTabletMob ? 'max-height:68vh;' : 'max-height:59vh;padding-bottom: 12vh;'" v-show="!show_message">
              <v-row v-masonry="containerId" transition-duration="0.3s" item-selector=".item" wrap :style="!isTabletMob ? '' : 'margin-right:2vh;margin-left: 1vh;'">
                <v-col col="12" :sm="!isTabletMob ? '3' :'6'" v-masonry-tile class="item" v-for="(al, key) in all_notes" :key="key" >
                  <v-card class="Path-704" @click.stop="show_note_area(al)" >
                    
                    <div class="Path-736">
                      <v-row wrap>
                        <v-col :cols="!isTabletMob ? '12' :'10'" md="10" lg="10" sm="10" xs="10"><span class="Added-on">Added on {{al.notes_date}}</span><br>
                          <span class="Added-on">Module - {{al.title}}</span>
                        </v-col>
                        <v-col :cols="!isTabletMob ? '12' :'2'" md="2" lg="2" sm="2" xs="2">
                          <div class="d-flex">
                            <v-menu offset-y :close-on-content-click="closeOnContentClick">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" style="padding-left:0.5vw;padding-right: 2vw;">
                                  <i class="fa fa-ellipsis-v" style="color:#03a6ad !important;margin-top: 1.7vh;"></i>
                                </div>
                                <!-- <v-btn
                                  plain
                                  v-bind="attrs"
                                  v-on="on"
                                  style="margin-right: 1vw !important"
                                  
                                >
                                  <i class="fa fa-solid fa-ellipsis-v" style="float:right;color:#03a6ad !important;margin-top: 1.7vh;"></i>
                                </v-btn> -->
                              </template>
                              <v-list style="max-height:15vh;overflow-y:scroll;" :style="!isTabletMob ? '' : 'max-height:8vh;'">
                                <v-list-item
                                  class="dropdownStyle">
                                  <v-list-item-title style="text-transform: capitalize;font-size: 13px;cursor: pointer;font-weight: 600;" @click="delete_my_note(al.module_note)">Delete</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                        </v-col>
                      </v-row>
                      <!-- <v-row style="margin-top: -1vh;">
                        <span class="Article-Type">Article Type</span>
                        <span class="Therapy-Landscape-type">{{al.title}}</span>
                      </v-row> -->
                      <v-row style="margin-top: -1vh;">
                        <!-- <span class="Article-Type">Disease Name</span> -->
                        <span class="Therapy-Landscape-type">{{al.module_note.disease}}</span>
                      </v-row>
                    </div>
                    <div style="padding: 2vh">
                      <span class="notes_text">{{ al.module_note.Note }}</span>
                    </div>
                  </v-card>                 
                </v-col>
              </v-row>
          </div>
        </div>
      </div>
    </div>
    <div class="right notes_section" v-if="!expanded && panels_show" style="margin-top:-10vh;padding-bottom:8vh;overflow-x:hidden" :style="!isTabletMob ? '' : 'width:100%'">
      <div>
        <div class="Rectangle-1062">
          <v-row style="margin-top: 5vh;">
            <v-col :cols="!isTabletMob ? '12' :'5'" md="3" lg="3" sm="3" :style="!isTabletMob ? '' : 'padding-left:0vh;padding-right:0vh'">
              <span class="notes_Added_on" >Date Added</span><br>
              <span class="note_content_type" :style="!isTabletMob ? '' : 'margin-left:2vh'">{{selected_notes_date}}</span>
            </v-col>
            <v-col :cols="!isTabletMob ? '12' :'5'" md="3" lg="3" sm="3" :style="!isTabletMob ? '' : 'padding-left:0vh;padding-right:0vh'">
              <span class="notes_Added_on" >Module Type</span><br>
              <span class="note_content_type" :style="!isTabletMob ? '' : 'margin-left:0vh'">{{selected_notes_article_type}}</span>
            </v-col>
            <v-col  :cols="!isTabletMob ? '12' :'1'" md="5" lg="5" sm="5">
            
            </v-col>
            <v-col :cols="!isTabletMob ? '12' :'1'" md="1" lg="1" sm="1" :style="!isTabletMob ? '' : 'margin-left:0vh'">
              <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;margin-top:2vh" @click="close_notes_area()">mdi-close</v-icon>
            </v-col>
          </v-row>
        </div>
        <div style="padding: 2vh 10vh 3vh 3vh;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);max-height: 15vh;overflow-y: auto;">
          <v-row>                          
            <p style="font-family: Ubuntu; font-size: 14px;text-align: left !important;font-family: Ubuntu;color: #2c2c48;margin: 0vh 5vh 1vh 2vh" :style="!isTabletMob ? '' : 'margin-left:-1vh'">{{notes_content_display}}</p>                  
          </v-row>                                       
        </div>
        <div v-if="loading_info" style="overflow-y:scroll;max-height:68vh;overflow-x:hidden">
              <v-row>
                <div v-for="(pan,kp) in panels" :key="kp" v-show="!loading">
                 
                  <div v-show="panel_id == pan.my_panel_id">
                    <v-row wrap v-if="pan.Panel_Info != 'Full_Panel'">
                      <v-col :cols="!isTabletMob ? '12' :'10'" md="4" lg="4" sm="4" xs="4" :style="!isTabletMob ? 'padding-left:40px' : 'padding-left:40px'">
                        <div class="news_heading">{{pan.Left.Header}}</div>
                        <div v-if="pan.Left.First_Text.length > 0 && pan.Left.First_Text[0] !== ''"> 
                          <div style="font-size: 16px;" class="pre-formatted" v-for="(pa,a) in pan.Left.First_Text" :key="a">
                            <span v-html="pa"></span>
                          </div>
                          <hr class="line" />
                        </div>
                        <div v-if="pan.Left.Second_Text.length > 0 && pan.Left.Second_Text[0] !== ''">
                          <div style="font-size: 16px;" class="pre-formatted" v-for="(pa,b) in pan.Left.Second_Text" :key="b">
                            <span v-html="pa"></span>
                          </div>
                          <hr class="line"/>
                        </div>
                        <div v-if="pan.Left.Third_Text.length > 0 && pan.Left.Third_Text[0] !== ''">
                          <div style="font-size: 16px;" class="pre-formatted" v-for="(pa,c) in pan.Left.Third_Text" :key="c">
                            <span v-html="pa"></span>
                          </div>
                          <hr class="line"/>
                        </div>
                        <div v-if="pan.Left.Forth_Text.length > 0 && pan.Left.Forth_Text[0] !== ''">
                          <div style="font-size: 16px;" class="pre-formatted" v-for="(pa,d) in pan.Left.Forth_Text" :key="d">
                            <span v-html="pa"></span>
                          </div>
                          <hr class="line"/>
                        </div>
                        <div v-if="pan.Left.Fifth_Text.length > 0 && pan.Left.Fifth_Text[0] !== ''">
                          <div style="font-size: 16px;" class="pre-formatted" v-for="(pa,e) in pan.Left.Fifth_Text" :key="e">
                            <span v-html="pa"></span>
                          </div>
                          <hr class="line"/>
                        </div>
                      </v-col>
                      <v-col :cols="!isTabletMob ? '12' :'10'" md="7" lg="7" sm="7" xs="7" :style="!isTabletMob ? 'margin-left:70px' : 'margin-left:30px;padding-right:10px;padding-bottom:10vh;overflow-x:scroll'">
                        <div>
                          <v-row>
                            <div v-if="pan.Right.Display.Display_Type == 'Card'">
                              <v-row wrap>
                                <v-col  cols="12" md="4" lg="4" sm="4" xs="4"
                                  v-for="(pa,key1) in pan.Right.Display.Display_Info" :key="key1">
                                  <v-card style="height:280px;width:95%;padding:0vh 2vh 0vh 2vh;margin-right: 4vh; ">
                                    <!-- card title -->
                                    <v-row wrap style="height:80px">
                                      <v-col class="mt-4" cols="12" md="3" lg="3" sm="3" xs="3">
                                        <img :src="pa.Logo" basic style="height: 22px; width: 22px;margin-left:1vh" />
                                      </v-col>
                                      <v-col class="mt-4" cols="12" md="9" lg="9" sm="9" xs="9">
                                        <p class="cardTitles">{{pa.Title}}</p>
                                      </v-col>
                                    </v-row>
                                    <!-- card contents  if Image-->
                                    <div v-if="pa.Value_Type == 'image'">
                                      <div class="centerAlign" style="height: 190px;">
                                        <img :src="pa.myimage.src" alt="ChannelLogo" />
                                      </div>
                                    </div>
                                    <!-- if text -->
                                    <div v-if="pa.Value_Type == 'text'" >
                                      <div style="margin-top:20px">
                                        <v-row>
                                          <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                                          <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4">
                                            <div style="margin-top: 10px">
                                              <hr class="hrLine">
                                              <span class="itemCountDesign centerAlign">{{pa.count}}</span>
                                              <hr class="hrLine">
                                            </div>
  
                                          </v-col>
                                          <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                                        </v-row>
                                      </div>
                                      <div class="titleCenter" style="margin-top:40px">
                                        <span class="title1">{{pa.rem_text}}</span>
                                      </div>
                                    </div>
                                  </v-card>
                                </v-col>
  
                              </v-row>
                            </div>
                            <div v-if="pan.Right.Display.Display_Type == 'Text Box'">
                              <div>
                                <div v-for="(pa,key3) in pan.Right.Display.Display_Info" :key="key3">
                                  <h5 class="texttitle">{{pa.Title}}</h5>
                                    <p v-if="!pa.textLength" class="textbox_content pre-formatted" v-html="pa.newText"></p>
                                    <p v-if="pa.textLength" class="textbox_content pre-formatted" v-html="pa.Text"></p>
                                    <span @click="changetextLength(pa)" class="pointer" v-if="!pa.textLength && !pa.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;">Read More</span>
                                    <span @click="changetextLength(pa)" class="pointer" v-if="pa.textLength && !pa.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;">Show Less</span>                               
                                </div>
                              </div>
                            </div>
                            <div v-if="pan.Right.Display.Display_Type == 'Table'">
                              <v-row v-for="(pa,key4) in pan.Right.Display.Display_Info" :key="key4">
                                <v-row wrap>
                                  <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2" v-for="(pbtn,keyyy) in pa.Dropdownnames" :key="keyyy">                       
                                      <v-btn :style="pbtn.selected ? 'background-color: #3fa6a1; color: #ffffff;font-size: 13px;font-weight:700; margin-top:2vh;font-family: Ubuntu;width:9vw;' : 'background-color: #ffffff; color: #3fa6a1; font-size: 13px;font-weight:700; margin-top:2vh; font-family: Ubuntu;width:9vw;'" @click="onChangetrials(pbtn,pa.myTable)">{{pbtn.name}}</v-btn>
                                  </v-col>
                                  <v-col cols="12" sm="7" md="7" lg="7" xs="7" xl="7"></v-col>
                                </v-row>
                                <table class="pl-0" :id="pa.myTable">
                                  <span v-if="pa.show_dropdown == true">
                                    <tr>
                                      <th :class="(pa.Table_Data.Table_Type == 'Dual Header' && key7 == 0)? 'dualHeaderRow': 'dualHeaderAnotherRow'" v-for="(p,key7) in pa.Table_Data.Columns" :key="key7" style="min-width: 9.5vw">{{p}} <span @click="sort_rows(key7, pa.myTable)" v-show="pa.Table_Data.Sort_Enable == 'True'"><i class="fa fa-arrow-down" style="margin-left: 2vh !important;cursor: pointer;"></i></span>
                                      </th>
                                    </tr>
                                    <tr v-for="(p,key5) in pa.Table_Data.show_rows" :key="key5">
                                      <td v-for="(x,k1) in p" :key="k1" :class="(pa.Table_Data.Table_Type == 'Dual Header' && k1 == 0)? 'dualHeaderData':'dualHeaderAnotherData'">
                                        <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                      </td>
                                    </tr>
                                  </span>
                                  <tr v-if="pa.show_dropdown !== true">
                                    <th
                                      :class="(pa.Table_Data.Table_Type == 'Dual Header' && key77 == 0)? 'dualHeaderRow': 'dualHeaderAnotherRow'"
                                      v-for="(p,key77) in pa.Table_Data.Columns" :key="key77">
                                      <v-row wrap style="padding:0">
                                        <v-col cols="12" sm="8" md="8" lg="8" xs="8" style="padding-top:0vh">
                                          <span style="white-space:normal;margin-right:2px">{{p}}</span>
                                        </v-col>
                                        <v-col cols="12" sm="2" md="2" lg="2" xs="2" @click="sort_rows(key77, pa.myTable)" style="padding-top:0vh" v-show="pa.Table_Data.Sort_Enable == 'True'">
                                            <i class="fa fa-arrow-down"
                                              style="cursor: pointer;padding:0px"></i>
                                        </v-col>
                                      </v-row>
                                    </th>
                                  </tr>
  
                                  <tr v-for="(p,key55) in pa.Table_Data.Rows" :key="key55" v-if="pa.show_dropdown !== true">
                                    <td v-for="(x,k11) in p" :key="k11"
                                      :class="(pa.Table_Data.Table_Type == 'Dual Header' && k11 == 0)? 'dualHeaderData':'dualHeaderAnotherData'">
                                      <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                    </td>
                                  </tr>
                                </table>
                              </v-row>
                            </div>
                            <div v-if="pan.Right.Display.Display_Type == 'Plot'" >
                              <v-row >
                              <v-col :cols="(pan.Right.Display.Display_Info.length == 1 || key6 ==2 ) ? '12' : '6'"  v-for="(pa,key6) in pan.Right.Display.Display_Info" :key="key6" style="">
                                 
                                  <span v-html="pa.div64" ></span>
                              </v-col>
                              </v-row>
                            </div>
                            <div v-if="pan.Right.Display.Display_Type == 'Financial_Cards'">
                              <div v-for="(pa,kfin) in pan.Right.Display.Display_Info" :key="kfin" style="padding:5vh">
                                <v-row class="mt-3">
                                  <v-col cols="12" md="6" lg="6" sm="6" xs="6" v-for="(p,fin) in pa.Fin_Card_Data" :key="fin">
                                    <v-card class="fincard">
                                      <div v-for="(pf,fink) in p" :key="fink">
                                        <p style="margin-top:2vh; margin-bottom:2vh;font-family:Ubuntu;font-size:16px;font-weight: 700">{{pf.Header}}</p>
                                        <v-row>
                                          <v-col cols="12" md="6" lg="6" sm="6" xs="6">
                                            <span class="finance_text">{{pf.name}}</span>
                                          </v-col>
                                          <v-col cols="12" md="6" lg="6" sm="6" xs="6">
                                            <span class="finance_text">{{pf.val}}</span>
                                          </v-col>
                                          <hr>
                                        </v-row>
                                      </div>
                                    </v-card>
                                  </v-col>
                                </v-row>
                                <v-row v-if="pa.Plot == 'Table'">
                                    <table style="">
                                      <tr>
                                        <th class="table_header" v-for="(pfi,ft) in pa.Columns" :key="ft">{{pfi}}</th>
                                      </tr>
                                      <tr v-for="(p,key) in pa.Rows" :key="key">
                                        <td v-for="(x,k) in p" :key="k" class="finaTableWrap">
                                          <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                        </td>
                                      </tr>
                                    </table>
                                </v-row>
                              </div>
                            </div>  
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- full panel -->
                  <v-row wrap style="margin-top:7vh;margin-bottom:5vh;margin-left:2vh;margin-right:2vh" v-if="pan.Panel_Info == 'Full_Panel'">
                    <div v-if="pan.Right.Display.Display_Type == 'Plot'">
                      <v-row>
                        <div class="news_heading">{{pan.Left.Header}}</div>
                      </v-row>
                      <div v-for="(pa,keyy) in pan.Right.Display.Display_Info" :key="keyy" style="padding:5vh">
                        <v-row class="mt-3">
                          <v-col>
                            <span v-html="pa.div64"></span>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                    <div v-if="pan.Left.First_Text.length > 0 ">
                      <v-row style="margin-top:-5vh">
                        <div class="news_heading">{{pan.Left.Header}}</div> 
                        <v-col class="mt-4" cols="12" md="2" lg="2" sm="2" xs="2"></v-col>            
                        <v-col class="mt-4" cols="12" md="8" lg="8" sm="8" xs="8">
                            <div v-if="pan.Left.First_Text.length > 0 && pan.Left.First_Text[0] !== ''"> 
                              <div style="font-size: 16px;" v-for="(ppaf,a) in pan.Left.First_Text" :key="a">
                                <span v-html="ppaf"></span>
                              </div>
                              <hr class="line" />
                            </div>
                            <div v-if="pan.Left.Second_Text.length > 0 && pan.Left.Second_Text[0] !== ''">
                              <div style="font-size: 16px;" class="" v-for="(ppas,b) in pan.Left.Second_Text" :key="b">
                                <span v-html="ppas"></span>
                              </div>
                              <hr class="line"/>
                            </div>
                            <div v-if="pan.Left.Third_Text.length > 0 && pan.Left.Third_Text[0] !== ''">
                              <div style="font-size: 16px;" class="" v-for="(ppat,c) in pan.Left.Third_Text" :key="c">
                                <span v-html="ppat"></span>
                              </div>
                              <hr class="line"/>
                            </div>
                            <div v-if="pan.Left.Forth_Text.length > 0 && pan.Left.Forth_Text[0] !== ''">
                              <div style="font-size: 16px;" class="" v-for="(ppafo,d) in pan.Left.Forth_Text" :key="d">
                                <span v-html="ppafo"></span>
                              </div>
                              <hr class="line"/>
                            </div>
                            <div v-if="pan.Left.Fifth_Text.length > 0 && pan.Left.Fifth_Text[0] !== ''">
                              <div style="font-size: 16px;" class="" v-for="(ppafi,e) in pan.Left.Fifth_Text" :key="e">
                                <span v-html="ppafi"></span>
                              </div>
                              <hr class="line"/>
                            </div>
                            <div v-if="pan.Left.Sixth_Text.length > 0 && pan.Left.Sixth_Text[0] !== ''">
                              <div style="font-size: 16px;" class="" v-for="(ppafs,f) in pan.Left.Sixth_Text" :key="f">
                                <span v-html="ppafs"></span>
                              </div>
                              <hr class="line"/>
                            </div>
                            <div v-if="pan.Left.Seventh_Text.length > 0 && pan.Left.Seventh_Text[0] !== ''">
                              <div style="font-size: 16px;" class="" v-for="(ppafss,g) in pan.Left.Seventh_Text" :key="g">
                                <span v-html="ppafss"></span>
                              </div>
                              <hr class="line"/>
                            </div>
                            <div v-if="pan.Left.Eighth_Text.length > 0 && pan.Left.Eighth_Text[0] !== ''">
                              <div style="font-size: 16px;" class="" v-for="(ppafse,h) in pan.Left.Eighth_Text" :key="h">
                                <span v-html="ppafse"></span>
                              </div>
                              <hr class="line"/>
                            </div>
                            <div v-if="pan.Left.Ninth_Text.length > 0 && pan.Left.Ninth_Text[0] !== ''">
                              <div style="font-size: 16px;" class="" v-for="(ppafn,i) in pan.Left.Ninth_Text" :key="i">
                                <span v-html="ppafn"></span>
                              </div>
                              <hr class="line"/>
                            </div>
                            <div v-if="pan.Left.Tenth_Text.length > 0 && pan.Left.Tenth_Text[0] !== ''">
                              <div style="font-size: 16px;" class="" v-for="(ppaft,j) in pan.Left.Tenth_Text" :key="j">
                                <span v-html="ppaft"></span>
                              </div>
                              <hr class="line"/>
                            </div>
                        </v-col>
                        <v-col class="mt-4" cols="12" md="2" lg="2" sm="2" xs="2"></v-col>
                      </v-row>
                    </div>
                    <div v-if="pan.Right.Display.Display_Type == 'Table'">
                      <v-row>
                        <div class="news_heading">{{pan.Left.Header}}</div>
                      </v-row>
                      <v-row v-for="(pa,keytt) in pan.Right.Display.Display_Info" :key="keytt">
                        <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2" v-for="(pbtn,keyyy) in pa.Dropdownnames" :key="keyyy">                       
                          <v-btn :style="pbtn.selected ? 'background-color: #3fa6a1; color: #ffffff;font-size: 14px;margin-top:2vh;font-weight:700;font-family: Ubuntu;' : 'background-color: #ffffff; color: #3fa6a1;font-size: 14px;margin-top:2vh;font-weight:700;font-family: Ubuntu;'" @click="onChangetrials(pbtn,pa.myTable)">{{pbtn.name}}</v-btn>                     
                        </v-col>                     
                        <table class="pl-0" :id="pa.myTable">
                                <span v-if="pa.show_dropdown == true">
                                  <tr>
                                    <th :class="(pa.Table_Data.Table_Type == 'Dual Header' && key7 == 0)? 'dualHeaderRow': 'dualHeaderAnotherRow'" v-for="(p,key7) in pa.Table_Data.Columns" :key="key7">{{p}} <span @click="sort_rows(key7, pa.myTable)" v-show="pa.Table_Data.Sort_Enable == 'True'"><i class="fa fa-arrow-down" style="margin-left: 2vh !important;cursor: pointer;"></i></span></th>
                                  </tr>
                                  <tr v-for="(p,key5) in pa.Table_Data.show_rows" :key="key5">
                                    <td v-for="(x,k1) in p" :key="k1" :class="(pa.Table_Data.Table_Type == 'Dual Header' && k1 == 0)? 'dualHeaderData':'dualHeaderAnotherData'">
                                      <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                    </td>
                                  </tr>
                                </span>
                                
                          <tr v-if="pa.show_dropdown !== true">
                            <th :class="(pa.Table_Data.Table_Type == 'Dual Header' && key77 == 0)? 'dualHeaderRow': 'dualHeaderAnotherRow'"
                              v-for="(p,key77) in pa.Table_Data.Columns" :key="key77">{{p}}<span
                              @click="sort_rows(key77, pa.myTable)" v-show="pa.Table_Data.Sort_Enable == 'True'"><i class="fa fa-arrow-down" style="margin-left: 2vh !important;cursor: pointer;"></i></span>
                            </th>
                            </tr>
                            <tr v-for="(p,key55) in pa.Table_Data.Rows" :key="key55" v-if="pa.show_dropdown !== true">
                              <td v-for="(x,k11) in p" :key="k11" :class="(pa.Table_Data.Table_Type == 'Dual Header' && k11 == 0)? 'dualHeaderData':'dualHeaderAnotherData'">
                                <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                              </td>
                          </tr>
                                
                        </table>
                      </v-row>
                    </div>  
                  </v-row>
                  </div>
                 
                </div>
              </v-row> 
            </div>
            <div v-else>
              <v-row style="text-align:center;margin-top:25vh">
                <v-col aligh="center">
                  <v-progress-circular indeterminate color="#8c8e91"></v-progress-circular>
                </v-col>
              </v-row>
            </div>
                
                       
        </div>
      </div>
      <v-row v-if="TotalCount>0 && hidePagination==false">
        <v-col cols="12" align="left" style="position:fixed;bottom:1px">
          <v-pagination :total-visible="5" color="#03a6ad" v-model="page"  :length="length" @input="show_all_notes" ></v-pagination>
        </v-col>
      </v-row>   
            <template>
              <div class="text-center">
                <v-dialog
                  v-model="show_table_modal"
                  width="800"
                >
                  <v-card class="Path-359" style="padding:3vh 3vh 3vh 6vh !important">
                      <div><v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="show_table_modal = false">mdi-close</v-icon></div>
                      <v-row wrap>
                        <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                          <div v-html="table_display"></div>
                        </v-col>   
                      </v-row>
                  </v-card>
                </v-dialog>
              </div>
            </template>
           <!-- <template>
              <div class="text-center">
                <v-dialog
                  v-model="delete_confirm"
                  width="600"
                >
                  <v-card class="Path-359" style="padding:2vh 0vh">
                    <v-card-text >
                      <v-row>
                        <v-col :cols="!isTabletMob ? '12' :'10'"  sm="6" md="6" lg="6" xs="6" xl="6">
                          <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;margin-right: 3vh;">
                          <span class="Active_Companies_title">Delete Note !</span>
                        </v-col>
                        <v-col v-if="!isTabletMob" cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                        <v-col cols="2"  sm="2" md="2" lg="2" xs="2" xl="2" style="margin-top:1px">
                          <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="delete_confirm = false">mdi-close</v-icon>
                        </v-col>
                      </v-row>
                      <v-row wrap>
                        <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                          <span class="com_nm1">Are you sure you want to delete this note ?</span>
                        </v-col>   
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-row>
                        <v-col v-if="!isTabletMob" cols="12"  sm="6" md="6" lg="6" xs="6" xl="6"></v-col>
                        <v-col sm="3" md="3" lg="3" xs="3" xl="3" >
                          <v-btn                          
                            style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh;"
                             @click="delete_note(note_deleted)"
                          >
                            Yes
                          </v-btn>
                        </v-col>
                        <v-col sm="3" md="3" lg="3" xs="3" xl="3" :style="!isTabletMob ? '' : 'margin-right:4vh'">
                          <v-btn
                            style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 2.2vh;font-family: Ubuntu;"
                            @click="delete_confirm = false"
                          >
                            No
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>  
                  </v-card>
                </v-dialog>
              </div>
          </template> -->
          <template>
            <v-dialog v-model="delete_confirm" width="410">
              <v-card class="Path-359" style="padding:3vh;">
                <v-row wrap no-gutters>
                  <v-col cols="12" align="right" style="padding-bottom:1vh">

                    <v-btn
                      icon
                      color="#009ea5"
                      @click="user_plan=false"
                      
                    >
                      <!-- <v-icon>mdi-close</v-icon> -->
                      <img src="../assets/Icons/Group 1354.svg" @click="delete_confirm = false" >
                    </v-btn>
                  </v-col>
                  <v-col cols="12" align="center" >
                    <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;margin-right: 3vh;margin-bottom: 1vh;">
                    <span class="Active_Companies_title">Delete Note !</span>
                  </v-col>
                  <v-col cols="12" align="center"  style="padding-top:4vh">
                    
                    <span  style="font-family: Ubuntu;font-size:18px;">
                      Are you sure you want to delete this note ?
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- <v-col sm="4" md="4" lg="4" xs="4" xl="4"></v-col> -->
                  
                  <v-col cols="12" align="center">
                    <v-btn style="background-color: #f16753; color: #ffffff;font-size: 1.8vh;width:100px;border-radius: 4px;"  @click="delete_note(note_deleted)"> 
                      
                      Delete
                      
                    </v-btn>
                  </v-col>
                  <!-- <v-col sm="4" md="4" lg="4" xs="4" xl="4"></v-col> -->
                </v-row>
              </v-card>
            </v-dialog>
          </template>
          <template>
              <div class="text-center">
                <v-dialog
                  v-model="note_failed"
                  width="600"
                >
                  <v-card class="Path-359" style="padding:3vh 2vh 3vh 2vh">
                    <v-card-text>
                      <v-row wrap no-gutters>
                        <v-col :cols="!isTabletMob ? '12' :'10'" sm="6" md="6" lg="6" xs="6" xl="6">
                          <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;margin-right: 3vh;">
                          <span class="Active_Companies_title">Delete Note !</span>
                        </v-col>
                        <v-col v-if="!isTabletMob" cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                        <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                          <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="note_failed = false">mdi-close</v-icon>
                        </v-col>
                      </v-row>
                      <v-row wrap style="margin-top:5vh">
                        <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                          <span class="com_nm1">Note deletion failed !</span>
                        </v-col>   
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-row>
                        <v-col sm="6" md="6" lg="6" xs="6" xl="6"></v-col>
                        <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                        </v-col>
                        <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                          <v-btn
                            style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 2.2vh;font-family: Ubuntu;"
                            @click="note_failed = false"
                          >
                            Okay
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>   
                  </v-card>
                </v-dialog>
              </div>
          </template>
          <template>
            <v-dialog v-model="show_popup_modal" width="550">
              <v-card class="Path-359" style="padding:3vh;">
                <v-row wrap no-gutters>
                  <v-col cols="12" align="right" >

                    <v-btn
                      icon
                      color="#009ea5"
                      @click="show_popup_modal=false"
                      
                    >
                      <!-- <v-icon>mdi-close</v-icon> -->
                      <img src="../assets/Icons/Group 1354.svg"  >
                    </v-btn>
                  </v-col>
                  <v-col cols="12" align="center" >
                    <img src="../assets/Icons/Group 1352.svg" style="height: 15vh;width: 15vh;">
                  </v-col>
                  <v-col cols="12" align="center" >
                    
                    <span  style="font-family: Ubuntu;font-size:18px">
                      Please Subscribe to a plan to continue
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- <v-col sm="4" md="4" lg="4" xs="4" xl="4"></v-col> -->
                  
                  <v-col cols="12" align="center">
                    <v-btn style="background-color: #f16753; color: #ffffff;font-size: 1.8vh;width:200px;border-radius: 4px;"  @click="go_to_pricing()"> 
                      
                      Subscribe Now
                      
                    </v-btn>
                  </v-col>
                  <!-- <v-col sm="4" md="4" lg="4" xs="4" xl="4"></v-col> -->
                </v-row>
              </v-card>
            </v-dialog>
          </template>
        </div>
      
    </div>
  </div>

</template>
<script>
//import { EventBus } from "../event-bus.js";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import postscribe from "postscribe";
import { EventBus } from "../event-bus.js";
export default {
data() {
  return {
    auth_token: '',
    diseases: [],
    no_authentication: false,
    all_notes: [],
    loading: false,
    fullPage: true,
    page: 1,
    listCount: 0,
    searchme: "",
    panels_show: false,
    panels: [],
    loading_info: false,
    article_type: '',
    article_title: '',
    notes_content_display: '',
    final_statements1: [],
    final_statements2: [],
    title_header: '',
    show_table_modal: false,
    table_display: {},
    containerId: 0,
    closeOnContentClick: false,
    article_types: ["Industry Updates", "Therapy Landscape", "Clinical Review", "Development Pipeline", "Active Companies"],
    selected_type: [],
    alldiseases: [],
    my_diseases: [],
    expanded: true,
    selected_disease: '',
    selected_article_type: '',
    selected_notes_date: '',
    selected_notes_disease: '',
    selected_notes_article_type: '',
    articles: [],
    title_disease : "Showing All Diseases",
    title_article_type : "Showing All Articles",
    disease_clicked: false,
    articletype_clicked: false,
    dis_page: 1,
    art_page: 1,
    show_message: false,
    delete_confirm: false,
    note_deleted: {},
    note_failed: false,
    TotalCount: 0,
    show_popup_modal: false,
    panel_id: '',
    loading_panel: false,
    module_price_id:'',
    module_price :0,
    moduleId:'',
    note_modules:[],
    length:0,
    hidePagination:false,
  }
},
components: {
  Loading,
},
watch:{
  searchme(newValue){
    if (newValue==''){
      this.show_all_notes(1)
      this.hidePagination=false
    }
  } 

  
},
computed: {
  isTabletMob : function() {

      const iPad = /iPad/i.test(navigator.userAgent);
      const iPhone = /iPhone/i.test(navigator.userAgent);
      const Android = /Android/i.test(navigator.userAgent);
      const webOS = /webOS/i.test(navigator.userAgent);
      const iPod = /iPod/i.test(navigator.userAgent);
      const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
      const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);

      return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
    },
  filteredList() {
    return this.all_notes.filter(item => {
      return JSON.stringify(item).toLowerCase().indexOf(this.searchme.toLowerCase()) > -1
    })
  },
},
created() {
},
mounted() {
  //this.disease = this.$route.params.disease
  var auth_token = localStorage.getItem("authtoken");
  EventBus.$emit("module_name","");
  localStorage.setItem("selected_module","")

  if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
    this.auth_token = auth_token
    this.no_authentication = false
    console.log("auth_token", this.auth_token)
   this.get_user_profile()
    var mypage = 1
  } else {
    this.auth_token = ""
    this.no_authentication = true
  }

  let recaptchaScript = document.createElement('script')
  recaptchaScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/plotly.js/2.8.3/plotly.min.js')
  document.head.appendChild(recaptchaScript)
},
methods: {
  onCancel() {
    console.log('User cancelled the loader.')
  },
  removeScrollListener() {
   console.log("remove scroll listener")
  },
  // page_scrolling(mypage) { 
  
    
    
  //   const listElm = document.getElementById('infinite-list1');
   
   
  //   if (listElm !== null) {
    
       
       
  //     listElm.addEventListener('scroll', e => {
  //       console.log("scrolling")
  //       if(listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
  //         if (this.auth_token !== null && this.auth_token !== "" && this.auth_token !== undefined) {
            
            
  //             this.page = this.page + 1
  //             this.show_all_notes()
                       
  //         }
  //       }
  //     });
    
  //   }
  // },
  show_note_area(hist) {
    console.log("hist", hist)
    if(!hist.subscribed){

      //iterate over modules and match module id with hist.module_note.Module_id
      console.log(this.note_modules,"this.note_modules")
     for (let i=0;i<this.note_modules.length;i++){
       if(this.note_modules[i].ID == hist.module_note.Module_id){
        console.log(this.note_modules[i],"this.note_modules[i]")
         this.module_price_id = this.note_modules[i].Price_id
         this.module_price = this.note_modules[i].Price
         this.moduleId = this.note_modules[i].ID
       }
      }
      this.show_popup_modal = true

      return
    }
    if (!this.no_authentication) {
      this.expanded = false;
      this.panels_show = true
      this.selected_notes_date = hist.notes_date
      this.selected_notes_article_type = hist.title
      this.show_panel(hist.module_note)
    }
  },
 
  get_user_profile() {
    axios
      .get("/userProfile?token=" + this.auth_token)
      .then((res) => {
        if (res.data.Status !== 1) {
            this.user = res.data.Data;
                         
            for (var a = 0; a < this.alldiseases.length; a++) {
              for (var b = 0; b < this.user.Diseases.length; b++) {
                if (this.alldiseases[a] == this.user.Diseases[b]) {
                  this.my_diseases.push(this.alldiseases[a])
                }
              }
            }             
            var unique_diseases = this.my_diseases.filter(this.onlyUnique);
            this.my_diseases = unique_diseases             
            if (this.my_diseases.length > 0) {
                var dis = this.my_diseases.sort(function(a, b) {
                  var textA = a.toUpperCase();
                  var textB = b.toUpperCase();
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                this.my_diseases = dis
            }
            this.get_total_count()
            this.show_all_notes(1)
            var mypage = 1
            // this.page_scrolling(mypage) 
        } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
          this.logOut()
        } else {
          console.log("profile not found")
        }
      })
      .catch((error) => {
        console.log(error.message);
        if (error.message == "Request failed with status code 500") {
          this.logOut()             
        }
      });
  },
  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  },
  get_total_count () {
    axios
      .get("/getmodulenotescount?token=" + this.auth_token)
      .then((res) => {
        //console.log(res.data,"res.data")
        if (res.data.Status !== 1) {
          this.TotalCount = res.data.Data.total_count
          this.length= Math.ceil(this.TotalCount/12)
        } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
          this.logOut()
        } else {
          console.log("total count not found")
        }
      })
      .catch((error) => {
        console.log(error.message);
        if (error.message == "Request failed with status code 500") {
          this.logOut()             
        }
      });
  },
  get_selected_notes() {
    console.log("selected notes")

    
      this.pageno = this.page
      
    
    //console.log(this.pageno,"this.pageno")
    axios.get("/getSelectedModuleNotes?token=" + this.auth_token +  "&page_no=" + this.pageno+"&search="+this.searchme)
      .then((res) => {
        if (res.data.Status != 1) {
          var all_notes = res.data.Data.notes
          if(this.searchme!=""){
            this.hidePagination=true
          }
          var modules = res.data.Data.modules    
          console.log(all_notes,"all_notes")
          console.log(modules,"modules")        
          this.loading = false
          if (all_notes != null) {
            console.log("helllooo", all_notes.length)

            for (var i = 0; i < all_notes.length; i++) {
              console.log('helllll111',modules.length)
              for (var j = 0; j < modules.length; j++) {
                console.log(modules[i],all_notes[i],"matched")
               
                if (all_notes[i].module_note.Module_id == modules[j].ID) {
                  console.log("innnnn")
                  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                  var curr_d = new Date(all_notes[i].module_note.Created_at),
                    curr_month = '' + (curr_d.getMonth() + 1),
                    curr_day = '' + curr_d.getDate(),
                    curr_year = curr_d.getFullYear();
                  all_notes[i].notes_date = [curr_day, monthNames[curr_d.getMonth()], curr_year].join(' ')
                  all_notes[i].title = modules[j].Name
                 
                 
                  if (all_notes[i].module_note.Panel !== undefined) {
                    all_notes[i].module_note.Panel = JSON.parse(all_notes[i].module_note.Panel)
                  }
                }
              }
              this.all_notes.push(all_notes[i])
            }
            if (this.all_notes !== null && this.all_notes.length > 0) {
              this.listCount = this.all_notes.length
              console.log("skndk")
              this.show_message = false
            }

            if (this.listCount == 0 && this.searchme=='') {
              console.log("skndk2566")
              this.show_message = true
            }
            //console.log(this.show_message,"this.show_message")
          } else {
            if (this.listCount == 0 && this.searchme=='') {
              console.log("blankkkk")
              this.show_message = true
            }              
          }
        } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
          this.show_message = true
          this.logout()
          
          //this.$router.push("/").catch(() => { })
        } else {
          this.loading = false
          this.show_message = true
          console.log("notes not found")
        }
      })
      .catch((error) => {
        this.show_message = true
        console.log(error.message);
        if (error.message == "Request failed with status code 500") {
          this.logout()             
        }
      });
  },
  show_all_notes(page) {
    this.all_notes = []
    //this.historyList = []
    this.loading = true
    this.page=page
    
    axios.get("/getallmodulenotes?token=" + this.auth_token + "&page_no=" + this.page+"&search="+this.searchme)
      .then((res) => {
        if (res.data.Status != 1) {
          // console.log(res.data.Data.notes,"dataa")
          if(this.searchme!=""){
            this.hidePagination=true
          }
          var all_notes = res.data.Data.notes
          var modules = res.data.Data.modules  
          this.note_modules=modules      
          this.loading = false
          if (all_notes != null) {
            for (var i = 0; i < all_notes.length; i++) {
              for (var j = 0; j < modules.length; j++) {
                if (all_notes[i].module_note.Module_id == modules[j].ID) {
                  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                  var curr_d = new Date(all_notes[i].module_note.Created_at),
                    curr_month = '' + (curr_d.getMonth() + 1),
                    curr_day = '' + curr_d.getDate(),
                    curr_year = curr_d.getFullYear();
                  all_notes[i].notes_date = [curr_day, monthNames[curr_d.getMonth()], curr_year].join(' ')
                  all_notes[i].title = modules[j].Name

                  // if (all_notes[i].module_note.Panel !== undefined) {
                  //   all_notes[i].module_note.Panel = JSON.parse(all_notes[i].module_note.Panel)
                  // }
                }
              }
              this.all_notes.push(all_notes[i])
            }

            console.log(this.all_notes,"this.all_notes")

            if (this.all_notes !== null && this.all_notes.length > 0) {
              this.listCount = this.all_notes.length
              this.show_message = false
            }

            if (this.listCount == 0) {
              this.show_message = true
            }
          } else {
            if (this.listCount == 0) {
              this.show_message = true
            }              
          }
        } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
          this.logout()
          //this.$router.push("/").catch(() => { })
        } else {
          this.loading = false
          this.show_message = true
          console.log("notes not found")
        }
      })
      .catch((error) => {
        console.log(error.message);
        if (error.message == "Request failed with status code 500") {
          this.logout()             
        }
      });
      
  },
  logout() {
    localStorage.removeItem("authtoken");
    localStorage.removeItem("selectedColorList");
    // localStorage.setItem("selected_article", "Industry Updates");
    this.disesase = "Multiple sclerosis"
    delete axios.defaults.headers.common["Authorization"];
    this.auth_token = null;        

    this.$router.push("/").catch(() => {})
    location.reload()
  },
  changetextLength(obj) {
    if (!obj.textLength) {
      obj.textLength = true
    } else {
      obj.textLength = false
    } 
    this.$forceUpdate();
  },
  show_panel(pan) {
    this.loading_panel=true
    this.notes_content_display = pan.Note
    this.loading_info = false
    this.panel_id=pan.Panel
    // console.log(this.panel_id,"panel_id")
    // to get article type
    //check if this.pan.Panel is object
    console.log(pan.S3key,"pan.S3key")
    if (typeof pan.S3key == undefined || pan.S3key == null || pan.S3key == "") {
      console.log("object")
      this.get_module_content_old(pan.Panel)
    } else {
     
    axios
      .get("/getmoduletype?token=" + this.auth_token + "&module_id=" + pan.Module_id+"&input_id="+pan.Input_id)
      .then((res) => {
        if (res.data.Status != 1) {
          if (res.data.Status != 1) {
            console.log(res.data.Data.Url,"article link found")
            fetch(res.data.Data.Url)
            .then(res => res.json())
            .then((out) => {
              var my_json = out
              
              this.get_module_content(my_json)
            })

            .catch(err => { throw err });
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
          } else {
            console.log("Article link not found")
          }
                   
        } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
          this.logout()
        } else {
          console.log("article not found")
        }
      })
      .catch((error) => {
        console.log(error.message);
        if (error.message == "Request failed with status code 500") {
          this.logout()             
        }
      });
    }
  },
  close_notes_area() {
    this.panels_show = false
    this.notes_content_display = ''
  },
  get_module_content_old(panel) {
    // console.log(panel,"pannnneeel")
    var pan_obj= JSON.parse(panel)
    this.panels=[]

    console.log(pan_obj,"pan_obj")
      this.panels_show = true
      //push pan_obj to panels
      this.panels.push(pan_obj)
      this.loading_info = true
      var scripts = ''
      
      
      scripts = `<script type="text/javascript">
          setTimeout(function(){`

      for (var j = 0; j < this.panels.length; j++) {
        console.log(this.panels[j].Panel_Id,"this.panels[j].Panel_Id")
        //this.panels[j].my_panel_id = this.article_prefix + "_" + j

        this.panels[j].my_panel_id = this.panels[j].Panel_Id
        this.panel_id=this.panels[j].my_panel_id
        // console.log(this.panels[j].my_panel_id,"panel_id",this.panel_id)
        if (this.panels[j].Left.First_Text!=null && this.panels[j].Left.First_Text.length>0 ){
          for (var q = 0; q < this.panels[j].Left.First_Text.length; q++) {
            if (this.panels[j].Left.First_Text[q].includes("<br>")) {
              var txt1 = this.panels[j].Left.First_Text[q].split("<br>").join("\n"); 
              this.panels[j].Left.First_Text[q] = txt1
            }
          }
        }
        if (this.panels[j].Left.Second_Text!=null && this.panels[j].Left.Second_Text.length>0 ){
          for (var q = 0; q < this.panels[j].Left.Second_Text.length; q++) {
            if (this.panels[j].Left.Second_Text[q].includes("<br>")) {
              var txt1 = this.panels[j].Left.Second_Text[q].split("<br>").join("\n"); 
              this.panels[j].Left.Second_Text[q] = txt1
            }
          }
        }
        if (this.panels[j].Left.Third_Text!=null && this.panels[j].Left.Third_Text.length>0 ){
          for (var q = 0; q < this.panels[j].Left.Third_Text.length; q++) {
            if (this.panels[j].Left.Third_Text[q].includes("<br>")) {
              var txt1 = this.panels[j].Left.Third_Text[q].split("<br>").join("\n"); 
              this.panels[j].Left.Third_Text[q] = txt1
            }
          }
        }
        //console.log(this.panels[j].Left.Forth_Text,"Forth_Text")
        if (this.panels[j].Left.Forth_Text!=null && this.panels[j].Left.Forth_Text.length>0 ){
          for (var q = 0; q < this.panels[j].Left.Forth_Text.length; q++) {
            if (this.panels[j].Left.Forth_Text[q].includes("<br>")) {
              //console.log("found")
              var txt1 = this.panels[j].Left.Forth_Text[q].split("<br>").join("\n"); 
              this.panels[j].Left.Forth_Text[q] = txt1
            }
          }
        }
        if (this.panels[j].Left.Fifth_Text!=null && this.panels[j].Left.Fifth_Text.length>0 ){
          for (var q = 0; q < this.panels[j].Left.Fifth_Text.length; q++) {
            if (this.panels[j].Left.Fifth_Text[q].includes("<br>")) {
              var txt1 = this.panels[j].Left.Fifth_Text[q].split("<br>").join("\n"); 
              this.panels[j].Left.Fifth_Text[q] = txt1
            }
          }
        }
        for (var i = 0; i < this.panels[j].Right.Display.Display_Info.length; i++) {
          // to separate count and text for infographic panel 0
          if (this.panels[j].Right.Display.Display_Type == 'Card') {
            this.panels[j].Right.Display.plot_display = true
            if (this.panels[j].Right.Display.Display_Info[i].Text != "" && this.panels[j].Right.Display.Display_Info[i].Value_Type == "text") {
                var count = this.panels[j].Right.Display.Display_Info[i].Text.substring(0, this.panels[j].Right.Display.Display_Info[i].Text.indexOf(' '));
                this.panels[j].Right.Display.Display_Info[i].count = count
                this.panels[j].Right.Display.Display_Info[i].count = count              
                var rem = this.panels[j].Right.Display.Display_Info[i].Text.substring(this.panels[j].Right.Display.Display_Info[i].Text.indexOf(' ') + 1);
                this.panels[j].Right.Display.Display_Info[i].rem_text = rem
              }
              //console.log(this.panels[j].Right.Display.plot_display,"hiiiii card")
          }

          if (this.panels[j].Right.Display.Display_Type == 'Text Box' && this.panels[j].Right.Display.Display_Info[i].Text !== '') {
            if (this.panels[j].Right.Display.Display_Info[i].Text.length > 1050) {
              var newText = this.panels[j].Right.Display.Display_Info[i].Text.substring(0, 1050);
              this.panels[j].Right.Display.Display_Info[i].newText = newText
              this.panels[j].Right.Display.Display_Info[i].textLength = false
              this.panels[j].Right.Display.Display_Info[i].no_show = false  
            } else {
              var newText = this.panels[j].Right.Display.Display_Info[i].Text
              this.panels[j].Right.Display.Display_Info[i].newText = newText
              this.panels[j].Right.Display.Display_Info[i].textLength = false
              this.panels[j].Right.Display.Display_Info[i].no_show = true
            }
            this.panels[j].Right.Display.plot_display = true
          }

          
          // to get base64 image
          if (this.panels[j].Right.Display.Display_Info[i].Value_Type == "image") {
            //console.log("image")
            var image = new Image();
            image.src = 'data:image/png;base64,'+this.panels[j].Right.Display.Display_Info[i].Plot_Data            
            this.panels[j].Right.Display.Display_Info[i].myimage = image
            //console.log(image,"myimage")
          }

          if (this.panels[j].Right.Display.Display_Type == "Table") {
            this.panels[j].Right.Display.Display_Info[i].table_type = this.panels[j].Right.Display.Display_Info[i].Table_Data.Table_Type
            if (this.panels[j].Right.Display.Display_Info[i].Additional_Info !== undefined) {
              var myObj = this.panels[j].Right.Display.Display_Info[i].Additional_Info
              if (Object.keys(myObj).length > 0) {
                this.panels[j].Right.Display.Display_Info[i].show_dropdown = true
                var drpdons = this.panels[j].Right.Display.Display_Info[i].Additional_Info.Dropdowns
                this.panels[j].Right.Display.Display_Info[i].Dropdownnames = []
                for (var d = 0; d < drpdons.length; d++) {
                  var drp = {}
                  if (d == 0) {
                    drp.selected = true
                  } else {
                    drp.selected = false
                  }
                  drp.name = drpdons[d]
                  this.panels[j].Right.Display.Display_Info[i].Dropdownnames.push(drp)
                }

                this.panels[j].Right.Display.Display_Info[i].N_Rows = this.panels[j].Right.Display.Display_Info[i].Additional_Info.N_Rows
                var first_half = this.panels[j].Right.Display.Display_Info[i].N_Rows/2
                var all_rows = this.panels[j].Right.Display.Display_Info[i].Table_Data.Rows
                var first_half_rows = []
                for (var v = 0; v < first_half; v++) {
                   first_half_rows.push(all_rows[v])
                }
                this.panels[j].Right.Display.Display_Info[i].Table_Data.first_half_rows = first_half_rows
                var second_half_rows = []
                for (var w = first_half; w < all_rows.length; w++) {
                   second_half_rows.push(all_rows[w])
                }
                this.panels[j].Right.Display.Display_Info[i].Table_Data.second_half_rows = second_half_rows
                this.panels[j].Right.Display.Display_Info[i].Table_Data.show_rows = this.panels[j].Right.Display.Display_Info[i].Table_Data.first_half_rows

              } else {
                this.panels[j].Right.Display.Display_Info[i].show_dropdown = false
              }
            } else {
              this.panels[j].Right.Display.Display_Info[i].show_dropdown = false
            }
            this.panels[j].Right.Display.Display_Info[i].myTable = "mytable" + j
            this.panels[j].Right.Display.Display_Info[i].search_found = false
            var row_data = this.panels[j].Right.Display.Display_Info[i].Table_Data.Rows
            if (row_data.length > 0) {
              this.panels[j].Right.Display.plot_display = true
              for (var k = 0; k < row_data.length; k++) {
                var mysrch1 = "\n"
                var matstr = ","
                for (var l = 0; l < row_data[k].length; l++) {
                  if (row_data[k][l].includes(mysrch1)) {
                    const pieces = row_data[k][l].split(mysrch1);
                    const resultingString = pieces.join("\n");
                    // if (resultingString.includes(matstr)) {
                    //   const pieces1 = resultingString.split(matstr);
                    //   const resultingString1 = pieces.join(", ");
                    //   resultingString = resultingString1
                    // }
                    row_data[k][l] = resultingString
                  }
                }
              }
            }
            //this.panels[j].Right.Display.Display_Info[i].Table_Data.Rows = row_data
          }


          // to display financial cards info

          if (this.panels[j].Right.Display.Display_Type == 'Financial_Cards' && (this.panels[j].Right.Display.Display_Info[0].Fin_Card_Data !== undefined || this.panels[j].Right.Display.Display_Info[1].Table_Data !== undefined))  {
            //console.log("hi finance")
            var finance_data = this.panels[j].Right.Display.Display_Info[i].Table_Data
            this.panels[j].Right.Display.plot_display = true
            if (finance_data !== undefined){
              //console.log(finance_data.Columns, "columns")
              this.panels[j].Right.Display.Display_Info[i].Columns = finance_data.Columns 
              this.panels[j].Right.Display.Display_Info[i].Rows = finance_data.Rows
             }
          }

          // to display plot

          if (this.panels[j].Right.Display.Display_Type == 'Plot' && this.panels[j].Right.Display.Display_Info[i].Plot_Data !== "") {
              //console.log("Plooot")
               var double_encode = this.panels[j].Right.Display.Display_Info[i].Plot_Data.split('.')[0]
               var plt_data = window.atob(double_encode);

               //console.log(plt_data,"plt_data")

               var obj = JSON.parse(plt_data)

               //console.log(obj,"obj")

               var plot_data = obj.div64.split('.')[0]
               var div = window.atob(plot_data);

               div = div.replace(/\\"/g, '"')
               div = div.replace(/\\'/g, "'")
               div = div.replace(/\r?\n|\r/g, "\n")

               this.panels[j].Right.Display.Display_Info[i].div64 = div
               this.panels[j].Right.Display.plot_display = true

               //console.log(this.panels.Right.Display.Display_Info[i].div64,"div")

               var legend = obj.legend

               //console.log(legend,"legend")
            
               if (legend !== undefined) {
                var colors = {}   
                var code = Object.keys(legend);
                var name = Object.values(legend);
                var color_name = []
                var color_code = []
                for (var z=0; z < name.length; z++) {
                  if (name[z] == "Phase 1") {
                    color_name[0] = name[z]
                    color_code[0] = code[z]
                  } else if (name[z] == "Phase 2") {
                    color_name[1] = name[z]
                    color_code[1] = code[z]
                  } else if (name[z] == "Phase 3") {
                    color_name[2] = name[z]
                    color_code[2] = code[z]
                  } else if (name[z] == "Phase 4") {
                    color_name[3] = name[z]
                    color_code[3] = code[z]
                  } else if (name[z] == "Early Phase 1") {
                    color_name[4] = name[z]
                    color_code[4] = code[z]
                  } else {
                    color_name.push(name[z])
                    color_code.push(code[z])
                  }
                }

                this.panels[j].Right.Display.Display_Info[i].code = color_code
                this.panels[j].Right.Display.Display_Info[i].name = color_name
              }

               var script_data = obj.script64.split('.')[0]
               var script = window.atob(script_data);

               script = script.replace(/\\"/g, '"')
               script = script.replace(/\\'/g, "'")
               script = script.replace(/\r?\n|\r/g, "\n")

               this.panels[j].Right.Display.Display_Info[i].script64 = script

               // console.log(this.panels[j].Right.Display.Display_Info[i].script64,"script")
               //console.log(this.panels[j].Panel_Id,"panels id")

               var str = this.panels[j].Right.Display.Display_Info[i].script64.replace('<script type="text/javascript">', "\n")
               var str1 = str.replace("window.PLOTLYENV=window.PLOTLYENV || {};","\n")
               var str2 = str1.replace("<\/script>", "\n")
               scripts += str2
           }
        }

        if (this.panels[j].Panel_Info == 'Full_Panel') {
          this.all_phases = []
          this.all_companies = []
          if (this.panels[j].Right.Display.Display_Type == "Table") {
            for (var q = 0; q < this.panels[j].Right.Display.Display_Info.length; q++) {
              this.all_companies = this.panels[j].Right.Display.Display_Info[q].Table_Data.Columns
              var rw = this.panels[j].Right.Display.Display_Info[q].Table_Data.Rows
              for (var r = 0; r < rw.length; r++) {
                var phases = {}
                var key = Object.keys(rw[r]);
                var value = rw[r][key];
                phases.names = key
                phases.details = value
                this.all_phases.push(phases)
              }
            }
          }
          //console.log(this.all_phases, "this.all_phases")
        }

      }
      console.log(this.panels,"panelssssss")
      scripts = scripts + `}, 500);<\/script>`
      //console.log(this.panels,"panels")
      postscribe('#gist', scripts)
      
    }, 
  get_module_content(myjson) {
   
    this.panels = myjson.Article.Panel
    this.loading_info = true
    var scripts = ''
    console.log(this.panels,"panels")
    
    scripts = `<script type="text/javascript">
        setTimeout(function(){`
      for (var j = 0; j < this.panels.length; j++) {
        //this.panels[j].my_panel_id = this.article_prefix + "_" + j

        this.panels[j].my_panel_id = this.panels[j].Panel_Id
        // console.log(this.panels[j].my_panel_id,"panel_id",this.panel_id)
        if (this.panels[j].Left.First_Text!=null && this.panels[j].Left.First_Text.length>0 ){
          for (var q = 0; q < this.panels[j].Left.First_Text.length; q++) {
            if (this.panels[j].Left.First_Text[q].includes("<br>")) {
              var txt1 = this.panels[j].Left.First_Text[q].split("<br>").join("\n"); 
              this.panels[j].Left.First_Text[q] = txt1
            }
          }
        }
        if (this.panels[j].Left.Second_Text!=null && this.panels[j].Left.Second_Text.length>0 ){
          for (var q = 0; q < this.panels[j].Left.Second_Text.length; q++) {
            if (this.panels[j].Left.Second_Text[q].includes("<br>")) {
              var txt1 = this.panels[j].Left.Second_Text[q].split("<br>").join("\n"); 
              this.panels[j].Left.Second_Text[q] = txt1
            }
          }
        }
        if (this.panels[j].Left.Third_Text!=null && this.panels[j].Left.Third_Text.length>0 ){
          for (var q = 0; q < this.panels[j].Left.Third_Text.length; q++) {
            if (this.panels[j].Left.Third_Text[q].includes("<br>")) {
              var txt1 = this.panels[j].Left.Third_Text[q].split("<br>").join("\n"); 
              this.panels[j].Left.Third_Text[q] = txt1
            }
          }
        }
        //console.log(this.panels[j].Left.Forth_Text,"Forth_Text")
        if (this.panels[j].Left.Forth_Text!=null && this.panels[j].Left.Forth_Text.length>0 ){
          for (var q = 0; q < this.panels[j].Left.Forth_Text.length; q++) {
            if (this.panels[j].Left.Forth_Text[q].includes("<br>")) {
              //console.log("found")
              var txt1 = this.panels[j].Left.Forth_Text[q].split("<br>").join("\n"); 
              this.panels[j].Left.Forth_Text[q] = txt1
            }
          }
        }
        if (this.panels[j].Left.Fifth_Text!=null && this.panels[j].Left.Fifth_Text.length>0 ){
          for (var q = 0; q < this.panels[j].Left.Fifth_Text.length; q++) {
            if (this.panels[j].Left.Fifth_Text[q].includes("<br>")) {
              var txt1 = this.panels[j].Left.Fifth_Text[q].split("<br>").join("\n"); 
              this.panels[j].Left.Fifth_Text[q] = txt1
            }
          }
        }
        for (var i = 0; i < this.panels[j].Right.Display.Display_Info.length; i++) {
          // to separate count and text for infographic panel 0
          if (this.panels[j].Right.Display.Display_Type == 'Card') {
            this.panels[j].Right.Display.plot_display = true
            if (this.panels[j].Right.Display.Display_Info[i].Text != "" && this.panels[j].Right.Display.Display_Info[i].Value_Type == "text") {
                var count = this.panels[j].Right.Display.Display_Info[i].Text.substring(0, this.panels[j].Right.Display.Display_Info[i].Text.indexOf(' '));
                this.panels[j].Right.Display.Display_Info[i].count = count
                this.panels[j].Right.Display.Display_Info[i].count = count              
                var rem = this.panels[j].Right.Display.Display_Info[i].Text.substring(this.panels[j].Right.Display.Display_Info[i].Text.indexOf(' ') + 1);
                this.panels[j].Right.Display.Display_Info[i].rem_text = rem
              }
              //console.log(this.panels[j].Right.Display.plot_display,"hiiiii card")
          }

          if (this.panels[j].Right.Display.Display_Type == 'Text Box' && this.panels[j].Right.Display.Display_Info[i].Text !== '') {
            if (this.panels[j].Right.Display.Display_Info[i].Text.length > 1050) {
              var newText = this.panels[j].Right.Display.Display_Info[i].Text.substring(0, 1050);
              this.panels[j].Right.Display.Display_Info[i].newText = newText
              this.panels[j].Right.Display.Display_Info[i].textLength = false
              this.panels[j].Right.Display.Display_Info[i].no_show = false  
            } else {
              var newText = this.panels[j].Right.Display.Display_Info[i].Text
              this.panels[j].Right.Display.Display_Info[i].newText = newText
              this.panels[j].Right.Display.Display_Info[i].textLength = false
              this.panels[j].Right.Display.Display_Info[i].no_show = true
            }
            this.panels[j].Right.Display.plot_display = true
          }

          
          // to get base64 image
          if (this.panels[j].Right.Display.Display_Info[i].Value_Type == "image") {
            //console.log("image")
            var image = new Image();
            image.src = 'data:image/png;base64,'+this.panels[j].Right.Display.Display_Info[i].Plot_Data            
            this.panels[j].Right.Display.Display_Info[i].myimage = image
            //console.log(image,"myimage")
          }

          if (this.panels[j].Right.Display.Display_Type == "Table") {
            this.panels[j].Right.Display.Display_Info[i].table_type = this.panels[j].Right.Display.Display_Info[i].Table_Data.Table_Type
            if (this.panels[j].Right.Display.Display_Info[i].Additional_Info !== undefined) {
              var myObj = this.panels[j].Right.Display.Display_Info[i].Additional_Info
              if (Object.keys(myObj).length > 0) {
                this.panels[j].Right.Display.Display_Info[i].show_dropdown = true
                var drpdons = this.panels[j].Right.Display.Display_Info[i].Additional_Info.Dropdowns
                this.panels[j].Right.Display.Display_Info[i].Dropdownnames = []
                for (var d = 0; d < drpdons.length; d++) {
                  var drp = {}
                  if (d == 0) {
                    drp.selected = true
                  } else {
                    drp.selected = false
                  }
                  drp.name = drpdons[d]
                  this.panels[j].Right.Display.Display_Info[i].Dropdownnames.push(drp)
                }

                this.panels[j].Right.Display.Display_Info[i].N_Rows = this.panels[j].Right.Display.Display_Info[i].Additional_Info.N_Rows
                var first_half = this.panels[j].Right.Display.Display_Info[i].N_Rows/2
                var all_rows = this.panels[j].Right.Display.Display_Info[i].Table_Data.Rows
                var first_half_rows = []
                for (var v = 0; v < first_half; v++) {
                   first_half_rows.push(all_rows[v])
                }
                this.panels[j].Right.Display.Display_Info[i].Table_Data.first_half_rows = first_half_rows
                var second_half_rows = []
                for (var w = first_half; w < all_rows.length; w++) {
                   second_half_rows.push(all_rows[w])
                }
                this.panels[j].Right.Display.Display_Info[i].Table_Data.second_half_rows = second_half_rows
                this.panels[j].Right.Display.Display_Info[i].Table_Data.show_rows = this.panels[j].Right.Display.Display_Info[i].Table_Data.first_half_rows

              } else {
                this.panels[j].Right.Display.Display_Info[i].show_dropdown = false
              }
            } else {
              this.panels[j].Right.Display.Display_Info[i].show_dropdown = false
            }
            this.panels[j].Right.Display.Display_Info[i].myTable = "mytable" + j
            this.panels[j].Right.Display.Display_Info[i].search_found = false
            var row_data = this.panels[j].Right.Display.Display_Info[i].Table_Data.Rows
            if (row_data.length > 0) {
              this.panels[j].Right.Display.plot_display = true
              for (var k = 0; k < row_data.length; k++) {
                var mysrch1 = "\n"
                var matstr = ","
                for (var l = 0; l < row_data[k].length; l++) {
                  if (row_data[k][l].includes(mysrch1)) {
                    const pieces = row_data[k][l].split(mysrch1);
                    const resultingString = pieces.join("\n");
                    // if (resultingString.includes(matstr)) {
                    //   const pieces1 = resultingString.split(matstr);
                    //   const resultingString1 = pieces.join(", ");
                    //   resultingString = resultingString1
                    // }
                    row_data[k][l] = resultingString
                  }
                }
              }
            }
            //this.panels[j].Right.Display.Display_Info[i].Table_Data.Rows = row_data
          }


          // to display financial cards info

          if (this.panels[j].Right.Display.Display_Type == 'Financial_Cards' && (this.panels[j].Right.Display.Display_Info[0].Fin_Card_Data !== undefined || this.panels[j].Right.Display.Display_Info[1].Table_Data !== undefined))  {
            //console.log("hi finance")
            var finance_data = this.panels[j].Right.Display.Display_Info[i].Table_Data
            this.panels[j].Right.Display.plot_display = true
            if (finance_data !== undefined){
              //console.log(finance_data.Columns, "columns")
              this.panels[j].Right.Display.Display_Info[i].Columns = finance_data.Columns 
              this.panels[j].Right.Display.Display_Info[i].Rows = finance_data.Rows
             }
          }

          // to display plot

          if (this.panels[j].Right.Display.Display_Type == 'Plot' && this.panels[j].Right.Display.Display_Info[i].Plot_Data !== "") {
              //console.log("Plooot")
               var double_encode = this.panels[j].Right.Display.Display_Info[i].Plot_Data.split('.')[0]
               var plt_data = window.atob(double_encode);

               //console.log(plt_data,"plt_data")

               var obj = JSON.parse(plt_data)

               //console.log(obj,"obj")

               var plot_data = obj.div64.split('.')[0]
               var div = window.atob(plot_data);

               div = div.replace(/\\"/g, '"')
               div = div.replace(/\\'/g, "'")
               div = div.replace(/\r?\n|\r/g, "\n")

               this.panels[j].Right.Display.Display_Info[i].div64 = div
               this.panels[j].Right.Display.plot_display = true

               //console.log(this.panels.Right.Display.Display_Info[i].div64,"div")

               var legend = obj.legend

               //console.log(legend,"legend")
            
               if (legend !== undefined) {
                var colors = {}   
                var code = Object.keys(legend);
                var name = Object.values(legend);
                var color_name = []
                var color_code = []
                for (var z=0; z < name.length; z++) {
                  if (name[z] == "Phase 1") {
                    color_name[0] = name[z]
                    color_code[0] = code[z]
                  } else if (name[z] == "Phase 2") {
                    color_name[1] = name[z]
                    color_code[1] = code[z]
                  } else if (name[z] == "Phase 3") {
                    color_name[2] = name[z]
                    color_code[2] = code[z]
                  } else if (name[z] == "Phase 4") {
                    color_name[3] = name[z]
                    color_code[3] = code[z]
                  } else if (name[z] == "Early Phase 1") {
                    color_name[4] = name[z]
                    color_code[4] = code[z]
                  } else {
                    color_name.push(name[z])
                    color_code.push(code[z])
                  }
                }

                this.panels[j].Right.Display.Display_Info[i].code = color_code
                this.panels[j].Right.Display.Display_Info[i].name = color_name
              }

               var script_data = obj.script64.split('.')[0]
               var script = window.atob(script_data);

               script = script.replace(/\\"/g, '"')
               script = script.replace(/\\'/g, "'")
               script = script.replace(/\r?\n|\r/g, "\n")

               this.panels[j].Right.Display.Display_Info[i].script64 = script

               // console.log(this.panels[j].Right.Display.Display_Info[i].script64,"script")
               //console.log(this.panels[j].Panel_Id,"panels id")

               var str = this.panels[j].Right.Display.Display_Info[i].script64.replace('<script type="text/javascript">', "\n")
               var str1 = str.replace("window.PLOTLYENV=window.PLOTLYENV || {};","\n")
               var str2 = str1.replace("<\/script>", "\n")
               scripts += str2
           }
        }

        if (this.panels[j].Panel_Info == 'Full_Panel') {
          this.all_phases = []
          this.all_companies = []
          if (this.panels[j].Right.Display.Display_Type == "Table") {
            for (var q = 0; q < this.panels[j].Right.Display.Display_Info.length; q++) {
              this.all_companies = this.panels[j].Right.Display.Display_Info[q].Table_Data.Columns
              var rw = this.panels[j].Right.Display.Display_Info[q].Table_Data.Rows
              for (var r = 0; r < rw.length; r++) {
                var phases = {}
                var key = Object.keys(rw[r]);
                var value = rw[r][key];
                phases.names = key
                phases.details = value
                this.all_phases.push(phases)
              }
            }
          }
          //console.log(this.all_phases, "this.all_phases")
        }

      }
      
    scripts = scripts + `}, 500);<\/script>`
    //console.log(this.panels,"panels")
    postscribe('#gist', scripts)
    
  }, 
 
  format_text(txt) {
    var newStr = ""
    if (txt.includes("<br>")) {
      var txt1 = txt.split("<br>").join("\n");
      newStr = txt1.split("Drug names:").join("\n <b>Drug names:</b>");
    }
    this.formated_text = newStr
  },
  sort_rows(n, mid) {
    //console.log(n, mid, "datatatt")
    var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById(mid);    
    rows = [...table.rows];
    var tableSort = (arr) => Object.keys(arr).map((item, index) => ({item, index})).sort((a,b) => {
      if(this.dir == "asc"){
        var oa = table.rows[a.index].getElementsByTagName("TD")[n];
        var ob = table.rows[b.index].getElementsByTagName("TD")[n];
        if(oa && ob){
          return ob.innerText.toLowerCase().localeCompare(oa.innerText.toLowerCase())
        }
      }else{
        var oa = table.rows[a.index].getElementsByTagName("TD")[n];
        var ob = table.rows[b.index].getElementsByTagName("TD")[n];
        if(oa && ob){
          return oa.innerText.toLowerCase().localeCompare( ob.innerText.toLowerCase())
        }
      }
    }).map(({index})=> table.rows[index]);

    var newrows = tableSort(rows);
    if ( this.dir == "asc"){
      this.dir = "desc"
    }else{
      this.dir = "asc"
    }
    while (table.rows.length > 1) {
      table.rows[1].remove();
      //console.log("hello")
    }
    for(var i=0; i<newrows.length; i++){
      table.rows[0].insertAdjacentElement("afterend",newrows[i])
    }

  },
  onChangetrials(nms,tab_id) {
    //console.log(nms,tab_id,"nmss")
    
      for (var i = 0; i < this.panels.Right.Display.Display_Info.length; i++) {
        if (this.panels.Right.Display.Display_Type == "Table") {
          if (tab_id == this.panels.Right.Display.Display_Info[i].myTable) {
            if (this.panels.Right.Display.Display_Info[i].Additional_Info !== undefined) {
              var drop_nms = this.panels.Right.Display.Display_Info[i].Dropdownnames
                if (nms.name == drop_nms[0].name) {
                  this.panels.Right.Display.Display_Info[i].Table_Data.show_rows = []
                  this.panels.Right.Display.Display_Info[i].Table_Data.show_rows = this.panels.Right.Display.Display_Info[i].Table_Data.first_half_rows
                  
                }
                if (nms.name == drop_nms[1].name) {
                  this.panels.Right.Display.Display_Info[i].Table_Data.show_rows = []
                  this.panels.Right.Display.Display_Info[i].Table_Data.show_rows = this.panels.Right.Display.Display_Info[i].Table_Data.second_half_rows
                }
                for (var k = 0; k < drop_nms.length; k++) {
                  if (nms.name == drop_nms[k].name) {
                    drop_nms[k].selected = true
                  } else {
                    drop_nms[k].selected = false
                  }                   
                }
              }               
            }
          }
        }
      this.$forceUpdate();
  },
  show_table(table_data) {
    this.show_table_modal = true
    this.table_display = table_data
  },
  delete_my_note(mynote) {
    this.delete_confirm = true
    this.note_deleted = mynote
  },
  delete_note(note) {
    //console.log(note,"noteeee")
    this.delete_confirm = false
    axios
      .post("/deletemodulenote?token=" + this.auth_token  + "&note_id=" + note.ID) 
      // + "&note_id=" + note.ID
      .then((res) => {
        if (res.data.Status != 1) {
          //console.log("success")
          if (res.data.Data.Message == "Success") {
            this.all_notes = []
            this.page = 1
            this.get_total_count()
            this.show_all_notes(1)
          } else {
            this.note_failed = true
            console.log("note deletion failed")
          }            
        } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
          this.logout()
        } else {
          this.note_failed = true
          console.log("note deletion failed")
        }
      })
      .catch((error) => {
        console.log(error.message);
        this.note_failed = true
        if (error.message == "Request failed with status code 500") {
          this.logout()             
        }
    });
  },
  save_page_activity() {
    //console.log("page activity")
    var disease = ""
    var articleid = ""
    var duration = "0"
    var panel_duration = "0"
    var activity_name = "Page Change"
    var panel_number = "0"
    var pagename = "Notes"
    axios
      .post("/SaveActivity?id=" + articleid + '&token=' + this.auth_token + "&activity_name=" + activity_name + "&disease=" + disease + "&page_name=" + pagename + "&duration=" + duration + "&panel_duration=" + panel_duration)
      .then((res) => {
        if (res.data.Status != 1) {
          //console.log("Activity saved successfully")
        } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
          this.logout()
        } else {
          console.log("Activity save failed")
        }
    })
    .catch((error) => {
      console.log(error.message);
      if (error.message == "Request failed with status code 500") {
        this.logout()             
      }
    });
  },
  show_popup(){
    this.show_popup_modal = true
  },
  go_to_pricing(){
      this.show_popup_modal=false
      if (this.moduleId=='64d4abad39e80392bcb587b3'){
        this.$router.push("/User_pricing");
      }else{
        this.$router.push("/ModulePurchase");
      }
      
    },
}
}
</script>
<style lang="scss" scoped>
.margin_align {
overflow-x: hidden;
overflow-y: auto;
height: 100vh;
}

.left {
height: 100%;
width: 100%;
position: absolute;
left: 0;
padding-left: 2%;
padding-right: 2%;
padding-bottom: 20px;
padding-top: 20px;
margin: 0vh 0vh 5vh 0vh !important;
overflow-y: auto;
overflow-x: hidden;
}

.leftPanelExpand {
width: 100%;
padding-left: 2%;
padding-right: 2%;
padding-bottom: 20px;
padding-top: 20px;
}

.right {
width: 70%;
position: absolute;
right: 0;
overflow-y: hidden;
background: white;
padding-bottom: 5px;
box-shadow: -5px 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.text_notes {
font-size: 16px;
font-weight: 500;
color: #49575d;
margin-bottom:1vh !important;
}

.show_article {
font-size: 14px;
font-weight: 500;
color: #00b2ba;
margin-bottom:1vh !important;
cursor: pointer
}

.v-card-text {
font-family: Ubuntu;
font-size: 16px;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
float: left;
color: #49575d;
padding: 16px 0px 8px;
background: white;
}

.col-md-2 {
flex: 1 0 auto;
width: 12.66666667%;
}

.aa {
background: #009ea5 !important;
font-family: Ubuntu;
font-size: 18px;
font-weight: 500 !important;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: #fff !important;
text-transform: none;
}

.bb {
background: #009ea5 !important;
font-family: Ubuntu;
font-size: 18px;
font-weight: 500 !important;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: #fff;
}

table tbody {
display: table;
width: 100%;
}

table {
display: block;
overflow-x: auto;
white-space: nowrap;
width: 98%;
margin: 2vh;
max-height: 90vh;
overflow-y: auto;
table-layout:fixed; 
border-collapse:collapse;
}

td {
padding: 1vh;
font-size: 16px;
color: #333;
border: 1px solid #D7DBDD;
}

tr {
border-top: 1px solid #D7DBDD;
font-size: 16px;
color: #333;
white-space: pre;
background-color: #ffffff;
}

th {
text-align: center;
padding: 1vh;
background-color: #ebf3f7;
font-family: Ubuntu;
border: 1px solid #D7DBDD; 
}

tr:nth-child(odd) {
background-color: #f3fbfc;
}

.line {
border: 1px solid #797C7D;
background-color: #797C7D !important;
margin: 0.5rem 0;
}

.pre-formatted {
white-space: pre-line;
/* 👈 this is the important part */
}

.news_heading {
margin: 0px 0px 18px 0px;
font-family: Ubuntu;
font-size: 18px;
font-weight: 700;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: #374246;
}

.title1 {
font-family: Ubuntu;
font-size: 13px !important;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 1.4;
letter-spacing: normal;
text-align: left;

color: #384348;
}

.titleCenter {
text-align: center;
margin:0 0.5vw;
}

.cardTitles {
font-family: Ubuntu;
font-size: 13px;
font-weight: 700;
font-stretch: normal;
font-style: normal;
line-height: 1.22;
letter-spacing: normal;
color: #49575d;
}

.cardTitles1 {
font-family: Ubuntu;
font-size: 15px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 1.22;
letter-spacing: normal;
color: #49575d;
}

.itemCountDesign {
font-family: Ubuntu;
font-size: 25px;
font-weight: 500;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: #374246;
margin-left: 4px;
}

.pharmaContent {
font-family: Ubuntu;
font-size: 18px;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: 1.46;
letter-spacing: normal;
text-align: left;
color: #49575d;
margin-top: -5vh;
}

.header_text {
text-transform: capitalize;
font-family: Ubuntu;
font-size: 15px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 1.46;
letter-spacing: normal;
text-align: left;
color: #49575d;
margin-left: 15vh
}

.linktitle {
margin: 5vh 45px 10px 0;
font-family: Ubuntu;
font-size: 17px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: #374246;
}

.hrLine {
border-bottom: 2px solid #374246;
margin: 0rem 0;
max-width: 5vw;
justify-content: center;
display: flex;
color:#374246 !important
}

.hrdialogLine {
border-bottom: 1px solid #374246;
margin: 0rem 0;
width:100%;
justify-content: center;
display: flex;
color:#374246 !important
}

.Rectangle-718 {
width: 30px;
height: 30px;
padding: 3px 5px 5px 9px!important;
background-color: #d5f8fa;
}


.titleCenter {
margin-top: 3vh;
text-align: center;
}

.table_header {
background-color: #3fa6a1;
color: #ffffff;
font-size: 16px;
font-weight: 700;
text-transform: capitalize;
word-wrap:break-word !important;
white-space: -o-pre-wrap; 
word-wrap: break-word;
white-space: pre-wrap; 
white-space: -moz-pre-wrap; 
white-space: -pre-wrap; 
word-wrap:break-word !important;
}

.circle {
border-radius: 50%;
height: 10px;
width: 10px;
border: 2px solid;
float: left;
margin: 10px 3px 0px 0px;
}

.text_left {
font-weight: 600;
color: #49575d;
font-size: 16px
}

.appBarBox {
height: 90px;
padding-left: 2%;
padding-right: 1%;
margin-bottom: 15px;
margin-top: -3vh !important;
margin-left: -3vh;
background-color: #f8fafb;
}

.optionMenu {
color: #009ea5;
font-family: Ubuntu;
font-size: 16px;
font-weight: 600;
}

.popUpVersion {
font-family: Ubuntu;
font-size: 16px;
font-weight: 600;
text-align: left;
color: #404040;
opacity: 0.86;
text-align: center !important
}

.popUpDates {
opacity: 0.86;
font-family: Ubuntu;
font-size: 16px;
font-weight: 300;
text-align: left;
color: #404040;
float: left;
}

.breadcrums {
font-family: Ubuntu;
font-size: 15px;
font-weight: 700;
text-align: left;
text-decoration: none;
}

.v-breadcrumbs__item {
color: #009ea5 !important
}

.v-breadcrumbs__item v-breadcrumbs__item--disabled {
color: #333333 !important
}

.notes_section {
height: 100vh !important;
//background-color: #f9f9fb;
z-index: 2;
margin-top:0vh;
font-family:Ubuntu;
overflow-x: hidden
}

.comments_list {
padding: 1vh 1vh 5vh 1vh;
height: 75vh;
max-height: 75vh;
overflow-y: auto;
background: inherit;
}

.Rectangle-913 {
margin: 2vh;
padding: 0vh 2vh 1.5vh 2vh;
border-radius: 18px;
border: solid 1px #ccc;
background-color: #fff;
}

.date_notes {
font-size: 13px;
font-weight: 500;
color: #8c8e91 !important;
margin-left: 5vh
}

.tablePhases1 {
color: #333;
background-color: #f8fafb;
font-size: 16px;
border: none !important;
border-top: 1px solid #f8fafb !important;
}

.tablePhases2 {
background-color: #f3fbfc;
}

.mech2Btn {
background-color: rgba(255, 103, 80, .1);
border-color: #ff6750;
font-size: 16px;
font-weight: 500;
color: #ff6750;
font-family: Ubuntu;
border-radius: 10px;
padding: 1vh !important;
height: 100% !important;
}

.tablePhases {
position: sticky;
z-index: 1;
left: 0;
border-top: 1px solid #D7DBDD !important;
border-bottom: 1px solid #D7DBDD !important;
border-left: 1px solid #D7DBDD !important;
}

.tablenonphase {
background-color: inherit;
font-size: 16px;
font-weight: 700
}

.fix {
position: absolute;
margin-left: -100px;
width: 100px;
}

.dualHeaderRow {
background-color: #3fa6a1;
color: #ffffff;
font-size: 13px;
font-weight: 700;
text-transform: capitalize;
border-bottom: 1px solid #D7DBDD !important;
border-left: 1px solid #D7DBDD !important;
position: sticky;
z-index: 1;
left: 0;
}

.dualHeaderAnotherRow {
background-color: #3fa6a1;
color: #ffffff;
font-size: 13px;
font-weight: 700;
text-transform: capitalize;
}

.dualHeaderData {  
background-color: #d2edee;
font-size: 13px;
font-weight: 700;
border-top: 1px solid #D7DBDD !important;
border-bottom: 1px solid #D7DBDD !important;
border-left: 1px solid #D7DBDD !important;
position: sticky;
z-index: 1;
left: 0;
word-wrap:break-word !important;
white-space: -o-pre-wrap; 
word-wrap: break-word;
white-space: pre-wrap; 
white-space: -moz-pre-wrap; 
white-space: -pre-wrap; 
word-wrap:break-word !important;
}

.dualHeaderAnotherData {

background-color: inherit;
font-size: 13px;
font-weight: 500;

//width: 100%;
word-wrap:break-word !important;
white-space: -o-pre-wrap; 
word-wrap: break-word;
white-space: pre-wrap; 
white-space: -moz-pre-wrap; 
white-space: -pre-wrap; 
word-wrap:break-word !important;
}

.finaTableWrap{
word-wrap:break-word !important;
white-space: -o-pre-wrap; 
word-wrap: break-word;
white-space: pre-wrap; 
white-space: -moz-pre-wrap; 
white-space: -pre-wrap; 
word-wrap:break-word !important;
}

.finathWrap{
word-wrap:break-word !important;
white-space: -o-pre-wrap; 
word-wrap: break-word;
white-space: pre-wrap; 
white-space: -moz-pre-wrap; 
white-space: -pre-wrap; 
word-wrap:break-word !important;
}

.icon {
font-size: 30px;
}
.finance_text {
font-family: Ubuntu;
font-weight: 500;
font-size: 16px
}
.fincard {
margin: 0.5vh;
padding:2vh;
height:70vh;
overflow-y:scroll;
}

.Path-359 {
box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
border: solid 1px #ff6750 !important;
background-color: #fff;
border-radius: 40px !important;
}

.Active_Companies_title {
margin: 2vh 2vh 2vh 0vh;
font-family: Ubuntu;
font-size: 2.8vh;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: 1.24;
letter-spacing: normal;
text-align: left;
color: #ff6750;
}
.Path-85 {
box-shadow: none !important;
margin: 2vh;
padding: 1vh 3vh 2vh 3vh;
border: solid 0.6px #d3d3d3 !important;
background-color: #fdfefe;
height: 40vh;
border-radius: 7px !important;
}
.com_nm {
margin-top: 3vh;
font-size: 2.2vh;
font-weight: 700;
font-family: Ubuntu;
text-align: center;
color: #383838 !important;
height: 4vh;
}
.com_nm1 {
margin-top: 2vh;
font-size: 2.3vh;
font-weight: 500;
font-family: Ubuntu;
text-align: center;
color: #383838 !important;
}
.noteBox {
position:absolute;
float:bottom;
bottom:80px;
}
.even_panels {
background-color:#f8fafb;
margin:0px -10px 6px 0px;
padding: 0px 30px 40px 70px;
box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.16) !important;
}
.odd_panels {
background-color:#fff;
margin:0px -10px 6px 0px;
padding: 0px 30px 40px 70px;
box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
}

.Path-499 {
height: 30px;
margin: -25px 5.5px 15px 0px;
padding: 2px 3px 3px 2px;
box-shadow: 0 2px 3px 0 rgba(88, 88, 88, 0.16) !important;
border: solid 0.7px #cfd3d5;
border-radius: 7px;
background-color: #fff !important;
}
.link_content {
font-family: Ubuntu;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 1.34;
letter-spacing: normal;
text-align: left;
color:#32393c !important;
font-size: 13px;
text-decoration: none;
}
.textbox_content {
font-family: Ubuntu;
font-size: 15px !important;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.67;
letter-spacing: normal;
text-align: left;
color: #384348;
}
.texttitle {
font-family: Ubuntu;
font-size: 20px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: #374246;
margin-top:2vh
}
.Rectangle-721 {
margin-top:0px;
background-color: #ffffff !important;
top:0; 
margin-left:5vh  
}
.Rectangle-905 {
padding: 2vh 0vh 2vh 2vh;
border-radius: 16px;
box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.22);
border: solid 2px #222545;
background-color: #f2fcfc;
margin-top:5px;
max-height:70vh;
}
.Rectangle-903 {
  height: 42px;
  margin: -2vh 0vh 0vh -2vh;
  padding: 1vh 0vh 0.5vh 2vw!important;
  font-size: 18px;
  text-align: left;
  font-weight: 700;
  color: #ffffff;
  background-color: #00b2ba;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}
.Notes-Centre-101 {
font-family: Ubuntu;
font-size: 20px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.43;
letter-spacing: normal;
text-align: left;
color: #2c2c48;
margin-left:3vh
}

.Showing-All-Diseases {
font-family: Ubuntu;
font-size: 14px;
font-weight: 300;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: #f16753;
}
.Path-704 {
box-shadow: 0 4px 9px 0 rgba(36, 36, 36, 0.16);
border: solid 1px #d9d7d7;
background-color: #fff;
border-radius: 12px;
}
.Path-736 {
min-height: 80px;
padding:0vh 2vh 1vh 2vh;
background-color: #f5f5f5;
border-top-left-radius: 12px;
border-top-right-radius: 12px;
border-bottom-left-radius: 0px;
border-bottom-right-radius: 0px;
}
.Added-on {
font-family: Ubuntu;
font-size: 13px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 2.53;
letter-spacing: normal;
text-align: left;
color: #a0a0a0;
}
.notes_Added_on {
font-family: Ubuntu;
font-size: 14px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 2.53;
letter-spacing: normal;
text-align: left;
color: #a0a0a0;
margin-left: 3vh;
}
.Article-Type {
font-family: Ubuntu;
font-size: 13px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 1.73;
letter-spacing: normal;
text-align: left;
color: #80808b;
margin-top:0vh !important
}
.Therapy-Landscape-type {
font-family: Ubuntu;
font-size: 13px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.73;
letter-spacing: normal;
text-align: left;
color: #2c2c48;
}
.note_content_type {
font-family: Ubuntu;
font-size: 13px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.73;
letter-spacing: normal;
text-align: left;
color: #2c2c48;
margin-left: 3vh;
}
.notes_text {
font-family: Ubuntu;
font-size: 13px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.67;
letter-spacing: normal;
text-align: left;
color: #2c2c48;
}

.dropdownStyle {
font-family: Ubuntu;
font-size: 15px;
font-weight: 500;
color: #49575d !important;
text-transform: capitalize !important;
}
.Rectangle-1062 {
// height: 125px;
padding: 20px 12px 10px 12px;
background-color: #f5f5f5 !important;
}
</style>