<template>
  <v-main>
   
     <div class="margin_align" style="margin-top:1.5vh;"  >  
         
              <div id="gist_1" >
                    <v-row style="padding:0vh 1vh;box-shadow: inset 0 0 0 1vh #fff;" >
                     
                      <v-col col="12" sm="3" md="3" lg="3" xs="3" xl="3"  style="height:100vh;background-color: #edf7f9 !important;box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.26);padding-top:8.5vh;padding-bottom:0vh;z-index:1">
                        <v-row style="margin-bottom:1px;margin-top:0vh;background-image: linear-gradient(to bottom, #013d48 20%, #4a93a2);" >
                         
                            <!-- <v-row v-if="module_id==undefined" style="margin-top:0vh;margin-left:0vh">
                              
                                  <v-col col="12" sm="6" md="6" lg="6" xs="6" xl="6" align="right" style="padding-right:0" >
                                    <div class="Path-1166">
                                      <span style="font-size: 2vh;
                                        font-weight: 500;
                                        font-stretch: normal;
                                        font-style: normal;
                                        border-radius: 10px;
                                        text-transform:  capitalize;
                                        color:#0a5053 !important">
                                        <img  style="padding-bottom:0px;width:2.5vh;height:2.5vh;margin-right:0.8vh" src="../assets/Icons/Group 1362.svg" class="">
                                        Analysis
                                      </span>
                                    </div>
                                  </v-col>
                                  <v-col col="12" sm="6" md="6" lg="6" xs="6" xl="6" align="center" style="padding-top:2.8vh;padding-left:0;cursor:pointer"  @click="gotoResearch()">
                                    <span style="font-size: 2vh;
                                    font-weight: 500;
                                    font-stretch: normal;
                                    font-style: normal;
                                    border-radius: 10px;
                                    text-transform:  capitalize;
                                    color:#ffff !important;
                                    cursor:pointer
                                    ">
                                    <img  style="padding-bottom:0px;width:2.5vh;height:2.5vh;margin-right:0.8vh;cursor:pointer" src="../assets/Icons/Group 1364.svg" class="">Research

                                    </span>
                                  </v-col>                       
                                </v-row> -->
                      </v-row> 
                        <v-row v-if="prevPages>0 && previousChat.length>0" style="margin:1vh 0 0vh 0vh;">
                          <v-col cols=12 sm="2" md="2" xl="2" xs="2" style=margin-top:1vh  >
                            <span style="text-align: left;color: #202527; font-family: Ubuntu;
                            font-size: 2vh;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.22;
                            letter-spacing: normal;
                            text-align: left;
                            ">
                              {{"Chats"}}
                            </span>
                            
                          </v-col>
                          <v-col @click="addNewChat()" style="cursor:pointer" cols=12 sm="10" md="10" xl="10" xs="10" align="right" >
                            <!-- <v-tooltip right color="#03a6ad">
                              <template v-slot:activator="{on }" >
                             -->
                            <!-- create v-btn  -->
                              <v-btn style="width:auto;font-size:1.4vh;color:#fff;background-color:#f16753;border-radius:6px;box-shadow:none"> <img style="padding-bottom:0px:width:2vh;height:2vh;margin-right:1vh" src="../assets/Icons/Group 1101.svg" >  Add New Chat</v-btn>
                          <!-- </template>
                            <span style="color:#ffff">New Chat</span>
                            </v-tooltip> -->
                          </v-col>
          
                        </v-row>
                       
                        <div id="prev_Chat" v-if="prevPages>0 && previousChat.length>0"  style="height:65vh;overflow-y: scroll;overflow-x:hidden;margin-left:0.5vh;   ;
                           ">
                          <div >
                          <v-row  v-for="(que,kque) in previousChat" :key="kque" style="padding: 0vh 3vh 0vh 20px;cursor:pointer" >
                            <div class="activeThread" id="activeCard"  @click="Initialclickthread(que,1), addSelectedQuestion(que)" :style="que.Selected   ? ' border: solid 2px #03a6ad;': ' border: none;  '" style=" box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border: solid 1px #dfe5e3;background-color: #fff;margin-top:2vh;border-radius:2vh;">
                              <v-row style="padding: 1vh 2vh 2vh 2vh" >
                                <v-col  col="12" sm="1" md="1" xl="1" xs="1" align="center"  style="padding:1.5vh 1vh 0 0" >
                                  <img  style="padding-bottom:10px" src="../assets/Icons/Group 1096.svg" class="Group-1096">
                                </v-col>
                                <v-col  col="12" sm="11" md="11" xl="11" xs="11" style="padding:1vh 0 1vh 0;" >
                                  <span   v-if="que.edit!=true" style="overflow: hidden;
                                  white-space: nowrap;
                                  ;max-width: 100%; display:block;  text-overflow: ellipsis" class="text-class">
                                  
                                    {{ que.Name }}
                                  </span>
                                  <!-- create v-textarea with v-model  que.Question -->
                                  <v-text-field v-model="new_thread_name" v-if="que.edit==true"
                                  class="text-class"
                                  style="color:#03a6ad;caret-color:#03a6ad !important"
                                 
                                  hide-details
                                  auto-grow
                                  >
                                  </v-text-field>

                                  <v-row>
                                    <v-col  col="12" sm="9" md="9" xl="9" xs="9"  >
                                      <span class="date">
                                        {{que.Date}}
                                       
                                      </span>
                                      
                                    </v-col>
                                    <v-col v-show="que.Selected" col="12" sm="3" md="3" xl="3" xs="3" >
                                      <img @click="RemoveChatModal(que.Key)" style="height:2vh;width:2vh;float:left" src="../assets/Icons/Group 975.svg" >
                                      <span v-show="que.edit==false"  @click.stop="EditQuestion(que)"> <img  style="height:2vh;width:2vh;float:right" src="../assets/Icons/Group 974.svg" ></span>
                                      <span v-show="que.edit==true"  @click.stop="SaveNewQuestion(que)"><img  style="height:2vh;width:2vh;float:right" src="../assets/Icons/tick.svg" ></span>
                                    </v-col>
                                    
                                    
                                  </v-row> 
                                 
                                  
                                </v-col>
                              </v-row>
                              
                            </div>
                           
                          </v-row>
                          </div>
                          <!-- add pagination to previousChat -->
                          
                          </div>
                        
                         <div  v-else-if="!loadingPrev && prevPages==0 && previousChat.length==0 && !startLoading" style="display:flex;align-items:center;justify-content:center;height:100%">
                          <span style="font-size:16px;color:#8c8e91;font-family: Ubuntu; font-weight: normal;">
                            {{"No Chats Yet"}}
                          </span>
                         </div>
                        <div  v-else-if="!loadingPrev && previousChat.length==0 && prevPages!=0 && !startLoading" style="display:flex;align-items:center;justify-content:center;height:100%">
                          <span style="font-size:16px;color:#8c8e91;font-family: Ubuntu; font-weight: normal;">
                            {{"No More Chats"}}
                          </span>
                         </div>
                        <v-row v-if="prevPages>0">
                          <v-col cols="12" sm="3" md="3" xl="3" xs="3" style=";position:fixed;bottom:1.5vh">
                            <v-pagination :total-visible="5" color="#03a6ad" v-model="prevPage" :length="length" @input="previousChats" ></v-pagination>
                          </v-col>
                        </v-row>
                        <div  v-if="loadingPrev || startLoading" >
                          <v-row style="text-align:center;margin-top:25vh">
                            <v-col aligh="center">
                              <v-progress-circular indeterminate color="#8c8e91"></v-progress-circular>
                            </v-col>
                          </v-row>
                          
                        </div>
                      </v-col>
                      <v-col  id="bottom" col="12" sm="9" md="9" xl="9" xs="9" style="padding-top:9vh;" >
                        <v-row v-if="sampleScrolled && queans.length==0 && !chat_loading">
                          <v-col cols="12" align=left >
                            <div style=" display: flex;
                            justify-content: left;
                            align-items: left;">
                              <img  style="padding-bottom:10px;width:7vh;height:7vh" src="../assets/Icons/Group 1095.svg" >
                            </div>
                            <div style=" display: flex;
                            justify-content: left;
                            align-items: left;
                            margin-left:1vh"> 
                              <span style="font-family: Ubuntu;
                              font-size: 1.5vh;
                              font-weight: 500;
                              font-stretch: normal;
                              font-style: normal;
                              line-height: 1.31;
                              letter-spacing: normal;
                              text-align: center;
                              color: #227c80;">
                                {{"KnolChat"}}
                              </span>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row v-if="queans.length==0 && !chat_loading ">
                          <v-col cols="12" align=center style="margin-top:1vh">
                            <div style=" display: flex;
                            justify-content: center;
                            align-items: center;" 
                            v-if="!sampleScrolled">
                              <img  style="padding-bottom:10px;height:10vh;width:10vh" src="../assets/Icons/Group 1095.svg" >
                            </div>
                            <div style=" display: flex;
                            justify-content: center;
                            align-items: center;"
                            v-if="!sampleScrolled"> 
                              <span style="font-family: Ubuntu;
                              font-size: 3vh;
                              font-weight: 500;
                              font-stretch: normal;
                              font-style: normal;
                              line-height: 1.31;
                              letter-spacing: normal;
                              text-align: center;
                              color: #227c80;">
                                {{"KnolChat"}}
                              </span>
                            </div>
                            <v-row>
                              <v-col cols="12" sm="1" md="1" xl="1" xs="1" ></v-col>
                              <v-col cols="12" sm="10" md="10" xl="10" xs="10" >
                                <v-row wrap no-gutters v-show="!sampleScrolled">
                                  <v-col style="margin-bottom:8px">
                                    <span style="font-size:14px;color:#8c8e91;font-family: Ubuntu; font-weight: normal;">Scroll to see more..</span>
                                  </v-col>
                                </v-row>
                                <div id="sample_scroll" @scroll="scrolling_sample()"  :style="!sampleScrolled?'max-height:50vh;overflow-y:scroll;overflow-x:hidden;':'max-height:65vh;overflow-y:scroll;overflow-x:hidden;margin-top:-5.5vh'">
                                 
                                <v-row v-masonry="containerId" >
                                  <v-col  cols="12" sm="4"  v-masonry-tile v-for="(q,k) in sample_questions" :key="k">
                                   
                                    <v-card id="sample_que" style="background-color:#f6f8f9 ;padding:2vh;box-shadow:none;cursor:pointer;border: solid 0.5px #DDDFE0;border-radius:6px "  @click=" sampleSelect(q.Questions)" >
                                        
                                        
                                        <span style="width: 149.2px;
                                        height: 134.5px;
                                        margin: 13.3px 0 0;
                                        font-family: Ubuntu;
                                        font-size: 1.6vh;
                                        font-weight: 300;
                                        font-stretch: normal;
                                        font-style: normal;
                                        line-height: 1.67;
                                        letter-spacing: normal;
                                        text-align: left;
                                        color: #595962;">
                                          {{q.Questions}}
                                        </span>
                                        <br>
                                        
                                     
                                      <!-- <span style="font-family: Ubuntu;">{{q.Questions}}</span> -->
                                    </v-card>
                                  </v-col>
                              </v-row>
                              </div>  
                              </v-col>
                              <v-col cols="12" sm="1" md="1" xl="1" xs="1" ></v-col>
                            </v-row>
                        </v-col>
                        </v-row>
                        <v-row v-else-if="chat_loading">
                          <v-col cols="12" align="center"  style="margin-top:30vh">
                            <v-progress-circular indeterminate color="#8c8e91"></v-progress-circular>
                          </v-col>
                        </v-row>
                        <v-row style=" margin-top:0vh" v-else>
                          <div class="scroll-container" ref="scrollContainer"  style="max-height:77vh;overflow-y: scroll;overflow-x:hidden;padding-bottom:2vh;" >
                            <div  v-if="canLoadMore" align="center" style="margin-top:2vh" ><v-progress-circular   indeterminate color="#0a5053"></v-progress-circular></div>
                            <div class="sentinel" ref="sentinel"></div>
                            <div class="list-container" ref="listContainer">
                            <div id="infinite-list"   v-for="(que,kque) in queans" :key="kque" style="margin:2vh;" v-show="que.Display != false && que.Regen!=true">
                             
                              <div style="padding:2vh 2vh 3vh 2vh;background-color: #edf7f9;position: relative;">
                                <v-row  class="question_row" >
                                
                                  <v-col  col="12" sm="1" md="1" lg="1" xs="1" xl="1" align=center style="padding-right:0;cursor:pointer">
                                    <div v-if="que.showcheckbox==false"   @click="createCheckbox(que)" class="Ellipse-154-chat" style=" display:flex;align-items:center;justify-content:center">
                                      <span style="font-family: Ubuntu;
                                      font-size: 1.5vh;
                                      font-weight: 300;
                                      font-stretch: normal;
                                      font-style: normal;
                                      line-height: 1.07;
                                      letter-spacing: normal;
                                      text-align: center;
                                      color: #68adb7;
                                      text-align:center;
                                                                         
                                      ">
                                        {{ mail_initials}}
                                      </span>
                                    </div>
                                   
                                    <div style="margin-top:0px;display:flex;align-items:center;margin-left:3.2vh" @click="checkQuestion(que) ,que.showcheckbox=false" v-if="que.showcheckbox">
                                     
                                        <img src="../assets/Icons/Group 992.svg" style="width:3.5vh;height:3.5vh;" >
                               
                                     
                                      <!-- <span class="circle1" v-show="que.Checked == 'Not_selected'" ></span> -->
                                    </div>
                                  </v-col>   
                                
                                     
                                  
                                  
                                  <v-col col="12" sm="10" md="10" lg="10" xs="10" xl="10" style=padding-left:0; :style="que.Regen_count!=undefined &&  que.Regen_count!=0?'' : ''">
                                    <span  v-if="que.Show_save!=true" class="text-class">{{que.Question}}</span>
                                    <v-text-field v-model="regen_question" v-if="que.Show_save==true"
                                    class="text-class"
                                    style="color:#03a6ad;caret-color:#03a6ad !important"
                                  
                                    hide-details
                                    auto-grow
                                    >
                                    </v-text-field>
                                  </v-col>
                                  <v-col col="12" sm="1" md="1" lg="1" xs="1" xl="1" align="center" style="padding-top:1.2vh;margin-left:-3vh">
                                    <!-- display image on hover only -->

                                    <v-tooltip right color="#03a6ad">
                                      <template v-slot:activator="{ on, attrs }" >
                                        <img v-bind="attrs" v-on="on" id="edit" v-if="que.Non_regenerative!=true && que.Answer!='' && !loadingAnswer"  @click="regenQuestion(que)" src="../assets/Icons/regenerate.svg" style="width:2.3vh;height:2.3vh;cursor:pointer;margin-left:10px;margin-top:0.7vh;float: left !important" >
                                        </template>
                                        <span style="color:#ffff">Regenerate</span>
                                    </v-tooltip>     
                                    <v-tooltip right color="#03a6ad">
                                      <template v-slot:activator="{ on, attrs }" >
                                        <img v-bind="attrs" v-on="on" @click="RemoveQuestionModal(que)" src="../assets/Icons/Group 988.svg" style="cursor:pointer; !important;float: right !important" >
                                        </template>
                                        <span style="color:#ffff">Remove</span>
                                    </v-tooltip>                                    
                                     
                                  </v-col>
                                  
                                  <v-row style="margin-bottom:1vh" v-if="que.Show_save==true">
                                    <v-col col="12" sm="6" md="6" lg="6" xs="6" xl="6" align="right">
                                      <v-btn @click="regenQuestion(que)" style=" background-color:#009ea5;  color:#ffff;font-size: 12px;height: 30px;border-radius: 4px;box-shadow: none;border: solid 2px #03a6ad;width:120px">Save & Submit</v-btn> 
                                    
                                    </v-col>
                                    <v-col col="12" sm="6" md="6" lg="6" xs="6" xl="6">
                                       <v-btn @click="cancelRegen(que.Question_id)" style=" background-color:#009ea5;  color:#ffff;font-size: 12px;height: 30px;border-radius: 4px;box-shadow: none;border: solid 2px #03a6ad;width:120px">Cancel</v-btn>
                                    </v-col>
                                  </v-row>
                                </v-row>
                                <!-- <v-row wrap no-gutters style="padding:0" >
                                <v-col cols="12" sm="1" md="1" lg="1" xs="1" xl="1" >
                                 
                                </v-col>
                                <v-col cols="12" sm="11" md="11" lg="11" xs="11" xl="11" >
                                  <span style="font-size:1.4vh;margin-left:-1.5vh;color:#838786;font-weight:600" v-if="que.Corrected_entities_count>0"> &bull; &nbsp;{{ que.Corrected_entities_array}}</span>
                                </v-col>
                             
                              </v-row> -->
                              </div>
                              <!-- <v-row wrap no-gutters style="margin-top:0.5vh;background-color:#E9F3E4" >
                                <v-col cols="12" sm="1" md="1" lg="1" xs="1" xl="1" >
                                 
                                </v-col>
                                <v-col cols="12" sm="11" md="11" lg="11" xs="11" xl="11" style="">
                                  <span style="padding:1vh;font-size:1.6vh" v-if="que.Corrected_entities_count>0">{{que.Corrected_entities_array}}</span>
                                </v-col>
                             
                              </v-row> -->
                             
                              <div style="margin-top:3vh !important;margin-bottom:3vh">
                                <v-card style="box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
                                border: solid 1px #e6e6e6;
                                background-color: #fff;border-radius:14px;padding:1vh 0 2vh 0">
                                <v-row style="margin-top:0vh;margin-left:1vh;" >
                                  <v-col cols="12" sm="1" md="1" lg="1" xs="1" xl="1"    align="center">
                                    <div class="Ellipse-knolchat" style="display: flex;
                                    align-items: center;justify-content:center" >
                                     
                                      <img src="../assets/Icons/Knolense_Dark111.svg"  >
                                      
                                    </div>
                                   
                                    <br>
                                    <span @click="nextQuestion(que,'left')" style="color:#a0a0a0;cursor:pointer" v-if="que.Regen_count!=undefined &&  que.Regen_count!=0" >{{"< "}}</span>
                                    <span style="color:#a0a0a0" v-if="que.Regen_count!=undefined &&  que.Regen_count!=0" >{{que.Regen_no}}</span>
                                    <span style="color:#a0a0a0" v-if="que.Regen_count!=undefined &&  que.Regen_count!=0" >{{"/"}}</span>
                                    <span style="color:#a0a0a0" v-if="que.Regen_count!=undefined &&  que.Regen_count!=0" >{{que.Regen_count}}</span>
                                     <span @click="nextQuestion(que,'right')" style="color:#a0a0a0;cursor:pointer" v-if="que.Regen_count!=undefined &&  que.Regen_count!=0" >{{" >"}}</span>
                                  </v-col>
                                  <v-col col="12" sm="10" md="10" lg="10" xs="10" xl="10"  style="padding-left:0" v-if="que.regenLoading">
                                   
                                    <v-row>
                                     <v-col cols=12 align="center">
                                       <v-progress-circular indeterminate :size="25" :width="2" color="#8c8e91"></v-progress-circular>
                                     </v-col>
                                    </v-row>
                                    
                                  </v-col>
                                  <v-col  cols="12" sm="10" md="10" lg="10" xs="10" xl="10" v-else-if="que.Answer!=''" style="padding-top:2vh;padding-left:0">                                                           
                                   <span style="font-size:1.4vh;margin-left:-1vh;color:#838786;font-weight:600;" v-if="que.Corrected_entities_count>0"> &nbsp;{{ que.Corrected_entities_array}} </br></span>
                                  <br>
                                 
                                  <span :id="que.Question_id" v-if="que.Question_type=='text'"  style="margin-left:0vh;" class="text-class" v-html="que.Answer"></span>                              
                                  </v-col>                                
                                  <v-col cols="12" sm="10" md="10" lg="10" xs="10" xl="10" v-else-if="que.Answer=='' && loadingAnswer">
                                   
                                    <!-- <v-row style="margin-bottom:10vh">
                                      <v-col align="right" >
                                        <span class="text-class" style="margin-left:10vh" > {{answerStatus}}</span>
                                      </v-col>
                                    </v-row> -->
                                  <v-row  align="center" justify="center">

                                    <v-col :id="que.Question_id" style="height:15vh;display: flex;justify-content: center;align-items: center;margin-bottom:4vh">
                                    
                                      <br>
                                      <div  class="loading-dots">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                      </div>
                                    </v-col>
                                  </v-row>
                                    <!-- <span class="text-class" style="margin-left:10vh" > <v-progress-circular indeterminate :size="15" :width="2" color="#8c8e91"></v-progress-circular><i> {{answerStatus}}</i></span> -->
                                  </v-col>
                                  <v-col v-else  cols="12" sm="10" md="10" lg="10" xs="10" xl="10"></v-col>

                                 <v-col cols="12" sm="1" md="1" lg="1" xs="1" xl="1" style="margin-top:0.5vh" >
                                  <v-tooltip left color="#03a6ad">
                                    <template v-slot:activator="{ on, attrs }" >
                                      <img src="../assets/Icons/Group 1745.svg"   v-bind="attrs"
                                      v-on="on"  v-show="que.Answer!='' && que.Copy_id==true" :style="que.Question_type=='htmlfile'? 'cursor:pointer;margin-left:-7.5vh':'cursor:pointer;margin-left:-3.5vh'"  @click="copyId(que.Question_id)"  style="width: 18.2px;height: 18.2px;cursor:pointer;margin-left:2vh" />    
                                      </template>
                                      <span style="color:#ffff">{{copymsg}}</span>
                                  </v-tooltip>
                                   <v-tooltip left color="#03a6ad">
                                    <template v-slot:activator="{ on, attrs }" >
                                      <img src="../assets/Icons/Group 1747.svg"   v-bind="attrs"
                                      v-on="on"  v-show="que.Answer!=''"  @click="show_json(que.Footnote,que.Question_id)" :style="que.Copy_id!=true && que.Question_type=='htmlfile' ?'margin-left:-3.5vh':(que.Question_type!='htmlfile' ? 'margin-left: 2vh' : 'margin-left: 1.8vh') "  style="width: 18.2px;height: 18.2px;cursor:pointer;" />    
                                      </template>
                                      <span style="color:#ffff">References</span>
                                    </v-tooltip>
                                    <v-tooltip left color="#03a6ad">
                                      <template v-slot:activator="{ on, attrs }" >
                                        <img src="../assets/Icons/Group 1383.svg" v-bind="attrs"
                                        v-on="on" v-show="que.Question_type=='htmlfile'" @click="downloadData(que.Plot_id)"  :style="que.Copy_id!=true?'margin-left:1.5vh':'margin-left:2vh'" style="width: 18.2px;height: 18.2px;cursor:pointer;"/>    
                                        </template>
                                        <span style="color:#ffff">Download Data</span>
                                    </v-tooltip> 
                                  </v-col>                                 
                                </v-row>

                               
                                <v-row :id="que.id" style="padding-top:0vh;padding-bottom:1vh;margin-top:0vh;" v-if="que.Question_type=='htmlfile'">
                                 
                                  <v-col col="12" sm="1" md="1" lg="1" xs="1" xl="1"></v-col>
                                  <v-col col="12" sm="11" md="11" lg="11" xs="11" xl="11"  style="padding-left:0" v-if="que.regenLoading">
                                   
                                   <!-- <v-row>
                                    <v-col cols=12 align="center">
                                      <v-progress-circular indeterminate :size="25" :width="2" color="#8c8e91"></v-progress-circular>
                                    </v-col>
                                   </v-row> -->
                                   
                                 </v-col>
                                  <v-col col="12" sm="11" md="11" lg="11" xs="11" xl="11" style="padding-top:2vh;margin-left:5vh"  v-else-if="que.Answer!='' && que.Question_type!='text'">
    
                                    <img v-if="que.Question_type=='image'" :src="`data:image/png;base64,${que.Answer}`"/>
                                    <!-- <span v-if="que.Question_type=='htmlfile'" v-html="plotdiv"></span> -->
                                    <v-row :id="que.Question_id" v-if="que.Question_type=='htmlfile'" >
                                      <v-col cols="12"  :id="que.Plot_div" >                                                               
                                          <span :id="que.Question_id"  v-html="que.div64"></span>
                                      </v-col>
                                      </v-row>
                                      
                                    <!-- <img  v-if="que.Type=='image'" src="data:image/png;base64,"+que.Answer+" /> -->
                                  </v-col>                                                                     
                                </v-row>
                                <div v-if="que.Suggestions!=undefined && que.Suggestions!= null && que.Suggestions.length>0 && que.Answer!='' && que.Is_suggestions" style="display:flex;flex-direction:column;margin:2vh 12vh 2vh 12vh">
                                  <!-- create forloop and display chips for suggestions -->
                                  <div><span style="font-size:12px"><b>{{"Did you mean?"}}</b></span></div>
                                  <div style="display:flex;flex-direction:column;flex-wrap: wrap;margin-left:-0.5vh;align-items:flex-start;justify-content:center">
                                    <template v-for="(suggestion, index) in que.Suggestions">
                                        <!-- Entity Label -->
                                        <div style="margin:1vh 0.5vh 0.5vh 0.5vh;display:flex;flex-direction:row;justify-content:center;align-items:center">
                                            <span style="font-family: Ubuntu;
                                                         font-size: 14px;   
                                                         font-stretch: normal;
                                                         font-style: normal;
                                                         line-height: 1.67;
                                                         letter-spacing: normal;
                                                         text-align: left;
                                                         ">
                                                {{ suggestion.entity+ " :" }} 
                                            </span>
                                        
                                            <template v-for="(value, index) in suggestion.type">
                                                <v-chip color="#edf7f9" style="margin:0vh 0.5vh 0vh 0.5vh;" @click="replaceType(suggestion,value,que.Question_id,que.Replaced_question)">
                                                    <span style="font-family: Ubuntu;
                                                                 font-size: 11px;   
                                                                 font-stretch: normal;
                                                                 font-style: normal;
                                                                 line-height: 1.67;
                                                                 letter-spacing: normal;
                                                                 text-align: left;
                                                                 color: #03a6ad;">
                                                        {{ value }}
                                                    </span>
                                                </v-chip>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                                
                                </div>
                                <v-row wrap no-gutters v-if="que.Answer!='' "  justify="center">
                                  <v-col  col=12 sm="2" md="2" lg="2" xs="2" xl="2" align=center ></v-col>
                                  <v-col  col=12 sm="8" md="8" lg="8" xs="8" xl="8" align=center >
                                    <!-- <div class="Rectangle-1517_1">
                                      <img  v-if="que.Review_status==0 || que.Review_status!=1"  src="../assets/Icons/Group 1380.svg" style="width: 2vh;height: 2vh;cursor:pointer" @click="que.Review_status=1,GetReview(1,que.Question_id)"/>
                                      <img  v-if="que.Review_status==1"  src="../assets/Icons/Group 1214.svg" style="width: 2vh;height: 2vh;cursor:pointer"  @click="GetReview(0,que.Question_id),que.Review_status=0"/>
                                      <div class="vr" style="margin: -5px 8px -2px 8px"></div>  
                                      <img  v-if="que.Review_status==0 || que.Review_status!=2"  src="../assets/Icons/Group 1379.svg" style="width: 2vh;height: 2vh;cursor:pointer"  @click="GetReview(2,que.Question_id),que.Review_status=2"/>
                                      <img  v-if="que.Review_status==2"  src="../assets/Icons/Group 1215.svg" style="width: 2vh;height: 2vh;cursor:pointer"  @click="GetReview(0,que.Question_id),que.Review_status=0"/>
                                    </div> -->
                                    
                                    <div v-if="que.Show_review!=false" style="  background-color: #f8fafb;padding:1.5vh;margin:2vh 0">
                                      
                                      <v-row wrap no-gutters>
                                        <v-col col=12 sm="6" md="6" lg="6" xs="6" xl="6" >
                                          <span style="font-family: Ubuntu;
                                          font-size: 12px;
                                          font-weight: 300;
                                          font-stretch: normal;
                                          font-style: normal;
                                          line-height: 1.67;
                                          letter-spacing: normal;
                                          text-align: left;
                                          color: #40404b;">
                                          How satisfied are you with this response?
                                          </span>
                                        </v-col>
                                        <v-col col=12 sm="2" md="2" lg="2" xs="2" xl="2" >
                                          <img  v-show="que.Review_status==0 || que.Review_status!=1"  src="../assets/Icons/Group 1380.svg" style="width: 2vh;height: 2vh;cursor:pointer" @click="GetReview(1,que.Question_id,'like')"/>
                                          <img  v-show="que.Review_status==1"  src="../assets/Icons/Group 1214.svg" style="width: 2vh;height: 2vh;cursor:pointer"  @click="GetReview(0,que.Question_id,'like'),que.Review_status=0"/>
                                          <span style="font-family: Ubuntu;
                                          font-size: 12px;
                                          font-weight: 300;
                                          font-stretch: normal;
                                          font-style: normal;
                                          line-height: 1.67;
                                          letter-spacing: normal;
                                          text-align: left;
                                          color: #40404b;">
                                          &nbsp; Satisfied
                                          </span>
                                        </v-col>
                                        <v-col col=12 sm="2" md="2" lg="2" xs="2" xl="2" >
                                          <img  v-show="que.Review_status==0 || que.Review_status!=2"  src="../assets/Icons/Group 1379.svg" style="width: 2vh;height: 2vh;cursor:pointer"  @click="GetReview(2,que.Question_id,'dislike')"/>
                                          <img  v-show="que.Review_status==2"  src="../assets/Icons/Group 1215.svg" style="width: 2vh;height: 2vh;cursor:pointer"  @click="GetReview(0,que.Question_id,'dislike'),que.Review_status=0"/>
                                          <span style="font-family: Ubuntu;
                                              font-size: 12px;
                                              font-weight: 300;
                                              font-stretch: normal;
                                              font-style: normal;
                                              line-height: 1.67;
                                              letter-spacing: normal;
                                              text-align: left;
                                              color: #40404b;">
                                              &nbsp; Not Satisfied
                                              </span>
                                        </v-col>
                                        <v-col col=12 sm="2" md="2" lg="2" xs="2" xl="2" align="right">
                                          <img src="../assets/Icons/Group 1392.svg" style="width: 3vh;height: 3vh;cursor:pointer"  @click="closeReview(que)"/>
                                        </v-col>
                                      </v-row>
                                      

                                    </div>
                                  </v-col>
                                  <v-col  col=12 sm="2" md="2" lg="2" xs="2" xl="2" align=center ></v-col>
                                </v-row>
                              </v-card>
                              </div>
                            </div>
                            </div>
                            </div>
                        </v-row>
                      <div>
                        <v-row >
                          <!-- create a dropdown to show the finallist -->
                          <div style="margin-left:4vh" class="queBox-2">
                            <v-col v-if="showlist" col="12" sm="12" md="12" lg="12" xl="12" xs="12" align="center">
                              <div  style="margin-top:2vh;">
                               
                                <!-- display finallist in dropdown -->
                                <v-card style="width: 100%;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border: solid 1px #dbdbdb;background-color: #f7f7f7;position: relative">
                                  <v-list>

                                  <v-list-item-group>
                                   
                                  <v-list-item id="list" v-for="(item, k) in finallist" :key="k" :style="k === selectedId ? ' background-color:#f9e5de' : ''" @click="select(item)">
                                    <v-list-item-content  >
                                      <v-list-item-title style="font-size:14px">{{item.entity}}
                                        
                                        <span style="font-size:12px" v-if="item.type!=''">{{" "+"["+item.type+"]"}}</span>
                                        <span style="font-size:12px" v-if="item.available!='' && item.available!=undefined && item.available!=null ">{{" "+"("+item.available+")"}}</span>
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list-item-group>
                                </v-list>
                                </v-card>
                              </div>
                            </v-col>
                            <div style="display:flex;flex-direction:row;align-items:space-between;">
                              <v-textarea  
                                id="question"
                                
                                style="font-size: 14px;
                                font-family: Ubuntu;
                                font-weight: 600;
                                color: #383838;
                                border: solid 0.5px black;
                                border-radius:10px !important" v-model="question"
                                :style="!loadingAnswer && question.length > 0 && !disableChat?' width: 68% !important;margin-right:2vh !important;':' width: 70% !important'"     
                                class="mx-2 mb-3 shrink" placeholder="Ask Question" solo
                              @keydown.enter="savequestion"
                                
                                hide-details
                                :disabled="disableChat || loadingAnswer"
                                auto-grow
                                variant="outlined"
                                rows="1"    
                                @click="showColorPicker=false"                         
                                >
                              <template v-slot:append>  
                                  <div v-if="loadingAnswer" style="margin-top:1.5vh">
                                    <span  class="text-class"  > <v-progress-circular indeterminate :size="15" :width="2" color="#8c8e91"></v-progress-circular><i> {{answerStatus}}</i></span>
                                  </div>
                                  <md-button v-if="!loadingAnswer"  class="md-icon-button" v-show="showspeechRec && !showspeechRecogsymbol "
                                      dark
                                      :disabled="disableChat"
                                      @click="toggle ? endSpeechRecognition() : startSpeechRecognition()"
                                      icon
                                      :color="!toggle ? 'primary' : (speaking ? 'red' : 'red darken-3')"
                                      :class="{'redcolor animated infinite pulse': toggle}" style="margin-left:15px;color:rgb(216 222 221);border-radius: 24px;padding:4px;margin-top:4px">
                                      <i class="material-icons" style="color:#bebebe;">{{toggle ? 'mic' : 'mic_none'}}</i>
                                  </md-button>
                                  <img v-if="!loadingAnswer && question.length>0 && !disableChat"  src="../assets/Icons/Path 858.svg" style="width: 21.2px;height:20.2px;cursor:pointer;margin-top:12px" @click.stop="send_question(question),showColorPicker=false">
                                  <img v-if="!loadingAnswer && question.length==0 || disableChat" src="../assets/Icons/Path 858G.svg" style="width: 21.2px;height: 20.2px;margin-top:12px">
                              </template>
                              </v-textarea>
                              <div>
                                <v-menu 
                                  :close-on-click="true"
                                  :close-on-content-click="false"
                                  top
                                  offset-y                         
                                  >
                                  <template v-slot:activator="{ on: menu, attrs }">
                                    <v-tooltip top color="#03a6ad">
                                      <template v-slot:activator="{ on: tooltip }">
                                        <img   v-bind="attrs"
                                        v-on="{...tooltip, ...menu }"
                                          
                                            src="../assets/images/color-palette@2x.webp"
                                            style="width: 21.2px; height: 20.2px; cursor:pointer; margin-top:12px"
                                            @click="showColorPicker = true,setSelectedColors()"
                                        />       
                                      </template>
                                      <span style="color:#ffff">Pick colors</span>
                                    </v-tooltip>
                                  </template>
                                  <v-card v-if="showColorPicker" style="border: #a0a0a0 solid 1px;border-radius:20px;box-shadow:none;overflow:hidden;width:55vh">
                                    <div style="display:flex;flex-direction:row;align-items:center;justify-content:space-between;padding:1vh">
                                     <span style="font-size:2vh;color:#ff6750;margin-left:1vh"><b>{{"Color Theme"}}</b></span>
                                      <v-tooltip left color="#03a6ad">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn v-bind="attrs" v-on="on" icon @click="showColorPicker = false,selectedColorList=[],selectedColor=''">
                                              <v-icon style="color:#03a6ad">mdi-close</v-icon>
                                          </v-btn>
                                        </template>
                                        <span style="color:#ffff"> close</span>
                                      </v-tooltip>
                                    </div> 
                                    <!-- <div style=" border-top:2px #ffff;
                                    height: 1px;                      
                                    background-color: #676772;"></div> -->
                                    <div style="display:flex;flex-direction:row;align-items:space-between;justify-content:center;padding:1vh;padding-bottom:0">
                                                                                  
                                      <v-row wrap no-gutters>
                                          <v-col cols=12>
                                            <span  v-if="selectedColorList.length>0" style="font-size:1.6vh;color:#03a6ad;margin-bottom:1.5vh">{{"Selected colors"}}</span>
                                            <span v-else style="font-size:1.6vh;color:#03a6ad;margin-bottom:1.5vh">{{"Default colors"}}</span>
                                            <v-row  v-if="selectedColorList.length>0" warp no-gutters>
                                           
                                              <v-col align="center" cols=12  sm="auto" md="auto" lg="auto" xs="auto" xl="auto"  style="padding:3px;padding-top:1px"  v-for="(color, index) in selectedColorList" :key="index">
                                                <v-chip  @click:close="removeColor(color)" close :color="color" text-color="white"></v-chip>
                                              </v-col>  
                                            </v-row>
                                            <div style="display:flex;align-items :center;justify-content:flex-start;" v-else>
                                              <v-row  warp no-gutters>
                                               
                                                <v-col align="center" cols=12  sm="auto" md="auto" lg="auto" xs="auto" xl="auto"  style="padding:3px;padding-top:1px"  v-for="(color, index) in defaultColors" :key="index">
                                                  <!-- show color in circle -->
                                                  <div class="Ellipse-154-chat" :style="{ background: color }" style="border:none!important">
                                                    
                                                  </div>
                                                </v-col>  
                                              </v-row>                                         
                                            </div>
                                          </v-col>
                                          
                                      </v-row>
                                   
                                      <div style="flex-direction:column;align-items:center;justify-content:center">
                                        <v-color-picker show-swatches v-model="selectedColor" :modes="['hex']"></v-color-picker>    
                                        
                                        
                                      </div>                                                             
                                    </div>  
                                    <div style="background:#effcff ;position:relative;bottom: 0;left:0;;right:0;display: flex; align-items:center; justify-content: space-between;padding: 1.5vh;border-radius: 0 0 10px 10px;word-break:break-all">
                                      <span style="font-family: Ubuntu;
                                      font-size: 1.5vh;
                                      font-weight: normal;
                                      font-stretch: normal;
                                      font-style: normal;
                                      line-height: 1.29;
                                      letter-spacing: normal;
                                      text-align: left;
                                      color: #03a6ad;cursor:pointer;" @click="resetColorList()">{{"Reset to Default Colours"}}</span>
                                      <div style="display:flex;flex-direction:row;align-items:space-between;justify-content:center">    
                                      
                                        <v-tooltip left color="#03a6ad">
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon v-on="on" v-bind="attrs" @click="addColor(selectedColor)">
                                              <v-icon style="color:#03a6ad;cursor:pointer">mdi-plus</v-icon>
                                            </v-btn>
                                          </template>
                                          <span style="color:#ffff">Add Color</span>
                                        </v-tooltip>  
                                      <v-btn @click="saveColors(), showColorPicker=false" style="background-color:#03a6ad;color:#ffff;border:none;width:auto;font-size: 1.5vh;cursor:pointer">
                                        {{ "Apply Colors" }}
                                      </v-btn>
                                    </div>
                                    </div>  
                                  </v-card>
                                </v-menu>
                              </div>
                            </div>
                              <div class="custom-bullet">
                                <span class="bullet">&#8226;</span><span style="font-family: Ubuntu;
                                font-size: 12px;
                                font-weight: 300;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: 1.67;
                                letter-spacing: normal;
                                text-align: left;
                                color: #676772;"> Use @ before entity to get suggestions
                                  </span>
                              </div>
                          </div>
                        </v-row>
                      </div>
                      </v-col>
                    </v-row>
                    <template>
                      <v-dialog v-model="showprev" persistent  width="800"  >
                        <v-card style="border-radius:2vh " >
                          <v-card-text class="text-center" >
                            <div style="margin-top:4vh">
                              <span class="pull-left"  style="cursor:pointer;float: left !important">{{"Previous Question"}}</span>
                            </div>
                            
                            <div >
                              <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right !important" @click="close_question_modalana()">mdi-close</v-icon>
                            </div>
          
                            <v-row style=" margin-top:10vh">
                              <div >
                                <div v-for="(p,kque) in prev" :key="kque" style="margin:2vh;margin-top:0">
                                  <div  style="padding:1vh 1vh 2vh 1vh;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border: solid 1px #dbdbdb;background-color: #f7f7f7;">
                                    <v-row no-gutters justify="center" >
                                      <v-col col="12" sm="1" md="1" lg="1" xs="1" xl="1" style="padding-right:0; ">
                                        <div class="Ellipse-154-chat">
                                        ? 
                                        </div>
                                      </v-col>
                                      <v-col align="left"  col="12" sm="10" md="10" lg="10" xs="10" xl="10">
                                        <span style="font-family: Ubuntu;
                                        font-size: 14px;
                                        font-weight: normal;
                                        font-stretch: normal;
                                        font-style: normal;
                                        line-height: 1.43;
                                        letter-spacing: normal;
                                        text-align: left;
                                        color: #2c2c48;">{{p.Question}}</span>
                                      </v-col>
                                      <v-col col="12" sm="1" md="1" lg="1" xs="1" xl="1" style="padding-left:0; padding-top:0.5vh">
                                         
                                      </v-col>
                                    </v-row>
                                  </div>
                                  <div>
                                    <v-row style="padding: 2vh 0 2vh 2vh;">
                                      <v-col col="12" sm="1" md="1" lg="1" xs="1" xl="1" align="center" style="padding-right:0">
                                        <div class="Ellipse-knolchat" >
                                          <img src="../assets/Icons/Knolense_Dark111.svg" style="width: 21.2px;height: 20.2px;">
                                        </div>
                                      </v-col>
                                      <v-col align="left" style="padding-left:0">
                                        <span v-if="p.Question_type=='text'"  style="font-family: Ubuntu;
                                        font-size: 14px;
                                        font-weight: normal;
                                        font-stretch: normal;
                                        font-style: normal;
                                        line-height: 1.43;
                                        letter-spacing: normal;
                                        text-align: left;
                                        color: #2c2c48;"  >{{p.Answer}}</span>
                                        <img v-if="p.Question_type=='image'" :src="`data:image/png;base64,${p.Answer}`" />
                                        <!-- <img  v-if="que.Type=='image'" src="data:image/png;base64,"+que.Answer+" /> -->
                                      </v-col>
                                    </v-row>
                                  </div>
                                </div>
                                </div>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        </v-dialog>
                    </template>
                    <!-- </v-card-text> -->
                   
                    <template>
                      <v-dialog @keydown.esc="show_json_template=false" v-model="show_json_template" persistent  width="800">
                        <v-card style="border-radius: 20px !important;">
                          <v-card-text class="text-center" >
                            <v-row style="margin-top:2vh">
                              <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6" >
                                <span class="pull-left"  style="cursor:pointer;float: left !important;padding-left: 2vh;padding-top:1vh;color: #f16753;">Json File</span>
                              </v-col>
                              <v-col cols="12" sm="5" md="5" lg="5" xs="5" xl="5" align="right">
                                  <v-btn v-if="!show_id"
                                    style=" background-color:#ffff;  color:#009ea5;font-size: 12px;height: 30px;border-radius: 4px;box-shadow: none;border: solid 1px #03a6ad;width:120px"
                                    @click="show_id=true" >Show ID
                                  </v-btn> 
                                  <span id="copy_id" @mouseup="copyId(json_content_id)" v-if="show_id" style="text-align: left;font-family: Ubuntu;font-size: 16px;font-weight: 700;">
                                      {{ json_content_id }}
                                  </span>
                              </v-col>                             
                              <v-col cols="12" sm="1" md="1" lg="1" xs="1" xl="1">
                                <img src="../assets/Icons/Group 1354.svg" class="pull-right" color="#009ea5" style="cursor:pointer;float: right !important" @click="show_json_template = false, show_id=false,json_content_id=''">
                              </v-col>
                            </v-row>          
                            <v-row style="padding:2vh">
                              <div style="overflow-y: scroll;height: 400px;">
                                <!-- <vue-json-pretty :data="{jsonfile_content}" /> -->
                                <tree-view style="text-align: left;font-family: Ubuntu;font-size: 17px;font-weight: 700; ;" :data="jsonfile_content" :options="{maxDepth: 1,rootObjectKey: 'root',modifiable: false,defaultOpen: false}"></tree-view>
                              </div>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        </v-dialog>
                    </template>  
                   <!-- </v-card> -->
                  <template>
                    <!--create a v-dialog asking -->
                    <!-- <v-dialog v-model="removeModal" persistent max-width="500px">
                      <v-card class="Path-359" style="padding:3vh 2vh 3vh 2vh">
                        <v-card-text>
                          
                           
                          <v-row wrap>
                                                  
                            <v-col col="12" sm="10" md="10" lg="10" xs="10" xl="10" align="left"> -->
                              <!-- <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;"> -->
                              <!-- <span class="com_nm1" style="margin-left:0vh">Do you really want to remove this question</span>
                            </v-col>
                            <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2" align="right">
                              <v-icon color="#009ea5" style="cursor:pointer;float: right;" @click="removeModal = false">mdi-close</v-icon>
                            </v-col>
                       
                          </v-row>
                          </v-card-text>
                          <v-card-actions> -->
                            <!-- <v-spacer></v-spacer> -->
                            <!-- <v-row>
                              <v-col sm="6" md="6" lg="6" xs="6" xl="6"></v-col>
                              <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                                <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 1.8vh;" @click="RemoveQuestion(removeQue)">Yes</v-btn>
                              </v-col>
                              <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                                <v-btn style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 1.8vh;font-family: Ubuntu;" @click="removeModal = false">No
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-actions>  
                        </v-card>
                    </v-dialog> -->
                    <v-dialog v-model="removeModal" width="410">
              <v-card class="Path-359" style="padding:3vh;border-radius: 40px;">
                <v-row wrap no-gutters>
                  <v-col cols="12" align="right" style="padding-bottom:1vh">

                    <v-btn
                      icon
                      color="#009ea5"
                      @click="user_plan=false"
                      
                    >
                      <!-- <v-icon>mdi-close</v-icon> -->
                      <img src="../assets/Icons/Group 1354.svg" @click="removeModal = false" >
                    </v-btn>
                  </v-col>
                  <v-col cols="12" align="center" >
                    <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;margin-right: 3vh;margin-bottom: 1vh;">
                    <span class="Active_Companies_title">Delete Chat !</span>
                  </v-col>
                  <v-col cols="12" align="center"  style="padding-top:4vh">
                    
                    <span  style="font-family: Ubuntu;font-size:16px;">
                      Do you really want to remove this question ?
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- <v-col sm="4" md="4" lg="4" xs="4" xl="4"></v-col> -->
                  
                  <v-col cols="12" align="center">
                    <v-btn style="background-color: #f16753; color: #ffffff;font-size: 1.8vh;width:100px;border-radius: 4px;"  @click="RemoveQuestion(removeQue)"> 
                      
                      Delete
                      
                    </v-btn>
                  </v-col>
                  <!-- <v-col sm="4" md="4" lg="4" xs="4" xl="4"></v-col> -->
                </v-row>
              </v-card>
            </v-dialog>
                  </template>
                  <template>
                    <!--create a v-dialog asking -->
                    <v-dialog v-model="removeChatModal" persistent max-width="500px">
                      <v-card class="Path-359" style="padding:3vh 2vh 3vh 2vh">
                        <v-card-text>
                          
                           
                          <v-row wrap>
                                                  
                            <v-col col="12" sm="10" md="10" lg="10" xs="10" xl="10" align="left">
                              <!-- <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;"> -->
                              <span class="com_nm1" style="margin-left:0vh">Do you really want to remove this chat</span>
                            </v-col>
                            <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2" align="right">
                              <v-icon color="#009ea5" style="cursor:pointer;float: right;" @click="removeChatModal = false">mdi-close</v-icon>
                            </v-col>
                       
                          </v-row>
                          </v-card-text>
                          <v-card-actions>
                            <!-- <v-spacer></v-spacer> -->
                            <v-row>
                              <v-col sm="6" md="6" lg="6" xs="6" xl="6"></v-col>
                              <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                                <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 1.8vh;" @click="removeChat()">Yes</v-btn>
                              </v-col>
                              <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                                <v-btn style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 1.8vh;font-family: Ubuntu;" @click="removeChatModal = false">No
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-actions>  
                        </v-card>
                    </v-dialog>
                  </template>
                  <v-dialog v-model="show_commentbox" max-width="350px"   style="border-radius:24px"   >
                    <v-card  style="padding:1vh;border-radius:24px" >
                    <v-card-text style="padding-bottom:0">
                      <v-row style="margin-top:1vh" >
                        <v-col  cols="12"  md="3" lg="3" sm="3" xs="3" style="margin-top:1vh" >
                          <div class="Ellipse-154-dislike_chat" style="background-color:#ffff;display:flex;align-items:center;" >
                            <span class="OP_1_chat" style="margin-left:0.6vh">
                              {{ mail_initials}}
                            </span>
                          </div>
                        </v-col>
                        <v-col  cols="12"  md="9" lg="9" sm="9" xs="9"  >
                          <v-textarea
                            v-model="comment"
                            placeholder="Write a comment"
                            style="box-shadow: none;width:100%;margin-top:0;color:#383838;font-size: 14px;font-weight: 400;font-family: 'Ubuntu';"
                            auto-grow
                            variant="outlined"
                            rows="1"
                            hide-details
                            v-on:keyup="addCommentEnter"
                            
                            
                          >
                          <template v-slot:append>  
                           
                            <img v-show="comment!='' && comment!=undefined" src="../assets/Icons/Path 858.svg" style="width: 21.2px;height: 20.2px;cursor:pointer"  @click="addComment()"> 
                            <!-- <img v-else-if="comment==''" src="../assets/Icons/Path 858G.svg" style="width: 21.2px;height: 20.2px"> -->
                          </template>
                        </v-textarea>
                        </v-col>
                      </v-row>
                      </v-card-text>  
                      <v-card-actions>
                      
                        
                        <br>
                        <v-row >
                          <v-col align="right" >
                            <span style="color:#009ea5;font-weight: 600;font-size: 1.8vh;font-family: Ubuntu;cursor:pointer;" @click="show_commentbox = false, comment=''">
                              Close
                            </span>
                          </v-col>
                           
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-dialog> 
                  
                  <v-dialog v-model="allowNotification" width="100%"  :retain-focus="false" >
                    <v-card class="notification_dialog" style="border-radius:0;" >
                      <div style="100%;
                      height: 8px;
                      margin: 0 0 0px;
                      background-color: #029ea5;">

                      </div>
                    <v-row style="padding:1vh;padding-bottom:2vh" >
                      <v-col cols=12 xs="2" md="2" xl="2" sm="2" ></v-col>
                      <v-col cols=12 xs="1" md="1" xl="1" sm="1" class="d-flex justify-center align-center" >
                        <img src="../assets/Icons/Group 1358.svg" style="width:9vh;height:9vh" >
                      </v-col>
                      <v-col cols=12 xs="5" md="5" xl="5" sm="5" >
                        <div style="margin-bottom:1vh">
                          <span style="font-family: Ubuntu;
                          font-size: 2.5vh;
                          font-weight: 500;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: 1.55;
                          letter-spacing: normal;
                          text-align: left;
                          color: #0a5053;
                         ">
                            Give us the green light to share updates with you.
                          </span>
                        </div>
                        <div>
                          <span style="object-fit: contain;
                          font-family: Ubuntu;
                          font-size:1.6vh;
                          font-weight: normal;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: 1.59;
                          letter-spacing: normal;
                          text-align: left;
                          color: #2c2c48;">
                            Please allow notifications to use KnolChat. After allowing notifications, please reload page. 
                            Firefox users may need to click on notifications icon in the addresses bar and click on allow
                          </span>
                        </div>
                      </v-col>
                      <v-col cols=12 xs="2" md="2" xl="2" sm="2" align=center >
                        <div style="margin-bottom:2vh;margin-top:0.5vh">
                          <span style=" font-family: Ubuntu;
                          font-size: 1.8vh;
                          font-weight: normal;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: 1.38;
                          letter-spacing: normal;
                          text-align: center;
                          color: #929298;
                          cursor:pointer" @click="allowNotification=false">

                            Close
                          </span>
                        </div>
                       
                        <div>
                          <v-btn
                          
                          style="padding:5px;background-color:#03a6ad;color:#fff;cursor:pointer" @click="asktoAllowNotification()"
                        >
                          Allow Notifications
                        </v-btn>
                        </div>
                      </v-col>
                      <v-col cols=12 xs="2" md="2" xl="2" sm="2" ></v-col>
                     
                    </v-row>
                    
                  </v-card>
                  </v-dialog>
                  <v-dialog id="nodata" v-model="noData" style="display:none;!important" width="400" >
                    <v-card class="Path-359" style="padding:4vh;padding-left:2vh;border-radius:8px;" >
                    <v-row >
                      <v-col cols=12 xs="1" md="1" xl="1" sm="1" style="padding:0"  align="right">
                        <img src="../assets/Icons/Path 270.svg" style="height: 2.5vh;width: 2.5vh;margin-top:1.8vh">
                      </v-col>
                      <v-col cols=12 xs="9" md="9" xl="9" sm="9" align="left" >
                     
                        <span style="font-family: Ubuntu;font-size:16px" >
                         Data not available for download
                        </span>
                      </v-col>
                      <v-col cols=12 xs="2" md="2" xl="2" sm="2" align="right" >
          
                        <v-btn
                          icon
                          color="#009ea5"
                          @click="noData=false"
                          style="padding-bottom:5px;color"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                  </v-dialog>
              </div>
              
    
       </div> 
   
      </v-main>
  </template>
  <script>
  
  import axios from 'axios'
 
  import { EventBus } from "../event-bus.js";
 
  import postscribe from "postscribe";

 
  //import { Mentionable } from "vue-mention";

let SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
let recognition = SpeechRecognition? new SpeechRecognition() : false


  
  export default {
    components: {
                  postscribe,
                 
                },
      name:'QnAModal',
    data() {
      return {
      auth_token: "",
      my_word: "",
      queans: [],
    
      myquestion: "",
      show_que: false,
      pageX: 0,
      pageY: 0,
      outputcpy: "",
      checkboxData:[],
      loadingModal: true,
      questionIds: [],
      option:"fda",
      question: "",
      loadingAnswer: false,
      mode:'ana',
      uploadedFiles:[...new Set()],
      Files:[ ...new Set()],
      option_lables: [
        {
          name: "Labels",
          status: "selected"
        },
        {
          name: "Clinical Studies",
          status: "notselected"
        },
        {
          name: "Loading Documents",
          status: "notselected"
        },
      ],
      resetCheckbox: false,
      oldQuestionIds: [],
      queans_modal:false,
      //analytical view
      previousChat:[],
      myquestionana:"",
      questionana:"",
      loadingAnswerana:false,  
      showprev:false,
      prev:[],
      show_queana:true,
      loadingPrev:false,
      key:'',
      question_id:'',
      addnewChat:true,
      prevChat:{},
     
      request:null,
      currentRequest:0,
      cancelrequestCode:0,
      show_json_template: false,
      jsonfile_content: {},
      footnote_available: false,
      footnote: "",
      items: [],
      text:'',
      show : false,
      finallist:[],
      showlist:false,
      finalword:'',
      plotdiv:'',
      new_plot_count : 0,
      selecteditem:{},
      message:{},
      answerStatus:'Question submitted...',
      question_answer:{},
      prevPage:1,
      prevPages:0,
      show_id:false,
      json_content_id:'',
      copied:false,
      got_answer:false,
      length:0,
      selectedId: 0,
      removeQue:{},
      removeModal:false,
      finalList:[],
      iskeyDown:false,
      toggle: false,
      runtimeTranscription: '',
      showspeechRec: false,
      speaking: false,
      show_commentbox:false,
      mail_initials:'',
      comment:'',
      comment_queid:'',
      mouseX: 0,
      mouseY: 0,
      active: false,
      loaded: false,
      page: 1,
      selectedquestion:{},
      currentSummary: '',
      qnaCount: 0,
     
      disableChat: false,
      allowNotification : false,
      msg:"Please allow notifications to use KnolChat. <br><br> After allowing notifications, please reload page. <br><br>Firefox users may need to click on notifications icon in the address bar and click on allow.",
      noData:false,
      startLoading:false,
      totalQuestions:0,
      que_length:0,
      prev_que_page:1,
      sample_questions:[],
      chat_loading:false,
      noScroll:false,
      edit:false,
      regen_question:'',
      fcm_supported:false,
      interval: null,
      sampleScrolled:false,
      previousChatScrolled:false,
      containerId:0,isLoadingMore: false,
      canLoadMore: false,
      showLoadMore: true,
      new_thread_name:'',
      removeChatKey:'',
      removeChatModal:false,
      answer_to_type:"",
      text_count:0,
      asked_question_key:'',
      temp_question_id:'',
      pageLoadStart:null,
      question_count:0,
      totalPageViewDuration: 0,
      blurTime:0,
      module_id:"",
      isModule:false,
      copymsg:"Copy Id",
      selectedColor:"",
      selectedColorList:[],
      showColorPicker:false,
      defaultColors:['#14ADB5', '#FF6C4E', '#4E7471', '#71CDD2', '#BBE6E9', '#FE8870',
            '#FFA694', '#FFD2C9', '#6A6D71', '#A5A7A9', '#CEC8C8', '#E0E0E0', '#849E9D', '#B4C4C4'],
      dialog:false,
      };

    },
    beforeRouteLeave(to, from, next) {
      // Calculate page view duration
      const pageViewDuration = Date.now() - this.pageLoadStart;

      // Log the duration (you can customize this part based on your logging strategy)
      // console.log(`Page view duration: ${pageViewDuration} milliseconds`);

      // You can also send this information to a server if needed
    
        this.sendDurationToServer(pageViewDuration);
      next();
    },
    beforeRouteEnter(to, from, next) {
      // Use a local variable to store the timestamp
      let pageLoadStart = Date.now();

      // Assign the local variable to the component's data property
      next(vm => {
        vm.pageLoadStart = pageLoadStart;
      });
    },
  
    
    computed: {
      showspeechRecogsymbol(){
            // if(this.leftSideView == 2 || this.leftSideView == 3){
                return false
            // }else{
            //     return false
            // }
      },
     
    },
    created() {
      
     
      
      document.addEventListener('visibilitychange', this.handleVisibilityChange);
    
    },

    destroyed(){
    
      document.removeEventListener('visibilitychange', this.handleVisibilityChange);
      window.removeEventListener("keydown", this.handleKeyDown);
      if (this.listEndObserver) {
        this.listEndObserver.disconnect();
      }
    },
    
    watch: {
     
      loadingAnswer(newValue) {
        if (newValue === true && !this.interval) {
          if (this.fcm_supported === false && newValue ) {
            this.startTimer();
          }
        } else {
          this.stopTimer();
        }
      },
       
    question(newQuestion, oldQuestion) {
     
     // console.log("question entered",newQuestion.length);
      if (newQuestion.length == 0 || newQuestion == "") {
       // console.log("inside if");
        this.showlist = false;
      }
      

      if (newQuestion.includes('@')) {
     //   console.log("hellllllooooo");
        var word = newQuestion.split('@')[1];

        let array = word.split(" ");
        if (array.length == 1) {
          word = array[0]
        }
        else if (array.length == 2){
          word = array[0] + " " + array[1]
        }else if(array.length > 4){
          //append first 4 words
          word = array[0] + " " + array[1] + " " + array[2] + " " + array[3]

        }
        // this.$refs.mentionable.open();
        //iterate over items and call jaccardSimilarity function
        if (array[0].length > 2) {
        var items = this.items;
        var result = [];
        for (var x in items) {
          var item = items[x];
         
          var similarity = this.jaccardSimilarity(word, item.entity);
          // console.log(similarity);

            item.Similar = similarity;
          if (item.Similar > 0.5) {
            result.push(item);
          }
        }
        
        // console.log("1::", result[0]);
        //sort result 
        //remove duplicate items
        // result = result.filter((thing, index, self) =>
        //   index === self.findIndex((t) => (
        //     t.entity === thing.entity
        //   ))
        // )
        
        result.sort(function(a, b) {
          return b.Similar - a.Similar;
        });
        this.finalword = word;
        //split finalword with space and get 1st word
        this.finalword = this.finalword.split(" ")[0];
        // console.log(this.finalword,"finalword");

        // console.log("2::", result[0]);
        //get top 5 results
        this.finallist = result.slice(0, 5);
        // console.log(result.slice(0, 5),"finallist");
      //   if (this.selecteditem.entity != this.finallist[0].entity) {
      //         console.log('selected itemm',this.finallist[0])
      //         this.selecteditem = this.finallist[0];
      //         this.$forceUpdate()
      //   }
      // console.log(this.selecteditem,"------------")

      
      if (this.finallist.length > 0) {
        this.showlist = true;
      }
       
        //
        
        //check of selected item is not blank
      
        // console.log("question changed")
      }else{
        this.showlist = false;
      }
    }
    }
  },
    mounted() {
      window.addEventListener('resize', this.handleResize);
     window.addEventListener("keydown", this.handleKeyDown);
     this.fcm_supported = firebase.messaging.isSupported();
      var auth_token = localStorage.getItem("authtoken");
      if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
        this.auth_token = auth_token
        this.module_id= this.$route.params.id
        if (this.module_id!=undefined){
          this.isModule=true
        }
       this.setSelectedColors()
       
        if (this.fcm_supported){

          if (Notification.permission !== "granted") {

            console.log("no notification received")
            this.allowNotification = true
            this.disableChat = true
            this.question="Please enable browser notification to use KnolChat"
          
            
          } else {
            console.log("notification received")
            this.disableChat = false
            this.allowNotification = false
            
          }
        }
       this.iskeyDown=false
       this.checkCompatibility()
      //  this.page_scrolling(1,this.selectedquestion)
     
      
        if (this.fcm_supported ){

        navigator.serviceWorker.addEventListener('message', (event) => {
          
        });

        var app = this
        this.$messaging.onMessage(function(payload) {
      
        this.message = payload;
        // console.log("message received",this.message.data.title)
      
        if(this.message.data.title=='Question executed'){
           

          // console.log("executtedd",)
            if (app.question_id == this.message.data.message) {
              // console.log("executtedd",app.question_id ,this.message.data.message)
              app.getAnswer(app.question_id);
            }
        }else{
       
            if (app.question_id == this.message.data.message) {  
                  
              app.answerStatus =this.message.data.title;           
            }
        }
         
          $(window).focus(function() {
         
             app.getQuestionStatus()
          });
          $(window).blur(function() {
              
              app.getQuestionStatus()
              
          });

          
      
      });

      }
      
      this.getsamplequestions()
      this.getsuggestionentities()


  

    this.show = true
   // console.log(this.items);     
        this.showquesModal()
      }else{
        //go to modules_library page
        this.$router.push('/')
     //   console.log("not logged in")
      }
    },
    methods: {
      handleResize() {
        this.checkForKeyboard();
      },
      checkForKeyboard() {
        // Check if the window inner height changes (keyboard open/close)
        const newHeight = window.innerHeight;

        if (newHeight < this.windowHeight) {
          this.isHidden = true;
          this.EventBus.$emit("hideAppbar");  
        } else if (newHeight > this.windowHeight) {
          this.isHidden = false;
          this.EventBus.$emit("showAppbar");  
        }
        this.windowHeight = newHeight;
      },
      getsamplequestions(){
        axios.get('/getSampleQuestions?token='+this.auth_token+"&modulePresent="+this.isModule).then((res) => {

          this.sample_questions = res.data.Data.Questions
        }).catch((err) => {
          console.log(err)
        })

      },
      getsuggestionentities(){
        //create a get request to get suggestion entities
        axios.get('/getSuggestionEntities?token='+this.auth_token+"&modulePresent="+this.isModule).then((res) => {
        //console.log(res)
          if (res.data.Status != 1) {
          // this.items=res.data.Data.Entities
            for (var x in res.data.Data.Entities) {
              if(this.module_id==undefined){
                if(res.data.Data.Entities[x].type!='drug' || ( res.data.Data.Entities[x].type =='drug' && res.data.Data.Entities[x].available_in.includes("fda"))){
                    this.items.push(res.data.Data.Entities[x]);
                }
              }else{
                this.items.push(res.data.Data.Entities[x]);
              }
            }
            
          //iterate over items and create new key available and append all available_in values comma separated
          if (this.module_id==undefined){
            for (var x in this.items) {
              var item = this.items[x];
              var available = "";
              for (var y in item.available_in) {
                if (item.available_in[y] == "fda") {
                  available += "labels" + ", ";
                }else{
                  available += item.available_in[y] + ", ";
                }
              }
              available = available.substring(0, available.length - 2);
              item.available = available;
            }
          }
         
        }else{
          console.log("not logged in")
        }
      }).catch((err) => {
        console.log(err)
      })


      },
      // onClickOutside () {
      //   console.log("outside")
      //  //iterate over queans match comment_queid with question_id and set comment to true
      //   for(var x in this.queans){
      //     if(this.queans[x].Question_id != this.comment_queid){
      //       this.queans[x].Disliked = false
      //     }
      //   }
        
      // },
     
      // prevChatScroll(){
      //   var sample_scroll=document.getElementById("prev_Chat");
      //   if (sample_scroll !=null){
          
       
      //    var visibleHeight = sample_scroll.clientHeight;

 
      //   if (sample_scroll.scrollTop + visibleHeight < sample_scroll.scrollHeight) {
          
      //       this.previousChatScrolled=true;
      //     }else{
      //       this.previousChatScrolled=false;
      //     }
      //   }
      // },
      addSelectedQuestion(q){
        this.selectedquestion = q 
      },
     
      scrolling_sample(){
        // console.log("scrolling_sample")
        //find sample_scroll element and check if its scrolled
        var sample_scroll=document.getElementById("sample_scroll");
        if (sample_scroll !=null){
          
          //check if scrolled 
          // console.log("scrolling_sample",sample_scroll.scrollTop,sample_scroll.scrollHeight)
          if (sample_scroll.scrollTop >= 1) {
            // console.log("bottom")
            //call showPreviousQue function
            this.sampleScrolled=true;
          }else{
            this.sampleScrolled=false;
          }
        }

      },
    
     
      handleVisibilityChange(isVisible) {
        var app = this
      if (isVisible) {
        if(this.question_id != "" && this.question_id != "undefined" && this.question_id != undefined){
        //  console.log("hellllllllooooo")
          this.temp_question_id =this.question_id;
          this.getAnswer(this.question_id);
        }
       
      } else {
      }
      if (document.hidden) {
        // console.log("hidden")
        this.blurTime=Date.now()
        //console.log(this.blurTime,"blurTime")
      } else  {
        // console.log("visible")
        this.totalPageViewDuration = this.totalPageViewDuration + (Date.now() - this.blurTime)
        //console.log(this.totalPageViewDuration,"totalPageViewDuration")
      }
    },
  
      getQuestionStatus(){
      //  console.log("getQuestionStatus")
        if(this.question_id != "" && this.question_id != "undefined" && this.question_id != undefined){
          this.temp_question_id =this.question_id;
          this.getAnswer(this.question_id);
        }
      },

  


    
  jaccardSimilarity(s1, s2) {
      let m = 0;
      // Exit early if either are empty.
      if ( s1.length === 0 || s2.length === 0 ) {
          return 0;
      }
      // Exit early if they're an exact match.
      //convert s1 and s2 to lowercase
      s1 = s1.toLowerCase();
      s2 = s2.toLowerCase();

      if ( s1 === s2 ) {
          return 1;
      }
      let range     = (Math.floor(Math.max(s1.length, s2.length) / 2)) - 1,
          s1Matches = new Array(s1.length),
          s2Matches = new Array(s2.length);

      for ( let i = 0; i < s1.length; i++ ) {
          let low  = (i >= range) ? i - range : 0,
              high = (i + range <= (s2.length - 1)) ? (i + range) : (s2.length - 1);

          for ( let j = low; j <= high; j++ ) {
              if ( s1Matches[i] !== true && s2Matches[j] !== true && s1[i] === s2[j] ) {
                  ++m;
                  s1Matches[i] = s2Matches[j] = true;
                  break;
              }
          }
      }
      // Exit early if no matches were found.
      if ( m === 0 ) {
          return 0;
      }
      // Count the transpositions.
      let k = 0;
      let numTrans = 0;

      for ( let i = 0; i < s1.length; i++ ) {
          if ( s1Matches[i] === true ) {
              let j;
              for ( j = k; j < s2.length; j++ ) {
                  if ( s2Matches[j] === true ) {
                      k = j + 1;
                      break;
                  }
              }
              if ( s1[i] !== s2[j] ) {
                  ++numTrans;
              }
          }
      }
      let weight = (m / s1.length + m / s2.length + (m - (numTrans / 2)) / m) / 3,
          l      = 0,
          p      = 0.1;

      if ( weight > 0.7 ) {
          while ( s1[l] === s2[l] && l < 4 ) {
              ++l;
          }
          weight = weight + l * p * (1 - weight);
      }
      return weight;
         
    },
   
 
     select(item){
      // console.log(item,"item")

  
      //find @word in question and replace it with selected item
      
      //prepend / to word
      // console.log(item.entity,"item")
      
      var word = this.finalword;

     
      var question = this.question;
      //in question find position of @
    
      //replace the word with item in question at index

      word='@'+word;
      //replace @word with item.entity
      var newquestion = question.replace(word, '@'+item.entity);
      

      
    // Check if the index is within the bounds of the array
     
          // Replace the word at the specified index
      //find the word in question and replace only where the cursor is
      // var newquestion = question.replace(word, item.entity);
        
      // console.log(newquestion,"newquestion")
      
      this.question = newquestion;
     
      this.showlist = false;
      this.$forceUpdate();
      //make class list display none
    
      var type=''
      // remove @ from question and replace with item.type with space
      if(item.type=='mechanism'){
        type='mechanism of action'
      }else{
        type=item.type
      }
      this.question = this.question.replace('@', type+' ');
      //add cursor in textarea after replacing @word
      var textarea = document.getElementById("question");
      var strLength = this.question.length;
      textarea.focus();
      
      textarea.setSelectionRange(strLength, strLength);


      this.$forceUpdate();
      // this.question = this.question.replace('@', '');
      
      this.finalword = "";
      this.finallist = [];
      this.iskeyDown=false
  

     },
     
     copyId(id){
      navigator.clipboard.writeText(id)
        .then(() => {
          console.log('ID copied to clipboard:', id);   
          //for some time show copied
          this.copymsg="Copied"
          setTimeout(() => {
            this.copymsg="Copy Id"
          }, 2000);
        })
        .catch(error => {
          
        });
      
     },
     getEmail(){
      //create a get request to get email
      axios.get('/getEmail?token='+this.auth_token).then((res) => {
        //console.log(res)
        if (res.data.Status != 1) {
          this.email=res.data.Data.Email
          this.mail_initials=this.initials(this.email)
        }else{
          console.log("not logged in")
        }
      }).catch((err) => {
        console.log(err)
      })
     },
      showquesModal(){
        this.key=Date.now().toString()
        this.selectedquestion.Key=this.key
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/plotly.js/2.8.3/plotly.min.js')
        document.head.appendChild(recaptchaScript)
       
        //console.log(this.key)
        // this.key=MD5(Str.random())
        this.getEmail()
        this.previousChats(1)
      },
      getPreviousChatsCount(){
        axios.get('/getPreviousChatsCount?token='+this.auth_token+"&type="+'analytical'+"&modulePresent="+this.isModule).then((res) => {
          //console.log(res)
          if (res.data.Status != 1) {
            this.prevPages=res.data.Data.Count
            this.length= Math.ceil(this.prevPages/5)
          //  console.log(this.prevPages,"prevPages")
            //console.log(this.previousChatsCount)
          }else{
            console.log("not logged in")
          }
        }).catch((err) => {
          console.log(err)
        })
      },
      closeReview(q){
        //iterate over queans and match question_id
        for(var x in this.queans){
          if(this.queans[x].Question_id == q.Question_id){
            // console.log("inside if")
            this.queans[x].Show_review = false
            this.$forceUpdate()
          }
        }
      },
      previousChats(page){
        this.getPreviousChatsCount()
        this.page=page
       
       
        this.queans_modal=true
        this.previousChat=[]
        this.loadingPrev=true

      //create get request
        axios.post('/getPreviousChats?token='+this.auth_token+"&type="+'analytical'+"&page="+page+"&modulePresent="+this.isModule).then((res) => {
           // console.log(res)
            if (res.data.Status != 1) {

            // console.log(res.data,"datttttta")
              
              for(var i=0;i<res.data.Data.Chats.length;i++){
                var obj={}
                  obj.Name=res.data.Data.Chats[i].Question
                  obj.Key=res.data.Data.Chats[i].Key
                  obj.edit=false
                  // this.mail_initials=this.initials(this.email)
                //  console.log(obj.Question)
                  var curr_article_date = res.data.Data.Chats[i].Updated_at
                  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                  var curr_d = new Date(curr_article_date),
                  month = '' + (curr_d.getMonth() + 1),
                  day = '' + curr_d.getDate(),
                  year = curr_d.getFullYear();
                  var hours = curr_d.getHours();
                  var minutes = curr_d.getMinutes();
                  var ampm = hours >= 12 ? 'pm' : 'am';
                  hours = hours % 12;
                  hours = hours ? hours : 12; // the hour '0' should be '12'
                  minutes = minutes < 10 ? '0'+minutes : minutes;
                  var strTime = hours + ':' + minutes + ' ' + ampm;
              
                  obj.Date= [day, monthNames[curr_d.getMonth()], year,strTime].join(' ')

                 
                  obj.Selected=false

                  this.previousChat.push(obj)
              }
              for (let i=0;i<this.previousChat.length;i++){
                if(this.previousChat[i].Key==this.key){
                  this.previousChat[i].Selected=true
                  this.addnewChat=false
                }else{
                  this.previousChat[i].Selected=false
                }
              }
             
              // for (let i=0;i<this.previousChat.length;i++){
              //   if(this.previousChat[i].Key==this.key){
              //     this.queans=this.previousChat[i].Question_Answers
              //   }
              // }
              //console.log(this.queans)
              
              this.loadingPrev=false
              this.startLoading=false
            } 
          }).catch((err) => {
            this.loadingPrev=false
            this.startLoading=false
            console.log(err)
          })
        // console.log(this.previousChat)


      },
      send_question(q){
        console.log("send_question")
        var str='Basedonselectedquestion,'
        // console.log(q.replace(/\s/g, ''),str,q.replace(/\s/g, '')==str)
        var newque=q
        // console.log(q.trim()!='',newque.replace(/\s/g, '')==str)
        if (q.trim()!='' && (newque.replace(/\s/g, '')==str)==false){
          // console.log("inside if")
          this.question=q
          this.show_answer(false,"","")
        }else{
          // console.log("inside else")
          return
        }
      //  console.log(q,"-----")
       
      },
      downloadData(id){
        // console.log(id,"id")
        // console.log(this.noData)

        let el = document.getElementById(id);
        let plot_Data = el.data;
        // console.log(plot_Data,"plot_Dataaaaaaaaa")
        let plot_Type = plot_Data[0].type;
      //  console.log(plot_Type,"plot_Type")
        if(plot_Type!='heatmap' && plot_Type!='pie' && plot_Type!='bar' && plot_Type!='scatter' && plot_Type!='table' && plot_Type!='barpolar' && plot_Type !='scatterpolar' && plot_Type!='treemap'){
          // console.log("inside if")
          this.noData=true
          return
        }
       
        var scripts = `<script type="text/javascript">
          setTimeout(function(){`

        var scr=`
        let plotElement = document.getElementById('`+id+`');
            let plotData = plotElement.data;
            let plotType = plotData[0].type;
            let layout = plotElement.layout;
            // Add the function for sanitizing data
            var sanitizedData = plotData.map(function(data) {
              if (data.type === 'heatmap') {
            return {
                x: data.x.map(function(value) {
                if (typeof value === 'string') {
                    return value.replace(/,/g, ''); // Remove commas from string values
                } else {
                    return value; // Keep integer values as they are
                }
                }),
                y: data.y.map(function(value) {
                if (typeof value === 'string') {
                    return value.replace(/,/g, ''); // Remove commas from string values
                } else {
                    return value; // Keep integer values as they are
                }
                }),
                z: data.z, // No need to sanitize z values for heatmap
                type: 'heatmap', // Add type property for later reference
            };
        }  else if (data.type === 'pie') {
            return {
                labels: data.labels.map(function(label) {
                    return label.replace(/,/g, ''); // Remove commas
                }),
                values: data.values, // No need to sanitize values for pie
                type: 'pie', // Add type property for later reference
            };
        }  else if (data.type === 'treemap') {
            return {
                labels: data.labels.map(function(label) {
                  return label.toString().replace(/,/g, ''); // Remove commas
                }),
                values: data.values.map(function(value) {
                    return isNaN(value) || value === "N/A" ? "" : value.toString().replace(",", "");
                }),
                type: 'treemap', // Add type property for later reference
            };

        } else if (data.type === 'bar') {
            // Check if it's a stacked bar plot or grouped bar plot
            if (layout && (layout.barmode === 'stack' || layout.barmode === 'group')) {
                let x = Array.isArray(data.x) ? data.x.map(function(value) {
                    return value.toString(); // Convert x values to strings
                }) : data.x.toString();

                let y = Array.isArray(data.y) ? data.y.map(function(value) {
                    return value.toString(); // Convert y values to strings
                }) : data.y.toString();
                //console.log(x,y)
                return {
                    x: x.map(function(value) {
                        return value.replace(/,/g, ''); // Remove commas
                    }),
                    y: y.map(function(value) {
                        return value.replace(/,/g, ''); // Remove commas
                    }),
                    type: 'bar', // Add type property for later reference
                    barmode: layout.barmode, // Set the barmode
                };
            } else {
                // For regular bar plot (not stacked or grouped)
                let xValues = Array.isArray(data.x) ? data.x.map(function(value) {
                    return value.toString(); // Convert x values to strings
                }) : data.x.toString();

                let yValues = Array.isArray(data.y) ? data.y.map(function(value) {
                    return value.toString(); // Convert y values to strings
                }) : data.y.toString();

                return {
                    x: xValues.map(function(value) {
                        return value.replace(/,/g, ''); // Remove commas
                    }),
                    y: yValues.map(function(value) {
                        return value.replace(/,/g, ''); // Remove commas
                    }),
                    type: 'bar', // Add type property for later reference
                };
            }
        } else if (data.type === 'scatter') {
            return {
                x: data.x.map(function(value) {
                    return value.toString(); // Convert x values to strings
                }),
                y: data.y.map(function(value) {
                    return value.toString(); // Convert y values to strings
                }),
                type: 'scatter', // Add type property for later reference
            };
        } else if (data.type === 'table') {
                //console.log(data,"data table")
                let sanitizedData = plotData[0].cells.values.map(arr => arr.map(val => val.toString().replace(/,/g, '')));
                  // console.log(headers,"headers",cells,"cells")
                  return {
                      header: {
                          values: data.header.values.map(function(value) {
                              return value.replace(/,/g, ''); // Remove commas
                          }),
                          
                      },
                      cells: {
                          values: sanitizedData,
                          
                      },
                      type: 'table', // Add type property for later reference
                  };
            } else if (data.type === 'barpolar') {
                    var sanitizedData = plotData.map(function(d) {
                        return {
                            r: d.r.map(function(value) {
                                return value.toString().replace(',', ''); //Remove commas 
                            }),
                            theta: d.theta.map(function(value) {
                                return value.toString().replace(',', ''); //Remove commas 
                            })
                        };
                    });
                    if(sanitizedData.length>0){
                return sanitizedData[0];
            };

            } else if (data.type === 'scatterpolar'){
                var sanitizedData = plotData.map(function(data) {
                    return {
                        r: data.r.map(function(value) {
                            return value.toString().replace(/,/g, ''); // remove commas
                        }),
                        theta: data.theta.map(function(value) {
                            return value.toString().replace(/,/g, ''); // remove commas
                        })
                    };
                });
                if(sanitizedData.length>0){
                return sanitizedData[0];
            };

            }
    
        });


    // Convert sanitized data to CSV
    let csvData = '';
          if (plotType === 'heatmap') {
            let xValues = sanitizedData[0].x;
            let yValues = sanitizedData[0].y;
            let zValues = sanitizedData[0].z;
            // console.log(zValues)
            // Convert x and y values to strings if they are integers
            xValues = xValues.map(function(value) {
            if (typeof value === 'number') {
                return value.toString();
            } else {
                return value;
            }
            });

            yValues = yValues.map(function(value) {
            if (typeof value === 'number') {
                return value.toString();
            } else {
                return value;
            }
            });

            // Generate CSV header
            csvData = ' ,' + xValues.join(',') + String.fromCharCode(10);

            // Generate CSV rows
            for (let i = 0; i < yValues.length; i++) {
                // Convert z values to strings if they are integers
                const zRow = zValues[i].map(function(value) {
                if (typeof value === 'number') {
                    return value.toString();
                } else {
                    return value;
                }
                });

                csvData += yValues[i] + ',' + zRow.join(',') + String.fromCharCode(10);
            }
        }
                        else if (plotType === 'pie') {

                            let labels = sanitizedData[0].labels;
                            let values = sanitizedData[0].values;

                            // Generate CSV header
                            csvData = 'labels,values' + String.fromCharCode(10);

                            // Generate CSV rows
                            for (let i = 0; i < labels.length; i++) {
                                csvData += labels[i] + ',' + values[i] + String.fromCharCode(10);
                            }
                        }
                        else if (plotType === 'treemap') {

                            let labels = sanitizedData[0].labels;
                            let values = sanitizedData[0].values;

                            // Generate CSV header
                            csvData = 'labels,values' + String.fromCharCode(10);

                            // Generate CSV rows
                            for (let i = 0; i < labels.length; i++) {
                                csvData += labels[i] + ',' + values[i] + String.fromCharCode(10);
                            }
                            }
                        else if (plotType === 'bar') {
                            // Check if it's a stacked or grouped bar plot
                            if (layout && (layout.barmode === 'stack' || layout.barmode === 'group')) {
                                let xValues = sanitizedData[0].x;
                                let yValues = sanitizedData[0].y;

                                // Generate CSV header
                                if (layout.barmode === 'group') {

                                    plotData.forEach(function(row) {
                                    csvData += String.fromCharCode(10);
                                    csvData += row.name + ',';
                                    // console.log(row.name)
                                    //csvData += String.fromCharCode(10);
                                    csvData += row.x.join(',') + String.fromCharCode(10)+',';
                                    csvData += row.y.join(',') + String.fromCharCode(10);
                                });
                                } else { // layout.barmode === 'stack'
                                    for (var i = 0; i < plotData.length; i++) {
                                        csvData += plotData[i].name + String.fromCharCode(10);
                                        
                                        //Header data
                                        const xAxisTitle = document.querySelector(".xtitle");
                                        const xAxisHeaderText = xAxisTitle.textContent;
                                        const yAxisTitle = document.querySelector(".ytitle");

                                        const yAxisHeaderText = yAxisTitle.textContent;
                                        // console.log(xAxisHeaderText,yAxisHeaderText,xAxisTitle,yAxisTitle,"xAxisHeaderText,yAxisHeaderText,xAxisTitle,yAxisTitle,headers")

                                        csvData += xAxisHeaderText+','+yAxisHeaderText+ String.fromCharCode(10);

                                        for (var j = 0; j < plotData[i].x.length; j++) {
                                            csvData += plotData[i].x[j] + "," + plotData[i].y[j] + String.fromCharCode(10);
                                        }
                                        csvData += String.fromCharCode(10);
                                    }
                                }
                                // Generate CSV rows
                                for (let i = 0; i < yValues[0].length; i++) {
                                    let rowValues = yValues.map(function(values) {
                                        return Array.isArray(values) ? values[i] : values; // Handle grouped or stacked bars
                                    });
                                    //csvData += 'Group ' + (i + 1) + ',' + rowValues.join(',') + String.fromCharCode(10);
                                }
                            } else {
                                let xValues = sanitizedData[0].x;
                                let yValues = sanitizedData[0].y;

                                // Generate CSV header
                                //console.log(xAxisHeaderText)
                                const xAxisTitle = document.querySelector(".xtitle");
                                const xAxisHeaderText = xAxisTitle.textContent;
                                const yAxisTitle = document.querySelector(".ytitle");
                                const yAxisHeaderText = yAxisTitle.textContent;
                                csvData = xAxisHeaderText+','+yAxisHeaderText+ String.fromCharCode(10);

                                // Generate CSV rows
                                for (let i = 0; i < xValues.length; i++) {
                                    csvData += xValues[i] + ',' + yValues[i]  + String.fromCharCode(10);
                                }
                            }
                        } else if (plotType === 'scatter') {
                            let xValues = sanitizedData[0].x;
                            let yValues = sanitizedData[0].y;

                            // Generate CSV header
                            const xAxisTitle = document.querySelector(".xtitle");
                            const xAxisHeaderText = xAxisTitle.textContent;
                            const yAxisTitle = document.querySelector(".ytitle");
                            const yAxisHeaderText = yAxisTitle.textContent;
                            csvData = xAxisHeaderText+','+yAxisHeaderText+ String.fromCharCode(10);

                            // Generate CSV rows
                            for (let i = 0; i < xValues.length; i++) {
                                csvData += xValues[i] + ',' + yValues[i] +  String.fromCharCode(10);
                            }
                            
                        } else if (plotType === 'table') {
                                var headers = sanitizedData[0].header.values;
                                var cells = sanitizedData[0].cells.values;

                                // Find the maximum number of columns in any row
                                var maxColumns = Math.max(...cells.map(row => row.length));

                                // Pad shorter rows with empty values to make them the same length
                                cells = cells.map(row => row.concat(Array(maxColumns - row.length).fill('')));

                                // Generate CSV header
                                csvData = headers.join(',') + String.fromCharCode(10);

                                // Generate CSV rows
                                for (var i = 0; i < maxColumns; i++) {
                                    var row = cells.map(function(c) { return c[i]; });
                                    csvData += row.join(',') + String.fromCharCode(10);
                                }
                            } else if(plotType === 'barpolar'){
                                csvData = '';
                                // console.log(sanitizedData)
                                if (plotType === 'barpolar') {
                                    csvData = 'r,theta'+String.fromCharCode(10);
                                    sanitizedData[0].r.forEach(function(r, i) {
                                        csvData += r + ',' + sanitizedData[0].theta[i] + String.fromCharCode(10);
                                    });
                                }
                            } else if(plotType === 'scatterpolar'){
                                csvData = 'r,theta'+String.fromCharCode(10);
                                    sanitizedData[0].r.forEach(function(r, i) {
                                        var theta = sanitizedData[0].theta[i];
                                        if (r !== 'N/A' && r !== 'NaN' && theta !== 'N/A' && theta !== 'NaN') {
                                            csvData += r + ',' + theta + String.fromCharCode(10);
                                        }
                                    });

                            }else{
                              console.log("else")
                              //call this.noDatatoDownload function and pass plotType    
                              return    
                            }
                              var encodedUri = encodeURI('data:text/csv;charset=utf-8,' + csvData);
                              var link = document.createElement('a');
                              link.setAttribute('href', encodedUri);
                              link.setAttribute('download', 'plot_data.csv');
                              link.click();
                              document.body.appendChild(link);`
                          scripts+=scr
                         
                        
                          setTimeout(() => {
               
               

                        scripts = scripts + `}, 500);<\/script>`
                          postscribe('#gist_1', scripts)
                          //wait for postscribe to finish

                          

                      // }
                      document.getElementById(qs.id).scrollIntoView({
                      behavior: 'smooth',block: "nearest", inline: "nearest"
                      });
                    }, 500);
                    this.$forceUpdate()
                   
        
      },
      noDatatoDownload(p){
        console.log("no data to download",p)
        // this.noData=true
      },
      savequestion(e) {
        // console.log(e,"e")
    
      
        
        
       
        var str='Basedonselectedquestion,'
        this.my_word = localStorage.getItem("my_word");
        if (e.keyCode == 13 ) {   
            //check if question is empty
          //set timeout of 2 seconds

          // console.log(e.shiftKey,"shiftkeyyyy")
          if(e.shiftKey==false){
           
            // console.log("inside if")
          var str='Basedonselectedquestion,'
      
          var newque=this.question
          
          // console.log(q.trim()!='',newque.replace(/\s/g, '')==str)
          if (this.question.trim()!='' && (newque.replace(/\s/g, '')==str)==false){
              // console.log(this.finallist,"finallist")
              // console.log(this.selecteditem.entity,"entityyyyy")
            
            
              
              if(this.question.includes('@') && this.selecteditem.entity!=''){

              //  console.log("hellllliiii")
                
                //call select function
              // console.log(this.iskeyDown,"iskeyDown")
              if(!this.iskeyDown){
                //  console.log("inside if keydownnnn")
                  //remove last character from question
                  // this.question=this.question.slice(0, -1)
                  // console.log(this.question,"question")
                  var word = this.question.split('@')[1];
                  

                    let array = word.split(" ");
                    if (array.length == 1) {
                      word = array[0]
                    }
                    else if (array.length == 2){
                      word = array[0] + " " + array[1]
                    }else if(array.length > 4){
                      //append first 4 words
                      word = array[0] + " " + array[1] + " " + array[2] + " " + array[3]

                    }
                    // this.$refs.mentionable.open();
                    //iterate over items and call jaccardSimilarity function
                    if (array[0].length > 2) {
                    var items = this.items;
                    var result = [];
                    for (var x in items) {
                      var item = items[x];
                    
                      var similarity = this.jaccardSimilarity(word, item.entity);
                      // console.log(similarity);

                        item.Similar = similarity;
                      if (item.Similar > 0.5) {
                        result.push(item);
                      }
                    }

                    // console.log("1::", result[0]);
                    //sort result 
                    result.sort(function(a, b) {
                      return b.Similar - a.Similar;
                    });
                    this.finalword = word;
                    //split finalword with space and get 1st 2 words
                    var array_1 = this.finalword.split(" ");
                    if (array_1.length == 1) {
                      this.finalword = array_1[0]
                    }
                    else if (array_1.length == 2){
                      this.finalword = array_1[0] + " " + array_1[1]
                    }else{
                      //append only first 2 words
                      this.finalword = array_1[0]

                    }

                    

                    // console.log("2::", result[0]);
                    //get top 5 results
                    this.finallist = result.slice(0, 5);
                            

                    }
                  // console.log(this.finallist,"finallist")
                  this.iskeyDown=true
                  // console.log(this.finallist,"iskeyDown")
                  this.select(this.finallist[0])
                
                
              }else{
                this.select(this.selecteditem)
              }
                
                
              }else{
              
            
                this.question_count=this.question_count+1
                this.show_answer(false,"","")
              }
            
            }
            e.preventDefault();
          }
        }
      },
      close_question_modal () {
        this.key=""
        this.queans_modal = false
        this.show_que = false
        this.question = ""
        this.myquestion = ""
        this.answer = ""
        this.checkboxData = []
        this.queans=[]
        this.queans=[]
        this.prev=[]
        this.loadingAnswer=false
        this.finallist=[]
        this.showlist=false
      //   console.log(this.AppBar.chat_selected )
        this.mode='ana'
        localStorage.removeItem("my_word");
        var menu = document.querySelector(".menu");
        menu.style.display = "none";
        for (var o = 0; o < this.option_lables.length; o++) {
          if ("Labels" == this.option_lables[o].name) {
          
            this.option_lables[o].status = "selected"
          } else {
            this.option_lables[o].status = "notselected"
          }
        }
        var chat=false
        EventBus.$emit("chatselected",chat );
        // this.$router.push("/")
        this.$forceUpdate();
      },   
     
      RemoveQuestionModal(q){
        this.removeModal=true
        this.removeQue=q
      },
      sampleSelect(s){
        if (!this.disableChat){
          this.question = s
          var textarea = document.getElementById("question");
          var strLength = this.question.length;
          textarea.focus();
          textarea.setSelectionRange(strLength, strLength);
        }
      },
      RemoveChatModal(q){
        this.removeChatModal=true
        this.removeChatKey=q
      },
      
      removeChat(){
        

        axios.post('/removeChat?token='+this.auth_token+"&key="+this.removeChatKey+"&modulePresent="+this.isModule).then((res) => {
          if (res.data.Status != 1) {
            console.log("Removed successfully")
            this.removeChatModal=false

            //get q.key and remove it from previousChat
            if (this.removeChatKey==this.asked_question_key){
              // console.log("inside if")
              this.loadingAnswer=false
              this.answerStatus="Question submitted"
              this.question_id=""
            }

            for(var i=0;i<this.previousChat.length;i++){
              if(this.previousChat[i].Key==this.removeChatKey){
                // console.log("inside if")
                this.previousChat.splice(i,1)
                //iterate over this.queans and compare unique_id with key
             
                  // console.log("inside if")
                  this.addnewChat=true
                  this.key=Date.now().toString()
                  this.previousChat=[]
                  if (this.prevPage==1){
                    // console.log("inside if")
                    this.startLoading=true
                    //update previousChat count
                    this.getPreviousChatsCount()

                    this.previousChats(this.prevPage)
                    this.prevPages=1
                  }else{
                   
                    this.startLoading=true
                    this.prevPage=this.prevPage-1
                    this.prevPages=this.prevPages-1
                    this.getPreviousChatsCount()
                    this.previousChats(this.prevPage)
                  }
                  
                  this.loadingPrev=false  
                
                
                this.addNewChat()
                
                break

              }
            
            }
            
            console.log()
           
          }else{
            console.log("not logged in")
          }

        
        }).catch((err) => {
          console.log(err)
        })

      },
      RemoveQuestion(q){
        var que_id = q.Question_id
        if (q.Regen_no>1 && q.Parent_id!=null && q.Parent_id!='' && q.Parent_id!=undefined){
         
          que_id= q.Parent_id
         

        }
      //  console.log(this.queans,"before.....removinggggg.......")
      if(q.Answer==""){
        this.loadingAnswer=false
      }
        //match the question_id of q and remove it from the queans array
        // if(q.Answer==""){
        //   for(var i=0;i<this.queans.length;i++){
        //     if(this.queans[i].Question_id==q.Question_id){
        //       this.queans.splice(i,1)
        //       break
        //     }
        //   }
        // }
       
        //  console.log("remove from db")
       
          axios.post('/removeQuestion?token='+this.auth_token+"&key="+this.key+"&questionid="+que_id+"&modulePresent="+this.isModule).then((res) => {
            if (res.data.Status != 1) {
            //  console.log(res.data)
            //check if every element in queans has display false
            //if yes then remove the key from previousChat
            // console.log(this.queans.length)
              if(this.queans.length==1 ){
                
                this.queans[0].Display = false
                if (this.queans[0].showcheckbox==true){
                
                  this.queans[0].showcheckbox=false
                  this.question=''
                  this.currentSummary=''
                }
                //get q.key and remove it from previousChat
                for(var i=0;i<this.previousChat.length;i++){
                  if(this.previousChat[i].Key==this.key){
                    this.previousChat.splice(i,1)
                    break

                  }
                }
                this.addNewChat()
              }else{
                for(var i=0;i<this.queans.length;i++){
                  if(this.queans[i].Question_id==q.Question_id){
                    this.queans[i].Display = false
                    this.queans[i].Deleted=true
                    if (this.queans[i].showcheckbox==true){
                    
                      this.queans[i].showcheckbox=false
                      this.question=''
                      this.currentSummary=''
                    }
                    //this.queans.splice(i,1)
                    break
                  }
                }
                //if every element in queans has display false then remove the key from previousChat
                var count=0
                for(var i=0;i<this.queans.length;i++){
                  if(this.queans[i].Display==false){
                    count++
                  }
                }
                if(count==this.queans.length){
                  for(var i=0;i<this.previousChat.length;i++){
                    if(this.previousChat[i].Key==this.key){
                   
                      this.addnewChat=true
                      this.key=Date.now().toString()
                      

                    //make i-1 element selected
                      // if(this.previousChat.length>=1) {
                      //   this.previousChat[0].Selected=true
                      //   this.key=this.previousChat[0].Key
                      //   this.queans=this.previousChat[0].Question_Answers
                      // }
                      this.previousChat.splice(i,1)
                      break
                    }
                  }
                }
              }
              this.removeModal=false
              this.removeQue={}
              if (this.previousChat.length==0){
                this.addnewChat=true
                this.key=Date.now().toString()
                this.previousChat=[]
                if (this.prevPage==1){
                  this.startLoading=true
                  this.previousChats(this.prevPage)
                  this.prevPages=1
                }else{
                  this.startLoading=true
                  this.prevPage=this.prevPage-1
                  this.prevPages=this.prevPages-1
                  this.previousChats(this.prevPage)
                }
                
                this.loadingPrev=false
              }
              //console.log(this.queans,"after........ removinggggg.......")
              this.$forceUpdate()
            } else {
              //console.log(res.data)
              this.removeModal=false
              this.removeQue={}
            }
           
            if (this.currentSummary != '') {
             
           
              this.question = 'Based on selected question, '
            
            }
            
            }).catch((err) => {
              console.log(err)
              this.removeModal=false
              this.removeQue={}
              if (this.currentSummary != '') {
              this.question = 'Based on selected question, '
            
              }
            })
            

      },
      addloadingIconToPlots(Plot_id, plot_div, index){
    //  console.log("helllllll;llllllll", document.getElementById(plot_div))
          if(document.getElementById(plot_div) != null){ 
          //  console.log("jksdjskdj")
            var height;
            var el = document.getElementById(plot_div)
            if(height == "SM"){
              height = document.getElementById(plot_div).offsetHeight / 2;
            }else{
              height = document.getElementById(plot_div).offsetHeight
            }
            // el.style.minHeight = height + "px"
            //this.queans[index].Actual_height = height
            var isEmpty = document.getElementById(Plot_id).innerHTML === ""
                    //check if document.getElementById(Plot_id) includes class plot-container
                    
            if (!isEmpty){
              //iterate over queans and match the question_id with Plot_id and set showLoading to false
              for(var i=0;i<this.queans.length;i++){
                if(this.queans[i].Question_id==Plot_id){
                  this.queans[i].showLoading=false
                  break
                }else{
                  this.queans[i].showLoading=true
                }
              }
              
            }
            // console.log("height",this.queans)
            let loading_div_id = Plot_id + "_loading"
            // let loading_div = `<div v-show="que.showLoading"  id="`+ loading_div_id +`" style="text-align:center;color:#676772;"><div class="spinner-border text-primary" role="status" style="color:#676772 !important"><span class="sr-only">Loading...</span></div></div>`
            let loading_div='<div></div>'
            // console.log(loading_div,"loading_div")

            document.getElementById(Plot_id).insertAdjacentHTML('beforebegin', loading_div)
            //add
            var myVar = setInterval(myTimer, 500);
            function myTimer() {
                if(document.getElementById(Plot_id) != null){
                    var isEmpty = document.getElementById(Plot_id).innerHTML === ""
                    //check if document.getElementById(Plot_id) includes class plot-container
                    
                    if (!isEmpty && document.getElementById(loading_div_id) != null){
                        document.getElementById(loading_div_id).remove()
                        for(var i=0;i<this.queans.length;i++){
                            if(this.queans[i].Question_id==Plot_id){
                              this.queans[i].showLoading=false
                              break
                            }
                          }
                          //remove loading icon
                          document.getElementById(loading_div_id).remove()
                        clearInterval(myVar);
                    }
                }
            }
          }
          this.loaded=true
      },
      recordScrollPosition() {
     
        let node = this.$refs["scrollContainer"];
        this.previousScrollHeightMinusScrollTop =
          node.scrollHeight - node.scrollTop;
      },
      restoreScrollPosition() {
      
      
        for(var i=0;i<this.queans.length;i++){
          if(this.queans[i].Display==false && this.queans[i].Deleted!=true){
            this.queans[i].Display=true
          }
        }
        let node = this.$refs["scrollContainer"];
        node.scrollTop =
        node.scrollHeight - this.previousScrollHeightMinusScrollTop;
      },
      setUpInterSectionObserver(key) {
       
        let options = {
          root: this.$refs["scrollContainer"],
          margin: "10px",
          key: key
        };
        this.listEndObserver = new IntersectionObserver(
          this.handleIntersection,
          options
        );
  
        this.listEndObserver.observe(this.$refs["sentinel"]);
      },
      handleIntersection([entry]) {
        if (entry.isIntersecting && this.showLoadMore && !this.isLoadingMore) {
          this.showPreviousQue(this.selectedquestion, 1);
        }
        if (!this.showLoadMore){
          this.showLoadMore=true
        }
      },
      async Initialclickthread(q,page){

        
        if(this.key == q.Key){
          return
        }
        
        var app=this
        if(app.key!= q.Key){
         
          app.page=1
          app.queans=[]
          app.chat_loading=true
          app.$forceUpdate();
        }
       //divide by 4 and round it to its next greater number
       var mod=app.qnaCount%4
       var count
        if (mod==0){
         count=app.qnaCount/4
        }else{
          count=Math.ceil(app.qnaCount/4)
        }

        if (app.page>count && app.page!=0 && app.qnaCount!=0){
          return
        }

        if (app.queans.length==app.page){
          return
        }
       app.addnewChat=false
       app.key=q.Key
       app.question=''
       
       await axios.get('/getSelectedChats?token='+app.auth_token+"&key="+app.key+"&page="+app.page+"&modulePresent="+app.isModule).then((res) => {
          if (res.data.Status!= 1) {
              app.qnaCount=res.data.Data.QnaCount
              for(let i=0;i<res.data.Data.Question_Answers.length;i++){
                
                if(res.data.Data.Question_Answers[i].Unique_id==app.key){
                  app.queans.unshift(res.data.Data.Question_Answers[i])
                }
              }
              app.chat_loading = false
              app.prev=res.data.Data.Question_Answers
              for (var i=0;i<this.queans.length;i++){              
                app.queans[i].showcheckbox=false
                app.queans[i].Show_review=true
                app.queans[i].Checked='Not_selected'
                if ( app.queans[i].Suggestions!=null){
                  if (app.queans[i].Suggestions.length>0 ){
                    for (let j=0;j<app.queans[i].Suggestions.length;j++){
                      app.queans[i].Suggestions[j].question=app.queans[i].Question
                    }
                  }
                }
                if((app.queans[i].Answer=="" || app.queans[i].Answer==null || app.queans[i].Answer==undefined || app.queans[i].Answer=="undefined") && (app.queans[i].Status=="Question executed")){             
                  app.queans[i].Answer="I don't have an answer to this question at the moment. Is there something else I can assist you with?"
                  app.queans[i].Question_type="text"
                  app.queans[i].Show_save=false
                  app.$forceUpdate();
                  }else if (app.queans[i].Answer=="" || app.queans[i].Answer==null || app.queans[i].Answer==undefined || app.queans[i].Answer=="undefined" && app.queans[i].Status!="Question executed") {
                    if (app.queans[i].Status==''){
                      app.temp_question_id=app.queans[i].Question_id
                      app.question_id=app.queans[i].Question_id
                      app.getAnswer(app.queans[i].Question_id)
                    }else{
                      app.loadingAnswer=false
                      app.answerStatus=app.queans[i].Status
                    }
                  }
                  if (app.queans[i].corrected_entities!=null){
                  //get count of key-value pairs in corrected_entities
                    app.queans[i].Corrected_entities_count=Object.keys(app.queans[i].corrected_entities).length
                    //store each key-value pair as element in array
                    var correction="AutoCorrection"
                    if (app.queans[i].Corrected_entities_count==1){
                      correction="AutoCorrection"
                    }else{
                      correction="AutoCorrections"
                    } 
                    //check if corrected_entities contains showing results 
                  //check if object have key showing results for entities similar to

                    if (app.queans[i].corrected_entities.hasOwnProperty("showing results for entities similar to")){
                      correction=""
                      console.log(app.queans[i].corrected_entities,"corrected_entities")
                      let result = `${Object.keys(app.queans[i].corrected_entities).map(key => `${key} => ${app.queans[i].corrected_entities[key]}`).join(', ')}`;
                      app.queans[i].Corrected_entities_array=result
                    }else{
                      let result = ` ${Object.keys(app.queans[i].corrected_entities).length} ${correction} : ${Object.keys(app.queans[i].corrected_entities).map(key => `${key} => ${app.queans[i].corrected_entities[key]}`).join(', ')}`;
                      app.queans[i].Corrected_entities_array=result
                    }
                  }
              }
              var scripts = ''
              scripts = `<script type="text/javascript">
                setTimeout(function(){`
            //save first item of queans in last_que
              var last_que=app.queans[0]
              for(let i=0;i<app.queans.length;i++){
                if (app.queans[i].Question_type == "htmlfile") {
                  //Decode the qs.Answer from base64
                  var answer = window.atob(app.queans[i].Answer)
                  var obj = JSON.parse(answer)

                  //console.log(obj,"obj")

                  var plot_data = obj.div64.split('.')[0]
                  var div = window.atob(plot_data);
                //  console.log("div")
                  div = div.replace(/\\"/g, '"')
                  div = div.replace(/\\'/g, "'")
                  div = div.replace(/\r?\n|\r/g, "\n")
                  //find 
                
                  app.queans[i].Plot_div  = "Plot_div_" + app.queans[i].Question_id
                  app.queans[i].div64  = div
                  // console.log(app.queans[i].div64,"div64")
                  //find class plotly-graph-div from div64
                  var div64 = app.queans[i].div64
                  var div64_dom = new DOMParser().parseFromString(div64, 'text/html');
                  var plotdiv = div64_dom.getElementsByClassName('plotly-graph-div')[0];
                  //find id of plotdiv
                  var plotdiv_id = plotdiv.id
                  
                  app.queans[i].Plot_id = plotdiv_id
                  
                  // console.log(this.plotdiv,"plotdiv")

                  var script_data = obj.script64.split('.')[0]
                  var script = window.atob(script_data);
                

                  script = script.replace(/\\"/g, '"')
                  script = script.replace(/\\'/g, "'")
                  script = script.replace(/\r?\n|\r/g, "\n")
                  app.queans[i].script64  = script
                
                  //add loading till plotly plots the graph
                  

                  var str = app.queans[i].script64.replace('<script type="text/javascript">', "\n")
                    


                  var str1 = str.replace("window.PLOTLYENV=window.PLOTLYENV || {};","\n")
                  var str2 = str1.replace("<\/script>", "\n")
                  scripts += str2
                  var newthis = this
                  setTimeout(function(){ 
                    newthis.addloadingIconToPlots(newthis.queans[i].Question_id, newthis.queans[i].Plot_div, i)
                    
                  }, 500);
                  this.loaded=true
                  this.$forceUpdate()
                  
                  
                }
              }
              setTimeout(() => {
                  scripts = scripts + `}, 500);<\/script>`
                    postscribe('#gist_1', scripts)
              }, 500);
              app.$forceUpdate()
          }else{
          console.log("no chats found")
          }
          this.showLoadMore=false
        
          this.$nextTick().then(() => {
            setTimeout(() => {
              let scrollContainer = this.$refs["scrollContainer"];
              let scrollHeight = scrollContainer.scrollHeight;
              let step = Math.ceil(scrollHeight / 100); // adjust the step size as needed
              let currentScroll = scrollContainer.scrollTop;
              let targetScroll = scrollHeight - scrollContainer.clientHeight;
              let scrollInterval = setInterval(() => {
                if (currentScroll < targetScroll) {
                  currentScroll += step;
                  scrollContainer.scrollTop = currentScroll;
                } else {
                  clearInterval(scrollInterval);
                }
              }, 10); // Adjust the interval for smoother scrolling
                this.$forceUpdate()
                this.$nextTick().then(() => {
                  //setup up observer after initial list loaded and scrolled all the way to the bottom
                  console.log("inside next tick",this.key,q.Key)
                  // if (q.Key==this.key){
                    this.setUpInterSectionObserver(q.Key);
                  // }
                
                });
              }, 1200);
          });
      
        }).catch((err) => {
          // if($state){
          //    $state.complete()
          //   }
          console.log(err)
           app.chat_loading = false
          //  app.noScroll=true
        })
       
        for (let i=0;i<app.previousChat.length;i++){
          if(app.previousChat[i].Key==app.key){
            app.previousChat[i].Selected=true
             app.previousChat[i].edit=false
          }else{
            app.previousChat[i].Selected=false
            app.previousChat[i].edit=false
          }
        }

        // this.showPreviousQue(this.selectedquestion,1)
      
    
        
      },
    
      async showPreviousQue(q,page){
      
       this.recordScrollPosition()
       var app=this
       if(app.key!= q.Key){
         return
        }
        app.currentSummary=''
        app.page=app.page+page
        var mod=app.qnaCount%4
       var count
        if (mod==0){
         count=app.qnaCount/4
        }else{
          count=Math.ceil(app.qnaCount/4)
        }
        if (app.page>count && app.page!=0 && app.qnaCount!=0){
          this.canLoadMore=false
          return
        }else{
          this.canLoadMore=true
        }

        if (app.queans.length==app.page){  
          this.canLoadMore=false
          return
        }else{
           this.canLoadMore=true
        }
        app.addnewChat=false
        app.key=q.Key
        // app.question=''
        await axios.get('/getSelectedChats?token='+app.auth_token+"&key="+app.key+"&page="+app.page+"&modulePresent="+app.isModule).then((res) => {
        //  console.log(res)
        
        if (res.data.Status!= 1) {
            let qnaChat=[]
            app.qnaCount=res.data.Data.QnaCount
           
            for(let i=0;i<res.data.Data.Question_Answers.length;i++){    
              if(res.data.Data.Question_Answers[i].Unique_id==app.key){
               res.data.Data.Question_Answers[i].Display=false
                app.queans.unshift(res.data.Data.Question_Answers[i])
              }
            }
           
            app.chat_loading = false
            app.prev=res.data.Data.Question_Answers
           //   console.log(this.queans.length,"queans")
            for (var i=0;i<this.queans.length;i++){
              app.queans[i].showcheckbox=false
              app.queans[i].Checked='Not_selected'
              if ( app.queans[i].Suggestions!=null){
               if (app.queans[i].Suggestions.length>0 && app.queans[i].Suggestions!=null){
                  for (let j=0;j<app.queans[i].Suggestions.length;j++){
                    app.queans[i].Suggestions[j].question=app.queans[i].Question
                  }
                }
              }

               if((app.queans[i].Answer=="" || app.queans[i].Answer==null || app.queans[i].Answer==undefined || app.queans[i].Answer=="undefined") && (app.queans[i].Status=="Question executed")){
                  app.queans[i].Answer="I don't have an answer to this question at the moment. Is there something else I can assist you with?"
                  app.queans[i].Question_type="text"
                  app.queans[i].Show_save=false
                  app.$forceUpdate();
                }else if (app.queans[i].Answer=="" || app.queans[i].Answer==null || app.queans[i].Answer==undefined || app.queans[i].Answer=="undefined" && app.queans[i].Status!="Question executed") {
                  if (app.queans[i].Status==''){
                    app.temp_question_id=app.queans[i].Question_id
                    app.getAnswer(app.queans[i].Question_id)
                  }else{
                    if (app.queans[i].Status=!undefined && app.queans[i].Status=="Question executed"){
                      app.loadingAnswer=false
                      app.answerStatus=app.queans[i].Status
                    }                   
                  }                  
                }             
                if (app.queans[i].corrected_entities!=null){
                 //get count of key-value pairs in corrected_entities
                  app.queans[i].Corrected_entities_count=Object.keys(app.queans[i].corrected_entities).length
                  //store each key-value pair as element in array
                  var correction="AutoCorrection"
                  if (app.queans[i].Corrected_entities_count==1){
                    correction="AutoCorrection"
                  }else{
                    correction="AutoCorrections"
                  }
                  if (app.queans[i].corrected_entities.hasOwnProperty("showing results for entities similar to")){
                      correction=""
                      let result = `${Object.keys(app.queans[i].corrected_entities).map(key => `${key} => ${app.queans[i].corrected_entities[key]}`).join(', ')}`;
                      app.queans[i].Corrected_entities_array=result
                    }else{
                      let result = ` ${Object.keys(app.queans[i].corrected_entities).length} ${correction} : ${Object.keys(app.queans[i].corrected_entities).map(key => `${key} => ${app.queans[i].corrected_entities[key]}`).join(', ')}`;
                      app.queans[i].Corrected_entities_array=result
                    }
                }                
            }
          var scripts = ''
          scripts = `<script type="text/javascript">
            setTimeout(function(){`
       
          var last_que=app.queans[0]
          for(let i=0;i<app.queans.length;i++){
            if (app.queans[i].Question_type == "htmlfile") {
              //Decode the qs.Answer from base64
              var answer = window.atob(app.queans[i].Answer)
              var obj = JSON.parse(answer)

              //console.log(obj,"obj")

              var plot_data = obj.div64.split('.')[0]
              var div = window.atob(plot_data);
            //  console.log("div")
              div = div.replace(/\\"/g, '"')
              div = div.replace(/\\'/g, "'")
              div = div.replace(/\r?\n|\r/g, "\n")
              //find 
            
              app.queans[i].Plot_div  = "Plot_div_" + app.queans[i].Question_id
              app.queans[i].div64  = div
              // console.log(app.queans[i].div64,"div64")
              //find class plotly-graph-div from div64
              var div64 = app.queans[i].div64
              var div64_dom = new DOMParser().parseFromString(div64, 'text/html');
              var plotdiv = div64_dom.getElementsByClassName('plotly-graph-div')[0];
              //find id of plotdiv
              var plotdiv_id = plotdiv.id
              
              app.queans[i].Plot_id = plotdiv_id
              
              // console.log(this.plotdiv,"plotdiv")

              var script_data = obj.script64.split('.')[0]
              var script = window.atob(script_data);
            

              script = script.replace(/\\"/g, '"')
              script = script.replace(/\\'/g, "'")
              script = script.replace(/\r?\n|\r/g, "\n")
              app.queans[i].script64  = script
            
              //add loading till plotly plots the graph
              

              var str = app.queans[i].script64.replace('<script type="text/javascript">', "\n")
                


              var str1 = str.replace("window.PLOTLYENV=window.PLOTLYENV || {};","\n")
              var str2 = str1.replace("<\/script>", "\n")
              scripts += str2
              var newthis = this
              setTimeout(function(){ 
              //  console.log("heyyy")
                
                newthis.addloadingIconToPlots(newthis.queans[i].Question_id, newthis.queans[i].Plot_div, i)
                
              }, 500);
              this.loaded=true

              // setTimeout(()=>{
              //   const existingDiv=document.getElementById(newthis.queans[i].Plot_div);
              //   const newdiv = document.createElement('new-div');
              //   existingDiv.insertAdjacentElement('afterend', newdiv);
              //   const scriptElement = document.createElement('script');
              //   scriptElement.innerHTML = `console.log("hellllloooooo")`
              //   newdiv.appendChild(scriptElement);

                
                

              // },500);
              
              this.$forceUpdate()
              
              
            }
          }
          setTimeout(() => {
              scripts = scripts + `}, 500);<\/script>`
                postscribe('#gist_1', scripts)
            // }
            
            // setTimeout(() => {
            //         // Scroll to the element with ID 'gist_1'
            
            //     //get element by id scroll
              // var element = document.getElementById("scroll");
              // element.scrollTop = element.scrollHeight;
            //   if (element.scrollTop != element.scrollHeight){
            //     if (app.queans.length<=4){
                  
            //       document.getElementById(last_que.Question_id).scrollIntoView({
            //       behavior: 'smooth',block: "nearest", inline: "nearest"
            //     });
            //     }
                  
            //   }
              
                  
              
            // }, 500);
          
          }, 500);

          app.$forceUpdate()
        }else{
          console.log("no chats found")
        }
         
        }).catch((err) => {
          console.log(err)
           app.chat_loading = false
          //  app.noScroll=true
        })
       
        for (let i=0;i<app.previousChat.length;i++){
          if(app.previousChat[i].Key==app.key){
            app.previousChat[i].Selected=true
             app.previousChat[i].edit=false
          }else{
            app.previousChat[i].Selected=false
            app.previousChat[i].edit=false
          }
        }
        // this.isLoadingMore = false;
        this.$nextTick(() => {
            setTimeout(() => {
              app.canLoadMore=false
              this.restoreScrollPosition();
            }, 1000);
          });
          if (app.queans.length==app.qnaCount){
            this.canLoadMore=false
          }
    },
     
   
      show_answer(regen,id,que){
        this.showColorpicker=false
        this.canLoadMore=false
        this.asked_question_key=this.key

        var found =false
            if (this.previousChat.length > 0){       
                for (let i=0;i<this.previousChat.length;i++){               
                  if(this.previousChat[i].Key==this.key){                   
                    found = true
                  }
                }
            }else{
              // console.log("empty")
              found = false
            }
            if (found == false) {
             //push this.key to previousChat at the top
              var obj = {
                Key:this.key,
                Selected:true,
                Name:this.question,
                edit : false,
              }
              this.$forceUpdate()
              if(this.previousChat.length==0){
              
                this.previousChat.push(obj)
                this.prevPages=1
              }else{
               
                this.previousChat.unshift(obj)
              }             
              this.addnewChat=false 
            }
        // this.selecteditem ={}
        // console.log(regen,"regen",id,que)
        if (regen==true){
          // console.log("regen")
          this.myquestion = que
          this.regen_question = ""

          //iterate over queans 
          // console.log(this.queans.length,"this.queans")
          for(var i=0;i<this.queans.length;i++){
           
          // console.log(this.queans[i].Question_id==id ,this.queans[i].Parent_id==id,"true")
            if(this.queans[i].Question_id==id || this.queans[i].Parent_id==id){          
              if(this.queans[i].Regen_count==0 || this.queans[i].Regen_count==undefined){
                this.queans[i].Regen_count=2
                this.queans[i].Regen_no=2
              }else{
                this.queans[i].Regen_no=this.queans[i].Regen_count+1
                this.queans[i].Regen_count=this.queans[i].Regen_no
              }            
              this.queans[i].Question=que
              this.queans[i].regenLoading=false
              this.queans[i].Answer=""
              this.queans[i].Question_type=""         
              this.queans[i].showcheckbox=false
              this.queans[i].Show_save=false
            }else{
              console.log("not found")
            }
          }
        }else{
          this.myquestion = this.question
        }
       
        console.log(this.myquestion,"this.myquestion")
        
       
       // console.log(this.myquestion)
        this.question= ""
        //iterate over queans and make showcheckbox false
        // for(var i=0;i<this.queans.length;i++){
        //   this.queans[i].showcheckbox=false
        //   this.queans[i].Suggestions=[]
        // }
       
        this.loadingAnswer = true
        var qs={
            Question:"",
            Answer:"",
            Question_type:"",
            Question_id:"",
           
        }  
        var newSplitRequest = this.currentRequest + 1
        this.currentRequest = newSplitRequest
        qs.Question = this.myquestion
        qs.Answer = ""
        qs.id= "message_" + this.currentRequest.toString()
        qs.Req_number = this.currentRequest 
        this.comment=''
        //comma separate the selectedColorList
        var colorList=""
        colorList = this.selectedColorList.join()
        console.log(colorList,"colorList")
        
        var que_id
       // this.show_queana = true
        axios.get('/getAnswer?token='+this.auth_token+"&key="+this.key+"&question="+encodeURIComponent(this.myquestion)+"&summary="+this.currentSummary+"&regen="+regen+"&question_id="+id+"&modulePresent="+this.isModule+"&colors="+encodeURIComponent(colorList)).then((res) => {
        //  console.log(res)
          // console.log(res.data.Data,"res.data.Data.Question_id")
          if (res.data.Status!= 1) {
          //  console.log("helllllllooooo")
          //  console.log(res.data.Data.Question_id,"res.data.Data.Question_id")
            // console.log(this.message,"this.message")
            this.question_id = res.data.Data.Question_id
            this.temp_question_id=res.data.Data.Question_id
                  
          //  console.log(this.question_id,"this.question_id")

            qs.Question_id=this.question_id
            qs.showcheckbox = false
            qs.Replaced_question = res.data.Data.Replaced_question
          
            //push qs to queans at the top of the array
            if (regen==true){
              qs.Regen=true
            }else{
              qs.Regen=false
            }
            if (qs.Regen!=true){
              this.queans.push(qs)
            }
           
            

            

            // console.log(this.email,"emailll")
            // this.mail_initials=this.initials(this.email)
            this.selectedId = 0
            this.$forceUpdate()

            //check if this.key is present in previousChat
          
            
            
            // this.answerStatus=this.message.data.title
            // this.getAnswer(res.data.Data.Question_id,newSplitRequest,qs)
      
          }else{
            this.question_id = ""
            this.selectedId = 0
            qs.Question_id=this.question_id
            this.queans.push(qs)
          }
        }).catch((err) => {
          console.log(err)
          this.question_id = ""
          this.selectedId = 0
          qs.Question_id=this.question_id
          this.queans.push(qs)
        })
          
        // if (this.queans.length > 4) {
            //scroll to bottom 
            setTimeout(() => {
            document.getElementById(qs.Question_id).scrollIntoView({
              behavior: 'smooth',block: "nearest", inline: "nearest"
            });
            
          }, 500);
        // }
          
        
      


      },
      getAnswer(que_id){   
      var qs={
        Question:"",
        Answer:"",
        Question_type:"",
        Question_id:"",
  
      }
      var newSplitRequest = this.currentRequest + 1
     // console.log(que_id,"que_id")
      axios.post('/getChat?token='+this.auth_token+"&key="+this.key+"&question_id="+que_id+"&modulePresent="+this.isModule).then((res) => {
            //console.log(res)
            var index_to_delete
            if (res.data.Status!= 1) {
             
              
               this.loadingAnswer = false
              // console.log(res.data,"res.data.Data.Question_Answers")
              for (let i = 0; i < this.queans.length; i++) {
                if (this.queans[i].Req_number == newSplitRequest){     
                  
                  index_to_delete = i
                  break
                }
              }
              if (res.data.Data.Question_Answers.Answer== '' || res.data.Data.Question_Answers.Answer== null || res.data.Data.Question_Answers.Answer== undefined || res.data.Data.Question_Answers.Answer== 'undefined' && res.data.Data.Question_Answers.Status!=
              'Question executed'){
              
                this.answerStatus=res.data.Data.Question_Answers.Status
                if (this.loadingAnswer!=true){

                  this.loadingAnswer=true
                }
                return
              }

              if(res.data.Data.Question_Answers.Unique_id!=this.key && res.data.Data.Question_Answers.Regen_no==0){
                return
              }else if (res.data.Data.Question_Answers.Regen_no>1){
                //check if res.data.Data.Question_Answers.Parent_id is present in queans as question_id
                var isPresent=false
                for(let i=0;i<this.queans.length;i++){
                
                  if(this.queans[i].Question_id==res.data.Data.Question_Answers.Parent_id || this.queans[i].Parent_id==res.data.Data.Question_Answers.Parent_id){
                   
                    isPresent=true
                    break


                  }
                }
                if (isPresent==false){
                  return
                }
              }
              var ischecked=false
              
              //iterate over queans and match the question_id
              for(let j=0;j<this.queans.length;j++){
                if(this.queans[j].Question_id==que_id){
                  if (this.queans[j].showcheckbox==true){
                    ischecked=true
                  }
                  break
                }
              }
             //match the question_id and push the answer to queans
             for (let i = 0; i < this.queans.length; i++) {
              if (res.data.Data.Question_Answers.Regen_no>1){
                if(this.queans[i].Question_id==que_id || this.queans[i].Parent_id==res.data.Data.Question_Answers.Parent_id ||res.data.Data.Question_Answers.Parent_id==this.queans[i].Question_id){
                
                  this.loadingAnswer=false
                   // this.queans[i]=res.data.Data.Question_Answers
                  // console.log(this.queans[i],"this.queans[i]")
                  // this.queans.splice(i,1)
                }
              }else{
                if(this.queans[i].Question_id==que_id ){
                
                  this.queans.splice(i,1)
                  break
                  
                  
                }
              }

             }

              qs.Question =res.data.Data.Question_Answers.Question
              if (ischecked==true){
                qs.showcheckbox = true
              }else{
                qs.showcheckbox = false
              }

              if (res.data.Data.Question_Answers.Answer=='' && res.data.Data.Question_Answers.Status==
              'Question executed'){
                qs.Answer = "I don't have an answer to this question at the moment. Is there something else I can assist you with?"
                qs.Question_type="text"
                this.loadingAnswer = false
              }else{
                qs.Answer = res.data.Data.Question_Answers.Answer
                this.loadingAnswer = false
                this.$forceUpdate()
              }
              
              qs.Question_type = res.data.Data.Question_Answers.Question_type
              qs.Question_id = res.data.Data.Question_Answers.Question_id
              qs.id= "message_" + this.currentRequest.toString()
              qs.Review_status=res.data.Data.Question_Answers.Review_status
              qs.Show_review=true
              qs.Copy_id=true
              qs.Non_regenerative=false
              qs.Is_suggestions=res.data.Data.Question_Answers.Is_suggestions
              qs.Replaced_question=res.data.Data.Question_Answers.Replaced_question
              if (res.data.Data.Question_Answers.Suggestions!=null){
                if (res.data.Data.Question_Answers.Suggestions.length>0){
                  var answer=qs.Answer.toLowerCase()
                  // if(answer.includes("no results") || answer.includes("no trials") ||  answer.includes("no companies")||  answer.includes("no sponsors") ||  answer.includes("no trial") ||answer.includes("no result") || answer.includes("no company") || answer.includes("no sponsor") || answer.includes("no trials found") || answer.includes("no companies found") || answer.includes("no sponsors found") || answer.includes("no trial found") || answer.includes("no result found") || answer.includes("no company found") || answer.includes("no sponsor found")){
                    qs.Suggestions=res.data.Data.Question_Answers.Suggestions
                    for (let i=0;i<qs.Suggestions.length;i++){
                      qs.Suggestions[i].question=res.data.Data.Question_Answers.Question
                    }
                  // }
                }
              }
              // qs.Suggestions=[{"entity":"hellll","type":["abc","def"],"question":qs.Question},{"entity":"pqr","type":["sdfg","sfghbb"],"question":qs.Question}]
              if (qs.Question_type=='text'){
                qs.Live_typing=true
                this.answer_to_type=qs.Answer
              }
              if (res.data.Data.Question_Answers.Footnote!=null && res.data.Data.Question_Answers.Footnote!="" && res.data.Data.Question_Answers.Footnote!=undefined){
                qs.Footnote = res.data.Data.Question_Answers.Footnote
              }
              if (qs.Regen_no!=0){
                qs.Regen_no=res.data.Data.Question_Answers.Regen_no
                qs.Regen_count=res.data.Data.Question_Answers.Regen_count

              } if (res.data.Data.Question_Answers.Parent_id!=''){
                qs.Parent_id=res.data.Data.Question_Answers.Parent_id
              }
              if (res.data.Data.Question_Answers.corrected_entities!=null && res.data.Data.Question_Answers.corrected_entities!=undefined){
                qs.corrected_entities=res.data.Data.Question_Answers.corrected_entities
               //get count of key-value pairs in corrected_entities
                qs.Corrected_entities_count=Object.keys(qs.corrected_entities).length
                //store each key-value pair as element in array
                var correction="correction"
                if (qs.Corrected_entities_count==1){
                  correction="correction"
                }else{
                  correction="corrections"
                }
               
                if (qs.corrected_entities.hasOwnProperty("showing results")){
                  correction=""
                  let result = `Found ${Object.keys(qs.corrected_entities).length} ${correction} ${Object.keys(qs.corrected_entities).map(key => `${key}: ${qs.corrected_entities[key]}`).join(', ')}`;
                  qs.Corrected_entities_array=result
                }else{
                  let result = `${Object.keys(qs.corrected_entities).map(key => `${key}: ${qs.corrected_entities[key]}`).join(', ')}`;
                  qs.Corrected_entities_array=result
                }
              }
             
              // if (res.data.Data.Question_Answers.Regen_no>=2){
              //   qs.Regen=true
              // }
              this.comment=''
            //  console.log(qs.Answer,"qs.Answer")
              qs.Answer=qs.Answer.toString()
              var scripts = ''
                  scripts = `<script type="text/javascript">
                    setTimeout(function(){`
                if (qs.Question_type == "htmlfile") {
                  //Decode the qs.Answer from base64
                  var answer = window.atob(qs.Answer)
                //  console.log(answer,"qs.Answer")

                  var obj = JSON.parse(answer)
                  var plot_data = obj.div64.split('.')[0]
                  var div = window.atob(plot_data);
                  // console.log("div")
                  div = div.replace(/\\"/g, '"')
                  div = div.replace(/\\'/g, "'")
                  div = div.replace(/\r?\n|\r/g, "\n")
                  qs.Plot_div  = "Plot_div_" + qs.Question_id
                  qs.div64  = div
                //  console.log(this.plotdiv,"plotdiv")
                var div64 = qs.div64
                  var div64_dom = new DOMParser().parseFromString(div64, 'text/html');
                  var plotdiv = div64_dom.getElementsByClassName('plotly-graph-div')[0];
                  //find id of plotdiv
                  var plotdiv_id = plotdiv.id
                  
                  qs.Plot_id = plotdiv_id
                 

                  var script_data = obj.script64.split('.')[0]
                  var script = window.atob(script_data);
                  // console.log("script")

                  script = script.replace(/\\"/g, '"')
                  script = script.replace(/\\'/g, "'")
                  script = script.replace(/\r?\n|\r/g, "\n")
                  qs.script64  = script
                  //add loading till plotly plots the graph
                  var str = qs.script64.replace('<script type="text/javascript">', "\n")
                  var str1 = str.replace("window.PLOTLYENV=window.PLOTLYENV || {};","\n")
                  var str2 = str1.replace("<\/script>", "\n")
                  scripts += str2
                  // console.log(scripts,"scripts") 
                
                }
               
               
                if (res.data.Data.Question_Answers.Regen_no<=1){
                 
                  this.queans.push(qs) 
                
                  this.loadingAnswer = false
                  this.$forceUpdate()
                }else{
                  //iterate over queans
                  for(let i=0;i<this.queans.length;i++){
                    if(this.queans[i].Question_id==que_id || this.queans[i].Parent_id==res.data.Data.Question_Answers.Parent_id ||res.data.Data.Question_Answers.Parent_id==this.queans[i].Question_id){
                     
                      this.queans[i]=qs
                      break
                    }
                  }
                }
            
                 // console.log(qs.id,"qs.id")
                  setTimeout(() => {
                 
                    scripts = scripts + `}, 500);<\/script>`
                    postscribe('#gist_1', scripts)

                    //execute the script tag
                    // var scriptTag = document.createElement("script");
                    // scriptTag.type = "text/javascript";
                    // scriptTag.innerHTML = scripts;
                    // document.getElementById(qs.id).appendChild(scriptTag);
                    setTimeout(() => {
                      // Scroll to the element with ID 'gist_1'
                      const element = document.getElementById('gist_1');
                        if (element){
                          if (qs.Question_type == "htmlfile") {
                            document.getElementById(qs.Question_id).scrollIntoView({
                            behavior: 'smooth',block: "end", inline: "nearest"
                            });
                          }else{
                              document.getElementById(qs.Question_id).scrollIntoView({
                              behavior: 'smooth',block: "nearest", inline: "nearest"
                              
                              });
                            }
                            
                        }

                       

                         
                      }, 500);
                   
                  
                 
                }, 500);
                  
                  //console.log(this.panels,"panels")
                  
                  this.$forceUpdate()
                // console.log(qs,"qssssssss")
                
                this.loadingAnswer = false
                // this.myquestion = ""  
                this.answerStatus = "Question Submitted"
                this.json_content_id=this.question_id
                this.question_id = ""
                this.asked_question_key=""

               
                this.$forceUpdate()
                
            }else{
           //   console.log(" not found")
              this.loadingAnswer = false
              this.json_content_id=this.question_id
              this.question_id = ""
              this.asked_question_key=""
              this.$forceUpdate()
            }
            this.currentRequest++
          }).catch((err) => {
            console.log(err)
            this.json_content_id=this.question_id
            this.question_id = ""
            this.asked_question_key=""
            this.loadingAnswer = false
            this.$forceUpdate()
            
          })
       
          
    },
      
      
  
     
    
      close_question_modalana () {
        this.prev=[]
        this.showprev=false
        this.finallist=[]
        this.showlist=false
        var chat=false
        EventBus.$emit("chatselected",chat );
        // this.$router.push("/")
        this.$forceUpdate();
        
      },    
      addNewChat(){
        this.addnewChat=true
      
        this.key=Date.now().toString()
        this.$forceUpdate()
       
       
       
        this.queans=[]
        this.question=""
        for (let i=0;i<this.previousChat.length;i++){
            this.previousChat[i].Selected=false
            this.previousChat[i].edit=false
        }
      },
      show_json(ftnote,id) {
      //  console.log("id", id)
        if (id =='' || id == undefined || id ==null) {
         id=this.question_id
        }
      //  console.log("json_content_id", this.json_content_id)
        this.jsonfile_content = {}
        this.show_json_template =  true
        this.json_content_id = id
        if (ftnote !== null && ftnote !== "" && ftnote !== undefined && ftnote !== 'undefined') {
         
          var obj_foot = window.atob(ftnote);
          this.jsonfile_content = JSON.parse(obj_foot)
          this.json_content_id = id
          
        //  console.log(this.jsonfile_content,"footnote")
        }

      
      },
      selectItem(itemId) {
     
        this.selectedId = itemId;
        this.selecteditem=this.finallist[this.selectedId]
        //iterate over finallist find item with index itemId
       
        // this.selecteditem=item
        this.$forceUpdate();
        // console.log(this.selecteditem,"selected item")
        // scrollIntoViewIfNeeded(this.$refs.root.children[itemId])
        
        //if enter is pressed call question inside watch
        

        // this.$refs.root.children[item.id].scrollIntoView({ behavior: "smooth" });
      },
      handleKeyDown(event) {
     
      switch (event.keyCode) {
        // In case of left arrow key move to the last item
        // case 37:
        //   if (this.selectedId > 0) {
        //     this.select(this.selectedId - 1);
        //   }
        //   // Prevent the default scroll event from firing
        //   event.preventDefault();
        //   break;
        // In case of up arrow key, move to the last item
       
        case 38:
          this.iskeyDown=true
          if (this.selectedId > 0) {
          
            this.selectItem(this.selectedId - 1);
          }
          event.preventDefault();
          break;
        // In case of right arrow key, move to the next item
        // case 39:
        //   if (this.selectedId < this.items.length - 1) {
        //     this.select(this.selectedId + 1);
        //   }
        //   event.preventDefault();
        //   break;
        // In case of down arrow key, move to the next item
        case 40:
        this.iskeyDown=true
          if (this.selectedId < this.items.length - 1) {
            this.selectItem(this.selectedId + 1);
          }
          event.preventDefault();
          break;
      }
     
    },
    GetReview(status,que_id,review,e){
   
      if(status==2){
        this.comment_queid=que_id


        //check if for que id comment is already present
        for(let c=0;c<this.queans.length;c++){
          if(this.queans[c].Question_id==que_id){
            if (this.queans[c].Review_status==2){
              this.queans[c].Review_status=0
              
            }else if(this.queans[c].Review_status==0 || this.queans[c].Review_status==1){
              this.queans[c].Review_status=2
            }
            this.$forceUpdate()
            // console.log("comment",this.queans[c].Review_message)
            this.comment=this.queans[c].Review_message
            // this.queans[c].Disliked=true
            break
          }else{
            this.comment=""
          }
        }
        this.show_commentbox=true
        axios.get('/updateChatStatus?token='+this.auth_token+"&status="+status+"&question_id="+que_id+"&modulePresent="+this.isModule).then((res) => {
          // console.log(res)
          if (res.data.Status!= 1) {
             console.log("status updated")
          
          }
        }).catch((err) => {
          console.log(err)
        })  
      }else if (status==1){
     
        //iterate over queans array and find the question id and update the status 
        for(let c=0;c<this.queans.length;c++){
          if(this.queans[c].Question_id==que_id){
            if (this.queans[c].Review_status==1){
              this.queans[c].Review_status=0
            }else if(this.queans[c].Review_status==0 || this.queans[c].Review_status==2){
              this.queans[c].Review_status=1
            }
            this.$forceUpdate()
           
            // var like = document.getElementById("like")
            // like.style.color="#daf7f9"

            break
          }
        }
        //create get request to get review to update the status
        axios.get('/updateChatStatus?token='+this.auth_token+"&status="+status+"&question_id="+que_id+"&modulePresent="+this.isModule).then((res) => {
          // console.log(res)
          if (res.data.Status!= 1) {
            console.log("status updated")
            //update the status in the queans array
           
          }
        }).catch((err) => {
          console.log(err)
        })  
      }else if (status==0){
        if (review=="like"){
          for(let c=0;c<this.queans.length;c++){
          if(this.queans[c].Question_id==que_id){
            if (this.queans[c].Review_status==1){
              this.queans[c].Review_status=0
              status=0
            }else if(this.queans[c].Review_status==0 || this.queans[c].Review_status==2){
              this.queans[c].Review_status=1
              status=1
            }
            this.$forceUpdate()
         
            // var like = document.getElementById("like")
            // like.style.color="#daf7f9"

            break
          }
        }

        }else{
          for(let c=0;c<this.queans.length;c++){
          if(this.queans[c].Question_id==que_id){
            if (this.queans[c].Review_status==2){
              this.queans[c].Review_status=0
              status=0
            }else if(this.queans[c].Review_status==0 || this.queans[c].Review_status==1){
              this.queans[c].Review_status=2
              status=2
            }
            this.$forceUpdate()
         
            // var like = document.getElementById("like")
            // like.style.color="#daf7f9"

            break
          }
        }

        }
        axios.get('/updateChatStatus?token='+this.auth_token+"&status="+status+"&question_id="+que_id+"&modulePresent="+this.isModule).then((res) => {
          // console.log(res)
          if (res.data.Status!= 1) {
            console.log("status updated")
            //update the status in the queans array
           
          }
        }).catch((err) => {
          console.log(err)
        })  


      }
    },
    initials(email){
            let name = email.substring(0, email.lastIndexOf("@"));
            if(name.includes(".")){
                var names = email.split(".")
                if(names.length > 0){
                    var initials_l = (names[0].substring(0,1) + names[1].substring(0,1)).toUpperCase()
                    return initials_l
                }
            }else{
                var names = email.split(' ')
                var initials_l = names[0].substring(0, 2).toUpperCase()
                return initials_l
            }
    },
    addCommentEnter(e){
      // console.log(e.keyCode,"-------")
      if(e.keyCode==13){
        this.comment=this.comment.slice(0, -1)
         e.preventDefault();
         
        this.addComment()
 
      }

    },
    addComment(){

      // console.log(this.comment,"sdfghj ")
      
        
      
      if(this.comment!='' || this.comment!=undefined || this.comment!=null){
        //update the comment in the queans array
        for(let c=0;c<this.queans.length;c++){
          if(this.queans[c].Question_id==this.comment_queid){
            this.queans[c].Review_message=this.comment
            break
          }
        }
        axios.get('/addComment?token='+this.auth_token+"&comment="+this.comment+"&question_id="+this.comment_queid+"&modulePresent="+this.isModule).then((res) => {
          // console.log(res)
          if (res.data.Status!= 1) {
            // console.log("comment added")
            //update the status in the queans array
            this.show_commentbox=false
            this.comment_queid=''
            this.comment=''
            this.$forceUpdate();
          }
        }).catch((err) => {
          console.log(err)
        })  
      }
      
    },
    //speech recognition related
    checkCompatibility () {
            // console.log(recognition)
            if (recognition) {
                this.showspeechRec = true
                this.show_speech_synthesis = true
            }else{
                this.showspeechRec = false
                this.show_speech_synthesis = false
            }
        },
      endSpeechRecognition () {
            // console.log("Recording End")
            if(recognition){
                recognition.stop()
            }
            this.toggle = false
            this.runtimeTranscription = ""
        },
        startSpeechRecognition () {
            
            var newthis = this
            if (!recognition) {
                newthis.showspeechRec = false
                return false
            }
            newthis.showspeechRec  = true
            this.toggle = true
            recognition.lang = this.lang
            recognition.interimResults = true
          


            recognition.addEventListener('speechstart', event => {
                this.speaking = true
            })

            recognition.addEventListener('speechend', event => {
                this.speaking = false
            })

            recognition.addEventListener('result', event => {
                //const text = Array.from(event.results).map(result => result[0]).map(result => result.transcript).join('')
                
              

                const text = event.results[0][0].transcript
                this.runtimeTranscription = text
                
             
               
            })
            
             
       
            var counter = 0;
            var interval = setInterval(function(){
                  counter++;
            },1000);
         
        
          //   recognition.start();
          // else
          //   clearInterval(interval)
          

            recognition.addEventListener('end', () => {
              console
                var const_this = this 
                setTimeout(function(){
                    // console.log("Recorded...", const_this.runtimeTranscription)
                   
                    //check if counter is greater than 10 seconds
                    //if yes then stop the recording
                    //else start the recording again
                    if (const_this.runtimeTranscription !== ''){
                          newthis.question = newthis.question + " " + (const_this.capitalizeFirstLetter(const_this.runtimeTranscription))
                      }
                     if(counter > 10){
                      
                      
                      const_this.toggle = false
                      const_this.runtimeTranscription = ''
                      recognition.stop()
                    }else{
                      this.speaking = true
                      recognition.start()
                    }
                    
                }, 200);
            })
            recognition.start()
           
        },
        
        capitalizeFirstLetter (string) {
            return string.charAt(0).toUpperCase() + string.slice(1)
        },
   
      checkQuestion(q){
        // console.log(q,"checkQuestion")
        if (q.Show_save==true){
          return true
        }
        //match question_id and update showcheckbox value
        for(let c=0;c<this.queans.length;c++){
          if(this.queans[c].Question_id==q.Question_id){
            // console.log(this.queans[c].showcheckbox,"this.queans[c].showcheckbox")
              // axios.get('/updateSummaryStatus?token='+this.auth_token+"&question_id="+q.Question_id).then((res) => {
              //   console.log(res)
              //   if (res.data.Status!= 1) {
              //     console.log("status updated")
              //   }else{
              //     console.log("status not updated")
              //   }

              // }
              // ).catch((err) => {
              //   console.log(err)
              // })
              this.queans[c].showcheckbox=false
              this.question=''
              this.currentSummary=''
              this.$forceUpdate();
          }
        }

      },
      createCheckbox(q){
        //  console.log(q,"createCheckbox")
        if(q.Show_save==true){
          return 
        }
        //match question_id and update showcheckbox value
        for(let c=0;c<this.queans.length;c++){
          if(this.queans[c].Question_id==q.Question_id){
           if (this.queans[c].showcheckbox==false){
             this.queans[c].showcheckbox=true
             this.question='Based on selected question, '
             this.currentSummary=this.queans[c].Summary
            //  console.log(this.currentSummary,"this.currentSummary")
             //create get request to update status
              // axios.get('/updateSummaryStatus?token='+this.auth_token+"&question_id="+q.Question_id).then((res) => {
              //   console.log(res)
              //   if (res.data.Status!= 1) {
              //     console.log("status updated")
              //   }else{
              //     console.log("status not updated")
              //   }

              // }
              // ).catch((err) => {
              //   console.log(err)
              // })


              this.$forceUpdate();
           }else{
            
            this.queans[c].showcheckbox=false
            this.question=''
            this.currentSummary=''
            this.$forceUpdate();
           }
          }else{
            this.queans[c].showcheckbox=false
          
            this.$forceUpdate();
          }
        }
        
      
      

      },
     
  
      gotoResearch(){
          this.$router.push('/ResearchChat')
      },
      EditQuestion(q){
        this.new_thread_name=q.Name
       
        //find q.Key in previousChat and match with q.Key and update edit to true
        // console.log(q,"EditQuestion")
        for(let c=0;c<this.previousChat.length;c++){
          if(this.previousChat[c].Key==q.Key){
            this.previousChat[c].edit=true
            this.$forceUpdate();
          }
        }
      },
      SaveNewQuestion(q){
        if (this.new_thread_name=='' || this.new_thread_name==undefined || this.new_thread_name==null){
          this.new_thread_name=q.Name
         

          return
        }else{
       
        }
        // console.log(q,"EditQuestion")
        for(let c=0;c<this.previousChat.length;c++){
          if(this.previousChat[c].Key==q.Key){
            this.previousChat[c].edit=false
            this.$forceUpdate();
          }
        }
        axios.get('/updateChatTitle?token='+this.auth_token+"&key="+q.Key+"&title="+this.new_thread_name+"&modulePresent="+this.isModule).then((res) => {
          // console.log(res)
          if (res.data.Status!= 1) {
            q.Name=this.new_thread_name
            this.$forceUpdate();
            // console.log("Updated Successfully")
           
            
          }
        }).catch((err) => {
          console.log(err)
        })  
      },
     
      cancelRegen(q){
        // console.log(q,"cancelRegen")
        for(let c=0;c<this.queans.length;c++){
          if(this.queans[c].Question_id==q){
            // console.log(this.queans[c].Show_save,"this.queans[c].Show_save")
            this.queans[c].Show_save=false
            this.$forceUpdate();
          }
        }
      },
      regenQuestion(q){
        //iterate over queans and match question_id and update the question
        // console.log(q,"regenQuestion")
        

        //create a get request to regen the question
       

      if (q.Question=='' || q.Question==undefined || q.Question==null){
          // console.log("no question id")
          return
        }
        // console.log("regenQuestion")
        var que_id =q.Question_id
        if (q.Parent_id != '' && q.Parent_id != undefined && q.Parent_id != null){
          // console.log("parent_id")
          que_id = q.Parent_id

        }
        this.show_answer(true,que_id,q.Question)
        
        
        //find q in queans and update the question
        for(let c=0;c<this.queans.length;c++){
          if(this.queans[c].Question_id==q.Question_id){
            this.queans[c].Show_save=false
           
            this.$forceUpdate();
          }else if ((this.queans[c].Parent_id==q.Parent_id)){
            this.queans[c].Show_save=false
           
            this.$forceUpdate();
          }
          
        }
        
        // axios.get('/regenQuestion?token='+this.auth_token+"&question_id="+this.question_id).then((res) => {
        //   console.log(res)
        //   if (res.data.Status!= 1) {
        //     console.log("regen Successfully")
        //     //update the question in queans array
           
            
        //   }
        // }).catch((err) => {
        //   console.log(err)
        // })


      },
      nextQuestion(q,d){
        // console.log(q,d, "nextQuestion")
        var regen 
        if(d=="right"){
          if (q.Regen_no==q.Regen_count){
          
            return
          }else{
            // console.log("right",q.Regen_no)
            regen=q.Regen_no+1
            // console.log(regen,"regen")


          }

        }else if (d=="left"){
          if (q.Regen_no==1){
          

            return
          }else{
            regen=q.Regen_no-1
            // console.log(regen,"regen")
          }

        }
        for (let i=0;i<this.queans.length;i++){
        
          if (this.queans[i].Question_id==q.Question_id){
           
            this.queans[i].regenLoading=true
            
            this.$forceUpdate();
            
          }
        }
        var qs={
          Question:"",
          Answer:"",
          Question_type:"",
          Question_id:"",
    
        }
        var que_id =''
        // console.log(q.Parent_id,"parent_id")
        if (q.Parent_id != '' && q.Parent_id != undefined && q.Parent_id != null){
          // console.log("parent_id")
          que_id = q.Parent_id

        }
        // console.log(que_id,"que_id")

        //create a get request to regen the question
        // console.log("regenQuestion")
        axios.
        get('/regenQuestion?token='+this.auth_token+"&question_id="+q.Question_id+"&regen_no="+regen+"&parent_id="+que_id+"&modulePresent="+this.isModule).then((res) => {
          // console.log(res)
          if (res.data.Status!= 1) {
            this.question=''
            // console.log("regen Successfully")
            //iterate over queans and match question_id and update the question
            for(let c=0;c<this.queans.length;c++){
              if(this.queans[c].Question_id==q.Question_id ){
                this.queans[c].Question=res.data.Data.Question_Answers.Question
               

                if (res.data.Data.Question_Answers.Answer=='' && res.data.Data.Question_Answers.Status==
                'Question executed'){
                  this.queans[c].Answer = "I don't have an answer to this question at the moment. Is there something else I can assist you with?"
                  this.queans[c].Question_type="text"
                  this.loadingAnswer = false
                }else if (res.data.Data.Question_Answers.Status!= 'Question executed') {
                  // console.log("status",res.data.Data.Question_Answers.Status)
                  this.queans[c].Answer=''
                  this.answerStatus = res.data.Data.Question_Answers.Status
                  this.loadingAnswer = true
                }else{
                  this.queans[c].Answer = res.data.Data.Question_Answers.Answer

                  this.loadingAnswer = false
                }
               
                
                this.queans[c].regenLoading=false
                this.queans[c].Question_type = res.data.Data.Question_Answers.Question_type
                this.queans[c].Question_id = res.data.Data.Question_Answers.Question_id
                this.queans[c].id= "message_" + this.currentRequest.toString()
                this.queans[c].Review_status=res.data.Data.Question_Answers.Review_status
                this.queans[c].Regen_no=res.data.Data.Question_Answers.Regen_no
                this.queans[c].Regen_count=res.data.Data.Question_Answers.Regen_count
                this.queans[c].Parent_id=res.data.Data.Question_Answers.Parent_id
                this.queans[c].showcheckbox=false
                if (res.data.Data.Question_Answers.Footnote!=null && res.data.Data.Question_Answers.Footnote!="" && res.data.Data.Question_Answers.Footnote!=undefined){
                  this.queans[c].Footnote = res.data.Data.Question_Answers.Footnote
                }
                this.queans[c].Answer=this.queans[c].Answer.toString()
                var scripts = ''
                    scripts = `<script type="text/javascript">
                      setTimeout(function(){`
                  if (this.queans[c].Question_type == "htmlfile") {
                    //Decode the qs.Answer from base64
                    var answer = window.atob(this.queans[c].Answer)
                  //  console.log(answer,"qs.Answer")

                    var obj = JSON.parse(answer)
                    var plot_data = obj.div64.split('.')[0]
                    var div = window.atob(plot_data);
                    // console.log("div")
                    div = div.replace(/\\"/g, '"')
                    div = div.replace(/\\'/g, "'")
                    div = div.replace(/\r?\n|\r/g, "\n")
                    this.queans[c].Plot_div  = "Plot_div_" + this.queans[c].Question_id
                    this.queans[c].div64  = div
                  //  console.log(this.plotdiv,"plotdiv")
                  var div64 = this.queans[c].div64
                    var div64_dom = new DOMParser().parseFromString(div64, 'text/html');
                    var plotdiv = div64_dom.getElementsByClassName('plotly-graph-div')[0];
                    //find id of plotdiv
                    // console.log(plotdiv,"plotdiv")
                    var plotdiv_id = plotdiv.id
                    
                    this.queans[c].Plot_id = plotdiv_id
                   

                    var script_data = obj.script64.split('.')[0]
                    var script = window.atob(script_data);
                    // console.log("script")

                    script = script.replace(/\\"/g, '"')
                    script = script.replace(/\\'/g, "'")
                    script = script.replace(/\r?\n|\r/g, "\n")
                    this.queans[c].script64  = script
                    //add loading till plotly plots the graph
                    var str = this.queans[c].script64.replace('<script type="text/javascript">', "\n")
                    var str1 = str.replace("window.PLOTLYENV=window.PLOTLYENV || {};","\n")
                    var str2 = str1.replace("<\/script>", "\n")
                    scripts += str2
                    // console.log(scripts,"scripts") 
                  
                  }
                   
                   // console.log(qs.id,"qs.id")
                    setTimeout(() => {
                   
                      scripts = scripts + `}, 500);<\/script>`
                      postscribe('#gist_1', scripts)

                      //execute the script tag
                      // var scriptTag = document.createElement("script");
                      // scriptTag.type = "text/javascript";
                      // scriptTag.innerHTML = scripts;
                      // document.getElementById(qs.id).appendChild(scriptTag);
                      setTimeout(() => {
                        // Scroll to the element with ID 'gist_1'
                        const element = document.getElementById('gist_1');
                          if (element){
                            if (this.queans[c].Question_type == "htmlfile") {
                              document.getElementById(this.queans[c].Question_id).scrollIntoView({
                              behavior: 'smooth',block: "end", inline: "nearest"
                              });
                            }else{
                                document.getElementById(this.queans[c].id).scrollIntoView({
                                behavior: 'smooth',block: "nearest", inline: "nearest"
                                
                                });
                              }
                          }
                        }, 500);
                     
                    
                   
                  }, 500);
               
                
                this.$forceUpdate();
              }
            }

           
          
           
            
          }
        }).catch((err) => {
          console.log(err)
        })



      },
      asktoAllowNotification(){
          var app= this
          
          Notification.requestPermission().then(function(result) {
            // console.log(result,"jvhj");
            if (result=='granted'){
              app.allowNotification=false
              location.reload()

            }
          });
          
        
        },
        startTimer() {
       
        if (!this.interval) {
         
          let getAnswerCalled = false; // Flag to track if getAnswer has been called in the current interval
          this.interval = setInterval(() => {
           
            // Your code to be executed every 15 seconds goes here
            if (this.loadingAnswer === true && !getAnswerCalled ) {
              this.getAnswer(this.temp_question_id);
              getAnswerCalled = true;
            } else {
              this.stopTimer();
              getAnswerCalled = false; // Reset the flag for the next interval
              if (this.loadingAnswer === true) {
                // Restart the timer only if loadingAnswer is still true
                setTimeout(() => {
                  this.startTimer();
                }, 0);
              }
            }
          }, 15000); // Change this value to 15000 for a 15-second interval
        }
      },
      stopTimer() {
        if (this.interval) {
          clearInterval(this.interval);
          this.interval = null;
        }
      },
      sendDurationToServer(duration) {
        // Example using fetch API
        var entity= this.myquestion + " "+ "(Asked "+ this.question_count+ " questions )"
        duration=duration-this.totalPageViewDuration
        axios.post("/savePageActivity?page_name="+"KnolChat"+"&token="+this.auth_token+"&input_id="+entity+"&duration="+duration+"&modulePresent="+this.isModule).then(response => {
          if (response.data.Status != 1) {
            console.log("Activity saved successfully")
          } 
          
        }).catch((error) => {
          console.log(error.message);
          if (error.message == "Request failed with status code 500") {
            this.logout()             
          }
        });
      },
      addColor(c){   
        var rgbHex = c.substring(0, 7);
        this.selectedColorList.push(rgbHex) 
        this.selectedColor=""
      },
      saveColors(){
        localStorage.setItem("selectedColorList", this.selectedColorList);
      },
      removeColor(c){
        console.log(c,"removeColor")
        var index = this.selectedColorList.indexOf(c);
        if (index > -1) {
          this.selectedColorList.splice(index, 1);
        }
      },
      resetColorList(){
        this.selectedColorList=[]
        localStorage.setItem("selectedColorList", this.selectedColorList);
    
      },
      setSelectedColors(){
        if (localStorage.getItem("selectedColorList") != null && localStorage.getItem("selectedColorList") != undefined && localStorage.getItem("selectedColorList").length > 0)  {
          this.selectedColorList = localStorage.getItem("selectedColorList").split(",");
        }
      },
      replaceType(sug, value, id,re_question) {
          // Find the entity and question
          var preExistingEntities = ["drug", "indication", "biomarker", "sponsor", "modality", "primaryendpoint", "diseasearea", "lineoftherapy", "mechanismofaction", "moa", "mechanism", "product", "regimen", "mechanism of action"];
          var entity = sug.entity.toLowerCase(); // Convert entity to lowercase
          var question = sug.question
          var words = question.split(' ');

          console.log("re_question",entity,question)


          var found = false;
          for(var prefix in preExistingEntities){
            var search = preExistingEntities[prefix] + " " + entity     
            console.log("found",search,question)
            if (question.toLowerCase().includes(search.toLowerCase())) {   
              found = true;            
              question = question.replace(search,  value + " " + entity)
            }
          }       
          if (!found) {
            const regex = new RegExp(entity, 'i');    
            // Prepend the value to the entity in the question
            question = question.replace(regex, value + " " + entity);
          }
          var new_question = question;
          
      
          // Update the question in queans array
          for (let c = 0; c < this.queans.length; c++) {
              if (this.queans[c].Question_id == id) {
                if (this.queans[c].Suggestions!=null){
                  if (this.queans[c].Suggestions.length > 0) {
                    for (let i = 0; i < this.queans[c].Suggestions.length; i++) {
                        this.queans[c].Suggestions[i].question = new_question;
                        // Use Vue's $set method to notify Vue of the change in array
                        this.$set(this.queans[c].Suggestions, i, this.queans[c].Suggestions[i]);
                    }
                  }
                }
              }
          }

          // Update the current question
          this.question = new_question;
      }
  }
  
  };
  </script>
  <style>
  .margin_align {
    overflow-x: hidden;
    overflow-y: hidden ;
    height: 100vh !important;
   
  }
  .ask_que_sec {
      font-family: Ubuntu;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.89;
      letter-spacing: normal;
      text-align: left;
      color: #202527;
      margin-left: 2vh
    }
    
   
    
    .que-text {
      font-family: Ubuntu;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: normal; 
      text-align: left;
      color: #9b9b9b;
    }
   
    .text-class {
      
      font-family: Ubuntu;
      font-size: 1.7vh;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #2c2c48;
    }
    
    .date {
     
      font-family: Ubuntu;
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #8c8e91;
    
    }
  
    .answer {
      font-family: Ubuntu;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #202527;
    }
  
    .queBox-2 {
      position:fixed;
      
      bottom: 1vh;
    }
 
   
   
    .Rectangle-1229 {
      border-radius: 2vh;
      margin: 19px 27px 0 0;
      padding: 4vh 4vh 5vh 4vh;
      box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.26);
      background-color: #edf7f9;
      height: 77vh;
    }
  
    .Path-350_1 {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
      overflow: hidden;
      padding:0vh 0vh 0vh 2vh
    }
  
    .question {
      font-family: Ubuntu;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #202527;
    }
    .v-dialog{
      margin-top:48px !important;
     
      overflow-y: hidden  !important;

    }

    .active_toggle{
      width: 139.5px;
      height: 30.7px;
      margin: 0 4px 0 4px;
      padding: 8.7px 0.5px 9px 0;
      border-radius: 2pt;
      background-color: #03a6ad;
      color: #fff;
    }
    .inactive{
     width: 139.5px;
      height: 30.7px;
      margin: 0 4px 0 4px;
      padding: 8.7px 0.5px 9px 0;
      opacity: 0.81;
      border-radius: 2px;
      background-color: #c6cdd5;
    }
    .Rectangle-1271{
      font-family: Ubuntu;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.83;
      letter-spacing: normal;
      text-align: center;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
      position:relative;
      z-index: 1;
      padding:5px;
    }
    .Ellipse-154-chat {
      width: 3.5vh;
      height: 3.5vh;
      background-color: #ffff;
      text-align: center;
      text-decoration: none;
      border-radius: 50%;
      font-size:15px;
      margin:auto;
      border:solid 1px #68adb7 !important;
     
     
    }
    .Ellipse-154-dislike_chat {
      width: 5vh;
      height: 5vh;
      border: solid 1px #03a6ad !important;
    
      text-align: center;
      text-decoration: none;
      border-radius: 50%;
      font-size:15px;
      margin:auto;
      
    }
    .Ellipse-knolchat {
      width: 4vh;
      height: 4vh;
      border: solid 1px #e3e3e3 !important;
      background-color:#ffff;
      text-align: center;
      text-decoration: none;
      border-radius: 50%;
      font-size:15px;
      margin:auto;
      
    }
    ::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }
    
    .Path-359 {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px #ff6750 !important;
      background-color: #fff;
      border-radius: 40px !important;
      overflow-y:auto;
      max-height: 500px;
    }

    .tree-view-item-key {
        color: #03a6ad;
    }
    .list:hover{
      background-color:#f9e5de;
    }
    .theme--light.v-icon{
      color: #03a6ad;
    }
    .redcolor{
      background-color:#d61b34 !important;
  }
  .v-textarea.v-text-field--solo .v-input__append-inner, .v-textarea.v-text-field--solo .v-input__append-outer, .v-textarea.v-text-field--solo .v-input__prepend-inner, .v-textarea.v-text-field--solo .v-input__prepend-outer {
    align-self: flex-start;
    margin-top: 0px !important;
}
    .Ellipse-154 {
      width: 47px;
      height: 47px;
     
      padding: 12.6px 0;
      border: solid 1px #676772;
      background-color: #fff;
      text-align: center;
      border-radius: 50%;
    }
    .OP_1_chat {
      font-family: Ubuntu;
      font-size: 2.5vh;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: center;
      color: #03a6ad;
     
    }
    .comment_menu{
      display:block;

    }
    
    .Rectangle-1517_1 {
      text-align: center;
      padding: 6px 15px;
      opacity: 0.88;
      border-radius: 16.5px;
      background-color:#e4f5f4;
      margin-top:5.5vh
      
    }
    .Line-269 {
      width: 1px;
      height: 15px;
      margin: 0 8.6px;
      background-color: #a0a0a0;
    }
    .circle1{
      border-radius: 50%;
      height: 20px;
      width: 20px;
      border: 2px solid;
      float: left;
      margin: 0px 3px 0px 15px;
      
    }
 
    html, body {
      overscroll-behavior-x: none;
  }
  .no-box-shadow .v-text-field--box-shadow {
    box-shadow: none;
  }
  
  .question_row:hover #edit {
    display: block;
    padding:0;
}
.shadow-border {
  width: 200px; /* Adjust the width as needed */
  height: 200px; /* Adjust the height as needed */
  background-color: #f0f0f0;
  position: relative;
}

.shadow-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px; /* Adjust the thickness of the shadow border */
  background: linear-gradient(to bottom, transparent, #03a6ad, transparent);
}
.v-dialog {
  box-shadow: none;
  overflow: visible;
}

.Path-1166 {
  width: 100%;
  height:auto;
  margin-bottom:-2vh;
  padding: 1.5vh 3vh 3vh 2.5vh;
  background-color: #edf7f9;
  text-align: center;
  border-radius:8px;
  justify-content: center;
}
.Path-1166_2 {
  width: 16vh;
  height:auto;
  margin-bottom:-2vh;
  padding: 1.5vh 2.5vh 3vh 2vh;
  background-color: transparent;
  text-align: center;
  border-radius:8px;
  justify-content: center;
}
.Rectangle-1578 {
  width: 1920px;
  height: 153px;
  padding: 0 0 19.8px;
  background-color: #fff;
}
.notification_dialog{
  position: absolute;
  bottom: 0;
  right: 0;
}
/*
svg{
  background:none !important;
}
rect{
  fill:transparent !important;
}
*/
.custom-bullet {
  margin-top:1vh;

}

.bullet {
  font-size: 20px; /* Adjust the size of the bullet point */
  color: #676772; /* Adjust the color of the bullet point */
  margin-right: 10px; /* Adjust the space between the bullet point and the text */
  vertical-align: middle; /* Vertically center the bullet point with text */
}
#sample_que:hover{
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  transform: translateY(-12px); 
}
.loading-dots_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  margin-top:2.5vh;
}
.loading-dots_1 div {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: .5rem;
  animation: scaling 2.5s ease-in-out infinite;
}


.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.loading-dots div {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: .5rem;
  animation: scaling 2.5s ease-in-out infinite;
}

@keyframes scaling {
  0%, 100% {
    transform: scale(0.2);
    background-color: #f16753;
  }
  40%{
    transform: scale(1);
    background-color:#76dede;
  }
  50% {
    transform: scale(1);
    background-color: #1abbbc;
  }
}

div:nth-child(0){
  animation-delay:0s;
}
div:nth-child(1){
  animation-delay:0.2s;
}
div:nth-child(2){
  animation-delay:0.4s;
}
div:nth-child(3){
  animation-delay:0.6s;
}
div:nth-child(4){
  animation-delay:0.8s;
}
div:nth-child(5){
  animation-delay:1s;
}
#typing_live {
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  animation: typing;
  animation-duration: 1.5s;
  animation-timing-function: steps(30, end);
  animation-fill-mode: forwards;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

.Active_Companies_title {
  margin: 2vh 2vh 2vh 0vh;
  font-family: Ubuntu;
  font-size: 2.8vh;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: left;
  color: #ff6750;
}

</style>
  
  
  