<template>
  <div class="margin_align">
    <div class="wholepage">    
      <div class="appLayout">
        <div style="margin-top:10vh !important">
          <loading
              :active.sync="loading"
              :can-cancel="true"
              :on-cancel="onCancel"
              :is-full-page="fullPage"
          ></loading>
        </div>
        <div>
          <v-row>
            <v-col col="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
            <v-col col="12" sm="6" md="6" lg="6" xs="6" xl="6">
              <span class="BACK" @click="gotohome()"> < Back</span>
              <div style="margin-top: 2vh;">
                <span class="mycart">My Cart</span>
              </div>     
              <v-row v-if="show_data" style="margin-top:-1vh">
                <p class="description">You have added following diseases in your cart</p> 
                <v-row wrap style="margin-top:-1vh">
                    <v-col col="12" sm="1" md="1" lg="1" xs="1" xl="1">
                     <span class="number">No.</span>
                    </v-col>
                    <v-col col="12" sm="5" md="5" lg="5" xs="5" xl="5">
                      <span class="number">Disease Name</span>
                    </v-col>
                    <v-col col="12" sm="2" md="2" lg="2" xs="2" xl="2">
                      <span class="number">Price</span>
                    </v-col>
                    <v-col col="12" sm="2" md="2" lg="2" xs="2" xl="2">
                      <span class="number">Duration</span>
                    </v-col>
                    <v-col col="12" sm="2" md="2" lg="2" xs="2" xl="2">
                      <span class="number">Action</span>
                    </v-col>
                </v-row>                  
                <v-row wrap class="Rectangle-1208" v-for="(ca,kw) in cart_diseases" :key="kw">
                    <v-col col="12" sm="1" md="1" lg="1" xs="1" xl="1">
                      <span class="content">{{kw+1}}</span>
                    </v-col>
                    <v-col col="12" sm="5" md="5" lg="5" xs="5" xl="5">
                      <span class="content">{{ca.Disease_name}}</span>
                    </v-col>
                    <v-col col="12" sm="2" md="2" lg="2" xs="2" xl="2">
                      <span class="content">$250</span>
                    </v-col>
                    <v-col col="12" sm="2" md="2" lg="2" xs="2" xl="2">
                      <span class="content">1 Year</span>
                    </v-col>
                    <v-col col="12" sm="2" md="2" lg="2" xs="2" xl="2">
                      <span @click="delete_my_disease(ca)"><v-icon color="#009ea5" style="cursor:pointer">mdi-close</v-icon></span>
                    </v-col>
                </v-row>
                <v-row style="margin:2vh 0vh 0vh 2vh">
                    <hr>
                </v-row>
                <v-row wrap class="Rectangle-1212">
                    <v-col col="12" sm="1" md="1" lg="1" xs="1" xl="1"></v-col>
                    <v-col col="12" sm="5" md="5" lg="5" xs="5" xl="5">
                      <span style="font-weight: 700">Total Amount</span>
                    </v-col>
                    <v-col col="12" sm="2" md="2" lg="2" xs="2" xl="2">
                      <span style="font-weight: 700;color:#ff6750 !important">${{cart_diseases.length * 250}}</span> 
                    </v-col>
                    <v-col col="12" sm="2" md="2" lg="2" xs="2" xl="2">({{cart_diseases.length}} Diseases)</v-col>
                    <v-col col="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
                  </v-row>
                  <v-row>
                    <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                    <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4">
                      <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh;" @click="makeStripePayment()" v-if="total_amount > 0">Proceed to Payment</v-btn>
                    </v-col>
                    <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                  </v-row>                          
              </v-row>
              <v-row v-if="!show_data" style="margin-top: 3vh;margin-bottom: 3vh;">
                <v-row>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4" align="center" justify="center">
                  <div class="Ellipse-153"><img src="../assets/Icons/Path 818.svg" style="height: 40px;object-fit: contain;"></div>
                  </v-col>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                </v-row>
                <p style="text-align: center;font-size: 18px;font-family: Ubuntu;">It seems like your cart is empty!</p>
              </v-row>
            </v-col>
            <v-col col="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
          </v-row>
        </div>   
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { EventBus } from "../event-bus.js";
export default {
  data() {
    return {
      auth_token: "",
      disesase: "",
      loading: false,
      fullPage: true,
      cart_diseases: [],
      user_email: '',
      show_data: false,
      total_amount: 0,
      stripe_key: ''
    };
  },
  components: {
    Loading,
  },
  computed: {
  },
  mounted() {
    //console.log("profile setting")

    // var disesase = localStorage.getItem("selected_disease")
    // if (disesase !== null && disesase !== "undefined" && disesase !== "") {
    //   this.disesase = disesase
    // } else {
    //   this.disesase = "Multiple sclerosis"
    // }

    var auth_token = localStorage.getItem("authtoken");
    
    if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
      this.auth_token = auth_token
      this.get_page_activity()
      this.getuser()
      this.get_wishlist()
      this.getPaymentReleatedKey()
    }
  },
  methods: {
    onCancel() {
      console.log('User cancelled the loader.')
    },
    getuser() {
      axios
        .get("/user?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status != 1) {
            this.user_email = res.data.Data.Email
          } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
            this.logOut()
          } else {
            console.log("User not found")
          }
      })
      .catch((error) => {
        console.log(error.message);
        if (error.message == "Request failed with status code 500") {
          this.logOut()             
        }
      });
    },
    get_wishlist() {
      this.loading = true
      axios
        .get("/getuserdiseasecart?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status != 1) {
            this.loading = false
            this.cart_diseases = res.data.Data.user_diseases_cart.Diseases
            if (this.cart_diseases.length > 0) {
              this.total_amount = this.cart_diseases.length * 250
              this.show_data = true
            }                
          } else {
            this.loading = false
            console.log("cart_diseases not found")
          }
      })
      .catch((error) => {
        console.log(error.message);
        // if (error.message == "Request failed with status code 500") {
        //   this.logOut()             
        // }
      });
    },
    getPaymentReleatedKey(){
      axios.post("/getPaymentReleatedKey?token="+this.auth_token)
      .then(response => {
        this.stripe_key=response.data.Data
  
      })
      .catch(error => {
        console.log(error);
      });
   
    },
    makeStripePayment() {
      this.loading = true
     
      var qty = this.cart_diseases.length
      console.log(axios.defaults.baseURL,"payment url....")
      var stripe=Stripe(this.stripe_key)
      axios.post(`/create-checkout-session?priceId=`+ priceid + "&quantity=" + qty + "&email=" + this.user_email + "&token=" + this.auth_token)
        .then(res => {
        //console.log(res.data.Data,"res.data.Data")
        if (res.data.Status !== 1) {
          const id = res.data.Data.Id;
          stripe.redirectToCheckout({sessionId: id});
          this.loading = false
        } else {
          this.loading = false
          this.err_flag = true
          console.log("error in payment redirecting")
        }
      })
    },
    gotohome() {
      this.$router.push("/").catch(() => {}) 
          
      this.l1l2_page_data = {}
      this.l1l2_page_data.article_title = "Events"
      this.l1l2_page_data.disease = this.disesase
      EventBus.$emit("l1l2_page_data", this.l1l2_page_data);
    },
    logout() {
      localStorage.removeItem("authtoken");
      localStorage.removeItem("selectedColorList");
      // localStorage.setItem("selected_article", "Industry Updates");
      this.disesase = "Multiple sclerosis"
      delete axios.defaults.headers.common["Authorization"];
      this.auth_token = null;        

      this.$router.push("/").catch(() => {})
    },
    delete_my_disease(dis) {
      this.loading = true      
      axios
        .put("/deletecartitem?token=" + this.auth_token + "&disease_name=" + dis.Disease_name)
        .then((res) => {
          if (res.data.Status != 1) {
            this.loading = false
            this.cart_diseases = []
            this.total_amount = 0
            this.cart_diseases = res.data.Data.userdiseasescart.Diseases
        
            if (this.cart_diseases.length > 0) {
              this.total_amount = this.cart_diseases.length * 250
              this.show_data = true
            } else {
              this.total_amount = 0
              this.show_data = false
            }
            //console.log(this.cart_diseases,"this.cart_diseases")
          } else {
            this.loading = false
            console.log("cart not found")
          }
      })
      .catch((error) => {
        console.log(error.message);
        // if (error.message == "Request failed with status code 500") {
        //   this.logOut()             
        // }
      });
    },
    get_page_activity(){
    axios
            .get("/fetchIndicationSelected?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status != 1) {
                console.log(res.data,"response.data")
                this.disesase = res.data.Data.Indication
                this.$forceUpdate();          
              } 
          })
  },
  },
};
</script>
<style scoped>
  .margin_align {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
  }
  .wholepage {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 20px;
    padding-top: 0px;
    margin: 0vh 0vh 5vh 5vh !important;
    overflow-y: auto
  }

  .mycart {
    margin: 2vh;
    font-family: Ubuntu;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #32324a;
  }

  .BACK {
    margin: 2vh;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.43;
    letter-spacing: normal;
    text-align: left;
    color: #03a6ad;
    cursor: pointer;
  }
  .description {
    margin: 2vh;
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: normal;
    text-align: left;
    color: #676772;
  }
  .number {
    margin: 2vh;
    padding: 1vh;
    font-family: Ubuntu;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.78;
    letter-spacing: normal;
    text-align: left;
    color: #727272;
  }

  .Rectangle-1208 {
    margin: 1vh 3vh 1vh 3vh!important;
    padding: 0.5vh;
    border-radius: 9px !important;
    border: solid 1px #4a4a5c;
    background-color: #fff;
  }

  .content {
    font-family: Ubuntu;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: right;
    color: #202527;
  }
  
  .Rectangle-1212 {
    margin: 1vh 3vh 1vh 3vh!important;
    padding: 0.5vh;
    border-radius: 9px;
    border: solid 1px #03a6ad;
    background-color: #f7faff;
  }

  .Ellipse-153 {
    width: 90px;
    height: 90px;
    margin: 2vh;
    padding: 25px;
    border-radius: 90px;
    border: solid 1px #d6d6d6;
    background-color: #f8f8f8;
  }

</style>
        