<template>
    <v-main>
      <div>      
        <div class="margin_align">
          <div>
          <loading :active.sync="loading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
          <v-row v-if="!loading">
            <v-col class="mt-2" cols="12" md="4" lg="4" sm="4" xs="4"></v-col>
            <v-col class="mt-4" cols="12" md="4" lg="4" sm="4" xs="4">
              <v-card class="Rectangle-1203">
                <v-row>
                  <v-col align="center" style="font-size:28px;color:#676772">
                      <img src="../assets/Icons/Group 951.svg"
                      class="Group-951">
                  </v-col>
              </v-row>
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3">
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6" align="center" justify="center">
                    <span style="font-family: Ubuntu;
                    font-size: 34px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.47;
                    letter-spacing: normal;
                    text-align: center;
                    color: #32324a;">Payment Successful!</span>
                    <!-- <img src="../assets/Icons/Group 975.svg"
                      style="height: 12vh;object-fit: contain;margin-top:3vh"> -->
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3">
                  </v-col>
                </v-row>
                <div v-if="process_done">
                  <div style="text-align: center;margin-top:3vh">
                    <span class="Payment-Successful">{{display_msg}}</span>
                    <p class="description">You have chosen {{""}} with annual subscription. Your purchase details as follows</p>
                  </div>
                  <hr>
                  <span class="Purchase-Details">Purchase Details</span>
                  <!-- <v-card class="Rectangle-1204" v-for="(app, ka) in approved_disease" :key="ka">
                    <span class="disease_name">{{app}}</span>
                  </v-card> -->
                </div>
                <div style="text-align: center;margin-top:3vh" v-if="!process_done">
                  <!-- <span class="Payment-Successful">{{display_msg}}</span> -->
                  <p class="description">We will get back to you very soon. <br>Please click on continue to proceed further</p>
                </div>
                <v-row>
                  <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3">
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6" align="center" justify="center">
                    <v-btn class="Path-703" @click="goback()">Continue</v-btn>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3">
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col class="mt-2" cols="12" md="4" lg="4" sm="4" xs="4"></v-col>
          </v-row>
          </div>
        </div>
      </div>
    </v-main>
  </template>
  
  <script>
  import axios from "axios";
  import { EventBus } from "../event-bus.js";
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  export default {
    data() {
      return {
        sessionId: '',
        customerId: '',
        auth_token: '',
        sessioninfo: {},
        plan: '',
        loading: false,
        fullPage: true,
        display_msg: '',
        process_done: false,
        user: {},
        approved_disease: [],
        my_approved_diseases: [],
        myplan: "",
        title: "",
        paymentId: "",
        process_done: false,
        disesase:"",
      };
    },
    components: {
      Loading,
    },
    computed: {  
    },
    mounted() {
      // localStorage.setItem("selected_article", "Transaction Success") 
      // localStorage.setItem("old_selected_article", "Transaction Success") 
      this.sessionId = this.$route.query.session_id
      this.customerId = this.$route.query.customer_id
      this.paymentId = this.$route.query.payid
      // var disesase = localStorage.getItem("selected_disease")
      // if (disesase !== null && disesase !== "undefined" && disesase !== "") {
      //   this.disesase = disesase
      // } else {
      //   this.disesase = ""
      // }
        this.get_page_activity()
  
      // this.title = localStorage.getItem("selected_disease")
      
      var auth_token = localStorage.getItem("authtoken");
      if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
        this.auth_token =  auth_token
      //  this.get_user_profile()
        this.update_status()
      } else {
        this.auth_token = ""
      }

      console.log("helloooo")
    },
    created () {
    },
    methods: {
      onCancel() {
        console.log('User cancelled the loader.')
      },
      goback() {
        // var old_selected = localStorage.getItem("selected_article");
        // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
        //   localStorage.setItem("old_selected_article", old_selected);
        // } else {
        //   old_selected = "Industry Updates"
        //   localStorage.setItem("old_selected_article", old_selected);
        // }
      // localStorage.setItem("selected_article", "Modules Library");
        this.$router.push("/").catch(() => {}) 
            
        // this.l1l2_page_data = {}
        // this.l1l2_page_data.article_title = "Events"
        // this.l1l2_page_data.disease = this.disesase
        // EventBus.$emit("l1l2_page_data", this.l1l2_page_data);
      },
      // get_session_data() {
      //   if (this.sessionId) {
      //     axios.get('/moduleCheckoutSession?sessionId='+this.sessionId+"&token="+this.auth_token).then((response) => {
      //       if (response.data.value !== 1) {
      //         console.log(response.data,"response")
      //         var user_email = response.data.customer_details.email
      //         this.sessioninfo = {}
      //         this.sessioninfo.session_id = response.data.id
      //         this.sessioninfo.customer_id = this.customerId
      //         this.sessioninfo.email = response.data.customer_details.email
      //         this.sessioninfo.amount = response.data.amount_total / 100
      //         this.sessioninfo.payment_status = response.data.payment_status
      //         this.sessioninfo.payment_method_types = response.data.payment_method_types
      //         this.sessioninfo.currency = response.data.currency
      //         this.savedate()  
      //       } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
      //         console.log("errrrororr")
      //         //this.logOut()
      //       } else {   
      //         console.log("checkout-session failed")
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error.message);
      //       // if (error.message == "Request failed with status code 500") {
      //       //   this.logOut()             
      //       // }
      //     });
      //   }
      // },
      // savedate() {
      //   axios.get('/subscriptions?token='+this.auth_token+"&customer_id="+this.customerId)
      //     .then(res => {
      //     if(res.data.Status != 1) {
      //       console.log(res.data.subscriptions,"data")
      //       var dt1 =  new Date(res.data.subscriptions.data[0].current_period_start * 1000) 
      //       var dt =  new Date(res.data.subscriptions.data[0].current_period_end * 1000) 
      //       this.sessioninfo.start_date = res.data.subscriptions.data[0].current_period_start
      //       this.sessioninfo.end_date = res.data.subscriptions.data[0].current_period_end
      //       this.sessioninfo.subscription_id = res.data.subscriptions.data[0].id
      //       this.save_subscription_info(this.sessioninfo)
      //     } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
      //       //this.logOut()
      //     } else {
      //       console.log("subscription not found")
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error.message);
      //     // if (error.message == "Request failed with status code 500") {
      //     //   this.logOut()             
      //     // }
      //   });
      // },
      // save_subscription_info(sessioninfo){
      //   var amount = sessioninfo.amount.toString()
      //   var start_date = sessioninfo.start_date.toString()
      //   var end_date = sessioninfo.end_date.toString() 
      //   var payment_method_types = sessioninfo.payment_method_types.toString()
  
      //   let tosenddata = {
      //     "session_id": sessioninfo.session_id,
      //     "customer_id": sessioninfo.customer_id,
      //     "subscription_id": sessioninfo.subscription_id,
      //     "amount": amount,
      //     "email": sessioninfo.email,
      //     "payment_status": sessioninfo.payment_status,
      //     "start_date": start_date,
      //     "end_date": end_date,        
      //     "payment_method_types": payment_method_types,
      //     "currancy": sessioninfo.currency,
      //     "plan": this.myplan
      //   }
  
      //   let url = "/createModuleSubscription?token=" + this.auth_token
      //   let config = { headers: { 'Content-Type': 'application/json' } }
      //   axios.post(url, JSON.stringify(tosenddata), config).then((res) => {
      //     if (res.data.Status !== 1) {
      //       console.log("Subscription created");
      //       this.display_msg = "Payment Successful!"
      //       this.process_done = true
      //       console.log(this.process_done,"successss")
      //       this.update_status()
      //     } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
      //       //this.logOut()
      //     } else {
      //       this.loading = false
      //       this.process_done = false
      //       console.log(this.process_done,"faillll")
      //       this.display_msg = "Payment Processing !"
      //       console.log("Error in while creating subscription");
            
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error.message);
      //     // if (error.message == "Request failed with status code 500") {
      //     //   this.logOut()             
      //     // }
      //   });
      // },
      update_status(){
        axios.post('/updateSubscriptionStatus?token='+this.auth_token+"&sid="+this.sessionId+"&cid="+this.customerId+"&pid="+this.paymentId)
        .then(res => {
          if(res.data.Status != 1) {
           
            //this.save_subscription_info(this.sessioninfo)
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            if (res.data.Data.Payment_process_complete){
                  this.cart_diseases = res.data.Data.Cart_diseases
                  this.process_done = true
                  this.display_msg = "Payment Successful !"
                  user_diseases = res.data.Data.Diseases
                  localStorage.setItem("selected_disease", user_diseases[0]); 
                  // localStorage.setItem("diseases", user_diseases);
                }else{
                  this.process_done = false
                  this.display_msg = "Payment Processing !"
                }
          } else {
            this.process_done = false
            this.display_msg = "Payment Processing !"
          }
        })
      }
   ,
    //     axios
    //       .get("/userProfile?token=" + this.auth_token)
    //       .then((res) => {
    //         //console.log(res.data.Status,"ressss")
    //         if (res.data.Status !== 1) {
    //           this.userLoggedIn = true;
    //           this.user = res.data.Data;
    //           this.myplan = this.user.Plan
    //           console.log(this.myplan,"this.myplan")
    //         } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
    //           console.log("errrrororr")
    //           this.logOut()
    //         } else { 
    //           console.log("profile not found")
    //         }
    //       })
    //       .catch((error) => {
    //         console.log(error.message);
    //         if (error.message == "Request failed with status code 500") {
    //           this.logOut()             
    //         }
    //       });
    //   },
    //   update_profile() {
    //     console.log(this.user,"this.user")
    //     axios
    //       .get("/getuserdiseases?token=" + this.auth_token)
    //       .then((res) => {
    //         if (res.data.Status != 1) {
    //           console.log(res.data.Data.userdiseases, "wislist found")
    //           var userdiseases = res.data.Data.userdiseases
    //           for (var m = 0; m < userdiseases.length; m++) {
    //             if (userdiseases[m].Status == 1) {
    //               var app_dis = {}
    //               app_dis.disesase_name = userdiseases[m].Disease
    //               app_dis.disease_id = userdiseases[m].Disease_id
    //               this.approved_disease.push(userdiseases[m].Disease)
    //               this.my_approved_diseases.push(app_dis)
    //             }
    //           }
                
    //           if (this.approved_disease.length > 0) {
  
    //               var mydis = this.user.Diseases
  
    //               console.log(mydis,"mydis")
  
    //               if (this.approved_disease.length > 0) {
    //                 mydis = mydis.concat(this.approved_disease);
    //                 var dis = mydis.sort(function(a, b) {
    //                   var textA = a.toUpperCase();
    //                   var textB = b.toUpperCase();
    //                   return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    //                 });
    //                 mydis = dis
    //               } else {
    //                 console.log("approved diseases not added")
    //               }
                          
    //               var unique_diseases = mydis.filter(this.onlyUnique);
    //               mydis = unique_diseases  
  
    //               console.log(mydis,"mydis")   
                  
    //               this.title = mydis[0]
  
    //               //console.log(this.title,"this.title")
    //               localStorage.setItem("selected_disease", this.title); 
    //               localStorage.setItem("diseases", mydis);
    //               localStorage.removeItem("plan"); 
  
  
    //               let senddata = {
    //                 company_name : this.user.Company_name,
    //                 user_position : this.user.User_position,
    //                 diseases: mydis,
    //                 other_company: this.user.Other_company,
    //                 other_position: this.user.Other_position,
    //                 other_diseases: this.user.Other_diseases,
    //                 plan: this.user.Plan,
    //               }
  
    //               let url = `/userProfile?token=` + this.auth_token
    //               let config = { headers: { 'Content-Type': 'application/json' } }
    //               axios.put(url, JSON.stringify(senddata), config).then((res) => {
    //                 if (res.data.Status == 0) {
    //                     console.log("Success") 
    //                     axios
    //                       .post("/deletediseasecart?token=" + this.auth_token)
    //                       .then((res) => {
    //                         if (res.data.Status != 1) {
    //                           this.loading = false
    //                           console.log("cart items deleted")
    //                         } else {
    //                           this.loading = false
    //                           console.log("cart items deleted")
    //                         }
    //                     })
    //                     .catch((error) => {
    //                       console.log(error.message);
    //                       if (error.message == "Request failed with status code 500") {
    //                         this.logOut()             
    //                       }
    //                     });          
    //                   } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
    //                     console.log("invalid user")
    //                     this.logOut()
    //                   } else {
    //                     this.loading = false
    //                     console.log("Error while profile updation")
    //                   }
    //               })
    //               .catch((error) => {
    //                 console.log(error.message);
    //                 if (error.message == "Request failed with status code 500") {
    //                   this.logOut()             
    //                 }
    //               });
    //           }
    //         } else {
    //           this.loading = false
    //           console.log("wislist not found")
    //         }
    //     })
    //     .catch((error) => {
    //       console.log(error.message);
    //       if (error.message == "Request failed with status code 500") {
    //         this.logOut()             
    //       }
    //     });
    //   },
      onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      },
      logOut() {
        //Auth Token Removal
        //localStorage.removeItem("selected_disease");
        //localStorage.removeItem("diseases");
        
        localStorage.removeItem("authtoken");
        // localStorage.setItem("selected_article", "Industry Updates");
        this.disesase = "Multiple sclerosis"
        this.title = "Multiple sclerosis"
        delete axios.defaults.headers.common["Authorization"];
        this.auth_token = null;
        
        this.$router.push("/").catch(() => {})
                
      },
      get_page_activity(){
      axios
            .get("/fetchIndicationSelected?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status != 1) {
                console.log(res.data,"response.data")
                this.disesase = res.data.Data.Indication
                this.$forceUpdate();          
              } 
          })
  },
    }
  };
  </script>
  
  <style scoped>
    .margin_align {
      overflow-x: hidden;
      overflow-y: auto;
      height: 100vh !important;
      background-color: #f5fffb !important;
    }
  
    .Payment-Successful {
      margin: 2vh !important;
      font-family: Ubuntu;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.47;
      letter-spacing: normal;
      text-align: center !important;
      color: #32324a;
    }
  
    .description {
      margin: 2vh;
      font-family: Ubuntu;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.89;
      letter-spacing: normal;
      text-align: center;
      color: #676772;
    }
  
    .Path-703 {
      width: 8vw;
      height: 5vh;
      padding: 2vh;
      background-color: #03a6ad !important;
      color: #fff;
    }
  
    .Rectangle-1203 {
      margin-top: 10vh;
      padding: 3vh;
      border-radius: 31px;
      box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px #707070;
      background-color: #fff;
    }
  
    .Purchase-Details {
      margin: 2vh;
      font-family: Ubuntu;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 3.13;
      letter-spacing: normal;
      text-align: left;
      color: #32324a;
    }
  
    .Rectangle-1204 {
      margin: 2vh;
      padding: 1vh;
      border: solid 1px #b1b1b1;
      background-color: #fff;
      box-shadow: none !important;
    }
  
    .disease_name {
      font-family: Ubuntu;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.89;
      letter-spacing: normal;
      text-align: left;
      color: #2c2c48;
    }
  
  
  
  </style>
  
  