<template>
  <v-main>
    <div style="margin-left:-4vw;">    
      <div>
        <div class="margin_align" id="topDiv">
        <div :class="expanded ? 'leftPanelExpand' : 'left'">
          <loading :active.sync="loading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
          <div v-if="!loading && article_not_found " style="font-family: Ubuntu;margin-bottom: -50px;margin-right: -5px;margin-top:20px">
              <v-row>
                <v-col cols="12" md="12" lg="12" sm="12" class="Rectangle-721">
                    <p class="No_article" style="text-align:center;padding-top:30vh">
                      Not found.
                    </p>
                </v-col>
              </v-row>
          </div>
          <p class="No_myarticle" style="text-align:center;padding-top:30vh" v-if="show_noarticle_msg">
            Please purchase {{disesase}} to see respective article
          </p>
          <div id="gist" style="font-family: Ubuntu;margin-bottom: -50px;margin-right: -5px;margin-top:40px">
          <div v-if="!loading && !article_not_found">
            
             
              <!--<v-row wrap style="margin-bottom: -5vh;">
                <v-col cols="12" md="5" lg="5" sm="5" xs="5">     
                  <h5 class="header_text" style="margin-top:2vh">{{page_title}}</h5>                
                </v-col>
                <v-col cols="12" md="3" lg="3" sm="3" xs="3" v-if="no_authentication">
                  <v-card flat>
                    <div class="appBarBox">
                      <v-row wrap>
                        <v-col class="" cols="12" md="4" lg="4" sm="4" xs="4">
                          <v-menu bottom nudge-bottom="55" nudge-right="0" max-width="300">
                            <template v-slot:activator="{ on, attrs }">
                              <v-sheet width="250" outlined color="#009ea5" class="ml-3">
                                <v-card width="250" elevation="0" color="#e5f1f7" height="45">
                                  <v-row>
                                    <v-col align="center">
                                      <span class="optionMenu">{{version_title}}</span>
                                      <v-icon size="14" color="#009ea5" v-bind="attrs" v-on="on" class="ml-2">
                                        mdi-arrow-down-drop-circle-outline</v-icon>
                                    </v-col>
                                  </v-row>
                                </v-card>
                              </v-sheet>
                            </template>
                            <v-list v-for="(i,kv) in versions" :key="kv">
                              <v-list-item style="cursor: pointer" @click="show_next(i)">
                                <v-col style="text-align: center">
                                  <span class="popUpVersion">
                                    {{ i.version_name}}
                                  </span>
                                </v-col>
                              </v-list-item>
                              <v-divider style="margin: 0px 0px"></v-divider>
                            </v-list>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3" lg="3" sm="3" xs="3" v-if="!no_authentication">
                  <v-card flat>
                    <div class="appBarBox">
                      <v-row wrap>
                        <v-col class="mt-4" cols="12" md="4" lg="4" sm="4" xs="4">
                          <v-menu bottom nudge-bottom="55" nudge-right="0" max-width="300">
                            <template v-slot:activator="{ on, attrs }">
                              <v-sheet width="250" outlined color="#009ea5" class="ml-3">
                                <v-card width="250" elevation="0" color="#e5f1f7" height="45">
                                  <v-row>
                                    <v-col class="pt-2" align="center">
                                      <span class="optionMenu">{{version_title}}</span>
                                      <v-icon size="14" color="#009ea5" v-bind="attrs" v-on="on" class="ml-2">
                                        mdi-arrow-down-drop-circle-outline</v-icon>
                                    </v-col>
                                  </v-row>
                                </v-card>
                              </v-sheet>
                            </template>
                            <v-list v-for="(i,kv) in versions" :key="kv">
                              <v-list-item style="cursor: pointer" @click="show_next(i)">
                                <v-col style="text-align: center">
                                  <span class="popUpVersion">
                                    {{ i.version_name}}
                                  </span>
                                </v-col>
                              </v-list-item>
                              <v-divider style="margin: 0px 0px"></v-divider>
                            </v-list>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3" lg="3" sm="3" xs="3" v-if="this.companyname != '' && !no_authentication">
                  <v-card flat>
                    <div class="appBarBox">
                      <v-row wrap>
                        <v-col class="mt-4" cols="12" md="4" lg="4" sm="4" xs="4">
                          <v-menu bottom nudge-bottom="55" nudge-right="0" max-width="300">
                            <template v-slot:activator="{ on, attrs }">
                              <v-sheet width="250" outlined color="#009ea5" class="ml-3">
                                <v-card width="250" elevation="0" color="#e5f1f7" height="45">
                                  <v-row>
                                    <v-col class="pt-2" align="center">
                                      <span class="optionMenu">Select Company</span>
                                      <v-icon size="14" color="#009ea5" v-bind="attrs" v-on="on" class="ml-2">
                                        mdi-arrow-down-drop-circle-outline</v-icon>
                                    </v-col>
                                  </v-row>
                                </v-card>
                              </v-sheet>
                            </template>
                            <v-list v-for="(c,kc) in companies" :key="kc">
                              <v-list-item style="cursor: pointer" @click="show_company(c)">
                                <v-col style="text-align: center">
                                  <span class="popUpVersion">
                                    {{c}}
                                  </span>
                                </v-col>
                              </v-list-item>
                              <v-divider style="margin: 0px 0px"></v-divider>
                            </v-list>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>
              </v-row>-->
              <v-row wrap v-if="no_info" style="padding-top:50px">
                <v-col cols="12" md="5" lg="5" sm="5" xs="5">
                  <h5 class="header_text" style="text-transform: capitalize;">Article coming soon !</h5>
                </v-col>
              </v-row>
              <!-- <v-row wrap style="margin-top: -2vh;margin-bottom:-7vh !important">
                <v-col cols="12" md="5" lg="5" sm="5" xs="5">
                  <h5 class="pharmaContent" style="text-transform: capitalize;">{{page_title}}</h5>
                </v-col>
              </v-row> -->
              <div class="menu1" @click="showqueModal()">
                <!-- <i class="fa fa-copy fa-2x" id="copy-btn"></i> -->
                <img src="../assets/Icons/Group 1102.svg" style="height:3.5vh;width:3.5vh">
              </div>
              
               
              <div v-for="(pan,kp) in panels" :key="kp" style="">
              <div class="text" @mouseup="showque">
               
                <div :id="pan.my_panel_id">
                 
                 
                  <v-row wrap v-if="pan.Panel_Info !== 'Full_Panel' && pan.Panel_Info == '' && (pan.Right.Display.plot_display == true && (pan.Right.Display.Display_Type == 'Plot' || pan.Right.Display.Display_Type == 'Table' || pan.Right.Display.Display_Type == 'Text Box' || pan.Right.Display.Display_Type == 'Card' || pan.Right.Display.Display_Type == 'Financial_Cards'))" :class="kp%2==0 ? 'even_panels': 'odd_panels'" :style="kp==0 ? 'padding-top:30px;margin-top:-2vh' : 'padding-top:20px'"> 
                    
                    <v-col cols="12" md="4" lg="4" sm="4" xs="4" style="">
                      <v-row>
                        <v-col cols="12" md="12" lg="12" sm="12" xs="12">
                          <div class="news_heading">{{pan.Left.Header}}</div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="3" lg="3" sm="3" xs="3" align="center" justify="center">
                          
                          <div class="Path-499">
                            <span >
                              <img src="../assets/images/share.png" alt="share" style="height: 1.8vh; width: 1.8vh;cursor: pointer;" @click="show_share_modal(pan.Panel_Id)"/>
                            </span>
                            <!-- Icon for Maximize and minimize -->
                            <span >
                              <a @click="showExpand()" v-show="!expanded">
                                <img src="../assets/Icons/expand.svg" alt="expandPanel"
                                        style="eight: 1.8vh; width: 1.8vh; margin-left: 1.4vw" />
                              </a>
                              <a @click="showShrink(pan)" v-show="expanded">
                                <img src="../assets/Icons/Path 533.svg" alt="expandPanel"
                                  style="eight: 1.8vh; width: 1.8vh; margin-left: 1.4vw" />
                              </a>
                            </span>
                          </div>
                        </v-col>
                        <v-col cols="12" md="8" lg="8" sm="8" xs="8"></v-col>
                      </v-row>
                      <div v-if="pan.Left.First_Text.length > 0 && pan.Left.First_Text[0] !== ''"> 
                        <div style="font-size: 15px;color:#384348" class="pre-formatted" v-for="(pa,a) in pan.Left.First_Text" :key="a">
                          <span v-html="pa"></span>
                        </div>
                        <div class="line"></div>
                      </div>
                      <div v-if="pan.Left.Second_Text.length > 0 && pan.Left.Second_Text[0] !== ''">
                        <div style="font-size: 15px;color:#384348" class="pre-formatted" v-for="(pa,b) in pan.Left.Second_Text" :key="b">
                          <span v-html="pa"></span>
                        </div>
                        <div class="line"></div>
                      </div>
                      <div v-if="pan.Left.Third_Text.length > 0 && pan.Left.Third_Text[0] !== ''">
                        <div style="font-size: 15px;color:#384348" class="pre-formatted" v-for="(pa,c) in pan.Left.Third_Text" :key="c">
                          <span v-html="pa"></span>
                        </div>
                        <div class="line"></div>
                      </div>
                      <div v-if="pan.Left.Forth_Text.length > 0 && pan.Left.Forth_Text[0] !== ''">
                        <div style="font-size: 15px;color:#384348" class="pre-formatted" v-for="(pa,d) in pan.Left.Forth_Text" :key="d">
                          <span v-html="pa"></span>
                        </div>
                        <div class="line"></div>
                      </div>
                      <div v-if="pan.Left.Fifth_Text.length > 0 && pan.Left.Fifth_Text[0] !== ''">
                        <div style="font-size: 15px;color:#384348" class="pre-formatted" v-for="(pa,e) in pan.Left.Fifth_Text" :key="e">
                          <span v-html="pa"></span>
                        </div>
                        <div class="line"></div>
                      </div>
                      <div>
                        <p class="linktitle">{{pan.Left.Link_Title}}</p>
                        <v-sheet outlined v-if="pa.Link !== '' || pa.Link_Text !== ''" class="links" v-for="(pa,ke) in pan.Left.Links" :key="ke" style="">
                       
                          <v-row wrap class="ma-0 pa-0" style="padding:0vh">
                            <v-col  cols="12" md="1" lg="1" sm="1" xs="1">
                              <div class="Rectangle-718">
                                <img alt="Link" 
                                  src="../assets/Icons/Path 344.svg"
                                  style="text-align: center;height:15px"
                                  >
                              </div>
                              </v-col>
                            <v-col  cols="12" md="10" lg="10" sm="10" xs="10">
                              <div style="margin-top:2px;margin-left:2vh">
                                <a class="link_content" @click="takemeto(pa.Link)" style="text-decoration: none">{{pa.Link_Text}}</a>
                              </div>
                            </v-col>
                          </v-row>
                        </v-sheet>
                      </div>
                    </v-col>
                    
                    <v-col cols="12" md="7" lg="7" sm="7" xs="7" style="margin-left:70px">
                      <div>
                        <v-row>
                          <div v-if="pan.Right.Display.Display_Type == 'Card'">
                            <v-row wrap>
                              <v-col  cols="12" md="4" lg="4" sm="4" xs="4"
                                v-for="(pa,key1) in pan.Right.Display.Display_Info" :key="key1">
                                <v-card style="height:280px;width:95%;padding:0vh 2vh 0vh 2vh;margin-right: 4vh; ">
                                  <!-- card title -->
                                  <v-row wrap style="height:80px">
                                    <v-col class="mt-4" cols="12" md="3" lg="3" sm="3" xs="3">
                                      <img :src="pa.Logo" basic style="height: 22px; width: 22px;margin-left:1vh" />
                                    </v-col>
                                    <v-col class="mt-4" cols="12" md="9" lg="9" sm="9" xs="9">
                                      <p class="cardTitles">{{pa.Title}}</p>
                                    </v-col>
                                  </v-row>
                                  <!-- card contents  if Image-->
                                  <div v-if="pa.Value_Type == 'image'">
                                    <div class="centerAlign" style="height: 190px;">
                                      <img :src="pa.myimage.src" alt="ChannelLogo" />
                                    </div>
                                  </div>
                                  <!-- if text -->
                                  <div v-if="pa.Value_Type == 'text'" >
                                    <div style="margin-top:20px">
                                      <v-row>
                                        <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                                        <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4">
                                          <div style="margin-top: 10px">
                                            <hr class="hrLine">
                                            <span class="itemCountDesign centerAlign">{{pa.count}}</span>
                                            <hr class="hrLine">
                                          </div>

                                        </v-col>
                                        <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                                      </v-row>
                                    </div>
                                    <div class="titleCenter" style="margin-top:40px">
                                      <span class="title1">{{pa.rem_text}}</span>
                                    </div>
                                  </div>
                                </v-card>
                              </v-col>

                            </v-row>
                          </div>
                          <div class="text" v-if="pan.Right.Display.Display_Type == 'Text Box'">
                            <div>
                              <div v-for="(pa,key3) in pan.Right.Display.Display_Info" :key="key3">
                                <h5 class="texttitle">{{pa.Title}}</h5>
                                  <p v-if="!pa.textLength" class="textbox_content pre-formatted" v-html="pa.newText"></p>
                                  <p v-if="pa.textLength" class="textbox_content pre-formatted" v-html="pa.Text"></p>
                                  <span @click="changetextLength(pa)" class="pointer" v-if="!pa.textLength && !pa.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;">Read More</span>
                                  <span @click="changetextLength(pa)" class="pointer" v-if="pa.textLength && !pa.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;">Show Less</span>                               
                              </div>
                            </div>
                          </div>
                          <div v-if="pan.Right.Display.Display_Type == 'Table'">
                            <!-- <v-row justify="end" style="padding-top: 0px">
                              <v-col cols="12" sm="12" md="12" lg="12" xs="12">
                                <v-text-field style="font-weight: 600;
                                    font-size: 13.5px;
                                    font-family: Ubuntu;" class="mt-6 shrink" placeholder="Search here" dense
                                  outlined background-color="#ffffff" v-model="search_table"
                                  v-on:keyup="is_enter_pressed"
                                  @click="current_panel_info(pan.Right.Display.Display_Info)"
                                  >
                                  <template v-slot:append>
                                    <v-icon color="#009ea5" size="26" @click="search_my_value(pan.Right.Display.Display_Info)" v-if="!pan.Right.Display.Display_Info[0].search_found">
                                      mdi-magnify
                                    </v-icon>
                                    <v-icon @click="clear_table_Search(pan.Right.Display.Display_Info)" color="#009ea5" size="26" v-if="pan.Right.Display.Display_Info[0].search_found">
                                        mdi-close
                                    </v-icon>
                                    
                                    
                                  </template>
                                </v-text-field>
                                <p class="cardTitles" v-if="isFound" style="margin-top:2vh">Match Found</p>
                              </v-col>
                            </v-row> -->
                            <v-row v-for="(pa,key4) in pan.Right.Display.Display_Info" :key="key4">
                              <v-row wrap>
                                <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2" v-for="(pbtn,keyyy) in pa.Dropdownnames" :key="keyyy">                       
                                    <v-btn :style="pbtn.selected ? 'background-color: #3fa6a1; color: #ffffff;font-size: 13px;font-weight:700; margin-top:0vh;font-family: Ubuntu;width:9vw;' : 'background-color: #ffffff; color: #3fa6a1; font-size: 13px;font-weight:700; margin-top:0vh; font-family: Ubuntu;width:9vw;'" @click="onChangetrials(pbtn,pa.myTable)">{{pbtn.name}}</v-btn>
                                </v-col>
                                <v-col cols="12" sm="7" md="7" lg="7" xs="7" xl="7"></v-col>
                              </v-row>
                              <table class="pl-0" :id="pa.myTable">
                                <span v-if="pa.show_dropdown == true">
                                  <tr>
                                    <th :class="(pa.Table_Data.Table_Type == 'Dual Header' && key7 == 0)? 'dualHeaderRow': 'dualHeaderAnotherRow'" v-for="(p,key7) in pa.Table_Data.Columns" :key="key7" style="min-width: 9.5vw">{{p}} <span @click="sort_rows(key7, pa.myTable)" v-show="pa.Table_Data.Sort_Enable == 'True'"><i class="fa fa-arrow-down" style="margin-left: 2vh !important;cursor: pointer;"></i></span>
                                    </th>
                                  </tr>
                                  <tr v-for="(p,key5) in pa.Table_Data.show_rows" :key="key5">
                                    <td v-for="(x,k1) in p" :key="k1" :class="(pa.Table_Data.Table_Type == 'Dual Header' && k1 == 0)? 'dualHeaderData':'dualHeaderAnotherData'">
                                      <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                    </td>
                                  </tr>
                                </span>
                                <tr v-if="pa.show_dropdown !== true">
                                  <th
                                    :class="(pa.Table_Data.Table_Type == 'Dual Header' && key77 == 0)? 'dualHeaderRow': 'dualHeaderAnotherRow'"
                                    v-for="(p,key77) in pa.Table_Data.Columns" :key="key77">
                                    <v-row wrap style="padding:0">
                                      <v-col cols="12" sm="8" md="8" lg="8" xs="8" style="padding-top:0vh">
                                        <span style="white-space:normal;margin-right:2px">{{p}}</span>
                                      </v-col>
                                      <v-col cols="12" sm="2" md="2" lg="2" xs="2" @click="sort_rows(key77, pa.myTable)" style="padding-top:0vh" v-show="pa.Table_Data.Sort_Enable == 'True'">
                                          <i class="fa fa-arrow-down"
                                            style="cursor: pointer;padding:0px"></i>
                                      </v-col>
                                    </v-row>
                                  </th>
                                </tr>
                                <tr v-for="(p,key55) in pa.Table_Data.Rows" :key="key55" v-if="pa.show_dropdown !== true">
                                  <td v-for="(x,k11) in p" :key="k11"
                                    :class="(pa.Table_Data.Table_Type == 'Dual Header' && k11 == 0)? 'dualHeaderData':'dualHeaderAnotherData'">
                                    <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                  </td>
                                </tr>
                              </table>
                              
                              <!-- <v-data-table  v-if="pa.show_dropdown !== true"
                                    :headers="pa.Table_Data.Columns"
                                    :items="pa.Table_Data.Rows"
                                    :sort-by="['calories', 'fat']"
                                    :sort-desc="[false, true]"
                                    multi-sort
                                    class="elevation-1"
                              ></v-data-table> -->
                              <!-- <table class="pl-0" v-if="pa.search_found">
                                <tr>
                                  <th
                                    :class="(pa.searched_table.Table_Type == 'Dual Header' && key7 == 0)? 'dualHeaderRow': 'dualHeaderAnotherRow'"
                                    v-for="(p,key7) in pa.searched_table.Columns" :key="key7">
                                    <v-row wrap style="padding:0">
                                      <v-col cols="12" sm="8" md="8" lg="8" xs="8" style="padding-top:0vh">
                                        <span style="white-space:normal;margin-right:2px">{{p}}</span>
                                      </v-col>
                                      <v-col cols="12" sm="2" md="2" lg="2" xs="2" @click="sort_rows(key7, p.myTable)" style="padding-top:0vh">
                                          <i class="fa fa-arrow-down"
                                            style="cursor: pointer;padding:0px"></i>
                                      </v-col>
                                    </v-row>  
                                  </th>
                                </tr>
                                <tr v-for="(p,key5) in pa.searched_table.Rows" :key="key5">
                                  <td v-for="(x,k1) in p" :key="k1"
                                    :class="(pa.searched_table.Table_Type == 'Dual Header' && k1 == 0)? 'dualHeaderData':'dualHeaderAnotherData'">
                                    <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                  </td>
                                </tr>
                              </table> -->
                            </v-row>
                          </div>
                          
                          <div v-if="pan.Right.Display.Display_Type == 'Plot'">
                            <v-row>
                            <v-col :cols="(pan.Right.Display.Display_Info.length == 1 || key6 ==2 ) ? '12' : '6'"  v-for="(pa,key6) in pan.Right.Display.Display_Info" :key="key6" style="">                             
                                <span v-html="pa.div64"></span>
                            </v-col>
                            </v-row>
                          </div>
                          <div v-if="pan.Right.Display.Display_Type == 'Financial_Cards'">
                            <div v-for="(pa,kfin) in pan.Right.Display.Display_Info" :key="kfin" style="padding:5vh">
                              <v-row class="mt-3">
                                <v-col cols="12" md="6" lg="6" sm="6" xs="6" v-for="(p,fin) in pa.Fin_Card_Data" :key="fin">
                                  <v-card class="fincard">
                                    <div v-for="(pf,fink) in p" :key="fink">
                                      <p style="margin-top:2vh; margin-bottom:2vh;font-family:Ubuntu;font-size:16px;font-weight: 700">{{pf.Header}}</p>
                                      <v-row>
                                        <v-col cols="12" md="6" lg="6" sm="6" xs="6">
                                          <span class="finance_text">{{pf.name}}</span>
                                        </v-col>
                                        <v-col cols="12" md="6" lg="6" sm="6" xs="6">
                                          <span class="finance_text">{{pf.val}}</span>
                                        </v-col>
                                        <hr>
                                      </v-row>
                                    </div>
                                  </v-card>
                                </v-col>
                              </v-row>
                              <v-row v-if="pa.Plot == 'Table'">
                                  <table style="">
                                    <tr>
                                      <th class="table_header" v-for="(pfi,ft) in pa.Columns" :key="ft">{{pfi}}</th>
                                    </tr>
                                    <tr v-for="(p,key) in pa.Rows" :key="key">
                                      <td v-for="(x,k) in p" :key="k" class="finaTableWrap">
                                        <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                      </td>
                                    </tr>
                                  </table>
                              </v-row>
                            </div>
                          </div>  
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                  <!--<v-row wrap style="margin-top:2vh;margin-bottom:5vh" v-if="pan.Panel_Info == 'Full_Panel'">
                    <v-row>
                      <div class="news_heading">{{pan.Left.Header}}</div>
                    </v-row>
                    <v-row justify="end" style="padding-top: 0px">
                      <v-col cols="12" sm="1" md="1" lg="1" xs="1"></v-col>
                      <v-col cols="12" sm="11" md="11" lg="11" xs="11">
                        <v-text-field style="font-weight: 600;
                            font-size: 13.5px;
                            font-family: Ubuntu;" class="mt-6 shrink" placeholder="Search by Company Name" dense
                          outlined background-color="#ffffff" v-model="textVal"
                          @keydown.enter.prevent="search_company_name(textVal)">
                          <template v-slot:append>
                            <v-icon @click="search_company_name(textVal)" color="#009ea5" size="26" v-if="!isFound">
                              mdi-magnify
                            </v-icon>
                            <v-icon @click="clearSearch()" color="#009ea5" size="26" v-else>
                              mdi-close
                            </v-icon>
                          </template>
                        </v-text-field>
                        <p class="cardTitles" v-if="isFound" style="margin-top:2vh">Match Found</p>
                      </v-col>
                    </v-row>
                    <v-row v-for="(pak,key) in pan.Right.Display.Display_Info" :key="key">
                      <v-col cols="12" sm="12" md="12" lg="12" xs="12">
                        <table style="margin-left:2vh">
                          <tr>
                            <th class="tablePhases1">Company</th>
                            <th
                              :style="(isFound && matchedheader== kt)?{'background-color':'#4e7471','color':'#ffffff','font-size': '16px','font-weight': '700','border': '1px solid #4e7471','width':'40vw'}:{'background-color':'#3fa6a1','color':'#ffffff','font-size': '16px', 'font-weight': '700','width':'40vw'}"
                              v-for="(alc,kt) in all_companies" :key="kt" v-if="alc != ''">{{alc}}</th>
                          </tr>
                          <tr v-for="(all,kr) in all_phases" :key="kr">
                            <td class="tablePhases1" v-for="(al,ka) in all.names" :key="ka+al">{{al}}</td>
                            <td class="tablePhases2 finathWrap" v-for="(a,kd) in all.details" :key="kd"> {{all.company}}
                              <span v-show="a.count>0 ">
                                <v-menu right offset-y offset-x nudge-left="30" nudge-top="10" max-width="230"
                                  max-height="350">
                                  <template v-slot:activator="{ on, attrs }">
                                    <div class="centerAlign">
                                      <v-btn outlined class="mech2Btn" v-bind="attrs" v-on="on"
                                        @click="format_text(a.text)">
                                        <span style="font-size: 15px" v-if="a.drug_names !== undefined">{{a.drug_names[0]}}
                                          <span style="display: block" v-if="a.count > 1"> +{{a.count-1}}
                                            Drugs</span>
                                        </span>
                                      </v-btn>
                                    </div>
                                  </template>
                                  <v-list>
                                    <v-list-item>
                                      <v-col>
                                        <div class="cardTitles1 pre-formatted" v-html="formated_text">
                                        </div>
                                      </v-col>
                                    </v-list-item>
                                    <v-divider style="margin: 0px 0px 9px 0px"></v-divider>
                                  </v-list>
                                </v-menu>
                              </span>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                  </v-row>-->
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div class="right notes_section" v-if="!expanded" >
          <v-row wrap style="margin-top:8vh">
            <v-col cols="12" md="10" lg="10" sm="10">
              <div style="font-size: 18px;font-weight: 700;margin-left:4vh;color: #49575d;">Notes</div>
            </v-col>
            <v-col cols="12" md="1" lg="1" sm="1">
              <v-icon color="#009ea5" style="cursor:pointer" @click="close_notes_section()">mdi-close</v-icon>
            </v-col>
          </v-row>
          <v-row v-if="all_notes == null" style="margin-top: 20vh">
            <v-col cols="12" md="2" lg="2" sm="2"></v-col>
            <v-col cols="12" md="8" lg="8" sm="8">
              <div>
                <img src="../assets/Icons/Group 598.svg" style="display: block;margin-left: auto;margin-right: auto;">
              </div>
              <div>
                <p
                  style="text-align: center;margin-top:5vh;margin-bottom:28vh;font-size:16px;font-weight:700;color:#8c8e8e">
                  You have not added any notes yet. Please start making notes for this article </p>
              </div>
            </v-col>
            <v-col cols="12" md="2" lg="2" sm="2"></v-col>
          </v-row>
          <v-row v-if="all_notes != null">
            <v-list v-chat-scroll class="comments_list mb-auto">
              <v-list-item>
                <v-row no-gutters>
                  <v-col cols="12" sm="11" md="11" lg="11">
                    <v-list v-for="(all, key) in all_notes" v-bind:data="all" v-bind:key="key" class="Rectangle-913">
                      <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12" xs="12">
                          <div class="text_notes">{{ all.Note}}</div>
                          <span class="date_notes">{{ all.notes_date }}</span>
                        </v-col>
                      </v-row>
                    </v-list>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-row>
          <v-row>
            <div class="noteBox">
              <v-text-field 
                style="width: 90% !important;
                font-weight: 600;
                font-size: 14px;
                font-family: Ubuntu;
                color: #383838;position: absolute;margin-left:4vh !important" v-model="mynote"
                class="mx-2 mb-3 shrink" placeholder=" Make your notes here...." solo
                prepend-inner-icon="mdi-note-plus-outline"></v-text-field>
            </div>
          </v-row>
        </div>

        <template>
          <div class="text-center">
            <v-dialog width="600" v-model="show_notes_modal">
                <v-card>
                  <v-card-title class="text-h5">
                    <span style="color: #49575d;margin-left: 2vh;;font-size: 18px;">Notes</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="show_notes_modal = false">
                      <v-icon color="#009ea5">mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                        <v-textarea
                          name="input-7-1"
                          class="mt-2"
                          v-model="mynote"
                          type="text"
                          placeholder="Enter notes here"
                          outlined
                          dense
                          style="margin-left:2vh;margin-right:2vh;font-weight: 500"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                  
                  <v-row style="margin-top: -5vh;margin-bottom: 2vh;margin-right:2vh">
                    <v-col sm="9" md="9" lg="9" xs="9" xl="9">
                      <p style="margin-left:5vh;margin-right:2vh;text-align: left;font-family:Ubuntu;font-size: 15px">{{notes_msg}}</p>
                    </v-col>
                    <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                      <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh;" @click="savenote(notes_panel)" class="{ disabled: isDisabled }" :disabled="isDisabled">Save</v-btn>
                    </v-col>
                    <!-- <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                      <v-btn style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 2.2vh;font-family: Ubuntu;" @click="show_notes_modal = false">Cancel
                      </v-btn>
                    </v-col> -->
                  </v-row>
                </v-card-actions>  
                </v-card>
              </v-dialog>
          </div>
        </template>


        <template>
          <div class="text-center">
            <v-dialog width="600" v-model="dialog">
                <v-card>
                  <v-card-title class="text-h5">
                    <span style="color: #49575d;margin-left: 2vh;;font-size: 18px;">Share</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="close_share()">
                      <v-icon color="#009ea5">mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-row style="margin-top:-3vh">
                      
                      <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                        <v-textarea
                          name="input-7-1"
                          class="mt-2"
                          v-model="quote_text"
                          type="text"
                          placeholder="Enter text and choose your social media platform to share"
                          outlined
                          dense
                          v-on:keyup="validateQuote"
                          style="margin-left:2vh;margin-right:2vh"
                        ></v-textarea>
                        <!-- <p style="font-size: 16px;font-weight:500;text-align:center">{{send_msg}}</p> -->
                      </v-col>
                      
                    </v-row>
                    <v-row style="margin-top:-5vh">
                <v-col cols="12" sm="1" md="1" lg="1" xs="1" xl="1"></v-col>
                



                
                <v-col  cols="12" sm="10" md="10" lg="10" xs="10" xl="10" >
                  <v-row wrap >
                    <v-col  cols="2" sm="2" md="2" lg="2" xs="2" xl="2">
                      
                      <div @click="Copylink" class="Ellipse-154-dislike" >
                        <input type="hidden" id="link-url" :value="myurl">
                        <span style="margin-bottom: 2px;">
                          <img src="@/assets/Icons/Path 494.svg" style="height: 3vh; width: 3vh;">
                        </span>
                      </div>
                      <div id ="cpymsg" style="font-size: 10px; text-align: center;">{{ copy_msg }}</div>
                    </v-col>
                    <v-col  cols="10" sm="2" md="2" lg="2" xs="2" xl="2" v-for="network in networks"
                      :key="network.network"
                    >
                    <ShareNetwork
                      :network="network.name"
                      :url= "myurl"
                      title="Knolens, Your personalized digital assistant that automates ongoing research, saving you time and money" 
                      :description="quote_text"
                      quote=""
                      hashtags=""
                    >
                      <div @click="share_article(network.name)">
                        <v-avatar
                        size="45"
                        class="mx-3 mb-3"
                        :color="network.color"
                        >
                          <i class="icon" :class="network.icon" color="white"></i>
                        </v-avatar>
                      </div>
                      </ShareNetwork>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="1" md="1" lg="1" xs="1" xl="1"></v-col>
                <!-- <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col> -->
              </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
          </div>
        </template>
        <template>
          <div class="text-center">
            <v-dialog
              v-model="msg_modal"
              width="600"
            >
            <v-card class="Path-359" style="padding:3vh 2vh 3vh 2vh">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                    <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;margin-right: 3vh;">
                    <span class="Active_Companies_title">Sign Up !</span>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                    <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="msg_modal = false">mdi-close</v-icon>
                  </v-col>
                </v-row>
                <v-row wrap>
                                        
                  <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                    <!-- <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;"> -->
                    <span class="com_nm1" style="margin-left:0vh">Please sign up to get this functionality.</span>
                  </v-col>
                </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-row>
                    <v-col sm="6" md="6" lg="6" xs="6" xl="6"></v-col>
                    <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                      <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh;" @click="goto_login()">Sign Up</v-btn>
                    </v-col>
                    <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                      <v-btn style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 2.2vh;font-family: Ubuntu;" @click="msg_modal = false">Cancel
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>  
              </v-card>
            </v-dialog>
          </div>
        </template>
        <template>
          <div class="text-center">
            <v-dialog
              v-model="msg_modal_no_subscribe"
              width="600"
            >
            <v-card class="Path-359" style="padding:3vh 2vh 3vh 2vh">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                    <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;margin-right: 3vh;">
                    <span class="Active_Companies_title">Subscribe !</span>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                    <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="msg_modal_no_subscribe = false">mdi-close</v-icon>
                  </v-col>
                </v-row>
                <v-row wrap>
                                        
                  <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                    <!-- <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;"> -->
                    <span class="com_nm1" style="margin-left:0vh">Please subscribe to get this functionality.</span>
                  </v-col>
                </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-row>
                    <v-col sm="6" md="6" lg="6" xs="6" xl="6"></v-col>
                    <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                      <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh;" @click="goto_user_pricing()">Subscribe</v-btn>
                    </v-col>
                    <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                      <v-btn style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 2.2vh;font-family: Ubuntu;" @click="msg_modal_no_subscribe = false">Cancel
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>  
              </v-card>
            </v-dialog>
          </div>
        </template>
        <QnAModal ref="QnAModal"></QnAModal>
      </div>
  </div>
</div>
</v-main>
</template>
<script>
import { EventBus } from "../event-bus.js";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import postscribe from "postscribe";
import Notes from "../components/Notes.vue";
import QnAModal from "@/components/QnAModal.vue";
export default {
  data() {
    return {
      auth_token: '',
      disesase: '',
      copy_msg:'',
      myjson: {},
      my_new_json: {},
      page_title: '',
      panels: [],
      version: '',
      page_type: '',
      initial_version: "1.0",
      show: false,
      link: '',
      article_date: "",
      versions: [],
      article_id: '',
      article_title: '',
      link: '',
      items_bread: [
        {
          text: 'Home',
          disabled: false,
          href: '/#/',
        }
      ],
      loading: false,
      fullPage: true,
      tab: null,
      expanded: true,
      notes_count: 0,
      items_count: 0,
      mynote: "",
      dir: "asc",
      all_notes: [],
      notes: [],
      notes_len: 0,
      all_phases: [],
      formated_text: '',
      all_companies: [],
      isFound: false,
      textVal: '',
      version_title: '',
      no_info: false,
      article_prefix: '',
      dialog: false,
      networks: [
        // {
        //   network: "facebook",
        //   name: "Facebook",
        //   icon: "fab fa-facebook-f",
        //   color: "#1877f2",
        // },

        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "fab fa-linkedin",
          color: "#007bb5",
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "fab fa-whatsapp",
          color: "#25d366",
        },
        // {
        //   network: "messenger",
        //   name: "Messenger",
        //   icon: "fab fa-facebook-messenger",
        //   color: "#0084ff",
        // },
        {
          network: "skype",
          name: "Skype",
          icon: "fab fa-skype",
          color: "#00aff0",
        },
        // {
        //   network: "telegram",
        //   name: "Telegram",
        //   icon: "fab fa-telegram-plane",
        //   color: "#0088cc",
        // },
        {
          network: "twitter",
          name: "Twitter",
          icon: "fab fa-twitter",
          color: "#1da1f2",
        },
        {
          network: "email",
          name: "Email",
          icon: "fa fa-envelope",
          color: "#1da1f2",
        },
      ],
      myurl: "",
      modal: false,
      shared_article_id: '',
      quote_text: '',
      quotetext: '',
      send_msg: '',
      companies_modal: false,
      companies_info :[],
      companyname: '',
      companies: [],
      domain_origin: '',
      search_table: '',
      table_searched: [],
      search_found: false,
      currentpanel_info: {},
      no_authentication: false,
      msg_modal: false,
      panel_shared: '',
      trials_enroll: '',
      network_items: false,
      user_approved: false,
      show_notes_modal: false,
      notes_panel: {},
      notes_msg: "",
      compare_panels: [],
      myplan: '',
      user_not_subscribed: false,
      msg_modal_no_subscribe: false,
      article_not_found : false,
      my_word: '',
      queans_modal: false,
      outputcpy: '',
      my_article: false,
      show_noarticle_msg: false,
      user_plan: '',
      planData: {}
    };
  },
  components: {
    //   SideBar,
    //   AppBar,
    QnAModal,
    Loading,
    Notes
  },
  computed: {
    isDisabled() {
      if (this.mynote !== '' && this.mynote !== ' ') {
        return false;
      } else {
        return true;
      }
    },
  },
  created(){
  },
  mounted() {
    var article_title = this.$route.query.article_title
    var disesase = this.$route.query.disease
    var companyname = this.$route.query.company_name
    
    EventBus.$on("disease_name", (dis_name) => {
      console.log(dis_name, "disease name")
      disesase = dis_name
      this.get_basic_values(article_title,disesase,companyname)
    });
    //console.log(article_title, disesase, "page dataaaaa")
    this.get_basic_values(article_title,disesase,companyname)

    EventBus.$on("page_data", (page_data) => {
      article_title = page_data.article_title
      disesase = page_data.disease
      companyname = undefined     
      this.get_basic_values(article_title,disesase,companyname)
    });   
   
  },
  beforeDestroy(){
    EventBus.$off('page_data')
  },
  methods: {
    get_basic_values(article_title,disesase,companyname) {
      var auth_token = localStorage.getItem("authtoken");

      this.domain_origin = window.location.origin

      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/plotly.js/2.8.3/plotly.min.js')
      document.head.appendChild(recaptchaScript)

      this.article_title = article_title.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');

      this.disesase = disesase.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');

      if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
        this.auth_token = auth_token
        this.no_authentication = false
        this.getUserPlan()
        this.get_breadcrum_item(this.article_title)
       

        if (companyname !== undefined) {
          this.companyname = companyname.toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');        
          // this.get_article_link_company()
          // this.get_other_companies()
        } else {
          this.get_user_profile()
        }
      } else {
        this.auth_token = ""
        this.no_authentication = true
        this.disesase = 'Multiple Sclerosis'
        this.get_breadcrum_item(this.article_title)
        if (companyname !== undefined) {
          this.companyname = companyname.toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
          //this.get_article_link_company_no_auth()
        } else {
          this.get_article_link_no_auth()
          this.version_title = "Recent version"
          var version_obj = {}
          version_obj.article_id = ""
          version_obj.version_name = "A Month Ago"
          this.versions.push(version_obj)
        }
      }
      this.$forceUpdate()
    },
    get_user_profile() {
      axios
        .get("/userProfile?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status !== 1) {
              this.user = res.data.Data;
              this.myplan = this.user.Plan
              this.my_diseases = this.user.Diseases
              axios.get("/getUserPlans?token=" + this.auth_token)
              .then((res) => {
                if (res.data.Status !== 1) {
                  this.planData = res.data.Data
                } else {
                  console.log("User plan not found")
                }
              })
              .catch((err) => {
                console.log(err);
              }); 
              console.log(this.myplan,"this.myplan")
              console.log(this.planData,"this.planData")     
              //console.log(this.my_diseases,"this.my_diseases")
              //console.log(this.disesase,"this.disesase")
              if (this.myplan == "Trial") {
                if (this.planData.Trial_exceeded == true || this.planData.Trial_remaining_days <= 0) {
                  console.log("Trial plan expired")
                  this.show_noarticle_msg = true
                } else {
                  this.get_article_link()
                  this.show_noarticle_msg = false
                  console.log("Trial plan active")
                }
              } else {
                for (var h = 0; h < this.my_diseases.length; h++) {
                  if (this.my_diseases[h].toLowerCase() == this.disesase.toLowerCase()) {
                    this.my_article = true
                  }
                }
                if (this.my_article == true) {
                  this.get_article_link()
                  this.show_noarticle_msg = false
                } else {
                  this.show_noarticle_msg = true
                }
              }
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User");     
          } else {
            this.disesase = "Multiple sclerosis"
            //console.log("Diseases not found")
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);        
          }
        });
    },
    save_page_activity(article_id) {
      var disease = this.disesase
      //console.log(article_id,"this.article_id")
      var articleid = article_id
      var duration = "0"
      var panel_duration = "0"
      var activity_name = "Page Change"
      var panel_number = "0"
      var pagename = this.article_title
      axios
        .post("/SaveActivity?id=" + articleid + '&token=' + this.auth_token + "&activity_name=" + activity_name + "&disease=" + disease + "&page_name=" + pagename + "&duration=" + duration + "&panel_duration=" + panel_duration)
        .then((res) => {
          if (res.data.Status != 1) {
            //console.log("Activity saved successfully")
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User");
          } else {
            console.log("Activity save failed")
          }
      })
      .catch((error) => {       
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);          
        }
      }); 
    },
    onCancel() {
      console.log('User cancelled the loader.')
    },
    showExpand() {
      if (!this.no_authentication) {
        if (!this.user_not_subscribed) {
          this.expanded = true;
        } else {
          this.msg_modal_no_subscribe = true
        }
      } else {
        this.msg_modal = true
      }      
    },
    showShrink(panell) {
      if (!this.no_authentication) {
        if (!this.user_not_subscribed) {
          const my_panel = panell

          localStorage.setItem("my_panel", JSON.stringify(my_panel));

          var display_info = panell.Right.Display.Display_Info

          for (var d = 0; d < display_info.length; d++) {
            if (display_info[d].hasOwnProperty("div64")) {
              delete display_info[d].div64
            } else {
              
            }

            if (display_info[d].hasOwnProperty("script64") ){
              delete display_info[d].script64
            } else {
              
            }
          }

          panell.Right.Display.Display_Info = display_info     

          this.show_notes_modal = true;
          this.mynote = ""
          this.notes_msg = ""
          this.notes_panel = panell
          var selectedpan = localStorage.getItem("my_panel");
          var mypan = JSON.parse(selectedpan)
          for (var k = 0; k < this.panels.length; k++) {
            if (mypan.my_panel_id == this.panels[k].my_panel_id) {
              this.panels[k] = mypan
            }
          } 
          this.$forceUpdate();
          localStorage.removeItem("my_panel");
        } else {
          this.msg_modal_no_subscribe = true
        }
      } else {
        this.msg_modal = true
      }
    },
    get_breadcrum_item(title) {
      for (var i = 0; i < 2; i++) {
        var bread = {}
        if (i == 0) {
          bread.text = this.disesase
          bread.href = "/#/Disease"
        } else {
          bread.text = title
          bread.href = ""
        }
        bread.disabled = false
        this.items_bread.push(bread)
      }
    },
    gotopage(link) {
      //console.log(link,"link")
      if (link !== '') {
        if (link.includes("Disease")) {
          this.$router.push("/Disease").catch(() => { })
        } else {
          this.$router.push("/").catch(() => { })
        }
      }
    },
    goto_home_page() {
      this.$router.push("/Disease").catch(() => { })
    },
    show_next(arti) {
      if (!this.no_authentication) {
        if (arti.version_name == "Recent version") {
          this.get_article_link()
        } else {
          this.my_new_json = {}
          //console.log(arti.article_id, "this.article_id")
          this.version_title = arti.version_name
          this.loading = true
          this.panels = []
          this.page_title = ''
          this.page_type = ''
          this.all_phases = []
          this.all_companies = []
          this.notes_count = 0
          this.all_notes = []
          this.mynote = ''
          this.article_id = arti.article_id
          axios
            .get("/getVersionWiseArticle?disease=" + this.disesase + "&article_title=" + this.article_title + "&article_id=" + arti.article_id + "&version=" + arti.version_name + "&token=" + this.auth_token).then((res) => {
              if (res.data.Status != 1) {
                this.article_not_found = false
                //console.log("Got URL")
                fetch(res.data.Data.Url)
                  .then(res => res.json())
                  .then((out) => {
                    this.no_info = false
                    this.my_new_json = out
                    this.get_article_content(this.my_new_json)
                    //this.show_all_notes()
                  })
                  .catch(err => { 
                    this.article_not_found = true
                    this.loading = false
                   });
              } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
                console.log("Invalid User")
              } else {
                this.loading = false
                this.no_info = true
                this.panels = []
                console.log("failure")
              }
          })
          .catch((error) => {
            if (error.message == "Request failed with status code 500") {
              console.log(error.message);           
            }
          });
        }
      } else {
        this.msg_modal = true
      }
    },
    get_article_link() {
      this.loading = true
      this.user_approved = true
      console.log(this.article_title,"article_title")
      if (this.article_title == "Therapeutic Effectiveness") {
        this.article_title = "Clinical Review"
      }
      
      setTimeout(e => {
        axios.get("/getarticleurl?token=" + this.auth_token + "&title=" + this.article_title + "&disease=" + this.disesase + "&user_approved=" + this.user_approved)
          .then((res) => {
          //console.log(res.data.Data,"res.data.Data")
            if (res.data.Status != 1) {
              this.no_info = false
              //console.log(res.data.Data,"article link found")
              var curr_article_date = res.data.Data.Created_at
              const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
              var curr_d = new Date(curr_article_date),
                month = '' + (curr_d.getMonth() + 1),
                day = '' + curr_d.getDate(),
                year = curr_d.getFullYear();
              this.article_date = [day, monthNames[curr_d.getMonth()], year].join('-')
              //console.log(this.article_date,this.article_title,"date and title")
              this.article_id = res.data.Data.ID
              this.article_not_found = false
              fetch(res.data.Data.Url)
                .then(res => res.json())
                .then((out) => {
                  this.myjson = out
                  //this.get_versions(this.article_id)
                  this.get_article_content(this.myjson)
                  //this.show_all_notes()
                })
                .catch(err => { 
                  this.article_not_found = true
                  this.loading = false
                 });

              // this.save_page_activity(this.article_id)
            } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
              console.log("Invalid User");
            } else {
              this.loading = false
              this.no_info = true
              this.panels = []
              console.log("Article link not found")
            }
          })
          .catch((error) => {
            if (error.message == "Request failed with status code 500") {
              console.log(error.message);
            }
          }); 
      },500)
    },
    get_article_link_company () {
      this.loading = true
      axios.get("/getarticlecompanyurl?token=" + this.auth_token + "&title=" + this.article_title + "&disease=" + this.disesase + "&company_name=" + this.companyname)
        .then((res) => {
          //console.log(res, "resss")
          if (res.data.Status != 1) {
            this.no_info = false
            console.log(res.data.Data,"article link found")
            var curr_article_date = res.data.Data.Created_at
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
            var curr_d = new Date(curr_article_date),
              month = '' + (curr_d.getMonth() + 1),
              day = '' + curr_d.getDate(),
              year = curr_d.getFullYear();
            this.article_date = [day, monthNames[curr_d.getMonth()], year].join('-')
            this.article_id = res.data.Data.ID
            this.article_not_found = false
            fetch(res.data.Data.Url)
              .then(res => res.json())
              .then((out) => {
                this.myjson = out
                //this.get_versions(this.article_id)
                this.get_article_content(this.myjson)
                //this.show_all_notes()
              })
              .catch(err => { 
                this.article_not_found = true
                this.loading = false
               });

            // this.save_page_activity(this.article_id)
          } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User");
          } else {
            this.loading = false
            this.no_info = true
            this.panels = []
            console.log("Article link not found")
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);           
          }
        });
    },
    get_article_content(myjson) {
      this.loading = false
      var scripts = ''
      //console.log("hello json")
      var len = Object.keys(myjson).length;
      var myval = Object.values(myjson)
      //this.version = myval[0]
      this.page_type = myjson.Type
      this.article_content = myjson.Article
      this.page_title = this.article_content.Header
      this.compare_panels = this.article_content.Panel
      this.panels = this.article_content.Panel
      
      
      //console.log(this.page_title,"this.panels")
      scripts = `<script type="text/javascript">
          setTimeout(function(){`

      this.article_prefix = ""
      if (this.article_title == "Therapy Landscape") {
        this.article_prefix = "a1"
      } else if (this.article_title == "Clinical Review"){
        this.article_prefix = "a2"
      } else if (this.article_title == "Development Pipeline") {
        this.article_prefix = "a3"
      } else if (this.article_title == "Active Companies") {
        this.article_prefix = "a4"
      } else if (this.article_title == "Comparative Review"){
        this.article_prefix = "a5"
      } else if (this.article_title == "Company Details"){
        this.article_prefix = "a6"
      } else {
        console.log("invalid article title")
      }

      //var weblink = "https://www.knolens.io/"
      //var weblink1 = "http://localhost:8080/"

      for (var j = 0; j < this.panels.length; j++) {
        //this.panels[j].my_panel_id = this.article_prefix + "_" + j

        this.panels[j].my_panel_id = this.panels[j].Panel_Id

        for (var q = 0; q < this.panels[j].Left.First_Text.length; q++) {
          if (this.panels[j].Left.First_Text[q].includes("<br>")) {
            var txt1 = this.panels[j].Left.First_Text[q].split("<br>").join("\n"); 
            this.panels[j].Left.First_Text[q] = txt1
          }
        }
        for (var q = 0; q < this.panels[j].Left.Second_Text.length; q++) {
          if (this.panels[j].Left.Second_Text[q].includes("<br>")) {
            var txt1 = this.panels[j].Left.Second_Text[q].split("<br>").join("\n"); 
            this.panels[j].Left.Second_Text[q] = txt1
          }
        }
        for (var q = 0; q < this.panels[j].Left.Third_Text.length; q++) {
          if (this.panels[j].Left.Third_Text[q].includes("<br>")) {
            var txt1 = this.panels[j].Left.Third_Text[q].split("<br>").join("\n"); 
            this.panels[j].Left.Third_Text[q] = txt1
          }
        }
        //console.log(this.panels[j].Left.Forth_Text,"Forth_Text")
        for (var q = 0; q < this.panels[j].Left.Forth_Text.length; q++) {
          if (this.panels[j].Left.Forth_Text[q].includes("<br>")) {
            //console.log("found")
            var txt1 = this.panels[j].Left.Forth_Text[q].split("<br>").join("\n"); 
            this.panels[j].Left.Forth_Text[q] = txt1
          }
        }
        for (var q = 0; q < this.panels[j].Left.Fifth_Text.length; q++) {
          if (this.panels[j].Left.Fifth_Text[q].includes("<br>")) {
            var txt1 = this.panels[j].Left.Fifth_Text[q].split("<br>").join("\n"); 
            this.panels[j].Left.Fifth_Text[q] = txt1
          }
        }
        for (var i = 0; i < this.panels[j].Right.Display.Display_Info.length; i++) {
          // to separate count and text for infographic panel 0
          if (this.panels[j].Right.Display.Display_Type == 'Card') {
            this.panels[j].Right.Display.plot_display = true
            if (this.panels[j].Right.Display.Display_Info[i].Text != "" && this.panels[j].Right.Display.Display_Info[i].Value_Type == "text") {
                var count = this.panels[j].Right.Display.Display_Info[i].Text.substring(0, this.panels[j].Right.Display.Display_Info[i].Text.indexOf(' '));
                this.panels[j].Right.Display.Display_Info[i].count = count
                this.panels[j].Right.Display.Display_Info[i].count = count              
                var rem = this.panels[j].Right.Display.Display_Info[i].Text.substring(this.panels[j].Right.Display.Display_Info[i].Text.indexOf(' ') + 1);
                this.panels[j].Right.Display.Display_Info[i].rem_text = rem
              }
              //console.log(this.panels[j].Right.Display.plot_display,"hiiiii card")
          }

          if (this.panels[j].Right.Display.Display_Type == 'Text Box' && this.panels[j].Right.Display.Display_Info[i].Text !== '') {
            if (this.panels[j].Right.Display.Display_Info[i].Text.length > 1050) {
              var newText = this.panels[j].Right.Display.Display_Info[i].Text.substring(0, 1050);
              this.panels[j].Right.Display.Display_Info[i].newText = newText
              this.panels[j].Right.Display.Display_Info[i].textLength = false
              this.panels[j].Right.Display.Display_Info[i].no_show = false  
            } else {
              var newText = this.panels[j].Right.Display.Display_Info[i].Text
              this.panels[j].Right.Display.Display_Info[i].newText = newText
              this.panels[j].Right.Display.Display_Info[i].textLength = false
              this.panels[j].Right.Display.Display_Info[i].no_show = true
            }
            this.panels[j].Right.Display.plot_display = true
          }

          
          // to get base64 image
          if (this.panels[j].Right.Display.Display_Info[i].Value_Type == "image") {
            //console.log("image")
            var image = new Image();
            image.src = 'data:image/png;base64,'+this.panels[j].Right.Display.Display_Info[i].Plot_Data            
            this.panels[j].Right.Display.Display_Info[i].myimage = image
            //console.log(image,"myimage")
          }

          if (this.panels[j].Right.Display.Display_Type == "Table") {
            this.panels[j].Right.Display.Display_Info[i].table_type = this.panels[j].Right.Display.Display_Info[i].Table_Data.Table_Type
            if (this.panels[j].Right.Display.Display_Info[i].Additional_Info !== undefined) {
              var myObj = this.panels[j].Right.Display.Display_Info[i].Additional_Info
              if (Object.keys(myObj).length > 0) {
                this.panels[j].Right.Display.Display_Info[i].show_dropdown = true
                var drpdons = this.panels[j].Right.Display.Display_Info[i].Additional_Info.Dropdowns
                this.panels[j].Right.Display.Display_Info[i].Dropdownnames = []
                for (var d = 0; d < drpdons.length; d++) {
                  var drp = {}
                  if (d == 0) {
                    drp.selected = true
                  } else {
                    drp.selected = false
                  }
                  drp.name = drpdons[d]
                  this.panels[j].Right.Display.Display_Info[i].Dropdownnames.push(drp)
                }

                this.panels[j].Right.Display.Display_Info[i].N_Rows = this.panels[j].Right.Display.Display_Info[i].Additional_Info.N_Rows
                var first_half = this.panels[j].Right.Display.Display_Info[i].N_Rows/2
                var all_rows = this.panels[j].Right.Display.Display_Info[i].Table_Data.Rows
                var first_half_rows = []
                for (var v = 0; v < first_half; v++) {
                   first_half_rows.push(all_rows[v])
                }
                this.panels[j].Right.Display.Display_Info[i].Table_Data.first_half_rows = first_half_rows
                var second_half_rows = []
                for (var w = first_half; w < all_rows.length; w++) {
                   second_half_rows.push(all_rows[w])
                }
                this.panels[j].Right.Display.Display_Info[i].Table_Data.second_half_rows = second_half_rows
                this.panels[j].Right.Display.Display_Info[i].Table_Data.show_rows = this.panels[j].Right.Display.Display_Info[i].Table_Data.first_half_rows

              } else {
                this.panels[j].Right.Display.Display_Info[i].show_dropdown = false
              }
            } else {
              this.panels[j].Right.Display.Display_Info[i].show_dropdown = false
            }
            this.panels[j].Right.Display.Display_Info[i].myTable = "mytable" + j
            this.panels[j].Right.Display.Display_Info[i].search_found = false
            var row_data = this.panels[j].Right.Display.Display_Info[i].Table_Data.Rows
            if (row_data.length > 0) {
              this.panels[j].Right.Display.plot_display = true
              for (var k = 0; k < row_data.length; k++) {
                var mysrch1 = "\n"
                var matstr = ","
                for (var l = 0; l < row_data[k].length; l++) {
                  if (row_data[k][l].includes(mysrch1)) {
                    const pieces = row_data[k][l].split(mysrch1);
                    const resultingString = pieces.join("\n");
                    // if (resultingString.includes(matstr)) {
                    //   const pieces1 = resultingString.split(matstr);
                    //   const resultingString1 = pieces.join(", ");
                    //   resultingString = resultingString1
                    // }
                    row_data[k][l] = resultingString
                  }
                }
              }
            }
            //this.panels[j].Right.Display.Display_Info[i].Table_Data.Rows = row_data
          }


          // to display financial cards info

          if (this.panels[j].Right.Display.Display_Type == 'Financial_Cards' && (this.panels[j].Right.Display.Display_Info[0].Fin_Card_Data !== undefined || this.panels[j].Right.Display.Display_Info[1].Table_Data !== undefined))  {
            //console.log("hi finance")
            var finance_data = this.panels[j].Right.Display.Display_Info[i].Table_Data
            this.panels[j].Right.Display.plot_display = true
            if (finance_data !== undefined){
              //console.log(finance_data.Columns, "columns")
              this.panels[j].Right.Display.Display_Info[i].Columns = finance_data.Columns 
              this.panels[j].Right.Display.Display_Info[i].Rows = finance_data.Rows
             }
          }

          // to display plot

          if (this.panels[j].Right.Display.Display_Type == 'Plot' && this.panels[j].Right.Display.Display_Info[i].Plot_Data !== "") {
               var double_encode = this.panels[j].Right.Display.Display_Info[i].Plot_Data.split('.')[0]
               var plt_data = window.atob(double_encode);

               //console.log(plt_data,"plt_data")

               var obj = JSON.parse(plt_data)

               //console.log(obj,"obj")

               var plot_data = obj.div64.split('.')[0]
               var div = window.atob(plot_data);

               div = div.replace(/\\"/g, '"')
               div = div.replace(/\\'/g, "'")
               div = div.replace(/\r?\n|\r/g, "\n")

               this.panels[j].Right.Display.Display_Info[i].div64 = div
               this.panels[j].Right.Display.plot_display = true

               //console.log(this.panels[j].Right.Display.Display_Info[i].div64,"div")

               var legend = obj.legend

               //console.log(legend,"legend")
            
               if (legend !== undefined) {
                var colors = {}   
                var code = Object.keys(legend);
                var name = Object.values(legend);
                var color_name = []
                var color_code = []
                for (var z=0; z < name.length; z++) {
                  if (name[z] == "Phase 1") {
                    color_name[0] = name[z]
                    color_code[0] = code[z]
                  } else if (name[z] == "Phase 2") {
                    color_name[1] = name[z]
                    color_code[1] = code[z]
                  } else if (name[z] == "Phase 3") {
                    color_name[2] = name[z]
                    color_code[2] = code[z]
                  } else if (name[z] == "Phase 4") {
                    color_name[3] = name[z]
                    color_code[3] = code[z]
                  } else if (name[z] == "Early Phase 1") {
                    color_name[4] = name[z]
                    color_code[4] = code[z]
                  } else {
                    color_name.push(name[z])
                    color_code.push(code[z])
                  }
                }

                this.panels[j].Right.Display.Display_Info[i].code = color_code
                this.panels[j].Right.Display.Display_Info[i].name = color_name
              }

               var script_data = obj.script64.split('.')[0]
               var script = window.atob(script_data);

               script = script.replace(/\\"/g, '"')
               script = script.replace(/\\'/g, "'")
               script = script.replace(/\r?\n|\r/g, "\n")
              
               this.panels[j].Right.Display.Display_Info[i].script64 = script

               // console.log(this.panels[j].Right.Display.Display_Info[i].script64,"script")
               //console.log(this.panels[j].Panel_Id,"panels id")

               var str = this.panels[j].Right.Display.Display_Info[i].script64.replace('<script type="text/javascript">', "\n")
               var str1 = str.replace("window.PLOTLYENV=window.PLOTLYENV || {};","\n")
               var str2 = str1.replace("<\/script>", "\n")
               scripts += str2
           }
        }

        if (this.panels[j].Panel_Info == 'Full_Panel') {
          this.all_phases = []
          this.all_companies = []
          if (this.panels[j].Right.Display.Display_Type == "Table") {
            for (var q = 0; q < this.panels[j].Right.Display.Display_Info.length; q++) {
              this.all_companies = this.panels[j].Right.Display.Display_Info[q].Table_Data.Columns
              var rw = this.panels[j].Right.Display.Display_Info[q].Table_Data.Rows
              for (var r = 0; r < rw.length; r++) {
                var phases = {}
                var key = Object.keys(rw[r]);
                var value = rw[r][key];
                phases.names = key
                phases.details = value
                this.all_phases.push(phases)
              }
            }
          }
          //console.log(this.all_phases, "this.all_phases")
        }

      }
      scripts = scripts + `}, 5000);<\/script>`
      //console.log(scripts,"scripts")
      //console.log(this.panels,"panels")
      postscribe('#gist', scripts)

      if (this.$route.query.panel !== undefined) {
        //var count = this.$route.query.panel.substring(this.$route.query.panel.indexOf('_') + 1)

        if (this.panels.length > 0) {
          //var panelid = this.article_prefix + '_' + count
          var panelid = this.$route.query.panel
          if (this.$route.query.panel !== undefined && this.$route.query.panel !== null && this.$route.query.panel !== '') {
            const myTimeout = setTimeout(function () {
              var element = document.getElementById(panelid);
              if (element !== null) {
                element.scrollIntoView();
              }
            }, 2000)
          }
        }
        
      }
      var myDiv = document.getElementById('topDiv');
      myDiv.scrollTop = 0;
    },
    // validateNote(e) {
    //   if (e.keyCode === 13) {
    //     this.savenote();
    //   } else {
    //     //console.log("entering");
    //   }
    // },
    savenote(notespanel) {
      //console.log(this.mynote,this.disesase,this.article_id,notespanel,"this.note")
      var notespanel = JSON.stringify(notespanel)

      let senddata = {
        article_id : this.article_id,
        disease : this.disesase,
        note: this.mynote,
        notes_panel: notespanel
      }

      let url = `/createnote?token=` + this.auth_token
      let config = { headers: { 'Content-Type': 'application/json' } }
      axios.post(url, JSON.stringify(senddata), config).then((res) => {
          if (res.data.Status != 1) {
            //console.log("note saved successfully")
            //this.show_all_notes()
            this.show_notes_modal = false
            this.notes_msg = "Your note have been saved successfully !"
          } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User");
          } else {             
            //console.log("could not save notes")
            this.notes_msg = "Could not save your note !"
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);           
          }
        });
    },
    show_all_notes() {
      this.notes_count = 0
      this.all_notes = []
      this.mynote = ''
      axios
        .get("/getallnotes?token=" + this.auth_token + "&article_id=" + this.article_id + "&disease=" + this.disesase)
        .then((res) => {
          if (res.data.Status != 1) {
            this.all_notes = res.data.Data.notes
            //console.log(this.all_notes,"this.all_notes")
            if (this.all_notes != null) {
              this.notes_count = this.all_notes.length
              for (var i = 0; i < this.all_notes.length; i++) {
                const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                var curr_d = new Date(this.all_notes[i].Created_at),
                  curr_month = '' + (curr_d.getMonth() + 1),
                  curr_day = '' + curr_d.getDate(),
                  curr_year = curr_d.getFullYear();
                this.all_notes[i].notes_date = [curr_day, monthNames[curr_d.getMonth()], curr_year].join(' ')
              }
            }
          } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User");
          } else {
            console.log("notes not found")
          }
        })
        .catch((error) => {         
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);             
          }
        });
    },
    close_notes_section() {
      this.expanded = true
    },
    format_text(txt) {
      var newStr = ""
      if (txt.includes("<br>")) {
        var txt1 = txt.split("Drug name:").join("\n <b>Drug name:</b>"); 
        newStr = txt1.split("<br>").join("\n");
      }
      this.formated_text = newStr
    },
    search_company_name(name) {
      //console.log(name,"name")
      for (var i = 0; i < this.all_companies.length; i++) {
        var mysrch1 = name.toLowerCase()
        var str = JSON.stringify(this.all_companies[i])
        if (str.toLowerCase().includes(mysrch1)) {
          this.matchedheader = i;
          if (this.matchedheader > -1) {
            this.isFound = true;
          } else {
            this.isFound = false;
          }
        } else {
          console.log("no match")
        }
      }
    },
    clearSearch() {
      this.textVal = "";
      this.isFound = false;
    },
    get_versions() {
      //console.log(this.article_id, " current article_id")
      this.versions = []
      var version_obj = {}
      version_obj.article_id = this.article_id
      version_obj.version_name = "Recent version"
      this.versions.push(version_obj)
      
      var urll = ""
      if (this.companyname == '') {
        urll = "/getVersions?disease=" + this.disesase + "&article_title=" + this.article_title + "&latest_id=" + this.article_id + "&token=" + this.auth_token
      } else {
        urll = "/getVersionsCompany?disease=" + this.disesase + "&article_title=" + this.article_title + "&latest_id=" + this.article_id + "&companyname=" + this.companyname + "&token=" + this.auth_token
      }

      axios
        .get(urll).then((res) => {
          if (res.data.Status != 1) {
            if (res.data.Data !== undefined) {
              if (res.data.Data.weekago.ID !== '') {
                var version_obj = {}
                version_obj.article_id = res.data.Data.weekago.ID
                version_obj.version_name = "A week ago"
                this.versions.push(version_obj)
              }
              if (res.data.Data.monthago.ID !== '') {
                var version_obj = {}
                version_obj.article_id = res.data.Data.monthago.ID
                version_obj.version_name = "A month ago"
                this.versions.push(version_obj)
              }
              if (res.data.Data.monthsixago.ID !== '') {
                var version_obj = {}
                version_obj.article_id = res.data.Data.monthsixago.ID
                version_obj.version_name = "6 Months ago"
                this.versions.push(version_obj)
              }
              if (res.data.Data.yearago.ID !== '') {
                var version_obj = {}
                version_obj.article_id = res.data.Data.yearago.ID
                version_obj.version_name = "1 Year ago"
                this.versions.push(version_obj)
              }
              if (res.data.Data.five_year_ago.ID !== '') {
                var version_obj = {}
                version_obj.article_id = res.data.Data.five_year_ago.ID
                version_obj.version_name = "5 Years ago"
                this.versions.push(version_obj)
              }

              //console.log(this.versions,"versions")
            }
          } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User");
          } else {
            console.log("versions not available")
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);          
          }
        });
      this.version_title = this.versions[0].version_name
    },
    sort_rows(n, mid) {
      //console.log(n, mid, "datatatt")
      var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
      table = document.getElementById(mid);    
      rows = [...table.rows];
      var tableSort = (arr) => Object.keys(arr).map((item, index) => ({item, index})).sort((a,b) => {
        if(this.dir == "asc"){
          var oa = table.rows[a.index].getElementsByTagName("TD")[n];
          var ob = table.rows[b.index].getElementsByTagName("TD")[n];
          if(oa && ob){
            return ob.innerText.toLowerCase().localeCompare(oa.innerText.toLowerCase())
          }
        }else{
          var oa = table.rows[a.index].getElementsByTagName("TD")[n];
          var ob = table.rows[b.index].getElementsByTagName("TD")[n];
          if(oa && ob){
            return oa.innerText.toLowerCase().localeCompare( ob.innerText.toLowerCase())
          }
        }
      }).map(({index})=> table.rows[index]);

      var newrows = tableSort(rows);
      if ( this.dir == "asc"){
        this.dir = "desc"
      }else{
        this.dir = "asc"
      }
      while (table.rows.length > 1) {
        table.rows[1].remove();
        //console.log("hello")
      }
      for(var i=0; i<newrows.length; i++){
        table.rows[0].insertAdjacentElement("afterend",newrows[i])
      }

    },
    takemeto(link) {
      //console.log(link,"link")
      if (!this.no_authentication) {
        //var weblink = "https://www.knolens.io/#"
        //var weblink = "http://localhost:8080/#"
        if (!this.user_not_subscribed) {
          var link1 = ''
          if (link.includes("%20")) {
            var pieces = link.split("%20");
            var result = pieces.join(" ");
            link1 = result
          } else {
            link1 = link
          }
          var rem = link1.substring(link1.indexOf('&panel=') + 7);

          if (link1.includes(this.article_title)) {
            document.getElementById(rem).scrollIntoView();
          } else {
            //var results = link1.replace(weblink, "");
            var results = link1
            //console.log(results, "results")
            this.$router.push(results).catch(() => { })
           // location.reload()
          }
        } else {
          this.msg_modal_no_subscribe = true
        }
      } else {
        this.msg_modal = true
      }
    },
    show_share_modal(panelid) {
      console.log(panelid,"panelid")
      if (!this.no_authentication) {
        if (!this.user_not_subscribed) {
          this.quote_text = ''
          this.send_msg = ''
          this.dialog = true
          this.shared_article_id = this.article_id
          this.panel_shared = panelid
          var input_id='at'
          this.myurl=this.domain_origin +"/#/sharewebarticle/" + this.shared_article_id + "/" + panelid+"/"+input_id
        } else {
          this.msg_modal_no_subscribe = true
        }
      } else {
        this.msg_modal = true
      }
    },
    Copylink(){
      cpymsg.style.display = 'block';
      let LinkToCopy = document.querySelector('#link-url')
      LinkToCopy.setAttribute('type', 'text')    // 不是 hidden 才能複製
      LinkToCopy.select()

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? true : false;
        console.log("copied",msg)
        if (msg){
          this.copy_msg="Copied!"
          setTimeout(function(){
            console.log("sqguqg")
          this.copy_msg= '';
          cpymsg.style.display = 'none';
          console.log("ddd",this.copy_msg)
          // this.$forceUpdate();
          }, 2000);
        }
        
        
      } catch (err) {
        console.log(err)
      }

      /* unselect the range */
      LinkToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    close_share() {
      this.dialog = false
      this.network_items = false
    },
    share_article(social) {
      this.dialog = false
      var medium = "social media " + social
      var duration = 0
      var panel_duration = 0
      axios
        .post("/SaveActivity?id=" + this.shared_article_id + '&token=' + this.auth_token + "&activity_name=" + medium + "&disease=" + this.disesase + "&panel_number=" + this.panel_shared + "&page_name" + this.article_title + "&panel_duration=" + panel_duration + "&duration=" + duration)
        .then((res) => {
          if (res.data.Status != 1) {
          //console.log("Activity saved successfully")
          this.network_items = false
        } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
          console.log("Invalid User");
        } else {
          console.log("Activity save failed")
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);  
        }
      });
    },
    validateQuote(e) {
      if (e.keyCode === 13) {
        this.quotetext = this.quote_text
        //this.send_msg = "Your message has been added. Please choose social media platform"
      } else {
        console.log("entering quote");
      }
    },
    addQuote() {
      this.network_items = true
      this.quotetext = this.quote_text
      this.send_msg = "Your message has been added. Please choose social media platform"
    },
    get_other_companies() {
      this.companies = []
      axios
        .get("/getarticlecompanies?&token=" + this.auth_token + "&disease=" + this.disesase + "&article_title=" + this.article_title)
        .then((res) => {
          if (res.data.Status != 1) {
            this.companies = res.data.Data
            //console.log(this.companies,"this.companies")
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User");
          } else {
            console.log("Companies not found")
          }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);          
        }
      });
    },
    show_company(name) {
      //console.log(name,"comapny")
      this.companyname = name
      this.loading = true
      axios.get("/getarticlecompanyurl?token=" + this.auth_token + "&title=" + this.article_title + "&disease=" + this.disesase + "&company_name=" + this.companyname)
        .then((res) => {
          //console.log(res, "resss")
          if (res.data.Status != 1) {
            this.no_info = false
            var curr_article_date = res.data.Data.Created_at
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
            var curr_d = new Date(curr_article_date),
              month = '' + (curr_d.getMonth() + 1),
              day = '' + curr_d.getDate(),
              year = curr_d.getFullYear();
            this.article_date = [day, monthNames[curr_d.getMonth()], year].join('-')
            this.article_id = res.data.Data.ID
            this.article_not_found = false
            fetch(res.data.Data.Url)
              .then(res => res.json())
              .then((out) => {
                this.myjson = out
                //this.get_versions(this.article_id)
                this.get_article_content(this.myjson)
                //this.show_all_notes()
              })
              .catch(err => { 
                this.article_not_found = true
                this.loading = false
              });
          } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User");
          } else {
            this.loading = false
            this.no_info = true
            this.panels = []
            console.log("Article link not found")
          }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);          
        }
      });
    },
    search_my_value(tabledata) {
      this.table_searched = []
      var table_id = ""
      for (var i = 0; i < tabledata.length; i++) {
        table_id = tabledata[i].myTable
        for (var j = 0; j < tabledata[i].Table_Data.Rows.length; j++) {
          var row_values = tabledata[i].Table_Data.Rows[j]
          for (var k = 0; k < row_values.length; k++) {
            if (row_values[k].toLowerCase().includes(this.search_table.toLowerCase())) {
              // console.log(row_values[k],"row_values[k]")
              // console.log(this.search_table,"found")
              this.table_searched.push(tabledata[i].Table_Data.Rows[j])
              break;
            }
          }  
        }
      }
      //console.log(this.table_searched,"this.table_searched")
      if (this.table_searched.length > 0) {
        for (var m = 0 ; m < this.panels.length; m++) {
          if (this.panels[m].Right.Display.Display_Type == 'Table') {
            var info = this.panels[m].Right.Display.Display_Info
            for (var n = 0; n < info.length; n++) {
              if (info[n].myTable == table_id) {
                var search_tab = {}
                info[n].search_found = true
                search_tab.Rows = []
                search_tab.Columns = info[n].Table_Data.Columns
                search_tab.Table_Type = info[n].Table_Data.Table_Type
                for (var t = 0; t < this.table_searched.length; t++) {                             
                  search_tab.Rows.push(this.table_searched[t])
                }
                info[n].searched_table = search_tab
              }
            }
          }
        }
      }
      this.$forceUpdate()
    },
    clear_table_Search(searched_data) {
      //searched_data[0].search_found = false
      //console.log(searched_data[0],"searched_data")
      for (var m = 0 ; m < this.panels.length; m++) {
        for (var t = 0; t < this.table_searched.length; t++) {
          if (this.panels[m].Right.Display.Display_Type == 'Table') {
            var info = this.panels[m].Right.Display.Display_Info
            for (var n = 0; n < info.length; n++) {
              if (info[n].myTable == searched_data[0].myTable) {
                info[n].search_found = false
                info[n].searched_table = []
              }
            }
          }
        }
      }
      this.search_table = ""
      this.$forceUpdate()
    },
    current_panel_info(tabl_data) {
      this.currentpanel_info = tabl_data
    },
    is_enter_pressed: function(e) {
      if (e.keyCode === 13) {
        //console.log("enter pressed")
        this.search_my_value(this.currentpanel_info)
      }  
    },
    get_article_link_no_auth() {
      this.loading = true
      axios.get("/get_noauth_articleurl?title=" + this.article_title)
        .then((res) => {
          if (res.data.Status != 1) {
            this.no_info = false
            var curr_article_date = res.data.Data.Created_at
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
            var curr_d = new Date(curr_article_date),
              month = '' + (curr_d.getMonth() + 1),
              day = '' + curr_d.getDate(),
              year = curr_d.getFullYear();
            this.article_date = [day, monthNames[curr_d.getMonth()], year].join('-')
            //console.log(this.article_date,this.article_title,"date and title")
            this.article_id = res.data.Data.ID
            this.article_not_found = false
            fetch(res.data.Data.Url)
              .then(res => res.json())
              .then((out) => {
                this.myjson = out
                this.get_article_content(this.myjson)
              })
              .catch(err => { 
                this.article_not_found = true
                this.loading = false
               });
              // .catch(err => { 
              //   throw err 
              //});
          } else {
            this.loading = false
            this.no_info = true
            this.panels = []
            console.log("Article link not found")
          }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);        
        }
      });
    },
    get_article_link_company_no_auth() {
      //console.log(this.article_title,"this.article_title")
      this.loading = true
      axios.get("/get_noauth_articlecompanyurl?title=" + this.article_title + "&company_name=" + this.companyname)
        .then((res) => {
          //console.log(res, "resss")
          if (res.data.Status != 1) {
            this.no_info = false
            //console.log(res.data.Data,"article link found")
            var curr_article_date = res.data.Data.Created_at
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
            var curr_d = new Date(curr_article_date),
              month = '' + (curr_d.getMonth() + 1),
              day = '' + curr_d.getDate(),
              year = curr_d.getFullYear();
            this.article_date = [day, monthNames[curr_d.getMonth()], year].join('-')
            this.article_id = res.data.Data.ID
            this.article_not_found = false
            fetch(res.data.Data.Url)
              .then(res => res.json())
              .then((out) => {
                this.myjson = out
                //this.get_versions(this.article_id)
                this.get_article_content(this.myjson)
                //this.show_all_notes()
              })
              .catch(err => { 
                this.article_not_found = true
                this.loading = false
              });
          } else {
            this.loading = false
            this.no_info = true
            this.panels = []
            console.log("Company Article link not found")
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);        
          }
        });
    },
    goto_login() {
      this.$router.push("/Login").catch(() => {})
    },
    changetextLength(obj) {
      if (!obj.textLength) {
        obj.textLength = true
      } else {
        obj.textLength = false
      } 
      this.$forceUpdate();
    },
    onChangetrials(nms,tab_id) {
      //console.log(nms,tab_id,"nmss")
      for (var j = 0; j < this.panels.length; j++) {
        for (var i = 0; i < this.panels[j].Right.Display.Display_Info.length; i++) {
          if (this.panels[j].Right.Display.Display_Type == "Table") {
            if (tab_id == this.panels[j].Right.Display.Display_Info[i].myTable) {
              if (this.panels[j].Right.Display.Display_Info[i].Additional_Info !== undefined) {
                var drop_nms = this.panels[j].Right.Display.Display_Info[i].Dropdownnames
                  if (nms.name == drop_nms[0].name) {
                    this.panels[j].Right.Display.Display_Info[i].Table_Data.show_rows = []
                    this.panels[j].Right.Display.Display_Info[i].Table_Data.show_rows = this.panels[j].Right.Display.Display_Info[i].Table_Data.first_half_rows
                    
                  }
                  if (nms.name == drop_nms[1].name) {
                    this.panels[j].Right.Display.Display_Info[i].Table_Data.show_rows = []
                    this.panels[j].Right.Display.Display_Info[i].Table_Data.show_rows = this.panels[j].Right.Display.Display_Info[i].Table_Data.second_half_rows
                  }
                  for (var k = 0; k < drop_nms.length; k++) {
                    if (nms.name == drop_nms[k].name) {
                      drop_nms[k].selected = true
                    } else {
                      drop_nms[k].selected = false
                    }                   
                  }
                }               
              }
            }
          }
        }
        this.$forceUpdate();
    },
    logout() {
      //console.log("logouttt")
      localStorage.removeItem("authtoken");
      localStorage.removeItem("selectedColorList");
      // localStorage.setItem("selected_article", "Industry Updates");
      this.disesase = "Multiple sclerosis"
      delete axios.defaults.headers.common["Authorization"];
      this.auth_token = null;        
      this.$router.push("/").catch(() => {})
    },
    goto_user_pricing() {
      this.msg_modal_no_subscribe = false
      this.$router.push("/User_pricing").catch(() => {})
    },
    //QnA related functions
  
    showque() {
      console.log("showque")
      if (this.auth_token !== null && this.auth_token !== "" &&this.auth_token !== undefined) {
      this.my_word = this.getSelectedText()
      console.log(this.my_word,"this.my_word")
      // localStorage.setItem("my_word", this.my_word);
      let selectedText = this.my_word.toString();
      console.log(selectedText,"selectedText")

      var menu = document.querySelector(".menu1");
      if (selectedText !== "") {
        
        let sel = window.getSelection();
        let getRange      = sel.getRangeAt(0);
        let rect = getRange.getBoundingClientRect();
        menu.style.top=rect.top - 32 + 'px',
        menu.style.left=( rect.left + (rect.width * 0.5)-35) + 'px'
        menu.style.display = "block";
        
        // menu.style.left = this.pageX - Math.round(rect.left) + "px";
        // menu.style.top = this.pageY - Math.round(rect.top) - 5 + "px";
        console.log(menu.style.display,"menu1.style.display")
       
        
      } else {
        
        menu.style.display = "none";
      }

      //document.getElementById("output").innerHTML = selectedText;

      if (selectedText !== "") {
        this.outputcpy = selectedText;
      } else {
        this.outputcpy = "";
      }
     
    
      console.log("showqueModal")
      //this.queans_modal = true
        //call viraj's api here
        // console.log(selectedText,".....")
        // console.log(this.outputcpy,"outputcpy")
      // if (myy !== "") {
      //   this.queans_modal = true
      // }      
      this.$forceUpdate();
      }
    },
    showqueModal(){
      
      console.log(this.queans_modal)
      var menu = document.querySelector(".menu1");
      menu.style.display = "none";

      if(this.user_plan=='Basic Plan'){
        this.queans_modal = true
      }else{
        this.queans_modal = false
      }
      this.$refs.QnAModal.showquesModal(this.outputcpy,this.queans_modal);


    },
    getSelectedText() {
      let selection = document.getSelection();
      console.log(selection,"selection")
      let selRange = selection.getRangeAt(0);
      console.log(selRange,"selRange")
      return selRange;  
    },
    getUserPlan(){
      //create a get request to get the user plan
      axios.get("/getPlan?token=" +this.auth_token ).then((response) => {
        if(response.data.Status !=1)
        console.log(response.data,"response.data")
        this.user_plan = response.data.Data.Plan
        this.$forceUpdate();
      })
    }
    
  },
};
</script>
<style lang="scss" scoped>
.margin_align {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
}
.left {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  padding-left: 2%;
  padding-right: 1.5%;
  padding-bottom: 20px;
  padding-top: 0px;
  margin: 0vh 0vh 5vh -5vh !important;
  overflow-y: auto
}

.leftPanelExpand {
  width: 100%;
  padding-left: 2%;
  padding-right: 1%;
  padding-bottom: 20px;
  padding-top: 20px;
}

.right {
  width: 40%;
  position: absolute;
  right: 0;
  overflow-y: hidden;
  background: white;
  padding-bottom: 5px;
  box-shadow: -5px 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.v-card-text {
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  float: left;
  color: #49575d;
  padding: 16px 0px 8px;
  background: white;
}

.col-md-2 {
  flex: 1 0 auto;
  width: 12.66666667%;
}

.aa {
  background: #009ea5 !important;
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff !important;
  text-transform: none;
}

.bb {
  background: #009ea5 !important;
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

table tbody {
  display: table;
  width: 100%;
}

table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  width: 98%;
  margin: 2vh;
  max-height: 90vh;
  overflow-y: auto;
  table-layout:fixed; 
  border-collapse:collapse;
}

td {
  padding: 1vh;
  font-size: 16px;
  color: #333;
  border: 1px solid #D7DBDD;
}

tr {
  border-top: 1px solid #D7DBDD;
  font-size: 16px;
  color: #333;
  white-space: pre;
  background-color: #ffffff;
}

th {
  text-align: center;
  padding: 1vh;
  background-color: #ebf3f7;
  font-family: Ubuntu;
  border: 1px solid #D7DBDD; 
}

tr:nth-child(odd) {
  background-color: #f3fbfc;
}

.line {
  border: 1px solid #797C7D;
  background-color: #797C7D !important;
  margin: 0.5rem 0;
}

.pre-formatted {
  white-space: pre-wrap;
  /* 👈 this is the important part */
}

.news_heading {
  margin: 0px 0px 18px 0px;
  font-family: Ubuntu;
  font-size: 20px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #374246;
}

.title1 {
  font-family: Ubuntu;
  font-size: 14px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
 
  color: #384348;
}

.titleCenter {
  text-align: center;
  margin:0 0.5vw;
}

.cardTitles {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #49575d;
}

.cardTitles1 {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #49575d;
}

.itemCountDesign {
  font-family: Ubuntu;
  font-size: 25px;
  font-weight: 500;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #374246;
  margin-left: 4px;
}

.pharmaContent {
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #49575d;
  margin-top: -5vh;
}

.header_text {
  text-transform: capitalize;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #49575d;
  margin-left: 15vh
}

.linktitle {
  margin: 5vh 45px 10px 0;
  font-family: Ubuntu;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #374246;
}

.hrLine {
  border-bottom: 2px solid #374246;
  margin: 0rem 0;
  max-width: 5vw;
  justify-content: center;
  display: flex;
  color:#374246
}

.Rectangle-718 {
  width: 30px;
  height: 30px;
  padding: 3px 5px 5px 9px!important;
  background-color: #d5f8fa;
}


.titleCenter {
  margin-top: 3vh;
  text-align: center;
}

.table_header {
  background-color: #3fa6a1;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  word-wrap:break-word !important;
  white-space: -o-pre-wrap; 
  word-wrap: break-word;
  white-space: pre-wrap; 
  white-space: -moz-pre-wrap; 
  white-space: -pre-wrap; 
  word-wrap:break-word !important;
}

.circle {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  border: 2px solid;
  float: left;
  margin: 10px 3px 0px 0px;
}

.text_left {
  font-weight: 600;
  color: #49575d;
  font-size: 16px
}

.appBarBox {
  height: 90px;
  padding-left: 2%;
  padding-right: 1%;
  margin-bottom: 15px;
  margin-top: -3vh !important;
  margin-left: -3vh;
  background-color: #f8fafb;
}

.optionMenu {
  color: #009ea5;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 600;
}

.popUpVersion {
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #404040;
  opacity: 0.86;
  text-align: center !important
}

.popUpDates {
  opacity: 0.86;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #404040;
  float: left;
}

.breadcrums {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  text-decoration: none;
}

.v-breadcrumbs__item {
  color: #009ea5 !important
}

.v-breadcrumbs__item v-breadcrumbs__item--disabled {
  color: #333333 !important
}

Rectangle-913 {
  margin: 2vh;
  padding: 23.1px 30.5px 23.9px 23.5px;
  border-radius: 18px;
  border: solid 1px #ccc !important;
  background-color: #f9f9f9 !important;
}

.notes_section {
  height: 100vh !important;
  background-color: #f9f9fb;
  z-index: 2;
  margin-top:0vh;
  font-family:Ubuntu
}

.comments_list {
  padding: 1vh 1vh 5vh 1vh;
  height: 75vh;
  max-height: 75vh;
  overflow-y: auto;
  background: inherit;
}

.Rectangle-913 {
  margin: 2vh;
  padding: 0vh 1vh 1vh 1vh;
  border-radius: 18px;
  border: solid 1px #ccc;
  background-color: #fff;
}

.text_notes {
  font-size: 16px;
  font-weight: 500;
  color: #49575d;
  margin-bottom:1vh !important;
}

.date_notes {
  font-size: 16px;
  font-weight: 500;
  color: #8c8e91 !important
}

.tablePhases1 {
  color: #333;
  background-color: #f8fafb;
  font-size: 16px;
  border: none !important;
  border-top: 1px solid #f8fafb !important;
}

.tablePhases2 {
  background-color: #f3fbfc;
}

.mech2Btn {
  background-color: rgba(255, 103, 80, .1);
  border-color: #ff6750;
  font-size: 16px;
  font-weight: 500;
  color: #ff6750;
  font-family: Ubuntu;
  border-radius: 10px;
  padding: 1vh !important;
  height: 100% !important;
}

.tablePhases {
  position: sticky;
  z-index: 1;
  left: 0;
  border-top: 1px solid #D7DBDD !important;
  border-bottom: 1px solid #D7DBDD !important;
  border-left: 1px solid #D7DBDD !important;
}

.tablenonphase {
  background-color: inherit;
  font-size: 16px;
  font-weight: 700
}

.fix {
  position: absolute;
  margin-left: -100px;
  width: 100px;
}

.dualHeaderRow {
  background-color: #3fa6a1;
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  text-transform: capitalize;
  border-bottom: 1px solid #D7DBDD !important;
  border-left: 1px solid #D7DBDD !important;
  position: sticky;
  z-index: 1;
  left: 0;
}

.dualHeaderAnotherRow {
  background-color: #3fa6a1;
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  text-transform: capitalize;
}

.dualHeaderData {  
  background-color: #d2edee;
  font-size: 13px;
  font-weight: 700;
  border-top: 1px solid #D7DBDD !important;
  border-bottom: 1px solid #D7DBDD !important;
  border-left: 1px solid #D7DBDD !important;
  position: sticky;
  z-index: 1;
  left: 0;
  word-wrap:break-word !important;
  white-space: -o-pre-wrap; 
  word-wrap: break-word;
  white-space: pre-wrap; 
  white-space: -moz-pre-wrap; 
  white-space: -pre-wrap; 
  word-wrap:break-word !important;
}

.dualHeaderAnotherData {
 
  background-color: inherit;
  font-size: 13px;
  font-weight: 500;
  
  //width: 100%;
  word-wrap:break-word !important;
  white-space: -o-pre-wrap; 
  word-wrap: break-word;
  white-space: pre-wrap; 
  white-space: -moz-pre-wrap; 
  white-space: -pre-wrap; 
  word-wrap:break-word !important;
}

.finaTableWrap{
  word-wrap:break-word !important;
  white-space: -o-pre-wrap; 
  word-wrap: break-word;
  white-space: pre-wrap; 
  white-space: -moz-pre-wrap; 
  white-space: -pre-wrap; 
  word-wrap:break-word !important;
}

.finathWrap{
  word-wrap:break-word !important;
  white-space: -o-pre-wrap; 
  word-wrap: break-word;
  white-space: pre-wrap; 
  white-space: -moz-pre-wrap; 
  white-space: -pre-wrap; 
  word-wrap:break-word !important;
}

.icon {
  font-size: 30px;
}
.finance_text {
  font-family: Ubuntu;
  font-weight: 500;
  font-size: 16px
}
.fincard {
  margin: 0.5vh;
  padding:2vh;
  height:70vh;
  overflow-y:scroll;
}

.Path-359 {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #ff6750 !important;
  background-color: #fff;
  border-radius: 20px !important;
}

.Active_Companies_title {
  margin: 2vh 2vh 2vh 0vh;
  font-family: Ubuntu;
  font-size: 2.8vh;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: left;
  color: #ff6750;
}
.Path-85 {
  box-shadow: none !important;
  margin: 2vh;
  padding: 1vh 3vh 2vh 3vh;
  border: solid 0.6px #d3d3d3 !important;
  background-color: #fdfefe;
  height: 40vh;
  border-radius: 7px !important;
}
.com_nm {
  margin-top: 3vh;
  font-size: 2.2vh;
  font-weight: 700;
  font-family: Ubuntu;
  text-align: center;
  color: #383838 !important;
  height: 4vh;
}
.com_nm1 {
  margin-top: 2vh;
  font-size: 2.3vh;
  font-weight: 500;
  font-family: Ubuntu;
  text-align: center;
  color: #383838 !important;
}
.noteBox {
  position:absolute;
  float:bottom;
  bottom:80px;
}
.even_panels {
  background-color:#f8fafb;
  margin:0px -10px 6px 0px;
  padding: 0px 6vw 40px 9vw;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.16) !important;
 }
 .odd_panels {
  background-color:#fff;
  margin:0px -10px 6px 0px;
  padding: 0px 6vw 40px 9vw;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
 }
 

.Path-499 {
  height: 30px;
  margin: -25px 5.5px 15px 0px;
  padding: 2px 3px 3px 2px;
  box-shadow: 0 2px 3px 0 rgba(88, 88, 88, 0.16) !important;
  border: solid 0.7px #cfd3d5;
  border-radius: 7px;
  background-color: #fff !important;
}
.link_content {
  font-family: Ubuntu;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.34;
  letter-spacing: normal;
  text-align: left;
  color:#32393c !important;
  font-size: 13px;
  text-decoration: none;
}
.textbox_content {
  font-family: Ubuntu;
  font-size: 15px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #384348;
}
.texttitle {
  font-family: Ubuntu;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #374246;
  margin-top:2vh
}
.No_article{
    font-family: Ubuntu;
    font-size: 34px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #676772;
  }

  .No_myarticle{
    font-family: Ubuntu;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #676772;
  }
  .menu1 {
    display: none;
    position: absolute;
    
    border-radius: 6px;
    
    padding:1vh 2vh 1vh 2vh;
    cursor: pointer;
    z-index: 1;
  
  }
  .menu1 i {
    color: #03a6ad;
    cursor: pointer;
    margin: 8px;
  }
  .Ellipse-154-dislike {
  width: 45px;
  height: 45px;
  border: solid 1px  #009ea5;

  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  font-size:15px;
  margin:auto;
  padding: 10px 0;
  background-color: #009ea5;
  cursor: pointer;
}
</style>
