<template>
  <v-main>
    <v-row style="max-height:100vh;overflow:hidden" v-if="!isTabletMob">
        <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" class="first_sec">
          <div>
            <img src="../assets/Icons/Knolense_Dark-014.svg" style="height: 6vh;margin: 5vh 0vh 2vh 5vh !important;cursor:pointer" @click="goBack()">
          </div>
          <div style="margin-left: 5vh;
          position: absolute;
          top: 35%;">
            <v-row>          
              <v-col cols="12" sm="10" md="10" lg="10" xs="10" xl="10">
                 <span class="Pricing-Plans_title">Pricing Plans</span>
                 <p class="Get-access">Get access to the richest source of real-time information on various dimensions of biopharmaceutical intelligence.</p>
              </v-col>
              <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
            </v-row>
          </div>
          <!-- <v-row style="bottom:20px;position: fixed;margin-left:1vh;">
            <span class="if-you">If you have an account, Login here</span>
            <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" ></v-col>
            <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" >
              <div><v-btn class="Path-820" @click="go_to_login()">Login</v-btn></div>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" ></v-col>
          </v-row> -->
       
        </v-col>
        <v-col cols="12" sm="8" md="8" lg="8" xs="8" xl="8" style="opacity: 1;background-color: #e3eef0;padding-right:20px;height:100vh;overflow-y: auto;">
          <v-row style="margin-top:1vh">
                <v-col sm="9" md="9" lg="9" xs="9" xl="9">
                  <v-card class="Rectangle-1152">
                      <v-row>
                        <v-col cols="12" md="4" lg="4" sm="4" xs="4" style="text-align: center;">
                         
                            <v-row class="Rectangle-1151">
                              <v-col align=center>
                                <span class="First-Mover">Trial</span>
                                <br>
                                <img src="../assets/images/Group 1337.png" style="height:7vh;margin-right:0vh;margin-top:0;">
                                <br>
                                <span class="Free">Free</span>
                                <br>
                                <span style="font-size:1.7vh;color:#005290;font-family: Ubuntu;"> For 7 days</span>
                                <br>
                                <v-btn class="Get-Trial-Plan" style="width:fit-content;margin-top:1vh" @click="goto_register('Free Plan')">Get Trial Plan</v-btn>
                                <br>
                              </v-col>
                            </v-row>
                            
                           
                          
                        </v-col>
                        <v-col cols="12" md="8" lg="8" sm="8" xs="8" style="text-align: center !important;">
                          <div style="padding:4vh 3vh 0vh 5vh">
                            <p class="Best-Suited-for">
                              Test drive the power of Knolens for free. <br><br>You will get access to all the capabilities of the starter plan with no restrictions for 7 days.  
                            </p>
                            <p class="Best-Suited-for" style="margin-top:2vh">No credit card needed for signup
                            </p>
                          </div>
                         
                        </v-col>
                      </v-row>
                    </v-card>
                </v-col>
                <v-col  cols="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
          </v-row>
          <v-row>
                <v-col cols="12" sm="8" md="8" lg="8" xs="8" xl="8">
                  <v-card class="Rectangle-1152_1">
                      <v-row>
                        <v-col cols="12" md="3" lg="3" sm="3" xs="3" style="text-align: center;">
                          <div class="Rectangle-1151">
                            <v-row>
                              <v-col align="center">
                                <span class="First-Mover">Starter</span>
                                <br>
                                <img src="../assets/images/Group 1339.png" style="height:7vh;margin-right:1vh;margin-top:0;">
                                <br>
                                <span class="Free">$599</span>
                                <br>
                                <span style="font-size:1.7vh;color:#005290;font-family: Ubuntu;"> USD/Year</span>
                                <br>
                                <v-btn class="Get-Trial-Plan" style="margin-top:1vh;width:fit-content" @click="goto_register('Basic Plan')">Get Starter Plan</v-btn>
                                <br>
                              </v-col>
                            </v-row>
                            
                           
                          </div>
                        </v-col>
                        <v-col cols="12" md="9" lg="9" sm="9" xs="9" style="text-align: center !important;padding:20px 35px 10px 30px">
                          <p class="Best-Suited-for">
                            <b>KnolChat:</b> The starter plan gives you unlimited access to KnolChat, a one of its kind, AI powered research assistant that dramatically simplifies your research by providing a natural language interface to answer your questions. It is built on a continuously updated database containing 1500+ diseases, 12000+ private companies (and 100k+ organizations), 4500+ mechanisms of action, and 2800+ drugs.
                          </p>
                          <p class="Best-Suited-for" style="margin-top:1vh"><b>Overview by Indications Analysis Module:</b> The starter plan gives you unlimited access to the "Overview by Indications" Module that covers the state of Therapy of ~150 indications and growing.</p>
                          <!-- <p class="Best-Suited-for" style="margin-top:1vh"><b>Landscape Analysis Modules:</b><a href="mailto:support@pienomial.com"> Contact Us </a> to purchase Landscape Analysis Module and other upcoming analysis modules</p> -->
                        </v-col>
                      </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
          </v-row>
          <v-row >
                <v-col cols="12" sm="8" md="8" lg="8" xs="8" xl="8">
                  <v-card class="Rectangle-1152_2">
                      <v-row>
                        <v-col cols="12" md="3" lg="3" sm="3" xs="3" style="text-align: center;">
                          <div class="Rectangle-1151">
                            <v-row>
                              <v-col align="center">
                                <span class="First-Mover">Enterprise</span>
                                <br>
                                <br>
                                <img src="../assets/images/Group 1338.png" style="height:7vh;margin-right:1vh;margin-top: 0vh;">
                                <br>
                                <!-- <span class="Free">Customise</span> -->
                                <br>
                                <span style="font-size:1.7vh;color:#005290;font-family: Ubuntu;"></span>
                                <br>
                                <v-btn class="Get-Trial-Plan" style="width:fit-content;" @click="contactUs()">Contact Us</v-btn>
                                <br>
                              </v-col>
                            </v-row>
                          
                          </div>
                        </v-col>
                        <v-col cols="12" md="8" lg="8" sm="8" xs="8" style="text-align: center !important;padding:20px 0px 10px 14vh">
                          <p class="Best-Suited-for">
                            We recognize no two organizations are alike.<a href="mailto:support@pienomial.com"> Contact us </a> for a custom plan that is best suited to your needs. <br><br>You will get access to everything in the Starter Plan and optional access to a wide variety of other pre-built and custom-built modules.
                          </p>
                        </v-col>
                      </v-row>
                    </v-card>
                </v-col>
                
                <v-col cols="12" md="4" lg="4" sm="4" xs="4"></v-col>
          </v-row>        
        </v-col>           
      </v-row>
      <v-row style="max-height:100vh;overflow-y:hidden" v-if="isTabletMob">
        <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12" align="center" style="opacity: 1;background-color: #e3eef0;height:100vh;">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12" align="left">
              <div style="background-color:#013d48;margin-top:-4vh">
                <img src="../assets/Icons/Knolense_Dark-01.svg" style="height: 5vh;margin: 3vh 0vh 2vh 2vh !important;cursor:pointer" @click="goBack()">
              </div>
            </v-col>
          </v-row>
          <div style="max-height:93vh;margin-top:1vh;overflow-y: auto;overflow-x:hidden;padding-bottom: 10vh;">
            <v-row>          
            <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12" align="center">
              <span class="Pricing-Plans_title" style="color:#3e3936">Pricing Plans</span>
              <p class="Get-access"  style="color:#3e3936;text-align: center;margin-left:8vh">Get access to the richest source of real-time information on various dimensions of biopharmaceutical intelligence.</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12" align="center">
              <v-card class="Rectangle-115233333">
                <v-row>
                  <v-col cols="12" md="4" lg="4" sm="4" xs="4" style="text-align: center;padding:0px 0px 0px 22px">                       
                    <v-row class="Rectangle-11513333">
                      <v-col align=center>
                        <span class="First-Mover333">Trial</span>
                        <br>
                        <img src="../assets/images/Group 1337.png" style="height:7vh;margin-right:0vh;margin-top:0;">
                        <br>
                        <span class="Free3333">Free</span>
                        <br>
                        <span style="font-size:1.7vh;color:#005290;font-family: Ubuntu;"> For 7 days</span>
                        <br>
                        <v-btn class="Get-Trial-Plan333" style="width:fit-content;margin-top:1vh" @click="goto_register('Free Plan')">Get Trial Plan</v-btn>
                        <br>
                      </v-col>
                    </v-row>                                 
                  </v-col>
                  <v-col cols="12" md="8" lg="8" sm="8" xs="8" style="text-align: center !important;">
                    <div style="padding:2vh 3vh 2vh 5vh">
                      <p class="Best-Suited-for">
                        Test drive the power of Knolens for free. <br><br>You will get access to all the capabilities of the starter plan with no restrictions for 7 days.  
                      </p>
                      <p class="Best-Suited-for" style="margin-top:2vh">No credit card needed for signup</p>
                    </div>       
                  </v-col>
                </v-row>
              </v-card>
              <v-card class="Rectangle-115233333" style="margin-top:4vh">
                <v-row>
                  <v-col cols="12" md="4" lg="4" sm="4" xs="4" style="text-align: center;padding:0px 0px 0px 22px">                       
                    <v-row class="Rectangle-11513333">
                      <v-col align=center>
                        <span class="First-Mover333">Starter</span>
                        <br>
                        <img src="../assets/images/Group 1339.png" style="height:7vh;margin-right:0vh;margin-top:0;">
                        <br>
                        <span class="Free3333">$599</span>
                        <br>
                        <span style="font-size:1.7vh;color:#005290;font-family: Ubuntu;"> USD/Year</span>
                        <br>
                        <v-btn class="Get-Trial-Plan333" @click="goto_register('Basic Plan')" style="word-break: break-all;width:fit-content;margin-top:1vh">Get Starter Plan</v-btn>
                        <br>
                      </v-col>
                    </v-row>                                 
                  </v-col>
                  <v-col cols="12" md="8" lg="8" sm="8" xs="8" style="margin-bottom: 2vh;text-align: center !important;">
                    <div style="padding:2vh 3vh 2vh 5vh;max-height:25vh;overflow-y: auto;">
                      <p class="Best-Suited-for">
                        <b>KnolChat:</b> The starter plan gives you unlimited access to KnolChat, a one of its kind, AI powered research assistant that dramatically simplifies your research by providing a natural language interface to answer your questions. It is built on a continuously updated database containing 1500+ diseases, 12000+ private companies (and 100k+ organizations), 4500+ mechanisms of action, and 2800+ drugs.
                      </p>
                      <p class="Best-Suited-for" style="margin-top:1vh"><b>Overview by Indications Analysis Module:</b> The starter plan gives you unlimited access to the "Overview by Indications" Module that covers the state of Therapy of ~150 indications and growing.</p>
                          
                    </div>       
                  </v-col>
                </v-row>
              </v-card>
              <v-card class="Rectangle-115233333" style="margin-top:4vh">
                <v-row>
                  <v-col cols="12" md="4" lg="4" sm="4" xs="4" style="text-align: center;padding:0px 0px 0px 22px">                       
                    <v-row class="Rectangle-11513333">
                      <v-col align=center>
                        <span class="First-Mover333">Enterprise</span>
                        <br><br>
                        <img src="../assets/images/Group 1338.png" style="height:7vh;margin-right:0vh;">
                        <br><br>
                        <v-btn class="Get-Trial-Plan333" @click="contactUs()" style="word-break: break-all;width:fit-content;margin-top:1vh">Contact Us</v-btn>
                        <br>
                      </v-col>
                    </v-row>                                 
                  </v-col>
                  <v-col cols="12" md="8" lg="8" sm="8" xs="8" style="margin-bottom: 2vh;text-align: center !important;">
                    <div style="padding:2vh 3vh 2vh 5vh;max-height:25vh;overflow-y: auto;">
                      <p class="Best-Suited-for">
                        We recognize no two organizations are alike.<a href="mailto:support@pienomial.com"> Contact us </a> for a custom plan that is best suited to your needs. <br><br>You will get access to everything in the Starter Plan and optional access to a wide variety of other pre-built and custom-built modules.
                      </p> 
                    </div>       
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </div>
        </v-col>
      </v-row>
  </v-main>
</template>

<script>
import axios from "axios";
import { EventBus } from "../event-bus.js";
export default {
  data() {
    return {
      contactus:false
    };
  },
  created () {
  },
  mounted() {
    console.log("Pricing")
    
    var auth_token = localStorage.getItem("authtoken");
    if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
      this.auth_token =  auth_token
      this.get_page_activity()
    } else {
      this.auth_token = ""
    }

    // var disesase = localStorage.getItem("selected_disease")
    // if (disesase !== null && disesase !== "undefined" && disesase !== "") {
    //   this.disesase = disesase
    // } else {
    //   this.disesase = ""
    // }

  },
  computed: {
    isTabletMob : function() {
      const iPad = /iPad/i.test(navigator.userAgent);
      const iPhone = /iPhone/i.test(navigator.userAgent);
      const Android = /Android/i.test(navigator.userAgent);
      const webOS = /webOS/i.test(navigator.userAgent);
      const iPod = /iPod/i.test(navigator.userAgent);
      const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
      const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);
      return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
    }
  },
  methods: {
   
    goto_register(my_plan) {
      var value = 0
      if (my_plan == "Free Plan"){
        value = 0
      } else if(my_plan == "First Mover Plan"){
        value = 1
      } else if(my_plan == "My Team Plan"){
        value = 2
      } else if(my_plan == "Basic Plan"){
        value = 4
      }
      var plan = value
      localStorage.setItem("plan", plan);
      this.$router.push("/Register").catch(() => {}) 
    },
    contactUs(){
      let route = this.$router.resolve({path: '/EnterprisePlan'});
      // let route = this.$router.resolve('/link/to/page'); // This also works.
      window.open(route.href, '_blank');
    },
    goBack(){
      this.$router.push("/").catch(() => {})
    },
    get_page_activity(){
    axios
            .get("/fetchIndicationSelected?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status != 1) {
                console.log(res.data,"response.data")
                this.disesase = res.data.Data.Indication
                this.$forceUpdate();          
              } 
          })
    },
    go_to_login() {
        console.log("go to login")
        this.$router.push("/Login");
    },
  }
};
</script>
<style scoped>

  .margin_align {
    overflow-x: hidden;
    overflow-y: hidden;
    
   
  }
  .wholepage {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom: 0px;
    padding-top: 0px;
    margin: 0vh 0vh 0vh 0vh !important;
    overflow-y: auto
  }

  .Pricing-Plans {
    font-family: Ubuntu;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: center !important;
    color: #000;
  }

  .description {
    margin: 0vh 2vh 1vh 2vh !important;
    font-family: Ubuntu;
    font-size: 17px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: center;
    color: #6c6c6c;
  }

  .Rectangle-1152 {
    border: solid 4px #fff;
    background-color: #dceaed !important;
    border-radius: 24px;
    margin-left:-8vw;
    position: fixed !important;
    z-index: 1 !important;
    width:40vw !important
  }
  .Rectangle-115233333 {
    border: solid 4px #fff;
    background-color: #dceaed !important;
    border-radius: 24px;
    margin:0vh 4vh 2vh 4vh;
  }
  .Rectangle-1152_1 {
    margin-top:29vh;
    border: solid 4px #fff;
    background-color: #dceaed;
    border-radius: 24px;
    margin-left:-8vw;
    position: fixed !important;
    z-index: 1 !important;
    width:63vw !important;
  }
  .Rectangle-1152_2 {
    margin-top:58vh;
    border: solid 4px #fff;
    background-color: #dceaed;
    border-radius: 24px;
    margin-left:-8vw;
    position: fixed !important;
    z-index: 1 !important;
    width:40vw !important
  }

  .Rectangle-1151 {
    margin-top:-1.8vh;
    margin-left:-0.5vh;
    margin-bottom:-0.5vh;
    padding: 10px;
    border-radius: 24px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff !important;   
    min-height: 28vh;
    max-height:28vh;
    position: relative;
    width:28vh;
  }
  .Rectangle-11513333 {
    border-radius: 20px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff !important;   
    width:100%;
    height:100%;
    margin-top:-0.5vh;
  }

  .Rectangle-1151_1 {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
  }

  .First-Mover { 
    font-family: Ubuntu;
    font-size: 2.4vh;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: left !important;
    color: #005290;
  }

  .First-Mover333 { 
    font-family: Ubuntu;
    font-size: 3vh;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: left !important;
    color: #005290;
  }

  .Best-Suited-for {
    margin: 0vh 0vh 0vh 0vh;
    font-family: Ubuntu;
    font-size: 1.9vh !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #515b64;
    text-align: justify;
    text-justify: inter-word;
  }

  .price {
    margin: 0 2.1px 0 0;
    font-family: Ubuntu;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }

  .Month {
    margin: 12px 0 7px 2.1px;
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #797979;
  }

  .Annual-subscription {
    margin: 2vh 1vh 2vh 1vh !important;
    font-family: Ubuntu;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center !important;
    color: #005290;
  }

  .price1 {
    margin: 0 2.1px 0 0;
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }

  .discount{
    margin: 2vh 0.5vh 2vh 0.5vh !important;
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center !important;
    color: #343232;
  }

  .Path-748 {
    margin: 2vh 4vh 2vh 4vh;
    padding: 8px 5px 8px 5px !important;
    background-color: #edf6fc;
    text-align: center;
  }
  .description1 {
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #625e5e;
  }
  .enterprises {
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #909090;
  }

  .Rectangle-1153 {
    border-radius:16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vh;
    background-color: #fff;
   
  }

  .Choice-of-2-indications {
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    color: #343232;
  }

  .Line-194 {   
    height: 1.5px;
    margin: 18.8px 25px 15.8px 25px;
    background-color:  #e3eef0;
  }
  .first_sec {
    height:110vh;
    padding: 10px 40px 10px 10px;
    margin-bottom:5vh;
    background-color: #013d48;
    position: relative;
    z-index: 0;
  }
  .Pricing-Plans_title {
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-family: Ubuntu;
    font-size: 3vh;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .Get-access{
    margin-top:5vh;
    font-family: Ubuntu;
    font-size: 2.1vh;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: left;
    color: #d0d0d0;
    margin-right:10vh
  }

  .Free {
    margin: 2vh 0px;
    font-family: Ubuntu;
    font-size: 2vh;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.14;
    letter-spacing: normal;
    text-align: center;
    color: #3e3936;
  }
  .Free3333 {
    margin: 2vh 0px;
    font-family: Ubuntu;
    font-size: 2.2vh;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.14;
    letter-spacing: normal;
    text-align: center;
    color: #3e3936;
  }

  .Get-Trial-Plan {
    font-family: Ubuntu;
    font-size: 2vh;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #f16753 !important;
    border-radius: 5px;
    height: 4vh !important;
    padding:10px;
  }
  .Get-Trial-Plan333 {
    font-family: Ubuntu;
    font-size: 2.2vh;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #f16753 !important;
    border-radius: 5px;
    height: 4vh !important;
    width:fit-content;
    margin-top:1vh;
    padding:10px;
    word-break: break-all;
  }

  .if-you {
    margin: 0px 0px 0px 0px !important;
    font-family: Ubuntu;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .Path-820 {
    margin-top:10px;
    padding: 10px 20px 10px 20px;
    background-color: #03a6ad !important;
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color:#ffffff;
    border-radius: 5px !important;
  }

</style>

