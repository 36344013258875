<template>
<v-main>
    <div>      
        <div>
            <div class="margin_align">
                <div style="font-family: Ubuntu;margin-bottom: -10px;margin-right: -5px;margin-top:100px">
                    <v-row>
                      <v-col
                          cols="12"
                          md="6"
                          lg="6"
                          sm="6"
                          style="text-align:left;cursor: pointer;padding:0"
                          
                        >
                         
                          <span class="My-Modules">Module Settings</span>  
                        
                        </v-col>
                        <v-col cols="12" md="4" lg="4" sm="4" xs="4" ></v-col>
                        <v-col cols="12" md="2" lg="2" sm="2" xs="2"  align="right">
                          <v-btn
                          style=" background-color:#009ea5; color: #ffffff;font-size: 12px;height: 30px;border-radius: 4px;box-shadow: none;border: solid 2px #03a6ad;"
                          @click="unsubscribeModal()" >Unsubscribe Module</v-btn> 
                        </v-col>
                    </v-row>
                    <div style="margin: 10px 30px 10px 30px">
                      <div> 
                       
                        <div  style="margin-top:5px" v-if="users.length==0" >
                              <v-row>
                                  <!-- <v-col align="center" style="margin-top:20vh;font-size:28px;color:#676772">
                                      <div> You have not added any Users.</div>
                                  </v-col> -->
                              </v-row>
                          </div> 
                          <div v-else  style="margin-top:10px;">
                              <v-row >
                                  <v-col  cols="12"  md="3" lg="3" sm="3" xs="3" v-for="(i,ku) in users" :key="ku">
                                      <v-card class="Path-704">
                                          <v-row style="margin-top:0px;">
                                             
                                              <v-col  cols="12"  md="3" lg="3" sm="3" xs="3" style="padding-right:0"  >
                                                <div class="Ellipse-154" style="margin:0">
                                                  <span class="OP">
                                                    {{ i.Initials }}
                                                  </span>
                                                </div>
                                                  
                                              </v-col>
                                              
                                              <v-col cols="12"  md="9" lg="9" sm="9" xs="9"  style="padding-top:26px;padding-left:0" >
                                                  <span style=" color: #676772;font-size: 14px;" >
                                                    {{ i.Email }}
                                                  </span>
                                               
                                              </v-col>
                                             
                                          </v-row>
                                          <div class="Line-194"></div>
                                          <v-row>
                                            <v-col align="left" style="padding-left:16px">
                                            
                                            </v-col>
                                            <v-col align="right">
                                             
                                              <img src="../assets/Icons/Group 975.svg" style="padding-left:20px" @click="removeUser(i.Email)" >
                                            </v-col>
                                          </v-row>
                
                                      </v-card>
                                  </v-col>
                              </v-row>
                            </div>
                      </div>
                          <v-dialog v-model="showUserModal" width="700">
                            <v-card style="padding:3vh 2vh 3vh 2vh;">
                              <v-card-text>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                                    <span class="Select-Diseases">Add User</span>
                                  </v-col>
                                  <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                                  <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                                    <!-- <a @click="close_diseases_modal()"><i class="fa fa-window-close" aria-hidden="true" style="color: #ff6750;margin-left: 8vh !important;"></i></a> -->
                                    <v-icon color="#ff6750" style="cursor:pointer;margin-left: 8vh "
                                      @click="close_modal()">mdi-close</v-icon>
                                  </v-col>
                                </v-row>
                                <v-row style="padding-left:5px" >
                                  <v-text-field label="Users List:" v-model="usersList"></v-text-field>
                                </v-row>
                              </v-card-text>
                              <v-card-actions>
                                <v-row style="padding-top:0">
                                  <v-col sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                                  <v-col sm="4" md="4" lg="4" xs="4" xl="4">
                                    <!-- <a style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 16px;font-family: Ubuntu;"
                                      @click="oth_dise_flag = true">
                                      Other Diseases
                                    </a> -->
                                  </v-col>
                                  <v-col sm="4" md="4" lg="4" xs="4" xl="4">
                                    <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 17px;" >
                                      Submit
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                           <v-dialog v-model="unsubscibe" width="700">
            <v-card style="padding:3vh 2vh 3vh 2vh;">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="10" md="10" lg="10" xs="10" xl="10" align="center" >
                    <span style="padding-left:12vh;font-family: Ubuntu;
                    font-size: 18px !important;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.24;
                    letter-spacing: normal;
                    text-align: left;
                    color: #ff6750 !important;" >Unsubscribe!</span>
                  </v-col>
                
                  <v-col cols="12" sm="1" md="1" lg="1" xs="1" xl="1">
                    <!-- <a @click="close_diseases_modal()"><i class="fa fa-window-close" aria-hidden="true" style="color: #ff6750;margin-left: 8vh !important;"></i></a> -->
                    <v-icon color="#ff6750" style="cursor:pointer;margin-left: 8vh "
                      @click="unsubscibe=false">mdi-close</v-icon>
                  </v-col>
                </v-row>
                <v-row style="padding-left:5px">
                  <v-col cols="12" align="center">
                    <span> Do you really want to unsubscibe this module?</span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row style="padding-top:0">
                 
                  <v-col cols="12" align="center">
                    <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 17px;width:120px" @click="unsubscibeModule()" >
                      Yes
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="unsubscibe" width="700">
            <v-card style="padding:3vh 2vh 3vh 2vh;">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="10" md="10" lg="10" xs="10" xl="10" align="center" >
                    <span style="padding-left:12vh;font-family: Ubuntu;
                    font-size: 18px !important;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.24;
                    letter-spacing: normal;
                    text-align: left;
                    color: #ff6750 !important;" >Unsubscribe!</span>
                  </v-col>
                
                  <v-col cols="12" sm="1" md="1" lg="1" xs="1" xl="1">
                    <!-- <a @click="close_diseases_modal()"><i class="fa fa-window-close" aria-hidden="true" style="color: #ff6750;margin-left: 8vh !important;"></i></a> -->
                    <v-icon color="#ff6750" style="cursor:pointer;margin-left: 8vh "
                      @click="unsubscibe=false">mdi-close</v-icon>
                  </v-col>
                </v-row>
                <v-row style="padding-left:5px">
                  <v-col cols="12" align="center">
                    <span> Do you really want to unsubscibe this module?</span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row style="padding-top:0">
                 
                  <v-col cols="12" align="center">
                    <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 17px;width:120px" @click="unsubscibeModule()" >
                      Yes
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
                    </div>
                </div>
            </div>
        </div>
    </div>
</v-main>
</template>
    <script>
    import axios from 'axios';
    import App from '../App.vue';
    
    export default {
      components: { App },
        
      data() {
        return {
        
        module_id:"",  
        usersList:"",
        users:[],
        user:{},
        showUserModal:false,
        unsubscibe:false,
        
       
       
        
           
        };
      },
      computed: {
      },
      created() {
      },
      mounted() {
        var auth_token = localStorage.getItem("authtoken");
        if (auth_token !== null && auth_token !== "" && auth_token !== undefined && auth_token !== "undefined") {
          this.auth_token = auth_token
          console.log("hellllooooo")
          if (this.$route.query.moduleId!=undefined){
            console.log("jiiii",this.$route.query.moduleId)
            this.module_id =this.$route.query.moduleId
            console.log(this.module_id)
           
            // this.getGeKnowMeSettingData()
        
          }
        }else{
            this.$router.push("/")
          }
       
      },
      methods: {
        getGeKnowMeSettingData(){
          this.users=[]
          console.log("hellll")
          axios
          .get("/getGeKnowMeSettingData?module_id="+this.module_id+"&token="+this.auth_token)
          .then((res) => {
            if (res.data.Status !== 1) {
              console.log(res.data.Data,"ddtata")
              if(res.data.Data.UsersData!=null){
                for(let i=0;i<res.data.Data.UsersData.length;i++){
                  this.user={}
                  this.user.Email=res.data.Data.UsersData[i]
                  this.user.Initials=this.initials(res.data.Data.UsersData[i])
                  this.users.push(this.user)
                }
              }else{
                this.users=[]
              }
    
              console.log(this.users,"Users ")
             
            }
            })
          .catch((error) => {
              console.log(error.message);
              if (error.message == "Request failed with status code 500") {
                this.logout()             
              }
          });
        
          
    
        },
        goBack(){
          this.$router.push("/")
        },
       
        addUsers(){
           axios
            .post("/addGeknowMeUsers?users="+this.usersList+"&module_id="+this.module_id+"&token="+this.auth_token)
            .then((res) => {
              this.usersList=""
              if (res.data.Status != 1) { 
                this.getGeKnowMeSettingData()
            } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
              this.logout()
              //this.$router.push("/").catch(() => {})
            } 
    
             
          })
          .catch((err) => {
            console.log(err);
          });
    
        },
        removeUser(email){
          console.log("removeeee")
          axios
            .post("/removeGeKnowMeUser?email="+email+"&module_id="+this.module_id+"&token="+this.auth_token)
            .then((res) => {
             
              if (res.data.Status != 1) { 
                this.getGeKnowMeSettingData()
            } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
              this.logout()
              //this.$router.push("/").catch(() => {})
            } 
    
             
          })
          .catch((err) => {
            console.log(err);
          });
    
        },
        addUserModal(){
      this.showUserModal=true
    },
    close_modal(){
      this.showUserModal=false
    },
    initials(email){
            let name = email.substring(0, email.lastIndexOf("@"));
            if(name.includes(".")){
                var names = email.split(".")
                if(names.length > 0){
                    var initials_l = (names[0].substring(0,1) + names[1].substring(0,1)).toUpperCase()
                    return initials_l
                }
            }else{
                var names = email.split(' ')
                var initials_l = names[0].substring(0, 2).toUpperCase()
                return initials_l
            }
    },
    goBack(){
     
      // var old_selected = localStorage.getItem("selected_article");
      //   if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //     localStorage.setItem("old_selected_article", old_selected);
      //   } else {
      //     old_selected = "Industry Updates"
      //     localStorage.setItem("old_selected_article", old_selected);
      //   }
      // localStorage.setItem("selected_article", "My_Modules");
      this.$router.push("/")
    },
    unsubscribeModal(){
      this.unsubscibe=true
    },
    unsubscibeModule(){
      axios
        .post("/cancelModuleSubscription?module_id="+this.module_id+"&token="+this.auth_token)
        .then((res) => {
          if (res.data.Status != 1) { 
            this.users=[]
            //go to module library
            this.$router.push("/")
        } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
          this.logout()
          //this.$router.push("/").catch(() => {})
        } 
      })
      .catch((err) => {
        console.log(err);
      });
          
      }
    },
    };
    </script>
    <style scoped>

    .margin_align {
      padding: 0 30px 0 30px;
      overflow-x: hidden;
      overflow-y: auto;
      height: 100vh;
    }
    .Line-194 {
      border-top:1.5px dotted #ffff;
      height: 0.5px;
      margin: 24.8px 3.5px 4px 0;
      background-color: #03a6ad;
    }
    .Path-704 {
    padding:15px;
    
    box-shadow: 0 4px 9px 0 rgba(36, 36, 36, 0.16);
    border: solid 1px #d9d7d7;
    background-color: #fff;
    border-radius: 12px;
    }
    .Ellipse-154 {
      width: 47px;
      height: 47px;
     
      padding: 12.6px 0;
      border: solid 1px #676772;
      background-color: #fff;
      text-align: center;
      border-radius: 50%;
    }
    .OP {
      font-family: Ubuntu;
      font-size: 20px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: center;
      color: #676772;
    }
    .My-Modules {
      font-family: Ubuntu;
      font-size: 22px;

      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #2c2c48;
      margin-left:3vh;
    
      
  }
       
    </style>