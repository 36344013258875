import Vue from 'vue'
import Router from 'vue-router'
import Login from '../views/Login_Page.vue'
import Register from '../views/Register.vue'
import ArticleList from '../views/ArticleList.vue'
//import Select_Diseases from '../views/Select_Diseases.vue'
//import Landing from '../views/Landing.vue'
//import Disease from '../views/Home.vue'
import Payment from '../views/Payment.vue'
import ArticleDetails from '../views/ArticleDetails.vue'
import Share from '../views/Share.vue'
import Testing from '../views/Testing.vue'
import Profile_Settings from '../views/Profile_Settings.vue'
import ArticleListLatest from '../views/ArticleListLatest.vue'
import MyModules from '../views/MyModules.vue'
import ModulesLibrary from '../views/ModulesLibrary.vue'
import MechanismModule from '../views/MechanismModule.vue'
import GeKnowMeModule from '../views/GeKnowMeModule.vue'
import GeKnowMeSettings from '../views/GeKnowMeSettings.vue'
import Sample from '../views/SampleModule.vue'
import Mechanism_Module from '../views/GetMechanismModule.vue'
import Module_Payment from '../views/Module_Payment.vue'
import Module_Transaction_Success from '../views/Module_transaction_success.vue'
import Module_Transaction_Fail from '../views/Module_transaction_fail.vue'

import ShareWebArticle from '../views/ShareWebArticle.vue'
import Notes from '../views/Notes.vue'
import Transaction_success from '../views/Transaction_success.vue'
import Transaction_failed from '../views/Transaction_failed.vue'
import Userwishlist from '../views/Userwishlist.vue'
import Usercart from '../views/Usercart.vue'
import Subscriptions from '../views/Subscriptions.vue'
import Pricing from '../views/Pricing.vue'
import User_pricing from '../views/User_pricing.vue'

import CustomModule from '../views/CustomModule.vue'


import Register_plan_payment from '../views/Register_plan_payment.vue'
import My_Team_plan_users from '../views/My_team_plan_users.vue'
import User_plan_payment from '../views/User_plan_payment.vue'


import Settings from '../components/Settings.vue'

import router from 'vue-router';
import Support from '../views/Support.vue'
import Enterprise_Plan from '../views/Enterprise_plan.vue'
import Unsubscribe_Mail from '../views/Unsubscribe_mail.vue'
import Module_Purchase from '../views/Module_purchase.vue'
import Analytical_Chat from '../views/Analytical_Chat.vue'
import Research_Chat from '../views/Research_Chat.vue'
import Analytical_Chat_Tab from '../views/Analytical_Chats_Tablet.vue'

import Flutter_app from '../views/flutter_app.vue'

Vue.use(Router)


export default new Router({


    routes: [{
            path: '/articleList',
            name: 'articleList',
            component: ArticleList,
        },
        {
            path: '/Login',
            name: 'Login',
            component: Login,
        },
        {
            path: '/Register',
            name: 'Register',
            component: Register,
        },
        {
            path: '/Payment',
            name: 'Payment',
            component: Payment,
        },
        {
            path: '/articleDetails',
            name: 'articleDetails',
            component: ArticleDetails,
        },
        {
            path: '/share/:id/',
            name: 'share',
            component: Share,
        },
        {
            path: '/sharewebarticle/:id/:panel/:input_id',
            name: 'sharewebarticle',
            component: ShareWebArticle,
        },
        {
            path: '/Profile_Settings/',
            name: 'Profile_Settings',
            component: Profile_Settings,
        },
        {
            path: '/Testing',
            name: 'Testing',
            component: Testing,
        },
        {
            path: '/articleListLatest',
            name: 'articleListLatest',
            component: ArticleListLatest,
        },
        {
            path: '/notes/:disease',
            name: 'notes',
            component: Notes,
        },
        {
            path: '/Transaction_success',
            name: 'Transaction_success',
            component: Transaction_success,
        },
        {
            path: '/Transaction_failed',
            name: 'Transaction_failed',
            component: Transaction_failed,
        },
        {
            path: '/Userwishlist',
            name: 'Userwishlist',
            component: Userwishlist,
        },
        {
            path: '/Usercart',
            name: 'Usercart',
            component: Usercart,
        },
        {
            path: '/Subscriptions',
            name: 'Subscriptions',
            component: Subscriptions,
        },
        {
            path: '/Pricing',
            name: 'Pricing',
            component: Pricing,
        },
        {
            path: '/User_pricing',
            name: 'User_pricing',
            component: User_pricing,
        },
        //My modules page
        {
            path: '/MyModules',
            name: 'My_Modules',
            component: MyModules,
        },
        //modules Library page
        {
            path: '/',
            name: 'Modules_Library',
            component: ModulesLibrary,
        },

        // Mechanism Module page
        {
            path: '/MechanismModule',
            name: 'Mechanism_Module',
            component: MechanismModule,
        },
        //Custom Module page
        {
            path: '/CustomModule',
            name: 'Custom_Module',
            component: CustomModule,
        },
        // GeKnowMe Module page
        {
            path: '/overviewbyIndication/:id/:name',
            name: 'Geknowme_Module',
            component: GeKnowMeModule,
        },
        // GeKnowMe Module  settings page
        {
            path: '/GeKnowMeSettings',
            name: 'Geknowme_Settings',
            component: GeKnowMeSettings,
        },
        // Show sample module
        {
            path: '/Sample',
            name: 'Sample',
            component: Sample,
        },
        // Show mechanism module
        {
            path: '/MechModule',
            name: 'Mech_Module',
            component: Mechanism_Module,
        },
        //Module Payment page
        {
            path: '/ModulePayment',
            name: 'Module_Payment',
            component: Module_Payment,
        },
        {
            path: '/ModuleTransactionSuccess',
            name: 'Module_Transaction_Success',
            component: Module_Transaction_Success,
        },
        {
            path: '/ModuleTransactionFail',
            name: 'Module_Transaction_Fail',
            component: Module_Transaction_Fail,
        },
        //knolensChat related Api

        {
            path: '/plan_payment',
            name: 'Plan_payment',
            component: Register_plan_payment,
        },
        {
            path: '/plan_users',
            name: 'My_team_plan_users',
            component: My_Team_plan_users,
        },
        {
            path: '/User_plan_payment/:plan',
            name: 'User_plan_payment',
            component: User_plan_payment,
        },
        {
            path: '/Support',
            name: 'Support',
            component: Support,
        },
        {
            path: '/EnterprisePlan',
            name: 'Enterprise_Plan',
            component: Enterprise_Plan,
        },
        {
            path: '/AnalyticalChat/:id',
            name: 'Analytical_Chat',
            component: Analytical_Chat,
        },
        {
            path: '/ResearchChat',
            name: 'Research_Chat',
            component: Research_Chat,
        },
        {
            path: '/ModulePurchase',
            name: 'Module_Purchase',
            component: Module_Purchase,
        },
        {
            path: '/Unsubscribe_mail',
            name: 'Unsubscribe_mail',
            component: Unsubscribe_Mail,
        },
        {
            path: '/Analytical_chat_tab',
            name: 'Analytical_chat_tab',
            component: Analytical_Chat_Tab,
        },

        {
            path: '/flutter_app',
            name: 'Flutter_app',
            component: Flutter_app,
        },
        {
            path: '*',
            redirect: '/',
        },












        // {
        //   path: '/Disease',
        //   name: 'Disease',
        //   component: Disease,
        // },
        // {
        //   path: '/Select_Diseases',
        //   name: 'Select_Diseases',
        //   component: Select_Diseases,
        // },



    ]
})