import { render, staticRenderFns } from "./Subscriptions.vue?vue&type=template&id=1dcd8533&scoped=true&"
import script from "./Subscriptions.vue?vue&type=script&lang=js&"
export * from "./Subscriptions.vue?vue&type=script&lang=js&"
import style0 from "./Subscriptions.vue?vue&type=style&index=0&id=1dcd8533&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dcd8533",
  null
  
)

export default component.exports