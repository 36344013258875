<template>
    <v-main>
        <div class="_Knolens_Payment-Successful_Op1"> 
            <v-row>
                <v-col align="center" style="font-size:28px;color:#676772;margin-top:13vh;">
                    <v-card class="Path-704" style="padding:30px">
                        <v-row>
                            <v-col align="center" style="font-size:28px;color:#676772">
                                <img src="../assets/Icons/Group 951.svg"
                                class="Group-951">
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col align="center" style="font-size: 34px;
                            font-weight: 500;">
                                <span>
                                    {{ "Payment Details" }}
                                </span>
                            </v-col>
                           
                        </v-row>
                       
                        <v-row>
                            <v-col align="left" >
                                <span style="font-size:16px;">
                                    {{ "Purchase Details" }}
                                </span>
                            </v-col>
                        </v-row>
                        <div class="Path-705">
                            <v-row>
                                <v-col align="left" cols=12 md="8" sm="8" xs="8" lg="8" style="padding:0 0 0 16px">
                                    <span style="font-size:16px;color: #2c2c48;font-weight: 500;text-align:left;">
                                        {{ Module.Name }}
                                    </span>  
                                </v-col>
                                <v-col align="right" cols=12 md="4" sm="4" xs="4" lg="4" style="padding:0 16px 0 0">
                                    <span class="-for-1-Year" >
                                        <span class="text-style-1">{{ Module.Price }} $</span>
                                        <span class="text-style-2"></span>
                                        for 1 Year
                                    </span>
                                </v-col>
                            </v-row>
                            
                           
                        </div>
                        <div class="Rectangle-1204">
                            <span style="font-size:16px;color: #2c2c48;font-weight: 500;text-align:left;">
                                {{ "Total" }}
                            </span>  
                            <span  style="padding-left:360px">
                                <span style="font-size:18px">{{ Module.Price +"$"}} </span>
                            </span>
                               
                        </div>
                        <v-row>
                            <v-col >
                            </v-col>
                        </v-row>
                        <v-row >
                            <v-col align="center">
                                <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 16px;  width: 157.1px;" @click="makeStripePaymentModules()">Continue</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>    
            </v-row>     
            
        </div>
    </v-main>
</template>


<script>

import axios from "axios";
export default {
  

  data() {
    return {
      Module:{},
      module_id:"",
      auth_token: "",
      priceId:"",
      price:"",
      stripe_key:""
    };
  },

  
  computed: {
  },
  created() {
   
  },
  mounted() {
    var auth_token = localStorage.getItem("authtoken");
    if (auth_token !== null || auth_token !== "undefined" || auth_token !== "") {
      this.auth_token = auth_token
      // var path=localStorage.getItem("selected_article")
      if(path=="Transaction Success"){
        console.log("path",path)
        this.$router.push("/")
      }

      this.getuser()
      this.getPaymentReleatedKey()
      this.module_id=this.$route.query.module_id
      this.priceId=this.$route.query.priceId
      this.price=this.$route.query.price
      this.getModulePurchaseData()
    } else {
      console.log("no authentication")
      this.auth_token = ""
    }
    
   
    
    
      
  },
  methods: {
    logOut() {
      localStorage.removeItem("authtoken");
      localStorage.removeItem("selectedColorList");
      // localStorage.setItem("selected_article", "Industry Updates");
      this.disesase = "Multiple sclerosis"
      this.title = "Multiple sclerosis"
      delete axios.defaults.headers.common["Authorization"];
      this.auth_token = null;
      
      this.$router.push("/").catch(() => {})           
    },
    getuser() {
      axios
        .get("/user?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status != 1) {
            this.user_email = res.data.Data.Email
          } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            this.logOut()
          } else {
            console.log("User not found")
          }
      })
      .catch((error) => {
        console.log(error.message);
        if (error.message == "Request failed with status code 500") {
          this.logOut()             
        }
      });
    },
    getPaymentReleatedKey(){
      axios.post("/getPaymentReleatedKey?token="+this.auth_token)
      .then(response => {
        this.stripe_key=response.data.Data
      })
      .catch(error => {
        console.log(error);
      });
   
    },
    makeStripePaymentModules() {
      this.loading = true
      var qty = 1
      console.log(axios.defaults.baseURL,"payment url....")
      var stripe=stripe(this.stripe_key)
      axios.post("/module_payment_checkout?priceId="+ this.priceId + "&quantity=" + qty + "&email=" + this.user_email + "&token=" + this.auth_token+"&module_id="+this.module_id)
        .then(res => {
        console.log(res.data.Data,"res.data.Data")
        if (res.data.Status !== 1) {
          const id = res.data.Data.Id;
          stripe.redirectToCheckout({sessionId: id});
          this.loading = false
        } else {
          this.loading = false
          this.err_flag = true
          console.log("error in payment redirecting")
        }
      })
    },
    getModulePurchaseData(){
        axios.post("/getModulePurchaseData?module_id="+ this.module_id+"&token="+this.auth_token)
        .then(response => {
        console.log(response.data.Data.Module)
        this.Module=response.data.Data.Module
        console.log(this.Module)
        
       
      })
      .catch(error => {
        console.log(error);
      });
           
       
    }
    
     
  }
};
</script>
<style scoped>

._Knolens_Payment-Successful_Op1 {
  font-family: Ubuntu;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
    background-color: #f5fffb;
  }
  .Path-704 {
  width: 550px;
  height: 570px;
  padding:10px;
  box-shadow: 0 4px 9px 0 rgba(36, 36, 36, 0.16);
  border: solid 1px #707070;
  background-color: #fff;
  border-radius:31px;

}
img.Group-951 {
    width: 113.9px;
    height: 110.3px;
    
    object-fit: contain;
  }
  .Line-194 {
    border-top:2px dashed #ffff;
    height: 1px;
    margin: 25.8px 3.5px 3.8px 0;
    background-color: #707070;
  }
  .Rectangle-1215 {
    width: 500px;
    height: 49px;
    margin: 11.6px 0.2px 17.3px 0;
    padding: 13px 184px 9px 15px;
    border: solid 1px #707070;
    background-color: #fff;
    border-radius: 0%;
  }
  .Path-705 {
    margin:0 10px 0 10px;
    height: 55px;
    margin: 11.6px 0.2px 17.3px 0;
    padding:0 4px 0 4px;
    border: solid 1px #989898;
    border-radius: 15px;
    background-color: #fff;
    
  }
  .-for-1-Year {
   
   
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.43;
    letter-spacing: normal;
    text-align: right;
    color: #676772;
  }
  .-for-1-Year .text-style-1 {
    font-family: Ubuntu;
    font-size: 18px;
    font-weight: 500;
    color: #2c2c48;
  }
  .-for-1-Year .text-style-2 {
    font-family: Ubuntu;
    font-weight: 500;
    color: #2c2c48;
  }
  .Rectangle-1204 {
   
    height: 55px;
    
    border-radius: 15px;
    border: solid 1px #03a6ad;
    background-color: #f7faff;
  }
</style>