<template>
    <div style="maxheight:65vh;margin-bottom:10px;">
      <v-row>
        <span class="Top-Performing-Modules">
           Processing Modules
        </span>
      </v-row>
      <loading :active.sync="loading" 
      :can-cancel="true" 
      :on-cancel="onCancel"
      :is-full-page="fullPage"></loading>
      <v-row style="padding: 5px 30px 30px 45px;margin-top:0px" v-if="allModules.length>0">
        <v-col cols="12" md="3" lg="3" sm="3" xs="3" v-for="(i,ka) in allModules" :key="ka">
          <v-card class="" style="padding: 20px; border-radius: 12px ;border: none'">
            <div align="center">
              <img :src="require(`@/assets/images/${i.Image}`)" basic style="height: 150px; width: 250px" />
            </div>
            <div style="margin-top: 12px">
              <span class="Industry-Updates">
                {{ i.Name }}
              </span>
            </div>
            <div>
              <span class="-ACTIVE">
                {{ "0 ACTIVE "}}
              </span>
              <div class="vr" ></div>
              <span class="-ALERTS">
                {{ "0 ALERTS"}}
              </span>
              <div class="vr"></div>
              <span class="-Processing">
                {{ "Processing…" }}
              </span>
            </div>
            <span class="Lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-sed-do-eiusmod-tempor-incididunt-ut-labore">
              {{ i.Description }}
            </span>
            <div class="Line-194"></div>
            <v-row >
              <v-col cols="12" align="left" style="padding-top:0;padding-bottom:20px;">
                <v-row>
                  <span class="Subscription-valid-till">
                    Subscription vaild till
                  </span>
                </v-row>      
                 <v-row>             
                  <span class="th-Jan-23">
                    20th Jan 2
                  </span>
                </v-row>   
              </v-col>
              <!-- <v-col  cols="12" md="6" sm="6" lg="6" xs="6" align="right">
                <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 10px;; width: 12px" >Visit</v-btn>
              </v-col> -->
            </v-row>
          </v-card>
        </v-col>
      </v-row>   
      <div v-else-if="allModules.length==0 && !loading">
        <v-row>
          <v-col align="center" style="margin-top:30vh;font-size:28px;color:#676772">
            <span >
              {{ "You have not any Processing Modules."}}
            </span>
            
          </v-col>
        </v-row>
      </div>
    </div>
  </v-main>
</template>

<script>
import axios from 'axios';
import App from "../App.vue";
import ProcessingModule from "@/components/ProcessingModule.vue"
import MyModules from "@/components/My_Modules.vue"
import Loading from 'vue-loading-overlay';
export default {
  components: { App,ProcessingModule,MyModules,Loading},

  data() {
    return {
      auth_token: "",
      allModules:[],
      activeTab:'1',
      selectedTab:false,
      loading: true,
      fullPage: true,
    };
  },
  computed: {},
  created() {},
  mounted() {
    var auth_token = localStorage.getItem("authtoken");
    if (auth_token !== null && auth_token !== "" && auth_token !== undefined && auth_token !== "undefined") {
      this.auth_token = auth_token
      this.GetModules()
    }else{
      this.loading=false
    }
  
   
  },
  methods: {
    GetModules(){
      // let activetab='3'
      //   this.$router.push("/mechanismModule?activeTab="+activetab)
      axios
      .get("/getprocessingmodules?token="+this.auth_token)
      .then((res) => {
          this.loading = false;
          //console.log(res.data.Data,"ddtata")
          if (res.data.Data.allModules!=null || res.data.Data.allModules!=undefined) {
            for(let i=0;i<res.data.Data.allModules.length;i++){
            this.allModules.push(res.data.Data.allModules[i])
            }
          }else{
            this.allModules=[]
          }
          
        //  console.log(this.allModules,"allModules")
        
        })
      .catch((error) => {         
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);         
          }
      });
    //  console.log(this.allModules,"allModules")
    },
   
    getFlagImage(flag) {
      return new URL("../assests/images/" + flag + ".png");
    },
    goToMyModules(){
      this.allModules=true
     

    },
    onCancel() {
      console.log('User cancelled the loader.')
    },
      changeView(tab){
       // console.log(this.activeTab)
        this.activeTab=tab
       // console.log(this.activeTab)
        this.selectedTab=true
    }
  },
};
</script>
<style scoped>
.Component-6-5 {
  width: 328.5px;
  height: 41.4px;
  margin: 14.3px 0 0 7.9px;
}
.Component-7-1 {
  width: 324.4px;
  height: 33px;
  margin: 11.1px 4.1px 0 0;
}
.Subscription-valid-till {

  height: 14.8px;
 
  font-family: Ubuntu;
  font-size: 9px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.44;
  letter-spacing: normal;
  text-align: left;
  color: #676772;
}
.TOP-3-Productive-Module-for-Healthcare-Industry {
  
  margin: 28.4px 147px 25.8px 0;
  font-family: Ubuntu;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.Image-175 {
  width: 333px;
  height: 190px;
  margin: 0 3.4px 50.2px 0;
}
.Top-Performing-Modules {
  margin: 10.9px 13.4px 20.8px 40px;
  font-family: Ubuntu;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}
.Rectangle-1043 {
  margin-bottom:30px;
  padding: 15.6px 0 40.6px 80px;
  opacity: 0.94;
  background-color: #595d6f;
}
.th-Jan-23 {
  font-family: Ubuntu;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: left;
  color: #676772;
}
.Path-704 {
 
  padding:20px;
  box-shadow: 0 4px 9px 0 rgba(36, 36, 36, 0.16);
  border: solid 1px #d5d5d5;
  border-radius: 8px;
  background-color: #fff;
}
.Path-714 {
  height: 45px;
  margin: 43.2px 296.4px 50.9px 13.4px;
  border: solid 1px #989898;
  text-align: center;
  background-color: #fff;
}
.Drugs-in-Development {
  margin: 173.3px 10px 0px 2px;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}
.Category-Name {
  padding-bottom: 20px;
  margin: 0 4px 10.3px 3px;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.71;
  letter-spacing: normal;
  text-align: left;
  color: #647272;
}
.Rectangle-1044 {
  
  padding: 49.5px 1459.3px 250.6px 121px;
  opacity: 0.94;
  background-color: #333;
}
.Processing-Modules {
  padding: 12px;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #989898;
}
.All-Modules {
  padding-left: 14px;
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
img.Group-736 {
  
  
  object-fit: contain;
}
.My-Modules {
  height: 24.8px;
  margin: 55.3px 289.7px 59px 13.4px;
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #989898;
}
.Path-705 {
  margin:0 10px 0 10px;
  text-align: center;
  height: 45px;
  padding: 12.1px 3.6px 8.1px 0;
  border: solid 1px #989898;
  border-radius: 24px;
  background-color: #fff;
}
.Path-705:hover{
  color:#fa6f5b
}
.Path-705:active{
  color:#fa6f5b
}
.Path-713 {
  height: 45px;
  margin-left: 20px;
  padding: 12.1px 3.6px 20.1px 0;
  border-radius: 24px;
  text-align: center;
  background-color: #fa6f5b;
}
.Sort-by-Relevance {
 
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #f16753;
}
.Lorem {
  width: 811px;
  height: 78.9px;
  margin: 25.8px 57px 27.4px 0;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.margin_align {
  overflow-x: hidden;
  overflow-y: auto;
  
}

.My-Modules-1 {
  font-family: Ubuntu;
  font-size: 20px;
  padding-left: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
  margin-left: 3vh;
}
.Image-120 {
  width: 220px;
  height: 180.3px;
}
.Industry-Updates {
  width: 320.4px;
  height: 31.9px;
  font-family: Ubuntu;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}
.-ACTIVE {
  height: 16.8px;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.71;
  letter-spacing: normal;
  text-align: left;
  color: #03a6ad;
}
.-ALERTS {
  height: 15px;

  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.71;
  letter-spacing: normal;
  text-align: left;
  color: #f66b67;
}
.-Processing {
  height: 12px;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.71;
  letter-spacing: normal;
  text-align: left;
  color: #868686;
}

.Lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-sed-do-eiusmod-tempor-incididunt-ut-labore {
  width: 340.4px;
  height: 122.9px;
  margin: 5.8px 3.6px 13.8px 0;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #7a7a83;
}
.Line-194 {
  height: 1px;
  margin: 15.8px 3.5px 13.8px 0;
  background-color: #03a6ad;
}
.Line-202 {
  height: 1px;
  margin: 314.2px 12.5px 16px 16.5px;
  background-color: #fff;
}
.Visit {
  width: 73.3px;
  height: 14.8px;
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.Path-703 {
  width: 74.1px;
  height: 33px;
  margin: 0 0 0 217.6px;
  padding: 8.6px 0 9.6px 0.8px;
  background-color: #03a6ad;
}
.Component-5-6 {
  width: 317.4px;
  height: 33px;
  margin: 13.8px 11.6px 4.4px 3px;
}
.Group-848 {
  width: 377px;
  height: 529.6px;
}
.Ellipse-151 {
  width: 22px;
  height: 22px;
  border: solid 1px #1abbbc;
  border-radius: 24px;
  background-color: #f5f5f5;
}
.Group-846 {
  width: 22px;
  height: 22px;
  margin: 0 0 6.4px 27px;
  padding: 5px 5.4px 5.4px 5px;
}
.Path-715 {
  width: 25.7px;
  height: 26.3px;
}
.Component-5-3 {
  width: 317.4px;
  height: 33px;
  margin: 13.8px 11.6px 4.4px 3px;
}
.Span-1 {
  margin: 5px 5px 2.9px 14px;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
}
.For-30-Days {
  margin: 0 15.8px 0 14px;
  font-family: Ubuntu;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.44;
  letter-spacing: normal;
  text-align: left;
  color: #676772;
}
.View-Details {
  margin: 0.9px 0.3px 8px 0.5px;
  font-family: Ubuntu;
  font-size: 8px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: left;
  color: #03a6ad;
}
footer {
  width: 339.8px;
  height: 44.9px;
  font-family: Ubuntu;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.Path-235 {

  height: 140.8px;
  margin: 0 0 0px;
  padding: 15.9px 0 43.8px;
  background-color: #013d48;
}
.Line-194 {
    border-top:2px dashed #ffff;
    height: 1px;
    margin: 15.8px 3.5px 13.8px 0;
    background-color: #03a6ad;
  }
  .vr{
    margin: 0 5px 0 5px;
  }
</style>