<template>
    <div class="margin_align" :style="isTabletMob?'overflow-y: hidden;height: 100%;position:fixed;':'height:120vh'">
  <div>
    <v-row v-if="!formFilled" style="margin-top:11vh"  >
      <v-col v-if="!isTabletMob" col="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
       <v-col v-if="!isTabletMob" col="12" sm="6" md="6" lg="6" xs="6" xl="6">
          <v-card class="Rectangle-1151" style="overflow-y:hidden" >
            <v-row>
              <v-col>
                <div class="banner-container">
                  <img src="@/assets/images/banner.jpg" alt="Newsletter Banner" class="banner-image">
                  <div class="banner-text">
                      <h1 style="margin: 0;" class="mycart">Talk to Us</h1>
                  </div>
              </div>
                
              </v-col>
            </v-row>
            <v-row style="margin-top:0" >
              <v-col>
                <span style="font-family: Ubuntu;
                font-size: 15px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                color:#676772
              
                " >
                Let us know more about your requirement, our sales team will be in touch with customized offers
              </span>
              </v-col>
            </v-row>
            <v-row class="row_style">
              <v-col>
              <v-form id="form">
                <!-- create name field with label name and textbox with validation -->
                <label for="name">Name<span style="color:red">*</span></label>
                <v-text-field
                v-model="user_data.Name"
                  required
                  outlined
                  placeholder="Enter your name"
                  :rules="[
                    v => !!v || 'Name is required',
                    v => /^[a-zA-Z ]+$/.test(v) || 'Name must contain only alphabetic characters.',
                  ]"
                ></v-text-field>
                <label for="name">Company Name<span style="color:red">*</span></label>
                <v-text-field
                v-model="user_data.Data.Company_Name"
                  required
                  outlined
                  placeholder="Enter your company name"
                  :rules="[
                    v => !!v || 'Compay is required',
                  ]"
                ></v-text-field>
                <!-- create name field with label email address and textbox with validation -->
                <label for="email">Email Address<span style="color:red">*</span></label>
                <v-text-field
                v-model="user_data.Data.Email"
                  required
                  outlined
                  placeholder="Enter your email address"
                  :rules="[
                    v => !!v || 'Email Address is required',
                    v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
                  ]"
                ></v-text-field>
                <label for="email">Interested in<span style="color:red">*</span></label>
                <v-text-field
                v-model="module_name"
                  required
                  outlined
                      disabled
                ></v-text-field>
                <!-- create Number of users field with label Number of Users and textbox with validation -->
                <label for="number_of_users">Number of Users<span style="color:red">*</span></label>
                <v-text-field
                v-model="user_data.Data.Number_of_Users"
                  required
                  outlined
                  placeholder="Enter number of users"
                  :rules="[
                    v => !v || /^\d+$/.test(v) || 'Please enter a valid number'
                  ]"
                ></v-text-field>
                
                <!-- create name field with label description and textbox with validation -->
                <label for="description">Talk to us more about your needs</label>
                <v-textarea
                v-model="user_data.Data.Description"
                  required
                  outlined
                  placeholder="Enter your description"
                  
                ></v-textarea>
                <!-- create submit buttom at center -->
                <v-row>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4">
                    <v-btn :class="{ disabled: isDisabled }" :disabled="isDisabled" block color="#009ea5"    
                    style="margin-top:2vh;background-color: #009ea5; color: #ffffff;font-size: 2.1vh;letter-spacing: 0.2px;" @click="getData(user_data)">
                   Submit
                  </v-btn>
                  </v-col>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                </v-row>
              </v-form>
            </v-col>
            </v-row>
          </v-card>
  
       </v-col>
       <v-col v-if="isTabletMob" col="12"  style="padding:3vh 3vh 0vh 3vh" >
          <v-card class="Rectangle-1151" style="overflow-y:hidden;padding:3vh ;" >
            <v-row>
              <v-col>
                <div class="banner-container">
                  <img src="@/assets/images/banner.jpg" alt="Newsletter Banner" class="banner-image">
                  <div class="banner-text">
                      <h1 style="margin: 0;" class="mycart">Talk to Us</h1>
                  </div>
              </div>
                
              </v-col>
            </v-row>
            <v-row style="margin-top:0" >
              <v-col>
                <span style="font-family: Ubuntu;
                font-size: 15px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                color:#676772
              
                " >
                Let us know more about your requirement, our sales team will be in touch with customized offers
              </span>
              </v-col>
            </v-row>
            <v-row  style="  height:50vh;
            overflow-y:scroll !important;">
              <v-col>
              <v-form id="form">
                <!-- create name field with label name and textbox with validation -->
                <label for="name">Name<span style="color:red">*</span></label>
                <v-text-field
                v-model="user_data.Name"
                  required
                  outlined
                  placeholder="Enter your name"
                  :rules="[
                    v => !!v || 'Name is required',
                    v => /^[a-zA-Z ]+$/.test(v) || 'Name must contain only alphabetic characters.',
                  ]"
                ></v-text-field>
                <label for="name">Company Name<span style="color:red">*</span></label>
                <v-text-field
                v-model="user_data.Data.Company_Name"
                  required
                  outlined
                  placeholder="Enter your company name"
                  :rules="[
                    v => !!v || 'Compay is required',
                  ]"
                ></v-text-field>
                <!-- create name field with label email address and textbox with validation -->
                <label for="email">Email Address<span style="color:red">*</span></label>
                <v-text-field
                v-model="user_data.Data.Email"
                  required
                  outlined
                  placeholder="Enter your email address"
                  :rules="[
                    v => !!v || 'Email Address is required',
                    v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
                  ]"
                ></v-text-field>
                <label for="email">Interested in<span style="color:red">*</span></label>
                <v-text-field
                v-model="module_name"
                  required
                  outlined
                      disabled
                ></v-text-field>
                <!-- create Number of users field with label Number of Users and textbox with validation -->
                <label for="number_of_users">Number of Users<span style="color:red">*</span></label>
                <v-text-field
                v-model="user_data.Data.Number_of_Users"
                  required
                  outlined
                  placeholder="Enter number of users"
                  :rules="[
                    v => !v || /^\d+$/.test(v) || 'Please enter a valid number'
                  ]"
                ></v-text-field>
                
                <!-- create name field with label description and textbox with validation -->
                <label for="description">Talk to us more about your needs</label>
                <v-textarea
                v-model="user_data.Data.Description"
                  required
                  outlined
                  placeholder="Enter your description"
                  
                ></v-textarea>
                <!-- create submit buttom at center -->
                <v-row>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4">
                    <v-btn :class="{ disabled: isDisabled }" :disabled="isDisabled" block color="#009ea5"    
                    style="margin-top:2vh;background-color: #009ea5; color: #ffffff;font-size: 2.1vh;letter-spacing: 0.2px;" @click="getData(user_data)">
                   Submit
                  </v-btn>
                  </v-col>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                </v-row>
              </v-form>
            </v-col>
            </v-row>
          </v-card>
  
       </v-col>
    
       
       <v-col v-if="!isTabletMob" col="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
    </v-row>
      <!-- </v-card> -->
      <v-row v-if="formFilled && !isTabletMob" style="margin-top:20%;">
        <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
        <v-col  col="12" sm="4" md="4" lg="4" xs="4" xl="4" >
       
          <v-card class="Path-359_ent" style="padding:3vh;">
          <v-row>
          
            <v-col col="12" align="center" >
              
              <span  style="font-family: Ubuntu;font-size:16px">
                We are committed to meeting your unique requirements, and our dedicated Sales team will reach out to you promptly with best offers tailored to your needs
              </span>
            </v-col>
            
          </v-row>
          
        </v-card>
      </v-col>
      <v-col  col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
      </v-row>
      <v-row v-if="formFilled && isTabletMob" style="margin-top:40%;padding:4vh">
       
        <v-col  col="12" >
       
          <v-card class="Path-359_ent" style="padding:3vh;">
          <v-row>
          
            <v-col col="12" align="center" >
              
              <span  style="font-family: Ubuntu;font-size:16px">
                We are committed to meeting your unique requirements, and our dedicated Sales team will reach out to you promptly with best offers tailored to your needs
              </span>
            </v-col>
            
          </v-row>
          
        </v-card>
      </v-col>
   
      </v-row>
   
    </div>
  </div> 
  </template>
  
  <script>
  import axios from "axios";
  import fs from 'fs';
  
  
  
  const accessToken = '1/1203670818179728:f673e96226d68fa215a19364f41064e4';
  const workspaceId = '1203019045083878';
  const projectId = '1205117802069048';
  
  export default {
  data() {
    return {
      // define user_data object
      user_data:{
        Name:'',
        Data:{
          Company_Name:'',
          Email:'',
          Number_of_Users:'',
          Description:''
        }
      },
      uploadFiles: [],
      Files:[],
      email:'',
      name:'',
      user:{},
      company_name:'',
      auth_token:'',
      selected_file:null,
      formFilled:false,
      module_name:""
  
    };
  },
  
  computed: {
    isTabletMob : function() {

      const iPad = /iPad/i.test(navigator.userAgent);
      const iPhone = /iPhone/i.test(navigator.userAgent);
      const Android = /Android/i.test(navigator.userAgent);
      const webOS = /webOS/i.test(navigator.userAgent);
      const iPod = /iPod/i.test(navigator.userAgent);
      const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
      const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);

      return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
    },
    isDisabled() {
     //check if user_data all fields are filled or not
        if(this.user_data.Name==undefined || this.user_data.Name=="" || this.user_data.Data.Company_Name==undefined || this.user_data.Data.Company_Name=="" || this.user_data.Data.Email==undefined || this.user_data.Data.Email=="" || this.user_data.Data.Number_of_Users==undefined || this.user_data.Data.Number_of_Users==""){
          return true
        }
        else{
          return false
        }
      },
  },
  created() {
  },
  mounted() {
    var auth_token = localStorage.getItem("authtoken");
    if ( auth_token !== null && auth_token !== "" && auth_token !== undefined ) {
      this.auth_token = auth_token
      ,this.getEmail();
      this.get_profile()
      this.module_name=this.$route.query.module_name
      // this.getdiseases(this.auth_token);
      // this.getpositions(this.auth_token);
      // this.getcompanies(this.auth_token);
    } else {
      this.auth_token = ""
   
    }
  
   
  },
  methods: {
    getData(data){
      console.log(data)
      data.Data.Module_name=this.module_name
      var data=data

      console.log(data)
      this.formFilled=true
      document.getElementById("form").reset();
     
      //convert data.Description to  pure string with no curly braces and "" and next line
      var description=JSON.stringify(data.Data)
     
      //remove curly braces and "" and next line
      description=description.replace(/['"]+/g, '')
      description=description.replace(/[\[\]']+/g, '')
      description=description.replace(/[\n\r]+/g, '')
      //remove { and } from string
      description=description.replace("{", "")
      description=description.replace("}", "")
      //add new line after , in string
      description=description.replace(/,/g, '\n')
      console.log(description)
    
  
    
  
     
      
      
     
     
      
      var taskData={
        data:{
          "workspace": workspaceId,
          "name":data.Name  ,
          "notes":  description,
          "projects": [projectId]
        }
      }
  
      
      axios.post('https://app.asana.com/api/1.0/tasks', taskData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then(async response => {
        const task = response.data.data; // Task data object from the response
        const taskId = task.gid; // Get the task ID using "gid" property
        // Upload attachment
        console.log(taskId,"taskid")
         // Create FormData for file upload
      
        //  this.send_email()
      })
      .catch(error => {
        console.error('Error creating task:', error.response.data.errors);
      });
      
         
  
  
  
         // send data.file as attachment to asana
       
         
    },
    handleFileChange(event) {
      this.selected_file = event.target.files[0];
      this.$forceUpdate();
      console.log(this.selected_file,"file")
    },
    goToHome(){
      this.$router.push("/")
     },
     getEmail(){
      //create a get request to get email
      axios.get('/getEmail?token='+this.auth_token).then((res) => {
        //console.log(res)
        if (res.data.Status != 1) {
          this.email=res.data.Data.Email
         this.user_data.Data.Email=this.email
        }else{
          console.log("not logged in")
        }
      }).catch((err) => {
        console.log(err)
      })
     },
     get_profile() {
      axios.get("/userProfile?token=" + this.auth_token).then((res) => {
        if (res.data.Status !== 1) {
          this.user = res.data.Data;
        console.log(this.user,"user")
          this.name=this.user.Name
          this.company_name=this.user.Company_name
          this.user_data.Data.Company_Name=this.company_name
          this.user_data.Name=this.name
         
         
          

        } else if (
          res.data.Status == 1 &&
          res.data.Data.error == "Invalid User"
        ) {
          console.log("Invalid User");
        } else {
          console.log("Profile not found");
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);             
        }
      });
    },
      
     send_email() {
          axios
            .post("/enterprisePlan_support_email?email=" + this.user_data.Data.Email+"&name="+this.user_data.Name)
            .then((res) => {
              if (res.data.Status != 1) {
                console.log("email send successfully")               
              } else {
                console.log("email failed")
              }
          })
        },
   
     
  }
  };
  </script>
  
  
  <style lang="scss" scoped>
  .margin_align {
  overflow-x: hidden;
  overflow-y: hidden;
  
  }
  
  .Path-359_support{
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
   
    background-color: #fff;
   
  }
  .Rectangle-1151 {
    border-radius:16px;
    padding: 4vh;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
   
  }
  .mycart {
    margin: 3vh 0vh 2vh 0vh;
    font-family: Ubuntu;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color:#ffffff;
    text-align: center !important;
    
  }
  .row_style{
  max-height:56vh;
  overflow-y:scroll !important;
  }
  .Path-359_ent {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  
  
  
  }  .banner-container {
  position: relative;
  width: 100%;
  max-height: 120px;
  margin-bottom: 20px;
  }
  .banner-image {
  width: 100%;
  max-height: 90px;
  object-fit: cover;
  }
  .banner-text {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  text-align: right;
  color: #ffffff;
  }
  </style>