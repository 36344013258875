<template>
    <v-main>
    <div class="margin_align" >    
        <div id="topDiv">
            <div class="wholepage">
                <loading :active.sync="loading" :can-cancel=true :on-cancel="onCancel" :is-full-page=true></loading>
                <div style="font-family: Ubuntu;margin-bottom: -10px;margin-right: -5px;margin-top:50px">
                    <v-row style="padding:20px;">
                        <v-col cols="12" md="1" lg="1" sm="1" xs="1" align="left" ><span class="BACK" @click="gotohome()"> < Back</span></v-col>
                        <v-col cols="12" md="5" lg="5" sm="5" xs="5" align="left" >
                            <span style="color: #2c2c48;font-size: 20px;">
                            My Team Plan Users - 
                            {{ users.length }}
                            </span>
                        </v-col>
                        <v-col cols="12" md="6" lg="6" sm="6" xs="6" align="right" >
                            <v-btn
                                style=" background-color:#009ea5; color: #ffffff;font-size: 12px;height: 30px;border-radius: 4px;box-shadow: none;border: solid 2px #03a6ad;width:120px"
                                @click="addUserModal()" >Add User</v-btn> 
                        </v-col>
                    </v-row>
                    <v-row style="padding:20px;">
                      <v-col  cols="12"  md="3" lg="3" sm="3" xs="3" v-for="(i,ku) in users" :key="ku">
                          <v-card class="Path-704">
                              <v-row style="margin-top:0px;">
                                  <v-col  cols="12"  md="3" lg="3" sm="3" xs="3" style="padding-right:0"  >
                                    <div class="Ellipse-154" style="margin:0">
                                      <span class="OP">
                                        {{ i.Initials }}
                                      </span>
                                    </div>
                                      
                                  </v-col>
                                  
                                  <v-col cols="12"  md="9" lg="9" sm="9" xs="9"  style="padding-top:26px;padding-left:0" >
                                      <span style=" color: #676772;font-size: 14px;" >
                                        {{ i.User_email }}
                                      </span>
                                   
                                  </v-col>
                                 
                              </v-row>
                              <div class="Line-194"></div>
                              <v-row>
                                <v-col align="left" style="padding-left:16px">
                                  <span style="color: #868686;font-size: 18px;
                                  font-weight: 500;">
                                    {{ i.Role }}
                                  </span>
                                </v-col>
                               
                                <v-col align="right" v-if="my_payment_id !== i.User_id">
                                  <a @click="removeModal(i.ID, i.Payment_id)"><img src="../assets/Icons/Group 975.svg" style="padding-left:20px"></a>
                                </v-col>
                                <v-col align="right" v-else>
                                  <span style="color: #868686;font-size: 14px;
                                  font-weight: 500;">{{"Creator"}}</span>
                                </v-col>
                              </v-row>
    
                          </v-card>
                      </v-col>
                    </v-row>    
                </div>

                <!--Modal Code-->
                <v-dialog v-model="showUserModal" width="700">
                    <v-card style="padding:3vh 2vh 3vh 2vh;">
                    <v-card-text>
                        <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                            <span class="Select-Diseases">Add User</span>
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                        <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                            <!-- <a @click="close_diseases_modal()"><i class="fa fa-window-close" aria-hidden="true" style="color: #ff6750;margin-left: 8vh !important;"></i></a> -->
                            <v-icon color="#ff6750" style="cursor:pointer;margin-left: 8vh "
                            @click="close_modal()">mdi-close</v-icon>
                        </v-col>
                        </v-row>
                        <v-row style="padding-left:5px" >
                        <v-text-field label="User Email ID:" v-model="user_email"></v-text-field>
                        </v-row>
                        <v-row style="padding-left:5px" v-if="show_limit_msg">
                          <p style="color:red">My Team Plan has limit of 5 users.</p>
                        </v-row>
                        
                    </v-card-text>
                    <v-card-actions>
                        <v-row style="padding-top:0">
                        <v-col sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                        <v-col sm="4" md="4" lg="4" xs="4" xl="4">
                            <!-- <a style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 16px;font-family: Ubuntu;"
                            @click="oth_dise_flag = true">
                            Other Diseases
                            </a> -->
                        </v-col>
                        <v-col sm="4" md="4" lg="4" xs="4" xl="4">
                            <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 17px;" @click="addUsers()" >
                            Submit
                            </v-btn>
                        </v-col>
                        </v-row>
                    </v-card-actions>
                    </v-card>
                </v-dialog>
                <template>
                    <v-dialog
                    max-width="700"
                     v-model="already_subscribed">  
                      <v-card class="Path-359" style="padding:4vh;padding-left:2vh;border-radius:8px;" >
                        <v-row no-gutters>
                          <v-col cols=12 xs="1" md="1" xl="1" sm="1" align="left">
                            <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;padding-top:6px">
                          </v-col>
                          <v-col cols=12 xs="9" md="9" xl="9" sm="9" align="left" >
                            <span  style="font-family: Ubuntu;font-size:18px">
                             
                              {{ "User is already subscribed to My Team Plan."}}
                            </span>
                          </v-col>
                          <v-col cols=12 xs="2" md="2" xl="2" sm="2" align="right" >
              
                            <v-btn
                              icon
                              color="#009ea5"
                              @click="already_subscribed = false"
                              style="padding-bottom:5px;color"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-dialog>
                </template>
                <template>
                  <v-dialog
                  v-model="user_removed"
                  max-width="700"
                  >
                  <!-- ask for confirmation to remove User -->
                    <v-card class="Path-359" style="padding:4vh;padding-left:2vh;border-radius:8px;" >
                      <v-row no-gutters>
                        <v-col cols=12 xs="1" md="1" xl="1" sm="1" align="left">
                          <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;padding-top:6px">
                        </v-col>
                        <v-col cols=12 xs="9" md="9" xl="9" sm="9" align="left" >
                          <span  style="font-family: Ubuntu;font-size:18px">
                           
                            {{ "Do you want to remove this user from My Team Plan?"}}
                          </span>
                        </v-col>
                        <!-- create button okay -->
                      
                        <v-col cols=12 xs="2" md="2" xl="2" sm="2" align="right" >
            
                          <v-btn
                            icon
                            color="#009ea5"
                            @click="user_removed = false"
                            style="padding-bottom:5px;color"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols=12 xs="8" md="8" xl="8" sm="8" >
                        </v-col>
                        <v-col cols=12 xs="2" md="2" xl="2" sm="2" align="right" >
                          <v-btn
                            style="background-color: #009ea5; color: #ffffff;font-size: 17px;"
                            @click="removeUser(remove_plan_id, remove_payment_id)"
                          >
                            Yes
                          </v-btn>
                        </v-col>
                        <v-col cols=12 xs="2" md="2" xl="2" sm="2" align="right" >
                            <v-btn
                            style="background-color: #ffffff ; color:#009ea5;font-size: 17px;"
                            @click="user_removed=false"
                          >
                            No
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>

                  </v-dialog>
                </template>
            </div>
        </div>
    </div>
</v-main>
</template>
<script>
    import axios from 'axios';
    import App from '../App.vue';
    import Loading from 'vue-loading-overlay';
    
    export default {
        components: { App, Loading },
        name:'My_Team_Plan_Users',
        data() {
            return {
                expanded: true,
                loading:true,
                user_email:"",
                users:[],
                showUserModal:false,
                show_limit_msg:false,
                my_payment_id: '',
                already_subscribed: false,
                user_removed: false,
                remove_payment_id: '',
                remove_plan_id: '',

            };
        },
        computed: {
        },
        created() {
        },
        mounted() {
            var auth_token = localStorage.getItem("authtoken");
            if (auth_token !== null && auth_token !== "" && auth_token !== undefined && auth_token !== "undefined") {
                this.auth_token = auth_token
                this.find_admin()
                this.getMyTeamPlanUsers()
            }else{
                this.$router.push("/")
            }
        },
        methods: {
            getMyTeamPlanUsers(){
              this.users=[]
              axios
              .get("/planUser?token="+this.auth_token)
              .then((res) => {
                console.log(res.data.Data,"Users")
                this.loading = false
                if (res.data.Status !== 1) {
                  if(res.data.Data!=null){
                    for(let i=0;i<res.data.Data.length;i++){
                        var user = res.data.Data[i]
                        user["Initials"] = this.initials(res.data.Data[i].User_email)
                        this.users.push(user)
                    }
                  }else{
                    this.users=[]
                  }
                  console.log(this.users,"Users ")
                }
                })
              .catch((error) => {
                  console.log(error.message);
                  if (error.message == "Request failed with status code 500") {
                    this.logout()             
                  }
              });
            },
            addUsers(){
              this.show_limit_msg = false
              if(this.users.length == 5){
                this.show_limit_msg= true
                return
              }
               axios
                .post("/planUser?email_ids="+this.user_email+"&token="+this.auth_token)
                .then((res) => {
                  this.showUserModal=false
                  this.user_email=""
                  if (res.data.Status != 1) { 
                    if(res.data.Data != null && res.data.Data.length > 0) {
                        for (let i = 0; i < res.data.Data.length; i++) {
                          var user = res.data.Data[i]
                          user["Initials"] = this.initials(res.data.Data[i].User_email)
                          this.users.push(user)
                        }
                    }
                }else if (res.data.Data.error == "User is already Subscribed to My Team Plan.") {
                  console.log("User is already Subscribed to My Team Plan.")
                  this.already_subscribed = true
                }
                else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
                  this.logout()
                } 
              })
              .catch((err) => {
                console.log(err);
                
              });
        
            },
            removeModal(plan_id, payment_id){
              console.log("removeModal", plan_id, payment_id)
              this.user_removed=true
              this.remove_plan_id = plan_id
              this.remove_payment_id = payment_id
            },
            removeUser(plan_id, payment_id){
              this.user_removed = false
                axios
                    .delete("/planUser?id="+plan_id+"&payid="+payment_id+"&token="+this.auth_token)
                    .then((res) => {
                        if (res.data.Status != 1) { 
                            for (let i = 0; i < this.users.length; i++) {
                                if(this.users[i].ID == plan_id){
                                    this.users.splice(i, 1)
                                    break
                                }
                            }
                            this.remove_payment_id = ''
                            this.remove_plan_id = ''
                        } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
                            this.logout()
                        } 
                })
                .catch((err) => {
                    console.log(err);
                });
            },
            addUserModal(){
              this.showUserModal=true
            },
            close_modal(){
              this.showUserModal=false
            },
            onCancel() {
                console.log('User cancelled the loader.')
            },
            initials(email){
                let name = email.substring(0, email.lastIndexOf("@"));
                if(name.includes(".")){
                    var names = email.split(".")
                    if(names.length > 0){
                        var initials_l = (names[0].substring(0,1) + names[1].substring(0,1)).toUpperCase()
                        return initials_l
                    }
                }else{
                    var names = email.split(' ')
                    var initials_l = names[0].substring(0, 2).toUpperCase()
                    return initials_l
                }
            },
            gotohome(){
                this.$router.push("/Subscriptions").catch(() => {}) 
            },
            find_admin() {
               axios
                  .get("/getmypayment?token=" + this.auth_token)
                  .then((res) => {
                    // this.loading = false
                    console.log( res.data.Data.Payment.ID, "res.data.Data.Payment.ID")
                    if (res.data.Status !== 1) {
                       this.my_payment_id = res.data.Data.Payment.User_id
                    } else {
                       console.log("payment not found")
                    }
                })
            }
        }
    };
    </script>
    <style scoped>
    .margin_align {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100vh;
        margin-left: 10vh;
        margin-right: 10vh;
        margin-top:5vh;
    }
    .Line-194 {
        border-top:1.5px dotted #ffff;
        height: 0.5px;
        margin: 24.8px 3.5px 13.8px 0;
        background-color: #03a6ad;
    }
    .Path-704 {
        padding:15px;
        
        box-shadow: 0 4px 9px 0 rgba(36, 36, 36, 0.16);
        border: solid 1px #d9d7d7;
        background-color: #fff;
        border-radius: 12px;
    }
    .Ellipse-154 {
        width: 47px;
        height: 47px;
     
        padding: 12.6px 0;
        border: solid 1px #676772;
        background-color: #fff;
        text-align: center;
        border-radius: 50%;
    }
    .OP {
        font-family: Ubuntu;
        font-size: 20px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #676772;
    }
    .BACK {
        margin: 2vh;
        font-family: Ubuntu;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.43;
        letter-spacing: normal;
        text-align: left;
        color: #03a6ad;
        cursor: pointer;
      }
      .Path-359 {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px #ff6750 !important;
        background-color: #fff;
        border-radius: 20px !important;
      }
    </style>