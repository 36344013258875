<template>
    <v-main>
        <div>      
            <div>
                <div class="margin_align">
                  <loading :active.sync="loading" 
                  :can-cancel="true" 
                  :on-cancel="onCancel"
                  :is-full-page="fullPage"></loading>
                    <div style="font-family: Ubuntu;margin-bottom: -10px;margin-right: -5px;">
                        <v-row>
                            <v-col cols="12" md="3" lg="3" sm="3" xs="3">
                                <span class="My-Modules">My Modules</span>  
                            </v-col>
                        </v-row>
                        <v-row style="padding:30px;padding-top:0px" v-if="filteredList.length>0" >

                          <v-col cols="12" md="3" lg="3" sm="3" xs="3" v-for="(i, key) in allModules" :key="key">
                  
                                <v-card class="" style="padding:20px;padding-top:5px;border-radius:12px"  >
                                         <!-- <v-col align="right" style="margin-left:3vh;">
                                            <span v-if="i.Pinned">
                                                <img  src="../assets/Icons/Group 846.svg" alt="share" style="height: 4vh; width: 3.5vh;" @click="PinModule(i)"/>
                                            </span>
                                            <span v-if="!i.Pinned">
                                              
                                              <img src="../assets/Icons/Group 859.svg" alt="share" style="height: 4vh; width: 3.5vh;"  @click="PinModule(i)"/>
                                          </span>
                                        </v-col> -->
                                       
                                        <div align="center">
                                        <img :src="require(`@/assets/images/${i.Module.Image}`)" basic style="height:150px;width:250px;">
                                            <!-- <img src="" basic style="height:150px; width:250px;" /> -->
                                        </div>  
                                        <div style="margin-top:12px">
                                        <span class="Industry-Updates">
                                            {{i.Module.Name}}
                                        </span>
                                        </div>
                                    <div>
                                        <!-- <span class="-ACTIVE">
                                            {{"0 ACTIVE"}}
                                        </span>
                                        <div class="vr" style="margin: 0 5px 0 5px"></div>  
                                        <span class="-ALERTS">
                                            {{"0 ALERTS"}}
                                        </span> -->
                                       <!-- <div class="vr" style="margin: 0 5px 0 5px"></div> -->
                                        <!-- <span class="-Processing">
                                            {{"Processing…"}}
                                        </span> -->
                                    </div>
                                      <v-row >
                                        <span class="Lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-sed-do-eiusmod-tempor-incididunt-ut-labore">
                                          {{ i.Module.Description }}
                                        </span> 
                                      </v-row>
                                       <div class="Line-194"></div>
                                    
                                    <v-row no-gutters wrap style="max-height:40vh;overflow-y:scroll;">
                                        <v-col align="left" style="padding-top:2px;pa" >
                                        <div v-if="i.Module.Custom==true">
                                          <img src="../assets/Icons/Path 715.svg" alt="share" style="height: 3.8vh; width: 5vh;" @click="GotoSettings(i.Module.ID)" >
                                        </div>
                                        <div v-if="i.Module.Custom==false">
                                          <img src="../assets/Icons/Path 715.svg" alt="share" style="height: 3.8vh; width: 5vh;" @click="GotoGeknowmeSettings(i.Module.ID)"  >
                                        </div>
                                        </v-col>
                                        <v-col align="right" v-if="i.Module.Custom==false" >
                                            <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh; width: 12px" @click="GetGeKnowMeModule(i.Module.ID,i.Module.Name)" >Open</v-btn>
                                        </v-col>
                                         <v-col align="right" v-else-if="i.Module.Custom && !i.IsArticle">
                                            <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh; width: 12px" @click="VisitModule(i.Module)" >Open</v-btn>
                                        </v-col>
                                        <v-col align="right" v-else>
                                          <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh; width: 12px" @click="goToArticle(i.Module.Name)" >Open</v-btn>
                                      </v-col>

                                    </v-row>
                                   
                                </v-card>
                            </v-col>  
                        </v-row>
                        <div v-else-if="allModules.length<=0 && !loading">
                          <v-row>
                            <v-col align="center" style="margin-top:30vh;font-size:28px;color:#676772">
                              <span >
                                {{ "You have not any Modules added. Click below icon to add Modules" }}
                              </span>
                              <div style="padding-top:20px">
                                <img src="../assets/Icons/Group 797.svg" style="margin-bottom:12px; width:12vh;height:12vh" @click="goToModulesLibrary()">
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    </v-main>
</template>

<script>

import axios from "axios";
import Loading from 'vue-loading-overlay';
export default {
  

  data() {
    return {
      allModules:[],
      disease:"",
      auth_token: "",
      loading: true,
      fullPage: true,
      Articles:[
        {
          Image: "4.png",
          Name: "Industry Updates",
          Description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",

        },
        {
          Image: "5.png",
          Name: "Clinical Review",
          Description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          
        },
        {
          Image: "6.png",
          Name: "Therapy Landscape",
          Description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        
        },
        {
          Image: "7.png",
          Name: "Development Pipeline",
          Description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        
        },
        {
          Image: "3.png",
          Name: "Active Companies",
          Description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        
        }

      ],
       
      
    };
  },
    
  watch:{
    //watch disease change
    disease:function(){
      console.log("disease changed")
      
      
    }


  },
  computed: {
  },
  components: {
 
    Loading,
   
  },
  created() {
  
  },
  mounted() {
    console.log("mounted")
    var auth_token = localStorage.getItem("authtoken");

    if (auth_token !== null && auth_token !== "" && auth_token !== undefined && auth_token !== "undefined") {
      this.auth_token = auth_token
      this.get_page_activity()
      this.GetModules()
      // var dis=localStorage.getItem("selected_disease")
      // if(dis!==null || dis!=="" || dis!==undefined || dis!=="undefined"){
      //   this.disease=dis
      // }
    }
  },
  methods: {
    
    
     GotoSettings(moduleId){
       let activetab='2'
      //  var old_selected = localStorage.getItem("selected_article");
      //   if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //     localStorage.setItem("old_selected_article", old_selected);
      //   } else {
      //     old_selected = "Industry Updates"
      //     localStorage.setItem("old_selected_article", old_selected);
      //   }
      // localStorage.setItem("selected_article", "Mechanism_Module");
      this.$router.push("/mechanismModule?activeTab="+activetab+"&moduleId="+moduleId)
     },
     GotoGeknowmeSettings(moduleId){
      // var old_selected = localStorage.getItem("selected_article");
      //   if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //     localStorage.setItem("old_selected_article", old_selected);
      //   } else {
      //     old_selected = "Industry Updates"
      //     localStorage.setItem("old_selected_article", old_selected);
      //   }
      // localStorage.setItem("selected_article", "Geknowme_Settings");
      this.$router.push("/geKnowMeSettings?moduleId="+moduleId)
     },
     GetGeKnowMeModule(moduleId,moduleName){
      EventBus.$emit("module_name",moduleName);
      localStorage.setItem("selected_module",moduleName)
      let url = "/overviewbyIndication/"+moduleId+"/"+moduleName
      this.$router.push(url)
     },
      VisitModule(module){
        // var old_selected = localStorage.getItem("selected_article");
        // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
        //   localStorage.setItem("old_selected_article", old_selected);
        // } else {
        //   old_selected = "Industry Updates"
        //   localStorage.setItem("old_selected_article", old_selected);
        // }
        if(module.Module_type !="Mechanisms"){
        this.$router.push("/customModule?moduleId="+module.ID)
      }else{
        this.$router.push("/mechanismModule?moduleId="+module.ID)
      }

     },
    GetModules(){
      this.allModules=[]
      console.log("get modules")
      // let activetab='3'
      //   this.$router.push("/mechanismModule?activeTab="+activetab)
      // var dis=localStorage.getItem("selected_disease")
      // if(dis!==null || dis!=="" || dis!==undefined || dis!=="undefined"){
      //   this.disease=dis
      // }
     // console.log(disease,"disesase")
      axios
      .get("/getmymodules?disease="+this.disease+"&token="+this.auth_token)
      .then((res) => {
        if (res.data.Status !== 1) {
          this.loading=false
          //console.log(res.data.Data,"ddtata")
          for(let i=0;i<res.data.Data.allModules.length;i++){
            if (res.data.Data.allModules[i].Article==false) {
              this.allModules.push(res.data.Data.allModules[i])
            }
            
          }
          var articles =[]
          if(this.Articles!=null){
            for(let i=0;i<res.data.Data.articles.length;i++){
              articles.push(res.data.Data.articles[i])
          }   
        }
      }
        
      //  for(let j=0;j<this.Articles.length;j++){
      //   for(let i=0;i<articles.length;i++){
      //     if(articles[i].Name==this.Articles[j].Name){
      //       this.Articles[j].Pinned=true
           
      //     }else{
      //       this.Articles[j].Pinned=false
      //     }
      //   }
      // }
      

        
          //check if article.Name and Articles.Name are same and if same then set it as pinned and push it to allModules else push it as unpinned
          
          for(let i=0;i<articles.length;i++){
            console.log(i)
            let article_data={
                  Module:{}
                }
            for(let j=0;j<this.Articles.length;j++){
              console.log(j)                
                  article_data.Module.Image=this.Articles[j].Image
                  article_data.IsArticle=true
                  article_data.Module.Name=this.Articles[j].Name
                  article_data.Module.Description=this.Articles[j].Description
                  console.log(articles[i].Name, this.Articles[j].Name)
                  if(articles[i].Name==this.Articles[j].Name){
                    console.log("within if")
                    article_data.Pinned=articles[i].Pinned
                    this.allModules.push(article_data)
                    console.log("done if")
                    break
                  }  
            }
           
           }

          
          // for (let i = 0; i < this.Articles.length; i++) {
          //   for (let j = 0; j < this.allModules.length; j++) {
          //     if (this.allModules[j].IsArticle == true) {
          //       if (this.Articles[i].Name != this.allModules[j].Module.Name) {
          //         break
          //       } else {
          //         let article_data = {
          //           Module: {}
          //         }
          //         article_data.Module.Image = this.Articles[i].Image
          //         article_data.IsArticle = true
          //         article_data.Module.Name = this.Articles[i].Name
          //         article_data.Module.Description = this.Articles[i].Description
          //         article_data.Pinned = false
          //         this.allModules.push(article_data)
          //       }
          //     }
          //   }
          // }


          
          //console.log(this.allModules,"allModules")
        
      })
      .catch((error) => {
          console.log(error.message);
          if (error.message == "Request failed with status code 500") {
            this.logout()             
          }
      });
      console.log(this.allModules,"allModules")
    } ,
    PinModule(module){
      console.log("module")
      let article=""
      let module_id=""
        if (module.IsArticle){
          article=module.Module.Name
          module_id=""
          
        }else{
          article=""
          module_id=module.Module.ID
        }
        console.log(module)
        axios

        .get("/pinmodule?module_id="+module_id+"&token="+this.auth_token+"&name="+article)
        .then((res) => {
          if (res.data.Status !== 1) {
            this.loading=false
            console.log(res.data.Data,"ddtata")
           // this.GetModules()
            location.reload()
          }
          })
        .catch((error) => {
            console.log(error.message);
            if (error.message == "Request failed with status code 500") {
              this.logout()             
            }
        });

      

    },
    goToArticle(article){
      if (article=="Industry Updates") {
        // var old_selected = localStorage.getItem("selected_article");
        // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
        //   localStorage.setItem("old_selected_article", old_selected);
        // } else {
        //   old_selected = "Industry Updates"
        //   localStorage.setItem("old_selected_article", old_selected);
        // }
      // localStorage.setItem("selected_article", article);
        console.log(article,"Industry Updates")
        this.$router.push("/");
      }else if (article=="Clinical Review") {
        // var old_selected = localStorage.getItem("selected_article");
        // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
        //   localStorage.setItem("old_selected_article", old_selected);
        // } else {
        //   old_selected = "Industry Updates"
        //   localStorage.setItem("old_selected_article", old_selected);
        // }
        // localStorage.setItem("selected_article",article);
        // var disease= localStorage.getItem("selected_disease");
        // if (disease !== null && disease !== "" && disease!== undefined) {
        //   this.diseaseName=disease;
        // }
        this.diseaseName=this.disease
        this.$router.push("/articleDetails?article_title="+article+"&disease="+this.diseaseName);
      }else if (article=="Therapy Landscape") {
        // var old_selected = localStorage.getItem("selected_article");
        // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
        //   localStorage.setItem("old_selected_article", old_selected);
        // } else {
        //   old_selected = "Industry Updates"
        //   localStorage.setItem("old_selected_article", old_selected);
        // }
      // localStorage.setItem("selected_article", article);
      // var disease= localStorage.getItem("selected_disease");
      //   if (disease !== null && disease !== "" && disease!== undefined) {
      //     this.diseaseName=disease;
      //   }
      this.diseaseName=this.disease
        this.$router.push("/articleDetails?article_title="+article+"&disease="+this.diseaseName);
      }else if (article=="Active Companies") {
        // var old_selected = localStorage.getItem("selected_article");
        // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
        //   localStorage.setItem("old_selected_article", old_selected);
        // } else {
        //   old_selected = "Industry Updates"
        //   localStorage.setItem("old_selected_article", old_selected);
        // }
      // localStorage.setItem("selected_article", article);
      // var disease= localStorage.getItem("selected_disease");
      //   if (disease !== null && disease !== "" && disease!== undefined) {
      //     this.diseaseName=disease;
      //   }
      this.diseaseName=this.disease
        this.$router.push("/articleDetails?article_title="+article+"&disease="+this.diseaseName);
      }else if (article=="Development Pipeline") {
        // var old_selected = localStorage.getItem("selected_article");
        // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
        //   localStorage.setItem("old_selected_article", old_selected);
        // } else {
        //   old_selected = "Industry Updates"
        //   localStorage.setItem("old_selected_article", old_selected);
        // }
      // localStorage.setItem("selected_article", article);
      // var disease= localStorage.getItem("selected_disease");
      //   if (disease !== null && disease !== "" && disease!== undefined) {
      //     this.diseaseName=disease;
      //   }
      this.diseaseName=this.disease
        this.$router.push("/articleDetails?article_title="+article+"&disease="+this.diseaseName);
      }

      
    },
    onCancel() {
      console.log('User cancelled the loader.')
    },
    goToModulesLibrary(){
      // var old_selected = localStorage.getItem("selected_article");
      //   if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //     localStorage.setItem("old_selected_article", old_selected);
      //   } else {
      //     old_selected = "Industry Updates"
      //     localStorage.setItem("old_selected_article", old_selected);
      //   }
      // localStorage.setItem("selected_article", "Modules_Library");
      this.$router.push("/")
    },
    get_page_activity(){
    axios
            .get("/fetchIndicationSelected?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status != 1) {
                console.log(res.data,"response.data")
                this.disease = res.data.Data.Indication
                this.$forceUpdate();          
              } 
          })
  },
  }
};
</script>
<style scoped>

.margin_align {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
   
  }
.Path-704 {
    width: 377px;
    box-shadow: 0 4px 9px 0 rgba(36, 36, 36, 0.16);
    border: solid 2px #03a6ad;
    background-color: #fff;
}
  
.My-Modules {
    font-family: Ubuntu;
    font-size: 20px;
    padding-left: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c48;
    margin-left:3vh
}
  .Image-120 {
    width: 220px;
    height: 180.3px;
   
  }
  .Industry-Updates {
    width: 320.4px;
    height: 31.9px;
    font-family: Ubuntu;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c48;
  }
  .-ACTIVE {
    
    height: 16.8px;
    font-family: Ubuntu;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.71;
    letter-spacing: normal;
    text-align: left;
    color: #03a6ad;
  }
  .-ALERTS {
   
    height: 15px;
    
    font-family: Ubuntu;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.71;
    letter-spacing: normal;
    text-align: left;
    color: #f66b67;
  }
  .-Processing {
  
    height: 12px;
    font-family: Ubuntu;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.71;
    letter-spacing: normal;
    text-align: left;
    color: #868686;
  }
  

  .Lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-sed-do-eiusmod-tempor-incididunt-ut-labore {
   
    margin: 5.8px 3.6px 13.8px 0;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #7a7a83;
  }
  .Line-194 {
    border-top:2px dashed #ffff;
    height: 1px;
    margin: 15.8px 3.5px 13.8px 0;
    background-color: #03a6ad;
  }
  .Line-202 {
   
    height: 1px;
    margin: 314.2px 12.5px 16px 16.5px;
    background-color: #fff;
  }
  .Visit {
    width: 73.3px;
    height: 14.8px;
    font-family: Ubuntu;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .Path-703 {
    width: 74.1px;
    height: 33px;
    margin: 0 0 0 217.6px;
    padding: 8.6px 0 9.6px 0.8px;
    background-color: #03a6ad;
  }
  .Component-5-6 {
    width: 317.4px;
    height: 33px;
    margin: 13.8px 11.6px 4.4px 3px;
  }
  .Group-848 {
    width: 377px;
    height: 529.6px; 
 
  }
  .Ellipse-151 {
    width: 22px;
    height: 22px;
    border: solid 1px #1abbbc;
    border-radius: 24px;
    background-color: #f5f5f5;
  }
  .Group-846 {
    width: 22px;
    height: 22px;
    margin: 0 0 6.4px 27px;
    padding: 5px 5.4px 5.4px 5px;
  }
  .Path-715 {
    width: 40.7px;
    height: 26.3px;
    background-color: #03a6ad;
  }
  .Component-5-3 {
    width: 317.4px;
    height: 33px;
    margin: 13.8px 11.6px 4.4px 3px;
  }
  .Top-Performing-Modules {
  margin: 10.9px 13.4px 20.8px 40px;
  font-family: Ubuntu;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}
</style>

