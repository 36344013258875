<template>
    <!-- <div class="text-center" style="font-family: Ubuntu;">  -->
      <v-dialog width="600" v-model="dialog" s>
          <v-card  style="border-radius:30px">
            <!-- <v-card-title style="border-radius:unset">
              <span style="color: #f16753;margin-left: 2vh;;font-size: 18px;">Share</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="close_share()">
                <v-icon color="#009ea5">mdi-close</v-icon>
              </v-btn>
            </v-card-title> -->
            <v-card-text>
              <v-row wrap >
                
                <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                  <v-row>
                    <v-col cols="10" sm="10" md="10" lg="10" xs="10" xl="10">
                      <span style="color: #f16753;margin-left: 2vh;;font-size: 18px;">Share</span>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" lg="2" xs="2" xl="2" align="center">
                      <v-btn icon @click="close_share()"  style="padding-bottom:2vh">
                        <v-icon color="#009ea5">mdi-close</v-icon>
                    </v-btn>
                    </v-col>

                  </v-row>
                 
                  <v-spacer></v-spacer>
                  
                  <v-textarea
                    name="input-7-1"
                    class="mt-1"
                    v-model="quote_text"
                    type="text"
                    placeholder="Enter text and choose your social media platform to share"
                    outlined
                    rows="8"
                    dense
                    v-on:keyup="validateQuote"
                    
                    :style="!isTabletMob ? 'margin-left:2vh;margin-right:2vh' : ''"
                  ></v-textarea>
                  <!-- <p style="font-size: 16px;font-weight:500;text-align:center">{{send_msg}}</p> -->
                </v-col>
                
              </v-row>
              <v-row :style="!isTabletMob ? 'margin-top:-5vh;' : 'margin-left: 4vh'">
                <v-col v-if="!isTabletMob" cols="12" sm="1" md="1" lg="1" xs="1" xl="1"></v-col>
                
                <v-col  :cols="!isTabletMob ? '12' :'10'" sm="10" md="10" lg="10" xs="10" xl="10" >
                  <v-row wrap v-if="!isTabletMob">
                    <v-col  cols="12" sm="2" md="2" lg="2" xs="2" xl="2" style="padding-right:-1vh">                    
                      <div @click="Copylink" class="Ellipse-154-dislike" >
                        <input type="hidden" id="link-url" :value="myurl">
                        <span style="margin-bottom: 2px;">
                          <img src="@/assets/Icons/Path 494.svg" style="height: 2.5vh; width: 2.5vh;">
                        </span>
                      </div>
                      <div id ="cpymsg" style="font-size: 10px; text-align: center;">{{ copy_msg }}</div>
                    </v-col>
                    <v-col  cols="10" sm="2" md="2" lg="2" xs="2" xl="2" v-for="network in networks"
                      :key="network.network"
                      
                    >
                    <ShareNetwork
                      :network="network.name"
                      :url= "myurl"
                      title="Knolens"
                      :description="quote_text"
                      quote="Knolens Life Sciences"
                      hashtags=""
                      media="https://reachout.knolens.app/plot.png" image="https://reachout.knolens.app/plot.png"
                    >
                      <div @click="share_article(network.name)">
                        <v-avatar
                        size="45"
                        class="mx-3 mb-3"
                        :color="network.color"
                        
                        >
                          <i class="icon" :class="network.icon" color="white" ></i>
                        </v-avatar>
                      </div>
                      </ShareNetwork>
                    </v-col>
                  </v-row>
                  <v-row wrap v-if="isTabletMob">
                    <v-col>                    
                      <div @click="Copylink" class="Ellipse-154-dislike" >
                        <input type="hidden" id="link-url" :value="myurl">
                        <span style="margin-bottom: 2px;">
                          <img src="@/assets/Icons/Path 494.svg" style="height: 2.5vh; width: 2.5vh;">
                        </span>
                      </div>
                      <div id ="cpymsg" style="font-size: 10px; text-align: center;">{{ copy_msg }}</div>
                    </v-col>
                    <v-col v-for="network in networks"
                      :key="network.network"
                      align="center"
                    >
                    <ShareNetwork
                      :network="network.name"
                      :url= "myurl"
                      title="Knolens" 
                      :description="quote_text"
                      quote=""
                      hashtags=""
                      media="https://reachout.knolens.app/plot.png" image="https://reachout.knolens.app/plot.png"
                    >
                      <span @click="share_article(network.name)">
                        <v-avatar
                        size="45"
                        :color="network.color"
                        class="mb-2"
                        >
                          <i class="icon" :class="network.icon" color="white"></i>
                        </v-avatar>
                      </span>
                      </ShareNetwork>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-if="!isTabletMob" cols="12" sm="1" md="1" lg="1" xs="1" xl="1"></v-col>
                <!-- <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col> -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
    <!-- </div> -->
  </template>

<script>

import axios from 'axios'

export default {
    data() {
        return { 
            quote_text:'',
            msg_modal: false,
            copy_msg:'',
            dialog: false,
            count:0,
            networks: [
            // {
            //   network: "facebook",
            //   name: "Facebook",
            //   icon: "fab fa-facebook-f",
            //   color: "#1877f2",
            // },

            {
                network: "linkedin",
                name: "LinkedIn",
                icon: "fab fa-linkedin",
                color: "#007bb5",
            },
            {
                network: "whatsapp",
                name: "Whatsapp",
                icon: "fab fa-whatsapp",
                color: "#25d366",
            },
            // {
            //   network: "messenger",
            //   name: "Messenger",
            //   icon: "fab fa-facebook-messenger",
            //   color: "#0084ff",
            // },
            {
                network: "skype",
                name: "Skype",
                icon: "fab fa-skype",
                color: "#00aff0",
            },
            // {
            //   network: "telegram",
            //   name: "Telegram",
            //   icon: "fab fa-telegram-plane",
            //   color: "#0088cc",
            // },
            {
                network: "twitter",
                name: "Twitter",
                icon: "fab fa-twitter",
                color: "#1da1f2",
            },
            {
                network: "email",
                name: "Email",
                icon: "fa fa-envelope",
                color: "#1da1f2",
            },
        ],
        myurl: "",
        network_items: false,
        auth_token:"",
        panel_id:"",
        domain_origin:"",
        id  : "",
        panel_shared:"",
        input_id:"",
        image:"https://helloworld-hlpc4jxnba-uc.a.run.app/img/plot4.88f9e0e8.png",
        module_name:"",
        input_value:""
                
        }
    },
    computed: {
      isTabletMob : function() {
        const iPad = /iPad/i.test(navigator.userAgent);
        const iPhone = /iPhone/i.test(navigator.userAgent);
        const Android = /Android/i.test(navigator.userAgent);
        const webOS = /webOS/i.test(navigator.userAgent);
        const iPod = /iPod/i.test(navigator.userAgent);
        const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
        const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);

        return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
      }  
  },
    mounted() {
        var auth_token = localStorage.getItem("authtoken");
        if (auth_token !== null && auth_token !== "" && auth_token !== undefined && auth_token !== "undefined") {
            this.auth_token = auth_token
        }
    },
    methods: {
        share_panel(id,panelid,input_id,module,input_value){
            this.domain_origin = window.location.origin
            console.log("share panel")
            this.quote_text = ''
            this.send_msg = ''
            this.dialog = true
            this.id = id
            this.panel_shared = panelid
            this.input_id =input_id
            this.myurl= this.domain_origin +"/#/sharewebarticle/" + id + "/" + panelid+"/"+this.input_id
            this.input_value = input_value
            this.module_name = module
           // this.myurl="https://helloworld-hlpc4jxnba-uc.a.run.app/#/"
        },
        close_share(){
            this.dialog = false
            this.network_items = false
        },
        Copylink(){
          cpymsg.style.display = 'block';
          let LinkToCopy = document.querySelector('#link-url')
          LinkToCopy.setAttribute('type', 'text')    // 不是 hidden 才能複製
          LinkToCopy.select()

          try {
            var successful = document.execCommand('copy');
            var msg = successful ? true : false;
            console.log("copied",msg)
            if (msg){
              this.copy_msg="Copied!"
              setTimeout(function(){
                console.log("sqguqg")
              this.copy_msg= '';
              cpymsg.style.display = 'none';
              console.log("ddd",this.copy_msg)
              // this.$forceUpdate();
              }, 2000);
            }
           
            
          } catch (err) {
            console.log(err)
          }

          /* unselect the range */
          LinkToCopy.setAttribute('type', 'hidden')
          window.getSelection().removeAllRanges()
        },
        share_article(social) {
            this.dialog = false
          //split panel_shared by _ and take last value
            var panel_id = this.panel_shared.split("_").pop()
            //convert panel_id to int and add 1
            panel_id = parseInt(panel_id) + 1
            //and add st or nd or rd or th to panel_id as per the value
            if (panel_id == 1) {
              panel_id = panel_id + "st"
            } else if (panel_id == 2) {
              panel_id = panel_id + "nd"
            } else if (panel_id == 3) {
              panel_id = panel_id + "rd"
            } else {
              panel_id = panel_id + "th"
            }



            var entity= "Shared " + this.module_name + " Panel " + panel_id + " for " + this.input_value + " on " + social
            axios.post("/savePageActivity?page_name="+"Share"+"&token="+this.auth_token+"&input_id="+entity+"&duration="+0).then(response => {
            if (response.data.Status != 1) {
              console.log("Activity saved successfully")
            } 
            
          }).catch((error) => {
            console.log(error.message);
            if (error.message == "Request failed with status code 500") {
              this.logout()             
            }
          });
            
        },
        validateQuote(){
            if (e.keyCode === 13) {
            this.quotetext = this.quote_text
            //this.send_msg = "Your message has been added. Please choose social media platform"
            } else {
            console.log("entering quote");
            }
        }
    },
}

</script>
<style >
.Ellipse-154-dislike {
  width: 46px !important;
  height: 46px !important;
  border: solid 1px  #ffffff !important;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  font-size:15px;
  margin:auto;
  padding: 10px 0;
  background-color: #009ea5;
  cursor: pointer;
}
.v-dialog {
  box-shadow: none !important;
  overflow: visible !important;
}
</style>
