<template>
    <v-dialog v-model="user_plan" width="350" height="300">
      <v-card class="Path-359" style="padding:3vh;">
        <v-row wrap no-gutters>
          <v-col cols="12" align="right" >

            <v-btn
              icon
              color="#009ea5"
              @click="user_plan=false"
              
            >
              <!-- <v-icon>mdi-close</v-icon> -->
              <img src="../assets/Icons/Group 1354.svg" >
            </v-btn>
          </v-col>
          <v-col cols="12" align="center" >
            <img src="../assets/Icons/Group 1352.svg" style="height: 15vh;width: 15vh;">
          </v-col>
           <v-col cols="12" align="center" >
            
            <span  style="font-family: Ubuntu;font-size:18px">
              Please Subscribe to a plan to continue
            </span>
          </v-col>
        </v-row>
        <v-row>
          <!-- <v-col sm="4" md="4" lg="4" xs="4" xl="4"></v-col> -->
          
          <v-col cols="12" align="center">
            <v-btn style="background-color: #f16753; color: #ffffff;font-size: 1.8vh;width:200px;border-radius: 4px;"  @click="go_to_pricing()"> 
              
               Subscribe Now
              
            </v-btn>
          </v-col>
          <!-- <v-col sm="4" md="4" lg="4" xs="4" xl="4"></v-col> -->
        </v-row>
      </v-card>
    </v-dialog>
  </template>

  <script>
import App from '../App.vue';

export default {
  components: { App },
    name:'Events',
  data() {
    return {
     user_plan:false
       
    };
  },

  
  computed: {
    isTabletMob : function() {
        const iPad = /iPad/i.test(navigator.userAgent);
        const iPhone = /iPhone/i.test(navigator.userAgent);
        const Android = /Android/i.test(navigator.userAgent);
        const webOS = /webOS/i.test(navigator.userAgent);
        const iPod = /iPod/i.test(navigator.userAgent);
        const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
        const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);
        return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
    },
  },
  created() {
  },
  mounted() {
      console.log("hellllooooo")
  },
  methods: {
    go_to_pricing(){
        this.user_plan=false
        this.$router.push('/User_pricing')
      },
      openPopup(){
        this.user_plan=true
      }
  }
};
</script>
<style scoped>
.Path-359 {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ff6750 !important;
    background-color: #fff;
    border-radius: 40px !important;
  }
</style>