<template>
  <div style="padding:2vh;margin-top:4vh;background-color:#f9f9f9" >
    <v-row >
      <v-col :style="!isTabletMob ? 'margin:3vh 0;text-align:center' : 'margin-top:18vh !important;margin-bottom:3vh;text-align:center'">
        <span class="Top-Performing-Modules">
          Overview and Landscape Modules
        </span>
      </v-col>
    </v-row>
    <loading :active.sync="loading" 
    :can-cancel="true" 
    :on-cancel="onCancel"
    :is-full-page="fullPage"></loading>
    <v-row   v-if="filteredList.length>0" :style="!isTabletMob ? 'padding: 0vh 5vh 0px 5vh' : 'padding: 0vh 2vh 0px 2vh;'">
      <v-col :cols="!isTabletMob ? '3' :'12'" v-masonry-tile v-for="(i, key) in filteredList" :key="key">
            <v-card style="padding: 25px; border-radius: 12px; ">
              <div align="center">
                      <img  :src="require(`@/assets/images/${i.Module.Image}`)" basic style="height:200px;width:350px;background-color:">
                      
                      </div>  
                      <div style="margin-top:12px;height:6vh">
                      <span class="Industry-Updates">
                          {{i.Module.Name}}
                      </span>
                      </div>
                  <div>
                </div>
                <div style="display: flex; flex-direction: column; ">
                  <span class="Lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-sed-do-eiusmod-tempor-incididunt-ut-labore" style="flex: 1;">
                    {{ i.Module.Description }}
                  </span>
                </div>
                   <div class="Line-194"></div>
                
                <v-row no-gutters wrap style="max-height:40vh;overflow-y:scroll;">
                    <v-col align="left" style="padding-top:2px;" >
                      <!-- <div v-if="i.Module.Ischat==true && i.Module.Custom==false">
                        <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh; width: 12px" @click="OpenKnolChat()" >Open</v-btn>
                      </div> -->
                      <div style="position:relative;display:flex;flex-direction:row;">
                        <!-- create a v-menu with button which opens video -->
                        <span v-show="i.Module.Name!='Insights Book'"  v-if="i.Subscribed==false && i.Module.Default==true" style="color:#009ea5;cursor:pointer;font-size: 15px;text-decoration:underline"  @click="showSample(i.Module.Sample)">Show Sample </span>
                        <span v-show="i.Module.Name!='Insights Book'"  v-if="i.Module.Custom==true && i.Subscribed==false" style="color:#009ea5;cursor:pointer;font-size: 15px;text-decoration:underline"  @click="showSample(i.Module.Sample)">Show Sample </span>
                        <v-menu   v-if="i.Module.Video!='' && i.Module.Video!=undefined && i.Module.Video!=null"                  
                          v-model="i.Module.showVideo"
                          :close-on-content-click="false"
                          :close-on-click="false"  
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="{ ...on, click: () =>  accessS3Video(i.Module) }"
                              icon
                              style="margin-top:-0.5vh"
                            >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  style="color:#03a6ad;font-size:4vh;cursor:pointer"
                                >
                                  mdi-play-circle-outline
                                </v-icon>
                              </template>
                              <span>Tutorial Video</span>
                            </v-tooltip>
                            </v-btn>
                          </template>
                          <v-card v-if="i.Module.showVideo" >
                           <v-row wrap no-gutters style="background-color:black">
                            <v-col align=right>
                              <v-btn style="cursor:pointer" icon @click="i.Module.showVideo=false">
                                <v-icon style="color:#fff;">mdi-close</v-icon>
                              </v-btn>
                            </v-col>                          
                            </v-row>
                            <v-card-text style="padding:0; position: relative;">
                              <video :id="i.Module.ID" width="600" height="400" style="padding:1vh" controls autoplay @click.stop @contextmenu.prevent :controlsList="['nodownload']" @loadeddata="hideLoading" @loadstart="showLoading">
                                Your browser does not support the video tag.
                              </video>
                              <div v-if="showLoading" style="position: absolute;
                              top: 50%;
                              left: 50%;
                              transform: translate(-50%, -50%);
                              z-index: 9999;" class="loading-overlay">
                                <v-progress-circular indeterminate color="#ffffff"></v-progress-circular>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-menu> 
                      </div>
                    <div v-if="i.Module.Custom==false">
                      <img  v-show="i.Default==false" src="../assets/Icons/Path 985.svg" alt="user"  @click="GotoGeknowmeSettings(i.Module.ID)"  style="height:4vh; width: 4vh;padding-right:1vh;cursor:pointer" />
                      <!-- <img src="../assets/Icons/Path 715.svg" alt="share" style="height: 3.8vh; width: 5vh;" @click="GotoGeknowmeSettings(i.Module.ID)"  > -->
                    </div> 
                    </v-col>
                    <v-col align="right" v-if="i.Module.Name=='KnolChat'" >
                      <img  src="../assets/Icons/Group 1156.svg" alt="user" style="height:4vh; width: 4vh;cursor:pointer" @click="OpenKnolChat()" />
                      <!-- <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh; width: 12px" " >Open</v-btn> -->
                  </v-col>
                    <v-col align="right" v-if="i.Module.Custom==false && i.Subscribed==true" >
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <img  src="../assets/Icons/Group 1156.svg" alt="user" style="height:4vh; width: 4vh;cursor:pointer;margin-top:0vh" @click="GetGeKnowMeModule(i.Module.ID,i.Module.Name)"/>
                          </div>
                        </template>
                        <span>Open</span>
                      </v-tooltip> 
                     
                        <!-- <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh; width: 12px" @click="GetGeKnowMeModule(i.Module.ID,i.Module.Name)" >Open</v-btn> -->
                    </v-col>
                    <v-col align="right" v-if="i.Subscribed==false && i.Module.Default==false">
                      <v-btn 
                      style="
                        background-color: #009ea5;
                        color: #ffffff;
                        font-size:1.6vh;
                        height: 26px;
                        border-radius: 4px;
                        box-shadow: none;
                        width: auto;
                        margin-right:1vh;
                      "
                      @click="goToPayment(i.Module)"
                      >Buy</v-btn
                    >
                    </v-col>
                    <v-col align="right" style="margin-right:1vh" v-if="i.Subscribed==false && i.Module.Default==true" >
                      
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <span v-if="buy_other==false" @click="goToPlanPayment(i.Module.ID,i.Module.Name)"><img  src="../assets/Icons/Group 1156.svg" alt="user" style="height:4vh; width: 4vh;cursor:pointer;margin-top:0vh"></span>
                          </div>
                        </template>
                        <span>Open</span>
                      </v-tooltip> 
                      
                        
                    </v-col>
                     <v-col align="right" v-else-if="i.Module.Custom && i.Subscribed==true">
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <img  src="../assets/Icons/Group 1156.svg" alt="user" style="height:4vh; width: 4vh;cursor:pointer;margin-top:0vh" @click="VisitModule(i.Module)"/>
                          </div>
                        </template>
                        <span>Open</span>
                      </v-tooltip> 
                     
                      
                        <!-- <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh; width: 12px" @click="VisitModule(i.Module)" >Open</v-btn> -->
                    </v-col>
                    <!-- <v-col align="right" v-else-if="i.Module.Name!='KnolChat'">
                      <img  src="../assets/Icons/Group 1156.svg" alt="user" style="height:4vh; width: 4vh;cursor:pointer" @click="goToArticle(i.Module.Name)"/>
                  v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh; width: 12px" @click="goToArticle(i.Module.Name)" >Open</v-btn>
                  </v-col> -->

                </v-row>
               
            </v-card>
        </v-col>  
    </v-row>
    <div v-else-if="filteredList.length<=0 && !loading">
      <v-row>
        <v-col align="center" style="margin-top:30vh;margin-bottom:30vh;font-size:28px;color:#676772">
          <span >
             "No matching modules found" 
          </span>
        
        </v-col>
      </v-row>
    </div>
    <div v-else-if="allModules.length<=0 && !loading">
      <v-row>
        <v-col align="center" style="margin-top:30vh;font-size:28px;color:#676772">
          <span >
            "You have not any Modules added"
          </span>
        
        </v-col>
      </v-row>
    </div>
  
    <template v-if="!isTabletMob">
      <v-dialog  width="800" style="margin:0px" v-model="showtermsmodal" :retain-focus="false">
        <v-card class="Path-359" :style="!isTabletMob ? 'padding:1vh 2vh 1vh 2vh;overflow-y:hidden' : 'padding: 1vh 1vh 1vh 1vh'">
          <v-card-title class="text-h5">
            <v-row >
              <v-col :cols="!isTabletMob ? '12' :'10'" sm="8" md="8" lg="8" xs="8" xl="8">
                <span class="Select-Diseases" :style="!isTabletMob ? 'margin-left: 1vh' : 'margin:1vh'">Terms and Conditions</span>
              </v-col>
              <v-col v-if="!isTabletMob" cols="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
              <v-col :cols="!isTabletMob ? '12' :'2'" sm="2" md="2" lg="2" xs="2" xl="2">
                <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="showtermsmodal = false">mdi-close</v-icon>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <div  style="max-height:30vh;overflow-y: auto;margin-top:2vh;padding-left: 3.5vh;padding-right: 3vh;text-align: left;">
              <Terms></Terms>
            </div>
       
          </v-card-text>
            <v-row wrap :style="!isTabletMob ? '' : 'padding-left:8vh'" >
              <v-col :cols="!isTabletMob ? '12' :'1'" sm="1" md="1" lg="1" xs="1" xl="1" ></v-col>
              <v-col :cols="!isTabletMob ? '12' :'1'" sm="1" md="1" lg="1" xs="1" xl="1" :style="!isTabletMob ? 'margin-top: -10px!important;cursor:pointer;padding-right:0;padding-left:0px;' : 'margin-right:2vh;'">
                
                <v-checkbox
                  v-model="agree_to_terms"
                  color="#3fa6a1"
                  label=""
                  
                ></v-checkbox>
              </v-col>
              <v-col :cols="!isTabletMob ? '12' :'10'" sm="6" md="6" lg="6" xs="6" xl="6" style="margin-top: 10px!important;">
                
                <span :style="!isTabletMob ? 'font-size:16px;margin-left:-6vh' : 'font-size:16px;margin-left:0vh'">I agree to the Terms and Conditions</span>
              </v-col>
              <v-col :cols="!isTabletMob ? '12' :'10'" sm="4" md="4" lg="4" xs="4" xl="4"  :align="!isTabletMob ? 'center' : 'right'">
                <v-btn :disabled="!agree_to_terms" style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh;width:auto" @click=" agreeTerms(),showtermsmodal = false">Agree</v-btn>
              </v-col>
           
            </v-row>     
        </v-card>
    </v-dialog>
    </template> 
  <template>
    <v-dialog  v-if="isTabletMob" width="800" style="margin:0px;overflow-y:hidden" v-model="showtermsmodal" :retain-focus="false">
      <v-card class="Path-359" :style="!isTabletMob ? 'padding:1vh 2vh 1vh 2vh;overflow-y:hidden' : 'padding: 1vh 1vh 1vh 1vh'">
        <v-card-title class="text-h5">
          <v-row >
            <v-col :cols="!isTabletMob ? '12' :'10'" sm="8" md="8" lg="8" xs="8" xl="8">
              <span class="Select-Diseases" :style="!isTabletMob ? 'margin-left: 1vh' : 'margin:1vh'">Terms and Conditions</span>
            </v-col>
            <v-col v-if="!isTabletMob" cols="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
            <v-col :cols="!isTabletMob ? '12' :'2'" sm="2" md="2" lg="2" xs="2" xl="2">
              <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="showtermsmodal = false">mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <div  style="max-height:25vh;overflow-y: auto;margin-top:2vh;padding-left:2vh;padding-right: 3vh;text-align: left;">
            <Terms></Terms>
          </div>
     
        </v-card-text>
          <v-row wrap style="padding-left:2vh" >
            <v-col>
              
              <v-checkbox
                v-model="agree_to_terms"
                color="#3fa6a1"
                label="I agree to the Terms and Conditions"
                hide-details
                
              ></v-checkbox>
              
            </v-col>
          </v-row>     
          <v-row >
            <v-col align="right">
             
              <v-btn :disabled="!agree_to_terms" style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh;width:auto;margin-right:3vh" @click=" agreeTerms(),showtermsmodal = false">Agree</v-btn>
            </v-col>
          </v-row>
      </v-card>
    </v-dialog>
</template>
   
  </div>
<!-- </v-main> -->
</template>
<script>

import axios from "axios";
import Loading from 'vue-loading-overlay';
import { EventBus } from "../event-bus.js";
import Terms from "../components/Terms.vue";
export default {


data() {
  return {
    allModules:[],
    disease:"",
    auth_token: "",
    loading: true,
    fullPage: true,
    Articles:[
      
      // {
      //   Image: "industry_updates.png",
      //     Name: "Industry Updates",
      //     Description:"Feed of clinical activity across therapy areas and companies - tracking activity status and trends."
      // },
     
      {
        Image: "5.png",
        Name: "Clinical Review",
        Description:"This set of panels focus on our learnings from the clinical trials (including key study design parameters and evolution of endpoints) and guidance on how the drug may be used.",
        
      },
      {
        Image: "6.png",
        Name: "Therapeutics Report by TA",
        Description:"This set of panels will review the state of the disease, and the landscape of therapies available today, including the number of drugs, mechanisms of action, the stages they target, companies driving these drugs, and the financial landscape of therapeutics for this disease.",
      
      },
      {
        Image: "7.png",
        Name: "Clinical Trial Stats by TA",
        Description:"An overview of the statistics of trials in the Therapy Area of interest, their progress, success rates of trials, Mechanisms that have been tried in the past and new Mechanisms in trials.",
      
      },
      {
        Image: "3.png",
        Name: "Companies Engagement",
        Description:"An overview of the companies engaged in this Therapy Area, statistics of engagement, historical and current state of participation, by stage of engagement, types of companies, their clinical and market performance",
      
      }

    ],
    searchme:'',
    containerId: 0,
    myplan: "",
    planData: {},
    buy_other: false,
    showtermsmodal:false,
    agree_to_terms:false,
    showVideo: false,
    selcetedKey:'',
    showLoading:false
     
    
  };
},

  
watch:{
  //watch disease change
  disease:function(){
    console.log("disease changed")
    
    
  },
  


},
computed: {
  filteredList() {
      return this.allModules.filter(item => {
        return JSON.stringify(item).toLowerCase().indexOf(this.searchme.toLowerCase()) > -1
      })
  },
  videoUrl() {
    console.log("videoUrl")
    return `/getVideo?token=${this.auth_token}&key=${this.selcetedKey}`;
  },
  isTabletMob : function() {
      const iPad = /iPad/i.test(navigator.userAgent);
      const iPhone = /iPhone/i.test(navigator.userAgent);
      const Android = /Android/i.test(navigator.userAgent);
      const webOS = /webOS/i.test(navigator.userAgent);
      const iPod = /iPod/i.test(navigator.userAgent);
      const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
      const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);
      return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
  }
},
components: {

  Loading,Terms
 
},
created() {

},
mounted() {
  console.log("mounted")
  var auth_token = localStorage.getItem("authtoken");

  if (auth_token !== null && auth_token !== "" && auth_token !== undefined && auth_token !== "undefined") {
    this.auth_token = auth_token
    this.get_page_activity()
    this.GetModules()
    this.get_user_profile()
   
    // var dis=localStorage.getItem("selected_disease")
    // if(dis!==null || dis!=="" || dis!==undefined || dis!=="undefined"){
    //   this.disease=dis
    // }
    
    EventBus.$on("searchme", data => {
         // console.log("searchme",data,"my_modules")
          this.searchme = data

          
        });
  }else{
    console.log("no auth token")
    this.loading=false
  }
},
methods: {
  hideLoading(){
    this.showLoading=false

  },
  accessS3Video(module) {
      this.showLoading = true; // Show loading overlay when video starts loading
      this.selcetedKey = module.Video;
      for (let i = 0; i < this.allModules.length; i++) {
        if (this.allModules[i].Module.ID === module.ID) {
          this.allModules[i].Module.showVideo = true;
        } else {
          this.allModules[i].Module.showVideo = false;
        }
      }
      axios.post('/getVideo?key='+module.Video+'&token='+this.auth_token)
      .then(response => {
        console.log(response.data)
        
        for (let i = 0; i < this.allModules.length; i++) {
          if (this.allModules[i].Module.ID === module.ID) {
            this.allModules[i].Module.showVideo = true;   
            var video = document.getElementById(module.ID);
            video.src=response.data.Data.Url;
            video.play();
            this.showLoading = false; // Hide loading overlay when video source is available
  
          } else {
            this.allModules[i].Module.showVideo = false;
          }
        }
      })
      .catch(error => {
        console.error('Error accessing S3 video:', error);
        this.showLoading = false; // Hide loading overlay on error
      });
  },
   GotoSettings(moduleId){
     let activetab='3'
    //  var old_selected = localStorage.getItem("selected_article");
    //   if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
    //     localStorage.setItem("old_selected_article", old_selected);
    //   } else {
    //     old_selected = "Industry Updates"
    //     localStorage.setItem("old_selected_article", old_selected);
    //   }
     if(moduleId=='6421561bfbaaf1a50922a1bd'){
      // localStorage.setItem("selected_article", "Custom_Module");
      this.$router.push("/customModule?activeTab="+activetab+"&moduleId="+moduleId)
     }else{
      // localStorage.setItem("selected_article", "Mechanism_Module");
      this.$router.push("/mechanismModule?activeTab="+activetab+"&moduleId="+moduleId)
     }
   
   },
   GotoGeknowmeSettings(moduleId){
    // var old_selected = localStorage.getItem("selected_article");
    //   if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
    //     localStorage.setItem("old_selected_article", old_selected);
    //   } else {
    //     old_selected = "Industry Updates"
    //     localStorage.setItem("old_selected_article", old_selected);
    //   }
    // localStorage.setItem("selected_article", "Geknowme_Settings");
    this.$router.push("/geKnowMeSettings?moduleId="+moduleId)
   },
   GetGeKnowMeModule(moduleId,moduleName){

    EventBus.$emit("module_name",moduleName);
    localStorage.setItem("selected_module",moduleName)
    let url="/overviewbyIndication/"+moduleId+"/"+moduleName
     this.$router.push(url)
     
   },
    VisitModule(module){
      // var old_selected = localStorage.getItem("selected_article");
      // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //   localStorage.setItem("old_selected_article", old_selected);
      // } else {
      //   old_selected = "Industry Updates"
      //   localStorage.setItem("old_selected_article", old_selected);
      // }
    // localStorage.setItem("selected_article", "Mechanism_Module");
    console.log(module.Name,"module name")

    EventBus.$emit("module_name",module.Name);
    localStorage.setItem("selected_module",module.Name)
    if (module.ID=="660bf3fcc1d87504a29913d5"){
      console.log("chat")
        this.$router.push("/AnalyticalChat/"+module.ID);
        return
    }
    if(module.Module_type !="Mechanisms"){
        this.$router.push("/customModule?moduleId="+module.ID)
    }else{
        this.$router.push("/mechanismModule?moduleId="+module.ID)
    }

   },
   OpenKnolChat(){
    var chat=true
        EventBus.$emit("chat",chat );
        // this.$router.push("/")
        this.$forceUpdate();
   },
   GetModules(){
    this.allModules=[]

  let modulesData=[]
   // console.log("get modules")
    // let activetab='3'
    //   this.$router.push("/mechanismModule?activeTab="+activetab)
    // var dis=localStorage.getItem("selected_disease")
    // if(dis!==null || dis!=="" || dis!==undefined || dis!=="undefined"){
    //   this.disease=dis
    // }
   // console.log(disease,"disesase")
    axios
    .get("/getmymodules?disease="+this.disease+"&token="+this.auth_token)
    .then((res) => {
      if (res.data.Status !== 1) {
        this.loading=false
        if (res.data.Data.allModules!=null){
        

         // console.log(res.data.Data.allModules,"all modulesss")
          for(let i=0;i<res.data.Data.allModules.length;i++){
               if (res.data.Data.allModules[i].Module.Private==true){
                res.data.Data.allModules[i].Module.showVideo=false
                modulesData.push(res.data.Data.allModules[i])
               }
          }  
          for(let i=0;i<res.data.Data.allModules.length;i++){
            if (res.data.Data.allModules[i].Module.Default==true ){
               res.data.Data.allModules[i].Module.showVideo=false
                modulesData.push(res.data.Data.allModules[i])
            }
          }  
          for(let i=0;i<res.data.Data.allModules.length;i++){
               if (res.data.Data.allModules[i].Module.Default!=true && res.data.Data.allModules[i].Module.Custom==true && res.data.Data.allModules[i].Module.Private!=true){
                // console.log(res.data.Data.allModules[i].Module.Name,"iiiiiiii")
                res.data.Data.allModules[i].Module.showVideo=false
                modulesData.push(res.data.Data.allModules[i])
               }
          }  
          for(let i=0;i<res.data.Data.allModules.length;i++){
               if (res.data.Data.allModules[i].Module.Default!=true && res.data.Data.allModules[i].Module.Custom!=true ){
                res.data.Data.allModules[i].Module.showVideo=false
                modulesData.push(res.data.Data.allModules[i])
               }
          }  
         
        }
       // console.log(modulesData,"modulesData")
        this.allModules=modulesData


       
    }else{
      //console.log("helllllloooooo")
      
      this.loading=false
      // for (let i=0;i<this.Articles.length;i++){
      // let article_data={
      //       Module:{}
      //     }
      //       article_data.Module=this.Articles[i]
      //       article_data.Pinned=false
      //       article_data.IsArticle=true
      //       this.allModules.push(article_data)
      //   }

        
    }

    })
    .catch((error) => {
      console.log(error,"error")
    //  for (let i=0;i<this.Articles.length;i++){
    //   let article_data={
    //         Module:{}
    //       }
    //         article_data.Module=this.Articles[i]
    //         article_data.Pinned=false
    //         article_data.IsArticle=true
    //         this.allModules.push(article_data)
    //     }
        
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);             
        }
    });
   // console.log(this.allModules,"all modules")
  } ,
   userExists(username,arr) {
   
    return arr.some(function(el) {
      // console.log(el)
      // console.log(el.Name)
      // console.log(el.Name,username)
      return el.Name === username;
    }); 
   
  },
  PinModule(module){
  // console.log(module)
    let article=""
    let module_id=""

      if (module.IsArticle){
        

        article=module.Module.Name
        if (module.Module.Name=="Pipeline Overview") {
          article="Development Pipeline"
         // console.log(article)

        }else if(module.Module.Name=="Companies Engagement"){
          article="Active Companies"
       //  console.log(article)

        }
        module_id=""
        
      }else{
        article=""
        module_id=module.Module.ID
      }
    //  console.log(module)
      axios

      .get("/pinmodule?module_id="+module_id+"&token="+this.auth_token+"&name="+article)
      .then((res) => {
        if (res.data.Status !== 1) {
          this.loading=false
        //  console.log(res.data.Data,"ddtata")
          this.GetModules()
          

          //location.reload()
        }
        })
      .catch((error) => {    
          this.loading=false      
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);           
          }
      });

    

  },
  goToArticle(article){
 //   console.log(article)
    if (article=="Industry Updates") {
      // var old_selected = localStorage.getItem("selected_article");
      // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //   localStorage.setItem("old_selected_article", old_selected);
      // } else {
      //   old_selected = "Industry Updates"
      //   localStorage.setItem("old_selected_article", old_selected);
      // }
    // localStorage.setItem("selected_article", article);
      //console.log(article,"Industry Updates")
      this.$router.push("/articleList");
    }else if (article=="Clinical Review") {
      // var old_selected = localStorage.getItem("selected_article");
      // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //   localStorage.setItem("old_selected_article", old_selected);
      // } else {
      //   old_selected = "Industry Updates"
      //   localStorage.setItem("old_selected_article", old_selected);
      // }
      // localStorage.setItem("selected_article",article);
      // var disease= localStorage.getItem("selected_disease");
      // if (disease !== null && disease !== "" && disease!== undefined) {
      //   this.diseaseName=disease;
      // }
      this.diseaseName=this.disease;
      this.$router.push("/articleDetails?article_title="+article+"&disease="+this.diseaseName);
    }else if (article=="Therapeutics Report by TA") {
      article="Therapy Landscape"
      // var old_selected = localStorage.getItem("selected_article");
      // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //   localStorage.setItem("old_selected_article", old_selected);
      // } else {
      //   old_selected = "Industry Updates"
      //   localStorage.setItem("old_selected_article", old_selected);
      // }
    // localStorage.setItem("selected_article", article);
    // var disease= localStorage.getItem("selected_disease");
    //   if (disease !== null && disease !== "" && disease!== undefined) {
    //     this.diseaseName=disease;
    //   }
    this.diseaseName=this.disease;
    this.$router.push("/articleDetails?article_title="+article+"&disease="+this.diseaseName);
    }else if (article=="Companies Engagement") {

      article="Active Companies"
    //  console.log(article,"Companies Engagement")
      // var old_selected = localStorage.getItem("selected_article");
      // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //   localStorage.setItem("old_selected_article", old_selected);
      // } else {
      //   old_selected = "Industry Updates"
      //   localStorage.setItem("old_selected_article", old_selected);
      // }
    // localStorage.setItem("selected_article", article);
    // var disease= localStorage.getItem("selected_disease");
    //   if (disease !== null && disease !== "" && disease!== undefined) {
    //     this.diseaseName=disease;

    //   }
      this.diseaseName=this.disease;
      this.$router.push("/articleDetails?article_title="+article+"&disease="+this.diseaseName);
    }else if (article=="Clinical Trial Stats by TA") {
     article="Development Pipeline"
      // var old_selected = localStorage.getItem("selected_article");
      // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //   localStorage.setItem("old_selected_article", old_selected);
      // } else {
      //   old_selected = "Industry Updates"
      //   localStorage.setItem("old_selected_article", old_selected);
      // }
    // localStorage.setItem("selected_article", article);
    // var disease= localStorage.getItem("selected_disease");
    //   if (disease !== null && disease !== "" && disease!== undefined) {
    //     this.diseaseName=disease;
    //   }
      this.diseaseName=this.disease;
      this.$router.push("/articleDetails?article_title="+article+"&disease="+this.diseaseName);
    }

    
  },
  showSample(Sample){
        // var old_selected = localStorage.getItem("selected_article");
        //   if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
        //     localStorage.setItem("old_selected_article", old_selected);
        //   } else {
        //     old_selected = "Industry Updates"
        //     localStorage.setItem("old_selected_article", old_selected);
        //   }
        // localStorage.setItem("selected_article", "Sample");
      this.$router.push("/Sample?sample="+Sample);
    },
  onCancel() {
    console.log('User cancelled the loader.')
  },
  goToModulesLibrary(){
    // var old_selected = localStorage.getItem("selected_article");
    //   if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
    //     localStorage.setItem("old_selected_article", old_selected);
    //   } else {
    //     old_selected = "Industry Updates"
    //     localStorage.setItem("old_selected_article", old_selected);
    //   }
    // localStorage.setItem("selected_article", "Modules_Library");
    this.$router.push("/")
  },
  goToPayment(m){
  //   console.log("m",m)
      // var old_selected = localStorage.getItem("selected_article");
      // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //     localStorage.setItem("old_selected_article", old_selected);
      //   } else {
      //     old_selected = "Industry Updates"
      //     localStorage.setItem("old_selected_article", old_selected);
      //   }
      // localStorage.setItem("selected_article", "Module_Payment");
      this.$router.push("/ModulePurchase?module_name="+m.Name);
    },
    goToPlanPayment(moduleId,moduleName,subscribed){
      //route to user_plan_payment page
      if (this.buy_other == true) {
        this.$router.push("/User_pricing");
      } else {    
        EventBus.$emit("module_name",moduleName);
        localStorage.setItem("selected_module",moduleName)
        let url="/overviewbyIndication/"+moduleId+"/"+moduleName
        this.$router.push(url)
      }
   },
   get_user_profile() {
      axios
        .get("/userProfile?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status !== 1) {
            this.checkTermsAgreed()
            this.user = res.data.Data;
            this.myplan = this.user.Plan
          //  console.log(this.myplan,"this.myplan")
            axios.get("/getUserPlans?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status !== 1) {
                this.planData = res.data.Data
           //     console.log(this.planData,"this.planData")
                if (this.myplan == "Trial" || this.myplan == "No Plan") {
                  if (this.planData.Trial_exceeded == true || this.planData.Trial_remaining_days <= 0) {
               //     console.log("Trial plan expired")
                    this.buy_other = true
                   
                  } else {
                    
                    this.buy_other = false
                  //  console.log("Trial plan active")
                  }
                }
              } else {
                console.log("User plan not found")
              }
              this.get_user_plan()
            })
            .catch((err) => {
              console.log(err);
              this.get_user_plan()
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });   
    },
    get_user_plan(){
      axios.get('/getUserPlan?token='+this.auth_token).then((res) => {
         //console.log(res)
         if (res.data.Status != 1) {
          //  console.log(res.data,"user plan")
           if (res.data.Data.Plan==true){
              this.buy_other = false
         //    console.log("no plan")
                      
           
             this.$forceUpdate();
           } else{
            this.buy_other = true
            // if(this.gotochat==true){
            //     this.$refs.SubscriptionModal.openPopup();
            // }
          }         
         }
       }).catch((err) => {
         console.log(err)
       })
    },
    get_page_activity(){
    axios
            .get("/fetchIndicationSelected?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status != 1) {
             //   console.log(res.data,"response.data")
                this.disease = res.data.Data.Indication
                this.$forceUpdate();          
              } 
          })
  },
  agreeTerms(){
      
      this.agree_to_terms = false
  //create get request to update profile
      
        axios.get("/updateAgreeTerms?token="+this.auth_token).
        then((res) => {
          if (res.data.Status != 1) {
         //   console.log("terms updated")
            
           
          // this.$router.push('/User_plan_payment/'+plan)
          } else {
         //   console.log("terms updated")
          }
        })
      
 
  },
  checkTermsAgreed(){
      //create get request
      //wait for 1 sec and then check if terms agreed
      setTimeout(() => {
            axios
          .get("/checktermsagreed?token=" + this.auth_token).
          then((res) => {
            if (res.data.Status != 1) {

              if (res.data.Data.Agreed!=true) {
                
              
            
                this.showtermsmodal = true
              }

            } else {
              this.showtermsmodal = false
            console.log("Terms not agreed")
            }
          }).catch((error) => {
            console.log(error.message);
          
          });

      }, 1000);
    },
   
     
}
};
</script>



<style scoped>
.margin_align {
overflow-x: hidden;
overflow-y: auto;
height: 60vh;
padding:6vh;
}
.Path-704 {
width: 377px;
box-shadow: 0 4px 9px 0 rgba(36, 36, 36, 0.16);
border: solid 2px #03a6ad;
background-color: #fff;
}

.My-Modules {
font-family: Ubuntu;
font-size: 20px;
padding-left: 10px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.43;
letter-spacing: normal;
text-align: left;
color: #2c2c48;
margin-left:3vh
}
.Image-120 {
width: 220px;
height: 180.3px;

}
.Industry-Updates {
width: 320.4px;
height: 31.9px;
font-family: Ubuntu;
font-size: 2.5vh;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 1.36;
letter-spacing: normal;
text-align: left;
color: #2c2c48;
}
.-ACTIVE {

height: 16.8px;
font-family: Ubuntu;
font-size: 12px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 2.71;
letter-spacing: normal;
text-align: left;
color: #03a6ad;
}
.-ALERTS {

height: 15px;

font-family: Ubuntu;
font-size: 12px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 2.71;
letter-spacing: normal;
text-align: left;
color: #f66b67;
}
.-Processing {

height: 12px;
font-family: Ubuntu;
font-size: 12px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 2.71;
letter-spacing: normal;
text-align: left;
color: #868686;
}


.Lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-sed-do-eiusmod-tempor-incididunt-ut-labore {

margin: 1vh 0.5vh 1vh 0;
font-family: Ubuntu;
font-size: 1.9vh;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.43;
letter-spacing: normal;
text-align: left;
color: #7a7a83;
}
.Line-194 {
border-top:2px dashed #ffff;
height: 1px;
margin: 15.8px 3.5px 13.8px 0;
background-color: #03a6ad;
}
.Line-202 {

height: 1px;
margin: 314.2px 12.5px 16px 16.5px;
background-color: #fff;
}
.Visit {
width: 73.3px;
height: 14.8px;
font-family: Ubuntu;
font-size: 13px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 1.69;
letter-spacing: normal;
text-align: center;
color: #fff;
}
.Path-703 {
width: 74.1px;
height: 33px;
margin: 0 0 0 217.6px;
padding: 8.6px 0 9.6px 0.8px;
background-color: #03a6ad;
}
.Component-5-6 {
width: 317.4px;
height: 33px;
margin: 13.8px 11.6px 4.4px 3px;
}
.Group-848 {
width: 377px;
height: 529.6px; 

}
.Ellipse-151 {
width: 22px;
height: 22px;
border: solid 1px #1abbbc;
border-radius: 24px;
background-color: #f5f5f5;
}
.Group-846 {
width: 22px;
height: 22px;
margin: 0 0 6.4px 27px;
padding: 5px 5.4px 5.4px 5px;
}
.Path-715 {
width: 40.7px;
height: 26.3px;
background-color: #03a6ad;
}
.Component-5-3 {
width: 317.4px;
height: 33px;
margin: 13.8px 11.6px 4.4px 3px;
}
.Top-Performing-Modules {
  margin: 0px;
  font-family: Ubuntu;
  font-size: 3vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: center !important;
  color: #05777c !important;
}
.responsive-diagonal {
  width: 100vw;

  background: linear-gradient(to right bottom, #E4F5F4 50%, #E4F5F4 40.3%);
  }


  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
  .column {
    flex: 1;
    border: 1px solid #ccc;
    padding: 10px;
    margin: 5px;
    display: flex;
    flex-direction: column;
  }
  .Path-359 {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ff6750 !important;
    background-color: #fff;
    border-radius: 20px !important;
    overflow-y:auto;
   
  }

</style>

