<template> 
  <div>
    <loading :active.sync="loading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"  class="loading-overlay"></loading>
  
  <div class="row margin_align"  v-if="!loading && !isTabletMob">
      
        <v-row>
          <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" class="first_sec">
          <div>
            <img src="../assets/Icons/Knolense_Dark-014.svg" style="height: 6vh;margin: 5vh 0vh 2vh 17vh !important;cursor:pointer" @click="goBack()">
          </div>
          <div style="margin-top:15vh;margin-bottom:20vh">
            <v-row  v-if="registered==false">          
              <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                <span class="new_login_11">Registration & Verification</span><span class="Ellipse-298"></span>
                <div class="Line-291_0"></div>
              </v-col>
            </v-row>
            <v-row style="margin-top:5vh"  v-if="registered==false">
              <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                <span class="new_login">Terms and Conditions</span><span class="Ellipse-298"></span>
                <div class="Line-291_26"></div>
              </v-col>
            </v-row>
           
            <v-row :style=" registered==false ? 'margin-top:5vh':'margin-top:25vh'">
              <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                <span class="payment">Payment</span><span class="Ellipse-297_2"></span>
                <div class="Line-291_22"></div>
              </v-col>
            </v-row>
          </div>
          <!-- <v-row v-if="registered==false" style="bottom:20px;position: fixed;margin-left:4vh;">
            <span class="if-you">If you have an account, Login here</span>
            <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" ></v-col>
            <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" >
              <div><v-btn class="Path-820" @click="go_to_login()">Login</v-btn></div>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" ></v-col>
          </v-row> -->
          </v-col>
          <v-col class="mt-4" cols="12" sm="8" md="8" lg="8" xs="8" xl="8">
            <v-row>
              <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3" ></v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6" >
                <v-card class="trans_Rectangle-1151">
                  <v-row align="center" justify="center">
                    <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3">
                      <img src="../assets/Icons/Group 1329.svg"
                        style="height: 5vh;object-fit: contain;">
                    </v-col>
                    <v-col cols="12" sm="9" md="9" lg="9" xs="9" xl="9">
                    </v-col>
                  </v-row>
                  <div v-if="process_done">
                    <div style="margin-top:3vh">
                      <span class="Payment-Successful">{{display_msg}}</span>
                      <p class="description">Your payment has been processed successfully.</p>
                      <p class="description">You have chosen {{my_plan}} with {{ duration }}ly subscription.</p>
                    </div>
                    <div v-if="sentEmail">
                      <p class="description">
                        We've sent you an email with a copy of your subscription details
                      </p>
                    </div>
                    <hr style="border-top: 1px solid #ffffff">
                    <div style="magin-top:5vh">
                      <span class="Purchase-Details">Purchase Details as follows:</span>
                    </div>
                    
                    <!-- <div  style="max-height:30vh;overflow-y:auto">
                    <v-card  class="Rectangle-1204" v-for="(cart_d, ka) in cart_diseases" :key="ka">
                      <span class="disease_name">{{cart_d}} </span>
                    </v-card>
                    </div> -->
                    <div>
                      <p class="description1">
                        All diseases included
                      </p>
                      <hr style="border-top: 1px solid #ffffff">
                      <p class="description1">
                        Unlimited access to KnolChat
                      </p>
                      <hr style="border-top: 1px solid #ffffff">
                      <p class="description1"> 
                        Access to Overview by Indications Analysis Module
                      </p>
                      <hr style="border-top: 1px solid #ffffff">
                    </div>
                  </div>
                  <div style="text-align: center;margin-top:3vh" v-if="!process_done">
                    <span class="Payment-Successful">{{display_msg}}</span>
                    <p class="description" style="margin-top:5vh">We will get back to you very soon. <br><br>Please click on continue to proceed further</p>
                  </div>
                </v-card>
                <v-row>
                    <v-col cols="12" sm="8" md="8" lg="8" xs="8" xl="8">
                      <span style="font-size:1.8vh;margin-left:1vh;font-family:Ubuntu" v-if="process_done">This plan is valid for 1 year</span>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3" align="center" justify="center">
                      <v-btn class="Path-703" @click="goback()">Continue</v-btn>
                    </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3" ></v-col>
            </v-row>        
          </v-col>
        </v-row>
  </div>

  <div class="row" v-else  style="height:100vh;">
    <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
      <loading :active.sync="loading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
            <div style="background-color:#013d48;margin-top:-4vh">
              <img src="../assets/Icons/Knolense_Dark-01.svg" style="height: 5vh;margin: 3vh 0vh 2vh 2vh !important;cursor:pointer" @click="goToHome()">
            </div>
          </v-col>
        </v-row>
        <v-row  style="max-height:80vh;overflow-y: auto;margin:4vh">
          <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12" >
                <v-card class="trans_Rectangle-11513333">
                  <v-row align="center" justify="center">
                    <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3">
                      <img src="../assets/Icons/Group 1329.svg"
                        style="height: 5vh;object-fit: contain;">
                    </v-col>
                    <v-col cols="12" sm="9" md="9" lg="9" xs="9" xl="9">
                      <span class="Payment-Successful" style="font-size:3.5vh">{{display_msg}}</span>
                    </v-col>
                  </v-row>
                  <div v-if="process_done">
                    <div style="margin-top:3vh">
                      <p class="description">Your payment has been processed successfully.</p>
                      <p class="description">You have chosen {{my_plan}} with {{ duration }}ly subscription.</p>
                    </div>
                    <div v-if="sentEmail">
                      <p class="description">
                        We've sent you an email with a copy of your subscription details
                      </p>
                    </div>
                    <hr style="border-top: 1px solid #ffffff">
                    <div style="magin-top:5vh">
                      <span class="Purchase-Details">Purchase Details as follows:</span>
                    </div>
                    
                    <!-- <div  style="max-height:30vh;overflow-y:auto">
                    <v-card  class="Rectangle-1204" v-for="(cart_d, ka) in cart_diseases" :key="ka">
                      <span class="disease_name">{{cart_d}} </span>
                    </v-card>
                    </div> -->
                    <div>
                      <p class="description1">
                        All diseases included
                      </p>
                      <hr style="border-top: 1px solid #ffffff">
                      <p class="description1">
                        Unlimited access to KnolChat
                      </p>
                      <hr style="border-top: 1px solid #ffffff">
                      <p class="description1"> 
                        Access to Overview by Indications Analysis Module
                      </p>
                      <hr style="border-top: 1px solid #ffffff">
                    </div>
                  </div>
                  <div style="text-align: center;margin-top:3vh" v-if="!process_done">
                    <span class="Payment-Successful">{{display_msg}}</span>
                    <p class="description" style="margin-top:5vh">We will get back to you very soon. <br><br>Please click on continue to proceed further</p>
                  </div>
                </v-card>
                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12" align="center">
                      <span style="font-size:2.2vh;margin-left:2vh;font-family:Ubuntu;" v-if="process_done">This plan is valid for 1 year</span>
                      <v-btn class="Path-703" @click="goback()" style="width:60%;margin-top:2vh;font-size:2vh;height:5vh;">Continue</v-btn>
                    </v-col>
                </v-row>
        </v-col>
      </v-row>        
    </v-col>
  </div>
</div>
</template>
        

<script>
import axios from "axios";
import { EventBus } from "../event-bus.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  data() {
    return {
      auth_token: '',
      disesase:"",
      loading: true,
      fullPage: true,
      display_msg: '',
      process_done: false,
      cart_diseases: [],
      my_plan : "",
      duration : "",
      user: {},
      current_payment_id: "",
      sentEmail:true,
      registered:false
    };
  },
  components: {
    Loading,
  },
  computed: { 
    isTabletMob : function() {
        const iPad = /iPad/i.test(navigator.userAgent);
        const iPhone = /iPhone/i.test(navigator.userAgent);
        const Android = /Android/i.test(navigator.userAgent);
        const webOS = /webOS/i.test(navigator.userAgent);
        const iPod = /iPod/i.test(navigator.userAgent);
        const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
        const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);
        return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
    }, 
  },
  mounted() {
    localStorage.removeItem("plan")
    var sessionId = this.$route.query.session_id
    var customerId = this.$route.query.customer_id  
    var paymentId = this.$route.query.payid
    var auth_token = localStorage.getItem("authtoken");
    if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
      this.auth_token =  auth_token
      // this.get_user_plan_name()
      // this.save_page_activity("ModulesLibrary","Transaction Success")
      localStorage.setItem("selected_article", "Transaction Success") 
      // localStorage.setItem("old_selected_article", "Transaction Success") 
      this.getuser()
      this.isRegistered()
      // this.send_email()
      this.updateSubscriptionData(sessionId, customerId, paymentId)
      // emit event 
      // EventBus.$emit('payment_done',true )
    } else {
      this.auth_token = ""
    }

   
  },
  methods: {
    goBack(){
        this.$router.push("/").catch(() => {})
    },
    onCancel() {
      console.log('User cancelled the loader.')
    },
    isRegistered(){
      var activity_name="Activity User Registration"
      axios
      .get("/getactivity?activity_name=" + activity_name)
        .then((res) => {
          console.log(res,"ressssssssss activity")
          if (res.data.Data.activities != null) {
              this.registered = true
              
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    getuser() {
      axios
        .get("/userProfile?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status !== 1) {
            this.user = res.data.Data;
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
            this.logOut()
          } else {
            console.log("user not found")
          }
        })
        .catch((error) => {
          console.log(error.message);
          this.logOut()
      });
    },
    goback() {
      // localStorage.setItem("selected_article", "Modules Library")
      // var disesase = localStorage.getItem("selected_disease")
      console.log("inside")
      
      this.get_page_activity()
      var disesase=this.disesase
      if (disesase !== null && disesase !== "undefined" && disesase !== "") {
        disesase = disesase
      } else {
        disesase = "Multiple sclerosis"
      }

      this.$router.push("/").catch(() => {}) 
      this.l1l2_page_data = {}
      this.l1l2_page_data.article_title = "Events"
      this.l1l2_page_data.disease = disesase
      EventBus.$emit("l1l2_page_data", this.l1l2_page_data);
    },
    updateSubscriptionData(sessionId, customerId, paymentId){
      if (sessionId){
        axios.put("/updateSubscriptionData?token="+this.auth_token+"&sid="+sessionId+"&cid="+customerId+"&pid="+paymentId)
        .then((res) => {
          this.loading = false
          if (res.data.Status != 1) {
                console.log(res.data.Data,"res.data.Dataaaaaa")
                if(res.data.Data.Plan == 0){
                  this.my_plan = "First Mover Plan"
                }else if (res.data.Data.Plan == 1){
                  this.my_plan = "My Team Plan"
                }else if (res.data.Data.Plan == 4){
                  this.my_plan = "Basic Plan"
                }

                
                if(res.data.Data.Duration == 0){
                  this.duration = "Month"
                }else if (res.data.Data.Duration == 1){
                  this.duration = "Year"
                }
                EventBus.$emit("updateprofile", true);
                var user_diseases = []
                if (res.data.Data.Payment_process_complete){
                  this.cart_diseases = res.data.Data.Cart_diseases
                  this.process_done = true
                  this.display_msg = "Payment Successful !"
                  user_diseases = res.data.Data.Diseases
                  // localStorage.setItem("selected_disease", user_diseases[0]); 
                  // localStorage.setItem("diseases", user_diseases);
                  // EventBus.$emit("disname", user_diseases[0]);
                  // EventBus.$emit("newdiseases", user_diseases);
                  if (this.my_plan == "First Mover Plan") {
                    this.delete_cart()
                  } 
                  this.current_payment_id = paymentId
                  this.update_profile()
                  // this.send_email()
                }else{
                  this.process_done = false
                  this.display_msg = "Payment Processing !"
                }
          } else {
            this.loading = false
            this.process_done = false
            this.display_msg = "Payment Processing !"
          }
      })
      .catch((error) => {
        console.log(error.message);
        // if (error.message == "Request failed with status code 500") {
        //   this.logOut()             
        // }
      });
      }
    },
    logOut() {
      EventBus.$emit("logout", this.auth_token)
    },
    delete_cart() {     
      axios.post("/deletediseasecart?token=" + this.auth_token)
      .then((res) => {
        if (res.data.Status != 1) {
          console.log("Cart deletion Successful")
        } else {
          console.log("Cart deletion failed")
        }
      })
      .catch((error) => {
        console.log(error.message);
        // if (error.message == "Request failed with status code 500") {
        //   this.logOut()             
        // }
      });
    },
    update_profile() {
      //console.log(this.my_plan,"this.my_plan")
      let url = `/updatePlan?token=` + this.auth_token + "&plan=" + this.my_plan
      axios.put(url).then((res) => {
        if (res.data.Status == 0) {
          if (res.data.Data.Message == "Success") {
            console.log("profile updated")
            if (this.my_plan == "My Team Plan") {
              this.unsubscribe_first_mover()
            }
          }
        } else {
          console.log("Error while profile updation");
          this.message = "Please try again";
        }
      })
    },
    unsubscribe_first_mover() {
      let url = `/unsubscribeFirstMover?token=` + this.auth_token + "&payment_id=" + this.current_payment_id
      axios.post(url).then((res) => {
        if (res.data.Status == 0) {
            console.log(res.data.Data.Message,"Message")
          } else {
            console.log("Error while plan updation");
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    send_email() {
          axios
            .post("/signup_sucess_email?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status != 1) {
                console.log("email send successfully")               
              } else {
                console.log("email failed")
              }
          })
    },
    go_to_login() {
      console.log("go to login")
        },
        get_page_activity(){
        axios
            .get("/fetchIndicationSelected?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status != 1) {
                console.log(res.data,"response.data")
                this.disesase = res.data.Data.Indication
                this.$forceUpdate();          
                this.$router.push("/Login");
            } 
          })
  },

  

  }
};
</script>

<style scoped>
  .margin_align {
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100vh !important;
    background-color: #f5fffb !important;
  }

  .Payment-Successful {
    margin: 0;
    font-family: Ubuntu;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .description {
    margin-top:2vh;
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .description1 {
    margin-top:2vh;
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .Path-703 {
    width: 8vw;
    height: 5vh;
    padding: 2vh;
    background-color: #03a6ad !important;
    color: #fff;
    border-radius: 5px;
    margin-left:2vh;
  }

  .Rectangle-1203 {
    margin-top: 5vh;
    padding: 3vh;
    border-radius: 31px;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #707070;
    background-color: #fff;
  }

  .Purchase-Details {
    margin: 2vh 2vh 2vh 0vh;
    font-family: Ubuntu;
    font-size: 2.4vh;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .Rectangle-1204 {
    margin: 2vh;
    padding: 1vh;
    border: solid 1px #b1b1b1;
    background-color: #fff;
    box-shadow: none !important;
  }

  .disease_name {
    font-family: Ubuntu;
    font-size: 2.1vh;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c48;
  }

  .Path-359_1 {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    
    background-color: #fff;
   
  }

  .first_sec {
    height:110vh;
    padding: 10px 40px 10px 10px;
    margin-bottom:5vh;
    background-color: #013d48;
  }
  .Ellipse-297 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #f16753;
    float: right;
    margin-top:6px;
  }
  .Ellipse-297_2 {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #03a6ad;
    float: right;
    margin-top:6px;
    margin-right:2vh;
  }
  .Ellipse-298 {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #03a6ad;
    float: right;
    margin-top:6px;
    margin-right:2vh;
  }
  .step1 {
    margin-left: 10px;
    font-family: Ubuntu;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #fff;
    float: right;
  }
  .new_login {
    font-family: Ubuntu;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    color: #03a6ad;
    text-align: right !important;
    float:right;
  }
  .new_login_11 {
    font-family: Ubuntu;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    color: #03a6ad;
    text-align: right !important;
    float:right;
  }
  .Line-291 {
    margin-top: 3vh;
    /* margin-left: 200px !important;
    margin-right:-40px !important; */
    margin-right:-40px;
    width: 120% !important;
    height: 3px !important;
    background-color: #f16753;
    float: right;
  }
  .Line-291_0 {
    margin-top: 3vh;
    /* margin-left: 200px !important;
    margin-right:-40px !important; */
    margin-right:-40px;
    width: 120% !important;
    height: 3px !important;
    background-color: #03a6ad;
    float: right;
  }
  .Line-291_26 {
    margin-top: 3vh;
    /* margin-left: 200px !important;
    margin-right:-38px !important; */
    margin-right:-40px;
    width: 120% !important;
    height: 3px !important;
    background-color: #03a6ad;
    float: right;
  }
  .Line-291_22 {
    margin-top: 3vh;
    /* margin-left: 200px !important;
    margin-right:-112px !important; */
    margin-right:-40px;
    width: 120% !important;
    height: 3px !important;
    background-color: #03a6ad;
    float: right;
  }
  .Line-291_3 {
    margin-top: 3vh;
    /* margin-left: 200px !important;
    margin-right:-38px !important; */
    margin-right:-40px;
    width: 120% !important;
    height: 3px !important;
    background-color: #f16753;
    float: right;
  }
  .Line-291_33 {
    margin-top: 3vh;
    /* margin-left: 200px !important;
    margin-right:-112px !important; */
    margin-right:-40px;
    width: 120% !important;
    height: 3px !important;
    background-color: #f16753;
    float: right;
  }
  .step2 {
    margin-left: 10px;
    font-family: Ubuntu;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #939393;
    float: right;
  }
  .payment {
    font-family: Ubuntu;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    color: #03a6ad;
    text-align: right !important;
    float:right;
  }
  .if-you {
    margin: 0px 0px 0px 0px !important;
    font-family: Ubuntu;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .Path-820 {
    margin-top:10px;
    padding: 10px 20px 10px 20px;
    background-color: #03a6ad !important;
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color:#ffffff;
    border-radius: 5px !important;
  }
  .welcome {
    font-family: Ubuntu;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #202527;
  }
  .Email_input {
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #49494d;
  }
  .try_again_msg{
    margin: 0px 0px 0px 0px !important;
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left !important;
    color: #03a6ad;
  }
  .Path-822 {
    margin-top: 10px;
    padding: 10px 20px 10px 20px;
    border: solid 1px #f16753;
    background-color: #fff;
    color: #f16753;
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left !important;
  }
  .path_1145 {
    width:33vw;
    margin-top: 2vh;
    padding: 20px;
    background-color: #f5f7f9;
    border-radius: 5px;
  }
  .Confirmation {
    margin-top:-30px;
    margin-left: 5vh;
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #202527;
  }
  .We-have {
    margin: 10px;
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #676772;
  }
  .otpRESEND {
    margin-left: 10px;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #f16753;
    cursor: pointer;
  }
  .step3 {
    margin-left: 10px;
    font-family: Ubuntu;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #03a6ad;
    float: right;
  }

  .Rectangle-1151 {
    margin: 10px 0px 10px 0px;
    padding: 20px 30px 20px 30px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
  }
  .Plan_heading {
    font-family: Ubuntu;
    font-size: 3.5vh;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: left;
    color: #005290;
  }
  .plan_details {
    margin: 2vh 0vh 0vh 2vh;
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #909090;
  }
  .trans_Rectangle-1151 {
    margin: 10px;
    padding: 20px 20px 20px 30px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #03a6ad;
    height:80vh !important;
    overflow-y: auto;
  }

  .trans_Rectangle-11513333 {
    margin: 10px;
    padding: 20px 20px 20px 30px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #03a6ad;
  }
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color:#fff; /* Adjust the transparency as needed */
    z-index: 1000; /* Ensure it's above other content */
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>

