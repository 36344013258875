<template>
    <div class="margin_align" :style="isTabletMob?'overflow-y: hidden;height: 100%;position:fixed;':'height:120vh'" >
  <div>
    <v-row v-if="!formFilled" style="margin-top:11vh;" >
      <v-col v-if="!isTabletMob" col="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
       <v-col v-if="!isTabletMob" col="12" sm="6" md="6" lg="6" xs="6" xl="6">
          <v-card class="Rectangle-1151" style="overflow-y:hidden" >
            <v-row>
              <v-col>
                <div class="banner-container">
                  <img src="@/assets/images/banner.jpg" alt="Newsletter Banner" class="banner-image">
                  <div class="banner-text">
                      <h1 style="margin: 0;" class="mycart">Unsubscribe me from your email list</h1>
                  </div>
              </div>
                
              </v-col>
            </v-row>
            <v-row style="margin-top:0" >
              <v-col>
                <span style="font-family: Ubuntu;
                font-size: 15px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                color:#676772
              
                " >
                Please provide your email address below to confirm your unsubscription. By clicking the "Unsubscribe" button, you'll no longer receive our emails.
              </span>
              </v-col>
            </v-row>
            <v-row class="row_style">
              <v-col>
              <v-form id="form">
                <!-- create name field with label name and textbox with validation -->
                <label for="name">Name<span style="color:red">*</span></label>
                <v-text-field
                v-model="user_data.Description.Name"
                  required
                  outlined
                  placeholder="Enter your name"
                  :rules="[
                    v => !!v || 'Name is required',
                    v => /^[a-zA-Z ]+$/.test(v) || 'Name must contain only alphabetic characters.',
                  ]"
                ></v-text-field>
                <!-- create name field with label email address and textbox with validation -->
                <label for="email">Email Address<span style="color:red">*</span></label>
                <v-text-field
                v-model="user_data.Description.Email"
                  required
                  outlined
                  placeholder="Enter your email address"
                  :rules="[
                    v => !!v || 'Email Address is required',
                    v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
                  ]"
                ></v-text-field>
                <!-- create name field with label description and textbox with validation -->
                <label for="description">Please describe the issue you are experiencing<span style="color:red">*</span></label>
                <!-- create choose one drop down with resons as options for unsubcription-->
                <v-select
                  v-model="user_data.Name"
                  :items="['Content is no longer relevant', 'Too may emails', 'Not interested']"
                  label="Reasons"
                  outlined
                  placeholder="Choose one"
                  
                ></v-select>
                <v-row>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4">
                    <v-btn :class="{ disabled: isDisabled }" :disabled="isDisabled" block color="#009ea5"    
                    style="margin-top:2vh;background-color: #009ea5; color: #ffffff;font-size: 2.1vh;letter-spacing: 0.2px;" @click="getData(user_data)">
                        Unsubscribe
                  </v-btn>
                  </v-col>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                </v-row>
              </v-form>
            </v-col>
            </v-row>
          </v-card>

       </v-col>
       <v-col v-if="isTabletMob" col="12"  style="padding:3vh 4vh 1vh 4vh">
          <v-card class="Rectangle-1151" style="overflow-y:hidden;padding:2vh ;" >
            <v-row>
              <v-col>
                <div class="banner-container">
                  <img src="@/assets/images/banner.jpg" alt="Newsletter Banner" class="banner-image">
                  <div class="banner-text">
                      <h1 style="margin: 0;" class="mycart">Unsubscribe me from your email list</h1>
                  </div>
              </div>
                
              </v-col>
            </v-row>
            <v-row style="margin-top:0" >
              <v-col>
                <span style="font-family: Ubuntu;
                font-size: 15px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                color:#676772
              
                " >
                Please provide your email address below to confirm your unsubscription. By clicking the "Unsubscribe" button, you'll no longer receive our emails.
              </span>
              </v-col>
            </v-row>
            <v-row  style="height:50vh;
            overflow-y:scroll !important;">
              <v-col>
              <v-form id="form">
                <!-- create name field with label name and textbox with validation -->
                <label for="name">Name<span style="color:red">*</span></label>
                <v-text-field
                v-model="user_data.Description.Name"
                  required
                  outlined
                  placeholder="Enter your name"
                  :rules="[
                    v => !!v || 'Name is required',
                    v => /^[a-zA-Z ]+$/.test(v) || 'Name must contain only alphabetic characters.',
                  ]"
                ></v-text-field>
                <!-- create name field with label email address and textbox with validation -->
                <label for="email">Email Address<span style="color:red">*</span></label>
                <v-text-field
                v-model="user_data.Description.Email"
                  required
                  outlined
                  placeholder="Enter your email address"
                  :rules="[
                    v => !!v || 'Email Address is required',
                    v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
                  ]"
                ></v-text-field>
                <!-- create name field with label description and textbox with validation -->
                <label for="description">Please describe the issue you are experiencing<span style="color:red">*</span></label>
                <!-- create choose one drop down with resons as options for unsubcription-->
                <v-select
                  v-model="user_data.Name"
                  :items="['Content is no longer relevant', 'Too may emails', 'Not interested']"
                  label="Reasons"
                  outlined
                  placeholder="Choose one"
                  hide-details
                  
                ></v-select>
                <v-row>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4">
                    <v-btn :class="{ disabled: isDisabled }" :disabled="isDisabled" block color="#009ea5"    
                    style="margin-top:2vh;background-color: #009ea5; color: #ffffff;font-size: 2.1vh;letter-spacing: 0.2px;" @click="getData(user_data)">
                        Unsubscribe
                  </v-btn>
                  </v-col>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                </v-row>
              </v-form>
            </v-col>
            </v-row>
          </v-card>

       </v-col>
    
       
       <v-col  v-if="!isTabletMob" col="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
    </v-row>
    <v-row v-if="formFilled && !isTabletMob" style="margin-top:20%;">
      <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
      <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4" >
     
        <v-card class="Path-359_1" style="padding:3vh;">
          <v-row>
           
            <v-col cols=12 align="center" >
              
              <span  style="font-family: Ubuntu;font-size:16px">
                 You'll no longer receive our emails.
              </span>
            </v-col>
            <!-- <v-col cols=12 xs="1" md="1" xl="1" sm="1" align="center" >

              <v-btn
                icon
                color="#009ea5"
                @click="formFilled=false"
                style="margin-top:-3vh"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col> -->
          </v-row>
          
        </v-card>
    </v-col>
    <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
    </v-row>
    
    <v-row v-if="formFilled && isTabletMob" style="margin-top:30%;padding:4vh">
   
      <v-col col="12" >
     
        <v-card class="Path-359_1" style="padding:3vh;">
          <v-row>
           
            <v-col cols=12  >
              
              <span  style="font-family: Ubuntu;font-size:16px">
                 You'll no longer receive our emails.
              </span>
            </v-col>
            <!-- <v-col cols=12 xs="1" md="1" xl="1" sm="1" align="center" >

              <v-btn
                icon
                color="#009ea5"
                @click="formFilled=false"
                style="margin-top:-3vh"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col> -->
          </v-row>
          
        </v-card>
    </v-col>
    
    </v-row>
    
      <!-- </v-card> -->
      
    </div>
  </div> 
</template>

<script>
import axios from "axios";
import fs from 'fs';


const accessToken = '1/1203670818179728:f673e96226d68fa215a19364f41064e4';
const workspaceId = '1203019045083878';
const projectId = '1205314795080252';

export default {
  data() {
    return {
      user_data:{
        Description:{
          Name:'',
          Email:'',
          
          
        },
        Name:'',
       
        
      },
      uploadFiles: [],
      Files:[],
      email:'',
      auth_token:'',
      selected_file:null,
      formFilled:false,

    };
  },
  computed: {
    isTabletMob : function() {

      const iPad = /iPad/i.test(navigator.userAgent);
      const iPhone = /iPhone/i.test(navigator.userAgent);
      const Android = /Android/i.test(navigator.userAgent);
      const webOS = /webOS/i.test(navigator.userAgent);
      const iPod = /iPod/i.test(navigator.userAgent);
      const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
      const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);

      return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
    },
    isDisabled() {
   //check if user_data all fields are filled or not
      if (this.user_data.Description.Name !== "" && this.user_data.Description.Email !== "" && this.user_data.Name !== "" ) {
        return false;
      } else {
        return true;
      }
     
    },
  },
  created() {
  },
  mounted() {
    var auth_token = localStorage.getItem("authtoken");
    // if ( auth_token !== null && auth_token !== "" && auth_token !== undefined ) {
    //   this.auth_token = auth_token
      // this.getEmail();
      // this.getdiseases(this.auth_token);
      // this.getpositions(this.auth_token);
      // this.getcompanies(this.auth_token);
    // } else {
    //   this.auth_token = ""
   
    // }

   
  },
  methods: {
    
    getData(data){
      var data=data
      console.log(data)
      this.formFilled=true
      document.getElementById("form").reset();
     
      //convert data.Description to  pure string
      var description=JSON.stringify(data.Description)
      //remove curly braces and "" from string 
     
   
  
          description=description.replace(/['"]+/g, '')
          description=description.replace(/[\[\]']+/g, '')
          description=description.replace(/[\n\r]+/g, '')
          //remove { and } from string
          description=description.replace("{", "")
          description=description.replace("}", "")
          //add new line after , in string
          description=description.replace(/,/g, '\n')
          console.log(description)
     
      
      var taskData={
        data:{
          "workspace": workspaceId,
          "name":data.Name  ,
          "notes":  description,
          "projects": [projectId]
        }
      }

      
      axios.post('https://app.asana.com/api/1.0/tasks', taskData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then(async response => {
        const task = response.data.data; // Task data object from the response
        const taskId = task.gid; // Get the task ID using "gid" property
        // Upload attachment
        console.log(taskId,"taskid")
         // Create FormData for file upload
      const formData = new FormData();
     
      formData.append('file', this.selected_file);
      formData.append('name', 'Attachment Name'); // Additional data
      console.log(formData,"formdata")
    
      if (this.selected_file!=null){
      try {
        // Upload the file to Asana task
        await axios.post(`https://app.asana.com/api/1.0/tasks/${taskId}/attachments`, formData, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data' // Set the content type for FormData
          }
        });

        console.log('Attachment uploaded successfully.');
       
       
      } catch (error) {
        console.error('Error uploading attachment:', error);
      }
    }
       
      })
      .catch(error => {
        console.error('Error creating task:', error.response.data.errors);
      });
      
         



         // send data.file as attachment to asana
       
         
    },
    handleFileChange(event) {
      this.selected_file = event.target.files[0];
      this.$forceUpdate();
      console.log(this.selected_file,"file")
    },
    goToHome(){
      this.$router.push("/")
     },
      getEmail(){
      //create a get request to get email
      axios.get('/getEmail').then((res) => {
        //console.log(res)
        if (res.data.Status != 1) {
          this.email=res.data.Data.Email
         this.user_data.Description.Email=this.email
        }else{
          console.log("not logged in")
        }
      }).catch((err) => {
        console.log(err)
      })
     },
     send_email() {
          axios
            .post("/send_support_email?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status != 1) {
                console.log("email send successfully")               
              } else {
                console.log("email failed")
              }
          })
        },
   
     
  }
};
</script>


<style lang="scss" scoped>
.margin_align {
  overflow-x: hidden;
  overflow-y: hidden;

  }


  
  .Path-359_support{
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
   
    background-color: #fff;
   
  }
  .Rectangle-1151 {
    border-radius:16px;
    padding: 4vh;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
   
  }
  .mycart {
    margin: 3vh 0vh 2vh 0vh;
    font-family: Ubuntu;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color:#ffffff;
    text-align: center !important;
    
  }
.row_style{
  max-height:56vh;
  overflow-y:scroll !important;
}
.Path-359_1 {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  
  background-color: #fff;
 
}  .banner-container {
  position: relative;
  width: 100%;
  max-height: 120px;
  margin-bottom: 20px;
}
.banner-image {
  width: 100%;
  max-height: 90px;
  object-fit: cover;
}
.banner-text {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  text-align: right;
  color: #ffffff;
}
</style>