<template>
  <div class="margin_align" >    
      <div style="overflow-y: auto;">
        <loading
            :active.sync="loading"
            :can-cancel="true"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
            class="loading-overlay"
        ></loading>
      </div>
      <div class="row" v-if="!isTabletMob">
        <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" class="first_sec">
          <div>
          <div>
            <img src="../assets/Icons/Knolense_Dark-014.svg" style="height: 6vh;margin: 5vh 0vh 2vh 17vh !important;cursor:pointer" @click="goBack()">
          </div>
          <div style="margin-top:15vh;margin-bottom:20vh">
            <v-row>          
              <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                <span class="new_login_11">Registration </span><span class="Ellipse-298"></span>
                <div class="Line-291_0"></div>
              </v-col>
            </v-row>
            <v-row style="margin-top:5vh">
              <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                <span class="new_login">Verification</span><span class="Ellipse-298"></span>
                <div class="Line-291_26"></div>
              </v-col>
            </v-row>
            <v-row style="margin-top:5vh">
              <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                <span class="verification">Payment</span><span class="Ellipse-297_2"></span>
                <div class="Line-291_22"></div>
              </v-col>
            </v-row>
          </div>
          <v-row style="bottom:20px;position: fixed;margin-left:4vh;">
            <span class="if-you">If you have an account, Login here</span>
            <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" ></v-col>
            <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" >
              <div><v-btn class="Path-820" @click="go_to_login()">Login</v-btn></div>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" ></v-col>
          </v-row>
        </div>
        <v-row style="bottom:20px;position: fixed;margin-left:4vh;">
          <span class="if-you">If you have an account, Login here</span>
          <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" ></v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" >
            <div><v-btn class="Path-820" @click="go_to_login()">Login</v-btn></div>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" ></v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="8" md="8" lg="8" xs="8" xl="8"> 
        <v-row>
          <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
          <v-col cols="12" sm="8" md="8" lg="8" xs="8" xl="8">
            <div style="margin-top:2vh;margin-bottom:2vh">
              <span class="welcome">Selected Subscription Plan</span>
             
            </div>
          
            <v-row  no-gutters justify="center">
              <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                <v-card class="Rectangle-1151" v-if="!loading">
                  
                  <div >
                    <v-row v-if=" myplan != 'No Plan'  && checkout_err">
                      
                      <v-col cols="12" sm="1" md="1" lg="1" xs="1" xl="1">
                      </v-col>
                      <v-col cols="12" sm="10" md="10" lg="10" xs="10" xl="10">
                        <div style="background-color:rgb(237, 247, 249) !important">
                        <p style="font-size:16px;color: #384348;font-family: Ubuntu;font-weight: 400;text-align: center;padding:10px;">
                          Error while processing your request. Please try again or contact us at support@knolens.com
                        </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row v-else-if="myplan=='Basic Plan'">
                      <v-col col="12" sm="3" md="3" lg="3" xs="3" xl="3">
                        <img src="../assets/images/Group 1339.png" style="width:70px;margin-top:-1vh;margin-left:2vh">
                      </v-col>
                      <v-col col="12" sm="9" md="9" lg="9" xs="9" xl="9">
                        <span class="Plan_heading">
                          Starter Plan
                        </span>                          
                      </v-col>
                      <v-row>  
                        <v-col cols="12">
                          <p class="plan_details">All Diseases Included</p>
                          <p class="plan_details">
                            KnolChat: The starter plan gives you unlimited access to KnolChat, a one of its kind, AI powered research assistant that dramatically simplifies your research by providing a natural language interface to answer your questions. It is built on a continuously updated database containing 1500+ diseases, 12000+ private companies (and 100k+ organizations), 4500+ mechanisms of action, and 2800+ drugs.
                            <br><br>
                            Overview by Indications" Analysis Module: The starter plan gives you unlimited access to the "Overview by Indications" Module that covers the state of Therapy of ~150 indications and growing.
                          </p>
                        </v-col> 
                      </v-row>
                    </v-row>                  
                    <v-row v-if="myplan == 'Basic Plan'" style="background-color: #f5f7f9;border-radius:5px;margin:5vh 2vh 3vh 2vh;padding: 0px 10px 0px 10px;">
                          <v-col col="12" sm="6" md="6" lg="6" xs="6" xl="6">
                            <span style="font-size:1.8vh;font-family:Ubuntu"><span style="font-size:18px;font-weight: 600;">$599</span><br>USD 599/User/Year</span>
                          </v-col> 
                          <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6" align="right" >                       
                            <v-btn style="width:auto" class="Path-820_1" @click="makeStripePayment('Yearly')">Proceed to Payment</v-btn>
                          </v-col>
                    </v-row>

                    <!-- Trial Plan -->
                   
                    <v-row v-if="myplan == 'Trial'" wrap>
                   
                      <v-col col="12" sm="3" md="3" lg="3" xs="3" xl="3">
                        <img src="../assets/images/Group 1337.png" style="width:70px;margin-top:-1vh;margin-left:2vh">
                      </v-col>
                      <v-col col="12" sm="9" md="9" lg="9" xs="9" xl="9">
                        <v-row>
                          <v-col col="12" sm="7" md="7" lg="7" xs="7" xl="7">
                            <span class="Plan_heading">
                              Trial Plan
                            </span>
                          </v-col>  
                        </v-row>
                      </v-col>
                      <v-row>  
                        <v-col cols="12">
                          <p class="plan_details">All Diseases Included</p>
                          <p class="plan_details">
                            Test drive the power of Knolens for free.  You will get access to all the capabilities of the starter plan with no restrictions for 7 days.
                          </p>
                        </v-col> 
                      </v-row>
                    </v-row>                    
                    <v-row v-if="myplan == 'Trial'" style="background-color: #f5f7f9;border-radius:5px;margin:5vh 2vh 3vh 2vh;padding: 0px 10px 0px 10px;">
                      <v-col col="12" sm="7" md="7" lg="7" xs="7" xl="7">
                        <span style="font-size:1.8vh;margin-left:1vh;font-family:Ubuntu">Free for first 7 days</span>
                      </v-col> 
                      <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3" >                       
                        <v-btn class="Path-820" style="width:140px;margin-top:0vh;font-size:1.8vh;" @click="gotohome_page()">Proceed</v-btn>
                      </v-col>
                    </v-row>
                  </div>
                  </v-card> 
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
          </v-row>       
        </v-col> 
      </div>
      <div class="row" v-if="isTabletMob" style="height:100vh;">
        <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12"> 
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                  <div style="background-color:#013d48;margin-top:-4vh">
                    <img src="../assets/Icons/Knolense_Dark-01.svg" style="height: 5vh;margin: 3vh 0vh 2vh 2vh !important;cursor:pointer" @click="goToHome()">
                    <span class="Path-68717 pull-right" @click="go_to_login()">Login</span>
                  </div>
                </v-col>
              </v-row>
              
              <div style="margin:5vh 5vh 0vh 5vh">
                <span class="welcome">Selected Subscription Plan</span>
              </div>
            
              <div style="display: flex; justify-content: center; overflow-y: auto;">
                <div style="flex: 1;">
                  <div class="Rectangle-1151" style="overflow-y: hidden;margin:2vh 4vh;padding:2vh" v-if="!loading">
                    <div v-if="checkout_err">
                      <div style="background-color: rgb(237, 247, 249) !important">
                        <p style="font-size: 2.2vh; color: #384348; font-family: Ubuntu; font-weight: 400; text-align: center; padding: 10px;">
                          Error while processing your request. Please try again or contact us at support@knolens.com
                        </p>
                      </div>
                    </div>
              
                    <!-- Standard Plan -->
                    <div v-if="plan_details.Plan == 'Basic Plan'" style="display: flex; flex-direction:column;margin:0 3vh;align-items:center">
                      <div style="flex: 1 0 0; display: flex; align-items: center;">
                        <img src="../assets/images/Group 1339.png" style="width: 70px; margin-top: -1vh; margin-left: 2vh;">
                      </div>
                      <div style="flex: 3 0 0;">
                        <span class="Plan_heading">Starter Plan</span>
                      </div>
                      <div style="flex: 1 0 0;">
                        <div @scroll="scrolling" style="overflow-y: auto; overflow-x: hidden; max-height: 100vh; height: 30vh;">
                          <p class="plan_details">All Diseases Included</p>
                          <p class="plan_details">
                            KnolChat: The starter plan gives you unlimited access to KnolChat, a one-of-its-kind, AI-powered research assistant that dramatically simplifies your research by providing a natural language interface to answer your questions. It is built on a continuously updated database containing 1500+ diseases, 12000+ private companies (and 100k+ organizations), 4500+ mechanisms of action, and 2800+ drugs.
                            <br><br>
                            Overview by Indications" Analysis Module: The starter plan gives you unlimited access to the "Overview by Indications" Module that covers the state of Therapy of ~150 indications and growing.
                          </p>
                        </div>
                        <div style="display: flex; align-items: center;justify-content:center">
                          <v-tooltip>
                            <template v-slot:activator="{ on }">
                              <div v-on="on" align="center">
                                <img src="../assets/images/scroll.png" style="cursor: pointer;">
                              </div>
                            </template>
                            <span>Scroll</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </div>
              
                    <div v-if="plan_details.Plan == 'Basic Plan'" style="background-color: #f5f7f9; border-radius: 5px; padding: 2vh; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                      <div style="text-align: center;">
                        <span style="font-size: 2vh; font-family: Ubuntu;"><span style="font-size: 18px; font-weight: 600;">$599</span><br>USD 599/User/Year</span>
                      </div>
                      <div>
                        <v-btn class="Path-820_1" @click="makeStripePayment('Yearly')" style="font-size: 2vh;">Proceed to Payment</v-btn>
                      </div>
                    </div>
                    
              
                    <!-- Trial Plan -->
                    <div v-if="myplan == 'Trial'" style="display: flex; flex-direction:column;margin:0 3vh;align-items:center">
                      <div style="flex: 1 0 0; display: flex; align-items: center;">
                        <img src="../assets/images/Group 1337.png" style="width: 70px; margin-top: -1vh; margin-left: 2vh;">
                      </div>
                      <div style="flex: 3 0 0;">
                        <div style="display: flex;">
                          <div style="flex: 1;">
                            <span class="Plan_heading">Trial Plan</span>
                          </div>
                        </div>
                      </div>
                      <div style="flex: 1 0 0;">
                        <div style="flex: 1;">
                          <p class="plan_details">All Diseases Included</p>
                          <p class="plan_details">Test drive the power of Knolens for free. You will get access to all the capabilities of the starter plan with no restrictions for 7 days.</p>
                        </div>
                      </div>
                    </div>
              
                    <div v-if="myplan == 'Trial'" style="background-color: #f5f7f9; border-radius: 5px; padding: 2vh; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                      <div style="flex: 1; align-self: center;">
                        <span style="font-size: 2vh; margin-left: 1vh; font-family: Ubuntu;">Free for first 7 days</span>
                      </div>
                      <div style="flex: 1; align-self: center;">
                        <v-btn class="Path-820" style="width: 140px; margin-top: 2vh; font-size: 1.8vh;" @click="gotohome_page()">Proceed</v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
           
        </v-col>
      </div> 
    </div>
</template>
<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { EventBus } from "../event-bus.js";
export default {
name : "plan_payment",
data() {
  return {
    auth_token: "",
    selected_page:"",
    old_selected_page:"",
    additional_page:"",
    user_plan:"",
    disesase: "",
    loading: true,
    fullPage: true,
    cart_diseases: [],
    user_email: '',
    show_data: false,
    total_amount: 0,
    plan_details : {},
    checkout_err : false, 
    payment_done: false,
   payment_success_status: false,
   myplan:'',
   e1: 3,
   stripe_key:""
    
  };
},
components: {
  Loading,
},
computed: {
  isTabletMob : function() {
      const iPad = /iPad/i.test(navigator.userAgent);
      const iPhone = /iPhone/i.test(navigator.userAgent);
      const Android = /Android/i.test(navigator.userAgent);
      const webOS = /webOS/i.test(navigator.userAgent);
      const iPod = /iPod/i.test(navigator.userAgent);
      const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
      const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);
      return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
  },
},
mounted() {
  //console.log("profile setting")
  
  //listen to event
  // this.get_user_plan()
  // this.get_user_plan_name()
  this.selected_page=localStorage.getItem("selected_article")
  this.get_page_info()
  this.getPaymentReleatedKey()
  var path=this.selected_page
  if(path=="Transaction Success" || path=="Transaction Failure"){

  console.log("path",path)
  this.$router.push("/")
}
  

  EventBus.$on("payment_done", (data) => {
    console.log("payment_done")
    this.payment_done = true
  });

  if (this.payment_done) {
    this.$router.push("/")
  }
  // var disesase = localStorage.getItem("selected_disease")
  // if (disesase !== null && disesase !== "undefined" && disesase !== "") {
  //   this.disesase = disesase
  // } else {
  //   this.disesase = "Multiple sclerosis"
  // }


  var auth_token = localStorage.getItem("authtoken");
  console.log(auth_token,"auth")
  
  if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
    this.auth_token = auth_token
    // this.getPaymentSuccessStatus()
    this.getuser()
  }
},
methods: {
  goBack(){
      this.$router.push("/").catch(() => {})
  },
  onCancel() {
    console.log('User cancelled the loader.')
  },
  getuser() {
    axios
      .get("/user?token=" + this.auth_token)
      .then((res) => {
        if (res.data.Status != 1) {
          this.user_email = res.data.Data.Email
          this.get_wishlist()
          this.getPlanDetails()
        } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
          console.log("Invalid User");
          this.logOut()
        } else {
          console.log("User not found")
        }
    })
    .catch((error) => {
      console.log(error.message);
      if (error.message == "Request failed with status code 500") {
        this.logOut()             
      }
    });
  },
  get_wishlist() {
    // this.loading = true
    axios
      .get("/getuserdiseasecart?token=" + this.auth_token)
      .then((res) => {
        if (res.data.Status != 1) {
          // this.loading = false
          this.cart_diseases = res.data.Data.user_diseases_cart.Diseases
          if (this.cart_diseases.length > 0) {
            this.total_amount = this.cart_diseases.length * 250
            this.show_data = true
          }                
        } else {
          // this.loading = false
          console.log("cart_diseases not found")
        }
    })
    .catch((error) => {
      console.log(error.message);
      // if (error.message == "Request failed with status code 500") {
      //   this.logOut()             
      // }
    });
  },
  getPaymentReleatedKey(){
      axios.post("/getPaymentReleatedKey?token="+this.auth_token)
      .then(response => {
        this.stripe_key=response.data.Data
      })
      .catch(error => {
        console.log(error);
      });
   
    },
  makeStripePayment(duration) {
    // this.save_page_activity("Transaction Page","Plan_payment")
    this.e1=4
    this.loading = true
    this.checkout_err = false
   
    var stripe=Stripe(this.stripe_key)
    var currentUrl= window.location.href
    console.log(currentUrl,"payment url....")
    //check if the current url contains helloworld-hlpc4jxnba-uc.a.run.app
    axios.post(`/create-checkout-session?&token=` + this.auth_token+"&duration="+duration+"&plan="+this.plan_details.Plan)
      .then(res => {
      //console.log(res.data.Data,"res.data.Data")
      if (res.data.Status !== 1) {
        const id = res.data.Data.Id;
        stripe.redirectToCheckout({sessionId: id});
        this.loading = false
      }else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
        console.log("Invalid User")
      }  else {
        this.loading = false
        this.checkout_err = true
        console.log("error in payment redirecting")
      }
    })
  },
  logout() {
    EventBus.$emit("logout", this.auth_token)
    localStorage.removeItem("selectedColorList");
  },
    delete_my_disease(dis) {
        this.loading = true      
        axios
        .put("/deletecartitem?token=" + this.auth_token + "&disease_name=" + dis.Disease_name)
        .then((res) => {
            if (res.data.Status != 1) {
            this.loading = false
            this.cart_diseases = []
            this.total_amount = 0
            this.cart_diseases = res.data.Data.userdiseasescart.Diseases
            if (this.cart_diseases.length > 0) {
                this.total_amount = this.cart_diseases.length * 250
                this.show_data = true
            } else {
                this.total_amount = 0
                this.show_data = false
            }
            //console.log(this.cart_diseases,"this.cart_diseases")
            } else {
            this.loading = false
            console.log("cart not found")
            }
        })
        .catch((error) => {
        console.log(error.message);
        // if (error.message == "Request failed with status code 500") {
        //     this.logOut()             
        // }
        });
    },
    getPlanDetails(){
        this.plan_details = {}
        this.loading = true
        axios
        .get("/getUserPlanDetails?token=" + this.auth_token)
        .then((res) => {
            if (res.data.Status != 1) {
               
                this.plan_details = res.data.Data
                this.myplan=this.plan_details.Plan
                this.show_data = true
                this.loading = false
                this.$forceUpdate()           
            } else {
                this.loading = false
            }
        })
        .catch((error) => {
            console.log(error.message);
            // if (error.message == "Request failed with status code 500"){
            //     this.logOut()             
            // }
        });
    },
    getPaymentSuccessStatus(){
      console.log("getPaymentSuccessStatus")  
      axios
        .get("/getPaymentSuccessStatus?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status !== 1) {
            console.log(res.data,"res.data.Data.Paid")
            this.payment_success_status = res.data.Data.Paid
            
            console.log(this.payment_success_status,"this.payment_success_status")
            if(this.payment_success_status==true){
              this.$router.push("/").catch(() => {})
            }
          } else {
            console.log("Payment Success Status not found")
          }
        })
        .catch((error) => {
          console.log(error.message);
          // if (error.message == "Request failed with status code 500") {
          //   this.logOut()             
          // }
        });
    },
    gotohome_page(){
      this.$router.push("/").catch(() => {})
    },
      get_page_info(){
        var auth_token=localStorage.getItem("authtoken")
      axios
      .get("/fetchCurrentPage?token=" + auth_token)
      .then((res) => {
        if (res.data.Status != 1) {
              // console.log(res.data,"response.data")
              this.selected_page = res.data.Data.Current_Page
              this.old_selected_page=res.data.Data.Previous_Page

                // if(this.old_selected_page=="Register"){
                //   console.log("inside if")
                //   // console.log("path",path)
                //   this.$router.push("/")
                // }
              
              this.$forceUpdate(); 
                
         }else{
              console.log("aaaaaa")
         }
      })
},
get_user_plan(){
    console.log(this.auth_token,"dfgdfg")
     
     //create get request to get user plan
     axios.get('/getUserPlan?token='+this.auth_token).then((res) => {
       //console.log(res)
       if (res.data.Status != 1) {
         console.log(res.data,"user plan")
         if (res.data.Data.Plan==true){
          this.user_plan=true
           console.log("no plan")
           if(this.gotochat==true){
            this.$router.push({ name: "Analytical_Chat" });
           }             
           console.log(this.user_plan,"getttt user plannnnn")
           this.$forceUpdate();
         } else{
          if(this.gotochat==true){
              this.$refs.SubscriptionModal.openPopup();
          }
        }         
       }
     }).catch((err) => {
       console.log(err)
     })

   },
  
save_page_activity(current,previous) {
  //console.log("page activity")
  var disease = ""
  var articleid = ""
  var duration = "0"
  var panel_duration = "0"
  var activity_name = "Page Change"
  var panel_number = "0"
  var pagename = current
  
  axios
    .post("/SaveActivity?id=" + articleid + '&token=' + this.auth_token + "&activity_name=" + activity_name + "&disease=" + disease + "&page_name=" + pagename + "&duration=" + duration + "&panel_duration=" + panel_duration + "&current=" + current + "&previous=" + previous)
    .then((res) => {
      if (res.data.Status != 1) {
        //console.log("Activity saved successfully")
      } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
        this.logout()
      } else {
        console.log("Activity save failed")
      }
  })
  .catch((error) => {
    console.log(error.message);
    if (error.message == "Request failed with status code 500") {
      this.logout()             
    }
  });
},
    go_to_login() {
      console.log("go to login")
      this.$router.push("/Login");
    },
    goToHome() {
      this.$router.push("/").catch(() => {})

      
    },

},
};
</script>
<style scoped>
.wholepage {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  padding-left: 0%;
  padding-right: 2%;
  padding-bottom: 20px;
  padding-top: 0px;
  margin: 0vh 0vh 5vh 0vh !important;
  overflow-y: auto
}

.mycart {
  margin: 3vh 0vh 2vh 0vh;
  font-family: Ubuntu;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center !important;
  color: #32324a;
}

.BACK {
  margin: 2vh 0vh 2vh 0vh;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.43;
  letter-spacing: normal;
  text-align: left;
  color: #03a6ad;
  cursor: pointer;
}
.description {
  margin: 2vh 0vh 2vh 0vh;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.89;
  letter-spacing: normal;
  text-align: left;
  color: #676772;
}
.number {
  margin: 2vh 0vh 2vh 0vh;
  padding: 1vh;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.78;
  letter-spacing: normal;
  text-align: left;
  color: #727272;
}

.Rectangle-1208 {
  margin: 1vh 1.5vh 1vh 1.5vh!important;
  padding: 0.5vh;
  border-radius: 9px !important;
  border: solid 1px #4a4a5c;
  background-color: #fff;
}

.content {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #202527;
}

.Rectangle-1212 {
  margin: 1vh 1vh 1vh 1vh!important;
  padding: 1vh !important;
  border-radius: 9px;
  border: solid 1px #03a6ad;
  background-color: #f7faff;
}

.Ellipse-153 {
  width: 90px;
  height: 90px;
  margin: 2vh;
  padding: 25px;
  border-radius: 90px;
  border: solid 1px #d6d6d6;
  background-color: #f8f8f8;
}

.Rectangle-1203 {
  padding: 3vh !important;
  border-radius: 31px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #707070 !important;
  background-color: #fff;
}
.Best-Suited-for {
  margin: 0vh 0vh 0vh 0vh;
  font-family: Ubuntu;
  font-size: 15px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #515b64;
  text-align: justify;
  text-justify: inter-word;
}

.first_sec {
height:110vh;
padding: 10px 40px 10px 10px;
margin-bottom:5vh;
background-color: #013d48;
}
.Ellipse-297 {
width: 10px;
height: 10px;
border-radius: 50%;
background-color: #f16753;
float: right;
margin-top:6px;
}
.Ellipse-297_2 {
width: 15px;
height: 15px;
border-radius: 50%;
background-color: #f16753;
float: right;
margin-top:6px;
margin-right:2vh;
}
.Ellipse-298 {
width: 15px;
height: 15px;
border-radius: 50%;
background-color: #03a6ad;
float: right;
margin-top:6px;
margin-right:2vh;
}
.step1 {
margin-left: 10px;
font-family: Ubuntu;
font-size: 13px;
font-weight: 300;
font-stretch: normal;
font-style: normal;
line-height: 1;
letter-spacing: normal;
color: #fff;
float: right;
}
.new_login {
font-family: Ubuntu;
font-size: 25px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 1.15;
letter-spacing: normal;
color: #03a6ad;
text-align: right !important;
float:right;
}
.new_login_11 {
font-family: Ubuntu;
font-size: 25px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 1.15;
letter-spacing: normal;
color: #03a6ad;
text-align: right !important;
float:right;
}
.Line-291 {
margin-top: 3vh;
/* margin-left: 200px !important;
margin-right:-40px !important; */
margin-right:-40px;
width: 120% !important;
height: 3px !important;
background-color: #f16753;
float: right;
}
.Line-291_0 {
margin-top: 3vh;
/* margin-left: 200px !important;
margin-right:-40px !important; */
margin-right:-40px;
width: 120% !important;
height: 3px !important;
background-color: #03a6ad;
float: right;
}
.Line-291_26 {
margin-top: 3vh;
/* margin-left: 200px !important;
margin-right:-38px !important; */
margin-right:-40px;
width: 120% !important;
height: 3px !important;
background-color: #03a6ad;
float: right;
}
.Line-291_22 {
margin-top: 3vh;
/* margin-left: 200px !important;
margin-right:-112px !important; */
margin-right:-40px;
width: 120% !important;
height: 3px !important;
background-color: #f16753;
float: right;
}
.Line-291_3 {
margin-top: 3vh;
/* margin-left: 200px !important;
margin-right:-38px !important; */
margin-right:-40px;
width: 120% !important;
height: 3px !important;
background-color: #f16753;
float: right;
}
.Line-291_33 {
margin-top: 3vh;
/* margin-left: 200px !important;
margin-right:-112px !important; */
margin-right:-40px;
width: 120% !important;
height: 3px !important;
background-color: #f16753;
float: right;
}
.step2 {
margin-left: 10px;
font-family: Ubuntu;
font-size: 13px;
font-weight: 300;
font-stretch: normal;
font-style: normal;
line-height: 1;
letter-spacing: normal;
color: #939393;
float: right;
}
.verification {
font-family: Ubuntu;
font-size: 25px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 1.15;
letter-spacing: normal;
color: #f16753;
text-align: right !important;
float:right;
}
.if-you {
margin: 0px 0px 0px 0px !important;
font-family: Ubuntu;
font-size: 2vh;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.67;
letter-spacing: normal;
text-align: center;
color: #fff;
}
.Path-820 {
margin-top:10px;
padding: 10px 20px 10px 20px;
background-color: #03a6ad !important;
font-family: Ubuntu;
font-size: 15px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.67;
letter-spacing: normal;
color:#ffffff;
border-radius: 5px !important;
}

.Path-820_1 {
margin-top:5px;
padding: 10px 30px 10px 30px;
background-color: #03a6ad !important;
font-family: Ubuntu;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: 1.67;
letter-spacing: normal;
color:#ffffff;
border-radius: 5px !important;
font-size:1.7vh;
margin-top:5px
}
.welcome {
font-family: Ubuntu;
font-size: 20px;
font-weight: 300;
font-stretch: normal;
font-style: normal;
line-height: 1.31;
letter-spacing: normal;
text-align: left;
color: #202527;
}
.Email_input {
font-family: Ubuntu;
font-size: 15px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.56;
letter-spacing: normal;
text-align: left;
color: #49494d;
}
.try_again_msg{
margin: 0px 0px 0px 0px !important;
font-family: Ubuntu;
font-size: 16px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.88;
letter-spacing: normal;
text-align: left !important;
color: #03a6ad;
}
.Path-822 {
margin-top: 10px;
padding: 10px 20px 10px 20px;
border: solid 1px #f16753;
background-color: #fff;
color: #f16753;
font-family: Ubuntu;
font-size: 16px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.88;
letter-spacing: normal;
text-align: left !important;
}
.path_1145 {
width:33vw;
margin-top: 2vh;
padding: 20px;
background-color: #f5f7f9;
border-radius: 5px;
}
.Confirmation {
margin-top:-30px;
margin-left: 5vh;
font-family: Ubuntu;
font-size: 15px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 1;
letter-spacing: normal;
text-align: left;
color: #202527;
}
.We-have {
margin: 10px;
font-family: Ubuntu;
font-size: 15px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.71;
letter-spacing: normal;
text-align: left;
color: #676772;
}
.otpRESEND {
margin-left: 10px;
font-family: Ubuntu;
font-size: 14px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 1.71;
letter-spacing: normal;
text-align: left;
color: #f16753;
cursor: pointer;
}
.step3 {
margin-left: 10px;
font-family: Ubuntu;
font-size: 13px;
font-weight: 300;
font-stretch: normal;
font-style: normal;
line-height: 1;
letter-spacing: normal;
color: #03a6ad;
float: right;
}

.Rectangle-1151 {
margin: 10px 0px 10px 0px;
padding: 20px 30px 20px 30px;
box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
background-color: #fff;
max-height:80vh;
overflow-y: auto ;
}
.Plan_heading {
font-family: Ubuntu;
font-size: 3.5vh;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.32;
letter-spacing: normal;
text-align: left;
color: #005290;
}
.plan_details {
margin: 2vh 0vh 0vh 2vh;
font-family: Ubuntu;
font-size: 16px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.56;
letter-spacing: normal;
text-align: left;
color: #909090;
}

.Path-68717 {
padding: 5px 20px 5px 20px;
background-color: #d04d3b;
border-top-left-radius: 7px;
border-bottom-left-radius: 7px;
border-top-right-radius: 7px;
border-bottom-right-radius: 7px;
font-size: 2vh;
float:right !important;
font-family: Ubuntu;
cursor:pointer;
color:#ffffff;
margin:2vh
}
.margin_align {
  overflow-x: hidden;
  overflow-y: hidden ;
  height: 100vh !important;
 
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color:#fff; /* Adjust the transparency as needed */
  z-index: 1000; /* Ensure it's above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color:#fff; /* Adjust the transparency as needed */
  z-index: 1000; /* Ensure it's above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
      