<template>
  <v-main>
    <div style="margin: 10vh 0vh 0vh 0vh; font-family: Ubuntu">
      <div id="gist">
        <div
          class="margin_align"
          :style="
            isTabletMob
              ? 'height:90vh !important'
              : 'overflow:hidden;height:90vh !important'
          "
        >
          <loading
            :active="loading"
            :can-cancel="true"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
          ></loading>
          <div
            :class="expanded ? 'leftPanelExpand' : 'left'"
            :style="!isTabletMob ? 'height:90vh !important' : ''"
          >
            <!-- <div class="margin_align"> -->
            <div>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                  lg="12"
                  sm="12"
                  xs="12"
                  style="padding: 0"
                >
                  <v-row>
                    <v-col cols="12" :align="!isTabletMob ? '' : 'center'">
                      <span
                        :class="
                          !isTabletMob
                            ? 'Development-Pipeline'
                            : 'Development-Pipeline_111'
                        "
                      >
                        Add New Analysis Mechanism of Action
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                :style="
                  !isTabletMob
                    ? 'padding:0 30px 0 40px;margin-top:20px'
                    : 'margin-top:3vh;margin-bottom:0vh;margin-left:0vh;padding:0vh 0vh 0vh 1vh'
                "
              >
                <!-- <v-col cols="12" align="center">-->
                <v-tabs id="tab" :v-model="activeTab">
                  <v-tabs-slider color="#009ea5"></v-tabs-slider>
                  <v-tab
                    id="mechanismodule"
                    :value="1"
                    @click="changeView('1')"
                  >
                    <span id="mechmod1" class="Mechanisms-0">
                      Mechanisms ({{ mechanismModules.length }})
                    </span>
                  </v-tab>
                  <!-- <v-tab :value='2'  @click="changeView('2') ">
                                    <span id="mechmod2">
                                        Events (0)
                                    </span>
                                </v-tab> -->
                  <!-- <v-tab id="setting" :value='3' @click="changeView('3')" >
                                    <span id="mechmod3" class="Settings">
                                      Settings
                                    </span>
                                </v-tab> -->
                </v-tabs>
                <v-divider
                  :style="
                    !isTabletMob
                      ? 'background-color:#7a7a83;margin-left:10px;margin-right:5px'
                      : 'background-color:#7a7a83;margin-left:10px;margin-right:20px'
                  "
                ></v-divider>
              </v-row>
            </div>
            <div v-if="activeTab == '1'">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: flex-end;
                  padding: 1vh 0;
                "
              >
                <div style="flex: 1">
                  <v-text-field
                    v-if="mechanismModules.length > 0"
                    style="
                      font-weight: 300;
                      font-size: 12px;
                      font-family: Ubuntu;
                      color: #fff;
                      margin-top: -10px;
                    "
                    :style="
                      !isTabletMob ? 'width: 20vh;padding-left:2.5vh' : ''
                    "
                    rows="1"
                    v-model="searchmech"
                    hide-details
                    class="mx-5 mb-3 shrink"
                    placeholder="Search"
                  >
                    <template v-slot:append>
                      <img
                        src="../assets/Icons/Path 1009.svg"
                        alt="user"
                        style="height: 2vh; width: 2vh; margin-top: 1vh"
                      />
                    </template>
                  </v-text-field>
                </div>

                <div>
                  <v-btn
                    :class="
                      !isTabletMob
                        ? 'add_mechanism_btn'
                        : 'add_mechanism_btn_111'
                    "
                    @click="AddMechanism()"
                  >
                    Add Mechanism of Action
                  </v-btn>
                </div>
              </div>

              <div v-if="mechanismModules.length == 0 && !loading">
                <v-row>
                  <v-col
                    align="center"
                    style="margin-top: 20vh; font-size: 28px; color: #676772"
                  >
                    <div style="font-size: 3vh">
                      You have not added any mechanism.
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div v-else-if="newFilteredList.length == 0 && !loading">
                <v-row>
                  <v-col
                    align="center"
                    style="margin-top: 20vh; font-size: 28px; color: #676772"
                  >
                    <div style="font-size: 3vh">No match found.</div>
                  </v-col>
                </v-row>
              </div>
              <div v-else style="margin-bottom: 7vh">
                <v-row
                  :style="
                    !isTabletMob
                      ? 'max-height:65vh;overflow-y:auto;margin: 0vh 2vh 3vh 3vh'
                      : 'max-height:50vh;overflow-y:auto;margin: 0vh 0vh 2vh 0vh;'
                  "
                >
                  <v-col
                    :cols="!isTabletMob ? '6' : '12'"
                    v-for="(i, km) in newFilteredList"
                    :key="km"
                  >
                    <v-card class="Path-704">
                      <v-row wrap>
                        <v-col cols="12" md="10" lg="10" sm="10" xs="10">
                          <span class="Mechanism">
                            {{ i.Input_value }}
                          </span>
                        </v-col>
                        <v-col cols="12" md="2" lg="2" sm="2" xs="2">
                          <v-btn
                            class="open_btn"
                            v-if="i.Status == 3"
                            @click="
                              visitMechanismModule(i.Input_id, i.Input_value)
                            "
                            >Open</v-btn
                          >
                          <span
                            style="
                              color: #626262;
                              font-family: Ubuntu;
                              font-size: 1.5vh;
                            "
                            align="right"
                            v-else-if="i.Status == 0 || i.Status == 2"
                          >
                            Processing...
                          </span>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div v-if="activeTab == '2'">
              <Events></Events>
            </div>
            <div v-if="activeTab == '3'">
              <Settings></Settings>
            </div>
            <!-- </div> -->
          </div>

          <div
            :class="
              !isTabletMob ? 'right notes_section' : 'right_111 notes_section'
            "
            :style="!isTabletMob ? 'height:93vh !important' : ''"
            v-if="!expanded && panels_show"
          >
            <v-row
              :style="
                !isTabletMob
                  ? 'padding:40px 40px 0 40px'
                  : 'padding:40px 0px 0px 20px'
              "
            >
              <v-col :col="!isTabletMob ? '6' : '12'">
                <span class="Add-Mechanism" v-if="!configure">
                  Add Mechanims
                </span>
                <span class="Add-Mechanism" v-if="configure">
                  Selected Mechanisms
                </span>
                <span v-if="isTabletMob">
                  <span v-if="!configure" align="right">
                    <!-- <img src="../assets/Icons/close.png"  style="height: 3vh; width: 5vh;float:right" @click="close_mechanisms_area()"/> -->
                    <img
                      src="../assets/Icons/Group 1354.svg"
                      style="
                        float: right;
                        height: 4vh;
                        width: 10vh;
                        cursor: pointer;
                      "
                      @click="close_mechanisms_area()"
                    />
                  </span>
                  <span v-if="configure">
                    <!-- <img src="../assets/Icons/close.png"  style="height: 3.8vh; width: 5vh;float:right;margin-right:2vh" @click="close_mechanism_selected_area()"/> -->
                    <img
                      src="../assets/Icons/Group 1354.svg"
                      style="
                        float: right;
                        height: 4vh;
                        width: 10vh;
                        cursor: pointer;
                      "
                      @click="close_mechanism_selected_area()"
                    />
                  </span>
                </span>
              </v-col>
              <v-col
                v-if="!isTabletMob"
                align="right"
                cols="12"
                md="6"
                lg="6"
                sm="6"
                xs="6"
              >
                <span v-if="!configure">
                  <img
                    src="../assets/Icons/Group 1354.svg"
                    style="height: 4vh; width: 10vh; cursor: pointer"
                    @click="close_mechanisms_area()"
                  />
                </span>
                <span v-if="configure">
                  <img
                    src="../assets/Icons/Group 1354.svg"
                    style="height: 4vh; width: 10vh; cursor: pointer"
                    @click="configure = false"
                  />
                </span>
              </v-col>
            </v-row>
            <v-row style="padding: 0 40px 0px 40px">
              <v-divider style="background-color: #7a7a83"></v-divider>
            </v-row>
            <div
              :style="
                !isTabletMob
                  ? 'margin: 2vh 2vh 2vh 6vh;'
                  : 'margin: 0vh 2vh 2vh 2vh;text-align:center'
              "
            >
              <span class="select-mechanism" v-if="!configure">
                Please search and select mechanism add mechanism to your module.
              </span>
            </div>
            <v-row
              :style="
                !isTabletMob
                  ? 'margin: 2vh 8vh -3vh 5vh !important'
                  : 'margin: 0vh 4vh 0vh 4vh !important'
              "
              v-if="!configure"
            >
              <v-text-field
                style="
                  width: 120vh;
                  height: 9vh;
                  min-width: 20vh;
                  font-weight: 300;
                  font-size: 14px;
                  font-family: Ubuntu;
                  color: #fff;
                "
                hide-details
                v-model="searchme"
                class="mx-2 mb-3 shrink"
                placeholder="Search modules by name, category or functionality"
                solo
                v-on:keyup="onEnter"
              >
                <template v-slot:append>
                  <img
                    @click="getMechanimsList(1)"
                    src="../assets/Icons/search.png"
                    alt="user"
                    style="
                      height: 22px;
                      width: 26px;
                      margin-right: 5px;
                      cursor: pointer;
                    "
                  />
                </template>
              </v-text-field>
            </v-row>
            <v-row style="margin: 0vh 4vh 0vh 4vh">
              <v-col
                align="left"
                :cols="!isTabletMob ? '9' : '12'"
                v-if="!configure && !isTabletMob"
              >
                <span class="related-mechanisms">
                  These are related mechanisms from our database. Select the
                  ones you want to include.
                </span>
              </v-col>
              <!-- <v-col align="center" :cols="!isTabletMob ? '3' : '12'">
                <v-btn v-if="finalMechanisms.length!=0 && !configure"
                style="background-color:#009ea5; color: #ffffff;font-size: 12px;height: 30px;border-radius: 4px;box-shadow: none;border: solid 2px #03a6ad;width:80px;margin-bottom: 2vh;"
                @click=" SelectAndConfigure()" >Next</v-btn> 
              </v-col> -->
            </v-row>

            <v-row style="margin: 0vh 4vh 0vh 4vh">
              <v-col
                align="left"
                :cols="!isTabletMob ? '9' : '12'"
                v-if="configure"
              >
                <span class="related-mechanisms">
                  Create Modules for following Mechanisms
                </span>
              </v-col>
              <v-col
                align="center"
                :cols="!isTabletMob ? '3' : '12'"
                v-if="configure"
              >
                <v-btn
                  style="
                    background-color: #009ea5;
                    color: #ffff;
                    font-size: 12px;
                    height: 30px;
                    border-radius: 4px;
                    box-shadow: none;
                    border: solid 2px #03a6ad;
                    width: 120px;
                    margin-bottom: 2vh;
                  "
                  @click="createModule(mechanismForm)"
                  >Start Processing</v-btn
                >
              </v-col>
            </v-row>
            <div
              :style="
                configure == false
                  ? 'height:48vh; overflow-y:scroll; overflow-x:hidden'
                  : 'height:60vh; overflow-y:scroll; overflow-x:hidden'
              "
            >
              <div
                v-if="mechanismsLoading"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                  overflow: hidden;
                "
              >
                <!-- add loader-->
                <v-progress-circular
                  indeterminate
                  color="teal"
                ></v-progress-circular>
              </div>
              <div
                v-if="
                  !mechanismsLoading && mechanisms.length == 0 && !configure
                "
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                  overflow: hidden;
                "
              >
                <span
                  style="font-family: Ubuntu; font-size: 18px; color: #676772"
                  >No mechanisms found</span
                >
              </div>
              <!-- add mechanism -->
              <v-row
                v-if="!configure && !mechanismsLoading"
                :style="
                  !isTabletMob ? 'padding-bottom:1vh' : 'padding-bottom:32vh'
                "
              >
                <div>
                  <v-row
                    :style="
                      !isTabletMob
                        ? 'margin: 0vh 8vh 0vh 6vh'
                        : 'margin: 0vh 5vh 0vh 5vh'
                    "
                    v-for="(i, kf) in filteredList"
                    :key="kf"
                  >
                    <div>
                      <v-tooltip
                        v-if="!isTabletMob"
                        bottom
                        color="white"
                        transition="fade-transition"
                        open-delay="300"
                      >
                        <template v-slot:activator="{ on }">
                          <div v-on="on" class="Path-731">
                            <v-row
                              v-if="!isTabletMob"
                              style="padding: 0 40px 0 0px"
                              @click="checkedMechanism(i)"
                            >
                              <v-col
                                align="left"
                                cols="12"
                                md="1"
                                lg="1"
                                sm="1"
                                xs="1"
                              >
                                <img
                                  src="../assets/Icons/Group 992 disabled.svg"
                                  style="
                                    width: 20px;
                                    height: 32px;
                                    margin-left: 2vh;
                                  "
                                  v-if="i.Present == true"
                                />
                                <img
                                  src="../assets/Icons/Group 992.svg"
                                  style="
                                    width: 20px;
                                    height: 32px;
                                    margin-left: 2vh;
                                    cursor: pointer;
                                  "
                                  v-else-if="i.Status == 'Selected'"
                                />
                                <span
                                  class="circle"
                                  style="cursor: pointer"
                                  v-else-if="i.Status == 'Not-selected'"
                                ></span>
                              </v-col>
                              <v-col
                                align="left"
                                cols="12"
                                md="11"
                                lg="11"
                                sm="11"
                                xs="11"
                                style="padding-top: 15px"
                              >
                                <span
                                  style="
                                    font-family: Ubuntu;
                                    font-size: 1.8vh;
                                    font-weight: normal;
                                    font-stretch: normal;
                                    font-style: normal;
                                    color: #2c2c48;
                                  "
                                  >{{ i.Mechanism }}</span
                                >
                              </v-col>
                            </v-row>
                          </div>
                        </template>
                        <v-list
                          :class="
                            !isTabletMob ? 'list_display' : 'list_display_111'
                          "
                        >
                          <v-list-item
                            v-for="(item, index) in i.Alias"
                            :key="index"
                          >
                            <v-list-item-title
                              style="word-break: break-all; font-family: Ubuntu"
                              >{{ item }}</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-tooltip>
                      <v-menu left offset-y>
                        <template v-slot:activator="{ on }">
                          <div v-if="isTabletMob" class="Path-731">
                            <v-row
                              wrap
                              v-if="isTabletMob"
                              @click="checkedMechanism(i)"
                              style="padding: 0px 0px 10px 0px"
                            >
                              <v-col cols="2">
                                <span>
                                  <img
                                    src="../assets/Icons/Group 992.svg"
                                    style="
                                      width: 20px;
                                      height: 32px;
                                      margin-left: 2vh;
                                    "
                                    v-show="i.Status == 'Selected'"
                                  />
                                  <span
                                    class="circle"
                                    v-show="i.Status == 'Not-selected'"
                                  ></span>
                                </span>
                              </v-col>
                              <v-col
                                cols="8"
                                style="
                                  margin-left: 0vh;
                                  margin-top: 0vh !important;
                                "
                              >
                                <span>
                                  <span
                                    style="
                                      font-family: Ubuntu;
                                      margin-left: -1vh;
                                      font-size: 1.8vh;
                                      font-weight: normal;
                                      font-stretch: normal;
                                      font-style: normal;
                                      color: #2c2c48;
                                      word-break: break-all;
                                    "
                                    >{{ i.Mechanism }}</span
                                  >
                                </span>
                              </v-col>
                              <v-col v-on="on" cols="2">
                                <span
                                  class="fa fa-info-circle"
                                  style="
                                    font-size: 17px;
                                    color: #009ea5;
                                    margin-left: 0vh;
                                    margin-top: 1vh;
                                  "
                                  align="right"
                                >
                                </span>
                              </v-col>
                            </v-row>
                          </div>
                        </template>
                        <v-list
                          class="list_display_111"
                          style="max-height: 30vh"
                        >
                          <div
                            align="right"
                            style="margin-right: 4vh; position: relative"
                          >
                            <v-icon
                              style="
                                color: #00b2ba;
                                font-size: 20px;
                                position: fixed;
                                cursor: pointer;
                              "
                              >mdi-close</v-icon
                            >
                          </div>
                          <v-list-item
                            v-for="(item, index) in i.Alias"
                            :key="index"
                          >
                            <div style="font-family: Ubuntu; max-width: 100%">
                              {{ item }}
                            </div>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-row>
                </div>
              </v-row>

              <v-row v-show="configure">
                <div>
                  <!-- list finalMechanisms List -->
                  <div style="max-height: 65vh; overflow-y: scroll">
                    <v-row
                      :style="
                        !isTabletMob
                          ? 'padding:0 80px 0 50px'
                          : 'padding: 0vh 4vh 0vh 4vh'
                      "
                      v-for="(i, kf) in finalMechanisms"
                      :key="kf"
                    >
                      <div class="Path-731">
                        <v-row
                          v-if="!isTabletMob"
                          style="padding: 0 40px 0 40px"
                        >
                          <v-col
                            align="left"
                            cols="12"
                            md="11"
                            lg="11"
                            sm="11"
                            xs="11"
                            style="padding-top: 15px"
                          >
                            <span
                              style="
                                text-align: left;
                                font-size: 16px;
                                margin: 19.5px 18.8px 15.4px 0;
                              "
                              >{{ i }}</span
                            >
                          </v-col>
                          <v-col
                            align="right"
                            cols="12"
                            md="1"
                            lg="1"
                            sm="1"
                            xs="1"
                            style="padding-top: 2vh"
                          >
                            <!-- close icon -->
                            <v-icon
                              class="close_btn"
                              @click="removeMechanism(i)"
                              style="
                                color: #00b2ba;
                                font-size: 20px;
                                cursor: pointer;
                              "
                              >mdi-close</v-icon
                            >
                          </v-col>
                        </v-row>
                        <v-row wrap v-if="isTabletMob" justify="center">
                          <v-col align="left">
                            <span style="width: 80%; float: left">
                              <span style="text-align: left; font-size: 16px">{{
                                i
                              }}</span>
                            </span>
                            <v-icon
                              class="close_btn"
                              @click="removeMechanism(i)"
                              style="color:#00b2ba;font-size:20px;cursor:pointer;width:20%;float:right;padding"
                              >mdi-close</v-icon
                            >
                            <!-- <div style="width:30px;margin-top:1vh"> -->

                            <!-- </div>  -->
                          </v-col>
                        </v-row>
                      </div>
                    </v-row>
                  </div>
                </div>
              </v-row>
            </div>

            <div :class="!isTabletMob ? 'bottom_sec' : 'bottom_sec1'">
              <div
                style="
                  display: flex;
                  flex: 1;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                "
              >
                <v-pagination
                  v-if="!hidePagination"
                  v-model="page"
                  color="#03a6ad"
                  :length="Math.ceil(totalMechanisms / 20)"
                  :total-visible="5"
                  class="pagination"
                  @input="getMechanimsList"
                ></v-pagination>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                "
              >
                <v-btn
                  :disabled="finalMechanisms.length === 0"
                  style="
                    background-color: #009ea5;
                    color: #ffffff;
                    font-size: 12px;
                    height: 30px;
                    border-radius: 4px;
                    box-shadow: none;
                    padding: 0 10px;
                    width: auto;
                    display: inline-block;
                    text-align: center;
                  "
                  @click="createModule(mechanismForm)"
                >
                  Start Processing
                </v-btn>
              </div>
            </div>
          </div>
        </div>

        <v-dialog v-model="alreadyPresent" max-width="400">
          <v-card
            class="Path-359"
            style="
              padding: 4vh;
              padding-left: 2vh;
              border-radius: 10px;
              border-color: #ff6750;
            "
          >
            <v-row no-gutters>
              <v-col cols="12" xs="10" md="10" xl="10" sm="10" align="left">
                <span style="font-family: Ubuntu; font-size: 18px">
                  {{ "Mechanism already present" }}
                </span>
              </v-col>
              <v-col cols="12" xs="2" md="2" xl="2" sm="2" align="right">
                <v-btn
                  icon
                  color="#009ea5"
                  @click="alreadyPresent = false"
                  style="padding-bottom: 5px; cursor: pointer"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
        <v-dialog v-model="limitend" max-width="450">
          <v-card
            class="Path-359"
            style="padding: 4vh; padding-left: 2vh; border-radius: 8px"
          >
            <v-row no-gutters>
              <v-col cols="12" xs="1" md="1" xl="1" sm="1" align="left">
                <img
                  src="../assets/Icons/Path 270.svg"
                  style="height: 3vh; width: 3vh; padding-top: 6px"
                />
              </v-col>
              <v-col cols="12" xs="9" md="9" xl="9" sm="9" align="left">
                <span style="font-family: Ubuntu; font-size: 18px">
                  {{
                    "You can add only" +
                    " " +
                    this.mechanismLimit +
                    " " +
                    "mechanism of action"
                  }}
                </span>
              </v-col>
              <v-col cols="12" xs="2" md="2" xl="2" sm="2" align="right">
                <v-btn
                  icon
                  color="#009ea5"
                  @click="limitend = false"
                  style="padding-bottom: 5px; cursor: pointer"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </v-main>
</template>
<script src="https://unpkg.com/popper.js"></script>
<script src="https://unpkg.com/vue/dist/vue.js"></script>
<script src="https://unpkg.com/v-tooltip@2.0.2"></script>
<script>
//import { EventBus } from "../event-bus.js";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import postscribe from "postscribe";
import Events from "@/components/Events.vue";
import Settings from "@/components/Settings.vue";

export default {
  data() {
    return {
      panels_show: false,
      expanded: true,
      // menuVisible:false,
      search: "../assets/Icons/search.png",
      mechanisms: [],
      configure: false,
      selectedMechanism: "",
      mechanismForm: {},
      configurations: [],
      clicked: false,
      activeTab: "",
      updates: "",
      weekdays: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      closeOnContentClick: false,
      mechanismModules: [],
      isAdmin: true,
      AlertbyEmail: "",
      auth_token: "",
      loading: true,
      fullPage: true,
      mech: "",
      mechanism: {},
      searchme: "",
      searchmech: "",
      items: [],
      // finallist: [],
      finalMechanisms: [],
      alreadyPresent: false,
      mechanismLimit: 0,
      limitend: false,
      mechlength: 0,
      checkedLength: 0,
      page: 1,
      totalMechanisms: 0,
      mechanismsLoading: false,
      lastScrollTop: 0,
      hidePagination: false,
    };
  },

  components: {
    Loading,
    Events,
    Settings,
  },
  watch: {
    searchme(newValue) {
      if (newValue == "") {
        this.getMechanimsList(1);
        this.hidePagination = false;
      }
    },
  },
  computed: {
    filteredList() {
      return this.mechanisms;
    },
    newFilteredList() {
      return this.mechanismModules.filter((item) => {
        console.log(this.searchme);
        return (
          JSON.stringify(item)
            .toLowerCase()
            .indexOf(this.searchmech.toLowerCase()) > -1
        );
      });
    },
    isTabletMob: function () {
      const iPad = /iPad/i.test(navigator.userAgent);
      const iPhone = /iPhone/i.test(navigator.userAgent);
      const Android = /Android/i.test(navigator.userAgent);
      const webOS = /webOS/i.test(navigator.userAgent);
      const iPod = /iPod/i.test(navigator.userAgent);
      const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
      const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);

      return (
        iPad ||
        iPhone ||
        Android ||
        webOS ||
        iPod ||
        BlackBerry ||
        Windows_Phone
      );
    },
    //   filteredList() {
    //   const filteredItems = this.mechanisms.filter(item => {
    //     return JSON.stringify(item.Mechanism).toLowerCase().indexOf(this.searchme.toLowerCase()) > -1;
    //   });

    //   if (filteredItems.length === 0) {
    //      if (this.searchme.length>0)
    //     {
    //       console.log("inside if")

    //     const aliasmatched = this.select()
    //     // this.alias=[] // Call another function if the filtered list is empty
    //     // console.log(aliasmatched)
    //     // return aliasmatched
    //     console.log(aliasmatched,"ajj")

    //     return aliasmatched;
    //   }
    //   }

    //   return filteredItems;
    // },
  },
  created() {},
  mounted() {
    var auth_token = localStorage.getItem("authtoken");
    if (
      auth_token !== null &&
      auth_token !== "" &&
      auth_token !== undefined &&
      auth_token !== "undefined"
    ) {
      this.auth_token = auth_token;
      this.getMechanismCount();
      this.getalias();

      this.getAllMechanismsModules();
      // localStorage.setItem("selected_article","Mechanism_Module")

      if (this.$route.query.activeTab != undefined) {
        // console.log("jiiii",this.$route.query.activeTab)
        this.activeTab = this.$route.query.activeTab;
        this.changeView("1");
        var link = document.getElementById("setting");
        link.click();
        // console.log("activeTabbbbb",this.activeTab)
      } else {
        this.activeTab = "1";
      }
    } else {
      this.$router.push("/");
    }

    // var aliasMechanism=require('@/components/alias_mechanismOfActions_cleaned.json')
    // for (var x in aliasMechanism){
    //   this.items.push(aliasMechanism[x]);
    // }
  },

  methods: {
    onEnter(e) {
      if (e.keyCode === 13) {
        this.getMechanimsList(1);
      }
    },
    getMechanismCount() {
      axios
        .get("/getMechanimsCount?token=" + this.auth_token)
        .then((res) => {
          // console.log("response",res)
          if (res.data.Status != 1) {
            this.totalMechanisms = res.data.Data.Count;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getalias() {
      axios
        .get("/getMoaAlias?token=" + this.auth_token)
        .then((res) => {
          // console.log("response",res)
          if (res.data.Status != 1) {
            this.items = res.data.Data.Alias;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onCancel() {
      console.log("User cancelled the loader.");
    },
    // handleScroll(event) {
    //   // Here you can access the event object to get scroll-related information
    //   // For example, event.target.scrollTop to get the scroll position
    //   console.log('Scrolled!', event.target.scrollTop);
    // },
    // openMenu(i) {
    //   console.log("insidefunc")
    //   // this.menuVisible = true;
    //   for(var x in this.mechanisms)
    //   {
    //     var item=this.mechanisms[x]
    //     if(i==item.Mechanism){
    //       console.log("inside if")
    //         item.Visible=true
    //     }else{
    //       item.Visible=false
    //     }
    //   }

    // },

    //Methods
    AddMechanism() {
      this.getMechanimsList(1);
      this.finalMechanisms = [];
      this.configure = false;

      this.expanded = false;
      this.panels_show = true;
    },
    checkedMechanism(mech) {
      if (mech.Present == true) {
        return;
      }

      //check if mechanism is already selected

      // if (this.mechanismModules.length > 0) {
      //   this.mechlength =
      //     this.mechanismModules.length + this.finalMechanisms.length;
      // } else {
      //   this.mechlength = this.finalMechanisms.length;
      // }
      // if (this.mechlength >= this.mechanismLimit) {
      //   if (mech.Status == "Not-selected") {
      //     this.limitend = true;
      //     return;
      //   } else {
      //     this.finalMechanisms.splice(
      //       this.finalMechanisms.indexOf(mech.Mechanism),
      //       1
      //     );
      //     for (let i = 0; i < this.mechanisms.length; i++) {
      //       if (this.mechanisms[i].Mechanism == mech.Mechanism) {
      //         this.mechanisms[i].Status = "Not-selected";
      //       }
      //     }
      //     this.$forceUpdate();
      //     return;
      //   }
      // }
      // console.log(mech.Status)
      for (let i = 0; i < this.mechanisms.length; i++) {
        if (this.mechanisms[i].Mechanism == mech.Mechanism) {
          // console.log("mechanism",this.mechanisms[i].Mechanism)
          if (mech.Status == "Not-selected") {
            this.mechanisms[i].Status = "Selected";
            this.finalMechanisms.push(mech.Mechanism);
            this.$forceUpdate();
            // console.log("false")
          } else {
            this.mechanisms[i].Status = "Not-selected";
            this.finalMechanisms.splice(
              this.finalMechanisms.indexOf(mech.Mechanism),
              1
            );
            this.$forceUpdate();
            // console.log("true")
          }
        }
      }

      // if (mech.Status==true){
      //   if (this.mechanismModules.length>0){
      //     this.mechlength=this.mechanismModules.length+this.finalMechanisms.length
      //   }else{
      //     this.mechlength=this.finalMechanisms.length
      //   }
      //   for(let i=0;i<this.mechanisms.length;i++){
      //     if (this.mechanisms[i].Status==true){
      //       this.checkedLength++
      //     }
      //   }

      //   if(this.checkedLength>=this.mechanismLimit){
      //     this.limitend=true
      //     //make status of last mechanism in array false
      //     for (let i=0;i<this.mechanisms.length;i++){
      //       if(this.mechanisms[i].Mechanism==mech.Mechanism){
      //         console.log("mechanism",this.mechanisms[i].Mechanism)
      //         this.mechanisms[i].Status=false
      //       }
      //     }
      //     this.$forceUpdate()
      //     return
      //   }else{
      //     this.finalMechanisms.push(mech.Mechanism)
      //     this.$forceUpdate()
      //   }
      // }else{
      //   this.finalMechanisms.splice(this.finalMechanisms.indexOf(mech.Mechanism),1)
      //  // console.log("finalMechanisms",this.finalMechanisms)
      // }
    },
    close_mechanism_selected_area() {
      this.panels_show = true;
      this.configure = false;
      this.$forceUpdate();

      console.log("closed", this.configure);
    },
    close_mechanisms_area() {
      this.panels_show = false;
      this.configure = false;
      this.searchme = "";
      this.hidePagination = false;
      //make page 1
      this.page = 1;

      for (let i = 0; i < this.mechanisms.length; i++) {
        this.mechanisms[i].Status = "Not-selected";
      }
    },
    SelectAndConfigure() {
      this.configure = true;
      this.checkedLength = 0;

      console.log("finalMechanisms", this.finalMechanisms);
    },
    createModule(formData) {
      this.loading = true;
      this.clicked = true;
      this.close_mechanisms_area();

      console.log(this.finalMechanisms, "finalMechanisms");

      for (let i = 0; i < this.mechanisms.length; i++) {
        for (let j = 0; j < this.finalMechanisms.length; j++) {
          if (this.mechanisms[i].Mechanism == this.finalMechanisms[j]) {
            this.mechanisms[i].Present = true;
            break;
          }
        }
      }

      formData.Mechanism = this.finalMechanisms;
      console.log("formData", formData);

      this.mechanismForm = formData;

      let config = { headers: { "Content-Type": "application/json" } };
      axios
        .post(
          "/addMechanism?module_id=" +
            this.$route.query.moduleId +
            "&token=" +
            this.auth_token,
          JSON.stringify(this.mechanismForm),
          config
        )
        .then((res) => {
          if (res.data.Status != 1) {
            if (res.data.Data.AlreadyPresent == false) {
              this.finalMechanisms = [];
              this.getAllMechanismsModules();
              console.log("added mechanism successfully");
            } else {
              this.alreadyPresent = true;
              this.getAllMechanismsModules();

              this.getMechanimsList(1);
              this.loading = false;
            }
          } else if (
            res.data.Status == 1 &&
            res.data.Data.error == "Invalid User"
          ) {
            this.logout();
            //this.$router.push("/").catch(() => { })
          } else {
            this.loading = false;
            console.log("could not save notes");
            this.notes_msg = "Could not save your note !";
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.message);
          if (error.message == "Request failed with status code 500") {
            this.logout();
          }
        });

      //change status of mechanism to false
      for (let i = 0; i < this.mechanisms.length; i++) {
        this.mechanisms[i].Status = "Not-selected";
      }
      this.finalMechanisms = [];

      this.configurations.push(this.mechanisms);

      //  console.log("mechanismForm",this.mechanismForm)
      //match mechanismForm with mechanismList and change present to true

      this.mechanismForm = {};
    },
    changeView(tab) {
      console.log(tab, "tab");
      this.activeTab = tab;
      if (tab == "1") {
        document.getElementById("mechmod1").className = "Mechanisms-0";
        //document.getElementById("mechmod2").className = "Settings"
        document.getElementById("mechmod3").className = "Settings";
      } else if (tab == "2") {
        //document.getElementById("mechmod2").className = "Mechanisms-0";
        document.getElementById("mechmod1").className = "Settings";
        document.getElementById("mechmod3").className = "Settings";
      } else if (tab == "3") {
        document.getElementById("mechmod3").className = "Mechanisms-0";
        document.getElementById("mechmod1").className = "Settings";
        //document.getElementById("mechmod2").className = "Settings"
      } else {
        document.getElementById("mechmod1").className = "Settings";
        //document.getElementById("mechmod2").className = "Settings"
        document.getElementById("mechmod3").className = "Settings";
      }
      //console.log(this.activeTab)
      this.selectedTab = true;
    },
    getMechanimsList(page) {
      // Accept page as an argument, default to 1
      // if (page === 1) this.mechanisms = []; // Clear mechanisms if loading the first page
      this.mechanismsLoading = true;
      this.mechanisms = [];

      axios
        .get(
          `/getMechanisms?token=${this.auth_token}&page=${page}&search=${this.searchme}`
        ) // Include page parameter
        .then((response) => {
          if (response.data.Status != 1) {
            const newMechanisms = []; // Temporary array for new mechanisms
            if (this.searchme != "") {
              this.hidePagination = true;
            }
            if (response.data.Data.Mechanisms.length == 0) {
              this.hidePagination = true;
              //make mechanismlist empty
              this.mechanisms = [];
            } else {
              for (let i = 0; i < response.data.Data.Mechanisms.length; i++) {
                const mechanism = {};
                mechanism.Mechanism = response.data.Data.Mechanisms[i];
                mechanism.Status = "Not-selected";
                mechanism.Alias = [mechanism.Mechanism];

                const items = this.items;
                for (var x in items) {
                  var item = items[x];
                  if (item.Name == mechanism.Mechanism) {
                    mechanism.Alias.push(item.Alias);
                  }
                }

                for (let j = 0; j < this.mechanismModules.length; j++) {
                  if (
                    mechanism.Mechanism == this.mechanismModules[j].Input_value
                  ) {
                    mechanism.Present = true;
                    break;
                  }
                }

                newMechanisms.push(mechanism);
              }

              // Combine old and new mechanisms, then sort
              this.mechanisms = [...this.mechanisms, ...newMechanisms];
              // this.mechanisms.sort((a, b) => {
              //   var nameA = a.Mechanism.toLowerCase(),
              //     nameB = b.Mechanism.toLowerCase();
              //   if (nameA < nameB) return -1;
              //   if (nameA > nameB) return 1;
              //   return 0;
              // });
              this.mechanismsLoading = false;

              this.getMechanismLimit();
            }
          } else {
            this.mechanismsLoading = false;
            this.loading = false;
            console.log("could not get mechanisms");
          }
        })
        .catch((error) => {
          this.mechanismsLoading = false;
          console.log(error.message);
          if (error.message == "Request failed with status code 500") {
            this.logout();
          }
        });
    },
    getMechanismLimit() {
      axios
        .post(
          "/getMechanismLimit?token=" +
            this.auth_token +
            "&module_id=" +
            this.$route.query.moduleId
        )
        .then((response) => {
          if (response.data.Status != 1) {
            if (this.searchme != "") {
              this.hidePagination = true;
            }
            var limit = response.data.Data.Limit;
            // console.log("mechanismLimit",limit)

            if (limit == "All") {
              this.mechanismLimit = this.mechanisms.length;
            } else {
              this.mechanismLimit = parseInt(limit);
            }
            // console.log("mechanismLimit",this.mechanismLimit)
          } else {
            this.loading = false;
            console.log("could not get mechanisms limit");
          }
        })
        .catch((error) => {
          console.log(error.message);
          this.loading = false;
          if (error.message == "Request failed with status code 500") {
          }
        });
    },

    // getTriggerEvent(i,trigger){
    //   this.closeOnContentClick=true
    //   if (trigger=="0"){
    //     this.mechanismForm.Weekday =i
    //   }else if (trigger=="2"){
    //     this.mechanismForm.MonthDate =i
    //   }

    // },
    removeMechanism(m) {
      //remove mechanism from finalMechanisms

      console.log("removing......", m);
      this.finalMechanisms.splice(this.finalMechanisms.indexOf(m), 1);

      for (let i = 0; i < this.mechanisms.length; i++) {
        if (m == this.mechanisms[i].Mechanism) {
          console.log("inside if");
          this.mechanisms[i].Status = "Not-selected";
        }
      }
      if (this.finalMechanisms.length == 0) {
        this.configure = false;
      }
    },
    goBack() {
      this.$router.push("/");
    },
    getAllMechanismsModules() {
      this.mechanismModules = [];
      axios
        .post(
          "/getAllMechanismsModules?module_id=" +
            this.$route.query.moduleId +
            "&token=" +
            this.auth_token
        )
        .then((response) => {
          if (response.data.Status != 1) {
            this.loading = false;
            console.log("mechanismmmmmmm");
            if (response.data.Data.MechanismModules.length > 0)
              // console.log("mechanisms",response.data.Data.MechanismModules)
              for (
                let i = 0;
                i < response.data.Data.MechanismModules.length;
                i++
              ) {
                //  if(response.data.Data.MechanismModules[i].ModuleId==this.$route.query.moduleId){
                this.mechanismModules.push(
                  response.data.Data.MechanismModules[i]
                );

                //}
              }
            this.isAdmin = response.data.Data.isAdmin;
          } else {
            console.log("No mechanisms");
            this.loading = false;
          }
          //iterate through mechanisms and mechanismsModules and change status of mechanism to true if present in mechanismModules

          // console.log("isAdmin",this.isAdmin)

          //  console.log("mechanismModules",this.mechanismModules)
        })
        .catch((error) => {
          console.log(error.message);
          this.loading = false;
          if (error.message == "Request failed with status code 500") {
            this.logout();
          }
        });
    },
    visitMechanismModule(m, mechanism) {
      //  console.log("mechanism",mechanism)
      // var old_selected = localStorage.getItem("selected_article");
      //     if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //       localStorage.setItem("old_selected_article", old_selected);
      //     } else {
      //       old_selected = "Industry Updates"
      //       localStorage.setItem("old_selected_article", old_selected);
      //     }
      // localStorage.setItem("selected_article", "Mech_Module");
      this.$router.push(
        "/mechModule?input_id=" +
          m +
          "&input_value=" +
          mechanism +
          "&module_id=" +
          this.$route.query.moduleId
      );
    },
    onCancel() {
      console.log("User cancelled the loader.");
    },
  },
};
</script>
<style lang="scss" scoped>
.margin_align {
  font-family: Ubuntu;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh !important;
}

.left {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 0px;
  padding-top: 20px;
  margin: 0vh 0vh 2vh 0vh !important;
  overflow-y: hidden;
  overflow-x: hidden;
}

.leftPanelExpand {
  width: 100%;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 20px;
  padding-top: 20px;
}

.right {
  width: 70%;
  position: absolute;
  right: 0;
  overflow-y: hidden;
  background: white;
  padding-bottom: 5px;
  box-shadow: -5px 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.right_111 {
  width: 100%;
  position: absolute;
  right: 0;
  overflow-y: hidden;
  background: white;
  padding-bottom: 5px;
  box-shadow: -5px 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.text_notes {
  font-size: 16px;
  font-weight: 500;
  color: #49575d;
  margin-bottom: 1vh !important;
}

.show_article {
  font-size: 14px;
  font-weight: 500;
  color: #00b2ba;
  margin-bottom: 1vh !important;
  cursor: pointer;
}

.v-card-text {
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  float: left;
  color: #49575d;
  padding: 16px 0px 8px;
  background: white;
}

.col-md-2 {
  flex: 1 0 auto;
  width: 12.66666667%;
}

.aa {
  background: #009ea5 !important;
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff !important;
  text-transform: none;
}

.bb {
  background: #009ea5 !important;
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

table tbody {
  display: table;
  width: 100%;
}

table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  width: 98%;
  margin: 2vh;
  max-height: 90vh;
  overflow-y: auto;
  table-layout: fixed;
  border-collapse: collapse;
}

td {
  padding: 1vh;
  font-size: 16px;
  color: #333;
  border: 1px solid #d7dbdd;
}

tr {
  border-top: 1px solid #d7dbdd;
  font-size: 16px;
  color: #333;
  white-space: pre;
  background-color: #ffffff;
}

th {
  text-align: center;
  padding: 1vh;
  background-color: #ebf3f7;
  font-family: Ubuntu;
  border: 1px solid #d7dbdd;
}

tr:nth-child(odd) {
  background-color: #f3fbfc;
}

.line {
  border: 1px solid #797c7d;
  background-color: #797c7d !important;
  margin: 0.5rem 0;
}

.pre-formatted {
  white-space: pre-wrap;
  /* 👈 this is the important part */
}

.news_heading {
  margin: 0px 0px 18px 0px;
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #374246;
}

.title1 {
  font-family: Ubuntu;
  font-size: 13px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;

  color: #384348;
}

.titleCenter {
  text-align: center;
  margin: 0 0.5vw;
}

.cardTitles {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #49575d;
}
.circle {
  width: 20px;
  height: 20px;
  margin-left: 2vh;
  // margin: 0vh 2vh 0vh 2vh;
  border-radius: 20px;
  border: solid 1px #676772;
  background-color: inherit;
}
.circle_1111 {
  width: 20px;
  height: 20px;
  margin-left: 2vh;
  border-radius: 20px;
  border: solid 1px #676772;
  background-color: inherit;
}
.cardTitles1 {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #49575d;
}

.itemCountDesign {
  font-family: Ubuntu;
  font-size: 25px;
  font-weight: 500;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #374246;
  margin-left: 4px;
}

.pharmaContent {
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #49575d;
  margin-top: -5vh;
}

.header_text {
  text-transform: capitalize;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #49575d;
  margin-left: 15vh;
}

.linktitle {
  margin: 5vh 45px 10px 0;
  font-family: Ubuntu;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #374246;
}

.hrLine {
  border-bottom: 2px solid #374246;
  margin: 0rem 0;
  max-width: 5vw;
  justify-content: center;
  display: flex;
  color: #374246 !important;
}

.hrdialogLine {
  border-bottom: 1px solid #374246;
  margin: 0rem 0;
  width: 100%;
  justify-content: center;
  display: flex;
  color: #374246 !important;
}

.Rectangle-718 {
  width: 30px;
  height: 30px;
  padding: 3px 5px 5px 9px !important;
  background-color: #d5f8fa;
}

.titleCenter {
  margin-top: 3vh;
  text-align: center;
}

.table_header {
  background-color: #3fa6a1;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  word-wrap: break-word !important;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  word-wrap: break-word !important;
}

.text_left {
  font-weight: 600;
  color: #49575d;
  font-size: 16px;
}

.appBarBox {
  height: 90px;
  padding-left: 2%;
  padding-right: 1%;
  margin-bottom: 15px;
  margin-top: -3vh !important;
  margin-left: -3vh;
  background-color: #f8fafb;
}

.optionMenu {
  color: #009ea5;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 600;
}

.popUpVersion {
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #404040;
  opacity: 0.86;
  text-align: center !important;
}

.popUpDates {
  opacity: 0.86;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #404040;
  float: left;
}

.breadcrums {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  text-decoration: none;
}

.v-breadcrumbs__item {
  color: #009ea5 !important;
}

.v-breadcrumbs__item v-breadcrumbs__item--disabled {
  color: #333333 !important;
}

.notes_section {
  height: 100vh !important;
  //background-color: #f9f9fb;
  z-index: 2;
  margin-top: -2vh !important;
  font-family: Ubuntu;
  overflow-x: hidden;
}

.comments_list {
  padding: 1vh 1vh 5vh 1vh;
  height: 75vh;
  max-height: 75vh;
  overflow-y: auto;
  background: inherit;
}

.Rectangle-913 {
  margin: 2vh;
  padding: 0vh 2vh 1.5vh 2vh;
  border-radius: 18px;
  border: solid 1px #ccc;
  background-color: #fff;
}

.date_notes {
  font-size: 13px;
  font-weight: 500;
  color: #8c8e91 !important;
  margin-left: 5vh;
}

.tablePhases1 {
  color: #333;
  background-color: #f8fafb;
  font-size: 16px;
  border: none !important;
  border-top: 1px solid #f8fafb !important;
}

.tablePhases2 {
  background-color: #f3fbfc;
}

.mech2Btn {
  background-color: rgba(255, 103, 80, 0.1);
  border-color: #ff6750;
  font-size: 16px;
  font-weight: 500;
  color: #ff6750;
  font-family: Ubuntu;
  border-radius: 10px;
  padding: 1vh !important;
  height: 100% !important;
}

.tablePhases {
  position: sticky;
  z-index: 1;
  left: 0;
  border-top: 1px solid #d7dbdd !important;
  border-bottom: 1px solid #d7dbdd !important;
  border-left: 1px solid #d7dbdd !important;
}

.tablenonphase {
  background-color: inherit;
  font-size: 16px;
  font-weight: 700;
}

.fix {
  position: absolute;
  margin-left: -100px;
  width: 100px;
}

.dualHeaderRow {
  background-color: #3fa6a1;
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  text-transform: capitalize;
  border-bottom: 1px solid #d7dbdd !important;
  border-left: 1px solid #d7dbdd !important;
  position: sticky;
  z-index: 1;
  left: 0;
}

.dualHeaderAnotherRow {
  background-color: #3fa6a1;
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  text-transform: capitalize;
}

.dualHeaderData {
  background-color: #d2edee;
  font-size: 13px;
  font-weight: 700;
  border-top: 1px solid #d7dbdd !important;
  border-bottom: 1px solid #d7dbdd !important;
  border-left: 1px solid #d7dbdd !important;
  position: sticky;
  z-index: 1;
  left: 0;
  word-wrap: break-word !important;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  word-wrap: break-word !important;
}

.dualHeaderAnotherData {
  background-color: inherit;
  font-size: 13px;
  font-weight: 500;

  //width: 100%;
  word-wrap: break-word !important;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  word-wrap: break-word !important;
}

.finaTableWrap {
  word-wrap: break-word !important;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  word-wrap: break-word !important;
}

.finathWrap {
  word-wrap: break-word !important;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  word-wrap: break-word !important;
}

.icon {
  font-size: 30px;
}
.finance_text {
  font-family: Ubuntu;
  font-weight: 500;
  font-size: 16px;
}
.fincard {
  margin: 0.5vh;
  padding: 2vh;
  height: 70vh;
  overflow-y: scroll;
}

.Path-359 {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #ff6750 !important;
  background-color: #fff;
  border-radius: 20px !important;
}

.Active_Companies_title {
  margin: 2vh 2vh 2vh 0vh;
  font-family: Ubuntu;
  font-size: 2.8vh;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: left;
  color: #ff6750;
}
.Path-85 {
  box-shadow: none !important;
  margin: 2vh;
  padding: 1vh 3vh 2vh 3vh;
  border: solid 0.6px #d3d3d3 !important;
  background-color: #fdfefe;
  height: 40vh;
  border-radius: 7px !important;
}
.com_nm {
  margin-top: 3vh;
  font-size: 2.2vh;
  font-weight: 700;
  font-family: Ubuntu;
  text-align: center;
  color: #383838 !important;
  height: 4vh;
}
.com_nm1 {
  margin-top: 2vh;
  font-size: 2.3vh;
  font-weight: 500;
  font-family: Ubuntu;
  text-align: center;
  color: #383838 !important;
}
.noteBox {
  position: absolute;
  float: bottom;
  bottom: 80px;
}
.even_panels {
  background-color: #f8fafb;
  margin: 0px -10px 6px 0px;
  padding: 0px 30px 40px 70px;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.16) !important;
}
.odd_panels {
  background-color: #fff;
  margin: 0px -10px 6px 0px;
  padding: 0px 30px 40px 70px;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
}

.Path-499 {
  height: 30px;
  margin: -25px 5.5px 15px 0px;
  padding: 2px 3px 3px 2px;
  box-shadow: 0 2px 3px 0 rgba(88, 88, 88, 0.16) !important;
  border: solid 0.7px #cfd3d5;
  border-radius: 7px;
  background-color: #fff !important;
}
.link_content {
  font-family: Ubuntu;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.34;
  letter-spacing: normal;
  text-align: left;
  color: #32393c !important;
  font-size: 13px;
  text-decoration: none;
}
.textbox_content {
  font-family: Ubuntu;
  font-size: 15px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #384348;
}
.texttitle {
  font-family: Ubuntu;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #374246;
  margin-top: 2vh;
}
.Rectangle-721 {
  margin-top: 0px;
  background-color: #ffffff !important;
  top: 0;
  margin-left: 5vh;
}
.Rectangle-905 {
  padding: 2vh 0vh 2vh 2vh;
  border-radius: 16px;
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.22);
  border: solid 2px #222545;
  background-color: #f2fcfc;
  margin-top: 5px;
  max-height: 58vh;
}
.Rectangle-903 {
  height: 42px;
  margin: -2vh 0vh 0vh -2vh;
  padding: 1vh 0vh 0.5vh 2vw !important;
  font-size: 18px;
  text-align: left;
  font-weight: 700;
  color: #ffffff;
  background-color: #00b2ba;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}
.Notes-Centre-101 {
  font-family: Ubuntu;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
  margin-left: 3vh;
}

.Showing-All-Diseases {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #f16753;
}
.Path-704 {
  padding: 10px 20px 10px 20px;
  box-shadow: 0px 0px 10px 0px rgba(36, 36, 36, 0.16);
  border: solid 1px #d9d7d7;
  background-color: #fff;
  border-radius: 5px;
}
.Path-736 {
  min-height: 80px;
  padding: 0vh 2vh 1vh 2vh;
  background-color: #f5f5f5;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.Added-on {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.53;
  letter-spacing: normal;
  text-align: left;
  color: #a0a0a0;
}
.notes_Added_on {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.53;
  letter-spacing: normal;
  text-align: left;
  color: #a0a0a0;
  margin-left: 3vh;
}
.Article-Type {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: normal;
  text-align: left;
  color: #80808b;
  margin-top: 0vh !important;
}
.Therapy-Landscape-type {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}
.note_content_type {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
  margin-left: 3vh;
}
.notes_text {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}

.dropdownStyle {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 500;
  color: #49575d !important;
  text-transform: capitalize !important;
}
.Rectangle-1062 {
  height: 125px;
  margin-top: 30px;
  padding: 0px 12px 10px 12px;
  background-color: #f5f5f5 !important;
}
//My styling

.-ACTIVE {
  padding: 14px 14px 0 14px;
  height: 16.8px;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.71;
  letter-spacing: normal;
  text-align: left;
  color: #03a6ad;
}
.-ALERTS {
  padding: 14px 14px 0 14px;
  height: 15px;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.71;
  letter-spacing: normal;
  text-align: left;
  color: #f66b67;
}

.Development-Pipeline {
  margin: 0px 0px 0px 50px;
  font-family: Ubuntu;
  font-size: 2.6vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #32324a;
}
.Development-Pipeline_111 {
  padding: 0px 0px 0px 0px;
  font-family: Ubuntu;
  font-size: 2.6vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #32324a;
}
.Mechanisms-0 {
  font-family: Ubuntu;
  font-size: 2vh;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #03a6ad;
  text-transform: capitalize;
}
.Events-0 {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.27;
  letter-spacing: normal;
  text-align: center;
  color: #676772;
}
.Settings {
  font-family: Ubuntu;
  font-size: 2vh;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #676772;
  text-transform: capitalize;
}
.notes_section {
  height: 100vh !important;
  background-color: #f9f9fb;
  z-index: 2;
  margin-top: 2vh;
  font-family: Ubuntu;
  overflow-x: hidden;
}
.right {
  width: 70%;
  position: absolute;
  right: 0;
  overflow-y: hidden;
  background: white;
  padding-bottom: 5px;
  box-shadow: -5px 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.Add-Mechanism {
  margin: 5px 17.1px 34.2px 9.5px;
  font-family: Ubuntu;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}
.select-mechanism {
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #202527;
}
.related-mechanisms {
  margin: 0px 20.6px 25.3px 5.5px;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #676772;
}
.Path-731 {
  min-height: 60px;
  margin-bottom: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #d3d3d3;
  border-radius: 12px;
  background-color: #fcfcfc;
}
.Path-732 {
  height: 100%;
  margin-bottom: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #d3d3d3;
  border-radius: 12px;
  background-color: #fcfcfc;
}
.Path-731:hover,
.Path-732:hover {
  border: solid 2px #03a6ad;
  // display:block;
}

.Select-Configure {
  margin: 6.5px 0 4.5px 170.8px;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: right;
  color: #03a6ad;
}
.O-Daily-O-Weekly-on-O-Monthly-on- {
  width: 543.2px;
  height: 31.5px;
  margin: 0 0 0 19.3px;
  font-family: Ubuntu;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}
div.inline {
  float: left;
}
.clearBoth {
  clear: both;
}

.Mechanism {
  font-family: Ubuntu;
  font-size: 2vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}

.Line-194 {
  border-top: 2px dashed #ffff;
  height: 1px;
  margin: 15.8px 3.5px 13.8px 0;
  background-color: #03a6ad;
}
.Path-359 {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #ff6750 !important;
  background-color: #fff;
  border-radius: 20px !important;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
// .custom-menu {
//   /* Add your custom positioning styles here */
//   top: auto; /* Remove any top positioning */
//   left: 0; /* Position the menu below the activator */
// }
.abc {
  position: relative;
  background-color: #f7fafb;
}

.add_mechanism_btn {
  margin-right: 5vh;
  background-color: #009ea5 !important;
  color: #ffffff;
  font-size: 12px;
  height: 30px;
  border-radius: 4px;
  box-shadow: none;
  border: solid 2px #03a6ad;
  width: auto;
}

.add_mechanism_btn_111 {
  margin: 0vh 0vh 2vh 2vh;
  background-color: #009ea5 !important;
  color: #ffffff;
  font-size: 12px;
  height: 30px;
  border-radius: 4px;
  box-shadow: none;
  border: solid 2px #03a6ad;
  width: auto;
}

.list_display {
  border: solid;
  border-radius: 10px;
  border-color: #ff6750;
  overflow-y: auto;
}

.list_display_111 {
  border: solid;
  border-radius: 10px;
  border-color: #ff6750;
  overflow-y: auto;
}
.v-dialog {
  box-shadow: none !important;
  overflow: visible !important;
  color: gray !important;
}
.v-application .primary--text {
  color: gray !important;
  caret-color: gray !important;
  // border: solid 1px;
}
.open_btn {
  width: 5vw;
  margin-top: -10px;
  background: inherit !important;
  border-radius: 5px;
  border: 1px solid #009ea5;
  color: #009ea5;
  font-size: 1.7vh;
  float: right !important;
  box-shadow: none;
  height: 4vh;
}
.bottom_sec {
  background-color: #f2f2f2;
  position: fixed;
  bottom: 0;
  display: flex;

  align-items: center;
  width: 70%;
  padding: 3vh;
  flex-direction: row;
}
.bottom_sec1 {
  background-color: #f2f2f2;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 3vh;
}
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 10px;
}
</style>
