<template>
  <v-app light>
      <div v-if="major_loading">
        <loading :active.sync="major_loading" :can-cancel="true"></loading>
      </div>
      <div v-if="!major_loading">
        <AppBar v-if="flag" />
        <div><router-view></router-view></div>
        <!-- <template>
          <v-dialog v-model="first_time" width="1000" content-class="elevation-0">
            <v-card style="border-radius: 30px;padding-bottom: 20px;">
              <div :class="next ? 'knolchat_img' : 'knolchat_img_modules'">
                <v-row>
                  <v-col cols=12 xs="9" md="9" xl="9" sm="9">                  
                  </v-col>
                  <v-col cols=12 xs="3" md="3" xl="3" sm="3" align="right" >    
                    <v-btn
                      icon
                      color="#ff6750"
                      style="margin-top:0vh;margin-right:2vh"
                      @click="first_time=false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <v-row>
                  <v-col cols=12 xs="9" md="9" xl="9" sm="9">                  
                  </v-col>
                  <v-col cols=12 xs="3" md="3" xl="3" sm="3" align="right" >                  
                    <v-btn
                      v-if="next"
                      style="margin-right: 4vh;font-size:13px;background-color: #ff6750;color:#ffffff;width:80px;height:30px"
                      @click="show_next()"
                    >
                      Next
                    </v-btn>
                    <v-btn
                      v-if="!next"
                      style="margin-right: 4vh;font-size:13px;background-color: #ff6750;color:#ffffff;width:80px;height:30px"
                      @click="show_next()"
                    >
                      Previous
                    </v-btn>
                  </v-col>
                </v-row>              
            </v-card>
          </v-dialog>
        </template>    -->
       
      
      </div>    
    </v-app>
</template>

<script>
//import SideBar from "./components/SideBar.vue";
import AppBar from "./components/AppBar.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import { EventBus } from "./event-bus.js";
import Vue from "vue";
export default {
  name: "App",
  components: {
    AppBar,
    Loading,
    //SideBar,
  },
  data() {
    return {
      major_loading : true,
      flag: false,
      auth_token: '',
      userObject: {},
      user_company: '',
      user_position: '',
      user: {},
      page_loaded: false,
      drawer: false,
      disease: '',
      first_time: false,
      next: true,
      dialog: false,
      // allowNotification: false,
      login_completed: false,
      sampleKey: "",
      msg:"Please allow notifications to use KnolChat. <br><br> After allowing notifications, please reload page. <br><br>Firefox users may need to click on notifications icon in the address bar and click on allow."
    };
  },
  watch: {
    $route(to,from) {
      this.page_loaded = true
      if (this.$route.name != "Custom_Module") {
        //emit event to disable close button
        EventBus.$emit("hide_home", true);
      }

      if (from.name === "Sample" && (to.name === "Sample" || to.name === null)){
            this.getSampleKey()
            
      } 
      // }
        var auth_token = localStorage.getItem("authtoken")
          // console.log("aaa",auth_token)
          //console.log("here authenticatedd",auth_token)
        if (auth_token != null && auth_token != "" && auth_token != undefined) {
          this.auth_token = auth_token
          // console.log("____",this.auth_token)
        }
      if (this.$route.name === "Login" || this.$route.name === "Register" || this.$route.name === "Payment" || this.$route.name == 'Transaction_success' || this.$route.name == 'Transaction_failed' || this.$route.name == 'Pricing' || this.$route.name === "Module_Transaction_Success" || this.$route.name === "Module_Transaction_Fail" || this.$route.name === "Plan_payment") {
        this.flag = false;
      } else {
        this.flag = true;
      }
      if(from.name === "Modules_Library" && to.name != "Login" && to.name != "Register" && to.name != "Pricing" && to.name!='articleDetails' && to.name != "Sample" && to.name != "Unsubscribe_mail"){
        //if user is not logged in, redirect to login page
          var auth_token = localStorage.getItem("authtoken")
          //console.log("aaa",auth_token)
          //console.log("here authenticatedd",auth_token)
        if (auth_token != null && auth_token != "" && auth_token != undefined) {
          this.auth_token = auth_token
          // console.log("____",this.auth_token)
        }
        if (this.auth_token === null || this.auth_token === "" || this.auth_token === undefined) {
          this.$router.push("/").catch(() => {})
        }
      }

      if(from.name === "Plan_payment" && to.name === "Register"){
            // console.log("here logginnggg outtt")
            // this.logout()

            this.$router.push("/").catch(() => {})
      }
     
   
      if (from.name === "Sample" && (to.name === "Sample" || to.name === null)){
            this.getSampleKey()
            
      } 
      
   
    // console.log(this.$route.name,"this.$route.name")
   
      if (this.auth_token !== null && this.auth_token !== "" && this.auth_token !== undefined) {               
        if (from.name === "Modules_Library" && (to.name === "Login" || to.name === "Plan_payment")){
        // console.log("here authenticatedd")
        // var selected_page = localStorage.getItem("selected_article")
        var selected_page
        // var activity_name="Page Change"
        axios
        .get("/fetchCurrentPage?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status != 1) {
                // console.log(res.data,"response.data")
                selected_page = res.data.Data.Current_Page
                this.$forceUpdate(); 
                if (selected_page =='Modules_Library_authenticated'){
                // console.log("here authenticatedd 2")
                
                this.$router.push("/").catch(() => {
                  // console.log("here authenticatedd 3")
                })
               
              }
                         
           }
        })
        
        
      }
      
          var current_page=localStorage.getItem('selected_article') 
          if ((current_page=='Overview By Indication' && from.name === null || to.name === null)){
            var moduleId = "64d4abad39e80392bcb587b3"
            var moduleName = "Overview by Indication"
            EventBus.$emit("module_name",moduleName);
            localStorage.setItem("selected_module",moduleName)
            let url = "/overviewbyIndication/"+moduleId+"/"+moduleName
            this.$router.push(url)
          } 
    
         
          // console.log("--------",this.auth_token)
          if (from.name === "articleList" && to.name === "Login"){
            this.$router.push("/").catch(() => {})
          } 
          if(from.name === "Plan_payment" && to.name === "Register"){
            // console.log("here logginnggg outtt")
            // this.logout()

            this.$router.push("/").catch(() => {})
          }

          if (from.name === "articleList" && to.name === "Payment"){
            this.$router.push("/").catch(() => {})
          } 
           
          if (from.name === "Modules_Library" && to.name === "Login"){
            this.$router.push("/").catch(() => {})
          } 

          if (from.name === "Modules_Library" && to.name === "Payment"){
            this.$router.push("/").catch(() => {})
          } 
          if (from.name === "Modules_Library" && to.name === "Register"){
            this.$router.push("/").catch(() => {})
          } 
           
          // if (from.name === "Register" && to.name === "articleList") {
          //   this.$router.push("/Register").catch(() => {})
          // }
          
          if (from.name === "Payment" && to.name === "Register") {
            this.$router.push("/Payment").catch(() => {})
          }

          // if (from.name === "Register" && to.name === "Login") {
          //   this.$router.push("/Register").catch(() => {})
          // }

          if (from.name === "Login" && to.name === "articleList") {
             this.$router.push("/").catch(() => {})
          }
         
          // if (from.name === "Login" && to.name === "Modules_Library"){
          //   //remove auth token from local storage
          //   if (!this.login_completed){
          //     // get mahiti from local storage
             
          //     this.logout()
          //   }
          // } 
       
          // if (from.name === "Module_Transaction_Success"){
           

            
          //   //stay on same page
          //   this.$router.push("/ModuleTransactionSuccess").catch(() => {})
          // }
          // if (from.name === "Module_Transaction_Fail" && to.name === "Modules_Payment"){
          //   //stay on same page
          //   this.$router.push("/ModuleTransactionFail").catch(() => {})
          // }
          // if (from.name === "Transaction_success" && to.name === "Register_plan_payment"){
          //   //stay on same page
          //   this.$router.push("/Transaction_success").catch(() => {})
          // }
          // if (from.name === "Transaction_failed" && to.name === "Register_plan_payment"){
          //   //stay on same page
          //   this.$router.push("/Transaction_failed").catch(() => {})
          // }
           this.save_page_activity(to.name,from.name)

      } else {
        this.auth_token = ""
        if (from.name === "articleList" && (to.name === "Profile_Settings" || to.name === "Payment" || to.name === "Register")){
          // console.log("here")
            this.$router.push("/").catch(() => {})
        }

        if (this.$route.name === "Login" || this.$route.name === "Pricing")
          // console.log(this.$route.query.page_type,"this.$route.query.page_type")
          if (this.$route.query.page_type == 'Login') {
            this.$router.push("/Login").catch(() => {
              console.log(err)
            });
          } else if (this.$route.query.page_type == 'Pricing') {
            this.$router.push("/Pricing").catch(() => { });
          } 
        }

     
      // if (this.page_loaded){
      //   var end = new Date();
      //   var time_spent = end - this.start
      //   //var page_time_spent = this.millisToMinutesAndSeconds(time_spent)
      //   var page_time_spent =  ((time_spent % 60000) / 1000).toFixed(0);
      //   if (this.auth_token !== null && this.auth_token !== "" && this.auth_token !== undefined) {
      //     this.save_duration_activity(page_time_spent, from.name)
      //   }
      // }
    },
  },
  computed: {
    isTabletMob : function() {

      const iPad = /iPad/i.test(navigator.userAgent);
      const iPhone = /iPhone/i.test(navigator.userAgent);
      const Android = /Android/i.test(navigator.userAgent);
      const webOS = /webOS/i.test(navigator.userAgent);
      const iPod = /iPod/i.test(navigator.userAgent);
      const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
      const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);

      return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
    }
  },
  created() {
    EventBus.$on('logincomplete', (data) => {
      // console.log("logincomplete event received", data)
        this.login_completed = true
     });
     //Firebase Code
    //  this.$messaging.onMessage(function(payload) {
    //     console.log("Message received.", payload);
    //     //EventBus.$emit('new-message', payload)
    //   });
      //emit event to send payload to the component
      // this.$messaging.onMessage((payload) => {
      //   EventBus.$emit('new-message', payload) 
      // });
  },
  async mounted() {
    //listen for the event
     EventBus.$on('logincomplete', (data) => {
      // console.log("logincomplete event received", data)
        this.login_completed = true
     });
     await this.get_release_version()

    var selected_module=localStorage.getItem("selected_module")
    if (selected_module!==null && selected_module!=="null" && selected_module!=="undefined" && selected_module!==undefined){
      EventBus.$emit("module_name",selected_module);
    }
    
    // this.check_visit()
    if (this.$route.name === "Login" || this.$route.name === "Register" || this.$route.name === "Payment" || this.$route.name == 'Transaction_success' || this.$route.name == 'Transaction_failed' || this.$route.name == 'Pricing' || this.$route.name == "Plan_payment") {
      // console.log("login page")
      this.flag = false;
    } else {
      this.flag = true;
    }
    var auth_token = localStorage.getItem("authtoken");
    if (this.isTabletMob == true) {
      this.major_loading = false
      //this.$router.push("/Testing").catch(() => {})
    } else { 
      // console.log("not a tablet or mobile")
        if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
          this.auth_token = auth_token
          this.get_page_activity()
          this.get_user_profile(this.auth_token)
        } else {
          this.major_loading = false
          this.auth_token = ""
          if (this.$route.name == 'articleDetails' || this.$route.name == "share" || this.$route.name== 'Register' || this.$route.name== 'sharewebarticle')  {
            // console.log("article detail path")
          } else if (this.$route.name === "Login" || this.$route.name === "Pricing"){
            // console.log(this.$route.query.page_type,"this.$route.query.page_type")
            if (this.$route.query.page_type == 'Login') {
              this.$router.push("/Login").catch(() => {});
            } else if (this.$route.query.page_type == 'Pricing') {
              this.$router.push("/Pricing").catch(() => { });
            } else {
              // console.log("routing check")
            }
          } else {
            // this.loadL1L2EventPage("Multiple sclerosis")
          }
        }
    }
    this.start = new Date();
    
    //Events Handling...
    EventBus.$on("auth", (auth) => {
      this.auth_token = auth
    });
    EventBus.$on("drawer_status", (drawer_status) => {
      this.drawer = drawer_status
    });
    EventBus.$on("login", (token) => {
     this.login_completed = true
      this.getUser(token);
    });
    EventBus.$on("logout", (token) => {
      this.logout();
    });
  },
  methods: {
    async get_release_version(){
      await axios
        .get("/getReleaseVersion")
        .then((res) => {
          if (res.data.Status != 1) {
            // console.log("Getting version number.........")
            var version_number = res.data.Data
            var current_local_version = localStorage.getItem("version_number");
            if (current_local_version == null || current_local_version == undefined || current_local_version == '') {
              localStorage.setItem("version_number", version_number);
            } else {
              console.log("version number not null")
            }            
            if (version_number != current_local_version) {
              //console.log("version number not matching")
              // localStorage.removeItem("old_selected_article");
              // localStorage.removeItem("selected_disease");
              // localStorage.removeItem("selected_article");
              // localStorage.removeItem("diseases");       
              // localStorage.removeItem("user");       
              // localStorage.removeItem("unselected_diseases");
              localStorage.removeItem("version_number");
              localStorage.setItem("version_number", version_number);

              //window.location.reload(true);
            } else {
              console.log("version number matching")
            }
          } else {
            console.log("Invalid Release Version")
          }
      })
      .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            //this.logout()  
            console.log(error.message);           
          }
      });
    },
    
    loadL1L2EventPage(disease){
      this.$router.push("/").catch(() => {})
      this.l1l2_page_data = {}
      this.l1l2_page_data.article_title = "Events"
      this.l1l2_page_data.disease = disease
      EventBus.$emit("l1l2_page_data", this.l1l2_page_data);
    },
    save_duration_activity(page_time,pagenm){
      // var disease = localStorage.getItem("selected_disease")
      var disease=this.disease
      if (disease !== null && disease !== "" && disease !== undefined) {
        //console.log(disease,"disease")
      } else {
        disease = "Multiple sclerosis"
      }
      var articleid = ""
      var duration = page_time
      var panel_duration = "0"
      var activity_name = "Page Duration"
      var panel_number = "0"
      var pagename = ""

      if (pagenm == "articleDetails") {
        // pagename = localStorage.getItem("old_selected_article")
        if (pagename !== null && pagename !== "" && pagename !== undefined) {
          //console.log(pagename,"pagename")         
        } else {
          pagename = "Industry Updates"
        }
      } else if (pagenm == "articleList") {
        pagename = "Industry Updates"
      } else {
        pagename = pagenm
      }      

      axios
        .post("/SaveActivity?id=" + articleid + '&token=' + this.auth_token + "&activity_name=" + activity_name + "&disease=" + disease + "&page_name=" + pagename + "&duration=" + duration + "&panel_duration=" + panel_duration)
        .then((res) => {
          if (res.data.Status != 1) {
          //console.log("Activity saved successfully")
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            this.logout()
            console.log("Invalid User")
          } else {
            console.log("Activity save failed")
          }
      })
      .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);      
          }
      });
    },
    getUser(token) {
      axios
        .get("/userProfile?token=" + token)
        .then((res) => {
          // console.log(res,"ressss")
          if (res.data.Status != 1) {
            // console.log("Profile found")
            var isfcm_supported = firebase.messaging.isSupported();
            // console.log(isfcm_supported,"fcm_supported")
            if (isfcm_supported==true){
              this.requestnotificationandtoken()
            }
            
              this.userLoggedIn = true;
              this.user = res.data.Data;
              Vue.prototype.$AuthToken = token;
              // localStorage.setItem("diseases", this.user.Diseases);
              axios.defaults.headers.common['Authorization'] = token
              localStorage.setItem("authtoken", token);
              this.auth_token = token

             
              
 
            
              // if (this.user.Company_name == "" && this.user.User_position == "") {
              //   this.$router.push("/Register").catch(() => {})
              // } else {
                //console.log("got info")
                //this.$router.push("/").catch(() => {})
                // var disease = localStorage.getItem("selected_disease");
                var disease=this.disease
                if (disease != null && disease != "" && disease != undefined) {
                  //console.log(disease,"disease")
                } else {
                  disease = "Multiple sclerosis"
                }
                // localStorage.setItem("selected_article", "Industry Updates");

                if (this.isTabletMob == false) {
                    this.$router.push("/").catch(() => {})                        
                    this.l1l2_page_data = {}
                    this.l1l2_page_data.article_title = "Events"
                    this.l1l2_page_data.disease = disease
                    EventBus.$emit("l1l2_page_data", this.l1l2_page_data);
                  } else {
                    // console.log("Tablet or Mobile")
                    this.major_loading = false
                    this.$router.push("/").catch(() => {})                        
                    this.l1l2_page_data = {}
                    this.l1l2_page_data.article_title = "Events"
                    this.l1l2_page_data.disease = disease
                    EventBus.$emit("l1l2_page_data", this.l1l2_page_data);
                  }
                  // console.log(this.user.ID,"this.user.ID")
              // }
            } else {
                   this.logout()
              console.log("Profile not found")
            }
        })
        .catch((error) => {
          console.log(error.message,"error.message")
          if (error.message == "Request failed with status code 500") {
              this.logout()
            console.log(error.message);         
          }
        });
    },
    get_user_profile(token) {
      axios
        .get("/userProfile?token=" + token)
        .then((res) => {
            this.major_loading = false
            //console.log(res.status,"ressss")
            if (res.data.Status != 1) {
              // console.log("Profile found")
              var isfcm_supported = firebase.messaging.isSupported();
              if (isfcm_supported==true){
                this.requestnotificationandtoken(res.data.Data.ID)
              }
              this.$forceUpdate();
              this.userLoggedIn = true;
              this.user = res.data.Data;
              Vue.prototype.$User = this.user;
              //localStorage.setItem("user", JSON.stringify(this.user));
              this.user_company = this.user.Company_name
              this.user_position = this.user.User_position 
              if (this.user_company == "" && this.user_position == "") {
                this.$router.push("/Register").catch(() => {})
                this.flag = false
              }  
            } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
              console.log("Invalid User")
               this.logout()
            } else {
              console.log("Profile not found")
            }         
      })
      .catch((error) => {        
        if (error.message == "Request failed with status code 500") {
          console.log(error.message); 
            this.logout()    
        }
      });
    },
    focus_check() {
      if (this.start_focus_time != undefined) {
        var curr_time = new Date();
        //Lets just put it for 4.5 minutes                                                                                
        if((curr_time.getTime() - this.last_user_interaction.getTime()) > (900 * 1000)) {
          //No interaction in this tab for last 5 minutes. Probably idle.                                               
          this.window_unfocused();
        }
      }
    },
    window_focused(eo) {
      this.last_user_interaction = new Date();
        if (this.start_focus_time == undefined) {
          this.start_focus_time = new Date();                              
      }
    },
    window_unfocused(eo) {
      //console.log(this.start_focus_time,"this.start_focus_time")
      if (this.start_focus_time != undefined) {
        var stop_focus_time = new Date();
        var total_focus_time = stop_focus_time.getTime() - this.start_focus_time.getTime();
        var total_focus_time_sec = this.millisToMinutesAndSeconds(total_focus_time)
        //console.log(total_focus_time_sec,"total_focus_time")
        //this.start_focus_time = undefined;
        var message = {};
            message.type = "time_spent";
            message.domain = document.domain;
            message.time_spent = total_focus_time;
            //chrome.extension.sendMessage("", message);
      }
      //console.log(message,"message sesssion")
    },
    millisToMinutesAndSeconds(millis) {
      var minutes = Math.floor(millis / 60000);
      var seconds = ((millis % 60000) / 1000).toFixed(0);
      return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    },
    logout() {
      // console.log("Logging out")
      var localdata = JSON.parse(localStorage.getItem('mahiti'))
        if (localdata != null && localdata.length > 0){
          // console.log(localdata[1],"localdata[1]")
          axios.put('/deletefcmtoken?fcmtoken='+localdata[1]+'&token=' + this.auth_token ).then(res => {
            if (res.data.value !== 1) {
              console.log("Deleted Token")
            }
          })
         
        }
      localStorage.removeItem("mahiti");
      localStorage.removeItem("authtoken");
      localStorage.removeItem("selectedColorList");
      // localStorage.setItem("selected_article", "Industry Updates");
      this.disesase = "Multiple sclerosis"

      var req = indexedDB.deleteDatabase("fcm_token_details_db");
        req.onsuccess = function () {
          console.log("Deleted database successfully");
      };
      
      delete axios.defaults.headers.common["Authorization"];
      this.auth_token = null;        
      this.$router.push("/").catch(() => {})
    },
    // check_visit() {
    //   var auth_token = localStorage.getItem("authtoken");
    //   if (auth_token == null || auth_token == "" || auth_token == undefined) {
    //     // var firstvisit = localStorage.getItem("visit");
    //     var activity_name="Activity User Registration"
    //     var firstvisit
    //     axios
    //     .get("/getactivity?activity_name=" + activity_name)
    //     .then((res) => {
    //       if (res.data.Status != 1) {
    //           firstvisit="visited"
    //       }else{
    //         firstvisit=""
    //       }
    //     }).catch((err)=>{
    //           console.log(err)
    //     });
    //       console.log("::",firstvisit)
    //     if (firstvisit == null || firstvisit == "" || firstvisit == undefined || firstvisit == "undefined") {
    //       //console.log("Visiting first time")
    //       this.first_time = true
    //       var visit_status = "Visited"
          
    //       axios
    //       .post("/SaveActivity?&token=" + auth_token + "&activity_name=" + activity_name)
    //       .then((res) => {
    //         if (res.data.Status != 1) {
    //           console.log("Activity saved successfully")
    //         } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
    //           this.$router.push("/").catch(() => { })
    //         } else {
    //           console.log("Activity save failed")
    //         }
    //     })

    //       // localStorage.setItem("visit", visit_status);

    //     } else {

    //       //console.log("Visited multiple times")
    //     }
    //   }
    // },
    show_next() {
      this.next = !this.next
    },
      //Firebse Functions
      // getuser() {
      //   axios
      //   .get("/user?token=" + this.auth_token)
      //   .then((res) => {
      //       if (res.data.Status != 1) {
      //         var user_id = res.data.Data.ID
      //         this.requestnotificationandtoken(user_id)
      //         //console.log(this.user_email,"this.user_email")
      //       } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
      //         console.log("Invalid User")
      //       } else {
      //         console.log("User not found")
      //       }
      //   })
      // },
       requestnotificationandtoken (userid){
      

        // console.log("Checking Firebase..")
        var fcm_messaging = this.$messaging
        let _this = this
        // console.log(fcm_messaging,"fcm_messaging")
        this.dialog = true
        // console.log(this.dialog,"this.dialog")

        

        // let token= fcm_messaging.instanceID().token()
        // console.log(token,"token")
        // $('#allow-push-notification').click(function () {
        //   $('#allow-push-notification-bar').hide();
        fcm_messaging.requestPermission()
        .then(function(permission) {
          // console.log(permission,"permission")
            fcm_messaging.getToken()
            .then(function(currentToken) {
              // console.log(currentToken,"currentToken")
                if (currentToken) {
                  if (localStorage.getItem("mahiti") === null) {
                    // console.log("q")
                    _this.deleteFCMToken(currentToken)
                  }else{
                      // console.log("2")
                    var localdata = JSON.parse(localStorage.getItem('mahiti'))
                    if (localdata.length > 0){
                      if(userid != localdata[0] || currentToken != localdata[1]){
                        // console.log(currentToken)
                        // console.log(localdata[1])
                        _this.deleteFCMToken(currentToken)
                      }
                    }else{
                        // console.log("3")
                      _this.deleteFCMToken(currentToken)
                    }
                  }
                } else {
                    // console.log("4")
                    console.log('No Instance ID token available. Request permission to generate one.');
                }
                // console.log("5")
            })
            .catch((error) => {
              // console.log("6")
                console.log('An error occurred while retrieving token. ', error);
              
          });
       
        })
        .catch((error) => {
            console.log(error,"error")
            // _this.allowNotification=true
            // EventBus.$emit('allownotification')
            //emit event to show notification
            
            console.log('Unable to get permission to notify.', error);
           
        });
        // })
        // console.log(8)
      },
      deleteFCMToken(currentToken){
        // console.log("DeletingFcmToken")
        var fcm_messaging = this.$messaging
        let _this = this

       
        fcm_messaging.getToken().then((currentToken) => {
          fcm_messaging.deleteToken(currentToken).then(() => {
            axios.put('/deletefcmtoken?fcmtoken='+currentToken+'&token=' + this.auth_token ).then(res => {
              if (res.data.value !== 1) {
                console.log("Deleted Token")
                _this.getFCMToken()
              }
            })
          }).catch((err) => {
            _this.getFCMToken()
            console.log('Unable to delete token. ', err, currentToken);
          });
          // [END delete_token]
        }).catch((err) => {
          console.log('Error retrieving Instance ID token. ', err);
        });
      },
      getFCMToken(){
        let _this = this
        this.$messaging.getToken()
        .then(function(currentToken) {
          if (currentToken){
            // console.log("Newtoken", currentToken)
                  if (localStorage.getItem("mahiti") === null) {
                    _this.UpdateFCMTOken(currentToken)
                  }else{
                    var localdata = JSON.parse(localStorage.getItem('mahiti'))
                    if (localdata.length > 0){
                      if(_this.userID != localdata[0] || currentToken != localdata[1]){
                        _this.UpdateFCMTOken(currentToken)
                      }
                    }else{
                      _this.UpdateFCMTOken(currentToken)
                    }
                  }
                } else {
                    console.log('No Instance ID token available. Request permission to generate one.');
                }
            })
            .catch(function(err) {
                console.log('An error occurred while retrieving token. ', err);
            });
      },
      UpdateFCMTOken(fcm){
        let _this = this
        axios.post('/fcmtoken?fcmtoken='+fcm+'&platform=Web'+'&token=' + this.auth_token ).then(res => {
          if (res.data.value !== 1) {
            console.log("Success")
            localStorage.removeItem("mahiti");
            var newdata = [_this.user.ID, fcm]
            localStorage.setItem("mahiti", JSON.stringify(newdata))
          }
        })
      },
    save_page_activity(current,previous) {
    //console.log("page activity")
    var disease = ""
    var articleid = ""
    var duration = "0"
    var panel_duration = "0"
    var activity_name = "Page Change"
    var panel_number = "0"
    var pagename = current
    
    
      axios
        .post("/SaveActivity?id=" + articleid + '&token=' + this.auth_token + "&activity_name=" + activity_name + "&disease=" + disease + "&page_name=" + pagename + "&duration=" + duration + "&panel_duration=" + panel_duration + "&current=" + current + "&previous=" + previous)
        .then((res) => {
          if (res.data.Status != 1) {
            //console.log("Activity saved successfully")
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            this.logout()
          } else {
            console.log("Activity save failed")
          }
      })
      .catch((error) => {
        console.log(error.message);
        if (error.message == "Request failed with status code 500") {
          this.logout()             
        }
      });
  
  },
  get_page_activity(){
    axios
      .get("/fetchIndicationSelected?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status != 1) {
            // console.log(res.data,"response.data")
            this.disease = res.data.Data.Indication
            this.$forceUpdate();          
         } 
     }).catch((err)=>{
       console.log(err)
     });
  },
      getSampleKey(){
        axios.get('/getSampleKey' ).then(res => {
          if (res.data.value !== 1) {
            // console.log("Success")
            this.sampleKey = res.data.Data.Key
            this.$router.push("/Sample?sample="+this.sampleKey).catch(() => {})
          }
        })
  
      },
  },
};
</script >
<style>
@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"), url(./fonts/Ubuntu/Ubuntu-Regular.ttf) format("truetype");
}
.app {
  font-family: Ubuntu;
}
.Path-359 {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #ff6750 !important;
  background-color: #fff;
  border-radius: 20px !important;
}
.knolchat_img {
  background-size: cover; 
  height:400px;
  background-repeat: no-repeat; 
  background-image: url('./assets/images/What is KnolChat.png')
}
.knolchat_img_modules {
  background-size: cover; 
  height:400px;
  background-repeat: no-repeat; 
  background-image: url('./assets/images/What are Modules.png')
}
.Path-359 {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #ff6750 !important;
  background-color: #fff;
  border-radius: 20px !important;
}

</style>
