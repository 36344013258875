<template>
  <div class="margin_align" style="margin-top:5vh"> 
    <div>
      <loading
        :active.sync="loading"
        :can-cancel="true"
        :on-cancel="onCancel"
        :is-full-page="fullPage"
        class="loading-overlay"
      ></loading>
    </div>
    <div class="row " v-if="!loading && !isTabletMob ">
      <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" class="first_sec">
        <div style="display: flex;
          justify-content: center; 
          align-items: center;
          height:100%" >
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
              <span class="verification">Payment</span><span class="Ellipse-297_2"></span>
              <div class="Line-291_22"></div>
            </v-col>
          </v-row>
        </div>
              
      </v-col>
      <v-col cols="12" sm="8" md="8" lg="8" xs="8" xl="8"> 
          <v-row>
            <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
            <v-col  cols="12" sm="8" md="8" lg="8" xs="8" xl="8">
              <div  style="margin-top:6vh">
                <span class="welcome">Selected Subscription Plan</span>
              </div>
                
              <v-row  no-gutters justify="center" style="margin-top:5vh;">
                <v-col class="d-flex justify-center" cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                  <v-card class="Rectangle-1151"  v-if="!loading">
                    <div v-if=" checkout_err" >
                      <v-row>
                        <v-col cols="12" sm="1" md="1" lg="1" xs="1" xl="1"></v-col>
                        <v-col cols="12" sm="10" md="10" lg="10" xs="10" xl="10">
                          <div style="background-color:rgb(237, 247, 249) !important">
                            <p style="font-size:16px;color: #384348;font-family: Ubuntu;font-weight: 400;text-align: center;padding:10px;">
                              Error while processing your request. Please try again or contact us at support@knolens.com
                            </p>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="1" md="1" lg="1" xs="1" xl="1"></v-col>
                      </v-row>
                    </div>
                    <!--Standard Plan-->
                    <v-row class="d-flex justify-center" style="margin-top:2vh" v-if=" plan_details.Plan == 'Basic Plan'" wrap>
                          <v-col col="12" sm="3" md="3" lg="3" xs="3" xl="3">
                            <img src="../assets/images/Group 1339.png" style="width:70px;margin-top:-1vh;margin-left:2vh">
                          </v-col>
                          <v-col col="12" sm="9" md="9" lg="9" xs="9" xl="9">
                            <span class="Plan_heading">
                              Starter Plan
                            </span>                          
                          </v-col>
                          <v-row>  
                            <v-col cols="12">
                            
                              <p class="plan_details">All Diseases Included</p>
                              <p class="plan_details">
                                KnolChat: The starter plan gives you unlimited access to KnolChat, a one of its kind, AI powered research assistant that dramatically simplifies your research by providing a natural language interface to answer your questions. It is built on a continuously updated database containing 1500+ diseases, 12000+ private companies (and 100k+ organizations), 4500+ mechanisms of action, and 2800+ drugs.
                                <br><br>
                                Overview by Indications" Analysis Module: The starter plan gives you unlimited access to the "Overview by Indications" Module that covers the state of Therapy of ~150 indications and growing.
                              </p>
                           
                            </v-col> 
                          </v-row>
                    </v-row>                  
                    <div v-if="plan_details.Plan == 'Basic Plan'" style="background-color: #f5f7f9; border-radius: 5px; margin: 5vh 2vh 3vh 2vh; padding: 2vh; display: flex;">
                      <div style="flex: 1;">
                          <span style="font-size: 1.8vh; font-family: Ubuntu;">
                              <span style="font-size: 18px; font-weight: 600;">$599</span><br>USD 599/User/Year
                          </span>
                      </div>
                      <div style="flex: 1; display: flex; justify-content: flex-end;">
                          <v-btn width="auto" class="Path-820_1" @click="makeStripePayment('Yearly')">Proceed to Payment</v-btn>
                      </div>
                  </div>
                  </v-card> 
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
          </v-row>       
        </v-col> 
    </div>
    <div class="row" v-if="isTabletMob" style="height:100vh;">
      <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12"> 
            <!-- <v-row>
              <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                <div style="background-color:#013d48;margin-top:-4vh">
                  <img src="../assets/Icons/Knolense_Dark-01.svg" style="height: 5vh;margin: 3vh 0vh 2vh 2vh !important;cursor:pointer" @click="goToHome()">
                  <span class="Path-68717 pull-right" @click="go_to_login()">Login</span>
                </div>
              </v-col>
            </v-row> -->
            
            <div style="margin:7vh 5vh 0vh 5vh;display:flex;justify-content:center" >
              <span class="welcome">Selected Subscription Plan</span>
            </div>
          
            <div style="display: flex; justify-content: center; overflow-y: auto;">
              <div style="flex: 1;">
                <div class="Rectangle-1151" style="overflow-y: hidden;margin:2vh 4vh;padding:2vh" v-if="!loading">
                  <div v-if="checkout_err">
                    <div style="background-color: rgb(237, 247, 249) !important">
                      <p style="font-size: 2.2vh; color: #384348; font-family: Ubuntu; font-weight: 400; text-align: center; padding: 10px;">
                        Error while processing your request. Please try again or contact us at support@knolens.com
                      </p>
                    </div>
                  </div>
            
                  <!-- Standard Plan -->
                  <div v-if="plan_details.Plan == 'Basic Plan'" style="display: flex; flex-direction:column;margin:0 3vh;align-items:center">
                    <div style="flex: 1 0 0; display: flex; align-items: center;">
                      <img src="../assets/images/Group 1339.png" style="width: 70px; margin-top: -1vh; margin-left: 2vh;">
                    </div>
                    <div style="flex: 3 0 0;">
                      <span class="Plan_heading">Starter Plan</span>
                    </div>
                    <div style="flex: 1 0 0;">
                      <div @scroll="scrolling" style="overflow-y: auto; overflow-x: hidden; max-height: 100vh; height: 30vh;">
                        <p class="plan_details">All Diseases Included</p>
                        <p class="plan_details">
                          KnolChat: The starter plan gives you unlimited access to KnolChat, a one-of-its-kind, AI-powered research assistant that dramatically simplifies your research by providing a natural language interface to answer your questions. It is built on a continuously updated database containing 1500+ diseases, 12000+ private companies (and 100k+ organizations), 4500+ mechanisms of action, and 2800+ drugs.
                          <br><br>
                          Overview by Indications" Analysis Module: The starter plan gives you unlimited access to the "Overview by Indications" Module that covers the state of Therapy of ~150 indications and growing.
                        </p>
                      </div>
                      <div style="display: flex; align-items: center;justify-content:center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <div v-on="on" align="center">
                              <img src="../assets/images/scroll.png" style="cursor: pointer;">
                            </div>
                          </template>
                          <span>Scroll</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                  <div v-if="plan_details.Plan == 'Basic Plan'" style="background-color: #f5f7f9; border-radius: 5px; padding: 2vh; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    <div style="text-align: center;">
                      <span style="font-size: 2vh; font-family: Ubuntu;"><span style="font-size: 18px; font-weight: 600;">$599</span><br>USD 599/User/Year</span>
                    </div>
                    <div>
                      <v-btn class="Path-820_1" @click="makeStripePayment('Yearly')" style="font-size: 2vh;">Proceed to Payment</v-btn>
                    </div>
                  </div>
                  
            
                  <!-- Trial Plan -->
                  <div v-if="myplan == 'Trial'" style="display: flex; flex-wrap: wrap;">
                    <div style="flex: 1 0 0; display: flex; align-items: center;">
                      <img src="../assets/images/Group 1337.png" style="width: 70px; margin-top: -1vh; margin-left: 2vh;">
                    </div>
                    <div style="flex: 3 0 0;">
                      <div style="display: flex;">
                        <div style="flex: 1;">
                          <span class="Plan_heading">Trial Plan</span>
                        </div>
                      </div>
                    </div>
                    <div style="flex: 1 0 0;">
                      <div style="flex: 1;">
                        <p class="plan_details">All Diseases Included</p>
                        <p class="plan_details">Test drive the power of Knolens for free. You will get access to all the capabilities of the starter plan with no restrictions for 7 days.</p>
                      </div>
                    </div>
                  </div>
            
                  <div v-if="myplan == 'Trial'" style="background-color: #f5f7f9; border-radius: 5px; margin: 5vh 2vh 3vh 2vh; padding: 0px 10px 10px 10px;">
                    <div style="flex: 1; align-self: center;">
                      <span style="font-size: 2vh; margin-left: 1vh; font-family: Ubuntu;">Free for first 7 days</span>
                    </div>
                    <div style="flex: 1; align-self: center;">
                      <v-btn class="Path-820" style="width: 140px; margin-top: 2vh; font-size: 1.8vh;" @click="gotohome_page()">Proceed</v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
         
      </v-col>
    </div> 
  </div>
</template>
  <script>
  import axios from "axios";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  import Terms from "../components/Terms.vue";
  import { EventBus } from "../event-bus.js";
  export default {
    name : "plan_payment",
    data() {
      return {
        auth_token: "",
        disesase: "",
        loading: true,
        fullPage: true,
        cart_diseases: [],
        user_email: '',
        show_data: false,
        total_amount: 0,
        plan_details : {},
        show_sub_plan: false,
        sub_plan: '',
        selected_plan: '',
        e1:2,
        show_terms_modal: false,
        agree_to_terms: false,
        payment_done:false,
        checkout_err: false,
        stripe_key:""
      };
    },
    components: {
      Loading,
      Terms
    },
    computed: {
      isTabletMob : function() {
        const iPad = /iPad/i.test(navigator.userAgent);
        const iPhone = /iPhone/i.test(navigator.userAgent);
        const Android = /Android/i.test(navigator.userAgent);
        const webOS = /webOS/i.test(navigator.userAgent);
        const iPod = /iPod/i.test(navigator.userAgent);
        const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
        const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);
        return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
      }, 
    },
    mounted() {
      //console.log("profile setting")
      //set local storage selected article
      
      
      var path=localStorage.getItem("selected_article")
      if(path=="Transaction Success" ){
        console.log("path",path)
       
        this.$router.push("/")
        location.reload()
      }
      EventBus.$on("payment_done", (data) => {
        console.log("payment_done")
        this.payment_done = true
      });

      if (this.payment_done) {
        this.$router.push("/")
      }
      this.selected_plan = this.$route.params.plan

      // console.log(this.selected_plan,"this.selected_plan")
      //localStorage set plan
      localStorage.setItem("plan", this.selected_plan)
  
      // var disesase = localStorage.getItem("selected_disease")
      this.get_page_activity()
      this.getPaymentReleatedKey()
      var disesase=this.disesase
      if (disesase !== null && disesase !== "undefined" && disesase !== "") {
        this.disesase = disesase
      } else {
        this.disesase = "Multiple sclerosis"
      }
  
      var auth_token = localStorage.getItem("authtoken");
      
      if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
        this.auth_token = auth_token
        this.getuser()
        // this.checkTermsAgreed()
      }
    },
    methods: {
      onCancel() {
        console.log('User cancelled the loader.')
      },
      checkTermsAgreed(){
        //create get request
        axios.get("/checktermsagreed?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status != 1) {

            if (res.data.Data.Agreed==true) {
              this.agree_to_terms = true
              this.e1=3
            }else{
              this.show_terms_modal = true
            }

          } else {
           console.log("Terms not agreed")
          }
        }).catch((error) => {
          console.log(error.message);
          if (error.message == "Request failed with status code 500") {
            this.logOut()             
          }
        });
      },
      getuser() {
        axios
          .get("/user?token=" + this.auth_token)
          .then((res) => {
            if (res.data.Status != 1) {
              this.user_email = res.data.Data.Email
              this.getuserProfile()
              this.get_wishlist()
            } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
              console.log("Invalid User");
              this.logOut()
            } else {
              console.log("User not found")
            }
        })
        .catch((error) => {
          console.log(error.message);
          if (error.message == "Request failed with status code 500") {
            this.logOut()             
          }
        });
      },
      get_wishlist() {
        // this.loading = true
        axios
          .get("/getuserdiseasecart?token=" + this.auth_token)
          .then((res) => {
            if (res.data.Status != 1) {
              // this.loading = false
              this.cart_diseases = res.data.Data.user_diseases_cart.Diseases
              //console.log(this.cart_diseases,"this.cart_diseases")
              if (this.cart_diseases.length > 0) {
                this.total_amount = this.cart_diseases.length * 250
                this.show_data = true
              }             
            } else {
              // this.loading = false
              console.log("cart_diseases not found")
            }
        })
        .catch((error) => {
          console.log(error.message);
          // if (error.message == "Request failed with status code 500") {
          //   this.logOut()             
          // }
        });
      },
      getPaymentReleatedKey(){
      axios.post("/getPaymentReleatedKey?token="+this.auth_token)
      .then(response => {
        this.stripe_key=response.data.Data
      })
      .catch(error => {
        console.log(error);
      });
   
    },
      makeStripePayment(duration) {
        this.loading = true
        //console.log(this.selected_plan,"this.selected_plan")
        if (this.selected_plan == "My Team Plan") {
          this.sub_plan = ""
        }
        var currentUrl= window.location.href
        //console.log(axios.defaults.baseURL,"payment url....")
        var stripe=Stripe(this.stripe_key)
        axios.post(`/create-checkout-session?&token=` + this.auth_token + "&duration=" + duration + "&plan=" + this.selected_plan + "&subplan="+ this.sub_plan)
          .then(res => {
          //console.log(res.data.Data,"res.data.Data")
          if (res.data.Status !== 1) {
            const id = res.data.Data.Id;
            stripe.redirectToCheckout({sessionId: id});
            this.loading = false
          } else {
            this.loading = false
            this.err_flag = true
            console.log("error in payment redirecting")
          }
        })
      },
      gotohome() {
        this.$router.push("/").catch(() => {}) 
        this.l1l2_page_data = {}
        this.l1l2_page_data.article_title = "Events"
        this.l1l2_page_data.disease = this.disesase
        EventBus.$emit("l1l2_page_data", this.l1l2_page_data);
      },
      logout() {
        EventBus.$emit("logout", this.auth_token)
        localStorage.removeItem("selectedColorList");
      },
      delete_my_disease(dis) {
            this.loading = true      
            axios
            .put("/deletecartitem?token=" + this.auth_token + "&disease_name=" + dis.Disease_name)
            .then((res) => {
                if (res.data.Status != 1) {
                this.loading = false
                this.cart_diseases = []
                this.total_amount = 0
                this.cart_diseases = res.data.Data.userdiseasescart.Diseases
                if (this.cart_diseases.length > 0) {
                    this.total_amount = this.cart_diseases.length * 250
                    this.show_data = true
                } else {
                    this.total_amount = 0
                    this.show_data = false
                }
                //console.log(this.cart_diseases,"this.cart_diseases")
                } else {
                this.loading = false
                console.log("cart not found")
                }
            })
            .catch((error) => {
            console.log(error.message);
            // if (error.message == "Request failed with status code 500") {
            //     this.logOut()             
            // }
            });
      },
        getuserProfile(){
          axios
            .get("/userProfile?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status !== 1) {
                this.user = res.data.Data;
                var mysubplan = this.user.Subplan
                if (mysubplan == "Single Disease Plan") {
                  this.my_sub_plan = mysubplan
                } else if (mysubplan == "Unlimited Disease Plan") {
                  this.my_sub_plan = mysubplan
                } else {
                  this.my_sub_plan = ""
                }

                this.getPlanDetails()
                //console.log(this.user,"this.user")
              } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
                console.log("Invalid User")
              } else {
                console.log("Diseases not found")
              }
            })
            .catch((error) => {
              console.log(error.message);
              // if (error.message == "Request failed with status code 500") {
              //   this.logOut()             
              // }
            });
        },
        agreeTerms(a){
        console.log(a,"a")
      //create get request to update profile
          if (a){
            axios.get("/updateAgreeTerms?token="+this.auth_token).
            then((res) => {
              if (res.data.Status != 1) {
                console.log("terms updated")
                
                this.e1=3
                var plan="Basic Plan"
              // this.$router.push('/User_plan_payment/'+plan)
              } else {
                console.log("terms updated")
              }
            })
          }
     
      },
        getPlanDetails(){
            this.plan_details = {}
            this.loading = true
            var url = ""
            var url11 = "/getUserPlanDetails?token=" + this.auth_token + "&plan=" + this.selected_plan
            var url22 = "/getUserSubPlanDetails?token=" + this.auth_token + "&subplan=" + this.my_sub_plan
            if (this.my_sub_plan == "") {
              url = url11
            } else {
              url = url22
            }
            axios
            .get(url)
            .then((res) => {
                if (res.data.Status != 1) {
                    
                    this.plan_details = res.data.Data
                    //console.log(this.plan_details,"this.plan_details") 
                    var subpln = res.data.Data.Subplan
                    if (subpln == "Single Disease Plan") {
                      this.sub_plan = "Sub_plan_1"
                    } else if (subpln == "Unlimited Disease Plan") {
                      this.sub_plan = "Sub_plan_2"
                    } else {
                      this.sub_plan = ""
                    }
                    //console.log(this.sub_plan,"this.sub_plan")
                    if (this.sub_plan == "Sub_plan_1" || this.sub_plan == "Sub_plan_2") {
                      this.show_sub_plan = true
                      this.show_data = true
                    } else {
                      this.show_sub_plan = false
                    } 
                    this.loading = false
                    //console.log(this.show_data,"this.show_data")       
                } else {
                    this.loading = false
                }
            })
            .catch((error) => {
                console.log(error.message);
                // if (error.message == "Request failed with status code 500"){
                //     this.logOut()             
                // }
            });
        },
        get_page_activity(){
        axios
            .get("/fetchIndicationSelected?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status != 1) {
                console.log(res.data,"response.data")
                this.disesase = res.data.Data.Indication
                this.$forceUpdate();          
              } 
          })
  },
    },
  };
  </script>
  <style scoped>
    .margin_align {
      overflow-x: hidden;
      overflow-y: hidden;
      height: 100vh !important;
     
    }
   
    .mycart {
      margin: 3vh 0vh 2vh 0vh;
      font-family: Ubuntu;
      font-size: 23px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: center !important;
      color: #32324a;
    }
  
    .BACK {
      margin: 2vh 0vh 2vh 0vh;
      font-family: Ubuntu;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.43;
      letter-spacing: normal;
      text-align: left;
      color: #03a6ad;
      cursor: pointer;
    }
    .description {
      margin: 2vh 0vh 2vh 0vh;
      font-family: Ubuntu;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.89;
      letter-spacing: normal;
      text-align: left;
      color: #676772;
    }
    .number {
      margin: 2vh 0vh 2vh 0vh;
      padding: 1vh;
      font-family: Ubuntu;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.78;
      letter-spacing: normal;
      text-align: left;
      color: #727272;
    }
  
    .Rectangle-1208 {
      margin: 1vh 1.5vh 1vh 1.5vh!important;
      padding: 0.5vh;
      border-radius: 9px !important;
      border: solid 1px #4a4a5c;
      background-color: #fff;
    }
  
    .content {
      font-family: Ubuntu;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #202527;
    }
    
    .Rectangle-1212 {
      margin: 1vh 1vh 1vh 1vh!important;
      padding: 1vh !important;
      border-radius: 9px;
      border: solid 1px #03a6ad;
      background-color: #f7faff;
    }
  
    .Ellipse-153 {
      width: 90px;
      height: 90px;
      margin: 2vh;
      padding: 25px;
      border-radius: 90px;
      border: solid 1px #d6d6d6;
      background-color: #f8f8f8;
    }

    .Rectangle-1203 {
      margin: 0vh 0vh 0 0vh;
      padding: 3vh !important;
      border-radius: 31px;
      box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px #707070 !important;
      background-color: #fff;
    }
    .Rectangle-1151 {
      margin: 10px 0px 10px 0px;
      padding: 20px 30px 20px 20px;
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
      max-height:80vh;
      overflow-y: auto;
    }
    .Plan_heading {
      font-family: Ubuntu;
      font-size: 3.5vh;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.32;
      letter-spacing: normal;
      text-align: left;
      color: #005290;
    }
    .plan_details {
      margin: 2vh 0vh 0vh 2vh;
      font-family: Ubuntu;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
      color: #909090;
    }

    .Path-820_1 {
      margin-top:5px;
      padding: 10px 30px 10px 30px;
      background-color: #03a6ad !important;
      font-family: Ubuntu;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color:#ffffff;
      border-radius: 5px !important;
      font-size:1.7vh;
     
    }

    .first_sec {
      height:110vh;
      padding: 10px 40px 10px 10px;
      margin-bottom:5vh;
      background-color: #013d48;
    }
    .Ellipse-297 {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #f16753;
      float: right;
      margin-top:6px;
    }
    .Ellipse-297_2 {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #f16753;
      float: right;
      margin-top:6px;
      margin-right:2vh;
    }
    .Ellipse-298 {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #03a6ad;
      float: right;
      margin-top:6px;
      margin-right:2vh;
    }
    .step1 {
      margin-left: 10px;
      font-family: Ubuntu;
      font-size: 13px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #fff;
      float: right;
    }
    .new_login {
      font-family: Ubuntu;
      font-size: 25px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.15;
      letter-spacing: normal;
      color: #03a6ad;
      text-align: right !important;
      float:right;
    }
    .new_login_11 {
      font-family: Ubuntu;
      font-size: 25px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.15;
      letter-spacing: normal;
      color: #03a6ad;
      text-align: right !important;
      float:right;
    }
    .Line-291 {
      margin-top: 3vh;
      /* margin-left: 200px !important;
      margin-right:-40px !important; */
      margin-right:-40px;
      width: 120% !important;
      height: 3px !important;
      background-color: #f16753;
      float: right;
    }
    .Line-291_0 {
      margin-top: 3vh;
      /* margin-left: 200px !important;
      margin-right:-40px !important; */
      margin-right:-40px;
      width: 120% !important;
      height: 3px !important;
      background-color: #03a6ad;
      float: right;
    }
    .Line-291_26 {
      margin-top: 3vh;
      /* margin-left: 200px !important;
      margin-right:-38px !important; */
      margin-right:-40px;
      width: 120% !important;
      height: 3px !important;
      background-color: #03a6ad;
      float: right;
    }
    .Line-291_22 {
      margin-top: 3vh;
      /* margin-left: 200px !important;
      margin-right:-112px !important; */
      margin-right:-40px;
      width: 120% !important;
      height: 3px !important;
      background-color: #f16753;
      float: right;
    }
    .Line-291_3 {
      margin-top: 3vh;
      /* margin-left: 200px !important;
      margin-right:-38px !important; */
      margin-right:-40px;
      width: 120% !important;
      height: 3px !important;
      background-color: #f16753;
      float: right;
    }
    .Line-291_33 {
      margin-top: 3vh;
      /* margin-left: 200px !important;
      margin-right:-112px !important; */
      margin-right:-40px;
      width: 120% !important;
      height: 3px !important;
      background-color: #f16753;
      float: right;
    }
    .step2 {
      margin-left: 10px;
      font-family: Ubuntu;
      font-size: 13px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #939393;
      float: right;
    }
    .verification {
      font-family: Ubuntu;
      font-size: 25px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.15;
      letter-spacing: normal;
      color: #f16753;
      text-align: right !important;
      float:right;
    }
    .if-you {
      margin: 0px 0px 0px 0px !important;
      font-family: Ubuntu;
      font-size: 2vh;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
    .Path-820 {
      margin-top:10px;
      padding: 10px 20px 10px 20px;
      background-color: #03a6ad !important;
      font-family: Ubuntu;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color:#ffffff;
      border-radius: 5px !important;
    }
    
    .Path-820_1 {
      margin-top:5px;
      padding: 10px 30px 10px 30px;
      background-color: #03a6ad !important;
      font-family: Ubuntu;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color:#ffffff;
      border-radius: 5px !important;
      font-size:1.7vh;
      margin-top:5px
    }

    .welcome {
      font-family: Ubuntu;
      font-size: 20px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      text-align: left;
      color: #202527;
    }
    .loading-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color:#fff; /* Adjust the transparency as needed */
      z-index: 1000; /* Ensure it's above other content */
      display: flex;
      justify-content: center;
      align-items: center;
    }
</style>
          