<template>
  <div style="overflow-y: auto;overflow-x: hidden;">
    <div class="row" v-if="!isTabletMob">
      <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" class="first_sec">
        <div>
          <img src="../assets/Icons/Knolense_Dark-014.svg" style="height: 6vh;margin: 10.5vh 0vh 2vh 17vh !important;cursor:pointer" @click="goBack()">
        </div>
        <div style="margin-top:15vh;margin-bottom:20vh">
          <v-row>          
            <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
              <span :class="show_otp_modal ? 'new_login_11' :'new_login'">Login</span><span :class="show_otp_modal ? 'Ellipse-298': 'Ellipse-297'"></span>
              <div :class="show_otp_modal ?'Line-291_0':'Line-291'"></div>
            </v-col>          
          </v-row>
          <v-row style="margin-top:5vh">
            <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
              <span :class="show_otp_modal ? 'new_login':'verification'">Verification</span><span :class="show_otp_modal ? 'Ellipse-297' : 'Ellipse-297_2'"></span>
              <div :class="show_otp_modal ? 'Line-291_3' : 'Line-291_2'"></div>
            </v-col>
          </v-row>
        </div>
        <v-row style="bottom:25px;position: fixed">
          <span class="if-you">If you don't have an account, Register here</span>
          <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" ></v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" >
            <div><v-btn class="Path-820" @click="goto_register()">Register</v-btn></div>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" ></v-col>
        </v-row>
      </v-col> 
      <v-col cols="12" sm="8" md="8" lg="8" xs="8" xl="8" >
        <v-row>
          <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
            <div style="margin-top:10vh">
              <span class="welcome">Welcome !</span>
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3" >
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6"  class="pb-0">
            <span class="label Email_input">Email</span>
            <div style="display:flex;flex-direction:row;margin-top: 2vh;justify-content:space-between">
                <v-text-field style="font-family:Ubuntu;font-size: 1.8vh;color: #aaaaaa;" v-model="email" type="email" outlined
                dense  v-on:keyup="validateEmail" :rules="emailRules" placeholder="Enter Email"></v-text-field>
               
                <!-- <v-menu                    
                v-model="showVideo"
                :close-on-content-click="false"
                :close-on-click="false"  
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="{ ...on, click: () =>  accessS3Video('loginvideo.mp4') }"
                    icon
                  >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        style="color:#03a6ad;font-size:4vh;cursor:pointer"
                      >
                        mdi-play-circle-outline
                      </v-icon>
                    </template>
                    <span>Tutorial Video</span>
                  </v-tooltip>
                  </v-btn>
                </template>
                <v-card v-if="showVideo" >
                <v-row wrap no-gutters style="background-color:black">
                  <v-col align=right>
                    <v-btn style="cursor:pointer" icon @click="showVideo=false">
                      <v-icon style="color:#fff;">mdi-close</v-icon>
                    </v-btn>
                  </v-col>                          
                  </v-row>
                  <v-card-text style="padding:0; position: relative;">
                    <video id="#loginvideo" width="600" height="400" style="padding:1vh" controls autoplay @click.stop @contextmenu.prevent :controlsList="['nodownload']">
                      Your browser does not support the video tag.
                    </video>
                    <div v-if="showLoading" style="position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 9999;" class="loading-overlay">
                      <v-progress-circular indeterminate color="#ffffff"></v-progress-circular>
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>    -->
              </div>            
            <v-row v-if="invalid_email">
                <span class="try_again_msg">
                  {{ message }}
                </span>
            </v-row>
            <v-row v-if="spinner">
              <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" ></v-col>
              <v-col cols="6" sm="2" md="2" lg="2" xs="2" xl="2" class="">
                <div slot="spinner" v-if="spinner" style="margin-left:2vh">
                  <b-spinner style="color: #12888e" label="Text Centered"></b-spinner>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6" ></v-col>
            </v-row>
            <v-row  v-if="show_pricing">
                <span v-show="spinner==false" @click="goto_register()" class="try_again_msg_1" style="margin-left:1vh !important;">Account does not exist. <span class="try_again_msg" style="text-decoration:underline;cursor:pointer">Please register.</span> </span>
                <v-btn v-show="spinner==false" @click="goToLogin()" class="Path-820" style="width:170px;margin-left:2vh;margin-top:2vh">
                  Try Again
                </v-btn>
            </v-row>
            <v-row v-if="!show_pricing && !show_otp_modal">
              <v-col cols="12" sm="9" md="9" lg="9" xs="9" xl="9">
                <v-btn @click="send_email()" style="width:auto !important" :class="{ disabled: isDisabled }" :disabled="isDisabled"  :style="!isDisabled ? 'width:auto !important;height:40px;padding: 10px;border: solid 1px #f16753;background-color: #fff;color: #f16753;font-family: Ubuntu;font-size: 15px;font-weight: normal;font-stretch: normal;font-style: normal;line-height: 1.88;letter-spacing: normal;text-align: left !important;box-shadow: none;' : 'border:none;width:auto !important;height:40px;'">
                  Send Verification Code to Registered Email ID
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="show_otp_modal">
              <v-col cols="12" sm="9" md="9" lg="9" xs="9" xl="9">
                <div class="path_1145">
                  <v-row wrap>
                    <img src="../assets/Icons/Group 12390.svg" style="width:40px;height:40px;margin-left:1vh">
                    <span class="Confirmation">Confirmation</span>
                  </v-row>
                  <v-row>
                    <span class="We-have">We have just emailed you the verification code to {{email}} . Please check and confirmed with us.</span>
                    <div>
                      <span class="We-have">If you don't get it</span><span class="otpRESEND" @click="resend_otp()">RESEND</span>
                    </div>
                    <div slot="spinner" v-if="resend_spinner" style="margin-left:2vh;margin-top:2vh">
                      <b-spinner style="color: #12888e;width:3vh;height:3vh" label="Text Centered"></b-spinner>
                    </div>
                    <div v-if="incorrect_code && otp!=''" style="margin-left:1vh;margin-top:2vh;margin-bottom: 2vh;">
                      <span class="try_again_msg" style="font-size:15px">
                        {{otp_message}}
                      </span>
                    </div>
                    
                    <v-otp-input
                      v-model="otp"
                        style="margin-top:1vh;margin-left:1vh"
                        plain
                        
                    ></v-otp-input>
                    <v-btn class="Path-820" style="width:170px;margin-left:2.5vh;margin-top:2vh;margin-bottom: 2vh"  @click="verifyOtp()">Verify & Proceed</v-btn>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
        </v-row>
      </v-col>
    </div>
    <div class="row" v-if="isTabletMob" style="height:100vh;margin-bottom: 3vh;">
      <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
      <div  style="background-color: #013d48; margin-top: -3vh; position: fixed; width: 100%; left: 0;z-index:1;display:flex;flex-direction:row;align-items:flex-end;justify-content:space-between">
        <img src="../assets/Icons/Knolense_Dark-01.svg" style="height: 4vh;margin: 3vh 0vh 2vh 2vh !important;cursor:pointer" @click="goToHome()">
        <span class="Path-68717 pull-right" @click="goto_register()" style="margin-top:2vh">Register</span>
      </div>
        
        <div style=margin-top:6vh>
          <v-row  v-if="show_otp_modal" wrap no-gutters style="margin-top:2vh">
            <v-col  cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
              <div style="text-align: left;margin-left:3vh">
                <span @click="show_otp_modal=false" style="color:#03a6ad;cursor:pointer">Back</span>
              </div>
            </v-col>
          </v-row>
          <v-row wrap no-gutters>
            <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
              <div style="text-align: center;margin-top:4vh">
                <span class="welcome">Login</span>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="!show_otp_modal" style="margin-top: -3vh;">
            <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                <span class="label Email_input" style="margin-left:7vh">Email</span>
                <div align="center">         
                  <v-text-field  style="margin-top:2vh; max-width: 80vw !important;font-family:Ubuntu;font-size: 1.8vh;color: #aaaaaa;" v-model="email" type="email" outlined
                  dense  v-on:keyup="validateEmail" :rules="emailRules" placeholder="Enter Email"></v-text-field>
                </div>
                <v-row v-if="invalid_email" >
                  <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12" align="center">
                  <span class="invalid_email">
                    {{ message }}
                  </span>
                  </v-col>  
                </v-row>
                <v-row v-if="spinner">
                  <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12" align="center">
                    <div slot="spinner" v-if="spinner">
                      <b-spinner style="color: #12888e" label="Text Centered"></b-spinner>
                    </div>
                  </v-col>
                </v-row>
                <v-row  v-if="show_pricing">
                  <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12" align="center">
                  <span v-show="spinner==false" @click="goto_register()" class="try_again_msg_1" style="margin-left:1vh !important;">Account does not exist. <span class="try_again_msg" style="text-decoration:underline;cursor:pointer">Please register.</span> </span>
                  <v-btn v-show="spinner==false" @click="goToLogin()" class="Path-820" style="width:170px;margin-left:2vh;margin-top:2vh">
                    Try Again
                  </v-btn>
                </v-col>
              </v-row>
                <v-row v-if="!show_pricing && !show_otp_modal">
                  <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12" align="center" >
                    <v-btn style="width:auto !important" @click="send_email()" :class="{ disabled: isDisabled }" :disabled="isDisabled"  :style="!isDisabled ? 'height:45px;padding: 10px;border: solid 1px #f16753;background-color: #fff;color: #f16753;font-family: Ubuntu;font-size: 2vh;font-weight: normal;font-stretch: normal;font-style: normal;line-height: 1.88;letter-spacing: normal;text-align: left !important;box-shadow: none;' : 'border:none important;height:40px;margin-left:0vh;margin-right:0vh;font-size:2vh'">
                      Send Verification Code to Registered Email ID
                    </v-btn>
                  </v-col>
                </v-row>
             
            </v-col>
          </v-row>
          <v-row v-if="show_otp_modal">
            <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12" align="center">
              <div class="path_1145_1">
                <v-row wrap>
                  <img src="../assets/Icons/Group 12390.svg" style="width:40px;height:40px;margin-left:1vh">
                  <span class="Confirmation">Confirmation</span>
                </v-row>
                <v-row>
                  <span class="We-have">We have just emailed you the verification code to {{email}} . Please check and confirmed with us.</span>
                  <div align="left">
                    <span class="We-have">If you don't get it</span><span class="otpRESEND" @click="resend_otp()">RESEND</span>
                  </div>
                  <div slot="spinner" v-if="resend_spinner" style="margin-left:2vh;margin-top:2vh">
                    <b-spinner style="color: #12888e;width:3vh;height:3vh" label="Text Centered"></b-spinner>
                  </div>
                  <div v-if="incorrect_code && otp!=''" style="margin-left:1vh;margin-top:2vh;margin-bottom: 2vh;">
                    <span class="try_again_msg" style="font-size:15px">
                      {{otp_message}}
                    </span>
                  </div>
                  
                  <v-otp-input
                    v-model="otp"
                      style="margin-top:1vh;margin-left:1vh"
                      plain
                      
                  ></v-otp-input>
                  <div align="center">
                    <v-btn class="Path-820" style="width:170px;margin-top:2vh;margin-bottom: 2vh"  @click="verifyOtp()">Verify & Proceed</v-btn>
                  </div>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </div>
        
        <!-- <v-row align="center" style="margin-left:4vh">
          <span class="if-you" style="color:#202527">If you don't have an account, Register here</span>
          <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" ></v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" >
            <div><v-btn class="Path-820" @click="goto_register()">Register</v-btn></div>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" ></v-col>
        </v-row> -->
      </v-col>
    </div>
  </div>

</template>

<script>
//import Carousel from "../components/Carousel.vue";
import axios from "axios";
import { EventBus } from "../event-bus.js";



export default {
  data() {
    return {
      flag: true,
      auth_token: "",
      message: "",
      error: false,
      email: "",
      error: "",
      otp: "",
      loading: false,
      show_getstarted: false,
      otp_message: '',
      auth_token: '',
      email_valid: false,
      spinner: false,
      resend_spinner: false,
      show_resend: false,
      show_pricing: false,
      invalid_email: false,
      show_otp_modal: false,
      incorrect_code:false,
      emailRules: [ 
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ],
      originalHeight: window.innerHeight,
      hideAppbar: false,
      showVideo: false,
      showLoading: false,

    };
  },
  components: {
   
    //Carousel,
  },
  created() {
    
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);

    // Initial check for keyboard presence on mount
    this.checkForKeyboard();
   
    var auth_token = localStorage.getItem("authtoken");
    if (auth_token !== null && auth_token !== "undefined" && auth_token !== "") {
      this.auth_token = auth_token
    } else {
      console.log("no authentication")
      this.auth_token = ""
    }
  },
  beforeDestroy() {
   
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {

    isDisabled() {
     
      if (this.email !== "") {
        if (this.emailRules[0](this.email) != true) {
        return true;
      }
        return false;
      } else {
        return true;
      }
    },
    isTabletMob : function() {
      const iPad = /iPad/i.test(navigator.userAgent);
      const iPhone = /iPhone/i.test(navigator.userAgent);
      const Android = /Android/i.test(navigator.userAgent);
      const webOS = /webOS/i.test(navigator.userAgent);
      const iPod = /iPod/i.test(navigator.userAgent);
      const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
      const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);
      return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
    }
  },
  methods: {
    accessS3Video(key) {
      this.showLoading = true; // Show loading overlay when video starts loading
      this.showVideo = true; // Show video element when video starts loading
      axios.post('/getVideo?key='+key)
      .then(response => {
          var video = document.getElementById('#loginvideo');
          video.src=response.data.Data.Url;
          video.play();
          this.showLoading = false; // Hide loading overlay when video source is available
      })
      .catch(error => {
        console.error('Error accessing S3 video:', error);
        this.showLoading = false; // Hide loading overlay on error
      });
    },
    goBack(){
      this.$router.push("/").catch(() => {})
    },
    ValidateEmailtext(emailtext) {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (String(emailtext).match(mailformat)) {
        return true;
      } else {
        return false;
      }
    },
    validateEmail(e) {
      if (e.keyCode === 13) {
        this.send_email();
      } else {
        console.log();
      }
    },
    validateOTP(e) {
      if (e.keyCode === 13) {
        this.send_otp();
      } else {
        console.log("entering OTP");
      }
    },
    send_email() {
      console.log(this.email, "send email")
      this.spinner = true
      var validmsg = this.ValidateEmailtext(this.email)
      if (this.email != '') {
        if (validmsg == true) {
          this.show_resend = true
          axios
            .post(
              "/userlogin?email=" + this.email
            )
            .then((res) => {
              console.log(res.data.Data.Newuser, "Newuser")
              if (res.data.Status == 0 && res.data.Data.Newuser == true) {
                console.log("new user found")
                this.spinner = false
                this.message = ""
                this.email_valid = false
                this.show_pricing = true
                this.$router.push("/Login").catch(() => {});

              } else if (res.data.Status == 0 && res.data.Data.Newuser == false) {
                console.log("User already created")
                this.spinner = false
                // this.message = "We have sent Multifactor Authentication Code to your email address. Please check and enter the code."
                this.email_valid = true
                this.show_pricing = false
                this.show_otp_modal=true
              } else if (res.data.Status == 0 && res.data.Data.Authentication_token) {
                this.spinner = false
                this.auth_token = res.data.Data.Authentication_token
                localStorage.setItem("authtoken", this.auth_token);
                EventBus.$emit("login", this.auth_token);
              } else {
                this.spinner = false
                this.message = "Invalid email, please try again"
              }
            })
        } else {
          this.spinner = false
          this.show_pricing = false
          this.email_valid = false
          this.show_resend = false
          this.invalid_email=true
          this.message = "Invalid email, please try again"
        }
      } else {
        this.spinner = false
        this.message = "Please enter email"
      }
    },
    send_otp() {
      this.incorrect_code=false
      if (this.otp != '') {
        axios
          .post(
            "/validateOTP?email=" + this.email + "&otp=" + this.otp
          )
          .then((res) => {
            //console.log(res, "response otp")
            if (res.data.Status == 0) {
              this.auth_token = res.data.Data.Authentication_token
              //console.log(this.auth_token,"this.auth_token")
              localStorage.setItem("authtoken", this.auth_token);
              this.incorrect_code=false
              //this.show_otp_modal = false
              EventBus.$emit("login", this.auth_token);
            } else if (res.data.Status == 1) {
              this.incorrect_code=true
              this.otp_message = "Incorrect Multifactor Authentication Code"
            } else {
              this.otp_message = "Please try again"
            }
          })
      } else {
        // this.message = "Please enter Multifactor Authentication Code"
      }
    },
    resend_otp() {
      this.incorrect_code=false
      this.otp = ''
      this.resend_spinner = true
      this.message = ''
      var validmsg = this.ValidateEmailtext(this.email)

      if (this.email != '') {
        if (validmsg == true) {
          axios
            .post(
              "/resendOTP?email=" + this.email
            )
            .then((res) => {
              if (res.data.Status == 0) {
                console.log("success")
                this.resend_spinner = false
                // this.message = "We have sent Multifactor Authentication Code to your email address. Please check and enter the code."
                this.email_valid = true
              } else {
                console.log("Error")
                this.resend_spinner = false
                // this.message = "Invalid email, please try again"
                this.message = ""
                this.email_valid = false
                this.show_pricing = true
              }
            })
        } else {
          this.resend_spinner = false
          this.message = "Invalid email, please try again"
        }
      } else {
        this.resend_spinner = false
        this.message = "Please enter email"
      }
    },
    send_default_email(email) {
      console.log(email)
      this.isDisabled = true
    },
    goto_register() {   
      this.$router.push("/Pricing").catch(() => {});
    },
    verifyOtp(){
      console.log("verify otp")
      this.send_otp()
      
     
      this.$forceUpdate()
      
    },
    close_otp_modal(){
      this.show_otp_modal=false
      this.otp=''
      this.incorrect_code=false
    },
    goToLogin(){
      this.send_email()
    },
    goToHome(){
      this.$router.push("/").catch(() => {});
    },
    handleScroll() {
      //create a event to tell appbarto hide
      this.EventBus.$emit("hideAppbar");  

    },
    handleResize() {
      this.checkForKeyboard();
    },
    checkForKeyboard() {
      // Check if the window inner height changes (keyboard open/close)
      const newHeight = window.innerHeight;

      if (newHeight < this.windowHeight) {
        this.isHidden = true;
        this.EventBus.$emit("hideAppbar");  
        this.hideAppbar=true
      } else if (newHeight > this.windowHeight) {
        this.isHidden = false;
        this.EventBus.$emit("showAppbar");  
        this.hideAppbar=false
      }
      this.windowHeight = newHeight;
    },
  },
 

};
</script>

<style lang="scss" scoped>
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 45px !important;
}

/* Control the left side */
.left {
  left: 0;
  background-color: #fff;
}

image {
  height: 100%;
}

/* Control the right side */
.right_section {
  margin-left: 5vh;
  // margin-right: 5vh;
  background-color: #ffffff;
  height: 100%;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.centered {
  margin: 10vh 5vh 1vh 5vh;

}

.v-carousel__controls {
  // background: rgba(0,0,0,.3);
  bottom: 0;
  // display: flex;
  height: 50px;
  // justify-content: right;
  list-style-type: none;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.v-btn__content {
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  line-height: normal;
  position: relative;
  transition: inherit;
  transition-property: opacity;
}

.PageView {
  font-family: Ubuntu;
}

.picture {
  height: 100%;
}

.title {
  font-family: Ubuntu;
  font-size: 35px;
  font-weight: bold;
}

.centered p {
  margin-bottom: 4vh;
  opacity: 0.7;
  font-size: 19px;
  font-weight: 300;
  text-align: left;
  color: #4b4b4b;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.logInBox {
  margin-top: 3vh;
  margin-bottom: 1vh;
  width: 75vw;
  font-family: Ubuntu
}

h6 {
  opacity: 0.81;
  font-size: 13.5px;
  font-weight: normal;
  margin-top: 0.5vh;
  color: #4b4b4b;
}

.v-divider--vertical {
  border: solid;
  border-width: 0 thin 0 0;
  border-color: black !important;
  float: left;
  margin-right: 10px !important;
  height: 4.5vh;
}

.subHead {
  font-size: 2.1vh;
  font-weight: 600;
  color: #4b4b4b;
}

.v-text-field--outlined {
  max-width: 28vw;
  max-height: 7.5vh !important;
}

.signUp {
  margin-top: 3vh;
  width: 100px;
  border: 2px solid #009ea5;
  font-size: 16px;
  font-weight: 600;
  color: #009ea5;
}

.KnoLens-custom {
  width: 100%;
  margin: 1vh 0vh 1vh 0vh;
  font-family: Ubuntu;
  font-size: 2.1vh !important; //17px
  font-weight: 700 !important;
  font-stretch: normal;
  font-style: normal;
  //line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #0b4f52 !important;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
.first_sec {
  height:110vh;
  padding: 10px 40px 10px 10px;
  margin-bottom:5vh;
  background-color: #013d48;
}
.Ellipse-297 {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #f16753;
  float: right;
  margin-top:6px;
  margin-right:2vh;
}
.step1 {
  margin-left: 10px;
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  float: right;
}
.new_login {
  font-family: Ubuntu;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  color: #f16753;
  text-align: right !important;
  float:right;
}
.step2 {
  margin-left: 10px;
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #939393;
  float: right;
}
.Ellipse-297_2 {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #939393;
  float: right;
  margin-top:6px;
  margin-right:2vh;
}
.verification {
  font-family: Ubuntu;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  color: #939393;
  text-align: right !important;
  float:right;
}

.if-you {
  margin: 0px 0px 0px 0px !important;
  font-family: Ubuntu;
  font-size: 2vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.Path-820 {
  margin-top:10px;
  padding: 10px 20px 10px 20px;
  background-color: #03a6ad !important;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color:#ffffff;
  border-radius: 5px !important;
}
.welcome {
  font-family: Ubuntu;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #202527;
}
.welcome_1 {
  font-family: Ubuntu;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center !important;
  color: #ffffff;
}
.Email_input {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left !important;
  color: #49494d;
}
.try_again_msg{
  margin: 0px 0px 0px 0px !important;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left !important;
  color: #03a6ad;
}
.invalid_email{
  margin: 0px 0px 0px 0px !important;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center !important;
  color: #03a6ad;
}
.try_again_msg_1{
  margin: 0px 0px 0px 0px !important;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left !important;
  color: #939393;
  
}
.Path-822 {
  margin-top: 10px;
  padding: 10px 20px 10px 20px;
  border: solid 1px #f16753;
  background-color: #fff;
  color: #f16753;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left !important;
}
.path_1145 {
  width:28vw;
  margin-top: 3vh;
  padding: 20px;
  background-color: #f5f7f9;
  border-radius: 5px;
}
.path_1145_1 {
  width:80vw;
  margin-top: 3vh;
  padding: 20px;
  background-color: #f5f7f9;
  border-radius: 5px;
}
.Confirmation {
  margin-top:-30px;
  margin-left: 5vh;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #202527;
}
.We-have {
  margin: 10px;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #676772;
}
.otpRESEND {
  margin-left: 10px;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #f16753;
  cursor: pointer;
}
.step3 {
  margin-left: 10px;
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #03a6ad;
  float: right;
}
.Line-291 {
  margin-top: 3vh;
  // margin-left: 200px !important;
  //margin-right:-35px !important;
  margin-right:-40px;
  width: 120% !important;
  height: 3px !important;
  background-color: #f16753;
  float: right;
}
.Line-291_0 {
  margin-top: 3vh;
  // margin-left: 200px !important;
  // margin-right:-85px !important;
  margin-right:-40px;
  width: 120% !important;
  height: 3px !important;
  background-color: #03a6ad;
  float: right;
}
.Line-291_2 {
  margin-top: 3vh;
  // margin-left: 200px !important;
  // margin-right:-135px !important;
  margin-right:-40px;
  width: 120% !important;
  height: 3px !important;
  background-color: #939393;
  float: right;
}
.Line-291_3 {
  margin-top: 3vh;
  // margin-left: 200px !important;
  // margin-right:-135px !important;
  margin-right:-40px;
  width: 120% !important;
  height: 3px !important;
  background-color: #f16753;
  float: right;
}
.new_login_11 {
  font-family: Ubuntu;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  color: #03a6ad;
  text-align: right !important;
  float:right;
}
.Ellipse-298 {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #03a6ad;
  float: right;
  margin-top:6px;
  margin-right: 2vh
}

.Path-68717 {
  margin:2vh;
  padding: 5px 20px 5px 20px;
  background-color: #d04d3b;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  font-size: 2vh;
  float:right !important;
  font-family: Ubuntu;
  cursor:pointer;
  color:#ffffff
}


@keyframes blink_input_opacity_to_prevent_scrolling_when_focus {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.v-otp-input:focus {
  animation: blink_input_opacity_to_prevent_scrolling_when_focus 0.01s;
}


</style>