<template>
  <v-main>
    <div>      
      <div class="margin_align">
        <div>
        <loading :active.sync="loading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
        <v-row v-if="!loading">
          <v-col class="mt-2" cols="12" md="4" lg="4" sm="4" xs="4"></v-col>
          <v-col class="mt-4" cols="12" md="4" lg="4" sm="4" xs="4">
            <v-card class="Rectangle-1203" v-if="show_data">
              <v-row align="center" justify="center">
                <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3">
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6" align="center" justify="center">
                  <img src="../assets/images/Knolens Final Logo HD 2/Knolense_Classic.jpg"
                   style="width: 8vw;object-fit: contain;">
                </v-col>
                <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3">
                </v-col>
              </v-row>
              <div>
                <div style="text-align: center;margin-top:3vh">
                  <span class="Payment-Successful">Trial Period</span>
                  <p class="description">Currently you can access following disease free of cost for 1 month.</p>
                </div>
                <hr>
                <span class="Purchase-Details">Purchase Details</span>
                <v-row v-for="(ca, kw) in cart_diseases" :key="kw">
                  <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                    <div class="Path-8">{{ca.Disease_name}} <span style="color:#676772;font-size:1.8vh;font-weight: 500;float: right">Validity 1 Month</span></div>
                  </v-col>           
                </v-row>
              </div>
              <v-row>
                <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4">
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" align="center" justify="center">
                  <v-btn class="Path-703" @click="next_page()">Proceed</v-btn>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4">
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3">
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6" align="center" justify="center">
                  <v-btn class="Path-703" @click="makeStripePayment()">Proceed to Payment</v-btn>
                </v-col>
                <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3">
                </v-col>
              </v-row> -->
            </v-card>
          </v-col>
          <v-col class="mt-2" cols="12" md="4" lg="4" sm="4" xs="4"></v-col>
        </v-row>
        </div>
      </div>
    </div>
  </v-main>
</template>
<script type="text/javascript">
import axios from "axios";
import { EventBus } from "../event-bus.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  data() {
    return {
      message: "",
      auth_token: "",
      disesase: "",
      disease_str: "",
      loading: false,
      fullPage: true,
      cart_diseases: [],
      user_email: '',
      show_data: false,
      stripe_key: "",
    };
  },
  components: {
    Loading,
  },
  computed: {},
  mounted() {
    var auth_token = localStorage.getItem("authtoken");
    if (auth_token !== null || auth_token !== "undefined" || auth_token !== "") {
      this.auth_token = auth_token
      this.get_page_activity()
      this.getPaymentReleatedKey()
    } else {
      console.log("no authentication")
      this.auth_token = ""
    }
    // var disesase = localStorage.getItem("selected_disease")
    // if (disesase !== null || disesase !== "undefined" || disesase !== "") {
    //   this.disesase = disesase
    // } else {
    //   this.disesase = ""
    // }

    var plan = localStorage.getItem("plan")
    if (plan !== null || plan !== "undefined" || plan !== "") {
      this.myplan = plan
    } else {
      this.myplan = ""
      this.$router.push("/Pricing").catch(() => { })
    }
   
    this.save_payment_page_activity()
    this.getuser()
    this.get_wishlist()
  },
  methods: {
    onCancel() {
      console.log('User cancelled the loader.')
    },
    save_payment_page_activity() {
      var article_id = ""
      var activity_name = "Activity Payment Page"
      var disease = ""
      axios
        .post("/SaveActivity?id=" + article_id + '&token=' + this.auth_token + "&activity_name=" + activity_name + "&disease=" + disease)
        .then((res) => {
          if (res.data.Status != 1) {
            console.log("Activity saved successfully")
          } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            this.$router.push("/").catch(() => { })
          } else {
            console.log("Activity save failed")
          }
        })
    },
    getuser() {
      axios
        .get("/user?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status != 1) {
            this.user_email = res.data.Data.Email
          } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User");
            this.logOut()
          } else {
            console.log("User not found")
          }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);  
          this.logOut()         
        }
      });
    },
    get_wishlist() {
      this.loading = true
      axios
        .get("/getuserdiseasecart?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status != 1) {
            this.loading = false
            this.cart_diseases = res.data.Data.user_diseases_cart.Diseases
            if (this.cart_diseases.length > 0) {
              this.show_data = true
            }                

          } else {
            this.loading = false
            console.log("cart_diseases not found")
          }
      })
      .catch((error) => {        
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);        
        }
      });
    },
    next_page() {
      this.loading = true
      axios
        .post("/deletediseasecart?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status != 1) {
            this.loading = false
            console.log("disease cart deleted")
          } else {
            this.loading = false
          }
      })
      .catch((error) => {
        console.log(error.message);
      });          
      this.$router.push("/").catch(() => {}) 
          
      this.l1l2_page_data = {}
      this.l1l2_page_data.article_title = "Events"
      this.l1l2_page_data.disease = this.disesase
      EventBus.$emit("l1l2_page_data", this.l1l2_page_data);
    },
    delete_my_disease(dis) {
      this.loading = true      
      axios
        .put("/deletecartitem?token=" + this.auth_token + "&disease_name=" + dis.Disease_name)
        .then((res) => {
          if (res.data.Status != 1) {
            this.loading = false
            this.cart_diseases = []
            this.cart_diseases = res.data.Data.userdiseasescart.Diseases
        
            if (this.cart_diseases.length > 0) {
              this.show_data = true
            } else {
              this.show_data = false
            }
            //console.log(this.cart_diseases,"this.cart_diseases")
          } else {
            this.loading = false
            console.log("cart not found")
          }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);         
        }
      });
    },
    getPaymentReleatedKey(){
      axios.post("/getPaymentReleatedKey?token="+this.auth_token)
      .then(response => {
        this.stripe_key=response.data.Data
      })
      .catch(error => {
        console.log(error);
      });
   
    },
    // makeStripePayment() {
    //   this.loading = true
    // 
    //   var qty = 1
    //   const stripe = Stripe(this.stripe_key);
    //   axios.post(`/create-checkout-session?priceId=`+ priceid + "&quantity=" + qty + "&email=" + this.user_email + "&token=" + this.auth_token)
    //     .then(res => {
    //     //console.log(res.data.Data,"res.data.Data")
    //     if (res.data.Status !== 1) {
    //       const id = res.data.Data.Id;
    //       stripe.redirectToCheckout({sessionId: id});
    //       this.show_data = false
    //     } else {
    //       this.loading = false
    //       this.err_flag = true
    //       console.log("error in payment redirecting")
    //     }
    //   })
    // },
    logOut() {
      //Auth Token Removal
      //localStorage.removeItem("selected_disease");
      //localStorage.removeItem("diseases");
      
      localStorage.removeItem("authtoken");
      localStorage.removeItem("selectedColorList");
      // localStorage.setItem("selected_article", "Industry Updates");
      this.disesase = "Multiple sclerosis"
      this.title = "Multiple sclerosis"
      delete axios.defaults.headers.common["Authorization"];
      this.auth_token = null;
      
      this.$router.push("/").catch(() => {})
              
    },
    get_page_activity(){
    axios
            .get("/fetchIndicationSelected?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status != 1) {
                console.log(res.data,"response.data")
                this.disesase = res.data.Data.Indication
                this.$forceUpdate();          
              } 
          })
  },
  },
};
</script>
<style scoped>

  .margin_align {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh !important;
    background-color: #f5fffb !important;
  }

  .Path-8 {
    padding: 2vh;
    height:100%;
    border: solid 1px #b1b1b1;
    background-color: #fff;
    border-radius: 7px;
    font-family: Ubuntu;
    font-size: 2.1vh;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #676772;
  }
  .Path-9 {
    padding: 2vh;
    border: solid 1px #03a6ad;
    background-color: #f7faff;
    border-radius: 7px;
    font-family: Ubuntu;
    font-size: 2.1vh;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #676772;
  }

  .Payment-Successful {
    margin: 2vh !important;
    font-family: Ubuntu;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center !important;
    color: #32324a;
  }

  .Rectangle-1203 {
    margin-top: 8vh;
    padding: 3vh;
    border-radius: 31px;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #707070;
    background-color: #fff;
  }

  .Purchase-Details {
    margin: -2vh 2vh 2vh 0vh !important;
    font-family: Ubuntu;
    font-size: 2.4vh;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #32324a;
  }

  .description {
    margin: 2vh;
    font-family: Ubuntu;
    font-size: 2.4vh;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: normal;
    text-align: center;
    color: #676772;
  }

  .Path-703 {
    margin-top:2vh;
    font-size:2vh;
    font-weight: 700;
    height: 5vh;
    padding: 2vh;
    background-color: #03a6ad !important;
    color: #fff;
  }
</style>  