<template>
  <v-main>
    <div style="margin-left: -5vh;margin-top:10vh;margin-bottom:5vh;font-family: Ubuntu;">
    
    <div class="appLayout">
      <div class="margin_align">
        <div id="sist">
            <loading :active.sync="loading" 
              :can-cancel="true" 
              :on-cancel="onCancel"
              :is-full-page="fullPage"></loading>
            <v-row v-if="no_authentication">
              <v-col cols="12" md="1" lg="1" sm="1"></v-col>
              <v-col cols="12" md="6" lg="6" sm="6" style="margin-left:4vw">
                    <div class="Rectangle-715">
                      <v-row>
                        <v-col>
                          <img src="../assets/images/Image 140.png" style="width:40px;height:40px">
                        </v-col>
                        <v-col md="7" lg="7" sm="7">
                          <span class="subscription">Click on login to view impacts for this feed.</span>
                        </v-col>
                        <v-col>
                          <v-btn class="Path-390" @click="login()">Login</v-btn>
                        </v-col>
                      </v-row>
                    </div>
              </v-col>
              <v-col cols="12" md="4" lg="4" sm="4"></v-col>
            </v-row>
            <v-row wrap style="padding: 5vh 2vh 5vh 5vh;margin-left:2vh;" v-show="!loading">
             
              <v-col cols="12" md="1" lg="1" sm="1"></v-col>
              <v-col cols="12" md="7" lg="7" sm="7" style="padding-bottom:5vh !important;">
                <div class="menu1" @click="showqueModal()">
                  <!-- <i class="fa fa-copy fa-2x" id="copy-btn"></i> -->
                  <img src="../assets/Icons/Group 1102.svg" style="height:3.5vh;width:3.5vh">
                </div>   
                <p style="font-size: 18px;color:#0d294a">{{article_title}}</p>
                <v-row>
                  <v-col cols="12" md="12" lg="12" sm="12">
                    <span class="summary">Summary</span>
                  </v-col>
                  <hr>
                </v-row>
                <div style="overflow-y: auto;margin-top: 10px; height: 55vh;overflow-x:hidden;padding-right: 1vw;"  @mouseup="showque">
                  <v-row v-for="(finb,key1) in final_statements1" :key="key1">
                    <div style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #0d294a;opacity: 0.85;" v-html="finb.div" v-if="finb.type !== 'string'"></div>
                    <div v-if="finb.type == 'string'">
                        <span style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="finb.newText" v-if="!finb.textLength"></span>
                        <span style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="finb.Text" v-if="finb.textLength"></span>

                        <span @click="changetextLength(finb)" class="pointer" v-if="!finb.textLength && !finb.no_show">...<span style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;margin-left:1vh">See More</span><br></span>
                        <span @click="changetextLength(finb)" class="pointer" v-if="finb.textLength && !finb.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;">&nbsp;&nbsp;See Less<br></span>
                      </div>                    
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" md="3" lg="3" sm="3" style="margin-left: 5vh;">
                <div class="Rectangle-905" v-if="!no_authentication">
                  <div class="Rectangle-903">Impact Assessment</div>
                  <div style="background-color: #f2fcfc;padding: 0vh 1vh 1vh 1vh;overflow-y: auto; max-height: 60vh !important;overflow-x: hidden;padding-right: 1vw;" @mouseup="showque">
                    <v-row v-for="(fib,keyb) in final_statements2" :key="keyb">
                      <div v-if="fib.type == 'plot'">
                          <v-col cols="12" md="12" lg="12" sm="12">
                              <!-- <div style="font-family: Ubuntu;font-size: 16px;font-weight: 500;text-align: left;color: #49575d;margin-bottom: 15vh;" v-html="fib.div"></div> -->
                          </v-col>
                        </div>
                        <div v-if="fib.type == 'image'">
                          <v-row wrap>
                            <v-col cols="12" md="2" lg="2" sm="2"></v-col>
                            <v-col cols="12" md="8" lg="8" sm="8">
                              <div style="font-family: Ubuntu;font-size:16px;font-weight: 500;text-align: left;color: #49575d;" v-html="fib.div"></div>
                            </v-col>
                            <v-col cols="12" md="2" lg="2" sm="2"></v-col>
                          </v-row>
                        </div>
                        <div  v-if="fib.type == 'table'">
                          <v-row>
                            <span @click="show_table(fib.div)" style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #00b2ba;margin-bottom: 3vh;cursor: pointer;">See table here</span>
                          </v-row>
                        </div>
                        <div style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="fib.div" v-if="fib.type != 'plot' && fib.type != 'image' && fib.type != 'table' && fib.type !== 'string'"></div>

                        <div v-if="fib.type == 'string'">                           
                          <span style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="fib.newText" v-if="!fib.textLength"></span>
                          <span style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;margin-bottom: 5vh;" v-html="fib.Text" v-if="fib.textLength"></span>
                          <span @click="changetextLength(fib)" class="pointer" v-if="!fib.textLength && !fib.no_show">...<span style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;margin-left:1vh">See More<br></span></span>
                          <span @click="changetextLength(fib)" class="pointer" v-if="fib.textLength && !fib.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;">&nbsp;&nbsp;See Less</span>
                        </div>
                    </v-row>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <template>
            <div class="text-center">
              <v-dialog
                v-model="show_table_modal"
                width="800"
              >
              <v-card class="Path-359" style="padding:3vh 3vh 3vh 6vh !important">
                  <div><v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="show_table_modal = false">mdi-close</v-icon></div>
                  <v-row wrap>
                    <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                        <div v-html="table_display"></div>
                      </v-col>   
                    </v-row>
                </v-card>
              </v-dialog>
            </div>
          </template>
          <QnAModal ref="QnAModal"></QnAModal>
      </div>
     </div>
 </div>
</v-main>
</template>
<script type="text/javascript">
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import postscribe from "postscribe";
import QnAModal from "@/components/QnAModal.vue";
export default {
  data() {
    return {
      open_share_modal: true,
      linkid: '',
      myjson: {},
      page_title: '',
      panels: [],
      version: '',
      page_type: '',
      link: '',
      templates: [],
      data_columns:[],
      data_rows: [],
      show: false,
      final_statements1: [],
      final_statements2: [],
      loading: false,
      fullPage: true,
      first_image:{},
      article_title: '',
      no_authentication: false,
      domain_origin: '',
      show_table_modal: false,
      table_display: {},
      //qna
      queans_modal: false,
      my_word: "",
      queans: [],
      myquestion: "",
      show_que: false,
      pageX: 0,
      pageY: 0,
      outputcpy: "",
      checkboxData:[],
      loadingModal: true,
      questionIds: [],
      option:"fda",
      question: "",
      loadingAnswer: false,
      option_lables: [
        {
          name: "Labels",
          status: "selected"
        },
        {
          name: "Clinical Studies",
          status: "notselected"
        },
        {
          name: "PubMed",
          status: "notselected"
        },
        {
          name: "Patents",
          status: "notselected"
        },
      ],
      resetCheckbox:false,
      user_plan: "",
    };
  },
  components: {
    Loading,
    QnAModal
  },
  computed: {
  },
  mounted() {
    var auth_token = localStorage.getItem("authtoken");
    if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
      this.auth_token = auth_token
      this.no_authentication = false
      // this.save_page_activity()
      this.getUserPlan()
    } else {
      this.auth_token = ""
      this.no_authentication = true
    }    
    this.id = this.$route.params.id
    this.getlink()
    this.domain_origin = window.location.origin
  },
  methods: {
    changetextLength(obj) {
      if (!obj.textLength) {
        obj.textLength = true
      } else {
        obj.textLength = false
      } 
      this.$forceUpdate();
    },
    save_page_activity() {
      var disease = ""
      var articleid = this.id
      var duration = "0"
      var panel_duration = "0"
      var activity_name = "Page Change"
      var panel_number = "0"
      var pagename = "Share Article List Page"
      axios
        .post("/SaveActivity?id=" + articleid + '&token=' + this.auth_token + "&activity_name=" + activity_name + "&disease=" + disease + "&page_name=" + pagename + "&duration=" + duration + "&panel_duration=" + panel_duration)
        .then((res) => {
          if (res.data.Status != 1) {
          console.log("Activity saved successfully")
        } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
          console.log("Invalid User")
        } else {
          console.log("Activity save failed")
        }
      })
      .catch((error) => {
        console.log(error.message);
        // if (error.message == "Request failed with status code 500") {
        //   this.logout()             
        // }
      });
    },
    onCancel() {
      console.log('User cancelled the loader.')
    },
    getlink() {
      this.loading = true
      axios
        .get("/getSharedArticle?id=" + this.id)
        .then((res) => {
          if (res.data.Status != 1) {
            //console.log(res.data.Data.Url,"article link found")
            fetch(res.data.Data.Url)
            .then(res => res.json())
            .then((out) => {
              var my_json = out
              this.get_article_content(my_json)
            })
            .catch(err => { throw err });
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
          } else {
            console.log("Article link not found")
          }
      })
      .catch((error) => {
        console.log(error.message);
        // if (error.message == "Request failed with status code 500") {
        //   this.logout()             
        // }
      });
    },
    // To display article content 
    get_article_content(my_json) {
      //var scripts = ''
      this.myjson = my_json
      var len = Object.keys(this.myjson).length;
      var myval = Object.values(this.myjson)
      this.version = this.myjson.Software_Version
      this.page_type = this.myjson.Type

      this.page_title = this.myjson.Card.Title
      this.article_title = this.myjson.Card.Template
      this.mandatory_var = this.myjson.Card.Mandatory_Variables
      var img = this.myjson.Card.Image

      var image = new Image();
      image.src = img
      this.first_image = image

      this.final_statements1 = []
      this.final_statements2 = []    

      this.templates = this.myjson.Templates
      var scripts = `<script type="text/javascript">setTimeout(function(){`
      
      // to get values of article title

      for (var i = 0; i < this.mandatory_var.length; i++) {
        var name = this.mandatory_var[i].Name
        var type = this.mandatory_var[i].Type
        var default_value = this.mandatory_var[i].Default_Values
        var match_string = '{{' + type + '__'+ name + '}}'
        //var match_string = '%%{' + type + '__'+ name + '}%%'
        //console.log(match_string,"match_string")
        if (this.article_title.includes(match_string)) {
          let result = this.article_title.replace(match_string, default_value);
          this.article_title = result
        }
      }
        

      this.l1 = this.templates[0]

      // to make string new lines separate

      var mysrch1 = "\\n"

      // to get values of L1 template
      this.l1_man_var = this.l1.Mandatory_Variables
      
      var statement = this.l1.Template.split(mysrch1)
      
      for (var k = 0;  k < statement.length; k++) {
        var resultingString1 = statement[k]
        var exp_obj = {}
        for (var j = 0; j < this.l1_man_var.length; j++) {
          var name = this.l1_man_var[j].Name
          var type = this.l1_man_var[j].Type      
          var default_value = this.l1_man_var[j].Default_Values
          var match_string = '{{' + type + '__'+ name + '}}'
          //var match_string = '%%{' + type + '__'+ name + '}%%'
          if (type == "int" || type == "string" || type == "date") {
            if (statement[k].includes(match_string)){
              var pieces1 = resultingString1.split(match_string);
              resultingString1 = pieces1.join(default_value);
              exp_obj.div = '<p>'+resultingString1+'</p>'
              exp_obj.type = 'string'

              var whole_string = '<br><span style="font-size:15px;">'+resultingString1+'</span>'
              exp_obj.Text = whole_string
              
              var newText = whole_string
              exp_obj.newText = newText
              exp_obj.textLength = false
              exp_obj.no_show = true

              // var charCount = 0
              // var comma_position = 0
              // for (var c = 0; c < exp_obj.Text.length; c++) {
              //   if (exp_obj.Text[c] == ',') {
              //     charCount = charCount + 1
              //     if (charCount == 10) {
              //       comma_position = c
              //     }
              //   }
              // }
              
              // if (charCount >= 10) {
              //   var newText = whole_string.substring(0, comma_position);
              //   exp_obj.newText = newText
              //   exp_obj.textLength = false
              //   exp_obj.no_show = false
              // } else {
              //   var newText = whole_string
              //   exp_obj.newText = newText
              //   exp_obj.textLength = false
              //   exp_obj.no_show = true
              // }


              if (exp_obj.div.includes(below_string) ) {
                exp_obj.below = true
              } else {
                exp_obj.below = false
              }
            }
          } else if (type == "hyperlink") {
              if (statement[k].includes(match_string)){
                var pieces1 = resultingString1.split(match_string);
                var lnk = this.domain_origin+ '/#/' + default_value.Link

                if (default_value.Link.includes("https://")) {
                  lnk = default_value.Link
                }

                //console.log(lnk,"lnk")

                var  hyp1 = `<a target='_blank' href="${lnk}">${default_value.Text}</a>`;

                resultingString1 = pieces1.join(hyp1); 
                exp_obj.div =  resultingString1 
                exp_obj.type = 'link'       
              }
          } else if (type == "table") {
              //console.log(default_value.Table_Data,"table dataa")
              if (default_value.Table_Data !== undefined) {
                if (statement[k].includes(match_string)){
                  var data_columns1 =  default_value.Table_Data.Columns
                  var data_rows1 = default_value.Table_Data.Rows
                  if (data_columns1 !== null && data_rows1 !== null) {                 
                    var div333 = this.displayShelfItemPairs(data_columns1, data_rows1)
                    var div444 = div333.outerHTML
                    exp_obj.type = 'table'
                    exp_obj.div = `${div444}`
                    //console.log(div444,"d444") 
                  } else {
                    console.log("row or table data null")
                  }
                }
              }
          } else if (type == "image") {
            if (statement[k].includes(match_string)){
              //console.log("imagess")
              var image1 = new Image();

              image1.src = 'data:image/png;base64,'+ default_value
              exp_obj.div = `<img
                          src="${image1.src}"
                          alt="image"
                          style="height: 200px;200px;margin-top:3vh;margin-bottom:3vh"
                          />`
              exp_obj.type = "image"
            }
          } else if (type == "plot"){
              if (statement[k].includes(match_string)) {
                //console.log(default_value,"plttt")
                var double_encode = default_value.split('.')[0]
                var plt_data = window.atob(double_encode);

                //console.log(plt_data,"plt_data")
                if (plt_data != '') {
                  var obj = JSON.parse(plt_data) 

                  var plot_data = obj.div64.split('.')[0]
                  var div = window.atob(plot_data);

                  div = div.replace(/\\"/g, '"')
                  div = div.replace(/\\'/g, "'")
                  div = div.replace(/\r?\n|\r/g, "\n")

                  var div64 = div

                  //console.log(div64,"div")

                  var script_data = obj.script64.split('.')[0]
                  var script = window.atob(script_data);

                  script = script.replace(/\\"/g, '"')
                  script = script.replace(/\\'/g, "'")
                  script = script.replace(/\r?\n|\r/g, "\n")

                  var script64 = script

                  //console.log(script64,"script")
                  var str = script64.replace('<script type="text/javascript">', "\n")
                  var str1 = str.replace("window.PLOTLYENV=window.PLOTLYENV || {};","\n")
                     var str2 = str1.replace("<\/script>", "\n")
                     scripts += str2

                  var pieces1 = resultingString1.split(match_string);
                  resultingString1 = pieces1.join(div64);
                  exp_obj.div = resultingString1
                  exp_obj.type = "plot"
                }
              }
            }  
        }
        this.final_statements1.push(exp_obj)        
      }
      this.loading = false

      //console.log(this.final_statements1,"final_statements1")

      // to get values of L2 template
      this.l2 = this.templates[1] 
      
      this.l2_man_var = this.l2.Mandatory_Variables

      var statement2 = this.l2.Template.split(mysrch1)
      var below_string = "Below is the plot"
      
      for (var k = 0;  k < statement2.length; k++) {
        var resultingString2 = statement2[k]
        var exp_obje = {}
        for (var j = 0; j < this.l2_man_var.length; j++) {
          var name = this.l2_man_var[j].Name
          var type = this.l2_man_var[j].Type      
          var default_value = this.l2_man_var[j].Default_Values
          var match_string = '{{' + type + '__'+ name + '}}'
          //var match_string = '%%{' + type + '__'+ name + '}%%'
          if (type == "int" || type == "string" || type == "date") {
            if (statement2[k].includes(match_string)){
              var pieces2 = resultingString2.split(match_string);
              resultingString2 = pieces2.join(default_value);
              exp_obje.div = '<p>'+resultingString2+'</p>'
              exp_obje.type = 'string'

              var whole_string = '<br><span style="font-size:15px;">'+resultingString2+'</span>'
              exp_obje.Text = whole_string
              var charCount = 0
              var comma_position = 0
              for (var c = 0; c < exp_obje.Text.length; c++) {
                if (exp_obje.Text[c] == ',') {
                  charCount = charCount + 1
                  if (charCount == 3) {
                    comma_position = c
                  }
                }
              }
              
              if (charCount >= 4) {
                var newText = whole_string.substring(0, comma_position);
                exp_obje.newText = newText
                exp_obje.textLength = false
                exp_obje.no_show = false
              } else {
                var newText = whole_string
                exp_obje.newText = newText
                exp_obje.textLength = false
                exp_obje.no_show = true
              }


              if (exp_obje.div.includes(below_string) ) {
                exp_obje.below = true
              } else {
                exp_obje.below = false
              }  
            }
          } else if (type == "hyperlink") {
            if (statement2[k].includes(match_string)){
              var pieces2 = resultingString2.split(match_string);
              var lnk = this.domain_origin+ '/#/' + default_value.Link

              if (default_value.Link.includes("https://")) {
                lnk = default_value.Link
              }
              //console.log(lnk,"lnk")
              var  hyp2 = `<a target='_blank' href="${lnk}">${default_value.Text}</a>`;

              resultingString2 = pieces2.join(hyp2); 
              exp_obje.div =  resultingString2 
              exp_obje.type = 'link'       
            }

          } else if (type == "table") {
            if (statement2[k].includes(match_string)){
              if (default_value.Table_Data !== undefined) {
                exp_obje.type = 'table'
                var data_columns2 =  default_value.Table_Data.Columns
                var data_rows2 = default_value.Table_Data.Rows
                if (data_columns2 !== null && data_rows2 !== null) {
                  var div111 = this.displayShelfItemPairs(data_columns2, data_rows2)
                  var div222 = div111.outerHTML
                  exp_obje.div = `${div222}`
                  //console.log(div111,"dvvvvv") 
                } else {
                   console.log("row or table data null")
                }
              }
            }
          } else if (type == "image") {
            if (statement2[k].includes(match_string)){
              //console.log("imagess")
              var image2 = new Image();

              image2.src = 'data:image/png;base64,'+ default_value
              exp_obje.div = `<img
                          src="${image2.src}"
                          alt="image"
                          style="height: 200px;200px;margin-top:3vh;margin-bottom:3vh"
                          />`
              exp_obje.type = "image"
            }
          } else if (type == "plot"){
            if (statement2[k].includes(match_string)) {
              //console.log(default_value,"plottttttttt")
              var double_encode = default_value.split('.')[0]
              var plt_data = window.atob(double_encode);

              //console.log(plt_data,"plt_data")
              if (plt_data != '') {
                var obj = JSON.parse(plt_data) 

                var plot_data = obj.div64.split('.')[0]
                var div = window.atob(plot_data);

                div = div.replace(/\\"/g, '"')
                div = div.replace(/\\'/g, "'")
                div = div.replace(/\r?\n|\r/g, "\n")

                var div64 = div

                //console.log(div64,"div")

                var script_data = obj.script64.split('.')[0]
                var script = window.atob(script_data);

                script = script.replace(/\\"/g, '"')
                script = script.replace(/\\'/g, "'")
                script = script.replace(/\r?\n|\r/g, "\n")

                var script64 = script

                //console.log(script64,"script")
                var str = script64.replace('<script type="text/javascript">', "\n")
                var str1 = str.replace("window.PLOTLYENV=window.PLOTLYENV || {};","\n")
                   var str2 = str1.replace("<\/script>", "\n")
                   scripts += str2

                var pieces2 = resultingString2.split(match_string);
                resultingString2 = pieces2.join(div64);
                exp_obje.div = resultingString2
                exp_obje.type = "plot"
              }
            }
          }
          
        }
        this.final_statements2.push(exp_obje)
      }

      //console.log(this.final_statements2,"final_statements2")
      scripts = scripts + `}, 5000);<\/script>`
      //console.log(scripts,"scripts")
      postscribe('#sist', scripts)
    },
    login () {
      this.$router.push("/Login").catch(() => {})
    },
    // To display content in table
    displayShelfItemPairs(col, rw) {
      this.data_columns = col
      this.data_rows = rw
      // To make table
      var table = document.createElement("table");
      table.setAttribute('id', 'bom_table');
      table.setAttribute("style", "display: block;overflow-x: auto; margin-top:2vh; margin-bottom:2vh; border-collapse:collapse; table-layout:fixed;max-height:60vh");
      var tr = table.insertRow(-1);
      for (var i = 0; i < this.data_columns.length; i++) {
        var th = document.createElement("th");
        th.innerHTML = this.data_columns[i];
        th.setAttribute("style", "min-width:10vw;padding:2vh;font-family:Ubuntu;font-size: 14px;color: #ffffff;border: 1px solid #ffffff;background-color: #3fa6a1;text-transform: capitalize;margin-top:2vh !important");
        tr.appendChild(th);
      }

      for (var i = 0; i < this.data_rows.length; i++) {
        tr = table.insertRow(-1);
        for (var j = 0; j < this.data_rows[i].length; j++){
          var tabCell = tr.insertCell(-1);
          tabCell.innerHTML = this.data_rows[i][j]
          tabCell.setAttribute("style", "min-width:10vw;padding:2vh;font-family:Ubuntu;font-size: 14px;color: #333;border: 1px solid #D7DBDD;text-transform: capitalize;word-wrap:break-word;");
        }
      }                    
      return(table); 
    },
    logout() {

      localStorage.removeItem("authtoken");
      localStorage.removeItem("selectedColorList");
      // localStorage.setItem("selected_article", "Industry Updates");
      this.disesase = "Multiple sclerosis"
      delete axios.defaults.headers.common["Authorization"];
      this.auth_token = null;        
      
      this.$router.push("/").catch(() => {})
    },
    show_table(table_data) {
      this.show_table_modal = true
      this.table_display = table_data
    },
     //qna section
     showque(c) {
      if (this.auth_token !== null && this.auth_token !== "" &&this.auth_token !== undefined) {
      console.log("showque")
      this.my_word = this.getSelectedText()
      // localStorage.setItem("my_word", this.my_word);
      let selectedText = this.my_word.toString();
      console.log(selectedText,"selectedText")
      var menu = document.querySelector(".menu1");
      if (selectedText !== "") {
        
      
        let sel = window.getSelection();
        let getRange      = sel.getRangeAt(0);
        let rect = getRange.getBoundingClientRect();
        menu.style.top=rect.top - 105 + 'px',
        menu.style.left=( rect.left + (rect.width * 0.5)-135) + 'px'
        menu.style.display = "block";
  
        menu.style.display = "block";
       
        console.log(menu.style.display)
        
      } else {
        menu.style.display = "none";
      }

      //document.getElementById("output").innerHTML = selectedText;

      if (selectedText !== "") {
        this.outputcpy = selectedText;
      } else {
        this.outputcpy = "";
      }
     
    
      console.log("showqueModal")
     
        
      this.$forceUpdate();
      }
    },
    showqueModal(){
      // this.queans_modal = true
      console.log(this.queans_modal)
      var menu = document.querySelector(".menu1");
      menu.style.display = "none";
      if(this.user_plan=='Basic Plan'){
        this.queans_modal = true
      }else{
        this.queans_modal = false
      }
      this.$refs.QnAModal.showquesModal(this.outputcpy,this.queans_modal);


    },
    getSelectedText() {
      let selection = document.getSelection();
      console.log(selection,"selection")
      let selRange = selection.getRangeAt(0);
      console.log(selRange,"selRange")
      return selRange;  
    },
    getUserPlan(){
      //create a get request to get the user plan
      axios.get("/getPlan?token=" +this.auth_token ).then((response) => {
        if(response.data.Status !=1)
        console.log(response.data,"response.data")
        this.user_plan = response.data.Data.Plan
        this.$forceUpdate();
      })
    }
    
  },
};
</script>
<style lang="scss" scoped>
  .margin_align {
    margin: 4vh -1vh 0vh -4vh !important;
  }
  .Rectangle-715 {
    width:90%;
    height: 10vh;
    padding: 0vh 1vh !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ff6750;
    background-color: #f9f9fb;
  }
  .Path-390 {
    width: 90%;
    margin: 0vh 1vh;
    background-color: #ff6750 !important;
    color: #ffffff
  }
  .subscription {
    width:100%;
    color: #ff6750;
    font-size: 16px;
    font-weight: 500;
    margin-left:-5vh
  }
  .Rectangle-903 {
    height: 6vh;
    margin: -2.1vh -2vh 2vh -2vh !important;
    padding: 1vh 1vh 1vh 3vh !important;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    font-size: 2.4vh;
    text-align: left;
    font-weight: 700;
    color: #ffffff;
    background-color: #00b2ba;
  }
  .Rectangle-905 {
    padding: 2vh;
    border-radius: 16px;
    box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.22);
    border: solid 2px #222545;
    background-color: #f2fcfc;
    margin-top:1vh;
    max-height:80vh;
  }

  .summary {
    font-family: Ubuntu;
    font-size: 18px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c48;
  }

  .Path-359 {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ff6750 !important;
    background-color: #fff;
    border-radius: 20px !important;
  }
  .que-text {
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.79;
    letter-spacing: normal;
    text-align: left;
    color: #9b9b9b;
  }
  .que_txt {
    font-family: Ubuntu;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 2.43;
    letter-spacing: normal;
    text-align: left;
    color: #676772;
    margin-left: 2vh
  }
  
  .Path-868 {
    margin: 0vh 2vh 0vh 1vh;
    padding: 1vh;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #b1b1b1;
    background-color: #fff;
  }
  
  .question {
    font-family: Ubuntu;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #202527;
  }
  
  .circle {
    width: 25px;
    height: 25px;
    margin: 0vh;
    padding: 3px 5px 3px 8px;
    border-radius: 25px;
    border: solid 1px #eaeaea;
    background-color: #eaeaea;
  }
  .answer {
    font-family: Ubuntu;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #202527;
  }
  
  
  .Searching-For {
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: normal;
    text-align: left;
    color: #676772;
  }
  
  .Rectangle-1204 {
    padding: 2vh;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #b1b1b1;
    background-color: #f7f7f7;
  }
  .search_context {
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c48;
  }
  ::v-deep .chkbox_content .v-label{
    font-family: Ubuntu;
    font-size: 12px !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c48;
  }
  .Label_btn {
    font-family: Ubuntu;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #00b2ba !important;
    border-radius: 9px;
  }
  .Label_btnn {
    font-family: Ubuntu;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #202527;
  }
  
  .menu1 {
    display: none;
    position: absolute;
    
    border-radius: 6px;
    
    padding:1vh 2vh 1vh 2vh;
    cursor: pointer;
    z-index: 1;
  
  }
  .menu1 i {
    color: #03a6ad;
    cursor: pointer;
    margin: 8px;
  }
</style>  