<template>
  <v-main>
    <v-row v-if="!isTabletMob" class="margin_align">
        <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4" class="first_sec">
          <div style="display: flex;
              justify-content: center; 
              align-items: center;
              height:100%;" >
            <v-row  align="center" >      
              <v-col cols="12" sm="1" md="1" lg="1" xs="1" xl="1"></v-col> 
              <v-col cols="12" sm="9" md="9" lg="9" xs="9" xl="9">
                 <span class="Pricing-Plans_title">Pricing Plans</span>
                 <p class="Get-access">Get access to the richest source of real-time information on various dimensions of biopharmaceutical intelligence.</p>
              </v-col>
              <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" sm="8" md="8" lg="8" xs="8" xl="8" style="opacity: 1;background-color: #e3eef0;padding-right:20px;height:120vh;overflow-y: auto;">
          <v-row style="margin: 0vh 5vh 0vh 2vh !important" v-if="!loading">
                <v-col cols="12" sm="8" md="8" lg="8" xs="8" xl="8">
                  <v-card class="Rectangle-1152_1">
                      <v-row>
                        <v-col cols="12" md="3" lg="3" sm="3" xs="3" style="text-align: center;">
                          <div class="Rectangle-1151">
                            <span class="First-Mover">Starter</span>
                            <div>
                              <img src="../assets/images/Group 1339.png" style="height:7vh;margin-right:1vh;margin-top:0;">
                            </div>
                            <span class="Free">$599</span>
                            <div style="text-align: center">
                              <span style="font-size:1.7vh;color:#005290;font-family: Ubuntu;"> USD/Year</span>
                            </div>
                            <v-row>
                              <v-col cols="12" md="1" lg="1" sm="1" xs="1"></v-col>
                              <v-col cols="12" md="10" lg="10" sm="10" xs="10">
                                <!-- <div v-if="myplan == 'Basic Plan' && isActive" style="font-family:Ubuntu;font-size:17px;font-weight:300;color:#f16753;cursor:pointer;" @click="show_plan_details()">Plan Details</div>  -->
                                <v-btn v-if="myplan != 'Basic Plan' || !isActive" style="height:35px;padding: 8px 7px 7px 7px;background-color: #f16753;color:#ffffff" @click="goto_purchase('Basic Plan')">Buy Plan</v-btn>

                              </v-col>
                              <v-col cols="12" md="1" lg="1" sm="1" xs="1"></v-col>
                            </v-row>
                          </div>
                        </v-col>
                        <v-col cols="12" md="9" lg="9" sm="9" xs="9" style="text-align: center !important;padding:20px 35px 10px 30px">
                          <p class="Best-Suited-for">
                            <b>KnolChat:</b> The starter plan gives you unlimited access to KnolChat, a one of its kind, AI powered research assistant that dramatically simplifies your research by providing a natural language interface to answer your questions. It is built on a continuously updated database containing 1500+ diseases, 12000+ private companies (and 100k+ organizations), 4500+ mechanisms of action, and 2800+ drugs.
                          </p>
                          <p class="Best-Suited-for" style="margin-top:1vh"><b>Overview by Indications Analysis Module:</b> The starter plan gives you unlimited access to the "Overview by Indications" Module that covers the state of Therapy of ~150 indications and growing.</p>
                          <!-- <p class="Best-Suited-for" style="margin-top:1vh"><b>Landscape Analysis Modules:</b>Reach out to us at <a href="mailto:support@pienomial.com">support@pienomial.com.</a> to purchase Landscape Analysis Module and other upcoming analysis modules</p> -->
                        </v-col>
                      </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
          </v-row>  
          <v-row style="margin: 0vh 2vh 0vh 2vh">
                <v-col cols="12" sm="8" md="8" lg="8" xs="8" xl="8">
                  <v-card class="Rectangle-1152_2">
                      <v-row>
                        <v-col cols="12" md="3" lg="3" sm="3" xs="3" style="text-align: center;">
                          <div class="Rectangle-1151">
                            <span class="First-Mover">Enterprise</span>
                            <div style="margin-top:4vh">
                              <img src="../assets/images/Group 1338.png" style="height:7vh;margin-right:1vh;margin-top: 0vh;">
                            </div>
                            <!-- <span class="Free">Customise</span> -->
                            <div style="text-align: center">
                              <span style="font-size:1.7vh;color:#005290;font-family: Ubuntu;"></span>
                            </div>
                            <v-row style="margin-top:2vh">
                              <v-col cols="12" md="1" lg="1" sm="1" xs="1"></v-col>
                              <v-col cols="12" md="10" lg="10" sm="10" xs="10">
                                <v-btn class="Get-Trial-Plan" @click="contactUs()">Contact Us</v-btn>
                              </v-col>
                              <v-col cols="12" md="1" lg="1" sm="1" xs="1"></v-col>
                            </v-row>
                          </div>
                        </v-col>
                        <v-col cols="12" md="8" lg="8" sm="8" xs="8" style="text-align: center !important;padding:20px 0px 10px 100px">
                          <p class="Best-Suited-for">
                            We recognize no two organizations are alike. <a href="mailto:support@pienomial.com"> Contact us</a> for a custom plan that is best suited to your needs. <br><br>You will get access to everything in the Starter Plan and optional access to a wide variety of other pre-built and custom-built modules.
                          </p>
                        </v-col>
                      </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4" lg="4" sm="4" xs="4"></v-col>
          </v-row>
        </v-col>    
   </v-row>
   <v-row style="max-height:102vh;overflow-y:hidden;opacity: 1;background-color: #e3eef0;" v-if="isTabletMob">
        <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12" align="center" style="height:100vh;margin-top:10vh;">
          <div style="max-height:100vh;margin-top:1vh;overflow-y: auto;">
            <v-row>          
            <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12" align="center">
              <span class="Pricing-Plans_title" style="color:#3e3936">Pricing Plans</span>
              <p class="Get-access"  style="color:#3e3936;text-align: center;margin-left:8vh">Get access to the richest source of real-time information on various dimensions of biopharmaceutical intelligence.</p>
            </v-col>
          </v-row>
          <v-row style="margin-bottom:5vh">
            <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12" align="center"  style="padding-bottom:10vh">
              <v-card class="Rectangle-115233333">
                <v-row>
                  <v-col cols="12" md="4" lg="4" sm="4" xs="4" style="text-align: center;padding:0px 0px 0px 22px">                       
                    <v-row class="Rectangle-11513333">
                      <v-col align=center>
                        <span class="First-Mover333">Starter</span>
                        <br>
                        <img src="../assets/images/Group 1339.png" style="height:7vh;margin-right:0vh;margin-top:0;">
                        <br>
                        <span class="Free3333">$599</span>
                        <br>
                        <span style="font-size:1.7vh;color:#005290;font-family: Ubuntu;"> USD/Year</span>
                        <br>
                        <v-btn class="Get-Trial-Plan333" style="word-break: break-all;width:fit-content;margin-top:1vh" @click="goto_purchase('Basic Plan')">Buy Plan</v-btn>
                        <br>
                      </v-col>
                    </v-row>                                 
                  </v-col>
                  <v-col cols="12" md="8" lg="8" sm="8" xs="8" style="margin-bottom: 2vh;text-align: center !important;">
                    <div style="padding:2vh 3vh 2vh 5vh;max-height:25vh;overflow-y: auto;">
                      <p class="Best-Suited-for">
                        <b>KnolChat:</b> The starter plan gives you unlimited access to KnolChat, a one of its kind, AI powered research assistant that dramatically simplifies your research by providing a natural language interface to answer your questions. It is built on a continuously updated database containing 1500+ diseases, 12000+ private companies (and 100k+ organizations), 4500+ mechanisms of action, and 2800+ drugs.
                      </p>
                      <p class="Best-Suited-for" style="margin-top:1vh"><b>Overview by Indications Analysis Module:</b> The starter plan gives you unlimited access to the "Overview by Indications" Module that covers the state of Therapy of ~150 indications and growing.</p>
                          
                    </div>       
                  </v-col>
                </v-row>
              </v-card>
              <v-card class="Rectangle-115233333" style="margin-top:4vh;margin-bottom:4vh">
                <v-row>
                  <v-col cols="12" md="4" lg="4" sm="4" xs="4" style="text-align: center;padding:0px 0px 0px 22px">                       
                    <v-row class="Rectangle-11513333">
                      <v-col align=center>
                        <span class="First-Mover333">Enterprise</span>
                        <br><br>
                        <img src="../assets/images/Group 1338.png" style="height:7vh;margin-right:0vh;">
                        <br><br>
                        <v-btn class="Get-Trial-Plan333" @click="contactUs()" style="word-break: break-all;width:fit-content;margin-top:1vh">Contact Us</v-btn>
                        <br>
                      </v-col>
                    </v-row>                                 
                  </v-col>
                  <v-col cols="12" md="8" lg="8" sm="8" xs="8" style="margin-bottom: 2vh;text-align: center !important;">
                    <div style="padding:2vh 3vh 2vh 5vh;max-height:25vh;overflow-y: auto;">
                      <p class="Best-Suited-for">
                        We recognize no two organizations are alike.<a href="mailto:support@pienomial.com"> Contact us </a> for a custom plan that is best suited to your needs. <br><br>You will get access to everything in the Starter Plan and optional access to a wide variety of other pre-built and custom-built modules.
                      </p> 
                    </div>       
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </div>
        </v-col>
      </v-row>
  </v-main>
</template>

<script>
import axios from "axios";
import { EventBus } from "../event-bus.js";
import Loading from "vue-loading-overlay";
export default {
  data() {
    return {
      auth_token: '',
      myplan: '',
      disesase: '',
      trial_message: '',
      open_diseases_modal: false,
      my_previous_diseases: [],
      searchdisease: '',
      diseases: [],
      extra_diseases: false,
      new_dis_len: 0,
      my_diseases: [],
      loading: false,
      fullPage: true,
      my_plan: '',
      user: {},
      team_plan_message: '',
      sub_plan: '',
      diseases_not_available: false,
      only_one_disease: false,
      showFirstMoverPlanOptions : false,
      isActive: false,
    };
  },
  components: {
    Loading,
  },
  created () {
  },
  mounted() {
    console.log("Pricing")
    
    var auth_token = localStorage.getItem("authtoken");
    if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
      this.auth_token =  auth_token
      this.getuserProfile()
      this.CheckPlans()
    } else {
      this.auth_token = ""
    }

    // var disesase = localStorage.getItem("selected_disease")
    // if (disesase !== null && disesase !== "undefined" && disesase !== "") {
    //   this.disesase = disesase
    // } else {
    //   this.disesase = ""
    // }

  },
  computed: {
    filteredDisesesList() {
      return this.diseases.filter(item => {
        return item.name.toLowerCase().indexOf(this.searchdisease.toLowerCase()) > -1
      })
    },
    isDisablednext() {
      if(this.my_plan == "First Mover Plan" && this.my_diseases.length == 3){
        return false
      } else if(this.my_plan == "My Team Plan"){
        return false
      } else if(this.my_plan == "First Mover Plan" && this.sub_plan == "Single Disease Plan" && this.my_diseases.length == 1){
        return false
      } else {
        return true
      }  
    },
    isTabletMob : function() {
      const iPad = /iPad/i.test(navigator.userAgent);
      const iPhone = /iPhone/i.test(navigator.userAgent);
      const Android = /Android/i.test(navigator.userAgent);
      const webOS = /webOS/i.test(navigator.userAgent);
      const iPod = /iPod/i.test(navigator.userAgent);
      const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
      const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);
      return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
    }
  },
  methods: {
    onCancel() {
      console.log('User cancelled the loader.')
    },
    logOut() {
      localStorage.removeItem("authtoken");
      localStorage.removeItem("selectedColorList");
      // localStorage.setItem("selected_article", "Industry Updates");
      this.disesase = "Multiple sclerosis"
      this.title = "Multiple sclerosis"
      delete axios.defaults.headers.common["Authorization"];
      this.auth_token = null;
      
      this.$router.push("/").catch(() => {})           
    },
    goto_purchase(my_plan) {
      var value = 0
      this.sub_plan = ""
      this.my_plan = my_plan
      if (my_plan == "Free Plan"){
        value = 0
      }else if(my_plan == "First Mover Plan"){
        value = 1
        this.open_diseases_modal = true
      }else if(my_plan == "My Team Plan"){
        value = 2
        this.update_profile()
      }else if(my_plan == "Basic Plan"){
        value = 4
        this.update_profile()
      }
    },
    getuserProfile(){
      axios
        .get("/userProfile?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status !== 1) {
            this.user = res.data.Data;
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
            this.logOut()
          } else {
            console.log("user not found")
          }
        })
        .catch((error) => {
          console.log(error.message);
          if (error.message == "Request failed with status code 500") {
            this.logOut()             
          }
        });
    },
    CheckPlans(){
      this.loading = true
      this.planData = {}
      axios.get("/getUserPlans?token=" + this.auth_token)
      .then((res) => {
          if (res.data.Status !== 1) {
            this.loading = false
            this.planData = res.data.Data
            //console.log(this.planData,"this.planDataaaaaa")
            this.myplan = this.planData.Current_plan
            this.isActive= this.planData.Active
            this.my_previous_diseases = this.planData.Diseases
            this.temp_diseases = []
            this.diseases = []
            axios.get("/getalldiseases?token=" + this.auth_token).then((res) => {
              if (res.data.Status != 1) {
                var alldiseases_data = res.data.Data 
                var unmatched_diseases = []                   
                for(var m = 0; m < this.my_previous_diseases.length; m++) {
                    for (var i = 0; i < alldiseases_data.length; i++) {
                    if (alldiseases_data[i] !== undefined) {
                      if (this.my_previous_diseases[m].toLowerCase() == alldiseases_data[i].Name.toLowerCase()) { 
                        var spliced = alldiseases_data.splice(i,1)                       
                      }                    
                    }
                  }      
                }

                unmatched_diseases = alldiseases_data

                for(var j = 0; j < unmatched_diseases.length; j++) {
                  var dis = {};
                    dis.status = "Not-selected";
                    dis.name = unmatched_diseases[j].Name;
                    dis.ID = unmatched_diseases[j].ID;
                    dis.Name = unmatched_diseases[j].Name;
                    this.temp_diseases.push(dis);
                  
                }
                var dis = this.temp_diseases.sort(function(a, b) {
                  var textA = a.name.toUpperCase();
                  var textB = b.name.toUpperCase();
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });

                this.temp_diseases = dis
                this.diseases = this.temp_diseases
                if (this.diseases.length == 0) {
                  this.diseases_not_available = true
                } else if (this.diseases.length > 0){
                  if (this.diseases.length == 1) {
                     this.only_one_disease = true
                  }
                  this.diseases_not_available = false
                }
                //console.log(this.diseases,"this.diseases")
              } else {
                this.loading = false
                console.log("Diseases not found");
              }
            })
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            this.loading = false
            console.log("Invalid User")
          }
      })
      .catch((error) => {
        this.loading = false
          console.log(error.message);
          // if (error.message == "Request failed with status code 500") {
          //   this.logOut()             
          // }
      });
    },
    get_disease(dis) {
      if (this.my_plan == "First Mover Plan"){
        if (dis.status == "Selected") {
          dis.status = "Not-selected";
          for (var j = 0; j < this.my_diseases.length; j++) {
            if (this.my_diseases[j].name == dis.name) {
              var spliced = this.my_diseases.splice(j, 1);
              break;
            }
          }
        } else {
          if (this.sub_plan == "Single Disease Plan") {
            if (this.my_diseases.length == 1){
              this.extra_diseases = true
              return
            }
          } else {
            if (this.my_diseases.length == 3){
              this.extra_diseases = true
              return
            }
          }
          dis.status = "Selected";
          this.my_diseases.push(dis);
        }

        var md = this.my_diseases.sort(function (a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        this.my_diseases = md;

        var wishlist_array = []
        for(var f = 0; f < this.my_diseases.length; f++) {
          wishlist_array.push(this.my_diseases[f].name)
        }
        this.new_dis_len = this.my_diseases.length
      }
    },
    next_process() {
      this.open_diseases_modal = false
      let url = `/createDiseaseCart?token=` + this.auth_token;
      let config = { headers: { "Content-Type": "application/json" } };
      axios.post(url, JSON.stringify(this.my_diseases), config)
      .then((res) => {
          if (res.data.Status != 1) {
            this.loading = false
            this.new_dis_len =  0
            this.update_profile()
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
          } else {
            this.loading = false
            console.log("disease cart creation failed")
          }
      })
      .catch((error) => {
        console.log(error.message);
        // if (error.message == "Request failed with status code 500") {
        //   this.logOut()             
        // }
      });
    },
    close_diseases_modal() {
      this.open_diseases_modal = false
      if (this.my_diseases.length == 0) {
        this.new_dis_len = 0
      }
    },
    close_options_modal(){
      this.showFirstMoverPlanOptions = false
    },
    update_profile() {
      let url = `/updateSubPlan?token=` + this.auth_token + "&subplan=" + this.sub_plan
      axios.put(url).then((res) => {
        if (res.data.Status == 0) {
          //console.log(res.data.Data,"data")
          //Check here if user belongs to My Team Plan i.e someone has already included him/her to My Team Plan
          if (res.data.Data.Message == "Success") {
            console.log("profile updated")
            this.$router.push("/User_plan_payment/" + this.my_plan).catch(() => {}) 
          }
        } else {
          console.log("Error while profile updation");
          this.message = "Please try again";
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
    },
    show_plan_details() {
      this.$router.push("/Subscriptions").catch(() => {}) 
    },
    select_subplan_disease(sub) {
      if (sub == 'Single Disease') {
        this.my_plan = 'First Mover Plan'
        this.sub_plan = 'Single Disease Plan'
        this.open_diseases_modal = true
      } else if (sub == 'Unlimited Diseases') {
        this.my_plan = 'First Mover Plan'
        this.sub_plan = 'Unlimited Disease Plan'
        this.update_profile()
      }
    },
    showOptionsModal(){
      this.showFirstMoverPlanOptions = true
    },
    contactUs(){
      let route = this.$router.resolve({path: '/EnterprisePlan'});
      // let route = this.$router.resolve('/link/to/page'); // This also works.
      window.open(route.href, '_blank');
    }
  }
};
</script>
<style scoped>
  .margin_align {
    overflow-x: hidden;
    overflow-y: hidden;
    
   
  }
  .wholepage {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom: 0px;
    padding-top: 0px;
    margin: 0vh 0vh 0vh 0vh !important;
    overflow-y: auto
  }

  .Pricing-Plans {
    font-family: Ubuntu;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: center !important;
    color: #000;
  }

  .description {
    margin: 0vh 2vh 1vh 2vh !important;
    font-family: Ubuntu;
    font-size: 17px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: center;
    color: #6c6c6c;
  }

  .Rectangle-1152 {
    border: solid 4px #fff;
    background-color: #dceaed !important;
    border-radius: 24px;
    margin-left:-10vw;
    position: fixed !important;
    z-index: 1 !important;
    width:40vw !important
  }
  .Rectangle-1152_1 {
    margin-top:22vh;
    border: solid 4px #fff;
    background-color: #dceaed;
    border-radius: 24px;
    margin-left:-10vw;
    position: fixed !important;
    z-index: 1 !important;
    width:63vw !important;
  }
  .Rectangle-1152_2 {
    margin-top:52vh;
    border: solid 4px #fff;
    background-color: #dceaed;
    border-radius: 24px;
    margin-left:-10vw;
    position: fixed !important;
    z-index: 1 !important;
    width:40vw !important
  }

  .Rectangle-1151 {
    margin-top:-1.8vh;
    margin-left:-0.5vh;
    margin-bottom:-0.5vh;
    padding: 10px;
    border-radius: 24px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff !important;   
    min-height: 28vh;
    max-height:28vh;
    width:28vh;
    position: relative;
  }

  .Rectangle-1151_1 {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
  }

  .First-Mover { 
    font-family: Ubuntu;
    font-size: 2.4vh;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: left !important;
    color: #005290;
  }

  .Best-Suited-for {
    margin: 0vh 0vh 0vh 0vh;
    font-family: Ubuntu;
    font-size: 1.9vh !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #515b64;
    text-align: justify;
    text-justify: inter-word;
  }

  .price {
    margin: 0 2.1px 0 0;
    font-family: Ubuntu;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }

  .Month {
    margin: 12px 0 7px 2.1px;
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #797979;
  }

  .Annual-subscription {
    margin: 2vh 1vh 2vh 1vh !important;
    font-family: Ubuntu;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center !important;
    color: #005290;
  }

  .price1 {
    margin: 0 2.1px 0 0;
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }

  .discount{
    margin: 2vh 0.5vh 2vh 0.5vh !important;
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center !important;
    color: #343232;
  }

  .Path-748 {
    margin: 2vh 4vh 2vh 4vh;
    padding: 8px 5px 8px 5px !important;
    background-color: #edf6fc;
    text-align: center;
  }
  .description1 {
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #625e5e;
  }
  .enterprises {
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #909090;
  }

  .Rectangle-1153 {
    border-radius:16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vh;
    background-color: #fff;
   
  }

  .Choice-of-2-indications {
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    color: #343232;
  }

  .Line-194 {   
    height: 1.5px;
    margin: 18.8px 25px 15.8px 25px;
    background-color:  #e3eef0;
  }
  .first_sec {
    height:110vh;
    padding: 10px 40px 10px 10px;
    margin-bottom:5vh;
    background-color: #013d48;
    position: relative;
    z-index: 0;
  }
  .Pricing-Plans_title {
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-family: Ubuntu;
    font-size: 3vh;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .Get-access{
    margin-top:5vh;
    font-family: Ubuntu;
    font-size: 2.1vh;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: left;
    color: #d0d0d0;
    margin-right:10vh
  }

  .Free {
    margin: 2vh 0px;
    font-family: Ubuntu;
    font-size: 2vh;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.14;
    letter-spacing: normal;
    text-align: center;
    color: #3e3936;
  }

  .Get-Trial-Plan {
    font-family: Ubuntu;
    font-size: 2vh;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #f16753 !important;
    border-radius: 5px;
    height: 4vh !important;
    padding:10px;
  }

  .Rectangle-115233333 {
    border: solid 4px #fff;
    background-color: #dceaed !important;
    border-radius: 24px;
    margin:0vh 4vh 2vh 4vh;
  }
  .Rectangle-11513333 {
    border-radius: 20px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff !important;   
    width:100%;
    height:100%;
    margin-top:-0.5vh;
  }
  .First-Mover333 { 
    font-family: Ubuntu;
    font-size: 3vh;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: left !important;
    color: #005290;
  }
  .Free3333 {
    margin: 2vh 0px;
    font-family: Ubuntu;
    font-size: 2.2vh;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.14;
    letter-spacing: normal;
    text-align: center;
    color: #3e3936;
  }

  .Get-Trial-Plan333 {
    font-family: Ubuntu;
    font-size: 2.2vh;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #f16753 !important;
    border-radius: 5px;
    height: 4vh !important;
    width:fit-content;
    margin-top:1vh;
    padding:10px;
    word-break: break-all;
  }
  .margin_align {
    overflow-x: hidden;
    overflow-y: hidden ;
    height: 102vh !important;
   
  }
</style>

