<template>
  <div class="margin_align">
    <div class="wholepage">    
      <div class="appLayout">
        <div style="margin-top:10vh !important">
          <loading
              :active.sync="loading"
              :can-cancel="true"
              :on-cancel="onCancel"
              :is-full-page="fullPage"
          ></loading>
        </div>
        <div>
          <v-row>
            <v-col col="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
            <v-col col="12" sm="6" md="6" lg="6" xs="6" xl="6">
              <span class="BACK" @click="gotohome()"> < Back</span>
              <div style="margin-top: 2vh;">
                <span class="mycart">My Wishlist</span>
              </div>     
              <v-row v-if="show_data" style="margin-top:-1vh">
                <p class="description">Following diseases are currently unavailable. We will notify you once they are available to purchase.</p> 
                <v-row wrap>
                </v-row>                                        
              </v-row>
              <v-row v-if="!show_data" style="margin-top: 3vh;margin-bottom: 3vh;">
                <v-row>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4" align="center" justify="center">
                  <div class="Ellipse-153"><img src="../assets/Icons/Path 821.svg" style="height: 40px;object-fit: contain;"></div>
                  </v-col>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                </v-row>
                <p style="text-align: center;font-size: 18px;font-family: Ubuntu;">It seems like your Wishlist is empty!</p>
              </v-row>
            </v-col>
            <v-col col="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
          </v-row>
        </div>   
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { EventBus } from "../event-bus.js";
export default {
  data() {
    return {
      auth_token: "",
      disesase: "",
      loading: false,
      fullPage: true,
      show_data: false
    };
  },
  components: {
    Loading,
  },
  computed: {
  },
  mounted() {
    //console.log("profile setting")

    // var disesase = localStorage.getItem("selected_disease")
    // if (disesase !== null && disesase !== "undefined" && disesase !== "") {
    //   this.disesase = disesase
    // } else {
    //   this.disesase = "Multiple sclerosis"
    // }

    var auth_token = localStorage.getItem("authtoken");
    
    if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
      this.auth_token = auth_token
    }
  },
  methods: {
    onCancel() {
      console.log('User cancelled the loader.')
    },
    gotohome() {
      this.$router.push("/").catch(() => {}) 
          
      this.l1l2_page_data = {}
      this.l1l2_page_data.article_title = "Events"
      this.l1l2_page_data.disease = this.disesase
      EventBus.$emit("l1l2_page_data", this.l1l2_page_data);
    },
    logout() {
      localStorage.removeItem("authtoken");
      localStorage.removeItem("selectedColorList");
      // localStorage.setItem("selected_article", "Industry Updates");
      this.disesase = "Multiple sclerosis"
      delete axios.defaults.headers.common["Authorization"];
      this.auth_token = null;        

      this.$router.push("/").catch(() => {})
    },
  },
};
</script>
<style scoped>
  .margin_align {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
  }
  .wholepage {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 20px;
    padding-top: 0px;
    margin: 0vh 0vh 5vh 5vh !important;
    overflow-y: auto
  }
  .mycart {
    margin: 2vh;
    font-family: Ubuntu;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #32324a;
  }

  .BACK {
    margin: 2vh;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.43;
    letter-spacing: normal;
    text-align: left;
    color: #03a6ad;
    cursor: pointer;
  }
  .description {
    margin: 2vh;
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: normal;
    text-align: left;
    color: #676772;
  }
  .number {
    margin: 2vh;
    padding: 1vh;
    font-family: Ubuntu;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.78;
    letter-spacing: normal;
    text-align: left;
    color: #727272;
  }

  .Rectangle-1208 {
    margin: 1vh 3vh 1vh 3vh!important;
    padding: 0.5vh;
    border-radius: 9px !important;
    border: solid 1px #4a4a5c;
    background-color: #fff;
  }

  .content {
    font-family: Ubuntu;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: right;
    color: #202527;
  }
  
  .Rectangle-1212 {
    margin: 1vh 3vh 1vh 3vh!important;
    padding: 0.5vh;
    border-radius: 9px;
    border: solid 1px #03a6ad;
    background-color: #f7faff;
  }

  .Ellipse-153 {
    width: 90px;
    height: 90px;
    margin: 2vh;
    padding: 25px;
    border-radius: 90px;
    border: solid 1px #d6d6d6;
    background-color: #f8f8f8;
  }

</style>
        