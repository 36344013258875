<template>
  <v-main>
    <div style="margin-left: -4vw;">    
    <div class="appLayout">
        <div class="margin_align">
          <loading :active.sync="loading" 
            :can-cancel="true" 
            :on-cancel="onCancel"
            :is-full-page="fullPage"></loading>
          <div id="mist">
            <v-row wrap>
              <!--  <v-col cols="12" md="2" lg="2" sm="2" style="height:100vh" >
               </v-col> -->
              <v-col cols="12" md="3" lg="3" sm="3" style="height:100vh;background-color: #edf7f9 !important;" v-show="!my_notes_section">
                <div style="margin: 2.5vh 1.5vw 1.8vh 5.5vw !important;">
                  <v-row  wrap>
                    <v-col @click="show_my_articles('Events')" v-bind:class="show_events ? 'newall' : 'all'">
                      <span>News</span>
                    </v-col>
                    <v-col @click="show_my_articles('Reviews')" v-bind:class="show_reviews ? 'newall' : 'all'">
                      <span>Trends</span>
                    </v-col>
                    <v-col @click="show_my_articles('Modules')" v-bind:class="show_modules ? 'newall' : 'all'">
                        <span>Insights</span>
                    </v-col>
                    <v-col @click="show_followed_articles()" v-bind:class="show_followed ? 'newall' : 'all'" v-show="showmyfollowed"> 
                        <span>Saved</span>
                    </v-col>
                  </v-row>
                </div>
                <v-row wrap style="background-color: inherit;" class="ml-6 mr-6">
                  <div class="leftSidePanel" id="infinite-list1" v-show="!show_followed">
                    <v-row wrap>
                       <v-col cols="12" md="12" lg="12" sm="12" v-for="(my,kc) in my_articles" :key="kc" v-bind:class= "my.selected ?'selectedCard':'unselectedCard'">
                       <v-card  class="Rectangle-888" @click="view_details_section(my)">
                        <div :class="my.selected ?'company_alpha_selected' : 'company_alpha'">{{my.title_value}}</div>
                        <v-row wrap>
                            <v-col cols="12" md="8" lg="8" sm="8">
                              <span class="date" >{{my.date}} </span>
                            </v-col>
                             <v-col class="pull-right" cols="12" md="4" lg="4" sm="4" v-if="!no_authentication">
                                <img alt="share" src="../assets/Icons/Path 468.svg" class="Group-505" style="width:0.8vw;height:1.7vh;cursor:pointer;margin-left:2vh" @click.stop="show_share_modal(my.Article_id)">
                                <img v-show="!my.Followed" src="../assets/Icons/Path 338.svg" @click.stop="followme(my)" style="width: 1.7vh;height:1.7vh;cursor:pointer;margin-left:0.8vw">
                                <img src="../assets/Icons/Group 593.svg" v-show="my.Followed" style="width:1.7vh;height:1.7vh;margin-left:0.8vw">
                             </v-col>
                             <v-col class="pull-right" cols="12" md="4" lg="4" sm="4" v-if="no_authentication">
                                <img alt="share" src="../assets/Icons/Path 468.svg" class="Group-505" style="width:0.8vw;height:1.7vh;cursor:pointer;" @click.stop="login()">
                                <img v-show="!my.Followed" src="../assets/Icons/Path 338.svg" @click.stop="login()" style="width: 1.7vh;height:1.7vh;cursor:pointer;margin-left:0.8vw">
                                <img src="../assets/Icons/Group 593.svg" v-show="my.Followed" style="width:1.7vh;height:1.7vh;margin-left:0.8vw">
                             </v-col>
                          </v-row>
                        </v-card>                     
                      </v-col>
                    </v-row>
                    <div v-show="my_message != ''">
                      <p class="msg">{{my_message}}
                      </p>
                    </div>
                  </div>
                  <div class="leftSidePanel" id="infinite-list2" v-show="show_followed">
                    <v-row wrap>
                      <v-col cols="12" md="12" lg="12" sm="12" v-for="(sav,ks) in saved_articles" :key="ks" v-bind:class= "sav.selected ?'selectedCard':'unselectedCard'">
                       <v-card  class="Rectangle-888" @click="view_details_section(sav)">
                        <div :class="sav.selected ?'company_alpha_selected' : 'company_alpha'">{{sav.title_value}}</div>
                        <v-row wrap>
                            <v-col cols="12" md="8" lg="8" sm="8">
                              <span class="date" >{{sav.date}} </span>
                            </v-col>
                            <v-col class="pull-right" cols="12" md="4" lg="4" sm="4" v-if="!no_authentication">
                                <img alt="share" src="../assets/Icons/Path 468.svg" class="Group-505" style="width:0.8vw;height:1.7vh;cursor:pointer;margin-left:2vh" @click.stop="show_share_modal(sav.Article_id)">
                                <img src="../assets/Icons/Group 593.svg" v-show="sav.Followed" style="width:1.7vh;height:1.7vh;margin-left:0.8vw">
                             </v-col>
                          </v-row>
                        </v-card>                     
                      </v-col>
                    </v-row>
                    <div v-show="saved_message != ''">
                      <p class="msg">{{saved_message}}
                      </p>
                    </div>
                  </div>
              </v-row>
          </v-col>
          <!-- <v-col  cols="12" md="3" lg="3" sm="3" v-show="my_notes_section" class="notes_section mb-8 pb-8 mt-3">
                  <div style="margin-left:5vw;height:100%">
                    <v-row wrap >
                      <v-col cols="12" md="10" lg="10" sm="10" style="font-size: 18px;font-weight: 700;color: #49575d;">
                        Notes
                      </v-col>
                      <v-col cols="12" md="2" lg="2" sm="2">
                        <v-icon style="cursor:pointer;float: right;" color="#009ea5"
                          @click="close_notes_section()">mdi-close</v-icon>
                      </v-col>
                    </v-row>
                    <v-row justify="center" align="center" v-if="all_notes == null" style="margin-top: 20vh;">
                      <v-col cols="12" md="2" lg="2" sm="2"></v-col>
                      <v-col cols="12" md="8" lg="8" sm="8" class="justify-center align-center" align-self="center"
                        style="display:block; align-items: center; justify-content: center" align="center">

                        <img src="../assets/Icons/Group 598.svg">
                        <p
                          style="text-align: center;margin-top:30px;margin-bottom:20vh;font-size:16px;font-weight:700;color:#8c8e8e;">
                          You have not added any notes yet. Please start making notes for this article </p>

                      </v-col>
                      <v-col cols="12" md="2" lg="2" sm="2"></v-col>
                    </v-row>
                    <v-row v-if="all_notes != null" >
                      <v-list v-chat-scroll class="comments_list mb-0" >
                        <v-list-item>
                          <v-row no-gutters>
                            <v-col cols="12" sm="12" md="12" lg="12">
                              <v-list v-for="(all, key) in all_notes" v-bind:data="all" v-bind:key="key"
                                class="Rectangle-913">
                                <v-row>
                                  <v-col cols="12" sm="12" md="12" lg="12" xs="12">
                                    <div class="text_notes">{{ all.Note }}</div>
                                    <span class="date_notes">{{ all.notes_date }}</span>
                                  </v-col>
                                </v-row>
                              </v-list>
                            </v-col>
                          </v-row>
                        </v-list-item>
                      </v-list>
                    </v-row>

                    <div class="notesTextField">
                      <v-text-field 
                     v-model="mynote"
                        class=" mb-3 shrink noteBox" placeholder="Make your notes here...." solo
                        prepend-inner-icon="mdi-note-plus-outline"></v-text-field>
                    </div>
                  </div>
          </v-col> -->
          <v-col cols="12" md="9" lg="9" sm="9">  
            <div class="menu1" @click="showqueModal()">
              <!-- <i class="fa fa-copy fa-2x" id="copy-btn"></i> -->
              <img src="../assets/Icons/Group 1102.svg" style="height:3.5vh;width:3.5vh">
            </div>
            <div v-show="!loading && my_articles.length > 0 && article_not_found">
              <v-row>
                <v-col cols="12" md="12" lg="12" sm="12" class="Rectangle-721">
                    <p class="No_article" style="text-align:center;padding-top:30vh">
                      Not found.
                    </p>
                </v-col>
              </v-row>
            </div>             
            <div v-show="!loading && my_articles.length > 0 && !article_not_found">
              <v-row v-show="!saved_details" style="margin-left: 3vh !important;">
                <v-col cols="12" md="8" lg="8" sm="8" class="Rectangle-721">
                  <v-row wrap style="margin-top:0vh;top:0px;height:50px;overflow:hidden;">
                    <v-col cols="12" md="9" lg="9" sm="9" class="pt-2">
                      <span class="summary">Summary</span>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" sm="3" style="float:right;" v-if="!no_authentication" class="pt-2">
                      <img alt="notes" @click="show_notes(final_statements1, final_statements2, my_article_id, notes_title)" src="../assets/Icons/Path 533.svg" style="width:17px;height:17px;cursor:pointer;margin-left:1vw">
                      <img alt="share" src="../assets/Icons/Path 496.svg" class="Group-505" style="width:15px;height:15px;margin-left: 1vw !important;cursor:pointer" @click="show_share_modal(my_article_id)">
                      <img v-if="!my_follow" src="../assets/Icons/Path 338.svg" style="width:15px;height:15px;margin-left: 1vw;" @click="follow_me_right(my_article_id,sub)">
                      <img src="../assets/Icons/Group 593.svg" v-if="my_follow" style="width:15px;height:15px;margin-left: 1vw;">
                    </v-col>
                    <v-col cols="12" md="3" lg="3" sm="3" style="float:right;right:0px;" v-if="no_authentication">
                      <img alt="notes" @click="login()" src="../assets/Icons/Path 533.svg" style="width:17px;height:17px;cursor:pointer;margin-left:2vh">
                      <img alt="share" src="../assets/Icons/Path 496.svg" class="Group-505" style="width:15px;height:15px;margin-left: 1vw !important;cursor:pointer" @click="login()">
                      <img v-if="!my_follow" src="../assets/Icons/Path 338.svg" style="width:15px;height:15px;margin-left: 1vw;" @click="login()">
                      <img src="../assets/Icons/Group 593.svg" v-if="my_follow" style="width:15px;height:15px;margin-left: 1vw;">
                    </v-col>                 
                  </v-row>
                  
                  <textarea id="output" ref="outputcpy" v-model="outputcpy"></textarea>
                  <div id="mypopup" class="popup">
                    <div class="popup-content">
                    <p>Copied!!</p>
                    <span class="close-btn">×</span>
                    </div>
                  </div>
                  <div style="overflow-y: auto;overflow-x:hidden;margin-top: 10px; height: 82vh;" @mouseup="showque(1)">
                    <v-row v-for="(finc,key2) in final_statements1" :key="key2">
                      <div style="font-family: Ubuntu;font-size: 16px;font-weight: 500;text-align: left;color: #49575d;padding-right:30px" v-html="finc.div" v-if="finc.type !== 'string'"></div>
                      <div class="text" v-if="finc.type == 'string'">
                        <span style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="finc.newText" v-if="!finc.textLength"></span>
                        <span style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="finc.Text" v-if="finc.textLength"></span>
                       
                        <span @click="changetextLength(finc)" class="pointer" v-if="!finc.textLength && !finc.no_show">...<span style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;margin-left:1vh">See More</span><br></span>
                        <span @click="changetextLength(finc)" class="pointer" v-if="finc.textLength && !finc.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;">&nbsp;&nbsp;See Less<br></span>
                      </div>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="12" md="4" lg="4" sm="4" class="pt-2">
                  <div class="Rectangle-905">
                    <div class="Rectangle-903" style="top:0px;overflow:hidden">Impact Assessment</div>
                    <div style="background-color: #f2fcfc;padding: 0vh 1vw 2vh 1vh;overflow-y: auto; max-height: 80vh;" @mouseup="showque(2)">
                       <v-row v-for="(fic,keyc) in final_statements2" :key="keyc">            
                        <div v-if="fic.type == 'plot'">
                          <v-row wrap>
                            <v-col cols="12" md="12" lg="12" sm="12">
                              <!-- <div style="font-family: Ubuntu;font-size: 16px;font-weight: 500;text-align: left;color: #49575d;margin-bottom: 15vh;" v-html="fic.div"></div> -->
                            </v-col>
                          </v-row>
                        </div>
                        <div v-if="fic.type == 'image'">
                          <v-row wrap>
                            <v-col cols="12" md="2" lg="2" sm="2"></v-col>
                            <v-col cols="12" md="8" lg="8" sm="8">
                              <div style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="fic.div"></div>
                            </v-col>
                            <v-col cols="12" md="2" lg="2" sm="2"></v-col>
                          </v-row>
                        </div>
                        <div  v-if="fic.type == 'table'">
                          <v-row>
                            <span @click="show_table(fic.div)" style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #00b2ba;margin-bottom: 3vh;cursor: pointer;">See table here</span>
                          </v-row>
                        </div>
                        <div style="margin-top:2.5vh;font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="fic.div" v-if="fic.type != 'plot' && fic.type != 'image' && fic.type != 'table' && fic.type != 'string'"></div>                       
                        <div class="text2" v-if="fic.type == 'string'">                  
                          <span style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="fic.newText" v-if="!fic.textLength"></span>
                          <span style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;margin-bottom: 5vh;" v-html="fic.Text" v-if="fic.textLength"></span>
                          <span @click="changetextLength(fic)" class="pointer" v-if="!fic.textLength && !fic.no_show">...<span style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;margin-left:1vh">See More<br></span></span>
                          <span @click="changetextLength(fic)" class="pointer" v-if="fic.textLength && !fic.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;">&nbsp;&nbsp;See Less</span>

                        </div>
                      </v-row>
                    </div>
                  </div>
                </v-col> 
              </v-row>
            </div>            
            <div v-show="show_followed && !loading && saved_articles.length > 0">
              <v-row v-show="saved_details" style="margin-left: 3vh !important;">
                <v-col cols="12" md="8" lg="8" sm="8" class="Rectangle-721">
                  <v-row wrap style="margin-top:0vh;top:0px;height:60px;overflow:hidden">
                    <v-col cols="12" md="9" lg="9" sm="9">
                      <span class="summary">Summary</span>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" sm="3" style="float:right;" v-if="!no_authentication">
                      <img alt="notes" @click="show_notes(final_statements7, final_statements8, saved_article_id, notes_title)" src="../assets/Icons/Path 533.svg" style="width:17px;height:17px;cursor:pointer;margin-left:2vh">
                      <img alt="share" src="../assets/Icons/Path 496.svg" class="Group-505" style="width:15px;height:15px;margin-left: 3vh !important;cursor:pointer" @click="show_share_modal(saved_article_id)">
                      <img src="../assets/Icons/Group 593.svg" v-if="saved_follow" style="width:15px;height:15px;margin-left: 3vh;">
                    </v-col>                
                  </v-row>
                  <div style="overflow-y: auto;overflow-x:hidden;margin-top: 10px; height: 82vh;" @mouseup="showque(3)">
                    <v-row v-for="(fins,key5) in final_statements7" :key="key5">
                      <div style="font-family: Ubuntu;font-size: 16px;font-weight: 500;text-align: left;color: #49575d;" v-html="fins.div" v-if="fins.type !== 'string'"></div> 
                      <div class="text3" v-if="fins.type == 'string'">
                        <span style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="fins.newText" v-if="!fins.textLength"></span>
                        <span style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="fins.Text" v-if="fins.textLength"></span>
                        <span @click="changetextLength(fins)" class="pointer" v-if="!fins.textLength && !fins.no_show">...<span style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;margin-left:1vh">See More</span><br></span>
                        <span @click="changetextLength(fins)" class="pointer" v-if="fins.textLength && !fins.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;">&nbsp;&nbsp;See Less<br></span>
                      </div>                 
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="12" md="4" lg="4" sm="4">
                  <div class="Rectangle-905">
                    <div class="Rectangle-903" style="top:0px;overflow:hidden">Impact Assessment</div>
                    <div style="background-color: #f2fcfc;padding: 0vh 1vw 2vh 1vh;overflow-y: auto; height: 80vh;" @mouseup="showque(4)">
                       <v-row v-for="(fis,keyss) in final_statements8" :key="keyss">                       
                        <div v-if="fis.type == 'plot'">
                          <v-row wrap>
                            <v-col cols="12" md="12" lg="12" sm="12">
                              <!-- <div style="font-family: Ubuntu;font-size: 2.2vh;font-weight: 500;text-align: left;color: #49575d;margin-bottom: 15vh;" v-html="fic.div"></div> -->
                            </v-col>
                          </v-row>
                        </div>
                        <div v-if="fis.type == 'image'">
                          <v-row wrap>
                            <v-col cols="12" md="2" lg="2" sm="2"></v-col>
                            <v-col cols="12" md="8" lg="8" sm="8">
                              <div style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="fis.div"></div>
                            </v-col>
                            <v-col cols="12" md="2" lg="2" sm="2"></v-col>
                          </v-row>
                        </div>
                        <div  v-if="fis.type == 'table'">
                          <v-row>
                            <span @click="show_table(fis.div)" style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #00b2ba;margin-bottom: 3vh;">See table here</span>
                          </v-row>
                        </div>
                        <div style="margin-top:2.5vh;font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="fis.div" v-if="fis.type != 'plot' && fis.type != 'image' && fis.type != 'table' && fis.type != 'string'"></div>
                        <div class="text4" v-if="fis.type == 'string'">                                           
                          <span style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="fis.newText" v-if="!fis.textLength"></span>
                          <span style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="fis.Text" v-if="fis.textLength"></span>
                          <span @click="changetextLength(fis)" class="pointer" v-if="!fis.textLength && !fis.no_show">...<span style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;margin-left:1vh">See More</span><br></span>
                          <span @click="changetextLength(fis)" class="pointer" v-if="fis.textLength && !fis.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;">&nbsp;&nbsp;See Less</span>
                          <br>
                        </div>
                      </v-row>
                    </div>
                  </div>
                </v-col> 
              </v-row>
            </div>
          </v-col>
          <template>
            <div class="text-center">
              <v-dialog width="600" v-model="dialog">
                <v-card>
                  <v-card-title class="text-h5">
                    <span style="color: #49575d;margin-left: 2vh;;font-size: 18px;">Share</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="close_share()">
                      <v-icon color="#009ea5">mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-row style="margin-top:-3vh">
                      
                      <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                        <v-textarea
                          name="input-7-1"
                          class="mt-2"
                          v-model="quote_text"
                          type="text"
                          placeholder="Enter text and choose your social media platform to share"
                          outlined
                          dense
                          v-on:keyup="validateQuote"
                          style="margin-left:2vh;margin-right:2vh"
                        ></v-textarea>
                        <!-- <p style="font-size: 16px;font-weight:500;text-align:center">{{send_msg}}</p> -->
                      </v-col>
                      
                    </v-row>
                    <v-row wrap class="ml-2" style="margin-top:-5vh">
                      <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
                      <v-col cols="12" sm="8" md="8" lg="8" xs="8" xl="8">
                        <v-row wrap>
                          <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2" v-for="network in networks"
                            :key="network.network"
                          >
                          <ShareNetwork
                            :network="network.name"
                            :url= "myurl"
                            title="Knolens" 
                            :description="quote_text"
                            quote=""
                            hashtags=""
                          >
                            <div @click="share_article(network.name)">
                              <v-avatar
                              size="45"
                              class="mx-3 mb-3"
                              :color="network.color"
                              >
                                <i class="icon" :class="network.icon" color="white"></i>
                              </v-avatar>
                              
                              <!-- <div>
                                <span style="color: #49575d;text-align:center !important;margin-left:1vh"> {{network.name}} </span>
                              </div> -->
                            </div>
                            </ShareNetwork>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
        </template>
        <template>
              <div class="text-center">
                <v-dialog
                  v-model="msg_modal"
                  width="600"
                >
                  <v-card class="Path-359" style="padding:3vh 2vh 3vh 2vh">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                          <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;margin-right: 3vh;">
                          <span class="Active_Companies_title">Sign Up !</span>
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                        <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                          <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="msg_modal = false">mdi-close</v-icon>
                        </v-col>
                      </v-row>
                      <v-row wrap>
                        <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                          <span class="com_nm1">Please sign up to get this functionality.</span>
                        </v-col>   
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-row>
                        <v-col sm="6" md="6" lg="6" xs="6" xl="6"></v-col>
                        <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                          <v-btn                          
                            style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh;"
                             @click="goto_login()"
                          >
                            Sign Up
                          </v-btn>
                        </v-col>
                        <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                          <v-btn
                            style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 2.2vh;font-family: Ubuntu;"
                            @click="msg_modal = false"
                          >
                            Cancel
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>  
                  </v-card>
                </v-dialog>
              </div>
          </template>
          <template>
              <div class="text-center">
                <v-dialog
                  v-model="show_table_modal"
                  width="800"
                >
                  <v-card class="Path-359" style="padding:3vh 3vh 3vh 6vh !important">
                      <div><v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="show_table_modal = false">mdi-close</v-icon></div>
                      <v-row wrap>
                        <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                          <div v-html="table_display"></div>
                        </v-col>   
                      </v-row>
                  </v-card>
                </v-dialog>
              </div>
          </template>

          <template>
            <div class="text-center">
              <v-dialog width="600" v-model="show_notes_modal">
                  <v-card>
                    <v-card-title class="text-h5">
                      <span style="color: #49575d;margin-left: 2vh;;font-size: 18px;">Notes</span>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="show_notes_modal = false">
                        <v-icon color="#009ea5">mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                          <v-textarea
                            name="input-7-1"
                            class="mt-2"
                            v-model="mynote"
                            type="text"
                            placeholder="Enter notes here"
                            outlined
                            dense
                            style="margin-left:2vh;margin-right:2vh;font-weight: 500"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                    
                    <v-row style="margin-top: -5vh;margin-bottom: 2vh;margin-right:2vh">
                      <v-col sm="9" md="9" lg="9" xs="9" xl="9">
                        <p style="margin-left:5vh;margin-right:2vh;text-align: left;font-family:Ubuntu;font-size: 15px">{{notes_msg}}</p>
                      </v-col>
                      <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                        <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh;" :class="{ disabled: isDisabled }" :disabled="isDisabled" @click="savenote(notes_panel)">Save</v-btn>
                      </v-col>
                      <!-- <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                        <v-btn style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 2.2vh;font-family: Ubuntu;" @click="show_notes_modal = false">Cancel
                        </v-btn>
                      </v-col> -->
                    </v-row>
                  </v-card-actions>  
                  </v-card>
                </v-dialog>
            </div>
          </template>
          <template>
              <div class="text-center">
                <v-dialog
                  v-model="show_payment_failed"
                  width="600"
                > 
                 <v-card class="Path-359" style="padding:3vh 2vh 3vh 2vh">
                  <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                          <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;margin-right: 3vh;">
                          <span class="Active_Companies_title">Payment !</span>
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                        <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                          <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="show_payment_failed = false">mdi-close</v-icon>
                        </v-col>
                      </v-row>
                      <v-row wrap>
                        <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                          <p class="com_nm1">Your payment is unsuccessful. We will get back to you soon.</p>
                          <p class="com_nm1">info@pienomial.com</p>
                          <p class="com_nm1">1-800-275-2273</p>
                        </v-col>   
                      </v-row>
                    </v-card-text>
                  </v-card-text>
                  <v-card-actions>                   
                    <v-row style="margin-bottom: 2vh;margin-right:2vh">
                      <v-col sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
                      <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                        <v-btn style="background-color: #f16753; color: #ffffff;font-size: 2.2vh;"  @click="show_payment_failed = false">Contact Us</v-btn>
                      </v-col>
                      <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                         <v-btn                          
                            style="background-color: #ffffff; color: #f16753;font-size: 2.2vh;"
                            @click="show_payment_failed = false"
                          >
                           Cancel
                          </v-btn>
                      </v-col>
                      <v-col sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
                    </v-row>
                  </v-card-actions>
                  </v-card>  
                </v-dialog>
              </div>
          </template>
          <template>
            <div class="text-center">
              <v-dialog
                v-model="msg_modal_no_subscribe"
                width="600"
              >
              <v-card class="Path-359" style="padding:3vh 2vh 3vh 2vh">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                      <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;margin-right: 3vh;">
                      <span class="Active_Companies_title">Subscribe !</span>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                    <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                      <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="msg_modal_no_subscribe = false">mdi-close</v-icon>
                    </v-col>
                  </v-row>
                  <v-row wrap>
                                          
                    <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                      <!-- <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;"> -->
                      <span class="com_nm1" style="margin-left:0vh">Please subscribe to get this functionality.</span>
                    </v-col>
                  </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-row>
                      <v-col sm="6" md="6" lg="6" xs="6" xl="6"></v-col>
                      <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                        <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh;" @click="goto_user_pricing()">Subscribe</v-btn>
                      </v-col>
                      <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                        <v-btn style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 2.2vh;font-family: Ubuntu;" @click="msg_modal_no_subscribe = false">Cancel
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>  
                </v-card>
              </v-dialog>
            </div>
        </template>
          <QnAModal ref="QnAModal"></QnAModal>
        </v-row>
      </div>
    </div>
    </div>
    
  </div>
  </v-main>
</template>
<script>
import { EventBus } from "../event-bus.js";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import postscribe from "postscribe";
import QnAModal from "@/components/QnAModal.vue";
export default {
  data() {
    return {
      items_bread: [
        {
          text: 'Home',
          disabled: false,
        },
      ],
      loading: false,
      myjson: {},
      my_new_json: {},
      page_title: '',
      panels: [],
      version: '',
      page_type: '',
      link: '',
      templates: [],
      data_columns:[],
      data_rows: [],
      first_image: {},
      initial_version: "1.0",
      show: false,
      final_statements1: [],
      final_statements2: [],
      below:  false,
      total_business: 0,
      total_clinical: 0,
      total_science: 0,
      total_all: 0,
      fullPage: true,
      disesase: '',
      my_title: '',
      busi_page: 1,
      clinical_page: 1,
      sci_page: 1,
      my_message: '',
      follows: [],
      my_display_date : '',
      my_follow: false,
      my_article_id: '',
      mynote: "",
      all_notes: [],
      my_notes_section: false,
      notes_article_id: '',
      dialog: false,
      networks: [
        // {
        //   network: "facebook",
        //   name: "Facebook",
        //   icon: "fab fa-facebook-f",
        //   color: "#1877f2",
        // },

        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "fab fa-linkedin",
          color: "#007bb5",
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "fab fa-whatsapp",
          color: "#25d366",
        },
        // {
        //   network: "messenger",
        //   name: "Messenger",
        //   icon: "fab fa-facebook-messenger",
        //   color: "#0084ff",
        // },
        {
          network: "skype",
          name: "Skype",
          icon: "fab fa-skype",
          color: "#00aff0",
        },
        // {
        //   network: "telegram",
        //   name: "Telegram",
        //   icon: "fab fa-telegram-plane",
        //   color: "#0088cc",
        // },
        {
          network: "twitter",
          name: "Twitter",
          icon: "fab fa-twitter",
          color: "#1da1f2",
        },
        {
          network: "email",
          name: "Email",
          icon: "fa fa-envelope",
          color: "#1da1f2",
        },
      ],
      myurl: "",
      modal: false,
      shared_article_id: '',
      quote_text: '',
      quotetext: '',
      send_msg: '',
      sub: '',
      containerId: 0,
      no_authentication: false,
      msg_modal: false,
      category_changed: false,
      my_diseases: [],
      alldiseases: [],
      show_events: false,
      show_reviews: false,
      show_modules: false,
      show_followed: false,
      follow_page: 1,
      saved_articles: [],
      saved_details: false,
      saved_message: '',
      saved_follow: false,
      final_statements7: [],
      final_statements8: [],
      showmyfollowed: false,
      my_articles: [],
      network_items: false,
      domain_origin: '',
      user_approved: false,
      show_table_modal: false,
      table_display: {},
      show_notes_modal: false,
      notes_panel: {},
      notes_msg: "",
      notes_title: "",
      show_payment_failed: false,
      user_not_subscribed: false,
      myplan: "",
      msg_modal_no_subscribe: false,
      queans_modal: false,
      my_word: "",
      queans: [],
      myquestion: "",
      show_que: false,
      option_lables: [
        {
          name: "Labels",
          status: "selected"
        },
        {
          name: "Clinical Studies",
          status: "notselected"
        },
        {
          name: "PubMed",
          status: "notselected"
        },
        {
          name: "Patents",
          status: "notselected"
        },
      ],
      checkboxtexts: [
        {  text: "NDA215814 REZLIDHIA is an isocitrate dehydrogenase-1 (IDH1) inhibitor indicated for the treatment of adult patients with relapsed or refractory acute myeloid leukemia (AML) with a susceptible IDH1 mutation as detected by an FDA-approved test."
        },
        {  text: "NDA208573 VENCLEXTA is a BCL-2 inhibitor indicated:For the treatment of adult patients with chronic lymphocytic leukemia (CLL) or small lymphocytic lymphoma (SLL). In combination with azacitidine, or decitabine, or low-dose cytarabine for the treatment of newly diagnosed acute myeloid leukemia (AML) in adults 75 years or older, or who have comorbidities that preclude use of intensive induction chemotherapy."
        },
        {  text: "NDA211349 XOSPATA is a kinase inhibitor indicated for the treatment of adult patients who have relapsed or refractory acute myeloid leukemia (AML) with a FLT3 mutation as detected by an FDA-approved test."
        },
        {  text: "NDA214120 ONUREG is a nucleoside metabolic inhibitor indicated for continued treatment of adult patients with acute myeloid leukemia who achieved first complete remission (CR) or complete remission with incomplete blood count recovery (CRi) following intensive induction chemotherapy and are not able to complete intensive curative therapy"
        },
        {  text: "NDA209401 VYXEOS has different dosage recommendations than daunorubicin hydrochloride injection, cytarabine injection, daunorubicin citrate liposome injection, and cytarabine liposome injection. Verify drug name and dose prior to preparation and administration to avoid dosing errors"
        },
        {  text: "NDA050734 IDAMYCIN PFS Injection in combination with other approved antileukemic drugs is indicated for the treatment of acute myeloid leukemia (AML) in adults. This includes French-American-British (FAB) classifications M1 through M7."
        },
        {  text: "NDA209606 IDHIFA is an isocitrate dehydrogenase-2 inhibitor indicated for the treatment of adult patients with relapsed or refractory acute myeloid leukemia (AML) with an isocitrate dehydrogenase-2 (IDH2) mutation as detected by an FDA-approved test"
        },
        {
          text: "NDA210656 DAURISMO is a hedgehog pathway inhibitor indicated, in combination with low-dose cytarabine, for the treatment of newly-diagnosed acute myeloid leukemia (AML) in adult patients who are ≥75 years old or who have comorbidities that preclude use of intensive induction chemotherapy"
        },
        {
          text: "BLA761060 MYLOTARG is a CD33-directed antibody and cytotoxic drug conjugate indicated for:treatment of newly-diagnosed CD33-positive acute myeloid leukemia (AML) in adults and pediatric patients 1 month and older (1.1).treatment of relapsed or refractory CD33-positive AML in adults and pediatric patients 2 years and older"
        },
        {
          text: "NDA207997 RYDAPT is a kinase inhibitor indicated for the treatment of adult patients with:Newly diagnosed acute myeloid leukemia (AML) that is FLT3 mutation-positive as detected by an FDA-approved test, in combination with standard cytarabine and daunorubicin induction and cytarabine consolidation. (1.1) Limitations of Use: RYDAPT is not indicated as a single-agent induction therapy for the treatment of patients with AML.Aggressive systemic mastocytosis (ASM), systemic mastocytosis with associated hematological neoplasm (SM-AHN), or mast cell leukemia (MCL)"
        }
      ],
      
      pageX: 0,
      pageY: 0,
      outputcpy: "",
      checkboxData:[],
      loadingModal: true,
      questionIds: [],
      option:"fda",
      question: "",
      loadingAnswer: false,
      resetCheckbox:false,
      selectedOption: "fda",
      oldQuestionIds: [],
      disease:"",
      article_not_found : false,
      user_plan: '',
    };
  },
  components: {
    //   SideBar,
    //   AppBar,
    QnAModal,
    Loading
  },
  computed: {
    isDisabled() {
      if (this.mynote !== '' && this.mynote !== ' ') {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    var title = "Events"
   this.get_page_activity()
    // var disesase = localStorage.getItem("selected_disease")
    var disesase=this.disease
    this.get_basic_values(title,disesase)

    document.addEventListener("mousedown", (e) => {
      this.pageX = e.pageX;
      this.pageY = e.pageY;
    });

    EventBus.$on("l1l2_page_data", (l1l2_page_data) => {
      title = l1l2_page_data.article_title
      disesase = l1l2_page_data.disease
      this.my_notes_section = false
      this.my_message = ''
      this.saved_message = ''
      this.my_follow = false
      this.get_basic_values(title,disesase)
    });
  },
  beforeDestroy(){
    EventBus.$off('l1l2_page_data')
  },
  methods: { 
    changetextLength(obj) {
      if (!obj.textLength) {
        obj.textLength = true
      } else {
        obj.textLength = false
      } 
      this.$forceUpdate();
    },
    get_basic_values(title, disesase){
      this.my_articles = []
      this.saved_articles = []
      this.my_message = ''
      this.saved_message = ''
      var auth_token = localStorage.getItem("authtoken");

      this.domain_origin = window.location.origin

      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/plotly.js/2.8.3/plotly.min.js')
      document.head.appendChild(recaptchaScript)

      this.title = title

      // this.title = title.toLowerCase()
      //     .split(' ')
      //     .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      //     .join(' ');
      
      if (disesase !== undefined && disesase !== null && disesase !== '') {
        this.disesase = disesase.toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
          this.auth_token = auth_token
        } else {
          this.auth_token = ""
        }

      } else {
        if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
          this.auth_token = auth_token
          this.showmyfollowed = true   
          this.getdiseases(this.auth_token)
          this.getUserPlan()
        } {
          this.auth_token = ""
          this.showmyfollowed = false
          this.getdiseases_no_auth()
        }
      }

      var mypage = 1
      
      if (this.title == "Events") {
        this.show_events = true
        this.show_reviews = false
        this.show_modules = false
        this.show_followed = false
        this.sub = "Events"
      } else if (this.title == "Reviews") {
        this.show_events = false
        this.show_reviews = true
        this.show_modules = false
        this.show_followed = false
        this.sub = "Reviews"
      } else if (this.title == "Modules"){
        this.show_events = false
        this.show_reviews = false
        this.show_modules = true
        this.show_followed = false
        this.sub = "Modules"
      } else {
        //console.log("Title Mismatch")
      }

      if (this.auth_token !== null && this.auth_token !== "" && this.auth_token !== undefined) {
        this.no_authentication = false
        this.showmyfollowed = true
        this.get_breadcrum_item()
        //this.get_total_count()
        setTimeout(e => {
          this.get_articles_subtype(this.title,mypage)
        },100)
        this.page_scrolling(mypage)
      } else {
        this.no_authentication = true
        this.showmyfollowed = false
        this.disesase = 'Multiple Sclerosis'
        this.title = "Events"
        this.get_breadcrum_item()
        //this.get_total_count_no_auth()
        this.get_articles_subtype_no_auth(this.title,mypage)
        this.page_scrolling(mypage)
      }
    },
    page_scrolling(mypage) {      
      const listElm = document.getElementById('infinite-list1');
      if (listElm !== null) {
        listElm.addEventListener('scroll', e => {
          //console.log(listElm.scrollTop + listElm.clientHeight, listElm.scrollHeight);
          if(listElm.scrollTop + listElm.clientHeight + 5 >= listElm.scrollHeight) {
            if (this.category_changed == true) {
              mypage = 1
              this.my_articles = []
              this.saved_articles = []
            } else {
              mypage++
            }
            
            if (this.auth_token !== null && this.auth_token !== "" && this.auth_token !== undefined) {
              if (mypage > 1){
                this.get_articles_subtype(this.title,mypage)
              }
            } else {
              if (mypage > 1){
                this.get_articles_subtype_no_auth(this.title,mypage)
              }
            }
          }
        });
      }

      const listElm1 = document.getElementById('infinite-list2');
      if (listElm1 !== null) {
        listElm1.addEventListener('scroll', e => {
          if(listElm1.scrollTop + listElm1.clientHeight + 5 >= listElm1.scrollHeight) {
            if (this.category_changed == true) {
              mypage = 1
              this.my_articles = []
              this.saved_articles = []
            } else {
              mypage++
            }
            
            if (this.auth_token !== null && this.auth_token !== "" && this.auth_token !== undefined) {
              if (mypage > 1){
                this.get_articles_subtype(this.title,mypage)
              }
            } else {
              if (mypage > 1){
                this.get_articles_subtype_no_auth(this.title,mypage)
              }
            }
          }
        });
      }
    },
    save_page_activity(article_id) {
      var disease = this.disesase
      var articleid = article_id
      var duration = "0"
      var panel_duration = "0"
      var activity_name = "Page Change"
      var panel_number = "0"
      var pagename = this.title
      
      axios
        .post("/SaveActivity?id=" + articleid + '&token=' + this.auth_token + "&activity_name=" + activity_name + "&disease=" + disease + "&page_name=" + pagename + "&duration=" + duration + "&panel_duration=" + panel_duration)
        .then((res) => {
          if (res.data.Status != 1) {
            //console.log("Activity saved successfully")
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log(error.message);
          } else {
            console.log("Invalid User")
          }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);      
        }
      });
    },
    getdiseases(token) {
      this.alldiseases = []
      var all_diseases = []
      var mydis = this.my_diseases
      axios
        .get("/getalldiseases?token=" + token)
        .then((res) => {
          if (res.data.Status != 1) {
            this.alldiseases = res.data.Data
            this.get_user_profile()
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
          } else {
            this.disesase = "Multiple sclerosis"
            console.log("Diseases not found")
          }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);        
        }
      });
    },
    get_user_profile() {
      axios
        .get("/userProfile?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status !== 1) {
              this.user = res.data.Data;
              this.myplan = this.user.Plan
              var user_dis = this.user.Diseases

              if (user_dis.length > 0) {
                //console.log("Got users diseases")
              } else {
                user_dis = ['Multiple sclerosis']
              }


              for (var a = 0; a < this.alldiseases.length; a++) {
                for (var b = 0; b < user_dis.length; b++) {
                  if (this.alldiseases[a].Name == user_dis[b]) {
                    this.my_diseases.push(this.alldiseases[a].Name)
                  }
                }
              }
              
              var unique_diseases = this.my_diseases.filter(this.onlyUnique);
              this.my_diseases = unique_diseases
              
              if (this.my_diseases.length > 0) {
                  var dis = this.my_diseases.sort(function(a, b) {
                    var textA = a.toUpperCase();
                    var textB = b.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                  });
                  this.my_diseases = dis
              } 
              this.disesase = this.my_diseases[0]  
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")()
            //this.$router.push("/").catch(() => {})
          } else {
            this.disesase = "Multiple sclerosis"
            console.log("Diseases not found")
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);          
          }
        });
    },
    getdiseases_no_auth() {
      this.my_diseases = []
      axios
        .get("/get_noauth_diseases")
        .then((res) => {
          if (res.data.Status != 1) {
            for (var i = 0 ; i < res.data.Data.length; i++) {
              this.my_diseases.push(res.data.Data[i].Name)
            }
            var unique_diseases = this.my_diseases.filter(this.onlyUnique);
            this.my_diseases = unique_diseases
            this.disesase = this.my_diseases[0]
            //console.log(this.disesase,"this.disesase")     
          } else {
            this.disesase = "Multiple sclerosis"
            console.log("Diseases not found")
          }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);       
        }
      });
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    goToHome() {
      this.$router.push("/Disease").catch(() => {})
    },
    onCancel() {
      console.log('User cancelled the loader.')
    },
    // To display breadcrums
    get_breadcrum_item () {
      for (var i = 0 ; i < 2; i++) {
        var bread = {}
        if (i == 0) {
          bread.text = this.disesase
        } else {
          bread.text = this.title
        }
        bread.disabled = false
        bread.href = ""
        this.items_bread.push(bread)
      }
    },
    view_details_section (article) {
      this.display_date = article.date
      this.notes_title = article.title_value
      
      if (!this.show_followed) {
        if (article.category == "Events") {
          this.events_details = true
        } else if (article.category == "Reviews") {
          this.reviews_details = true
        } else if (article.category == "Modules") {
          this.modules_details = true
        } else {
          console.log("Category not matching")
        }          
        this.saved_details = false         
        //this.sub = "Business Implications"
        this.my_display_date = article.date
        this.my_follow = article.Followed
        this.my_article_id = article.Article_id
      } else {
        this.events_details = false
        this.reviews_details = false
        this.modules_details = false
        this.saved_details = true
        this.saved_display_date = article.date
        this.saved_follow = article.Followed
        this.saved_article_id = article.Article_id

        for (var i = 0 ; i < this.saved_articles.length; i++) {
          if (this.saved_article_id == this.saved_articles[i].Article_id) {
            this.saved_articles[i].selected = true
          } else {
            this.saved_articles[i].selected = false
          }
        }
      }
      if (!this.no_authentication) {
        this.get_article_link(article)
      } else {
        this.get_article_link_no_auth(article)
      }
      
    },

    // To get count of articles
    get_total_count() {
      axios
        .get("/getAllL1L2Count?token=" + this.auth_token + "&disease=" + this.disesase)
        .then((res) => {
          if (res.data.Status !== 1) {
            this.total_business = res.data.Data.total_business
            this.total_clinical = res.data.Data.total_clinical
            this.total_science =res.data.Data.total_science
            this.total_all = this.total_business + this.total_clinical + this.total_science
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")()
            //this.$router.push("/").catch(() => {})
          } else {
            console.log("Counts not found")
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);          
          }
        });
    },

    // To get link of json file from aws
    get_article_link(art){
      for (var i = 0 ; i < this.my_articles.length; i++) {
        if (art.ID == this.my_articles[i].ID) {
            art.selected = true
          } else {
            this.my_articles[i].selected = false
        }
      }     
      this.loading = true
      this.link = art.S3_key

      if (this.show_followed) {
        art.Title = "saved"
      }

      this.title = art.category

      // To save activity
      if (art.Article_id !== '') {
        // this.save_page_activity(art.Article_id)
      }
      this.article_not_found = false
      // To get json link
      axios
        .get("/getArticleUrlL1L2?s3_link=" + this.link +"&token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status != 1) {
            //console.log(res.data.Data.Url,"article link found")
            fetch(res.data.Data.Url)
            .then(res => res.json())
            .then((out) => {
              var my_json = out
              this.article_not_found = false
              this.get_article_content(my_json, art.Title)
            })
            .catch(err => { 
              //this.article_not_found = true
              this.loading = false
            });
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")()
            //this.$router.push("/").catch(() => {})
          } else {
            this.article_not_found = true
            console.log("Article link not found")
          }
      })
      .catch((error) => {       
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);            
        }
      });
    },

    // To display article content 
    get_article_content(myjson, pgtitle) {
      //console.log(myjson, pgtitle,"22222")
      this.myjson = myjson
      //var scripts = ''
      this.loading = true
      var len = Object.keys(this.myjson).length;
      var myval = Object.values(this.myjson)
      this.version = this.myjson.Software_Version
      this.page_type = this.myjson.Type

      // this.page_title = this.myjson.Article_Title.Title
      // this.article_title = this.myjson.Article_Title.Template
      // this.mandatory_var = this.myjson.Article_Title.Mandatory_Variables
      // var img = this.myjson.Article_Title.Image

      this.page_title = this.myjson.Card.Title
      this.article_title = this.myjson.Card.Template
      this.mandatory_var = this.myjson.Card.Mandatory_Variables
      var img = this.myjson.Card.Image

      var image = new Image();
      //image.src = 'data:image/png;base64,'+ img
      image.src = img
      this.first_image = image

      if (pgtitle !== "saved") {
        this.final_statements1 = []
        this.final_statements2 = []
      } else if (pgtitle == "saved") {
        this.final_statements7 = []
        this.final_statements8 = []
      } else {
        console.log("invalid title")
      }

      this.templates = this.myjson.Templates
      var scripts = `<script type="text/javascript">setTimeout(function(){`
      
      // to get values of article title

      for (var i = 0; i < this.mandatory_var.length; i++) {
        var name = this.mandatory_var[i].Name
        var type = this.mandatory_var[i].Type
        var default_value = this.mandatory_var[i].Default_Values
        var match_string = '{{' + type + '__'+ name + '}}'
        //var match_string = '%%{' + type + '__'+ name + '}%%'
        if (this.article_title.includes(match_string)) {
          if (type == "string") {
            default_value = this.capitalizeFirstLetter(default_value)
          }
          let result = this.article_title.replace(match_string, default_value);
          this.article_title = result
        }
      }
        

      this.l1 = this.templates[0]

      // to make string new lines separate

      var mysrch1 = "\\n"

      // to get values of L1 template
      this.l1_man_var = this.l1.Mandatory_Variables
      
      var statement = this.l1.Template.split(mysrch1)
      
      for (var k = 0;  k < statement.length; k++) {
        var resultingString1 = statement[k]
        var exp_obj = {}
        exp_obj.myid = "myid_"+k
        for (var j = 0; j < this.l1_man_var.length; j++) {
          var name = this.l1_man_var[j].Name
          var type = this.l1_man_var[j].Type      
          var default_value = this.l1_man_var[j].Default_Values
          var match_string = '{{' + type + '__'+ name + '}}'
          //var match_string = '%%{' + type + '__'+ name + '}%%'
          if (type == "int" || type == "string" || type == "date") {
            //console.log(type,"type")
            if (statement[k].includes(match_string)){
              var pieces1 = resultingString1.split(match_string);
              if (type == "string") {
                //default_value = this.capitalizeFirstLetter(default_value)
                default_value = default_value
              }
              resultingString1 = pieces1.join(default_value);
              exp_obj.div = '<p style="font-size:15px">'+resultingString1+'</p>'
              exp_obj.type = 'string'
              var whole_string = '<br><span style="font-size:15px;">'+resultingString1+'</span>'
              exp_obj.Text = whole_string

              var newText = whole_string
              exp_obj.newText = newText
              exp_obj.textLength = false
              exp_obj.no_show = true

              // var charCount = 0
              // var comma_position = 0
              // for (var c = 0; c < exp_obj.Text.length; c++) {
              //   if (exp_obj.Text[c] == ',') {
              //     charCount = charCount + 1
              //     if (charCount == 10) {
              //       comma_position = c
              //     }
              //   }
              // }
              
              // if (charCount >= 10) {
              //   var newText = whole_string.substring(0, comma_position);
              //   exp_obj.newText = newText
              //   exp_obj.textLength = false
              //   exp_obj.no_show = false
              // } else {
              //   var newText = whole_string
              //   exp_obj.newText = newText
              //   exp_obj.textLength = false
              //   exp_obj.no_show = true
              // }

              if (exp_obj.div.includes(below_string) ) {
                exp_obj.below = true
              } else {
                exp_obj.below = false
              }
            }
          } else if (type == "hyperlink") {
              if (statement[k].includes(match_string)){
                var pieces1 = resultingString1.split(match_string);

                var lnk = this.domain_origin+ '/#/' + default_value.Link

                if (default_value.Link.includes("https://")) {
                  lnk = default_value.Link
                }

                var  hyp1 = `<a target='_blank' href="${lnk}">${default_value.Text}</a>`;

                resultingString1 = pieces1.join(hyp1); 
                exp_obj.div =  resultingString1 
                exp_obj.type = 'link'       
              }
          } else if (type == "table") {
            if (default_value.Table_Data !== undefined) {
              if (statement[k].includes(match_string)){
                if (Object.keys(default_value).length !== 0) {
                  var data_columns1 =  default_value.Table_Data.Columns
                  var data_rows1 = default_value.Table_Data.Rows
                  if (data_columns1 !== null && data_rows1 !== null) {
                    var div333 = this.displayShelfItemPairs(data_columns1, data_rows1)
                    var div444 = div333.outerHTML
                    exp_obj.type = 'table'
                    exp_obj.div = `${div444}` 
                  } else {
                    console.log("row or table data null")
                  }
                }
              }
            }
          } else if (type == "image") {
            if (statement[k].includes(match_string)){
              var image1 = new Image();

              image1.src = 'data:image/png;base64,'+ default_value
              exp_obj.div = `<img
                          src="${image1.src}"
                          alt="image"
                          style="height: 200px;200px;margin-top:3vh;margin-bottom:3vh"
                          />`
              exp_obj.type = "image"
            }
          } else if (type == "plot"){
              if (statement[k].includes(match_string)) {
                var double_encode = default_value.split('.')[0]
                var plt_data = window.atob(double_encode);
                if (plt_data != '') {
                  var obj = JSON.parse(plt_data) 

                  var plot_data = obj.div64.split('.')[0]
                  var div = window.atob(plot_data);

                  div = div.replace(/\\"/g, '"')
                  div = div.replace(/\\'/g, "'")
                  div = div.replace(/\r?\n|\r/g, "\n")

                  var div64 = div
                  //console.log(div64,"div")

                  var script_data = obj.script64.split('.')[0]
                  var script = window.atob(script_data);

                  script = script.replace(/\\"/g, '"')
                  script = script.replace(/\\'/g, "'")
                  script = script.replace(/\r?\n|\r/g, "\n")

                  var script64 = script
                  //console.log(script64,"script")

                  var str = script64.replace('<script type="text/javascript">', "\n")
                  var str1 = str.replace("window.PLOTLYENV=window.PLOTLYENV || {};","\n")
                     var str2 = str1.replace("<\/script>", "\n")
                     scripts += str2

                  var pieces1 = resultingString1.split(match_string);
                  resultingString1 = pieces1.join(div64);
                  exp_obj.div = resultingString1
                  exp_obj.type = "plot"
                }
              }
            }  
        }
        if (pgtitle !== "saved") {
          this.final_statements1.push(exp_obj)
          this.saved_details = false
        } else if (pgtitle == "saved") {
          this.final_statements7.push(exp_obj)
          this.saved_details = true
        } else {
          console.log("invalid title")
        }     
                
      }
      this.loading = false

      //console.log(this.final_statements1,"final_statements1")

      // to get values of L2 template
      this.l2 = this.templates[1] 
      
      this.l2_man_var = this.l2.Mandatory_Variables

      var statement2 = this.l2.Template.split(mysrch1)
      var below_string = "Below is the plot"
      
      for (var k = 0;  k < statement2.length; k++) {
        var resultingString2 = statement2[k]
        var exp_obje = {}
        for (var j = 0; j < this.l2_man_var.length; j++) {
          var name = this.l2_man_var[j].Name
          var type = this.l2_man_var[j].Type      
          var default_value = this.l2_man_var[j].Default_Values
          var match_string = '{{' + type + '__'+ name + '}}'
          //var match_string = '%%{' + type + '__'+ name + '}%%'
          if (type == "int" || type == "string" || type == "date") {
            if (statement2[k].includes(match_string)){
              var pieces2 = resultingString2.split(match_string);
              if (type == "string") {
                //default_value = this.capitalizeFirstLetter(default_value)
                default_value = default_value
              }
              resultingString2 = pieces2.join(default_value);
              exp_obje.div = '<span style="font-size:15px">'+resultingString2+'</span>'
              exp_obje.type = 'string'
              var whole_string = '<br><span style="font-size:15px">'+resultingString2+'</span>'
              exp_obje.Text = whole_string
              var charCount = 0
              var comma_position = 0
              for (var c = 0; c < exp_obje.Text.length; c++) {
                if (exp_obje.Text[c] == ',') {
                  charCount = charCount + 1
                  if (charCount == 3) {
                    comma_position = c
                  }
                }
              }
              
              if (charCount >= 4) {
                var newText = whole_string.substring(0, comma_position);
                exp_obje.newText = newText
                exp_obje.textLength = false
                exp_obje.no_show = false
              } else {
                var newText = whole_string
                exp_obje.newText = newText
                exp_obje.textLength = false
                exp_obje.no_show = true
              }

              //console.log(exp_obje,"exp_obje")

              if (exp_obje.div.includes(below_string) ) {
                exp_obje.below = true
              } else {
                exp_obje.below = false
              }  
            }
          } else if (type == "hyperlink") {
            if (statement2[k].includes(match_string)){
              var pieces2 = resultingString2.split(match_string);

              var lnk = this.domain_origin + '/#/' + default_value.Link             
             
              if (default_value.Link.includes("https://")) {
                lnk = default_value.Link
              }
              //console.log(lnk,"lnk")
              var  hyp2 = `<a target='_blank' href="${lnk}">${default_value.Text}</a>`;

              resultingString2 = pieces2.join(hyp2); 
              exp_obje.div =  resultingString2 
              exp_obje.type = 'link'       
            }

          } else if (type == "table") {
            if (default_value.Table_Data !== undefined) {
              if (statement2[k].includes(match_string)){
                if (Object.keys(default_value).length !== 0) {
                  var data_columns2 =  default_value.Table_Data.Columns
                  var data_rows2 = default_value.Table_Data.Rows
                  if (data_columns2 !== null && data_rows2 !== null) {
                    var div111 = this.displayShelfItemPairs(data_columns2, data_rows2)
                    var div222 = div111.outerHTML
                    exp_obje.type = 'table'
                    exp_obje.div = `${div222}`
                  } else {
                    console.log("row or column data null")
                  }
                }
              }
            }
          } else if (type == "image") {
            if (statement2[k].includes(match_string)){
              var image2 = new Image();

              image2.src = 'data:image/png;base64,'+ default_value
              exp_obje.div = `<img
                          src="${image2.src}"
                          alt="image"
                          style="height: 200px;200px;margin-top:3vh;margin-bottom:3vh"
                          />`
              exp_obje.type = "image"
            }
          } else if (type == "plot"){
            if (statement2[k].includes(match_string)) {
              var double_encode = default_value.split('.')[0]
              var plt_data = window.atob(double_encode);
              if (plt_data != '') {
                var obj = JSON.parse(plt_data) 

                var plot_data = obj.div64.split('.')[0]
                var div = window.atob(plot_data);

                div = div.replace(/\\"/g, '"')
                div = div.replace(/\\'/g, "'")
                div = div.replace(/\r?\n|\r/g, "\n")

                var div64 = div
                //console.log(div64,"div")

                var script_data = obj.script64.split('.')[0]
                var script = window.atob(script_data);

                script = script.replace(/\\"/g, '"')
                script = script.replace(/\\'/g, "'")
                script = script.replace(/\r?\n|\r/g, "\n")

                var script64 = script
                //console.log(script64,"script")

                var str = script64.replace('<script type="text/javascript">', "\n")
                var str1 = str.replace("window.PLOTLYENV=window.PLOTLYENV || {};","\n")
                   var str2 = str1.replace("<\/script>", "\n")
                   scripts += str2

                var pieces2 = resultingString2.split(match_string);
                resultingString2 = pieces2.join(div64);
                exp_obje.div = resultingString2
                exp_obje.type = "plot"
              }
            }
          }
          
        }

        if (pgtitle !== "saved") {
          this.final_statements2.push(exp_obje)
        } else if (pgtitle == "saved") {
          this.final_statements8.push(exp_obje)
        } else {
          console.log("invalid title")
        }  
      }
      //console.log(this.final_statements2,"final_statements2")
      scripts = scripts + `}, 3000);<\/script>`
      postscribe('#mist', scripts)
    },

    // To display content in table
    displayShelfItemPairs(col, rw) {
      this.data_columns = col
      this.data_rows = rw
      // To make table
      var table = document.createElement("table");
      table.setAttribute('id', 'bom_table');
      table.setAttribute("style", "display: block;overflow-x: auto; margin-top:2vh; margin-bottom:2vh; border-collapse:collapse; table-layout:fixed;max-height:60vh");
      var tr = table.insertRow(-1);
      for (var i = 0; i < this.data_columns.length; i++) {
        var th = document.createElement("th");
        th.innerHTML = this.data_columns[i];
        th.setAttribute("style", "min-width:10vw;padding:2vh;font-family:Ubuntu;font-size: 14px;color: #ffffff;border: 1px solid #ffffff;background-color: #3fa6a1;text-transform: capitalize;margin-top:2vh !important");
        tr.appendChild(th);
      }

      for (var i = 0; i < this.data_rows.length; i++) {
        tr = table.insertRow(-1);
        for (var j = 0; j < this.data_rows[i].length; j++){
          var tabCell = tr.insertCell(-1);
          tabCell.innerHTML = this.data_rows[i][j]
          tabCell.setAttribute("style", "min-width:10vw;padding:2vh;font-family:Ubuntu;font-size: 14px;color: #333;border: 1px solid #D7DBDD;text-transform: capitalize;word-wrap:break-word;");
        }
      }                    
      return(table); 
    },

    // To get articles subtype wise
    get_articles_subtype(mytitle,page) {
      //console.log(mytitle,page,"mytitle,page")
      if (this.category_changed == true) {
        page = 1
      }
      this.user_approved = true

      setTimeout(e => {
        this.my_title = mytitle
        var all_articles = []
        var all_metadata = []
        var art_title = ''
        var url1 = ""

        if (this.my_title !== 'saved') {
          url1 = "/getAllL1L2Articles?token=" + this.auth_token + "&article_title="+ this.my_title + "&disease=" + this.disesase + "&page=" + page + "&user_approved=" + this.user_approved
        } else {
          url1 = "/getL1L2ArticlesFollowed?token=" + this.auth_token + "&article_title="+ this.my_title + "&disease=" + this.disesase + "&page=" + page
        }
        axios
          .get(url1)
          .then((res) => {
            if (res.data.Status != 1) {
              //console.log(res.data.Data,"data")
              this.loading = false
              all_articles = res.data.Data.articles
              all_metadata = res.data.Data.articles_metadata
              art_title = res.data.Data.article_title
              
              if (all_articles != null) {
                for (var i = 0; i < all_articles.length; i++) {
                  for (var j = 0; j < all_metadata.length; j++) {
                    if (all_articles[i].ID === all_metadata[j].Article_id) {
                      var my_date = new Date (all_articles[i].Created_at)
                      const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
                      const mon = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sept","Oct","Nov","Dec"];
                      var year = my_date.getFullYear();
                      var mes = my_date.getMonth();
                      var name = month[mes];
                      var mon_name = mon[mes]
                      var dia = my_date.getDate();
                      var fecha = dia+" "+name+" "+year;
                      var fecha_mon = dia+" "+mon_name+" "+year;
                      //console.log(all_articles[i].Article_number, all_articles[i].Disease,my_date,"dt")
                      var image2 = new Image();

                      image2.src = all_metadata[j].Tiles[0].Value.Image
                      
                      all_metadata[j].display_img = image2

                      var arti_titl = all_metadata[j].Tiles[0].Text

                      var manditory_var = all_metadata[j].Tiles[0].Value.Mandatory_Variables
                      var title_value

                      for (var s = 0; s < manditory_var.length; s++) {
                        var namee = manditory_var[s].Name
                        var typee = manditory_var[s].Type
                        var default_valuee = manditory_var[s].Default_Values
                        var match_string = '{{' + typee + '__'+ namee + '}}'
                        if (arti_titl.includes(match_string)) {
                          var pieces1 = arti_titl.split(match_string);
                          arti_titl = pieces1.join(default_valuee);
                          all_metadata[j].title_value = arti_titl
                        }
                      }
                      
                      all_metadata[j].date = fecha
                      all_metadata[j].date_sm = fecha_mon
                      all_metadata[j].selected = false
                      all_metadata[j].title_value = all_metadata[j].title_value
                      all_metadata[j].Title = all_articles[i].Title
                      all_metadata[j].Followed = false
                      all_metadata[j].Disease = all_articles[i].Disease
                      if (art_title == "Events") {
                        all_metadata[j].category = "Events"
                        this.my_articles.push(all_metadata[j])
                      } else if(art_title == "Reviews") {
                        all_metadata[j].category = "Reviews"
                        this.my_articles.push(all_metadata[j])
                      } else if(art_title == "Modules") {
                        all_metadata[j].category = "Modules"
                        this.my_articles.push(all_metadata[j])
                      } else if (art_title == "saved"){
                        all_metadata[j].category = "saved"
                        all_metadata[j].Followed = true
                        this.saved_articles.push(all_metadata[j])
                      } else {
                        this.my_articles = []
                        this.saved_articles = []
                      }
                    }
                  }
                }
                //console.log(this.my_articles,"my articles")
                //console.log(this.saved_articles,"saved articles")
                if (this.my_articles.length > 0) {
                  this.my_articles[0].selected = true
                  this.saved_details = false
                  this.my_display_date = this.my_articles[0].date
                  this.my_article_id = this.my_articles[0].Article_id
                  this.notes_title = this.my_articles[0].title_value
                  if (page == 1) {
                    this.get_article_link(this.my_articles[0])
                  }                 
                }
                
                if (this.saved_articles.length > 0) {
                  this.saved_articles[0].selected = true
                  this.saved_details = true
                  this.saved_display_date = this.saved_articles[0].date
                  this.saved_article_id = this.saved_articles[0].Article_id
                  this.saved_follow = this.saved_articles[0].Followed
                  this.notes_title = this.saved_articles[0].title_value
                  this.saved_message = ''
                  if (page == 1) {
                    this.get_article_link(this.saved_articles[0])
                  }
                } 
                this.category_changed = false
              } else {
                this.category_changed = false
                if (this.my_articles.length == 0) {
                  if (art_title != "saved") {
                    this.my_message = "Coming Soon !"
                  } else if (art_title == "saved") {
                    this.saved_message = "No saved article for this disease"
                  } else {
                    this.my_message = ''
                    this.saved_message = ''
                    this.notes_title = ''
                  }
                }
              }
              this.get_all_follows()
            } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
              console.log("Invalid User")()
              //this.$router.push("/").catch(() => {})
            } else {
              console.log("Articles not found")
              this.loading = false
              this.follow_page = 0
              this.category_changed = false
            }
        })
        .catch((error) => {
          this.loading = false
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);       
          }
        });
      //this.loading = false;
      }, 500);
    },
    show_my_articles(category_nm) {
      var mytitle = category_nm
      this.my_articles = []
      if (mytitle == "Events") {
        this.show_events = true
        this.show_reviews = false
        this.show_modules = false
        this.sub = "Events"
      } else if (mytitle == "Reviews") {
        this.show_events = false
        this.show_reviews = true
        this.show_modules = false
        this.sub = "Reviews"
      } else if (mytitle == "Modules"){
        this.show_events = false
        this.show_reviews = false
        this.show_modules = true
        this.sub = "Modules"
      } else {
        console.log("Title Mismatch")
      }
      this.show_followed = false
      this.loading = true
      this.my_page = 1
      this.my_message = ''
      this.title = mytitle
      this.sub = mytitle
      this.saved_articles = []
      this.category_changed = true
      if (this.auth_token !== null && this.auth_token !== "" && this.auth_token !== undefined) {
        this.get_articles_subtype(mytitle, this.my_page)
      } else {
        this.get_articles_subtype_no_auth(mytitle, this.my_page)
      }
    },

    // To create follow article
    followme(art) {
      if (!this.user_not_subscribed) {
        axios
          .post("/createfollow?token=" + this.auth_token + "&article_id="+ art.Article_id + "&disease=" + this.disesase)
          .then((res) => {
            if (res.data.Status != 1) {
              //console.log("success follow")
              art.Followed = true            
              this.my_follow = true
              for (var i = 0; i < this.my_articles.length; i++) {
                if (art.Article_id == this.my_articles[i].Article_id) {
                  this.my_articles[i].Followed = true
                }
              }
              
            } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
              console.log("Invalid User")()
              //this.$router.push("/").catch(() => {})
            } else {
              art.Followed = false
              console.log("failure follow")
            }
          })
          .catch((error) => {
            if (error.message == "Request failed with status code 500") {
              console.log(error.message);         
            }
          });
      } else {
        this.msg_modal_no_subscribe = true
      }
    },
    follow_me_right(artid,type) { 
      if (!this.user_not_subscribed) {    
        axios
          .post("/createfollow?token=" + this.auth_token + "&article_id="+ artid + "&disease=" + this.disesase)
          .then((res) => {
            if (res.data.Status != 1) {           
                this.my_follow = true
                for (var i = 0; i < this.my_articles.length; i++) {
                  if (artid == this.my_articles[i].Article_id) {
                    //console.log("followed")
                    this.my_articles[i].Followed = true
                  }
                }
            } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
              console.log("Invalid User")()
              //this.$router.push("/").catch(() => {})
            } else {
              console.log("failure follow")
            }
          })
          .catch((error) => {
            if (error.message == "Request failed with status code 500") {
              console.log(error.message);             
            }
          });
      } else {
        this.msg_modal_no_subscribe = true
      }
    },

    // To get follows
    get_all_follows () {
      axios
        .get("/getAllFollows?token=" + this.auth_token + "&disease=" + this.disesase) 
        .then((res) => {
          if (res.data.Status != 1) {
            this.follows = res.data.Data.follows
            if (this.follows !== null) {
              if (this.my_articles.length > 0) {
                for (var i = 0; i < this.my_articles.length; i++) {
                  for (var j = 0; j < this.follows.length; j++) {
                    if (this.my_articles[i].Article_id == this.follows[j].Article_id && this.follows[j].Followed == true) {
                      this.my_articles[i].Followed = true
                    }
                  }
                }
                this.my_follow = this.my_articles[0].Followed
              }
            }
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")()
            //this.$router.push("/").catch(() => {})
          } else {
            console.log("Follows not found")
          }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);            
        }
      });
    },
    show_notes (st1,st2,artid,notestitle) {
      if (!this.no_authentication) {
        if (!this.user_not_subscribed) {
          var panell = []
          this.notes_article_id = artid
          this.show_notes_modal = true;
          this.mynote = ""
          this.notes_msg = ""
          this.notes_panel.first_sec = st1
          this.notes_panel.second_sec = st2
          this.notes_panel.Header = notestitle
          //console.log(this.notes_panel,"this.notes_panel")
        } else {
          this.msg_modal_no_subscribe = true
        }
      }
    },
    
    savenote(notespanel) {
      //console.log(this.mynote,this.disesase,this.notes_article_id,notespanel,"this.note")
      var notespanel = JSON.stringify(notespanel)

      let senddata = {
        article_id : this.notes_article_id,
        disease : this.disesase,
        note: this.mynote,
        notes_panel: notespanel
      }

      let url = `/createnote?token=` + this.auth_token
      let config = { headers: { 'Content-Type': 'application/json' } }
      axios.post(url, JSON.stringify(senddata), config).then((res) => {

          if (res.data.Status != 1) {
            //console.log("note saved successfully")
            //this.show_all_notes()
            this.show_notes_modal = false
            this.notes_msg = "Your note have been saved successfully !"
          } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")()
            //this.$router.push("/").catch(() => { })
          } else {             
            console.log("could not save notes")
            this.notes_msg = "Could not save your note !"
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);         
          }
        });
    },

    close_notes_section() {
      this.my_notes_section = false
    },
    copyText() {
      navigator.clipboard.writeText(this.url);
    },
    show_share_modal(art_id) {
      if (!this.user_not_subscribed) {
        this.quote_text = ''
        this.send_msg = ''
        this.dialog = true
        this.shared_article_id = art_id
        this.myurl= this.domain_origin + "/#/Share/" + this.shared_article_id
        //this.myurl= "http://localhost:8080/#/Share/" + this.shared_article_id
      } else {
        this.msg_modal_no_subscribe = true
      }
    },
    close_share() {
      this.dialog = false
      this.network_items = false
    },
    share_article(social) {
      this.dialog = false
      var medium = "social media " + social
      var panel_shared = ""
      var duration = 0
      var panel_duration = 0
      axios
        .post("/SaveActivity?id=" + this.shared_article_id + '&token=' + this.auth_token + "&activity_name=" + medium + "&disease=" + this.disesase + "&panel_number=" + panel_shared + "&page_name" + this.article_title + "&panel_duration=" + panel_duration + "&duration=" + duration)
        .then((res) => {
          if (res.data.Status != 1) {
          //console.log("Activity saved successfully")
          this.network_items = false
        } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
          console.log("Invalid User")()
          //this.$router.push("/").catch(() => {})
        } else {
          console.log("Activity save failed")
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);        
        }
      });
    },
    validateQuote(e) {
      if (e.keyCode === 13) {
        this.quotetext = this.quote_text
        //this.send_msg = "Your message has been added. Please choose social media platform"
      } else {
        console.log("entering quote");
      }
    },
    // addQuote() {
    //   this.network_items = true
    //   this.quotetext = this.quote_text
    //   this.send_msg = "Your message has been added. Please choose social media platform"
    // },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    get_total_count_no_auth() {
      axios
        .get("/get_noauth_AllL1L2Count")
        .then((res) => {
          if (res.data.Status !== 1) {
            this.total_business = res.data.Data.total_business
            this.total_clinical = res.data.Data.total_clinical
            this.total_science =res.data.Data.total_science
            this.total_all = this.total_business + this.total_clinical + this.total_science
          } else {
            console.log("Counts not found")
          }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);           
        }
      });
    },
    get_articles_subtype_no_auth(mytitle,page) {
      if (this.category_changed == true) {
        page = 1
      }
      setTimeout(e => {
        this.my_title = mytitle
        //console.log(this.my_title,page,"title and page")
        var all_articles = []
        var all_metadata = []
        var art_title = ''

        axios
          .get("/get_noauth_AllL1L2Articles?article_title="+ this.my_title + "&page=" + page)
          .then((res) => {
            if (res.data.Status != 1) {
              this.loading = false
              all_articles = res.data.Data.articles
              all_metadata = res.data.Data.articles_metadata
              art_title = res.data.Data.article_title
              
              if (all_articles != null) {
                for (var i = 0; i < all_articles.length; i++) {
                  for (var j = 0; j < all_metadata.length; j++) {
                    if (all_articles[i].ID === all_metadata[j].Article_id) {
                      var my_date = new Date (all_articles[i].Created_at)
                      const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
                      const mon = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sept","Oct","Nov","Dec"];
                      var year = my_date.getFullYear();
                      var mes = my_date.getMonth();
                      var name = month[mes];
                      var mon_name = mon[mes]
                      var dia = my_date.getDate();
                      var fecha = dia+" "+name+" "+year;
                      var fecha_mon = dia+" "+mon_name+" "+year;

                      var image2 = new Image();

                      image2.src = all_metadata[j].Tiles[0].Value.Image
                      
                      all_metadata[j].display_img = image2

                      var arti_titl = all_metadata[j].Tiles[0].Text

                      var manditory_var = all_metadata[j].Tiles[0].Value.Mandatory_Variables
                      var title_value

                      for (var s = 0; s < manditory_var.length; s++) {
                        var namee = manditory_var[s].Name
                        var typee = manditory_var[s].Type
                        var default_valuee = manditory_var[s].Default_Values
                        var match_string = '{{' + typee + '__'+ namee + '}}'
                        if (arti_titl.includes(match_string)) {
                          var pieces1 = arti_titl.split(match_string);
                          arti_titl = pieces1.join(default_valuee);
                          all_metadata[j].title_value = arti_titl
                        }
                      }
                      
                      all_metadata[j].date = fecha
                      all_metadata[j].date_sm = fecha_mon
                      all_metadata[j].selected = false
                      all_metadata[j].title_value = all_metadata[j].title_value
                      all_metadata[j].Title = all_articles[i].Title
                      all_metadata[j].Followed = false
                      
                      if (art_title == "Events") {
                        all_metadata[j].category = "Events"
                        this.my_articles.push(all_metadata[j])
                      } else if(art_title == "Reviews") {
                        all_metadata[j].category = "Reviews"
                        this.my_articles.push(all_metadata[j])
                      } else if(art_title == "Modules") {
                        all_metadata[j].category = "Modules"
                        this.my_articles.push(all_metadata[j])
                      } else if (art_title == "saved"){
                        this.saved_articles = []
                      } else {
                        this.my_articles = []
                        this.saved_articles = []
                      }
                    }
                  }
                }
                //console.log(this.my_articles.length,"my articles")
                if (this.my_articles.length > 0) {
                  this.my_articles[0].selected = true
                  this.saved_details = false
                  this.my_display_date = this.my_articles[0].date
                  this.my_article_id = this.my_articles[0].Article_id
                  if (page == 1) {
                    this.get_article_link_no_auth(this.my_articles[0])
                  }                 
                }
                this.category_changed = false
              } else {
                this.category_changed = false
                if (this.my_articles.length == 0) {
                  if (art_title != "saved") {
                    this.my_message = "Coming Soon !"
                  } else if (art_title == "saved") {
                    this.saved_message = "No saved article for this disease"
                  } else {
                    this.my_message = ''
                    this.saved_message = ''
                  }
                }
              }              
            } else {
              //console.log("Articles not found")
              this.loading = false
              this.category_changed = false
            }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);       
          }
        });
      //this.loading = false;
      }, 200);
    },

    // To get link of json file from aws
    get_article_link_no_auth(art){
      //console.log(art,"articcleeeee")
      for (var i = 0 ; i < this.my_articles.length; i++) {
        if (art.ID == this.my_articles[i].ID) {
            art.selected = true
          } else {
            this.my_articles[i].selected = false
        }
      }           
      this.loading = true
      this.link = art.S3_key

      this.article_not_found = false
      
      // To get json link
      axios
        .get("/get_noauth_L1L2ArticleUrl?s3_link=" + this.link)
        .then((res) => {
          if (res.data.Status != 1) {
            fetch(res.data.Data.Url)
            .then(res => res.json())
            .then((out) => {
              var my_json = out
              this.article_not_found = false
              this.get_article_content(my_json, art.Title)
            })
            .catch(err => { 
              //this.article_not_found = true
              this.loading = false
             });
          } else {
            this.article_not_found = true
            console.log("Article link not found")
          }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);            
        }
      });
    },
    login() {
      this.msg_modal = true
    },
    goto_login() {
      this.$router.push("/Login").catch(() => {})
    },
    show_followed_articles(){
      this.saved_articles = []
      this.my_articles = []
      this.show_events = false
      this.show_reviews = false
      this.show_modules = false
      this.show_followed = true
      this.category_changed = true
      var mytitle = "saved"
      this.follow_page = 1
      if (this.auth_token !== null && this.auth_token !== "" && this.auth_token !== undefined) {
        this.get_articles_subtype(mytitle, this.follow_page)
      }
    },
    logout() {
      localStorage.removeItem("authtoken");
      localStorage.removeItem("selectedColorList");
      // localStorage.setItem("selected_article", "Industry Updates");
      this.disesase = "Multiple sclerosis"
      delete axios.defaults.headers.common["Authorization"];
      this.auth_token = null;        
      
      this.$router.push("/").catch(() => {})
    },
    show_table(table_data) {
      this.show_table_modal = true
      this.table_display = table_data
    },
    goto_user_pricing() {
      this.msg_modal_no_subscribe = false
      this.$router.push("/User_pricing").catch(() => {})
    },
    showque(c) {
      if (this.auth_token !== null && this.auth_token !== "" &&this.auth_token !== undefined) {
      console.log("showque")
      this.my_word = this.getSelectedText()
      // localStorage.setItem("my_word", this.my_word);
      let selectedText = this.my_word.toString();
      console.log(selectedText,"selectedText")
      var menu = document.querySelector(".menu1");
      if (selectedText !== "") {
        
      
        let sel = window.getSelection();
        let getRange      = sel.getRangeAt(0);
        let rect = getRange.getBoundingClientRect();
        menu.style.top=rect.top - 82 + 'px',
        menu.style.left=( rect.left + (rect.width * 0.5)-330) + 'px'
        menu.style.display = "block";
  
        menu.style.display = "block";
       
        console.log(menu.style.display)
        
      } else {
        menu.style.display = "none";
      }

      //document.getElementById("output").innerHTML = selectedText;

      if (selectedText !== "") {
        this.outputcpy = selectedText;
      } else {
        this.outputcpy = "";
      }
     
    
      console.log("showqueModal")
     
        
      this.$forceUpdate();
      }
    },
    showqueModal(){
      // this.queans_modal = true
      console.log(this.queans_modal)
      var menu = document.querySelector(".menu1");
      menu.style.display = "none";
      if(this.user_plan=='Basic Plan'){
        this.queans_modal = true
      }else{
        this.queans_modal = false
      }
      this.$refs.QnAModal.showquesModal(this.outputcpy,this.queans_modal);


    },
    getSelectedText() {
      let selection = document.getSelection();
      //console.log(selection,"selection")
      let selRange = selection.getRangeAt(0);
      //console.log(selRange,"selRange")
      return selRange;  
    },
    copyfieldvalue() {
      const storage = document.createElement('textarea');
      storage.value = this.$refs.outputcpy.innerHTML;
      this.copyTextToClipboard(storage.value)
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copying to clipboard was successful!');
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    },
    getUserPlan(){
      //create a get request to get the user plan
      axios.get("/getPlan?token=" +this.auth_token ).then((response) => {
        if(response.data.Status !=1)
        console.log(response.data,"response.data")
        this.user_plan = response.data.Data.Plan
        this.$forceUpdate();
      })
    },
    get_page_activity(){
    axios
            .get("/fetchIndicationSelected?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status != 1) {
                console.log(res.data,"response.data")
                this.disease = res.data.Data.Indication
                this.$forceUpdate();          
              } 
          })
  },

  }
};
</script>
<style>
  .breadcrumb-item a {
    color: red
  }
</style>
<style lang="scss" scoped>
  .margin_align {
     //margin: 4vh -1vh 0vh -4vh !important
     margin: 50px 0vw 0vh 0vh !important
  }
  .Line-242 {
    height: 1px;
    margin: 15px 0px 15px 0px;
    background-color: #cdcdd3;
  }

  .newall {
    padding: 0.5vh 0.5vw 0.5vh 0.5vw;
    border-radius: 6px;
    background-color: #00b2ba;
    height:30px;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    cursor: pointer
  }
  .all {
    padding: 1vh 1vh 0.5vh 1vh;
    height:30px;
    font-family: Ubuntu;
    font-size: 13px;
    font-weight: 700 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #525252 !important;
    cursor: pointer
  }
  .hr_all {
    height: 1.5px;
    width: 50% !important;
    margin-left: 0vh;
    background-color: #ff6750 !important;
  }
  .hr_business {
    height: 1.5px;
    width: 90% !important;
    margin-left: 1vh;
    background-color: #f66b67 !important;
  }
  .hr_clinical {
    height: 1.5px;
    width: 90% !important;
    margin-left: 1vh;
    background-color: #ffb345 !important;
  }
  .hr_tech {
    height: 1.5px;
    width: 90% !important;
    margin-left: 1vh;
    background-color: #44b3b8 !important;
  }
  .Rectangle-721 {
    margin-top:0px;
    background-color: #ffffff !important;
    top:0;   
  }
  .cards_section {
    max-height:100vh;
    overflow-y: auto;
    padding: 2vh;
    background-color: #f9f9fb !important;
  }
  .impact_crds {
    background-color:  #f9ffff;
    height: 100vh;
    overflow-y: auto
  }
  .SHOWING-DETAILS {
    margin: 1vh;
    font-family: Ubuntu;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #f66b67;
  }
  .SHOWING-IMPACTS {
    margin: 1vh;
    font-family: Ubuntu;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right !important;
    color: #4eaaae;
  } 
  .show_impacts {
    padding: 15.5px 0 15.5px 1.5px;
    border-radius: 7px;
    background-color: #44b3b8 !important;
    color: #ffffff !important;
    font-size:17px;
    font-weight: 700
  }
  .show_impacts1 {
    width: 95%;
    padding: 15.5px 0 15.5px 1.5px;
    border-radius: 7px;
    background-color: #44b3b8 !important;
    color: #ffffff !important
  }
  .details {
    margin: 2vh 1vh 1vh 1vh;
    opacity: 0.85;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #0d294a;
  }
  .Path-338 {
    width: 12.1px;
    height: 13.9px;
    margin: 2.1px 13.5px 33px 250.4px;
    background-color: #a2a2a2;
  }
  .date {
    padding: 0.8vh 0.5vw 0.8vh 0.5vw !important;
    font-family: Ubuntu;
    font-size: 13px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #8c8e91 !important;

  }
  .datee {
    margin: 0vh 1vh 0vh 0vh !important;
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #8c8e91 !important;

  }
  .image_123 {
    height: 10vh;
    margin: 0vh 1vh 0vh 0vh !important;
  }
  .company_alpha {
    margin: 1vh 0vh 0vh 1vh !important;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c48;
  }
  .company_alpha_selected {
    margin: 1vh 0vh 0vh 1vh !important;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c48;
  }
  .Rectangle-727 {
    width: 1px !important;
    height: inherit;
    margin: -3vh 0vh 0vh -3vh;
    background-color: #44b3b8;
  }
  .Rectangle-720 {
    margin-top:-2vh;
    height: 20vh;
    padding: 2vh;
    background-color: #f7f7f7;
  }
  .Rectangle-722 {
    height: 20vh;
    padding: 2vh;
    background-color: inherit !important;
  }
  .Rectangle-709 {
    width:100%;
    height: 5vh !important;
    padding: 1vh;
    background-color: #f7f7f7 !important;
  }
  .view_details {
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #44b3b8 !important;
    margin-left: 1vh;
    margin-top: 1vh;
    cursor: pointer
  }

  .view_share {
    font-family: Ubuntu;
    font-size: 18px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #44b3b8 !important;
    margin-left: 1vh;
    margin-top: 1vh;
  }

  .view_impact {
    font-family: Ubuntu;
    font-size: 18px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #44b3b8 !important;
    margin-left: 1vh;
    text-align: right !important
  }
  .view_details_btn {
    width:100% !important;
    margin: -1vh 0vh 0vh 1vh;
    padding: 1vh !important;
    background-color: #44b3b8 !important;
    color: #ffff;
    font-size: 17px;
    font-weight: 700
  }
  .breadcrums {
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    text-decoration: none;
    color: #009ea5 !important
  }
  .v-breadcrumbs__item {
    color: #009ea5 !important
  }
  .v-breadcrumbs__item v-breadcrumbs__item--disabled {
    color: #333333 !important
  }
  .solid_line {
    border:none;
    border-top:1px dashed #5a5a5a;
    color:#fff;
    background-color:#fff;
    height:1px;
    width:100%;
    margin-top:1.5vh;
    margin-left:-1.5vh;
  }
  .Rectangle-888 {
    padding: 1vh;
    border: solid 1px #dfe5e3;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff !important;
    cursor: pointer;
    border-radius : 7px;
    font-weight: 500
  }
  .Rectangle-903 {
    height: 42px;
    margin: -2vh 0vh 0vh -2vh;
    padding: 1vh 0vh 0.5vh 2vw!important;
    font-size: 18px;
    text-align: left;
    font-weight: 700;
    color: #ffffff;
    background-color: #00b2ba;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }
  .Path-469 {
    display:inline-block;
    border-top:4.5vh solid #00b2ba;
    border-right:4.5vh solid transparent;
    margin: -2.2vh 0vh 0vh -2vh !important
  }

  .second_items {
    font-size: 16px;
    font-weight: 700;
    color:#44b3b8;
    text-align:right
  }
  .notes_section {
    max-height: 100%;
    height: 98vh;
    overflow-y: auto;
    background-color: #f9f9f9;
    box-shadow: -5px 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
  .comments_list {
    padding:1vh 0.1vw 0vh 0.1vw;
    min-height: 65vh;
    max-height: 78vh;
    overflow-y: scroll;
    background: inherit;
  }
  .Rectangle-913 {
    margin: 1vh 0.2vw 0.5vh 0vw;
    padding: 0vh 0.8vw 1vh 0.9vw !important;
    border-radius: 18px;
    border: solid 1px #ccc;
    background-color: #fff;
  }
  .text_notes {
    font-size: 15px;
    font-weight: 500;
    color: #49575d;
    margin-bottom:1vh !important;
  }
  .date_notes {
    font-size: 13px;
    font-weight: 500;
    color: #8c8e91 !important;
  }
  .icon {
    font-size: 30px;
  }
  .sline {
    border: none;
    border-top: 2px dotted #bcbcbc;
    color: #fff;
    background-color: #fff;
    height: 1px;
    width: 95%;
  }
  .msg {
    text-align:center; 
    font-weight:500;
    font-size:13px;
    font-family:Ubuntu;
    margin-top: 4vh;
    color: #525252
  }
  .card_img {
    object-fit:fill;
    border-radius: 9px;
  }

.Path-359 {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #ff6750 !important;
  background-color: #fff;
  border-radius: 20px !important;
}



.Active_Companies_title {
  margin: 2vh 2vh 2vh 0vh;
  font-family: Ubuntu;
  font-size: 2.8vh;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: left;
  color: #ff6750;
}
.Path-85 {
  box-shadow: none !important;
  margin: 2vh;
  padding: 1vh 3vh 2vh 3vh;
  border: solid 0.6px #d3d3d3 !important;
  background-color: #fdfefe;
  height: 40vh;
  border-radius: 7px !important;
}
.com_nm {
  margin-top: 3vh;
  font-size: 2.2vh;
  font-weight: 700;
  font-family: Ubuntu;
  text-align: center;
  color: #383838 !important;
  height: 4vh;
}
.com_nm1 {
  margin-top: 2vh;
  font-size: 2.3vh;
  font-weight: 500;
  font-family: Ubuntu;
  text-align: center;
  color: #383838 !important;
}
.summary {
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}
.Rectangle-905 {
  padding: 2vh 0vh 2vh 2vh;
  border-radius: 16px;
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.22);
  border: solid 2px #222545;
  background-color: #f2fcfc;
  margin-top:10px;
  max-height:88vh;
}
.leftSidePanel {
  width:100%;
  overflow-y:auto;
  overflow-x: hidden;
  height:87vh;
  padding-bottom: 5vh;
  margin-left: 2.3vw;
}

.selectedCard {
  background-color: #c5edef !important;
  margin-top:10px;
  margin-left:0.1vw;
  max-width: 20vw !important
}
.unselectedCard {
  background-color: inherit;
  margin-bottom:-1.5vh;
  margin-left:0.1vw;
  max-width: 20vw !important
}

.noteBox {
  width: 16.8vw !important;
  max-width: 30vw;
  font-weight: 600;
  font-size: 14px;
  font-family: Ubuntu;
  color: #383838;
  position: absolute;
}
.notesTextField {
  position:absolute;
  float:bottom;
  bottom:70px;
  margin:0 10px 45px 0;
}
.know_more {
  font-family: Ubuntu;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: left;
  color: #32324a;
}

.Searching-For {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.89;
  letter-spacing: normal;
  text-align: left;
  color: #676772;
}

.Rectangle-1204 {
  padding: 0 2vh 0 2vh;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1b1b1;
  background-color: #f7f7f7;
}
.search_context {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}
::v-deep .chkbox_content .v-label{
  font-family: Ubuntu;
  font-size: 12px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}
.lorem {
  font-family: Ubuntu;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8c8e91;
  margin-left:4vh;
}
.ask_que_sec {
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.89;
  letter-spacing: normal;
  text-align: left;
  color: #202527;
  margin-left: 2vh
}

.Rectangle-1232 {
  margin: 2.5vh -5vh 0vh 0vh;
  padding: 1vh;
  background-color: #f7f7f7;
  height:402px;
}
.que-text {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: left;
  color: #9b9b9b;
}
.que_txt {
  font-family: Ubuntu;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 2.43;
  letter-spacing: normal;
  text-align: left;
  color: #676772;
  margin-left: 2vh
}

.Path-868 {
  margin: 0vh 2vh 0vh 1vh;
  padding: 1vh;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1b1b1;
  background-color: #fff;
}

.question {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #202527;
}

.circle {
  width: 25px;
  height: 25px;
  margin: 0vh;
  padding: 3px 5px 3px 8px;
  border-radius: 25px;
  border: solid 1px #eaeaea;
  background-color: #eaeaea;
}
.answer {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #202527;
}



.menu1 {
  display: none;
  position: absolute;
  
  border-radius: 6px;
  
  padding:1vh 2vh 1vh 2vh;
  cursor: pointer;
  z-index: 1;

}
.menu1 i {
  color: #03a6ad;
  cursor: pointer;
  margin: 8px;
}

#output {
  position: absolute;
  opacity: 0.01;
  height: 0;
  overflow: hidden;
}
  .popup {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  }
  .popup-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 10px 10px 0px 10px;
    border: 1px solid #888;
    width: 10%;
    display: flex;
    align-items: center;
  }
  .close-btn {
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    margin-left: auto;
    margin-top:-2vh;
  }
  .close-btn:hover,
  .close-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .popup-content p {
    font-size: 19px;
    font-weight: normal;
    text-align: center
  }
  .No_article{
    font-family: Ubuntu;
    font-size: 34px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #676772;
  }


</style>