<template>
  <v-main>
    <div> 
      <div  class="margin_align">
        <div class="Path-235" >   
          <v-row style="padding:5.5vh 20vh 0 20vh;">
              <!-- <v-col cols=12 md=auto xs=auto lg=auto sm=auto align=center style="padding-top:0">
                  <div style="margin:1vh 30vh 2vh 30vh">
                    <v-text-field
                            style="
                              width: 100vh;
                              min-width: 20vh;
                              font-weight: 300;
                              font-size: 14px;
                              font-family: Ubuntu;
                              color: #fff;
                              border-radius: 24px;
                            "
                        v-model="searchme"
                        class="mx-2 mb-3 shrink"
                        
                        placeholder="Search modules by name, category or functionality"
                        solo
                        >
                        <template v-slot:append>  
                            <img src="../assets/Icons/search.png" alt="user" style="height: 22px; width: 26px; margin-right:5px"/>
                        </template>
                    </v-text-field>
                  </div>
              </v-col> -->
          </v-row>
          <div class="Path-984" style="margin-top:7vh;position:relative;margin-bottom:6vh" v-if="!isTabletMob">
            <v-row  gutters wrap justify="center" align="center"> 
              <v-col cols=12 md=1 xs=1 lg=1 sm=1 >
                <img v-if="!no_auth" src="../assets/Icons/Group 1154.svg" alt="user" @click=" OpenKnolChat()"  style="height:9vh; width: 9vh;cursor:pointer;"   />
                <img v-else src="../assets/Icons/Group 1154.svg" alt="user" @click=" goto_pricing()"  style="cursor:pointer"   />
              </v-col>
              <v-col cols=12 md=8 xs=8 lg=8 sm=8 align="left">
                <v-row gutters wrap justify="center" align="center">
                  <v-col>
                    <span style="font-family: Ubuntu;
                    font-size: 4vh;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 0.57;
                    letter-spacing: normal;
                    text-align: left;
                    color: #fff;
                    
                   ">
                      KnolChat
                    </span>
                  </v-col>
                </v-row>
                <v-row no-gutters wrap justify="center" align="center" style="margin-top: 1vh;">
                  <v-col>
                    <span class="knolChatText" >
                      Ask for factual information and analyze trends  &amp;  statistics
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols=12 md=3 xs=3 lg=3 sm=3 align=center>
                <v-row justify=center>

                  <v-col cols=12 md=auto xs=auto lg=auto sm=auto>
                    
                    <v-btn v-if="!no_auth"
                    style="
                      background-color: #ffffff;
                      color: #009ea5;
                      font-size:16px;
                      height: 34px;
                      border-radius: 6px;
                      box-shadow: none;
                      width: auto;                     
                    "
                    :style="planData.Current_plan == 'Trial'?'margin-bottom:3vh;':'margin-bottom:5vh;'"
                    @click=" OpenKnolChat()"
                    >Explore KnolChat</v-btn
                  >
                  <v-row v-if="!no_auth" style="margin-top:-2vh">
                    <v-col style="padding:0">
                      <span class="knolChatText1" v-if="!planData.Trial_exceeded &&  planData.Current_plan == 'Trial'">
                        Free Trial {{planData.Trial_remaining_days }} Days Remaining 
                      </span>
                      <span class="knolChatText1" v-if="planData.Trial_exceeded && planData.Current_plan == 'Trial'">
                        Your Free Trial has ended.
                      </span>
                    </v-col>
                  </v-row>

                  <v-btn v-else
                  @click="gotoReachout()"
                  style="
                    background-color: #ffffff;
                    color: #009ea5;
                    font-size:16px;
                    height: 34px;
                    border-radius: 6px;
                    box-shadow: none;
                    width: auto;
                    margin-bottom:1vh;

                  ">
                  
                    View Demo</v-btn
                >
                <!-- create video icon here -->
                      
                  </v-col>
                  <v-col cols=12 md=auto xs=auto lg=auto sm=auto>
                  <!-- <v-menu  v-if="!no_auth"               
                  v-model="showKnolchatVideo"
                  :close-on-content-click="false"
                  :close-on-click="false"  
                  left
                  style="z-index: 999999;"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="{ ...on, click: () =>  accessknolchatS3Video('chatvideo.mp4') }"
                      icon
                     
                    >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          style="color:#ffff;font-size:4vh;cursor:pointer"
                        >
                          mdi-play-circle-outline
                        </v-icon>
                      </template>
                      <span>Tutorial Video</span>
                    </v-tooltip>
                    </v-btn>
                  </template>
                  <v-card v-if="showKnolchatVideo" >
                  <v-row wrap no-gutters style="background-color:black">
                    <v-col align=right>
                      <v-btn style="cursor:pointer" icon @click="showKnolchatVideo=false">
                        <v-icon style="color:#fff;">mdi-close</v-icon>
                      </v-btn>
                    </v-col>                          
                    </v-row>
                    <v-card-text style="padding:0; position: relative;">
                      <video id="#knol_chat_video" width="600" height="400" style="padding:1vh" controls autoplay @click.stop @contextmenu.prevent :controlsList="['nodownload']" >
                        Your browser does not support the video tag.
                      </video>
                      <div v-if="showLoading" style="position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      z-index: 9999;" class="loading-overlay">
                        <v-progress-circular indeterminate color="#ffffff"></v-progress-circular>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-menu>    -->
              
                </v-col>    
                </v-row>
                
              </v-col>
              
            </v-row>              
          </div>
          <div class="Path-984" style="margin-top: 10vh; position: relative; margin-bottom: 6vh; padding: 3vh; display: flex; flex-direction: column" v-if="isTabletMob">
            <div style="display: flex; align-items: flex-start;">
              <img v-if="!no_auth" src="../assets/Icons/Group 1154.svg" alt="user" @click="OpenKnolChat()" style="margin-top: -1vh; height: 5vh; width: 5vh; cursor: pointer;" />
              <img v-else src="../assets/Icons/Group 1154.svg" alt="user" @click="goto_pricing()" style="margin-top: -1vh; height: 6vh; width: 6vh; cursor: pointer;" />
          
              <span style="font-family: Ubuntu; font-size: 3vh; font-weight: 500; font-stretch: normal; font-style: normal; line-height: 0.57; letter-spacing: normal; text-align: center; color: #fff; margin-left: 1vh;">
                KnolChat
              </span>
            </div>
          
            <div style="display: flex; flex-direction: column; ">
              <div class="knolChatText" style="font-size: 2vh;padding-top:0px !important ">
                Ask for factual information and analyze trends & statistics
              </div>
            
              <div style="display: flex; justify-content: space-between; margin-top: 1vh; width: 100%;align-items:center">
                <div>
                  <v-row wrap no-gutters v-if="!no_auth" style="margin-right:2vh">
                    <v-col >
                      <span class="knolChatText1" style="font-size: 1.5vh;color:#013d48">
                        <span v-if="!planData.Trial_exceeded && planData.Current_plan == 'Trial'">
                          Free Trial {{ planData.Trial_remaining_days }} Days Remaining
                        </span>
                        <span v-if="planData.Trial_exceeded && planData.Current_plan == 'Trial'">
                          Your Free Trial has ended.
                        </span>
                      </span>
                    </v-col>
                  </v-row>
                </div>
              
                <div>
                  <v-btn v-if="!no_auth" style="width:auto; background-color: #ffffff; color: #009ea5; font-size: 2vh; height: 4vh; border-radius: 6px; box-shadow: none;" @click="OpenKnolChat()">Explore KnolChat</v-btn>
                  <v-btn v-else style="width:auto; background-color: #ffffff; color: #009ea5; font-size: 2vh; height: 4vh; border-radius: 6px; box-shadow: none;" @click="gotoReachout()">View Demo</v-btn>
                </div>
              </div>
              
            </div>
          </div>
          
          
        </div>
        <div>
          <div
            style="
              font-family: Ubuntu;  
              margin-right: -5px;
            "
          >
          </div>
          <div >
            <div v-if="!no_auth" style=" margin-top:6vh" >
              <MyModules></MyModules>
            </div>
           <div :class="!no_auth?'responsive-diagonal':'none'" :style="!no_auth?'margin-top:-130vh' :'margin-top:0'"></div>
            <div :style="!no_auth ? 'background-color:#e4f5f4;': 'background-color:#f9f9f9;padding-bottom: 12vh;'" >
              <div style="margin: 0vh 3vw 0px 3vw;"  :style="!no_auth ? 'padding-top:5vh;' : 'padding-top:8vh;'">
                  <v-row :style="!no_auth?'padding-top:75vh;margin-bottom:2vh !important':'padding-top:0vh;margin-bottom:2vh !important'" >
                    <v-col align=center>
                      <div v-if="no_auth" class="Top-Performing-Modules" :style="!isTabletMob ? 'margin-bottom:0vh;margin-top:2vh' : 'margin-top:18vh;margin-bottom:0vh'">
                        Overview and Landscape Modules
                      </div>
                      <div :style="!no_auth?'padding-bottom:0vh;':'padding-bottom:4vh;'" v-else class="Top-Performing-Modules"  >
                        Coming Soon
                      </div>
                    </v-col>
                  </v-row>
                  <loading :active.sync="loading" 
                    :can-cancel="true" 
                    :on-cancel="onCancel"
                    :is-full-page="fullPage"></loading>
                    
                    <v-row   v-if="filteredList.length>0 && !no_auth"   :style="!isTabletMob ? 'padding: 0vh 2vh 0px 2vh' : 'padding: 0vh 2vh 0px 2vh;margin-top:2vh'">
                      <v-col :cols="!isTabletMob ? '3' :'12'"  v-for="(i,kf) in filteredList" :key="kf">
                        <v-card 
                          class=""
                          style="padding: 25px; border-radius: 12px; "
                        >
                          <div align="center">
                            <img
                              :src="require(`@/assets/images/${i.Module.Image}`)"
                              basic
                              style="height: 200px; width: 350px"
                            />
                            <!-- <img src="" basic style="height:150px; width:250px;" /> -->
                          </div>
                          <div style="margin-top: 12px;height:6vh">
                            <span class="Industry-Updates">
                              {{ i.Module.Name }}
                            </span>
                          </div>
                          <v-row>
                            <span  
                            class="
                              Lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-sed-do-eiusmod-tempor-incididunt-ut-labore
                            "
                          >
                          {{ i.Module.Description }}
                          </span>
                          </v-row>
                        
                          <div class="Line-194"></div>
                          <v-row no-gutters wrap style="max-height:40vh;overflow-y:scroll;" v-if="!no_auth" >
                            <v-col cols="12" md="9" sm="9" lg="9" xs="9" align=left>
                              <span class="View-Details" style="cursor:auto;"><i> Coming Soon ...</i></span>
                            </v-col>
                            <v-col cols="12" md="3" sm="3" lg="3" xs="3" align=right >
                            </v-col>
                           
                          </v-row>
                          <!--  -->
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row v-if="filteredList.length>0 && no_auth"   :style="!isTabletMob ? 'padding: 0vh 2vh 0px 2vh' : 'padding: 0vh 2vh 0px 2vh;'">
                      <v-col :cols="!isTabletMob ? '3' :'12'"  v-for="(i,kf) in filteredList" :key="kf">
                        <v-card 
                          class=""
                          style="padding: 25px; border-radius: 12px; "
                        >
  
                          <div align="center">
                            <img
                              :src="require(`@/assets/images/${i.Module.Image}`)"
                              basic
                              style="height: 200px; width: 350px"
                            />
                            <!-- <img src="" basic style="height:150px; width:250px;" /> -->
                          </div>
                          <div style="margin-top: 12px;height:6vh">
                            <span class="Industry-Updates">
                              {{ i.Module.Name }}
                            </span>
                          </div>
                          <v-row  >
                            <span  
                            class="
                              Lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-sed-do-eiusmod-tempor-incididunt-ut-labore
                            "
                          >
                          {{ i.Module.Description }}
                          </span>
                          </v-row>
                        
                          <div class="Line-194"></div>
  
                          <v-row no-gutters>
                            <v-col cols="12" md="6" sm="6" lg="6" xs="6" align="left">
                              <div v-if="i.Module.Video!='' && i.Module.Video!=undefined && i.Module.Video!=null && !no_auth">
                              <!-- create a v-menu with button which opens video -->
                              <div :id="i.Module.ID" v-if="i.Module.Video!='' && i.Module.Video!=undefined && i.Module.Video!=null " style="position:relative">
                                <!-- create a v-menu with button which opens video -->
                                <v-menu offset-y>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      icon
                                      style="padding-bottom:5px"
                                    >
                                      <v-icon>mdi-play-circle-outline</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-card>
                                    <v-card-title>
                                      <span class="text-h5">Video</span>
                                    </v-card-title>
                                    <v-card-text>
                                      <v-responsive>
                                        <iframe
                                          width="100%"
                                          height="315"
                                          :src="i.Module.Video"
                                          frameborder="0"
                                          allowfullscreen
                                        ></iframe>
                                      </v-responsive>
                                    </v-card-text>
                                  </v-card>
                                </v-menu>
               
                                </div>
                              </div>
                          </v-col>
                            <v-col  cols="12" md="6" sm="6" lg="6" xs="6" align="right" >
                                <span v-show="i.Module.Name!='Insights Book'" style="color: #009ea5; cursor: pointer;font-size: 15px;"
                                      @click="showSample(i.Module.Sample)">View Sample
                                </span>
                            </v-col>
                          </v-row>                      
                          <v-row no-gutters wrap style="max-height:40vh;overflow-y:scroll;" v-if="!no_auth" >
                            <v-col cols="12" md="9" sm="9" lg="9" xs="9" align=left>
                              <span class="View-Details" style="cursor:auto;"><i> Coming Soon ...</i></span>
                            </v-col>
                            <v-col cols="12" md="3" sm="3" lg="3" xs="3" align=right >
                            </v-col>
                           
                          </v-row>
                          <!--  -->
                        </v-card>
                      </v-col>
                    </v-row>
                 
                  <!-- <div class="Rectangle-1044" >
                    <footer>
                    
                  </footer>
                  </div> -->
              </div>
              <div style="margin: 0px 3vw 0vh 3vw;"  :style="!no_auth ? 'padding-top:5vh;' : 'padding-top:0vh;'">
                <v-row>
                  <v-col align=center style="margin-bottom: 3vh;margin-top:2vh">
                    <span v-if="no_auth" class="Top-Performing-Modules">
                      Coming Soon
                    </span>
                  </v-col>                
                </v-row>
                <loading :active.sync="loading" 
                  :can-cancel="true" 
                  :on-cancel="onCancel"
                  :is-full-page="fullPage"></loading>
                  <v-row  v-if="comingSoonModules.length>0 && no_auth"  :style="!isTabletMob ? 'padding: 0vh 2vh 10vh 2vh' : 'padding: 0vh 2vh 6vh 2vh;'">
                    <v-col :cols="!isTabletMob ? '3' :'12'"  v-for="(i,kf) in comingSoonModules" :key="kf">
                      <v-card 
                        class=""
                        style="padding: 25px; border-radius: 12px; "
                      >

                        <div align="center">
                          <img
                            :src="require(`@/assets/images/${i.Image}`)"
                            basic
                            style="height: 200px; width: 350px"
                          />
                          <!-- <img src="" basic style="height:150px; width:250px;" /> -->
                        </div>
                        <div style="margin-top: 12px;">
                          <span class="Industry-Updates">
                            {{ i.Name }}
                          </span>
                        </div>
                        <v-row  >
                          <span
                          class="
                            Lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-sed-do-eiusmod-tempor-incididunt-ut-labore
                          "
                        >
                        {{ i.Description }}
                        </span>
                        </v-row>
                      
                        <div class="Line-194"></div>
                        <v-row>
                          <v-col align="right">
                            <span class="View-Details" style="cursor:auto;"><i> Coming Soon ...</i></span>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                <!-- <div class="Rectangle-1044" >
                  <footer ></footer>
                </div> -->
            </div>
            </div>
            <div  v-if="activeTab=='2'"  style="padding-top:10px;">
              <ProcessingModule></ProcessingModule>
            </div>
            <div  v-if="activeTab=='3'"  style="padding-top:10px;">
              <MyModules></MyModules>
            </div>
          </div>
          <v-dialog
          
            v-model="comingSoonModal"
            max-width="300"      
            >
            <v-card class="Path-359" style="padding:4vh;padding-left:2vh;border-radius:8px;" >
              <v-row>
                <v-col cols=12 xs="10" md="10" xl="10" sm="10" align="center" >
                  <span  style="font-family: Ubuntu;font-size:18px">
                  <i> {{ "Coming Soon !" }}</i>
                  </span>
                </v-col>
                <v-col cols=12 xs="2" md="2" xl="2" sm="2" align="right" >
    
                  <v-btn
                    icon
                    @click="comingSoonModal=false"
                    style="padding-bottom:5px"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>          
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
  </v-main>
</template>

<script>
import axios from "axios";
import ProcessingModule from "@/components/ProcessingModule.vue"
import MyModules from "@/components/My_Modules.vue"
import Loading from 'vue-loading-overlay';
import { EventBus } from "../event-bus.js";
import unorm from 'unorm';

export default {


  data() {
    return {
  
      Articles:[
       
      // {
      //     Image: "industry_updates.png",
      //     Name: "Industry Updates",
      //     Description:"Feed of clinical activity across therapy areas and companies - tracking activity status and trends."
      //   },
     
      {
        Image: "5.png",
        Name: "Clinical Review",
        Description:"This set of panels focus on our learnings from the clinical trials (including key study design parameters and evolution of endpoints) and guidance on how the drug may be used.",
        
      },
      {
        Image: "6.png",
        Name: "Therapeutics Report by TA",
        Description:"This set of panels will review the state of the disease, and the landscape of therapies available today, including the number of drugs, mechanisms of action, the stages they target, companies driving these drugs, and the financial landscape of therapeutics for this disease.",
      
      },
      {
        Image: "7.png",
        Name: "Clinical Trial Stats by TA",
        Description:"An overview of the statistics of trials in the Therapy Area of interest, their progress, success rates of trials, Mechanisms that have been tried in the past and new Mechanisms in trials.",
      
      },
      {
        Image: "3.png",
        Name: "Companies Engagement",
        Description:"An overview of the companies engaged in this Therapy Area, statistics of engagement, historical and current state of participation, by stage of engagement, types of companies, their clinical and market performance",
      
      }

      ],
      Images: ["M1.png", "M2.png", "M1.png"],
      allModules:false,
      activeTab:'1',
      selectedTab:false,
      modulesData:[],
      modules:[],
      searchme:"",
      auth_token: "",
      loading:true,
      fullPage: true,
      diseaseName:"",
      no_auth:false,
      comingSoonModal:false,
      payment_status:true,
      planData:{},
      containerId: 0,
      comingSoonModules:[],
      pageLoadStart:null,
      totalPageViewDuration: 0,
      blurTime:0,
      showLoading: false,
      showKnolchatVideo: false,
      
    };
  },
  beforeRouteLeave(to, from, next) {
    // Calculate page view duration
    const pageViewDuration = Date.now() - this.pageLoadStart;

    // Log the duration (you can customize this part based on your logging strategy)
    console.log(`Page view duration: ${pageViewDuration} milliseconds`);

    // You can also send this information to a server if needed
    this.sendDurationToServer(pageViewDuration);

    next();
  },
  beforeRouteEnter(to, from, next) {
    // Use a local variable to store the timestamp
    let pageLoadStart = Date.now();

    // Assign the local variable to the component's data property
    next(vm => {
      vm.pageLoadStart = pageLoadStart;
    });
  },

  components: {
 
 Loading,MyModules,ProcessingModule

},
  computed: {
    filteredList() { 
      return this.modulesData.filter(item => {
        return JSON.stringify(item).toLowerCase().indexOf(this.searchme.toLowerCase()) > -1
      })
    },
    isTabletMob : function() {
      const iPad = /iPad/i.test(navigator.userAgent);
      const iPhone = /iPhone/i.test(navigator.userAgent);
      const Android = /Android/i.test(navigator.userAgent);
      const webOS = /webOS/i.test(navigator.userAgent);
      const iPod = /iPod/i.test(navigator.userAgent);
      const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
      const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);
      return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
    }
  },
  // watch:{
  //     searchme:function(){
  //         EventBus.$emit("searchme",this.searchme );
  //         // this.$router.push("/")
  //         this.$forceUpdate();
  //   }
  // },
  created() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  mounted() {
    localStorage.removeItem("selected_article")
    localStorage.removeItem("plan")
    EventBus.$emit("onhome",true );
    EventBus.$emit("module_name","");
    localStorage.setItem("selected_module","")
    console.log("mounted")
    var auth_token = localStorage.getItem("authtoken");
    EventBus.$on("searchme", data => {    
          this.searchme = data   
    });


    
   
    if (auth_token !== null && auth_token !== "" && auth_token !== undefined && auth_token !== "undefined") {
      this.auth_token = auth_token
      
      this.get_page_activity()
      // localStorage.setItem("selected_article", "Modules_Library_authenticated");
      // localStorage.setItem("old_selected_article", "Modules_Library_authenticated");
      this.save_page_activity("Modules_Library_authenticated","Modules_Library_authenticated")
      //this.check_visit()
      this.get_user_profile(this.auth_token)
     
     
    //fire event to get all modules
    // EventBus.$emit("logincomplete",true );
      EventBus.$on("libray", () => {
        this.GetAllModules()
    });   
    document.addEventListener('visibilitychange', this.handleVisibilityChange);

  
   
    //check if website is opened on notificaiton click


    // console.log("analytical.....",this.$route.params.analytical)
    // if(this.$route.params.analytical!=undefined){
    //   if (this.$route.params.analytical == "true") {
    //     console.log("analytical")
    //     this.OpenKnolChat()
    //   }
    // }
   
      // console.log("auth_token", this.auth_token);
      if(this.$route.query.activeTab!=undefined){
        // console.log("jiiii",this.$route.query.activeTab)
        this.activeTab =this.$route.query.activeTab
      
        this.changeView('1')
       
         
          // var link = document.getElementById('mymodules');
          // if (link != null || link != "" || link != undefined){
          //   link.click();
          // }
        
       
      } else{
      
      this.activeTab = '1'
      }
      // console.log("activeTabbbbb",this.activeTab)
   
      this.GetAllModules()
    }else{
     // console.log("not authenticated")
      this.GetAllModules_no_auth()
    }
   
   
   
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  },
  methods: {
    accessknolchatS3Video(key) {
      this.showLoading = true; // Show loading overlay when video starts loading
      this.showKnolchatVideo = true; // Show video player
      axios.post('/getVideo?key='+key+'&token='+this.auth_token)
      .then(response => {
          var video = document.getElementById("#knol_chat_video");
          video.src=response.data.Data.Url;
          video.play();
          this.showLoading = false; // Hide loading overlay when video source is available
      })
      .catch(error => {
        console.error('Error accessing S3 video:', error);
        this.showLoading = false; // Hide loading overlay on error
      });
  },
    handleVisibilityChange() {
    if (document.hidden) {
      // console.log("hidden")
     this.blurTime=Date.now()
      //console.log(this.blurTime,"blurTime")
    } else  {
      // console.log("visible")
       this.totalPageViewDuration = this.totalPageViewDuration + (Date.now() - this.blurTime)
      //console.log(this.totalPageViewDuration,"totalPageViewDuration")
    }

     
  },
  OpenKnolChat(){
    var chat=true
   
        EventBus.$emit("chat",chat);
        // this.$router.push("/")
        this.$forceUpdate();
   },
   goToPlanPayment(){
    //route to user_plan_payment page
    this.$router.push("/User_pricing");
   },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("selectedColorList");
      this.$router.push("/").catch(() => {});
    },
    goto_reachOut(){
     
    },
  
    goToPayment(m){
     //console.log("m",m)
      // var old_selected = localStorage.getItem("selected_article");
      // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //     localStorage.setItem("old_selected_article", old_selected);
      //   } else {
      //     old_selected = "Industry Updates"
      //     localStorage.setItem("old_selected_article", old_selected);
      //   }
      // localStorage.setItem("selected_article", "Module_Payment");
      this.$router.push("/ModulePayment?module_id="+m.ID+"&priceId="+m.Price_id+"&price="+m.Price);
    },
    goToArticle(article){
      if (article=="Industry Updates") {
        // var old_selected = localStorage.getItem("selected_article");
        // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
        //   // localStorage.setItem("old_selected_article", old_selected);
        // } else {
        //   old_selected = "Industry Updates"
        //   localStorage.setItem("old_selected_article", old_selected);
        // }
      // localStorage.setItem("selected_article", article);
      //  console.log(article,"Industry Updates")
        this.$router.push("/articleList");
      }else if (article=="Clinical Review") {
        // var old_selected = localStorage.getItem("selected_article");
        // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
        //   localStorage.setItem("old_selected_article", old_selected);
        // } else {
        //   old_selected = "Industry Updates"
        //   localStorage.setItem("old_selected_article", old_selected);
        // }
        // localStorage.setItem("selected_article",article);
        // var disease= localStorage.getItem("selected_disease");
        // if (disease !== null && disease !== "" && disease!== undefined) {
        //   this.diseaseName=disease;
        // }
        var disease=this.disease
        this.$router.push("/articleDetails?article_title="+article+"&disease="+this.diseaseName);
      }else if (article=="Therapeutics Report by TA") {
        article="Therapy Landscape"
        // var old_selected = localStorage.getItem("selected_article");
        // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
        //   localStorage.setItem("old_selected_article", old_selected);
        // } else {
        //   old_selected = "Industry Updates"
        //   localStorage.setItem("old_selected_article", old_selected);
        // }
      // localStorage.setItem("selected_article", article);
      // var disease= localStorage.getItem("selected_disease");
      //   if (disease !== null && disease !== "" && disease!== undefined) {
      //     this.diseaseName=disease;
      //   }
      var disease=this.disease
        this.$router.push("/articleDetails?article_title="+article+"&disease="+this.diseaseName);
      }else if (article=="Companies Engagement") {
        article="Active Companies"
        // var old_selected = localStorage.getItem("selected_article");
        // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
        //   localStorage.setItem("old_selected_article", old_selected);
        // } else {
        //   old_selected = "Industry Updates"
        //   localStorage.setItem("old_selected_article", old_selected);
        // }
      // localStorage.setItem("selected_article", article);
      // var disease= localStorage.getItem("selected_disease");
      //   if (disease !== null && disease !== "" && disease!== undefined) {
      //     this.diseaseName=disease;
      //   }
      var disease=this.disease
        this.$router.push("/articleDetails?article_title="+article+"&disease="+this.diseaseName);
      }else if (article=="Clinical Trial Stats by TA") {
        article="Development Pipeline"
        // var old_selected = localStorage.getItem("selected_article");
        // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
        //   localStorage.setItem("old_selected_article", old_selected);
        // } else {
        //   old_selected = "Industry Updates"
        //   localStorage.setItem("old_selected_article", old_selected);
        // }
      // localStorage.setItem("selected_article", article);
      // var disease= localStorage.getItem("selected_disease");
      //   if (disease !== null && disease !== "" && disease!== undefined) {
      //     this.diseaseName=disease;
      //   }
      var disease=this.disease
        this.$router.push("/articleDetails?article_title="+article+"&disease="+this.diseaseName);
      }

      
    },
    gotoReachout(){
    //redirect to this  url https://reachout.knolens.app/Business_Development_Expert"
    window.open("https://reachout.knolens.app/Business_Development_Expert", "_blank");
  

    },
    
    AddToMyModules(module_id){
    
    //  console.log(module_id,"module_id")  
      axios
        .post("/addtoMyModules?id="+module_id+"&token="+this.auth_token)
        .then((res) => {
          if (res.data.Status != 1) {
            this.GetAllModules()
          //console.log("Activity saved successfully")
         
        
        this.changeView('3')
        var link = document.getElementById('mymodules');
        link.click();
       
        } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
          this.loading = false;
          console.log("invalid user")
        } else {
       //   console.log("Activity save failed")
        }
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
      });

    },
    goToMyModules(){
      // var old_selected = localStorage.getItem("selected_article");
      //   if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //     localStorage.setItem("old_selected_article", old_selected);
      //   } else {
      //     old_selected = "Industry Updates"
      //     localStorage.setItem("old_selected_article", old_selected);
      //   }
      // localStorage.setItem("selected_article", "My_Modules");
      this.$router.push("/")
       

    },
    goBack(){
      this.$router.push('/false') 
    },
    changeView(tab){
      // console.log(this.activeTab)
      this.activeTab=tab
      //  console.log(this.activeTab)
      this.selectedTab=true
    },
    GetAllModules(){
      this.modulesData=[]
      var modulesData_toSort=[]
    // console.log("GetAllModules")
      axios
      .get("/getallmodules?token="+this.auth_token)
      .then(response => {
        if(response.data.Status!=1){
        this.loading=false     
        for(let i=0;i<response.data.Data.allModules.length;i++){
          // console.log(response.data.Data.allModules,"/////////")
          let module_data={}
          var curr_article_date = response.data.Data.allModules[i].Ends_at
              const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
              var curr_d = new Date(curr_article_date),
                month = '' + (curr_d.getMonth() + 1),
                day = '' + curr_d.getDate(),
                year = curr_d.getFullYear();
                response.data.Data.allModules[i].Ends_at = [day, monthNames[curr_d.getMonth()], year].join(' ')
                // if(response.data.Data.allModules[i].Price_id!=''){
                //   this.allModules[i].Available=true
                // }

                module_data=response.data.Data.allModules[i]
                if (response.data.Data.allModules[i].Module.Price_id!=''){
                  module_data.Available=true
                }else{
                  console.log("falseee")
                  module_data.Available=false
                }

            modulesData_toSort.push(module_data)
          }
          

        //  console.log(this.modulesData)
        
          // for (let j=0;j<this.Articles.length;j++){
          //   let article_data={
          //     Module:{}
          //   }
          
          //   article_data.Module.Image=this.Articles[j].Image
          //   article_data.Module.IsArticle=true
          //   article_data.Module.Name=this.Articles[j].Name
          //   article_data.Module.Description=this.Articles[j].Description
          //   article_data.Subscribed=true
          //   article_data.Available=true
          //  modulesData_toSort.push(article_data)

            
          // }

          //console.log(modulesData_toSort,"modulesData.......")

          for(let i=0;i<modulesData_toSort.length;i++){
            if (modulesData_toSort[i].Module.Default==true){
              // console.log('helllloo')
              this.modulesData.push(modulesData_toSort[i])
            }
          }

          for(let i=0;i<modulesData_toSort.length;i++){
            if (modulesData_toSort[i].Module.Default!=true){
              this.modulesData.push(modulesData_toSort[i])
            }
          }        
          //console.log( this.modulesData)                  
        }else{
          this.loading=false
          console.log("error")
        }
        //  console.log(this.modulesData)       
      })
     
      .catch(error => {
        this.loading = false;
        console.log(error);
      });
     //use computed property to filter the data
     },

     GetAllModules_no_auth(){
      var allModules=[]
      this.no_auth=true
      axios
      .get("/getallmodulesnoauth")
      .then(response => {
        if (response.data.Status!=1){
        this.loading=false
      //   console.log(response.data.Data)
      //  console.log(response.data.Data.allModules)
      this.comingSoonModules=response.data.Data.comingModules
       for(let i=0;i<response.data.Data.allModules.length;i++){
           let data={
              Module:{}
            }
            data.Module=response.data.Data.allModules[i]
          
            allModules.push(data)
          }
        //  console.log(this.modulesData)
        
          // for (let j=0;j<this.Articles.length;j++){
          //   let article_data={
          //     Module:{}
          //   }
           
          //   article_data.Module.Image=this.Articles[j].Image
          //   article_data.Module.IsArticle=true
          //   article_data.Module.Name=this.Articles[j].Name
          //   article_data.Module.Description=this.Articles[j].Description
          //   article_data.Subscribed=false
          //   allModules.push(article_data)

            
          // }
          for(let i=0;i<allModules.length;i++){
            if (allModules[i].Module.Default==true){
              this.modulesData.push(allModules[i])
            }
          }
          for(let i=0;i<allModules.length;i++){
          // console.log(allModules[i].Module.Name,"iiiiiiii")
            if (allModules[i].Module.Default!=true &&  allModules[i].Module.Custom==true){
              // console.log('helllloo')
              this.modulesData.push(allModules[i])
            }
          }

          for(let i=0;i<allModules.length;i++){
          // console.log(allModules[i].Module.Name,"iiiiiiii")
            if (allModules[i].Module.Default!=true &&  allModules[i].Module.Custom!=true){
              // console.log('helllloo')
              this.modulesData.push(allModules[i])
            }
          }
         

         
          
          
        }else{
          this.loading=false
          console.log("error")
        }
      //  console.log(this.modulesData)
        
      })
     
      .catch(error => {
        console.log(error);
      });
     
    },
    
    showSample(Sample){
        // var old_selected = localStorage.getItem("selected_article");
        //   if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
        //     localStorage.setItem("old_selected_article", old_selected);
        //   } else {
        //     old_selected = "Industry Updates"
        //     localStorage.setItem("old_selected_article", old_selected);
        //   }
        // localStorage.setItem("selected_article", "Sample");
      this.$router.push("/Sample?sample="+Sample);
    },
    showSampleComingSoon(){
      // var old_selected = localStorage.getItem("selected_article");
      //     if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //       localStorage.setItem("old_selected_article", old_selected);
      //     } else {
      //       old_selected = "Industry Updates"
      //       localStorage.setItem("old_selected_article", old_selected);
      //     }
        // localStorage.setItem("selected_article", "Sample");
      this.$router.push("/Sample");
    },
    GetGeKnowMeModule(moduleId,moduleName){
      EventBus.$emit("module_name",moduleName);
      localStorage.setItem("selected_module",moduleName)
      let url = "/overviewbyIndication/"+moduleId+"/"+moduleName
      this.$router.push(url)
    },

    VisitModule(module){
      console.log("module",module.Name)
      EventBus.$emit("module_name",module.Name);
      localStorage.setItem("selected_module",module.Name)

      if (module.ID=="660bf3fcc1d87504a29913d5"){
        this.$router.push("/AnalyticalChat/"+module.ID);
      }
      if (module.Module_type != "Mechanisms"){ 
        this.$router.push("/customModule?moduleId="+module.ID)
      } else{
        this.$router.push("/mechanismModule?moduleId="+module.ID)
      }
     

    },
    comingSoon(){
     this.comingSoonModal=true
    
    },
    onCancel() {
      console.log('User cancelled the loader.')
    },
    goto_pricing() {
      this.$router.push("/Pricing").catch(() => {});
    },
    get_payment_status(token){
      // console.log("getttt payment status1111111")
     
      axios
        .get("/getPaymentStatus?token=" + token).then((res)=>{
          if (res.data.Status != 1) {
            this.payment_status = res.data.Data.PaidOnce
            // console.log(this.payment_status,"this.payment_status")
            // console.log(this.myplan,"this.myplan")
            // console.log(this.planData.Trial_exceeded,"this.planData.Trial_exceeded")
            if (this.payment_status == false){
              if (this.myplan == "Trial") {
                if (this.planData.Trial_exceeded == true || this.planData.Trial_remaining_days <= 0) {
                 // console.log("Trial plan expired")
                  this.$router.push("/").catch(() => { });
                  //this.$router.push('/User_pricing')
                } else {
                //  console.log("Trial plan active")
                }
              } else if (this.myplan == "Basic Plan") {
                //this.$router.push('/User_plan_payment/'+this.myplan)
                this.$router.push("/").catch(() => { });
              }
            }          
          } else {
            console.log("Payment status not found")
          }
        }).catch((error) => {
          if (error.message == "Request failed with status code 500") {
            this.logout()
            console.log(error.message);         
          }
        });
    },
    get_user_profile() {
      axios
        .get("/userProfile?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status !== 1) {
            this.user = res.data.Data;
            this.myplan = this.user.Plan
            axios.get("/getUserPlans?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status !== 1) {
                this.planData = res.data.Data
                // console.log(this.planData,"this.planData")
              } else {
                console.log("User plan not found")
              }
            })
            .catch((err) => {
              console.log(err);
            }); 
            
            setTimeout(() => {
              this.get_payment_status(this.auth_token)
            }, 500);          
          }
        })
        .catch((err) => {
          console.log(err);
        });   
    },
    check_visit() {
    //  console.log("visiitedddd")
      var auth_token = localStorage.getItem("authtoken");
      // console.log("authh_tokkennnnnn",auth_token)
      if (auth_token != null && auth_token != "" && auth_token != undefined) {

        // var firstvisit = localStorage.getItem("visit");
        // console.log("insideif")
        var activity_name="Activity User Registration"
        var firstvisit
        axios
        .get("/getactivity?activity_name=" + activity_name)
        .then((res) => {
        //  console.log(res,"ressssssssss activity")=
          if (res.data.Data.activities != null) {
              firstvisit="visited"
              // console.log("status",firstvisit)
          }else{
          

            firstvisit=""
          //  console.log("status not visited",firstvisit)
            if (firstvisit == null || firstvisit == "" || firstvisit == undefined || firstvisit == "undefined") {
              //console.log("Visiting first time")
              this.first_time = true
              // var visit_status = "Visited"
              
              axios
              .post("/SaveActivity?&token=" + auth_token + "&activity_name=" + activity_name)
              .then((res) => {
                if (res.data.Status != 1) {
                  console.log("Activity saved successfully")
                } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
                  this.$router.push("/").catch(() => { })
                } else {
                  console.log("Activity save failed")
                }
            })

              // localStorage.setItem("visit", visit_status);

            } else {

              //console.log("Visited multiple times")
            }
          }

        }).catch((err)=>{
              console.log(err)
        });
          // console.log("::",firstvisit)
       
      }
    },
    //
    save_page_activity(current,previous) {
    //console.log("page activity")
    var disease = ""
    var articleid = ""
    var duration = "0"
    var panel_duration = "0"
    var activity_name = "Page Change"
    var panel_number = "0"
    var pagename = current
    
    axios
      .post("/SaveActivity?id=" + articleid + '&token=' + this.auth_token + "&activity_name=" + activity_name + "&disease=" + disease + "&page_name=" + pagename + "&duration=" + duration + "&panel_duration=" + panel_duration + "&current=" + current + "&previous=" + previous)
      .then((res) => {
        if (res.data.Status != 1) {
          //console.log("Activity saved successfully")
        } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
          this.logout()
        } else {
          console.log("Activity save failed")
        }
    })
    .catch((error) => {
      console.log(error.message);
      if (error.message == "Request failed with status code 500") {
        this.logout()             
      }
    });
  },
  get_page_activity(){
    axios
            .get("/fetchIndicationSelected?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status != 1) {
            //    console.log(res.data,"response.data")
                this.diseaseName = res.data.Data.Indication
                this.$forceUpdate();          
              } 
          })
  },
  sendDurationToServer(duration) {
      duration=duration-this.totalPageViewDuration
      // Example using fetch API
      axios.post("/savePageActivity?page_name="+"Home Page"+"&token="+this.auth_token+"&input_id="+this.disease+"&duration="+duration).then(response => {
        if (response.data.Status != 1) {
          console.log("Activity saved successfully")
        } 
        
      }).catch((error) => {
        console.log(error.message);
        if (error.message == "Request failed with status code 500") {
          this.logout()             
        }
      });
    },
    show_video(module){
      console.log("videoooooo",module)
       for (let i=0;i<this.allModules.length;i++){
          if (this.allModules[i].Module.ID==module.ID){
            this.allModules[i].showVideo=true
            // var video= `data:video/mp4;base64,${res.data.Data.Video}`;
            // this.allModules[i].Video=video
          }else{
            this.allModules[i].showVideo=false
          }
        }
    },
 
   
    }
  
};
</script>
<style scoped>
.Component-6-5 {
  width: 328.5px;
  height: 41.4px;
  margin: 14.3px 0 0 7.9px;
}
.Component-7-1 {
  width: 324.4px;
  height: 33px;
  margin: 11.1px 4.1px 0 0;
}
.Subscription-valid-till {

  height: 14.8px;
 
  font-family: Ubuntu;
  font-size: 9px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.44;
  letter-spacing: normal;
  text-align: left;
  color: #676772;
}
.TOP-3-Productive-Module-for-Healthcare-Industry {
  
  margin: 28.4px 147px 25.8px 0;
  font-family: Ubuntu;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.Image-175 {
  width: 333px;
  height: 190px;
  margin: 0 3.4px 50.2px 0;
}
.Top-Performing-Modules {
  margin: 0px;
  font-family: Ubuntu;
  font-size: 3vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: center;
  color: #05777c;
}
.Rectangle-1043 {
  margin-bottom:30px;
  padding: 15.6px 0 40.6px 80px;
  opacity: 0.94;
  background-color: #595d6f;
}
.th-Jan-23 {
  font-family: Ubuntu;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: left;
  color: #676772;
}
.Path-704 {
 
  padding:20px;
  box-shadow: 0 4px 9px 0 rgba(36, 36, 36, 0.16);
  border: solid 1px #d5d5d5;
  border-radius: 8px;
  background-color: #fff;
}
.Path-714 {
  height: 45px;
  margin: 43.2px 296.4px 50.9px 13.4px;
  border: solid 1px #989898;
  text-align: center;
  background-color: #fff;
}
.Drugs-in-Development {
  margin: 173.3px 10px 0px 2px;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}
.Category-Name {
  padding-bottom: 20px;
  margin: 0 4px 10.3px 3px;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.71;
  letter-spacing: normal;
  text-align: left;
  color: #647272;
}
.Rectangle-1044 {
 margin: 0 0 0 0;
  opacity: 0.94;

}
.Processing-Modules {
  padding: 12px;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #989898;
}
.All-Modules {
  padding-left: 14px;
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
img.Group-736 {
  
  
  object-fit: contain;
}
.My-Modules {
  height: 24.8px;
  margin: 55.3px 289.7px 59px 13.4px;
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #989898;
}
.Path-705 {
  margin:0 10px 0 10px;
  text-align: center;
  height: 45px;
  padding: 12.1px 3.6px 8.1px 0;
  border: solid 1px #989898;
  border-radius: 24px;
  background-color: #fff;
}
.Path-705:hover{
  color:#fa6f5b
}
.Path-705:active{
  color:#fa6f5b
}
.Path-713 {
  height: 45px;
  margin-left: 20px;
  padding: 12.1px 3.6px 20.1px 0;
  border-radius: 24px;
  text-align: center;
  background-color: #fa6f5b;
}
.Sort-by-Relevance {
 
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #f16753;
}
.Lorem {
  width: 811px;
  height: 78.9px;
  margin: 25.8px 57px 27.4px 0;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.margin_align {
  overflow-x: hidden;
  overflow-y: auto;
  height: 102vh !important;

  background-color:#f9f9f9;
  
}

.My-Modules-1 {
  font-family: Ubuntu;
  font-size: 20px;
  padding-left: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
  margin-left: 3vh;
}
.Image-120 {
  width: 220px;
  height: 180.3px;
}
.Industry-Updates {
  width: 320.4px;
  height: 31.9px;
  font-family: Ubuntu;
  font-size: 2.5vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}
.-ACTIVE {
  height: 16.8px;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.71;
  letter-spacing: normal;
  text-align: left;
  color: #03a6ad;
}
.-ALERTS {
  height: 15px;

  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.71;
  letter-spacing: normal;
  text-align: left;
  color: #f66b67;
}
.-Processing {
  height: 12px;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.71;
  letter-spacing: normal;
  text-align: left;
  color: #868686;
}

.Lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-sed-do-eiusmod-tempor-incididunt-ut-labore {

  margin: 1vh 0.5vh 1vh 0;
  font-family: Ubuntu;
  font-size: 1.9vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #7a7a83;
}
.Line-194 {
  height: 1px;
  margin: 15.8px 3.5px 13.8px 0;
  background-color: #03a6ad;
}
.Line-202 {
  height: 1px;
  margin: 314.2px 12.5px 16px 16.5px;
  background-color: #fff;
}
.Visit {
  width: 73.3px;
  height: 14.8px;
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.Path-703 {
  width: 74.1px;
  height: 33px;
  margin: 0 0 0 217.6px;
  padding: 8.6px 0 9.6px 0.8px;
  background-color: #03a6ad;
}
.Component-5-6 {
  width: 317.4px;
  height: 33px;
  margin: 13.8px 11.6px 4.4px 3px;
}
.Group-848 {
  width: 377px;
  height: 529.6px;
}
.Ellipse-151 {
  width: 22px;
  height: 22px;
  border: solid 1px #1abbbc;
  border-radius: 24px;
  background-color: #f5f5f5;
}
.Group-846 {
  width: 22px;
  height: 22px;
  margin: 0 0 6.4px 27px;
  padding: 5px 5.4px 5.4px 5px;
}
.Path-715 {
  width: 25.7px;
  height: 26.3px;
}
.Component-5-3 {
  width: 317.4px;
  height: 33px;
  margin: 13.8px 11.6px 4.4px 3px;
}
.Span-1 {
  margin: 5px 5px 2.9px 14px;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
}
.For-30-Days {
  margin: 0 15.8px 0 14px;
  font-family: Ubuntu;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.44;
  letter-spacing: normal;
  text-align: left;
  color: #676772;
}
.View-Details {
  margin: 0.9px 0.3px 8px 0.5px;
  cursor: pointer;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: left;
  color: #03a6ad;
}
.View-Details1 {
  margin: 0.9px 0.3px 8px 0.5px;
  font-family: Ubuntu;
  font-size: 9px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: left;
  color: #03a6ad;
}
footer {
  width: 339.8px;
  height: 44.9px;
  font-family: Ubuntu;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.Path-235 {
  height: 140.8px;
  margin-bottom:10px;
  z-index:1;
  background-color: #013d48;
}
.Line-194 {
    border-top:2px dashed #ffff;
    height: 1px;
    margin: 15.8px 3.5px 13.8px 0;
    background-color: #03a6ad;
  }
  .vr{
    margin: 0 5px 0 5px;
  }
  .Path-359 {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ff6750 !important;
    background-color: #fff;
    border-radius: 20px !important;
  }
  .responsive-diagonal {
    width: 100vw;
    height: 50vh;
   
    background: linear-gradient(to right bottom, #f9f9f9 50%, #e4f5f4 50.3%);
    }
  
    .Path-984 {
      margin: 0 6vw 1vw 6vw;
      box-shadow: 0 4px 11px 0 rgba(36, 36, 36, 0.21);
      background-image: linear-gradient(to left, #1bbdab 25%, #14a292 65%);
      border-radius: 14px;
      padding:0 2vh 0 3vh;
      z-index: 1;    
    }
    .knolChatText {
      padding-top:20px;
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      font-family: Ubuntu;
      font-size: 2.5vh;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }

    .knolChatText1 {
    
      font-family: Ubuntu;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
    ::-webkit-scrollbar {
      width: 0;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
    }
    .v-application {
      /* height: 100vh !important; */
      height: -webkit-fill-available;
  }
  
    .v-application--wrap {
       /* min-height: 100vh !important; */
        min-height: -webkit-fill-available !important;
    }
</style>

