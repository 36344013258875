<template>
    <div style="padding:2vh 5vh;" :style="!isTabletMob ? 'height:90vh !important;overflow-y:hidden !important' : ''">
        <div>
            <div style="margin-left:2vh" v-if="moduleData.Logo != '' && moduleData.Logo != undefined && moduleData.Logo !=null">
                <img :src="require(`@/assets/images/${moduleData.Logo}`)" basic style="height: 5vh; width: 15vh" />
            </div>
            <div style="margin-left:3vh" v-if="moduleData.Name!='' && input_value!=''">
                <span :class="!isTabletMob ? 'Development-Pipeline' : 'Development-Pipeline_111'">
                    <span style="cursor:pointer" @click="goBack()">{{ moduleData.Name }}</span> > {{ input_value }}
                </span>
            </div>
        </div>
        <v-divider
            :style="!isTabletMob ? 'background-color:#7a7a83;margin-left:10px;margin-right:5px' : 'background-color:#7a7a83;margin-left:10px;margin-right:20px'"></v-divider>
        <div style="max-height:75vh;overflow-y:scroll;overflow-x:hidden;padding:2vh 3vh">
            <v-row>
                <v-col :cols="!isTabletMob ? '3' : '12'" v-masonry-tile v-for="(c, k) in categories" :key="k">
                    <v-card style="padding: 25px; border-radius: 12px; ">
                        <div style="display:flex;flex-direction:column;">
                            <div>
                                <img :src="require(`@/assets/images/${c.image}`)" basic
                                    style="height: 155px; width: 350px" />
                                <div style="margin-top: 12px;height:5vh">
                                    <span class="Industry-Updates">
                                        {{ c.name }}
                                    </span>
                                </div>
                                <v-row style="margin-top:10px">
                                    <span class="
                                    Lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-sed-do-eiusmod-tempor-incididunt-ut-labore
                                    " v-html="c.description">

                                    </span>
                                </v-row>
                                <div class="Line-194"></div>
                                <v-row>
                                    <v-col v-if="c.disable==false"  align="right" style="padding:0 2vh">
                                        <v-tooltip right>
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on">
                                                    <img src="../assets/Icons/Group 1156.svg" alt="user"
                                                        style="height:4vh; width: 4vh;cursor:pointer;"
                                                        @click="visitMechanismModule(c.name)" />
                                                </div>
                                            </template>
                                            <span>Open</span>
                                        </v-tooltip>

                                        <!-- <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh; width: 12px" @click="GetGeKnowMeModule(i.Module.ID,i.Module.Name)" >Open</v-btn> -->
                                    </v-col>
                                    <v-col v-if="c.disable"  align="right" style="padding:0 2vh">
                                        
                                        <img src="../assets/Icons/Group 1156 copy.svg" alt="user"
                                            style="height:4vh; width: 4vh;"
                                        />
                                   
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>  
                    <div>
                        <span class="Industry-Updates">
                          Influencers
                        </span>
                    </div>
                        <v-row>
                            <v-col>
                                <v-row  >
                                    <v-col :cols="!isTabletMob ? 'auto' :'12'" v-for="(s,k) in sources" :key="k">
                                        <img
                                            :src="require(`@/assets/images/${s}`)"
                                            basic
                                            style="height: 6vh; width: 15vh"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                </v-col>
            </v-row>

        </div>

    </div>
</template>
<script>

import axios from "axios";
import { EventBus } from '../event-bus';
export default {
    name: "SubModule",
    props: {
        input_id: {
            type: String,
            default: ""
        },
        input_value: {
            type: String,
            default: ""
        },
        module_id: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            categories: [
                {
                    image: "sub_1.png",
                    name: "Disease Overview",
                    description: "",
                    disable: false

                },
                {
                    image: "sub_2.png",
                    name: "Epidemiology",
                    description: '',
                    disable: false

                },
                {
                    image: "1.png",
                    name: "Clinical Burden",
                    description: "",
                    disable: false
                },
                {
                    image: "5.png",
                    name: "Economic Burden",
                    description: "",
                    disable: false
                },
                
                {
                    image: "sub_3.png",
                    name: "Current treatment guidelines",
                    description: '',
                    disable: false
                },
                {
                    image: "plot1.png",
                    name: "Treatment Landscape",
                    description: "",
                    disable: false
                },
                {
                    image: "3.png",
                    name: "Clinical Unmet Needs",
                    description: "",
                    disable: false
                },
                {
                    image: "4.png",
                    name: "HTA Assessments",
                    description: "",
                    disable: false
                },
                {
                    image: "sub_4.png",
                    name: "Pipeline activities",
                    description: '',
                    disable: false
                },
            ],
            sources: ["NORD.png","euroes.png","apfed.png" ],
            moduleData: {},
            auth_token: '',
            
        }
    },
    computed: {
        isTabletMob : function() {
            const iPad = /iPad/i.test(navigator.userAgent);
            const iPhone = /iPhone/i.test(navigator.userAgent);
            const Android = /Android/i.test(navigator.userAgent);
            const webOS = /webOS/i.test(navigator.userAgent);
            const iPod = /iPod/i.test(navigator.userAgent);
            const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
            const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);

            return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
        },
    },
    mounted() {
        //catch event
      
        var sub_m=localStorage.getItem("submodulepage");
        if(sub_m=="true"){
            // console.log("submodulepage",sub_m)
            this.input_value=localStorage.getItem("input_value")
            this.input_id=localStorage.getItem("input_id")
            
            localStorage.removeItem("submodulepage")
            localStorage.removeItem("input_value")
            localStorage.removeItem("input_id")
        }
    
        // console.log("submodule mounted")
        // console.log("module_id", this.$route.query.moduleId)
        var auth_token = localStorage.getItem("authtoken");
        if (auth_token !== null && auth_token !== undefined && auth_token !== "") {
            this.auth_token = auth_token;
            localStorage.setItem("submodule", true);
            localStorage.setItem("module_id", this.$route.query.moduleId);
            
            
            if (this.input_value != "Thrombosis") {
                this.categories = []
                var temp = [
                    {
                        image: "iso_do.png",
                        name: "Disease Overview",
                        description: "",
                        disable: false

                    },
                    {
                        image: "iso_epi.png",
                        name: "Epidemiology",
                        description: '',
                        disable: false

                    },
                    {
                        image: "1.png",
                        name: "Clinical Burden",
                        description: "",
                        disable: false
                    },
                    {
                        image: "5.png",
                        name: "Economic Burden",
                        description: "",
                        disable: false
                    },
                   
                    {
                        image: "sub_3.png",
                        name: "Current treatment guidelines",
                        description: '',
                        disable: false
                    },
                    {
                        image: "plot1.png",
                        name: "Treatment Landscape",
                        description: "",
                        disable: false
                    },
                    {
                        image: "3.png",
                        name: "Clinical Unmet Needs",
                        description: "",
                        disable: false
                    },
                    {
                        image: "4.png",
                        name: "HTA Assessments",
                        description: "",
                        disable: false
                    },
                    {
                        image: "sub_4.png",
                        name: "Pipeline activities",
                        description: '',
                        disable: false
                    },
                ]
                this.categories = temp



            }
            this.getModuleType()
        }
    },
    methods: {
        visitMechanismModule(category) {
            this.$router.push("/mechModule?input_id=" + this.input_id + "&input_value=" + this.input_value + "&module_id=" + this.$route.query.moduleId + "&category="+category);
        },
        getModuleType() {
            //create get request to get module type
            axios.get("/getModuleType?module_id=" + this.$route.query.moduleId + "&token=" + this.auth_token).then(res => {
                // console.log("response",res)
                if (res.data.Status != 1) {
                    this.moduleData = res.data.Data.Module
                    // console.log("moduleData", this.moduleData)
                }
            })

        },
        goBack(){
            EventBus.$emit("submodule",false);
        }
    },

}
</script>
<style scoped>
.Development-Pipeline {

    font-family: Ubuntu;
    font-size: 2.6vh;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #32324a;
}

.Development-Pipeline_111 {

    font-family: Ubuntu;
    font-size: 2.6vh;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left !important;
    color: #32324a;
}

.Path-704 {
    padding: 10px 20px 10px 20px;
    box-shadow: 0px 0px 10px 0px rgba(36, 36, 36, 0.16);
    border: solid 1px #d9d7d7;
    background-color: #fff;
    border-radius: 15px;
}

.open_btn {
    width: 5vw;
    margin-top: -10px;
    background: inherit !important;
    border-radius: 5px;
    border: 1px solid #009ea5;
    color: #009ea5;
    font-size: 1.7vh;
    float: right !important;
    box-shadow: none;
    height: 4vh;
}

.Industry-Updates {
    width: 320.4px;
    height: 31.9px;
    font-family: Ubuntu;
    font-size: 2.5vh;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c48;
}

.Lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-sed-do-eiusmod-tempor-incididunt-ut-labore {
    margin: 1vh 0.5vh 1vh 0;
    font-family: Ubuntu;
    font-size: 1.9vh;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #7a7a83;
}

.Line-194 {
    border-top: 2px dashed #ffff;
    height: 1px;
    margin: 15.8px 3.5px 13.8px 0;
    background-color: #03a6ad;
}

.margin_align {
    font-family: Ubuntu;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh !important;
}

.Development-Pipeline {

    font-family: Ubuntu;
    font-size: 2.6vh;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #32324a;
}

.Development-Pipeline_111 {
    padding: 0px 0px 0px 30px;
    font-family: Ubuntu;
    font-size: 2.6vh;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left !important;
    color: #32324a;
}
</style>