<template>
  <v-row>
    <loading
      :active.sync="loading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <v-app-bar
      elevation="0"
      :class="!isTabletMob ? 'custom-app-bar' : ''"
      dense
      dark
      fixed
      v-if="hideAppbar == false"
      style="position: fixed; z-index: 99999"
      :style="!isOverview ? '' : 'height: 11vh !important;'"
    >
      <v-row
        gutters
        align="center"
        justify="center"
        v-if="isTabletMob"
        style="margin-top: 1vh"
      >
        <v-col
          cols="12"
          sm="12"
          md="12"
          xl="12"
          xs="12"
          lg="12"
          :style="!isOverview ? '' : 'margin-top:2.5vh'"
        >
          <span
            class="pull-right"
            @click="drawer_show()"
            :style="!verified ? 'float:left;margin-right:2vh' : 'float:right;'"
            style="cursor: pointer"
            ><i
              class="fa fa-bars"
              style="
                height: 3vh;
                width: 3vh;
                color: #ffffff;
                margin-top: 0vh !important;
              "
            ></i
          ></span>
          <img
            src="../assets/Icons/Group 732.svg"
            style="width: 2vh; margin-left: 0vh; cursor: pointer"
            @click="goBack()"
            v-if="!isModuleLibrary && verified && !private_module"
          />

          <img
            src="../assets/Icons/home.svg"
            style="
              width: 4vh;
              height: 2vh;
              cursor: pointer;
              margin-left: 3vh;
              margin-bottom: 1vh;
            "
            v-if="!isModuleLibrary && private_module && verified"
            @click="goToHome()"
          />
          <img
            src="../assets/Icons/Knolense_Dark-01.svg"
            style="height: 3vh; cursor: pointer; margin-left: 5vw"
            @click="goToHome()"
          />

          <span
            v-show="show_login && !verified && isModuleLibrary"
            class="Path-68717 pull-right"
            @click="goto_login()"
            style="margin-top: 0vh; margin-right: 1vh"
            >Login</span
          >
          <!-- <span class="Path-68717 pull-right" @click="gopricingpage()"  v-if="((myplan == 'Trial') && pricing_page_current == false && verified) || user_plan==false && verified" style="margin-top:-0.5vh;margin-right:1vh">Buy</span>  -->
          <br />
          <div
            class="d-flex"
            style="margin-top: 1vh; float: left"
            v-if="!no_authentication && show_diseases && expected_pages"
          >
            <v-menu
              v-if="drawer != true"
              offset-y
              :close-on-content-click="closeOnContentClick"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <span class="active_com1111">
                    {{ title }}
                  </span>
                  <img
                    v-show="show_diseases"
                    src="../assets/Icons/Group 635.svg"
                    style="height: 1vh; margin-left: 5px"
                  />
                </div>
              </template>
              <v-row>
                <v-text-field
                  type="text"
                  v-model="searchme"
                  label="Search"
                  style="
                    background-color: white;
                    font-size: 13px !important;
                    font-family: Ubuntu;
                    font-weight: 500;
                    margin-left: 1vh;
                    margin-right: 1vh;
                  "
                  @click="stayopen()"
                ></v-text-field>
              </v-row>

              <v-list style="max-height: 30vh; overflow-y: scroll">
                <!-- <v-list-subheader class="v-list-subheader" v-if=" display_diseases_my.length>0" >Purchased</v-list-subheader> -->
                <v-list-item
                  v-for="(my, key) in display_diseases_my"
                  :key="key"
                  item-value="my"
                  v-model="disease_name"
                  class="dropdownStyle"
                  @click="get_disease_name(my, 'purchased')"
                >
                  <v-list-item-title
                    style="text-transform: capitalize; font-size: 13px"
                    >{{ my }}</v-list-item-title
                  >
                </v-list-item>
                <v-divider
                  v-if="
                    display_diseases_my.length > 0 &&
                    display_diseases_other.length > 0
                  "
                ></v-divider>
                <v-list-subheader
                  class="v-list-subheader"
                  v-if="display_diseases_other.length > 0"
                  >Indications</v-list-subheader
                >
                <v-list-item
                  v-for="(other, key1) in display_diseases_other"
                  :key="key1"
                  item-value="other"
                  v-model="disease_name"
                  class="dropdownStyle"
                  @click="get_disease_name(other, 'nott')"
                >
                  <v-list-item-title
                    style="text-transform: capitalize; font-size: 13px"
                    >{{ other }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        v-if="!isTabletMob"
        style="margin-top: 2vh !important"
      >
        <v-col cols="12" sm="2" md="2" xl="2" xs="2" lg="2">
          <img
            src="../assets/Icons/Group 732.svg"
            style="width: 1.5vw; margin-left: 1vh; cursor: pointer"
            @click="goBack()"
            v-if="!isModuleLibrary && !private_module"
          />
          <img
            src="../assets/Icons/home.svg"
            style="
              width: 4vh;
              height: 3vh;
              cursor: pointer;
              margin-left: 0vh;
              margin-bottom: 1vh;
            "
            v-if="!isModuleLibrary && private_module"
            @click="goToHome()"
          />
          <img
            src="../assets/Icons/Knolense_Dark-01.svg"
            style="width: 7vw; height: 5vh; cursor: pointer"
            :style="!private_module ? 'margin-left:3vw' : 'margin-left:1.6vw'"
            @click="goToHome()"
          />
        </v-col>
        <v-col cols="12" sm="3" md="3" xl="3" xs="3" lg="3">
          <v-row
            v-show="show_diseases"
            wrap
            align="center"
            justify="center"
            class="mb-1"
          >
            <div
              class="d-flex"
              style="margin-left: 1vw"
              v-if="!no_authentication && expected_pages"
            >
              <v-menu offset-y :close-on-content-click="closeOnContentClick">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <span class="active_com">
                      {{ title }}
                    </span>
                    <img
                      v-show="show_diseases"
                      src="../assets/Icons/Group 635.svg"
                      style="height: 1vh; margin-left: 5px"
                    />
                  </div>
                </template>
                <v-row>
                  <v-text-field
                    type="text"
                    v-model="searchme"
                    label="Search"
                    style="
                      background-color: white;
                      font-size: 13px !important;
                      font-family: Ubuntu;
                      font-weight: 500;
                      margin-left: 1vh;
                      margin-right: 1vh;
                    "
                    @click="stayopen()"
                  ></v-text-field>
                </v-row>

                <v-list style="max-height: 30vh; overflow-y: scroll">
                  <!-- <v-list-subheader class="v-list-subheader" v-if=" display_diseases_my.length>0" >Purchased</v-list-subheader> -->
                  <v-list-item
                    v-for="(my, key) in display_diseases_my"
                    :key="key"
                    item-value="my"
                    v-model="disease_name"
                    class="dropdownStyle"
                    @click="get_disease_name(my, 'purchased')"
                  >
                    <v-list-item-title
                      style="text-transform: capitalize; font-size: 13px"
                      >{{ my }}</v-list-item-title
                    >
                  </v-list-item>
                  <v-divider
                    v-if="
                      display_diseases_my.length > 0 &&
                      display_diseases_other.length > 0
                    "
                  ></v-divider>
                  <v-list-subheader
                    class="v-list-subheader"
                    v-if="display_diseases_other.length > 0"
                    >Indications</v-list-subheader
                  >
                  <v-list-item
                    v-for="(other, key1) in display_diseases_other"
                    :key="key1"
                    item-value="other"
                    v-model="disease_name"
                    class="dropdownStyle"
                    @click="get_disease_name(other, 'nottt')"
                  >
                    <v-list-item-title
                      style="text-transform: capitalize; font-size: 13px"
                      >{{ other }}</v-list-item-title
                    >
                  </v-list-item>
                  <!-- <v-btn
                    style="background-color: #009ea5; color: #ffffff;font-size: 13px;margin:1vh 0vh 1vh 1vh;width:90%"
                    @click="open_diseasesmodal()" v-if="!no_add_diseases">+ Add new Diseases</v-btn> -->
                </v-list>
              </v-menu>
            </div>
            <div
              class="d-flex"
              style="margin-left: 1vw"
              v-if="no_authentication"
              @click="msg_modal = true"
            >
              <span v-show="isArticle" class="active_com">
                {{ title }}
              </span>
              <img
                v-show="isArticle"
                src="../assets/Icons/Group 635.svg"
                style="
                  height: 1vh;
                  margin-left: 8px;
                  margin-top: 1vh;
                  cursor: pointer;
                "
              />
            </div>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          sm="2"
          md="2"
          xl="2"
          xs="2"
          lg="2"
          v-if="verified && isModuleLibrary"
        >
          <div v-show="show_search" pull-left style="margin-left: -4vw">
            <!-- <v-text-field type="text" v-model="searchme_module"  placeholder="Search modules by name"
              class="centered-input text--darken-3  mx-2 mb-3 shrink " 
              style="font-size:13px !important;font-color:#ffff;font-family: Ubuntu;font-weight: 500; border-radius: 28px;margin-top:4vh;"
            ></v-text-field> -->
            <!-- <v-text-field
            
            style="
              width: 100vh;
              min-width: 20vh;
              font-weight: 300;
              font-size: 14px;
              font-family: Ubuntu;
              color: #ffff important!;
              background-color:#ffffff;
              margin-top:6vh;
              border-radius: 24px;
             
            "
        v-model="searchme_module"
        class="centered-input text--darken-3  mx-2 mb-3 shrink " 
        placeholder="Search modules by name"
        solo
        >
        <template v-slot:append>  
            <img src="../assets/Icons/search.png" alt="user" style="height: 22px; width: 26px; margin-right:5px"/>
        </template>
    </v-text-field> -->
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="2"
          md="2"
          xl="2"
          xs="2"
          lg="2"
          v-if="verified && !isModuleLibrary"
        ></v-col>
        <v-col
          cols="12"
          sm="2"
          md="2"
          xl="2"
          xs="2"
          lg="2"
          v-if="!verified && isModuleLibrary"
        ></v-col>
        <v-col
          cols="12"
          sm="3"
          md="3"
          xl="3"
          xs="3"
          lg="3"
          v-if="!verified && isModuleLibrary"
        >
          <div
            v-show="show_search"
            pull-left
            style="margin-left: -18vw; margin-right: 18vw"
          >
            <!-- <v-text-field type="text" v-model="searchme_module"  placeholder="Search modules by name"
            class="centered-input text--darken-3  mx-2 mb-3 shrink " 
            style="font-size:13px !important;font-color:#ffff;font-family: Ubuntu;font-weight: 500;margin-left:2vh;margin-right:1vh; border-radius: 28px;margin-top:4vh;"
          ></v-text-field> -->
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="2"
          md="2"
          xl="2"
          xs="2"
          lg="2"
          v-if="!verified && !isModuleLibrary"
        ></v-col>
        <v-col
          cols="12"
          sm="4"
          md="4"
          xl="4"
          xs="4"
          lg="4"
          v-if="!verified && !isModuleLibrary"
        >
          <div v-show="isArticle" class="Path-687">
            <span v-show="isArticle" style="font-size: 13px"
              >This content is out of date. To see updated content, please</span
            >
            <span
              v-show="isArticle"
              class="pull-right"
              style="
                margin-left: 1vh;
                font-size: 13px;
                font-weight: 700 !important;
                cursor: pointer;
              "
              @click="show_pricing()"
              >Register</span
            >
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="1"
          md="1"
          xl="1"
          xs="1"
          lg="1"
          v-if="!verified && isModuleLibrary"
        >
          <div v-show="show_login" class="Path-6871 pull-right">
            <span
              v-show="show_login"
              class="pull-right"
              style="
                margin-left: 2vh;
                margin-right: 2vh;
                font-size: 15px;
                font-weight: 700 !important;
                cursor: pointer;
              "
              @click="goto_login()"
              >Login</span
            >
          </div>
        </v-col>
        <!-- Trial Period Active Info-->
        <!-- v-if="verified && !planData.Trial_exceeded && planData.Current_plan == 'Trial' " -->
        <!-- <v-col cols="12" sm="3" md="3" xl="3" xs="3" lg="3" v-if="verified && !planData.Trial_exceeded && planData.Current_plan == 'Trial'  " >
          <div class="Path-687 ">
            <img src="../assets/Icons/Group 806.svg">
            <span style="font-size:13px">Free Trial Period Remaining Days - {{planData.Trial_remaining_days }}</span> 
           </div>          
        </v-col>
        -->
        <!---->
        <!-- Trial Period Ended Info-->
        <!-- v-if="verified && planData.Trial_exceeded && planData.Current_plan == 'Trial'" -->
        <!-- <v-col cols="12" sm="3" md="3" xl="3" xs="3" lg="3" align="right" v-if="verified && planData.Trial_exceeded && planData.Current_plan == 'Trial'"  >
          <div class="Path-687 ">
            <img src="../assets/Icons/Group 806.svg" >
            <span style="font-size:13px">Your Free Trial has ended.</span> 
           </div>          
        </v-col> -->

        <v-col
          cols="12"
          sm="3"
          md="3"
          xl="3"
          xs="3"
          lg="3"
          style="margin-bottom: 0.5vh"
          align="right"
          v-if="verified"
        >
          <span
            :style="
              user_plan == false || myplan == 'Trial'
                ? 'margin-right:-4vh'
                : 'margin-right:-12vh'
            "
            >{{ module_name }}
          </span>
        </v-col>

        <v-col cols="12" sm="1" md="1" xl="1" xs="1" lg="1" v-if="!verified">
          <span
            v-show="show_login"
            v-if="isModuleLibrary"
            :class="pricing_selected ? 'selected_pricing' : 'pricing'"
            @click="show_pricing()"
          >
            Pricing
          </span>
          <span
            v-show="isArticle"
            v-else
            :class="pricing_selected ? 'selected_pricing' : 'pricing'"
            @click="goto_login()"
          >
            Login
          </span>
        </v-col>
        <v-col cols="12" sm="2" md="2" xl="2" xs="2" v-if="verified">
          <div
            v-show="show_settings"
            class="flex-container"
            :style="
              myplan == 'Trial' && pricing_page_current == false
                ? 'margin-left:8vh'
                : ''
            "
          >
            <div v-if="user_plan == false || myplan == 'Trial'">
              <span
                @click="gopricingpage()"
                class="selected_pricing"
                style="margin-left: 0vh"
                >Buy</span
              >
            </div>
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <div
                      class="user_profile"
                      @click="openSupport()"
                      :style="
                        user_plan && myplan != 'Trial' ? 'margin-left:8vh' : ''
                      "
                      style="
                        margin-left: 2vh;
                        margin-top: -0.5vh;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <span
                        class="OP_2"
                        style="font-weight: 600; font-size: 1vh"
                      >
                        <img
                          style="height: 2.5vh; width: 2.5vh"
                          src="../assets/Icons/headphone-915.svg"
                        />
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 512 512"><path fill="#013d48" d="M256 80C149.9 80 62.4 159.4 49.6 262c9.4-3.8 19.6-6 30.4-6c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48c-44.2 0-80-35.8-80-80V384 336 288C0 146.6 114.6 32 256 32s256 114.6 256 256v48 48 16c0 44.2-35.8 80-80 80c-26.5 0-48-21.5-48-48V304c0-26.5 21.5-48 48-48c10.8 0 21 2.1 30.4 6C449.6 159.4 362.1 80 256 80z"/></svg> -->
                      </span>
                    </div>
                  </div>
                </template>
                <span style="color: #ffff">Support</span>
              </v-tooltip>
            </div>

            <!-- <div v-if="(myplan === 'No Plan' || user_plan === false) && pricing_page_current === false" >
              <span @click="gopricingpage()" class="selected_pricing" style="margin-left: 0vh;">Buy</span>
            </div> -->

            <div
              v-if="
                (myplan === 'No Plan' || user_plan === false) &&
                pricing_page_current === true
              "
              style=""
            ></div>

            <div style="display: flex; align-items: center">
              <v-menu bottom offset-y style="border: 1px solid blue">
                <template v-slot:activator="{ on, attrs }">
                  <div class="profileBtnPosition" v-bind="attrs" v-on="on">
                    <div
                      class="user_profile"
                      style="
                        margin-left: 2vh;
                        margin-top: -0.5vh;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <span class="OP_2">
                        {{ mail_initials }}
                      </span>
                    </div>
                    <!-- <span class="userProfileBtn">
                      User Profile
                    </span> -->
                    <!-- <img src="../assets/Icons/Group 736.svg" style="height: 1vh;margin-left:3px" /> -->
                  </div>
                </template>
                <v-list>
                  <v-list-item
                    class="popUpMenus a"
                    style=""
                    v-if="myplan != 'No Plan' && user_plan == true"
                  >
                    <v-list-item-title
                      style="color: #49575d !important; font-size: 14px"
                      @click="goto_profile()"
                      >Profile Settings</v-list-item-title
                    >
                  </v-list-item>
                  <v-divider
                    v-show="
                      show_notes_icon &&
                      myplan != 'No Plan' &&
                      user_plan == true
                    "
                    style="margin: 4px 0"
                  ></v-divider>
                  <v-list-item
                    v-show="
                      show_notes_icon &&
                      myplan != 'No Plan' &&
                      user_plan == true
                    "
                    class="popUpMenus a"
                    style=""
                  >
                    <v-list-item-title
                      style="color: #49575d !important; font-size: 14px"
                      @click="show_notes()"
                      >My Notes
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider
                    v-show="
                      show_notes_icon &&
                      myplan != 'Trial' &&
                      myplan != 'No Plan' &&
                      user_plan == true
                    "
                    style="margin: 4px 0"
                  ></v-divider>
                  <v-list-item
                    v-show="
                      show_notes_icon &&
                      myplan != 'Trial' &&
                      myplan != 'No Plan' &&
                      user_plan == true
                    "
                    class="popUpMenus a"
                    style=""
                  >
                    <v-list-item-title
                      style="color: #49575d !important; font-size: 14px"
                      @click="goto_myplan()"
                      >My Plan</v-list-item-title
                    >
                  </v-list-item>
                  <!-- <v-divider style="margin: 4px 0"></v-divider>
                    <v-list-item class="popUpMenus a" style="">
                      <v-list-item-title style="color: #49575d !important;font-size: 14px;" @click="goto_wishlist()">My Wishlist</v-list-item-title>
                    </v-list-item> -->
                  <!-- <v-divider style="margin: 4px 0"></v-divider>
                    <v-list-item class="popUpMenus a" style="">
                      <v-list-item-title style="color: #49575d !important;font-size: 14px;" @click="goto_cart()">My Cart</v-list-item-title>
                    </v-list-item>
                    <v-divider style="margin: 4px 0"></v-divider>
                    <v-list-item class="popUpMenus a" style="">
                      <v-list-item-title style="color: #49575d !important;font-size: 14px;" @click="goto_subscribed()">Subscribed Diseases</v-list-item-title>
                    </v-list-item> -->
                  <v-divider
                    style="margin: 4px 0"
                    v-if="myplan != 'No Plan' && user_plan == true"
                  ></v-divider>
                  <v-list-item class="popUpMenus">
                    <v-list-item-title
                      style="color: red; font-size: 14px"
                      @click="() => logOut()"
                      >Log Out</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </v-col>
      </v-row>

      <template>
        <div class="text-center">
          <v-dialog v-model="open_diseases_modal" width="1000">
            <v-card
              class="Path-359"
              style="
                padding: 2vh 2vh 2vh 2vh;
                overflow-y: auto;
                max-height: 100vh;
              "
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="7" md="7" lg="7" xs="7" xl="7">
                    <span class="Select-Diseases">Diseases Selection</span>
                  </v-col>
                  <v-col cols="12" sm="3" md="4" lg="4" xs="4" xl="4"> </v-col>
                  <v-col cols="12" sm="1" md="1" lg="1" xs="1" xl="1">
                    <v-icon
                      color="#009ea5"
                      style="cursor: pointer; float: right"
                      @click="close_diseases_modal()"
                      >mdi-close</v-icon
                    >
                  </v-col>
                </v-row>
                <hr />
                <v-row>
                  <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                    <v-row wrap>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xs="12"
                        xl="12"
                        style="margin-top: -4vh"
                      >
                        <div class="Select-Diseasee">Select Diseases</div>
                        <v-text-field
                          type="text"
                          dense
                          outlined
                          v-model="searchdisease"
                          label="Search Disease"
                          style="
                            background-color: white;
                            font-family: Ubuntu;
                            font-weight: 500;
                            margin-left: 0.5vw;
                            margin-right: 0.5vw;
                          "
                        ></v-text-field>
                        <div
                          style="
                            max-height: 40vh;
                            overflow-y: scroll;
                            padding: 1vh;
                          "
                        >
                          <div
                            v-for="(dis, key) in filteredDisesesList"
                            :key="key"
                            @click="get_disease(dis)"
                          >
                            <div
                              :class="
                                dis.status == 'Not-selected'
                                  ? 'dotted_box1'
                                  : 'dotted_box2'
                              "
                            >
                              <v-row>
                                <v-col
                                  cols="12"
                                  sm="2"
                                  md="2"
                                  lg="2"
                                  xs="2"
                                  xl="2"
                                >
                                  <img
                                    src="../assets/Icons/Group 992.svg"
                                    style="width: 20px; height: 20px"
                                    v-show="dis.status !== 'Not-selected'"
                                  />
                                  <span
                                    class="circle"
                                    v-show="dis.status == 'Not-selected'"
                                  ></span>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="10"
                                  md="10"
                                  lg="10"
                                  xs="10"
                                  xl="10"
                                  style=""
                                >
                                  <span class="Cat-Scratch-Disease">{{
                                    dis.name
                                  }}</span>
                                </v-col>
                              </v-row>
                              <!-- <v-checkbox color="#03a6ad" :label="dis.name" class="Cat-Scratch-Disease"></v-checkbox> -->
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    lg="6"
                    xs="6"
                    xl="6"
                    style="margin-top: -4vh"
                  >
                    <v-row wrap>
                      <v-col cols="12" sm="1" md="1" lg="1" xs="1" xl="1">
                        <div class="vl"></div>
                      </v-col>
                      <v-col cols="12" sm="11" md="11" lg="11" xs="11" xl="11">
                        <div class="Select-Diseasee">
                          {{ new_dis_len }} Selected Diseases
                        </div>
                        <v-row style="max-height: 45vh; overflow-y: scroll">
                          <div v-for="(newd, knw) in new_diseases" :key="knw">
                            <div class="Path-8">
                              {{ newd.name }}
                              <span
                                ><img
                                  src="../assets/Icons/Group 1010.svg"
                                  style="
                                    float: right;
                                    margin-top: 0.5vh !important;
                                  "
                                  @click="get_disease(newd)"
                              /></span>
                            </div>
                          </div>
                          <div
                            style="margin-top: 15vh; text-align: center"
                            v-show="new_dis_len == 0"
                          >
                            <span class="further_section"
                              >You have not selected diseases for further
                              process</span
                            >
                          </div>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <hr />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-row style="margin-top: -6vh">
                  <v-col sm="8" md="8" lg="8" xs="8" xl="8"></v-col>
                  <v-col sm="4" md="4" lg="4" xs="4" xl="4">
                    <v-btn
                      style="
                        background-color: #009ea5;
                        color: #ffffff;
                        font-size: 17px;
                      "
                      @click="update_profile()"
                      :class="{ disabled: isDisabled }"
                      :disabled="isDisabled"
                    >
                      Submit
                    </v-btn>
                    <!-- <v-btn v-if="myplan == 'First Mover Plan'" style="background-color: #009ea5; color: #ffffff;font-size: 17px;" @click="submit_diseases()" :class="{ disabled: isDisabled }" :disabled="isDisabled">
                      Submit
                    </v-btn> -->
                    <div id="error-message" class="error-message"></div>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
      <template>
        <div class="text-center">
          <v-dialog v-model="companies_modal" width="900">
            <v-card class="Path-359" style="padding: 3vh 2vh 3vh 2vh">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                    <span class="Active_Companies_title"
                      >Active Leading Companies</span
                    >
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                    <v-icon
                      class="pull-right"
                      color="#009ea5"
                      style="cursor: pointer; float: right"
                      @click="close_companies_modal()"
                      >mdi-close</v-icon
                    >
                  </v-col>
                </v-row>
                <v-row wrap>
                  <p v-if="!comp_flag" class="com_nm1">
                    Information not available
                  </p>

                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    lg="4"
                    xs="4"
                    xl="4"
                    v-for="(com, kcom) in companies_info"
                    :key="kcom"
                    style="margin-top: 1vh"
                  >
                    <v-card class="Path-85">
                      <div v-for="(t, kt) in com.Tiles" :key="kt">
                        <div class="centerAlign">
                          <img
                            :src="t.company_image.src"
                            alt="logo"
                            style="height: 5vh; margin-top: 2vh"
                            v-if="t.Text == 'Company_Logo'"
                          />
                        </div>
                        <p class="com_nm" v-if="t.Text == 'Company_Name'">
                          {{ t.Value }}
                        </p>
                        <p
                          class="com_des"
                          v-if="t.Text == 'Company_Brief_Summary'"
                        >
                          {{ t.Value }}
                        </p>
                      </div>
                      <p class="learn" @click="goto_comapny_article(com)">
                        Learn More
                      </p>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions> </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      mini-variant-width="98%"
      width="100%"
      height="100%"
      color="#013d48"
      :clipped="clipped"
      hide-overlay
      right
      class="nav-Drawer"
      v-if="isTabletMob"
    >
      <div
        class="vuebar-element"
        style="max-height: 90vh; overflow-y: scroll; padding: 2vh !important"
      >
        <div v-if="!verified">
          <div class="sidebar_option_selection">
            <span
              style="
                color: #ffffff;
                font-size: 2.5vh;
                font-family: Ubuntu;
                font-weight: 500;
                line-height: 1.67;
                cursor: pointer;
              "
              @click="show_pricing() && (this.drawer = false)"
              >Pricing</span
            >
          </div>
          <hr style="border-top: 2px solid #ffffff !important" />
        </div>
        <div v-if="verified">
          <div class="sidebar_option_selection">
            <span
              style="
                color: #ffffff;
                font-size: 2.5vh;
                font-family: Ubuntu;
                font-weight: 500;
                line-height: 1.67;
                cursor: pointer;
              "
              @click="goto_profile() && (this.drawer = false)"
              >Profile Settings</span
            >
          </div>
          <hr style="border-top: 2px solid #ffffff !important" />
          <div class="sidebar_option_selection">
            <span
              style="
                color: #ffffff;
                font-size: 2.5vh;
                font-family: Ubuntu;
                font-weight: 500;
                line-height: 1.67;
                cursor: pointer;
              "
              @click="show_notes() && (this.drawer = false)"
              >My Notes</span
            >
          </div>
          <hr
            style="border-top: 2px solid #ffffff !important"
            v-show="
              show_notes_icon &&
              myplan != 'Trial' &&
              myplan != 'No Plan' &&
              user_plan == true
            "
          />
          <div
            class="sidebar_option_selection"
            v-show="
              show_notes_icon &&
              myplan != 'Trial' &&
              myplan != 'No Plan' &&
              user_plan == true
            "
          >
            <span
              style="
                color: #ffffff;
                font-size: 2.5vh;
                font-family: Ubuntu;
                font-weight: 500;
                line-height: 1.67;
                cursor: pointer;
              "
              @click="goto_myplan() && (this.drawer = false)"
              >My Plan</span
            >
          </div>
          <hr style="border-top: 2px solid #ffffff !important" />
          <div class="sidebar_option_selection">
            <span
              style="
                color: #ffffff;
                font-size: 2.5vh;
                font-family: Ubuntu;
                font-weight: 500;
                line-height: 1.67;
                cursor: pointer;
              "
              @click="openSupport() && (this.drawer = false)"
              >Support</span
            >
          </div>
          <hr style="border-top: 2px solid #ffffff !important" />
          <div class="sidebar_option_selection">
            <span
              style="
                color: red;
                font-size: 2.5vh;
                font-family: Ubuntu;
                font-weight: 500;
                line-height: 1.67;
                cursor: pointer;
              "
              @click="logOut() && (this.drawer = false)"
              >Logout</span
            >
          </div>
          <hr style="border-top: 2px solid #ffffff !important" />
        </div>
      </div>
    </v-navigation-drawer>
    <!--<v-navigation-drawer v-model="drawer" app mini-variant-width="8%" width="8%" height="100%" color="#013d48" :mini-variant="miniVariant" :clipped="clipped" hide-overlay left
      class="nav-Drawer" v-if="!isModuleLibrary">
      <div class="vuebar-element" style="max-height: 54vh;overflow-y: scroll;">
          <div>
            <div class="sidebar_option_selection" v-for="(ins, keyy) in sortedInsights" :key="keyy" 
              :style="ins.selected == true ? 'border-radius: 6px;background-color: #f16753;' : 'background-color:inherit;'" @click="go_to_article(ins)" v-show="show_article">         
                <div class="Industry-Updates-text" data-toggle="tooltip" data-placement="right" :title="ins.Info">
                  <span v-html="ins.Title"></span>                  
                </div>
            </div>
            <div class="sidebar_option_selection" v-for="(inss, keyins) in sortedInsights" :key="'A'+keyins" 
              :style="inss.selected == true ? 'border-radius: 6px;background-color: #f16753;' : 'background-color:inherit;'" @click="go_to_article(inss)" v-show="!show_article && (inss.Title_Value == 'Industry Updates'|| inss.Title_Value == 'Therapy Landscape')">
                <div class="Industry-Updates-text" data-toggle="tooltip" data-placement="right" :title="inss.Info">
                  <span v-html="inss.Title"></span>                  
                </div>
            </div>
          </div>  
            <div class="sidebar_option_selection_modules"  v-if="!no_authentication" align="center" style="bottom:26vh"
               >
              <div class="Line-202" ></div> 
              <div  @click="showque()" :style="chat_selected == true ? 'border-radius: 6px;background-color: #f16753;' : 'background-color:inherit;'" style="margin-bottom:3vh;padding:1.5 vh 1.5vh 1vh 1vh">
                <div class="Industry-Updates-text" data-toggle="tooltip" data-placement="right" title="KnolChat is an intelligent researcher, a question answerer, a document finder, an analytics engine.">
                  <img v-if="!chat_selected" src="../assets/Icons/Group 1102.svg" style="margin-bottom:12px;padding-top:4px;">
                  <img v-else src="../assets/Icons/Group 1102_un.svg" style="margin-bottom:12px;margin-top:10px">
                  <span style="margin-top:11.3px;color:#ffffff;font-family: Ubuntu;font-size: 1.7vh;">KnolChat</span>
                </div>
              </div>
            </div>
            <div class="sidebar_option_selection_modules"  v-if="no_authentication" align="center" style="bottom:26vh"
               >
              <div class="Line-202" ></div> 
              <div  @click="goto_register()" style="margin-top:1vh;margin-bottom:2vh;padding:1.5 vh 1.5vh 1vh 1vh;background-color:inherit;">               
                  <img  src="../assets/Icons/Group 1102.svg" style="margin-bottom:12px;margin-top:5px;margin-left:-1vh">
                  <br>
                  <span style="margin-top:11.3px;color:#ffffff;font-family: Ubuntu;font-size: 1.7vh;">KnolChat</span>
                </div>
              </div>
            </div>
            
            <div class="sidebar_option_selection_modules" style="bottom:10vh">
              <div @click="go_to_ModuleLibray()" :style="selectedModule == true ? 'border-radius: 6px;background-color: #f16753;' : 'background-color:inherit;'">
                <div class="Industry-Updates-text" data-toggle="tooltip" data-placement="right" title="Modules Library is store of modules related to the Indication Area you have chosen. Each module allows you to dive into detailed analysis and change assumptions to analyze scenarios.">
                  <img src="../assets/Icons/Group 797.svg" style="margin-bottom:12px;"><br>
                  <span style="margin-top:11.3px;">Modules Library</span>
                </div>
              </div>
            </div>
      </div>
    </v-navigation-drawer>-->
    <template>
      <div class="text-center">
        <v-dialog v-model="msg_modal" width="600">
          <v-card class="Path-359" style="padding: 3vh 2vh 3vh 2vh">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                  <img
                    src="../assets/Icons/Path 270.svg"
                    style="height: 3vh; width: 3vh; margin-right: 1.5vh"
                  />
                  <span class="Active_Companies_title">Sign Up !</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                  <v-icon
                    class="pull-right"
                    color="#009ea5"
                    style="cursor: pointer; float: right"
                    @click="msg_modal = false"
                    >mdi-close</v-icon
                  >
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                  <!-- <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;"> -->
                  <span class="com_nm1" style="margin-left: 0vh"
                    >Please sign up to get more diseases.</span
                  >
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <!-- <v-spacer></v-spacer> -->
              <v-row>
                <v-col sm="6" md="6" lg="6" xs="6" xl="6"></v-col>
                <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                  <v-btn
                    style="
                      background-color: #009ea5;
                      color: #ffffff;
                      font-size: 1.8vh;
                    "
                    @click="goto_login()"
                    >Sign Up</v-btn
                  >
                </v-col>
                <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                  <v-btn
                    style="
                      text-decoration: none;
                      color: #009ea5;
                      font-weight: 600;
                      font-size: 1.8vh;
                      font-family: Ubuntu;
                    "
                    @click="msg_modal = false"
                    >Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <template>
      <div class="text-center">
        <v-dialog v-model="subscription_failed_modal" width="600">
          <v-card class="Path-359" style="padding: 3vh 2vh 3vh 2vh">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                  <img
                    src="../assets/Icons/Path 270.svg"
                    style="height: 3vh; width: 3vh; margin-right: 3vh"
                  />
                  <span class="Active_Companies_title"
                    >Subscription Failed !</span
                  >
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                  <v-icon
                    class="pull-right"
                    color="#009ea5"
                    style="cursor: pointer; float: right"
                    @click="subscription_failed_modal = false"
                    >mdi-close</v-icon
                  >
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                  <p class="com_nm3" style="margin-left: 0vh">
                    Your subscription has been failed. Now you have limited
                    access for diseases<span style="color: #ff6750">
                      Multiple Sclerosis.</span
                    >
                  </p>
                  <p class="com_nm3">
                    You can subscribe again with same plan to get access for
                    more diseases
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-row>
                <v-col sm="5" md="5" lg="5" xs="5" xl="5"></v-col>
                <v-col sm="4" md="4" lg="4" xs="4" xl="4">
                  <v-btn
                    style="
                      background-color: #009ea5;
                      color: #ffffff;
                      font-size: 2.2vh;
                    "
                    @click="subscribe_again()"
                    >Subscribe</v-btn
                  >
                </v-col>
                <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                  <v-btn
                    style="
                      text-decoration: none;
                      color: #009ea5;
                      font-weight: 600;
                      font-size: 2.2vh;
                      font-family: Ubuntu;
                    "
                    @click="subscription_failed_modal = false"
                    >Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <SubscriptionModal ref="SubscriptionModal"></SubscriptionModal>
    </template>

    <!-- Modal on Payment fail of other plans, and Asking to select Disease for Trial Plan-->
    <!-- <template>
          <div class="text-center">
            <v-dialog
              v-model="planData.Show_disease_select_opt"
              width="600"
              close-on-content-click=false
            >
            <v-card class="Path-359" style="padding:3vh 2vh 3vh 2vh">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="8" md="8" lg="8" xs="8" xl="8">
                    <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;margin-right: 3vh;">
                    <span class="Active_Companies_title">Trial Period</span>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                    <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="planData.Show_disease_select_opt = false">mdi-close</v-icon>
                  </v-col>
                </v-row>
                <v-row wrap>
                                        
                  <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                    <p class="com_nm3" style="margin-left:0vh">
                      Your 1 month free Trial Plan has activated.<br>
                    </p>
                  </v-col>
                </v-row>
                <v-row wrap>
                        <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12" style="margin-top: -4vh;">
                          <div class="Select-Diseasee">Select Disease For Trial Plan</div>
                          <v-text-field type="text" dense outlined v-model="searchdisease" label="Search Disease"
                            style="background-color: white;font-family: Ubuntu;font-weight: 500;margin-left:0.5vw;margin-right:0.5vw;"></v-text-field>
                          <div style="max-height:30vh;overflow-y:scroll;padding:1vh">
                            <div v-for="(dis, key) in  trialDiseaseList" :key="key" @click="trialDisease(dis)">
                              <div :class="dis.status == 'Not-selected' ? 'dotted_box1' : 'dotted_box2'">
                                <v-row>
                                  <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                                    <img src="../assets/Icons/Group 992.svg" style="width:20px;height:20px;" v-show="dis.status !== 'Not-selected'">
                                    <span class="circle" v-show="dis.status == 'Not-selected'"></span>
                                  </v-col>
                                  <v-col cols="12" sm="10" md="10" lg="10" xs="10" xl="10" style="">
                                    <span class="Cat-Scratch-Disease">{{dis.name}}</span>
                                  </v-col>
                                </v-row>
                                
                              </div>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-row>
                    <v-col sm="5" md="5" lg="5" xs="5" xl="5"></v-col>
                    <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                      
                    </v-col>
                    <v-col sm="4" md="4" lg="4" xs="4" xl="4">
                      <v-btn style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 2.2vh;font-family: Ubuntu;" @click="SaveTrialDisease()">Proceed
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>  
              </v-card>
            </v-dialog>
          </div>
      </template> -->
    <template>
      <v-dialog v-model="trial_modal" width="700">
        <v-card class="Path-359" style="padding: 3vh">
          <v-row>
            <v-col cols="12" xs="2" md="2" xl="2" sm="2" align="right">
              <img
                src="../assets/Icons/Path 270.svg"
                style="height: 3vh; width: 3vh"
              />
            </v-col>
            <v-col cols="12" xs="8" md="8" xl="8" sm="8" align="left">
              <span style="font-family: Ubuntu; font-size: 18px">
                KnolChat is not included in your current plan.
              </span>
            </v-col>
            <v-col cols="12" xs="2" md="2" xl="2" sm="2" align="right">
              <v-btn
                icon
                color="#009ea5"
                @click="trial_modal = false"
                style="padding-bottom: 5px"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog fullscreen :scrim="false" style="padding: 0">
        <v-card class="Path-359_support">
          <v-row>
            <v-col cols="12" xs="2" md="2" xl="2" sm="2"></v-col>
            <v-col cols="12" xs="8" md="8" xl="8" sm="8" align="center">
              <div class="asana-embed-container" style="height: 90vh">
                <link
                  rel="stylesheet"
                  href="https://form.asana.com/static/asana-form-embed-style.css"
                />
                <iframe
                  class="asana-embed-iframe"
                  style="width: 100%; height: 100% !important"
                  src="https://form.asana.com/?k=CnkL-JbFDrXTcbUKHr4S7Q&d=1203019045083878&embed=true"
                ></iframe>
                <div class="asana-embed-footer">
                  <!-- <a rel="nofollow noopener" target="_blank" class="asana-embed-footer-link" href="https://asana.com/?utm_source=embedded_form">
                  <span class="asana-embed-footer-text">Form powered by</span><div class="asana-embed-footer-logo" role="img" aria-label="Logo of Asana">
                  </div>
                </a> -->
                </div>
              </div>
            </v-col>
            <v-col
              cols="12"
              xs="2"
              md="2"
              xl="2"
              sm="2"
              align="right"
              style="padding: 0"
            >
              <v-btn
                icon
                color="#009ea5"
                @click="support = false"
                style="padding-bottom: 5px; margin-right: 4vh; margin-top: 1vh"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </template>
  </v-row>
</template>

<script>
import axios from "axios";
import { EventBus } from "../event-bus.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import SubscriptionModal from "@/components/subscription_modal.vue";

export default {
  name: "AppBar",
  data() {
    return {
      auth_token: "",
      user: {},
      userID: "",
      disease_name: "",
      version_number: "",
      toggleDefault: 0,
      title: "Acute Myeloid Leukaemia",
      closeOnContentClick: false,
      searchme: "",
      my_diseases: [],
      open_diseases_modal: false,
      diseases: [],
      other_diseases: [],
      other_disease: "",
      searchdisease: "",
      new_diseases: [],
      alldiseases: [],
      disease_id: "",
      verified: false,
      summary: false,
      companies_modal: false,
      companies_info: [],
      companyname: "",
      active: false,
      comp_flag: false,
      no_add_diseases: false,
      no_authentication: false,
      duration_span: ["Last 3 Months", "Last Year"],
      drawer: false,
      miniVariant: true,
      clipped: false,
      insight_card_items: [
        { card_no: 0 },
        { card_no: 1 },
        { card_no: 2 },
        { card_no: 3 },
      ],
      disesase: "",
      articles: "",
      old_selected_page: "",
      selected_page: "",
      trends_articles: [],
      busi_count: 0,
      clini_count: 0,
      sci_count: 0,
      total_ind_count: 0,
      loading: false,
      fullPage: true,
      no_info: false,
      company_listed: false,
      companies: [],
      my_company: "",
      comparative_view: {},
      latest_updates: {},
      total_business: 0,
      total_clinical: 0,
      total_science: 0,
      total_all: 0,
      newly_approved_drugs: {},
      new_approved_drugs_count: 0,
      no_authentication: false,
      comp_text_blank: false,
      selected_article: "",
      insights_articles: [
        {
          Title: "Industry <br> Updates",
          Title_Value: "Industry Updates",
          Number: 0,
          Info: "",
        },
        {
          Title: "Therapy <br> Landscape",
          Title_Value: "Therapy Landscape",
          Number: 1,
          Info: "This set of panels will review the state of the disease, and the landscape of therapies available today, including the number of drugs, mechanisms of action, the stages they target, companies driving these drugs, and the financial landscape of therapeutics for this disease.",
        },
        {
          Title: "Clinical <br> Review",
          Title_Value: "Clinical Review",
          Number: 2,
          Info: "This set of panels focus on our learnings from the clinical trials (including key study design parameters and evolution of endpoints) and guidance on how the drug may be used.",
        },
        {
          Title: "Development <br> Pipeline",
          Title_Value: "Development Pipeline",
          Number: 3,
          Info: "The goal of this section is to help understand the strength of the current pipeline and what it takes to develop drugs in this therapeutic area. These panels include the historic breakup of studies by phases and kind of studies, statistics on studies initiated, completed, terminated  and how many trials it takes for  drugs to be approved, size of these studies and time they take, nature and number of new mechanisms being tried.",
        },
        {
          Title: "Active <br> Companies",
          Title_Value: "Active Companies",
          Number: 4,
          Info: "This section reviews the state of companies who sponsored drugs that were approved, and those that are trying to bring new drugs to the market, the revenue they generate from this therapeutic area, their engagement in terms of number of drugs and trials.",
        },
        // {
        //   "Title": "My <br> Modules",
        //   "Title_Value": "My Modules",
        //   "Number": 5,
        // },
      ],
      msg_modal: false,
      user_approved: false,
      note_selected: false,
      confirm_disease: "",
      total_amount: 0,
      err_flag: false,
      user_email: "",
      expected_pages: true,
      diseases_in_cart: [],
      total_cart_items: 0,
      temp_diseases: [],
      pricing_selected: false,
      myplan: "",
      subscription_failed: false,
      subscription_failed_modal: false,
      new_dis_len: 0,
      //Modules definations
      selectedModule: false,
      MyModule: false,
      isModuleLibrary: false,
      show_article: false,
      pinnedTabs: [],
      queana_modal: false,
      checkboxData: [],
      questionIds: [],
      loadingModal: false,
      my_word: "",
      answer: "",
      question: "",
      myquestion: "",
      show_que: false,
      option_lables: [
        {
          name: "Labels",
          status: "selected",
        },
        {
          name: "Clinical Studies",
          status: "notselected",
        },
        {
          name: "PubMed",
          status: "notselected",
        },
        {
          name: "Patents",
          status: "notselected",
        },
      ],
      loadingAnswer: false,
      option: "fda",
      queans: [],
      trial_modal: false,
      chat_selected: false,
      resetCheckbox: false,
      trial_moal: false,
      planData: {},
      mydisease_count: 0,
      current_module_id: "",
      steps: [
        {
          target: "#v-step-0", // We're using document.querySelector() under the hood
          header: {
            title: "Get Started",
          },
          content: `Discover <strong>Vue Tour</strong>!`,
        },
        {
          target: ".v-step-1",
          content: "An awesome plugin made with Vue.js!",
        },
        {
          target: '[data-v-step="2"]',
          content:
            "Try it, you'll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.",
          params: {
            placement: "top", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
        },
      ],
      days_remaining: 0,
      gotochat: false,
      searchme_module: "",
      support: false,
      show_search: false,
      user_plan: false,
      show_diseases: false,
      isArticle: false,
      show_notes_icon: true,
      pricing_page_current: false,
      show_settings: true,
      show_login: true,
      sampleKey: "",
      isOverview: false,
      email: "",
      mail_initials: "",
      private_module: false,
      hideAppbar: false,
      module_name: "",
    };
  },
  Name: "AppBar",
  watch: {
    searchme_module: function () {
      EventBus.$emit("searchme", this.searchme_module);
      // this.$router.push("/")
      this.$forceUpdate();
    },

    $route(to, from) {
      // console.log(this.$route.name,"to.name")

      if (this.$route.name === "Geknowme_Module") {
        if (this.isTabletMob) {
          this.isOverview = true;
        }
      } else {
        if (this.isTabletMob) {
          this.isOverview = false;
        }
      }

      if (this.$route.name === "Modules_Library") {
        this.isModuleLibrary = true;
        this.searchme_module = "";
      } else {
        this.isModuleLibrary = false;
      }
      if (this.$route.name === "articleDetails") {
        this.isArticle = true;
      }
      // console.log(this.$route.name,"this.$route.name......")
      if (this.$route.name === "Unsubscribe_mail") {
        // console.log("Unsubscribe_mail")
        this.show_settings = false;
      } else {
        this.show_settings = true;
      }
      if (this.$route.name === "sharewebarticle") {
        // console.log("Unsubscribe_mail")
        this.show_login = false;
      } else {
        this.show_login = true;
      }

      //check if route.params.analytical is true

      if (
        this.auth_token !== null &&
        this.auth_token !== "" &&
        this.auth_token !== undefined
      ) {
        if (from.name === "Modules_Library" && to.name === "articleList") {
        }
        // console.log(this.$route.name,"this.$route.name")

        if (this.$route.name == "Geknowme_Module") {
          // console.log("Geknowmeeee")
          this.show_diseases = true;
        } else {
          this.show_diseases = false;
        }
        if (this.$route.name == "Support") {
          // console.log("Geknowmeeee")
          this.show_notes_icon = false;
        }

        if (from.name === "Modules_Library" && to.name === "Login") {
          this.$router.push("/");
          // console.log("whyyyyyy loginn")
        }
        if (from.name === "Modules_Library" && to.name === "Register") {
          this.$router.push("/");
        }
        if (from.name === "Modules_Library" && to.name === "articleDetails") {
          this.selectedModule = false;
          // this.$router.push("/")
        }
        if (
          (from.name === "Modules_Library" && to.name === "My_Modules") ||
          (from.name === "Modules_Library" && to.name === "Mechanism_Module")
        ) {
          this.selectedModule = false;
          this.MyModule = true;
        }
        if (from.name === "Profile_Settings" && to.name === "articleList") {
          this.selectedModule = false;
          this.MyModule = false;
        }
        if (from.name === "Modules_Library" && to.name === "Sample") {
          this.selectedModule = true;
          this.MyModule = false;
        }
        if (from.name === "Modules_Library" && to.name === "My_Modules") {
          this.selectedModule = false;
          this.MyModule = false;
          //this.$router.push("/")
        }
        if (from.name === "Modules_Library" && to.name === "Mechanism_Module") {
          this.selectedModule = true;
          this.MyModule = false;
        }
        if (
          (from.name === "My_Modules" && to.name === "articleList") ||
          (from.name === "Module_Transaction_Fail" &&
            to.name === "articleList") ||
          (from.name === "Sample" && to.name === "articleList") ||
          (from.name === "Module_Transaction_Success" &&
            to.name === "articleList") ||
          (from.name === "Module_Payment" && to.name === "articleList") ||
          (from.name === "Mechanism_Module" && to.name === "articleList") ||
          (from.name === "Geknowme_Module" && to.name === "articleList") ||
          (from.name === "Geknowme_Settings" && to.name === "articleList")
        ) {
          this.selectedModule = false;
          this.MyModule = false;
        }

        if (this.$route.name == "User_pricing") {
          this.pricing_page_current = true;
        } else {
          this.pricing_page_current = false;
        }
      }
      //console.log(to.name, from.name)
      //this.check_essentials_created()
      this.check_article_type();
    },
  },
  components: {
    Loading,

    SubscriptionModal,
  },
  computed: {
    display_diseases_my() {
      var returnArray = [];
      if (this.mydisease_count > -1) {
        if (this.alldiseases.length > 0) {
          this.alldiseases.filter((item) => {
            if (
              item.Name.toLowerCase().indexOf(this.searchme.toLowerCase()) >
                -1 &&
              item.My_disease
            ) {
              returnArray.push(item.Name);
            }
          });
        }
      }
      return returnArray;
    },
    display_diseases_other() {
      var returnArray = [];
      if (this.mydisease_count > -1) {
        if (this.alldiseases.length > 0) {
          this.alldiseases.filter((item) => {
            if (
              item.Name.toLowerCase().indexOf(this.searchme.toLowerCase()) >
                -1 &&
              !item.My_disease
            ) {
              returnArray.push(item.Name);
            }
          });
        }
      }
      return returnArray;
    },
    filteredList() {
      return this.my_diseases.filter((item) => {
        return item.toLowerCase().indexOf(this.searchme.toLowerCase()) > -1;
      });
    },
    filteredDisesesList() {
      return this.diseases.filter((item) => {
        return (
          item.name.toLowerCase().indexOf(this.searchdisease.toLowerCase()) > -1
        );
      });
    },
    trialDiseaseList() {
      return this.temp_diseases.filter((item) => {
        return (
          item.name.toLowerCase().indexOf(this.searchdisease.toLowerCase()) > -1
        );
      });
    },
    sortedInsights: function () {
      return this.insights_articles.sort((a, b) => a.Number - b.Number);
    },
    isTabletMob: function () {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ];

      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    },
    isDisabled() {
      if (this.new_diseases.length > 0) {
        return false;
      } else {
        return true;
      }
    },

    // If a fetch error occurs, log it to the console and show it in the UI.
    handleFetchResult(result) {
      if (!result.ok) {
        return result
          .json()
          .then(function (json) {
            if (json.error && json.error.message) {
              throw new Error(
                result.url + " " + result.status + " " + json.error.message
              );
            }
          })
          .catch(function (err) {
            showErrorMessage(err);
            throw err;
          });
      }
      return result.json();
    },

    showErrorMessage(message) {
      var errorEl = document.getElementById("error-message");
      errorEl.textContent = message;
      errorEl.style.display = "block";
    },
  },
  mounted() {
    //check for isTabletMob and set drawer accordingly

    // console.log(this.$route.name,"this.$route.name")

    if (
      this.$route.name === null &&
      selected_article == "Overview By Indication"
    ) {
      // console.log('nulllllll')
      // Add an event listener for the beforeunload event
      // window.addEventListener("beforeunload", function (e) {
      var moduleId = "64d4abad39e80392bcb587b3";
      var moduleName = "Overview by Indication";
      EventBus.$emit("module_name", moduleName);
      localStorage.setItem("selected_module", moduleName);
      let url = "/overviewbyIndication/" + moduleId + "/" + moduleName;
      this.$router.push(url);

      // });
    }
    if (this.$route.name === null && selected_article == "Sample") {
      this.getSampleKey();
    }

    if (this.$route.name === "Modules_Library") {
      this.isModuleLibrary = true;
      this.show_search = true;
    } else {
      this.isModuleLibrary = false;
    }
    // console.log(this.selectedModule)

    if (this.$route.name == "User_pricing") {
      this.pricing_page_current = true;
    } else {
      this.pricing_page_current = false;
    }
    if (this.$route.name === "Unsubscribe_mail") {
      // console.log("Unsubscribe_mail")
      this.show_settings = false;
    } else {
      this.show_settings = true;
    }
    if (this.$route.name === "sharewebarticle") {
      // console.log("Unsubscribe_mail")
      this.show_login = false;
    } else {
      this.show_login = true;
    }

    this.duration = this.duration_span[0];
    var auth_token = localStorage.getItem("authtoken");
    if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
      this.auth_token = auth_token;
      //console.log("auth_tokennnnn",this.auth_token)

      this.get_user_plan();
      this.get_page_activity();
      this.getEmail();
      EventBus.$on("module_name", (module_name) => {
        this.module_name = module_name;
        console.log(this.module_name, "module_name");
      });
      var module_n = localStorage.getItem("selected_module");
      if (
        module_n !== null ||
        module_n !== "" ||
        module_n !== undefined ||
        module_n !== "undefined"
      ) {
        this.module_name = module_n;
      }
      EventBus.$on("chat", (chat) => {
        this.gotochat = chat;
        if (this.gotochat) {
          this.get_user_plan();
          //  console.log(this.user_plan,"user_plan")
          //wait for get_user_plan to complete

          // if (this.user_plan==true){
          //   this.$router.push({ name: "Analytical_Chat" });
          // }else{
          //   this.$refs.SubscriptionModal.openPopup();
          // }

          // this.showque()
        }
      });
      this.get_initial_disease();
      this.check_essentials();
      this.check_article_type();
      this.fetch_data();
      this.getPinnedTabs();
      // var dis=localStorage.getItem("selected_disease")
      // if(dis!==null || dis!=="" || dis!==undefined || dis!=="undefined"){
      //   this.my_word=dis
      // }
    } else {
      this.auth_token = "";
      this.disesase = "Multiple sclerosis";
      this.check_essentials();
      this.check_article_type();
    }

    // if (this.isTabletMob == false) {
    //   this.drawer = false
    // } else {
    //   this.drawer = true
    // }

    // this.gotochat= this.$route.params.analytical
    // if (this.gotochat == "true") {
    //   console.log("chat onnnnnnn")
    //   this.chat_selected = true
    //   this.showque()
    // } else {
    //   this.chat_selected = false
    // }
  },

  created() {
    if (this.$route.name === "Geknowme_Module") {
      if (this.isTabletMob) {
        this.isOverview = true;
      }
    }
    EventBus.$on("hideAppbar", () => {
      this.hideAppbar = true;
    });
    EventBus.$on("showAppbar", () => {
      this.hideAppbar = false;
    });
    EventBus.$on("hide_close", () => {
      this.private_module = true;
    });
    EventBus.$on("hide_home", () => {
      this.private_module = false;
    });

    EventBus.$on("shared", () => {
      this.show_search = false;
      this.isModuleLibrary = true;
    });
    EventBus.$on("onhome", () => {
      this.show_notes_icon = true;
    });
    EventBus.$on("disease_list", (disease_list) => {
      this.alldiseases = [];
      if (disease_list.length > 0) {
        for (var i = 0; i < disease_list.length; i++) {
          var obj = {};
          obj.Name = disease_list[i];
          obj.My_disease = true;
          this.alldiseases.push(obj);
        }
        this.title = disease_list[0];
      } else {
        this.getdiseases(this.auth_token);
      }
      // console.log(this.alldiseases,"this.alldiseases")
    });

    EventBus.$on("disname", (disname) => {
      // console.log(disname,"disname")
      this.title = disname;
      this.get_disease_name(this.title, "non_geknowme");
    });
    EventBus.$on("chatselected", (chat) => {
      // console.log(chat)
      this.chat_selected = chat;
    });
    EventBus.$on("queana", (queana) => {
      // console.log(queana)
      this.queana_modal = queana;
    });
    EventBus.$on("geknowme_data", () => {
      this.show_article = false;
    });

    EventBus.$on("module_id", (moduleId) => {
      this.current_module_id = moduleId;
    });
    EventBus.$on("updateprofile", (data) => {
      // console.log("profile updated.....")
      this.getdiseases(this.auth_token);
      this.get_user_profile();
      // this.CheckPlans()
    });
    // EventBus.$on("newdiseases", (newdiseases) => {
    //   this.my_diseases = newdiseases
    //   console.log(this.my_diseases,"2222222")
    // });

    $(document).ready(function () {
      //initializing tooltip
      $('[data-toggle="tooltip"]').tooltip();
    });

    // if (this.isTabletMob == false) {
    //   this.drawer = false
    // } else {
    //   this.drawer = true
    // }
    if (this.$route.name === "Modules_Library") {
      this.isModuleLibrary = true;
    } else {
      this.isModuleLibrary = false;
    }
  },
  methods: {
    get_initial_disease() {
      //create a function to get the initial disease
      axios
        .get("/get_initial_disease?token=" + this.auth_token)
        .then((response) => {
          if ((response.data.Status = !1)) {
            this.title = response.data.Data.Disease;
            this.get_disease_name(this.title, "non_geknowme");
          }
        });
    },
    change_drawer_status() {
      // console.log(this.drawer)
      this.drawer = !this.drawer;
      this.chat_selected = false;
      EventBus.$emit("drawer_status", this.drawer);
    },
    check_article_type() {
      if (
        this.$route.name == "articleList" ||
        this.$route.name == "articleListLatest" ||
        this.$route.name == "share"
      ) {
        this.selected_article = "Industry Updates";
        this.note_selected = false;
        this.pricing_selected = false;
        this.chat_selected = false;
        for (var j = 0; j < this.insights_articles.length; j++) {
          if (this.insights_articles[j].Title_Value == "Industry Updates") {
            this.selectedModule = false;
            this.insights_articles[j].selected = true;
          } else {
            this.insights_articles[j].selected = false;
          }
        }
      } else {
        if (
          this.$route.name == "articleDetails" &&
          this.$route.query.panel !== undefined
        ) {
          this.note_selected = false;
          this.pricing_selected = false;
          var art_title = this.$route.query.article_title
            .toLowerCase()
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ");
          //console.log(art_title,"title")
          if (art_title == "Therapeutic Effectiveness") {
            art_title = "Clinical Review";
          }
          for (var j = 0; j < this.insights_articles.length; j++) {
            if (this.insights_articles[j].Title_Value == art_title) {
              this.insights_articles[j].selected = true;
            } else {
              this.insights_articles[j].selected = false;
            }
          }
        } else if (this.$route.name == "notes") {
          this.note_selected = true;
          this.pricing_selected = false;
          for (var i = 0; i < this.insights_articles.length; i++) {
            this.insights_articles[i].selected = false;
          }
        } else if (
          this.$route.name == "Pricing" ||
          this.$route.name == "User_pricing"
        ) {
          this.pricing_selected = true;
          this.note_selected = false;
          for (var i = 0; i < this.insights_articles.length; i++) {
            this.insights_articles[i].selected = false;
          }
        } else {
          this.note_selected = false;
          this.pricing_selected = false;
          // var selected_article = localStorage.getItem("selected_article");
          var selected_article = this.selected_page;
          if (
            selected_article !== undefined &&
            selected_article !== null &&
            selected_article !== ""
          ) {
            this.selected_article = selected_article;
            for (var j = 0; j < this.insights_articles.length; j++) {
              if (
                this.insights_articles[j].Title_Value == this.selected_article
              ) {
                this.insights_articles[j].selected = true;
              } else {
                this.insights_articles[j].selected = false;
              }
            }
          } else {
            this.selected_article = "";
            for (var j = 0; j < this.insights_articles.length; j++) {
              if (this.insights_articles[j].Title_Value == "Industry Updates") {
                this.insights_articles[j].selected = true;
              } else {
                this.insights_articles[j].selected = false;
              }
            }
          }
        }
      }

      this.$forceUpdate();
    },
    drawer_show() {
      this.drawer = !this.drawer;
    },
    check_essentials() {
      var auth_token = localStorage.getItem("authtoken");
      if (
        auth_token !== null &&
        auth_token !== "" &&
        auth_token !== undefined
      ) {
        this.auth_token = auth_token;
        this.no_authentication = false;
        this.verified = true;
        // this.title = localStorage.getItem("selected_disease")
        this.no_add_diseases = false;
        this.getuser();
        if (this.$route.name == "Transaction_success") {
          setTimeout((e) => {
            this.getdiseases(this.auth_token);
          }, 10000);
        } else {
          this.getdiseases(this.auth_token);
        }
      } else {
        this.auth_token = "";
        this.no_authentication = true;
        this.verified = false;
        this.title = "Multiple sclerosis";
        this.no_add_diseases = true;
        this.getdiseases_no_auth();
      }
    },
    check_essentials_created() {
      var auth_token = localStorage.getItem("authtoken");
      if (
        auth_token !== null &&
        auth_token !== "" &&
        auth_token !== undefined
      ) {
        this.auth_token = auth_token;
        this.no_authentication = false;
        this.verified = true;
        this.no_add_diseases = false;
        // this.title = localStorage.getItem("selected_disease")
        this.getuser();

        // if (this.$route.name == 'Transaction_success') {
        //   setTimeout(e => {
        //     this.getdiseases(this.auth_token)
        //   }, 10000)
        // } else {
        //   this.getdiseases(this.auth_token)
        // }
      } else {
        this.auth_token = "";
        this.no_authentication = true;
        this.verified = false;
        this.no_add_diseases = true;
        this.getdiseases_no_auth();
      }
    },
    getuser() {
      axios
        .get("/user?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status != 1) {
            this.user_email = res.data.Data.Email;
            //console.log(this.user_email,"this.user_email")
          } else if (
            res.data.Status == 1 &&
            res.data.Data.error == "Invalid User"
          ) {
            console.log("Invalid User");
            this.logOut();
          } else {
            console.log("User not found");
          }
        })
        .catch((err) => {
          this.logOut();
          console.log(err);
        });
    },
    logOut() {
      //Auth Token Removal
      //localStorage.removeItem("selected_disease");
      //localStorage.removeItem("diseases");

      localStorage.removeItem("authtoken");
      localStorage.removeItem("selectedColorList");
      // localStorage.setItem("selected_article", "Industry Updates");
      this.disesase = "Multiple sclerosis";
      this.title = "Multiple sclerosis";
      delete axios.defaults.headers.common["Authorization"];
      this.auth_token = null;

      this.$router.push("/").catch(() => {});
      location.reload();
    },
    goToHome() {
      this.private_module = false;
      this.$router.push("/").catch(() => {});
      localStorage.removeItem("submodule");
      localStorage.removeItem("submodulepage");
      localStorage.removeItem("module_id");
      localStorage.removeItem("input_id");
      localStorage.removeItem("input_value");

      this.l1l2_page_data = {};
      this.l1l2_page_data.article_title = "Events";
      this.l1l2_page_data.disease = this.title;
      this.chat_selected = false;
      this.queana_modal = false;
      EventBus.$emit("l1l2_page_data", this.l1l2_page_data);
    },
    goBack() {
      console.log(
        "goback",
        this.isModuleLibrary,
        this.private_module,
        this.verified
      );
      this.private_module = false;
      var isub = localStorage.getItem("submodule");

      if (isub == "true") {
        console.log("submodule", localStorage.getItem("submodulepage"));
        var subpage = localStorage.getItem("submodulepage");
        if (subpage != "true") {
          console.log("vkbj");
          EventBus.$emit("submodule", false);
          return;
        } else {
          console.log("submodule", localStorage.getItem("submodulepage"));
          this.$router
            .push("/customModule?moduleId=" + localStorage.getItem("module_id"))
            .catch(() => {});
          return;
        }
      } else {
        localStorage.removeItem("submodule");
        localStorage.removeItem("submodulepage");
        localStorage.removeItem("module_id");
        localStorage.removeItem("input_id");
        localStorage.removeItem("input_value");
      }

      //get selected article from local storage
      // var selected_article = localStorage.getItem("selected_article");
      // console.log("inside go back")
      if (!this.verified) {
        console.log("inside go back");
        this.$router.push("/").catch(() => {});
        return;
      }
      this.get_page_info();
      // var selected_article =this.selected_page
      // console.log(selected_article,"sqdfgf")
      // //get old selected article from local storage
      // // var old_selected_article = localStorage.getItem("old_selected_article");
      // if(selected_article == "Mech_module" ){
      //   // localStorage.setItem("selected_article","Mechanism_Module");
      //   this.$router.push("/MechanismModule?moduleId="+this.current_module_id).catch(() => {})

      //   //set selected article Mechanism_Module in local storage

      // }else if(selected_article == "Mech_module" ){
      //   // localStorage.setItem("selected_article","Custom_Module");
      //   this.$router.push("/CustomModule?moduleId="+this.current_module_id).catch(() => {})
      // }
      // //if selected article is mechanism module
      // else if(selected_article == "Mechanism_Module"){
      //   this.$router.push("/").catch(() => {})
      // }
      // else if(selected_article == "Custom_Module"){
      //   this.$router.push("/").catch(() => {})
      // }else{
      //   this.$router.push("/").catch(() => {})
      // }
    },
    goto_login() {
      this.$router.push("/Login").catch(() => {});
    },
    get_disease_name(nm, text) {
      console.log(text, "texttttt");

      // console.log(nm,"change diseases")
      this.closeOnContentClick = true;
      this.searchme = "";
      this.title = nm;
      this.show_article = false;
      var activity_name = "Disease Change";

      // localStorage.setItem("selected_disease",nm);
      axios
        .post(
          "/SaveActivity?token=" +
            this.auth_token +
            "&activity_name=" +
            activity_name +
            "&disease=" +
            nm
        )
        .then((res) => {
          if (res.data.Status !== 1) {
            console.log("updated succcesfully");
          }
        })
        .catch((err) => {
          throw err;
        });

      //Add condition if routename if module_library route to module_library else /
      if (this.$route.name == "Modules_Library") {
        // location.reload()

        EventBus.$emit("library", {});
        this.selectedModule = true;
        //   this.$router.push("/modulesLibrary").catch((err) => {
        //     console.log(err)

        // })
      } else if (
        this.$route.name == "Geknowme_Module" &&
        text != "non_geknowme"
      ) {
        console.log("geknowme emitted");
        //emit event to geknowme module
        EventBus.$emit("geknowme", nm);

        this.MyModule = true;

        //   this.$router.push("/GeKnowMeModule").catch((err) => {
        //     console.log(err)
        //   })
      } else if (this.$route.name == "articleList") {
        this.l1l2_page_data = {};
        this.l1l2_page_data.article_title = "Events";
        this.l1l2_page_data.disease = this.title;
        EventBus.$emit("l1l2_page_data", this.l1l2_page_data);

        for (var m = 0; m < this.user.Diseases.length; m++) {
          if (this.title.toLowerCase() == this.user.Diseases[m].toLowerCase()) {
            this.show_article = true;
            break;
          }
        }

        location.reload();
        // this.MyModule=true

        //   this.$router.push("/GeKnowMeModule").catch((err) => {
        //     console.log(err)
        //   })
      } else if (this.$route.name == "My_Modules") {
        // location.reload(true)
        this.MyModule = true;
        // this.$router.push("/myModules").catch((err) => {
        //   console.log(err)
        // })
      } else if (this.$route.name == "articleDetails") {
        //fire event with disease name
        EventBus.$emit("disease_name", this.title);
        // location.reload()

        // this.MyModule=true

        //   this.$router.push("/GeKnowMeModule").catch((err) => {
        //     console.log(err)
        //   })
      }

      this.$forceUpdate();
    },
    validateEmailAddress(e) {
      if (e.keyCode === 13) {
        //console.log("enter pressed")
      } else if (e.keyCode === 50) {
        alert("@ was pressed");
      }
    },
    stayopen() {
      this.closeOnContentClick = false;
    },
    open_diseasesmodal() {
      this.getdiseases(this.auth_token);
      this.open_diseases_modal = true;
      this.closeOnContentClick = true;
    },
    close_diseases_modal() {
      this.open_diseases_modal = false;
      this.new_diseases = [];
      this.new_dis_len = 0;
    },
    get_disease(dis) {
      if (dis.status == "Selected") {
        dis.status = "Not-selected";
        for (var j = 0; j < this.new_diseases.length; j++) {
          if (this.new_diseases[j].name == dis.name) {
            var spliced = this.new_diseases.splice(j, 1);
            break;
          }
        }
      } else {
        dis.status = "Selected";
        this.new_diseases.push(dis);
      }
      this.new_dis_len = this.new_diseases.length;
    },
    close_limited_disease() {
      this.show_limited_disease = false;
    },
    getdiseases(token) {
      this.loading = true;
      this.diseases = [];
      this.alldiseases = [];
      this.temp_diseases = [];
      var mydis = localStorage.getItem("seldis");
      if (
        mydis !== null &&
        mydis !== "" &&
        mydis !== undefined &&
        mydis !== "undefined"
      ) {
        var previous_selected = mydis.split(",");
        this.my_diseases.concat(previous_selected);
      } else {
        this.my_diseases = [];
      }
      //console.log(this.my_diseases, ".................")
      var all_diseases = [];
      var mydis = this.my_diseases;
      axios
        .get("/getalldiseases?token=" + token)
        .then((res) => {
          //console.log(res.status,"ressss")
          if (res.data.Status != 1) {
            this.alldiseases = res.data.Data;
            for (var i = 0; i < res.data.Data.length; i++) {
              all_diseases.push(res.data.Data[i].Name);
            }
            var array3 = all_diseases.filter(function (obj) {
              return mydis.indexOf(obj) == -1;
            });
            for (var j = 0; j < array3.length; j++) {
              var dis = {};
              dis.status = "Not-selected";
              dis.name = array3[j];
              this.temp_diseases.push(dis);
            }

            var dis = this.temp_diseases.sort(function (a, b) {
              var textA = a.name.toUpperCase();
              var textB = b.name.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });

            var sorted_dis = this.alldiseases.sort(function (x, y) {
              var textX = x.Name.toUpperCase();
              var textY = y.Name.toUpperCase();
              return textX < textY ? -1 : textX > textY ? 1 : 0;
            });

            this.temp_diseases = dis;
            this.alldiseases = sorted_dis;
            this.loading = false;
            this.title = this.alldiseases[0].Name;

            //this.diseases = this.temp_diseases
            //console.log(this.temp_diseases,"this.temp_diseases")

            this.get_user_profile();
          } else if (
            res.data.Status == 1 &&
            res.data.Data.error == "Invalid User"
          ) {
            console.log("Invalid User");
          } else {
            this.title = "Multiple sclerosis";
            console.log("Diseases not found");
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);
          }
        });
    },
    get_user_profile() {
      axios
        .get("/userProfile?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status !== 1) {
            this.userLoggedIn = true;
            this.user = res.data.Data;
            this.user_approved = this.user.User_approved;
            this.myplan = this.user.Plan;
            this.show_article = false;
            // console.log(this.myplan,"this.myplan")

            // if (this.myplan == null || this.myplan == "" || this.myplan == undefined || this.myplan == "undefined" || this.myplan == "0") {
            //   this.myplan = "Trial"
            // }

            if (
              this.user_approved == undefined ||
              this.user_approved == null ||
              this.user_approved == "" ||
              this.user_approved == "undefined"
            ) {
              this.user_approved = false;
            } else {
              this.user_approved = true;
            }

            //console.log(this.user.Diseases,"this.user.Diseases")
            if (this.user.Diseases.length > 0) {
              this.set_disease(this.user.Diseases);
              for (var m = 0; m < this.user.Diseases.length; m++) {
                if (
                  this.title.toLowerCase() ==
                  this.user.Diseases[m].toLowerCase()
                ) {
                  this.show_article = true;
                }
              }
            } else if (this.myplan != "Trial") {
              // console.log(this.myplan,"this.myplan")
              this.title = "Multiple sclerosis";
              // localStorage.setItem("selected_disease", this.title);
              console.log("Diseases not found");
            }

            // if (this.myplan == "Trial") {

            // } else {
            //   localStorage.removeItem("seldis");
            // }

            this.$forceUpdate();

            //Check User Plans And Change Display Accordingly
            this.CheckPlans();
          } else if (
            res.data.Status == 1 &&
            res.data.Data.error == "Invalid User"
          ) {
            console.log("Invalid User");

            this.logOut();
          } else {
            this.title = "Multiple sclerosis";
            // localStorage.setItem("selected_disease", this.title);
            console.log("Diseases not found");
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);
            this.logOut();
          }
        });
    },
    update_profile() {
      console.log("updating profile");
      this.open_diseases_modal = false;
      this.show_article = false;

      var wishlist_array = [];
      for (var f = 0; f < this.new_diseases.length; f++) {
        wishlist_array.push(this.new_diseases[f].name);
      }

      if (this.new_diseases.length > 0) {
        this.my_diseases = this.my_diseases.concat(wishlist_array);
        var dis = this.my_diseases.sort(function (a, b) {
          var textA = a.toUpperCase();
          var textB = b.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        this.my_diseases = dis;
      } else {
        console.log("new diseases not added");
      }

      // localStorage.setItem("selected_disease", this.new_diseases[0].name);
      var new_selected_disease = this.new_diseases[0].name;
      this.new_diseases = [];
      this.new_dis_len = 0;
      this.title = new_selected_disease;

      this.$router.push("/").catch(() => {});

      this.l1l2_page_data = {};
      this.l1l2_page_data.article_title = "Events";
      this.l1l2_page_data.disease = new_selected_disease;
      EventBus.$emit("l1l2_page_data", this.l1l2_page_data);

      for (var t = 0; t < this.user.Diseases.length; t++) {
        if (this.title.toLowerCase() == this.user.Diseases[t].toLowerCase()) {
          this.show_article = true;
        }
      }

      this.$forceUpdate();

      // let senddata = {
      //   company_name : this.user.Company_name,
      //   user_position : this.user.User_position,
      //   diseases: this.my_diseases,
      //   other_company: this.user.Other_company,
      //   other_position: this.user.Other_position,
      //   other_diseases: this.user.Other_diseases,
      //   plan: this.user.Plan,
      // }

      // let url = `/userProfile?token=` + this.auth_token
      // let config = { headers: { 'Content-Type': 'application/json' } }
      // axios.put(url, JSON.stringify(senddata), config).then((res) => {
      //   //console.log(res,"res")
      //   if (res.status != 500) {
      //     if (res.data.Status == 0) {
      //         //console.log("Success")
      //         localStorage.setItem("selected_disease", this.new_diseases[0].name);
      //         var new_selected_disease = this.new_diseases[0].name
      //         this.new_diseases = []
      //         this.new_dis_len = 0
      //         this.title = new_selected_disease

      //         this.$router.push("/").catch(() => {})

      //         this.l1l2_page_data = {}
      //         this.l1l2_page_data.article_title = "Events"
      //         this.l1l2_page_data.disease = new_selected_disease
      //         EventBus.$emit("l1l2_page_data", this.l1l2_page_data);

      //         for (var t = 0; t < this.user.Diseases.length; t++) {
      //           if (this.title.toLowerCase() == this.user.Diseases[t].toLowerCase()) {
      //             this.show_article = true
      //           }
      //         }

      //       //localStorage.setItem("user", JSON.stringify(senddata));
      //     } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
      //       console.log("invalid user")
      //       //this.$router.push("/").catch(() => {})
      //     } else {
      //       console.log("Error while profile updation")
      //       this.message = "Please try again"
      //     }
      //   } else {
      //     console.log(error, "error");
      //   }
      // })
      // .catch((error) => {
      //   if (error.message == "Request failed with status code 500") {
      //     console.log(error.message);
      //   }
      // });
    },
    goto_profile() {
      this.$router.push("/Profile_Settings").catch(() => {});
    },
    show_active_companies() {
      this.companies_modal = true;
      if (
        this.auth_token !== null &&
        this.auth_token !== "" &&
        this.auth_token !== undefined
      ) {
        axios
          .get(
            "/getactivecomapnies?token=" +
              this.auth_token +
              "&disease=" +
              this.title
          )
          .then((res) => {
            //console.log(res,"res")
            if (res.data.Status != 1) {
              this.companies_info = res.data.Data;
              if (this.companies_info !== null) {
                this.comp_flag = true;
                for (var m = 0; m < this.companies_info.length; m++) {
                  var txt = this.companies_info[m].Tiles[0];
                  this.companies_info[m].Tiles[0] =
                    this.companies_info[m].Tiles[1];
                  this.companies_info[m].Tiles[1] = txt;
                  this.companies_info[m].Tiles[2] =
                    this.companies_info[m].Tiles[2];
                  if (this.companies_info[m].Tiles[0].Text == "Company_Logo") {
                    var imagee = new Image();
                    if (this.companies_info[m].Tiles[0].Value != "") {
                      imagee.src =
                        "data:image/png;base64," +
                        this.companies_info[m].Tiles[0].Value;
                    } else {
                      imagee.src = "Icons/Group 671.svg";
                    }
                    this.companies_info[m].Tiles[0].company_image = imagee;
                  }
                }
              } else {
                this.comp_flag = false;
              }
            } else if (
              res.data.Status == 1 &&
              res.data.Data.error == "Invalid User"
            ) {
              console.log("Invalid User");
            } else {
              console.log("comapnies not not found");
            }
          })
          .catch((error) => {
            if (error.message == "Request failed with status code 500") {
              console.log(error.message);
            }
          });
      } else {
        console.log("no token");
        this.comp_flag = false;
      }
    },
    close_companies_modal() {
      this.companies_info = [];
      this.companies_modal = false;
    },
    goto_comapny_article(com) {
      var arti_title = "Company Details";
      //var activity_name = "Active Company Selection"

      this.$router
        .push(
          "/articleDetails?article_title=" +
            arti_title +
            "&disease=" +
            this.title +
            "&company_name=" +
            com.Article_display
        )
        .catch(() => {});
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    getdiseases_no_auth() {
      this.my_diseases = [];
      axios
        .get("/get_noauth_diseases")
        .then((res) => {
          if (res.data.Status != 1) {
            for (var i = 0; i < res.data.Data.length; i++) {
              this.my_diseases.push(res.data.Data[i].Name);
            }
            var unique_diseases = this.my_diseases.filter(this.onlyUnique);
            this.my_diseases = unique_diseases;
            this.show_article = true;
            //console.log(this.my_diseases,"my diseases")
          } else {
            console.log("Diseases not found");
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);
          }
        });
    },
    select_duration(duration) {
      this.duration = duration;
      this.closeOnContentClick = true;
      EventBus.$emit("duration", duration);
    },
    goto_industry_updates(trend) {
      this.chat_selected = false;
      this.selectedModule = false;
      this.MyModule = false;
      if (!this.no_authentication) {
        this.$router.push("/").catch(() => {});

        this.l1l2_page_data = {};
        this.l1l2_page_data.article_title = "Events";
        this.l1l2_page_data.disease = this.title;
        EventBus.$emit("l1l2_page_data", this.l1l2_page_data);
      } else {
        this.$router.push("/").catch(() => {});
        this.l1l2_page_data = {};
        this.l1l2_page_data.article_title = "Events";
        this.l1l2_page_data.disease = "Multiple sclerosis";
        EventBus.$emit("l1l2_page_data", this.l1l2_page_data);
      }
    },
    go_to_article(ar) {
      this.chat_selected = false;
      this.selectedModule = false;
      this.MyModule = false;

      this.note_selected = false;
      this.pricing_selected = false;

      // // var old_selected = localStorage.getItem("selected_article");
      // var old_selected = this.selected_page
      // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //   localStorage.setItem("old_selected_article", old_selected);
      // } else {
      //   old_selected = "Industry Updates"
      //   localStorage.setItem("old_selected_article", old_selected);
      // }

      // localStorage.setItem("selected_article", ar.Title_Value);
      for (var i = 0; i < this.insights_articles.length; i++) {
        if (ar.Title_Value == this.insights_articles[i].Title_Value) {
          ar.selected = true;
        } else {
          this.insights_articles[i].selected = false;
        }
      }

      if (ar.Title_Value == "Industry Updates") {
        this.selectedModule = false;
        this.goto_industry_updates(ar);
      } else if (
        ar.Title_Value == "Therapy Landscape" ||
        ar.Title_Value == "Clinical Review" ||
        ar.Title_Value == "Development Pipeline" ||
        ar.Title_Value == "Active Companies"
      ) {
        if (!this.no_authentication) {
          this.$router
            .push(
              "/articleDetails?article_title=" +
                ar.Title_Value +
                "&disease=" +
                this.title
            )
            .catch(() => {});
          this.page_data = {};
          this.page_data.article_title = ar.Title_Value;
          this.page_data.disease = this.title;
          EventBus.$emit("page_data", this.page_data);
          this.check_article_type();
        } else {
          var arti_title = ar.Title_Value;
          this.$router
            .push(
              "/articleDetails?article_title=" +
                arti_title +
                "&disease=" +
                "Multiple sclerosis"
            )
            .catch(() => {});
          this.page_data = {};
          this.page_data.article_title = ar.Title_Value;
          this.page_data.disease = this.title;
          EventBus.$emit("page_data", this.page_data);
          this.check_article_type();
        }
      } else if (ar.Title_Value == "Mechanism of Action") {
        this.selected_article = "Mechanism of Action";
        // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
        //   localStorage.setItem("old_selected_article", old_selected);
        // } else {
        //   old_selected = "Industry Updates"
        //   localStorage.setItem("old_selected_article", old_selected);
        // }
        // localStorage.setItem("selected_article", "Mechanism_Module");
        var moduleId = "64004be2fda0a506a4e3c305";
        this.$router.push("/mechanismModule?moduleId=" + moduleId);
      } else if (
        ar.Title_Value == "Biomarker" ||
        ar.Title_Value == "Patient Response"
      ) {
        if (ar.Title_Value == "Biomarker") {
          var module_id = "63f45ebc6910da5747280902";
          var module_name = "Biomarker";
          this.selected_article = "Biomarker";
        } else if (ar.Title_Value == "Patient Response") {
          var module_id = "63f45ebc6910da5747280802";
          var module_name = "Patient Response";
          this.selected_article = "Patient Response";
        }
        EventBus.$emit("module_name", module_name);
        localStorage.setItem("selected_module", module_name);
        let url = "/overviewbyIndication/" + module_id + "/" + module_name;
        //let url="/geknowmeModule?moduleId="+module_id+"&moduleName="+module_name
        //  this.move({ name: 'Geknowme_Module' },url)
        //this.routerPush(url)
        //this.$router.push("/geknowmeModule?moduleId="+module_id+"&moduleName="+module_name)
        // console.log("heloooo")
        this.$router.push(url);
        var module_data = {};
        module_data.id = module_id;
        module_data.name = module_name;
        EventBus.$emit("module_data", module_data);
      }

      this.$forceUpdate();
    },
    routerPush(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    show_notes() {
      this.note_selected = true;
      this.pricing_selected = false;
      this.chat_selected = false;
      // // var old_selected = localStorage.getItem("selected_article");
      // var old_selected = this.selected_page
      // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //   localStorage.setItem("old_selected_article", old_selected);
      // } else {
      //   old_selected = "Industry Updates"
      //   localStorage.setItem("old_selected_article", old_selected);
      // }
      var notes_page = "Notes";
      // localStorage.setItem("selected_article", notes_page);
      for (var i = 0; i < this.insights_articles.length; i++) {
        this.insights_articles[i].selected = false;
      }
      this.$router.push("/notes/" + this.title).catch(() => {});
    },
    onCancel() {
      console.log("User cancelled the loader.");
    },

    // to fetch basic price of product
    fetch_data() {
      axios
        .get(`/config?token=` + this.auth_token)
        .then((res) => {
          if (res.data.Status !== 1) {
            var basicPrice = res.data.basicPrice;
            // console.log(basicPrice,"basicPrice")
            // const basicPriceInput = document.querySelector('#basicPrice');
            // basicPriceInput.value = basicPrice;
            // const proPriceInput = document.querySelector('#proPrice');
            // proPriceInput.value = proPrice;
          }
        })
        .catch((err) => {
          throw err;
        });
    },
    goto_wishlist() {
      this.pricing_selected = false;
      // // var old_selected = localStorage.getItem("selected_article");
      // var old_selected = this.selected_page
      // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //   localStorage.setItem("old_selected_article", old_selected);
      // } else {
      //   old_selected = "Industry Updates"
      //   localStorage.setItem("old_selected_article", old_selected);
      // }
      var my_page = "My wishlist";
      // localStorage.setItem("selected_article", my_page);
      for (var i = 0; i < this.insights_articles.length; i++) {
        this.insights_articles[i].selected = false;
      }

      this.$router.push("/Userwishlist").catch(() => {});
    },
    //Modules related Methods
    go_to_ModuleLibray() {
      this.selectedModule = true;
      this.MyModule = false;
      this.sortedInsights.selected = false;
      this.chat_selected = false;
      this.note_selected = false;
      // var old_selected = localStorage.getItem("selected_article");
      // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //   localStorage.setItem("old_selected_article", old_selected);
      // } else {
      //   old_selected = "Industry Updates"
      //   localStorage.setItem("old_selected_article", old_selected);
      // }
      var my_page = "Modules_library";
      // localStorage.setItem("selected_article", my_page);
      for (var i = 0; i < this.insights_articles.length; i++) {
        this.insights_articles[i].selected = false;
      }

      // console.log(this.selectedModule,"selectedModule")

      if (!this.no_authentication) {
        this.$router.push("/").catch(() => {});
      } else {
        //var arti_title = ar.Title_Value;
        this.$router.push("/").catch(() => {});
      }

      $('[data-toggle="tooltip"]').tooltip("hide");
    },
    go_to_MyModules() {
      this.selectedModule = false;
      this.sortedInsights.selected = false;
      this.MyModule = true;
      this.note_selected = false;
      // var old_selected = localStorage.getItem("selected_article");
      // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //   localStorage.setItem("old_selected_article", old_selected);
      // } else {
      //   old_selected = "Industry Updates"
      //   localStorage.setItem("old_selected_article", old_selected);
      // }
      var my_page = "My_Modules";
      // localStorage.setItem("selected_article", my_page);
      for (var i = 0; i < this.insights_articles.length; i++) {
        this.insights_articles[i].selected = false;
      }

      if (!this.no_authentication) {
        this.$router.push("/myModules").catch(() => {});
      } else {
        this.$router.push("/myModules").catch(() => {});
      }
    },
    getPinnedTabs() {
      axios
        .get(`/getPinnedTabs?token=` + this.auth_token)
        .then((res) => {
          if (res.data.Status !== 1) {
            //console.log(res.data.Data,"PinnedModules")
            if (res.data.Data.PinnedModules != null) {
              for (let i = 0; i < res.data.Data.PinnedModules.length; i++) {
                if (res.data.Data.PinnedModules[i].ID != "") {
                  let pinned = {};
                  pinned.Title_Value = res.data.Data.PinnedModules[i].Name;
                  pinned.Title = res.data.Data.PinnedModules[i].Name;
                  pinned.Number = this.insights_articles.length + 1;
                  this.insights_articles.push(pinned);
                }
              }
            }
            let pinnedArticles = [];
            if (res.data.Data.PinnedArticles != null) {
              pinnedArticles.push(res.data.Data.PinnedArticles);
            }

            //console.log(pinnedArticles,"pinnedArticles")

            //match values from pinnedArticles and insights_articles and if matched remove from insights_articles
            if (pinnedArticles.length > 0) {
              for (let i = 0; i < pinnedArticles.length; i++) {
                for (let j = 0; j < pinnedArticles[i].length; j++) {
                  for (let k = 0; k < this.insights_articles.length; k++) {
                    if (pinnedArticles[i][j] == "Therapy Landscape") {
                      continue;
                    }
                    if (
                      pinnedArticles[i][j] ==
                      this.insights_articles[k].Title_Value
                    ) {
                      this.insights_articles.splice(k, 1);
                    }
                  }
                }
              }
            }
          }
        })
        .catch((err) => {
          throw err;
        });
    },
    userExists(username, arr) {
      return arr.some(function (el) {
        //  console.log(el)
        //  console.log(el.Title_Value)
        //  console.log(el.Title_Value,username)
        console;
        return el.Title_Value === username;
      });
    },

    show_pricing() {
      this.pricing_selected = true;
      // var old_selected = localStorage.getItem("selected_article");
      // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //   localStorage.setItem("old_selected_article", old_selected);
      // } else {
      //   old_selected = "Industry Updates"
      //   localStorage.setItem("old_selected_article", old_selected);
      // }
      var pricing_page = "Pricing";
      // localStorage.setItem("selected_article", pricing_page);
      for (var i = 0; i < this.insights_articles.length; i++) {
        this.insights_articles[i].selected = false;
      }
      if (
        this.auth_token !== null &&
        this.auth_token !== "" &&
        this.auth_token !== undefined
      ) {
        this.$router.push("/User_pricing").catch(() => {});
      } else {
        this.$router.push("/pricing").catch(() => {});
      }
    },
    goto_myplan() {
      this.pricing_selected = true;
      // var old_selected = localStorage.getItem("selected_article");
      // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //   localStorage.setItem("old_selected_article", old_selected);
      // } else {
      //   old_selected = "Industry Updates"
      //   localStorage.setItem("old_selected_article", old_selected);
      // }
      var my_page = "My Plan";
      // localStorage.setItem("selected_article", my_page);
      for (var i = 0; i < this.insights_articles.length; i++) {
        this.insights_articles[i].selected = false;
      }
      if (this.myplan == "Trial") {
        this.$router.push("/").catch(() => {});
      } else if (this.myplan == "Basic Plan") {
        this.$router.push("/Subscriptions").catch(() => {});
      } else {
        this.$router.push("/User_pricing").catch(() => {});
      }
    },
    get_subscription_status(plan) {
      axios
        .get("/getusersubscription?token=" + this.auth_token + "&plan=" + plan)
        .then((res) => {
          if (res.data.Status !== 1) {
            var sub = res.data.Data.subscription;
            //console.log(sub,"sub")
            this.no_add_diseases = false;
            this.user_approved = true;
            for (var m = 0; m < this.user.Diseases.length; m++) {
              if (
                this.title.toLowerCase() == this.user.Diseases[m].toLowerCase()
              ) {
                this.show_article = true;
              }
            }
          } else if (
            res.data.Status == 1 &&
            res.data.Data.error == "Invalid User"
          ) {
            console.log("Invalid User");
          } else {
            //console.log("User subscription not found")
            this.subscription_failed = true;
            // this.subscription_failed_modal = true
            this.no_add_diseases = true;
            this.user_approved = false;
            var user_dis = ["Multiple sclerosis"];
            this.set_disease(user_dis);
            if (this.title.toLowerCase() == "Multiple sclerosis") {
              this.show_article = true;
            }
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);
          }
        });
    },
    subscribe_again() {
      this.subscription_failed_modal = false;
      this.pricing_selected = true;
      // var old_selected = localStorage.getItem("selected_article");
      // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
      //   localStorage.setItem("old_selected_article", old_selected);
      // } else {
      //   old_selected = "Industry Updates"
      //   localStorage.setItem("old_selected_article", old_selected);
      // }
      var pricing_page = "Pricing";
      // localStorage.setItem("selected_article", pricing_page);
      for (var i = 0; i < this.insights_articles.length; i++) {
        this.insights_articles[i].selected = false;
      }

      this.$router.push("/User_pricing").catch(() => {});
    },
    set_disease(user_dis) {
      return;
      //console.log(user_dis,"user_dis")
      for (var a = 0; a < this.alldiseases.length; a++) {
        for (var b = 0; b < user_dis.length; b++) {
          if (this.alldiseases[a].Name == user_dis[b]) {
            this.my_diseases.push(this.alldiseases[a].Name);
            this.setUpper(this.alldiseases[a].Name);
          }
        }
      }
      //console.log(this.my_diseases,"this.my_diseases")

      var unique_diseases = this.my_diseases.filter(this.onlyUnique);
      this.my_diseases = unique_diseases;

      if (this.my_diseases.length > 0) {
        var dis = this.my_diseases.sort(function (a, b) {
          var textA = a.toUpperCase();
          var textB = b.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        this.my_diseases = dis;
      }
      var matched_title = false;

      if (
        this.title == "" ||
        this.title == undefined ||
        this.title == null ||
        this.title == "undefined"
      ) {
        this.title = this.my_diseases[0];
      }

      var available_diseases = this.temp_diseases;
      for (var t = 0; t < available_diseases.length; t++) {
        for (var m = 0; m < this.my_diseases.length; m++) {
          if (
            available_diseases[t].name.toLowerCase() ==
            this.my_diseases[m].toLowerCase()
          ) {
            var spliced = available_diseases.splice(t, 1);
          }
        }
      }

      this.diseases = available_diseases;

      // localStorage.setItem("selected_disease", this.title);
      // localStorage.setItem("diseases", this.my_diseases);
      var wishlist_array = [];
      for (var f = 0; f < this.new_diseases.length; f++) {
        wishlist_array.push(this.new_diseases[f].name);
      }
      // localStorage.setItem("seldis", wishlist_array);
    },
    //chat related functions

    showque() {
      if (this.$route.name == "User_plan_payment") {
        //console.log("user plan paymenttttttttttt")
        this.show_diseases = false;
      }
      if (this.$route.name === "sharewebarticle") {
        // console.log("Unsubscribe_mail")
        this.show_login = false;
      } else {
        this.show_login = true;
      }

      if (this.$route.name == "Support") {
        // console.log("supporttttttt")
        this.show_diseases = false;
        this.show_notes_icon = false;
      }
      if (this.$route.name == "Geknowme_Module") {
        // console.log("Geknowmeeee")
        this.show_diseases = true;
      } else {
        this.show_diseases = false;
      }
      if (this.$route.name === "Unsubscribe_mail") {
        // console.log("Unsubscribe_mail")
        this.show_settings = false;
      } else {
        this.show_settings = true;
      }
      this.CheckPlans();

      // console.log(this.myplan,"myplan")
      // console.log("showque")
      this.MyModule = false;
      this.selectedModule = false;
      this.chat_selected = true;

      this.note_selected = false;
      //call anlaytical_Chat function in QnAModal component
      if (this.myplan == "Trial") {
        if (
          this.planData.Trial_exceeded == true ||
          this.planData.Trial_remaining_days <= 0
        ) {
          this.queana_modal = false;
        } else {
          this.queana_modal = true;
        }
      } else if (this.myplan == "Basic Plan") {
        if (this.user_plan == false) {
          this.queana_modal = true;
        } else {
          this.queana_modal = false;
        }
      } else {
        console.log("other plan");
      }

      this.$refs.QnAModal.analytical_Chat(this.queana_modal);
      // this.$forceUpdate();
    },

    get_user_plan() {
      //console.log(this.auth_token,"dfgdfg")

      if (this.$route.name == "User_plan_payment") {
        // console.log("user plan paymenttttttttttt")
        this.show_diseases = false;
      }
      if (this.$route.name === "sharewebarticle") {
        // console.log("Unsubscribe_mail")
        this.show_login = false;
      } else {
        this.show_login = true;
      }

      if (this.$route.name == "Support") {
        // console.log("supporttttttt")
        this.show_diseases = false;
        this.show_notes_icon = false;
      }
      if (this.$route.name == "Geknowme_Module") {
        // console.log("Geknowmeeee")
        this.show_diseases = true;
      } else {
        this.show_diseases = false;
      }
      if (this.$route.name === "Unsubscribe_mail") {
        // console.log("Unsubscribe_mail")
        this.show_settings = false;
      } else {
        this.show_settings = true;
      }

      //create get request to get user plan
      axios
        .get("/getUserPlan?token=" + this.auth_token)
        .then((res) => {
          //console.log(res)
          if (res.data.Status != 1) {
            //  console.log(res.data,"user plan")
            if (res.data.Data.Plan == true) {
              this.user_plan = true;
              //  console.log("no plan")
              if (this.gotochat == true) {
                if (this.isTabletMob == true) {
                  // console.log("tablettttt ")
                  this.$router.push({ name: "Analytical_chat_tab" });
                } else {
                  // console.log("tablettttt nottt ")
                  this.$router.push({ name: "Analytical_Chat" });
                }
              }
              //  console.log(this.user_plan,"getttt user plannnnn")
              this.$forceUpdate();
            } else {
              if (this.gotochat == true) {
                this.$refs.SubscriptionModal.openPopup();
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSelectedText() {
      let selection = document.getSelection();
      // console.log(selection,"selection")
      let selRange = selection.getRangeAt(0);
      // console.log(selRange,"selRange")
      return selRange;
    },
    CheckPlans() {
      this.planData = {};
      axios
        .get("/getUserPlans?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status !== 1) {
            this.planData = res.data.Data;
            //console.log(this.planData,"this.planData")
            this.myplan = this.planData.Current_plan;

            if (this.planData.Current_plan == "No Plan") {
              this.show_article = false;
              this.user.Diseases = [];
              this.planData.Show_disease_select_opt = false;
              this.$forceUpdate();
            }
            if (this.planData.Trial_exceeded) {
              this.show_article = false;
              this.user.Diseases = [];
              this.$forceUpdate();
            }
            if (this.myplan == "Trial") {
              // console.log("trialllllll")
              this.check_trial_period();
            }
            //console.log(this.planData,"this.planData")
          } else if (
            res.data.Status == 1 &&
            res.data.Data.error == "Invalid User"
          ) {
            console.log("Invalid User");
            this.logOut();
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);
            this.title = "Multiple sclerosis";
            // localStorage.setItem("selected_disease", this.title);
          }
        });
    },
    trialDisease(dis) {
      if (dis.status == "Selected") {
        for (var d = 0; d < this.temp_diseases.length; d++) {
          if (this.temp_diseases[d].name == dis.name) {
            this.temp_diseases[d].status = "Not-selected";
          }
        }
      } else {
        for (var d = 0; d < this.temp_diseases.length; d++) {
          if (this.temp_diseases[d].name == dis.name) {
            this.temp_diseases[d].status = "Selected";
          } else {
            this.temp_diseases[d].status = "Not-selected";
          }
        }
      }
    },
    SaveTrialDisease() {
      let selected_disease = "";
      for (var d = 0; d < this.temp_diseases.length; d++) {
        if (this.temp_diseases[d].status == "Selected") {
          selected_disease = this.temp_diseases[d].name;
          break;
        }
      }
      if (selected_disease == "") {
        return;
      }
      // console.log(selected_disease,"selected_diseaseeeee")
      axios
        .put(
          "/userTrialPlan?token=" +
            this.auth_token +
            "&disease=" +
            selected_disease
        )
        .then((res) => {
          // console.log(res.data.Data,"ressss")
          if (res.data.Status !== 1) {
            this.planData.Show_disease_select_opt = false;
            this.user.Diseases.push(selected_disease);
            this.my_diseases.push(selected_disease);
            this.setUpper(selected_disease);
            // localStorage.setItem("diseases", this.my_diseases);
            this.get_disease_name(selected_disease, "Trial");
            this.planData.Current_plan = "Trial";
            this.title = selected_disease;
          } else if (
            res.data.Status == 1 &&
            res.data.Data.error == "Invalid User"
          ) {
            console.log("Invalid User");
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);
          }
        });
    },
    setUpper(disease) {
      this.mydisease_count += 1;
      if (this.alldiseases != null && this.alldiseases.length > 0) {
        for (let i = 0; i < this.alldiseases.length; i++) {
          if (this.alldiseases[i].Name.toLowerCase() == disease.toLowerCase()) {
            this.alldiseases[i].My_disease = true;
          } else {
            if (this.alldiseases[i].My_disease != true) {
              this.alldiseases[i].My_disease = false;
            }
          }
        }
      }
      this.$forceUpdate();
    },
    check_trial_period() {
      // console.log(this.planData,"the plan")
      this.days_remaining = this.planData.Trial_remaining_days;
      var activity_name = "Email Sent";
      axios
        .get("/getactivity?activity_name=" + activity_name)
        .then((res) => {
          // console.log("registereeddddd")
          if (res.data.Status != 1) {
            var activities = res.data.Data.activities;
            var email_count = 0;
            var todays_email_sent = false;
            if (activities !== null) {
              for (var m = 0; m < activities.length; m++) {
                if (activities.Text !== "") {
                  email_count++;
                }
                var act_date = new Date(activities[m].Created_at),
                  act_month = "" + (act_date.getMonth() + 1),
                  act_day = "" + act_date.getDate(),
                  act_year = act_date.getFullYear();
                // console.log("::c",act_date)
                var activity_date = [act_day, act_month, act_year].join("-");
                // console.log("activity",activity_date)
                var curr_d = new Date(),
                  month = "" + (curr_d.getMonth() + 1),
                  day = "" + curr_d.getDate(),
                  year = curr_d.getFullYear();
                // console.log("::::t",curr_d)
                var today = [day, month, year].join("-");
                // console.log("today",today)
                var timeDifferenceInMilliseconds = curr_d - act_date;
                var durationInHours =
                  timeDifferenceInMilliseconds / (1000 * 60 * 60);

                // console.log("Duration in hours:", durationInHours);
                //console.log(activity_date, today,"today")
                if (durationInHours <= 24) {
                  todays_email_sent = true;
                } else {
                  todays_email_sent = false;
                }
              }
            }
            // console.log(email_count,"email_count")
            if (!todays_email_sent) {
              if (activities == null || email_count <= 3) {
                if (
                  this.days_remaining == 7 ||
                  this.days_remaining == 3 ||
                  this.days_remaining == 0
                ) {
                  axios
                    .post(
                      "/sendreminderemail?token=" +
                        this.auth_token +
                        "&remaining_days=" +
                        this.days_remaining
                    )
                    .then((res) => {
                      if (res.data.Status != 1) {
                        console.log("email send successfully");
                        this.save_email_activity();
                      } else {
                        console.log("email failed");
                      }
                    });
                }
              }
            }
          } else if (
            res.data.Status == 1 &&
            res.data.Data.error == "Invalid User"
          ) {
            console.log("Invalid User");
          } else {
            console.log("Activity not found");
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);
          }
        });
    },
    save_email_activity() {
      var article_id = "";
      var activity_name = "Email Sent";
      var disease = this.title;
      var day = this.days_remaining;
      axios
        .post(
          "/SaveActivity?id=" +
            article_id +
            "&token=" +
            this.auth_token +
            "&activity_name=" +
            activity_name +
            "&disease=" +
            disease +
            "&day=" +
            day
        )
        .then((res) => {
          if (res.data.Status != 1) {
            console.log("Activity saved successfully");
          } else if (
            res.data.Status == 1 &&
            res.data.Data.error == "Invalid User"
          ) {
            console.log("Invalid User");
          } else {
            console.log("Activity save failed");
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);
          }
        });
    },
    move(params, fullPath) {
      // console.log(params,fullPath,"params fullPath")
      // get comparable fullPaths
      let from = this.$route.fullPath;
      let to = this.$router.resolve(params).fullPath;

      // console.log(from,to,"from to")
      if (from === to) {
        // handle any error due the redundant navigation here
        // or handle any other param modification and route afterwards
        return;
      }

      // route as expected
      this.$router.push(params);
    },
    goto_register() {
      this.$router.push("/Pricing").catch(() => {});
    },
    openSupport() {
      let route = this.$router.resolve({ path: "/Support" });
      // let route = this.$router.resolve('/link/to/page'); // This also works.
      EventBus.$emit("module_name", "");
      localStorage.setItem("selected_module", "");
      window.open(route.href, "_blank");
    },
    go_to_pricing() {
      this.user_plan = false;
      this.$router.push("/User_pricing");
    },
    gopricingpage() {
      this.$router.push("/User_pricing").catch(() => {});
    },
    get_page_activity() {
      axios
        .get("/fetchIndicationSelected?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status != 1) {
            // console.log(res.data,"response.data")
            this.title = res.data.Data.Indication;
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);
          }
        });
    },
    getSampleKey() {
      axios.get("/getSampleKey").then((res) => {
        if (res.data.value !== 1) {
          // console.log("Success")
          this.sampleKey = res.data.Data.Key;
          this.$router.push("/Sample?sample=" + this.sampleKey).catch(() => {});
        }
      });
    },
    get_page_info() {
      axios
        .get("/fetchCurrentPage?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status != 1) {
            // console.log(res.data,"response.data")
            this.selected_page = res.data.Data.Current_Page;
            this.old_selected_page = res.data.Data.Previous_Page;

            // var selected_article =this.selected_page

            // console.log(this.selected_page,"sqdfgf",this.old_selected_page)
            //get old selected article from local storage
            // var old_selected_article = localStorage.getItem("old_selected_article");
            if (
              this.selected_page == "Mech_Module" &&
              this.old_selected_page == "Mechanism_Module"
            ) {
              // localStorage.setItem("selected_article","Mechanism_Module");
              // console.log("aaaaaaaaa")
              this.$router
                .push("/MechanismModule?moduleId=" + this.current_module_id)
                .catch(() => {});

              //set selected article Mechanism_Module in local storage
            } else if (
              this.selected_page == "Mech_Module" &&
              this.old_selected_page == "Custom_Module"
            ) {
              // localStorage.setItem("selected_article","Custom_Module");
              this.$router
                .push("/CustomModule?moduleId=" + this.current_module_id)
                .catch(() => {});
            }
            //if selected article is mechanism module
            else if (this.selected_page == "Mechanism_Module") {
              // console.log("bbbbbbb")
              this.$router.push("/").catch(() => {});
            } else if (this.selected_page == "Custom_Module") {
              this.$router.push("/").catch(() => {});
            } else {
              console.log("ccccccc");
              this.$router.push("/").catch(() => {});
            }
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);
          }
        });
    },
    getEmail() {
      //create a get request to get email
      axios
        .get("/getEmail?token=" + this.auth_token)
        .then((res) => {
          //console.log(res)
          if (res.data.Status != 1) {
            this.email = res.data.Data.Email;
            this.mail_initials = this.initials(this.email);
            // console.log(this.email,"email")
          } else {
            console.log("not logged in");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    initials(email) {
      let name = email.substring(0, email.lastIndexOf("@"));
      if (name.includes(".")) {
        var names = email.split(".");
        if (names.length > 0) {
          var initials_l = (
            names[0].substring(0, 1) + names[1].substring(0, 1)
          ).toUpperCase();
          return initials_l;
        }
      } else {
        var names = email.split(" ");
        var initials_l = names[0].substring(0, 2).toUpperCase();
        return initials_l;
      }
    },
  },
};
</script>

<style scoped>
h6,
h5 {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #009ea5;
}

h5 {
  width: 55px;
  height: 26px;
  margin: -10px 0px -10px 0;
  font-size: 22px;
  border-bottom: 3px solid #009ea5;
}

.v-sheet.v-app-bar.v-toolbar {
  background-color: #013d48 !important;
  height: 9vh !important;
  /* width: 75%;
  left: 25% !important; */
  /* float: right;
  right: 0px !important; */
  position: absolute;
}

.row.no-gutters {
  /* margin-left: 30%; */
  margin-top: 42px !important;
}

.v-list-item:hover {
  cursor: pointer;
}

.v-menu__content {
  border: 1px solid#009ea5;
  font-size: 18px;
  font-weight: 600;
}

.my-text-style >>> .v-text-field__slot input {
  color: white !important;
  border-color: #ffffff;
}

.v-label {
  color: white !important;
  opacity: 1;
}

.custom-label-color input {
  color: white !important;
}

.fixed-bar {
  position: sticky;
  z-index: 6;
}

.knolheading {
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer;
  text-underline-position: under;
  line-height: normal;
  text-align: left;
  margin-left: 3vw;
  margin-top: 2vh !important;
}

.select-field {
  font-size: 3vh;
  color: #fff !important;
  border-color: #fff !important;
  background-color: #013d48 !important;
  color: #ffffff;
  font-family: Ubuntu;
  font-weight: 500;
  text-transform: capitalize;
}

.select-duration {
  font-size: 2.7vh;
  color: #ff6750 !important;
  border-color: #fff !important;
  background-color: #013d48 !important;
  font-family: Ubuntu;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
}

.active_com {
  font-size: 16px;
  color: #ff6750 !important;
  background-color: inherit !important;
  font-family: Ubuntu;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
}
.active_com1111 {
  font-size: 15px;
  color: #ff6750 !important;
  background-color: inherit !important;
  font-family: Ubuntu;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.notes {
  font-size: 17px;
  color: #ff6750 !important;
  background-color: inherit !important;
  font-family: Ubuntu;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
}

.imgBox {
  height: 33px;
  width: 33px;
  margin-left: 50px;
  margin-top: 5px;
}

.active_companies {
  font-family: Ubuntu;
  font-size: 2vh;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff !important;
  margin: 1vh 0vh 0vh 2vh;
  cursor: pointer;
  background-color: #ff6750 !important;
  height: 5vh !important;
  float: right;
}

.userProfileBtn {
  font-family: Ubuntu;
  font-size: 1.75vh;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
}
.supportBtn {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
}
.searchText123 {
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-top: 7px;
}

.Sclerosis {
  width: 123px;
  height: 41px;
  margin: 15px 3.5px 0 0;
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ff6750 !important;
}

.select_disease {
  width: 100%;
  margin: 0vh 2vh 0vh 5vh;
  color: #ffffff !important;
}

.lifesciences {
  height: 41px;
  margin: 8px 3.5px 0 3vw;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #14adb5;
}

.aa {
  background: #009ea5 !important;
  font-family: Ubuntu;
  font-size: 15px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff !important;
  text-transform: none;
}

.bb {
  background: #009ea5 !important;
  font-family: Ubuntu;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff !important;
}

.v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input {
  color: white !important;
}

.dropdown {
  color: white;
  border-color: transparent;
  margin-top: 10px;
  border: transparent;
}

.dropdown:hover {
  color: white !important;
}

.input-group {
  height: 22px;
}

form,
input,
label,
p {
  color: white !important;
}

.form-control {
  color: white !important;
}
.menu1 {
  margin-left: 46vh;
  position: absolute;
  background: none;
  border-radius: 6px;
  cursor: pointer;
  padding: 3px 8px 2px 8px;
}
.menu1 i {
  color: #fff;
  cursor: pointer;
}
.drop-down-menus {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: #013d48 !important;
}

.dropdownStyle {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 600;
  color: #49575d !important;
  text-transform: capitalize !important;
}

.theme--light.v-list-item--active:before,
.theme--light.v-list-item--active:hover:before,
.theme--light.v-list-item:focus:before {
  opacity: 0;
  background-color: #ffffff !important;
  color: #ffffff;
}

.Please-select {
  width: 100%;
  margin: 2vh 52.3px 34px 6.3px;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #384348 !important;
}

.Path-85 {
  box-shadow: none !important;
  margin: 2vh;
  padding: 1vh 3vh 2vh 3vh;
  border: solid 0.6px #d3d3d3 !important;
  background-color: #fdfefe;
  height: 40vh;
  border-radius: 7px !important;
}

.com_nm {
  margin-top: 3vh;
  font-size: 2.2vh;
  font-weight: 700;
  font-family: Ubuntu;
  text-align: center;
  color: #383838 !important;
  height: 4vh;
}

.com_nm1 {
  margin-top: 5vh;
  font-size: 2vh;
  font-weight: 700;
  font-family: Ubuntu;
  text-align: center;
  color: #383838 !important;
  height: 6vh;
}

.com_des {
  font-size: 2vh;
  font-weight: 500;
  font-family: Ubuntu;
  color: #383838 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  height: 7vh;
  margin-bottom: 2vh;
}

.learn {
  text-align: center;
  color: #00959b !important;
  font-weight: 700;
  cursor: pointer;
  margin-top: 10vh;
}

.vertical-center {
  margin: 0;
  width: 98%;
  position: absolute;
  top: 45%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.Industry-Updates-text {
  margin: 2vh 2vh 2vh 1vh;
  font-family: Ubuntu;
  font-size: 1.7vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center !important;
  color: #fff;
  cursor: pointer;
}

.sidebar_option_selection {
  padding: 0px 10px 0px 10px;
  margin-top: 10px !important;
  text-align: left;
  cursor: pointer;
  width: 100%;
}
.sidebar_option_selection_modules {
  padding: 0.1px 0.1px 0.1px 0px;
  margin-top: 10px !important;
  margin-left: 0px;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
  width: 80%;
  position: absolute;
  bottom: 0;
}

.profileBtnPosition {
  position: absolute;
}
.nav-Drawer {
  margin-left: 0vw;
  overflow-y: hidden;
  margin-top: 7.5vh;
  max-height: 100%;
}
.com_nm2 {
  margin-top: 2vh;
  font-size: 17px;
  font-weight: 500;
  font-family: Ubuntu;
  text-align: left;
  color: #383838 !important;
}
.com_nm3 {
  margin-top: 3vh;
  font-size: 16px;
  font-weight: 500;
  font-family: Ubuntu;
  text-align: left;
  color: #5e5e5e !important;
  line-height: 1.8;
}
.Active_Companies_title {
  margin: 2vh 2vh 2vh 0vh;
  font-family: Ubuntu;
  font-size: 2.5vh;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: left;
  color: #ff6750;
}

.Path-687 {
  padding: 2.6px 3px 2.6px 3px;
  background-color: #d04d3b;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  font-size: 14px;
  text-align: center;
  margin-left: 4vh;
  font-family: Ubuntu;
}
.Path-6871 {
  padding: 2.6px 3px 2.6px 3px;
  background-color: #d04d3b;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  font-size: 14px;
  float: right !important;
  font-family: Ubuntu;
}
.Path-68717 {
  padding: 5px 20px 5px 20px;
  background-color: #d04d3b;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  font-size: 2vh;
  float: right !important;
  font-family: Ubuntu;
  cursor: pointer;
}

table tbody {
  display: table;
  width: 100%;
}

table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  width: 98%;
  margin: 2vh;
  max-height: 90vh;
  overflow-y: auto;
  table-layout: fixed;
  border-collapse: collapse;
}

td {
  padding: 2vh;
  font-size: 16px;
  font-weight: 500;
  font-family: Ubuntu;
  text-align: left;
  color: #383838 !important;
  border: 1px solid #d7dbdd;
}

tr {
  border-top: 1px solid #d7dbdd;
  font-size: 16px;
  color: #333;
  white-space: pre;
  background-color: #ffffff;
}

th {
  text-align: center;
  padding: 1vh;
  background-color: #ebf3f7;
  font-family: Ubuntu;
  color: #383838 !important;
  font-weight: 600;
  border: 1px solid #d7dbdd;
}

.pricing {
  font-size: 16px;
  color: #ff6750 !important;
  background-color: inherit !important;
  font-family: Ubuntu;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
  text-align: center;
  margin-left: 3vw;
}
.selected_pricing {
  font-size: 15px;
  color: #ffffff !important;
  background-color: inherit !important;
  font-family: Ubuntu;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
  text-align: center;
  margin-left: 3vw;
  border-radius: 7px;
  background-color: #f16753 !important;
  padding: 6px 12px 6px 12px;
  cursor: pointer;
}

.user_pricing {
  font-size: 15px;
  color: #f16753 !important;
  background-color: inherit !important;
  font-family: Ubuntu;
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
  border-radius: 7px;
  background-color: inherit;
  padding: 6px 12px 6px 12px;
  cursor: pointer;
  float: right;
  margin-top: -1.7vh;
}
.user_selected_pricing {
  font-size: 15px;
  color: #ffffff !important;
  background-color: inherit !important;
  font-family: Ubuntu;
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
  border-radius: 7px;
  background-color: #f16753 !important;
  padding: 6px 12px 6px 12px;
  cursor: pointer;
  float: right;
  margin-top: -1.7vh;
}
.notesselect {
  border-radius: 6px;
  background-color: #f16753;
  padding: 4px 15px 4px 15px;
  cursor: pointer;
  width: 45px;
  float: right;
  margin-top: -1vh !important;
}
.chatselect {
  border-radius: 6px;
  background-color: #f16753;
  cursor: pointer;
}

.notesunselect {
  border-radius: 6px;
  background-color: inherit;
  padding: 4px 15px 4px 15px;
  cursor: pointer;
  width: 45px;
  float: right;
  margin-top: -1vh !important;
}
.view_cart {
  margin: 1vh;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: #03a6ad;
}
.Cat-Scratch-Disease {
  margin: 0vh 2vh 2vh 0vh;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}
.dotted_box1 {
  padding: 0vh 1vh 1vh 1vh;
  border-top: 1px dotted #384348;
  background-color: inherit;
}
.dotted_box2 {
  padding: 0vh 1vh 1vh 1vh;
  border-top: 1px dotted #384348;
  background-color: #f0feff;
}
.Path-8 {
  margin: 0vh 2vh 2vh 2vh;
  padding: 1vh;
  border: solid 1px #008489;
  background-color: #fff;
  border-radius: 7px;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #008489;
}

.Select-Diseasee {
  margin: 1vh 2vh 2vh 0vh;
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.27;
  letter-spacing: normal;
  text-align: left;
  color: #44446c;
}
.circle {
  width: 20px;
  height: 20px;
  margin: 0vh 0vh 0vh 0vh;
  border-radius: 20px;
  border: solid 1px #676772;
  background-color: inherit;
}

.further_section {
  margin: 2vh 2vh 2vh 2vh;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.78;
  letter-spacing: normal;
  text-align: center;
  color: #4e5558;
}

.vl {
  border-left: 1px solid #676772;
  height: 60vh;
  /* Modules styling */
}
.Line-202 {
  height: 1px;
  margin-right: 1vh;
  background-color: #fff;
  border-top: 2px dashed;
}
.Line-Module {
  width: 85px;
  height: 1px;
  margin: 10.2px 12.5px 0 0.5px;
  background-color: #fff;
}
.ask_que_sec {
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.89;
  letter-spacing: normal;
  text-align: left;
  color: #202527;
  margin-left: 2vh;
}

.Rectangle-1232 {
  margin: 2vh -5vh 0vh 0vh;
  padding: 1vh;
  background-color: #f7f7f7;
  height: 465px;
}
.que-text {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: left;
  color: #9b9b9b;
}
.que_txt {
  font-family: Ubuntu;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 2.43;
  letter-spacing: normal;
  text-align: left;
  color: #676772;
  margin-left: 2vh;
}

.Path-868 {
  margin: 0vh 2vh 0vh 1vh;
  padding: 1vh;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1b1b1;
  background-color: #fff;
}

.question {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #202527;
}

/*.circle {
  width: 25px;
  height: 25px;
  margin: 0vh;
  padding: 3px 5px 3px 8px;
  border-radius: 25px;
  border: solid 1px #eaeaea;
  background-color: #eaeaea;
}*/
.answer {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #202527;
}

.Searching-For {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.89;
  letter-spacing: normal;
  text-align: left;
  color: #676772;
}

.Rectangle-1204 {
  padding: 2vh;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1b1b1;
  background-color: #f7f7f7;
}
.search_context {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}
::v-deep .chkbox_content .v-label {
  font-family: Ubuntu;
  font-size: 12px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}
.Label_btn {
  font-family: Ubuntu;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  background-color: #00b2ba !important;
  border-radius: 9px;
}
.Label_btnn {
  font-family: Ubuntu;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #202527;
}
.Path-359 {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #ff6750 !important;
  background-color: #fff;
  border-radius: 20px !important;
}
.Path-359_support {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  background-color: #fff;
}
.v-list-subheader {
  align-items: center;
  background: inherit;
  color: rgba(var(--v-theme-on-surface), var(--v-medium-emphasis-opacity));
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.375rem;
  padding: 0 16px;
  color: rgb(241, 103, 83);
  font-family: Ubuntu;
  font-weight: 700;
}
.centered-input >>> input {
  text-align: center;
}
.user_profile {
  width: 4vh;
  height: 4vh;
  border: none;
  background-color: #d9ebef;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  font-size: 15px;
  margin: auto;
  justify-content: center;
}
.support {
  width: 4vh;
  height: 4vh;
  border: none;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  font-size: 15px;
  margin: auto;
  justify-content: center;
  border: 1px solid #fff;
}

.OP_2 {
  font-family: Ubuntu;
  font-size: 1.8vh;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;
  color: #0a5053;
}
.flex-container {
  display: flex;
  flex-direction: row;
  margin-left: 8vh;
  align-items: center;
}
.flex-container_plan {
  display: flex;
  flex-direction: row;
  margin-left: 12vh;
  align-items: center;
}
.custom-app-bar >>> .v-toolbar__content {
  height: auto !important;
}
</style>
