<template>
    <div class="margin_align_1" :style="!isTabletMob?'height:100vh !important;overflow-y:hidden':'height:120vh'"  >
  <div>
    <v-row v-if="!formFilled" style="margin-top:11.5vh;" :style="!isTabletMob ? '' : 'margin-left:1vh;margin-right: 1vh;margin-bottom:2vh'">
      <v-col v-if="!isTabletMob" col="12" sm="1" md="1" lg="1" xs="1" xl="1"></v-col>
       <v-col v-if="!isTabletMob" col="12"  sm="10" md="10" lg="10" xs="10" xl="10">
          <div :style="!isTabletMob?'overflow-x:hidden;overflow-y:scroll;height:90vh !important':'overflow:hidden;height:90% !important'"  >
            <v-row>
              <v-col>
                <div class="banner-container">
                  <img src="@/assets/images/banner.jpg" alt="Newsletter Banner" class="banner-image">
                  <div class="banner-text">
                      <h1 style="margin: 0;" class="mycart">Report an Issue</h1>
                  </div>
              </div>
                
              </v-col>
            </v-row>
            <v-row style="margin-top:0" >
              <v-col>
                <span style="font-family: Ubuntu;
                font-size: 15px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                color:#676772
                " >
                  Please use this form to submit any issues or bugs you are experiencing.
              </span>
              </v-col>
            </v-row>
            <v-row class="row_style" style="margin-bottom:3vh;overflow:hidden !important">
              <v-col>
              <v-form id="form">
                <!-- create name field with label name and textbox with validation -->
                <label for="name">Name<span style="color:red">*</span></label>
                <v-text-field
                v-model="user_data.Description.Name"
                  required
                  outlined
                  placeholder="Enter your name"
                  :rules="[
                    v => !!v || 'Name is required',
                    v => /^[a-zA-Z ]+$/.test(v) || 'Name must contain only alphabetic characters.',
                  ]"
                ></v-text-field>
                <!-- create name field with label email address and textbox with validation -->
                <label for="email">Email Address<span style="color:red">*</span></label>
                <v-text-field
                v-model="user_data.Description.Email"
                  required
                  outlined
                  placeholder="Enter your email address"
                  :rules="[
                    v => !!v || 'Email Address is required',
                    v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
                  ]"
                ></v-text-field>
                 
                <!-- create name field with label description and textbox with validation -->
                
                <div style="display:flex;flex-direction:row;justify-content:space-between">
                  <div style="display:flex;flex-direction:column;margin-right:5vh;flex-basis: 60%;">
                    <label for="description">Please describe the issue you are experiencing<span style="color:red">*</span></label>
                    <v-textarea
                    v-model="user_data.Name"
                      required
                      outlined
                      placeholder="Enter your description"
                      :rules="[
                        v => !!v || 'Description is required',
                      ]"
                    ></v-textarea>
                  </div>
                  <div style="display:flex;flex-direction:column;flex-basis: 30%;">
                    <label for="description">Do you have screenshot of this issue that you can share with us</label>
                    <!-- add browse file and take file as attachment and save its path -->
                    <br>
                    <input  style="margin-top:1vh" type="file" accept="image/*" @change="handleFileChange" />
                  </div>
                </div>
                <v-row>
                  <v-col col="12" sm="2" md="2" lg="2" xs="2" xl="2">
                    <v-btn :class="{ disabled: isDisabled }" :disabled="isDisabled" block color="#009ea5"    
                    style="margin-top:1vh;background-color: #009ea5; color: #ffffff;font-size: 2vh;letter-spacing: 0.2px;width:auto" @click="getData(user_data)">
                   Submit
                 </v-btn>
              
                  </v-col>
                  <v-col col="12" sm="5" md="5" lg="5" xs="5" xl="5"></v-col>
                 
                  <v-col col="12" sm="5" md="5" lg="5" xs="5" xl="5"></v-col>
                </v-row>
               
                  
              </v-form>
            </v-col>
            </v-row>
          </div>

       </v-col>
       <v-col v-if="isTabletMob" col="12" >
          <v-card class="Rectangle-1151" style="overflow-y:hidden" :style="!isTabletMob ? '' : 'padding:2vh;'">
            <v-row>
              <v-col>
                <div class="banner-container">
                  <img src="@/assets/images/banner.jpg" alt="Newsletter Banner" :style="isTabletMob?'height:50px':''" class="banner-image">
                  <div class="banner-text">
                      <h1 style="margin: 0;" class="mycart">Report an Issue</h1>
                  </div>
              </div>
                
              </v-col>
            </v-row>
            <v-row style="margin-top:0" >
              <v-col>
                <span style="font-family: Ubuntu;
                font-size: 15px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                color:#676772
                " >
                  Please use this form to submit any issues or bugs you are experiencing.
              </span>
              </v-col>
            </v-row>
            <v-row style=" max-height:50vh;min-height:30vh;
            overflow-y:scroll !important;">
              <v-col>
              <v-form id="form">
                <!-- create name field with label name and textbox with validation -->
                <label for="name">Name<span style="color:red">*</span></label>
                <v-text-field
                v-model="user_data.Description.Name"
                  required
                  outlined
                  placeholder="Enter your name"
                  :rules="[
                    v => !!v || 'Name is required',
                    v => /^[a-zA-Z ]+$/.test(v) || 'Name must contain only alphabetic characters.',
                  ]"
                ></v-text-field>
                <!-- create name field with label email address and textbox with validation -->
                <label for="email">Email Address<span style="color:red">*</span></label>
                <v-text-field
                v-model="user_data.Description.Email"
                  required
                  outlined
                  placeholder="Enter your email address"
                  :rules="[
                    v => !!v || 'Email Address is required',
                    v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
                  ]"
                ></v-text-field>
                <!-- create name field with label description and textbox with validation -->
                <label for="description">Please describe the issue you are experiencing<span style="color:red">*</span></label>
                <v-textarea
                v-model="user_data.Name"
                  required
                  outlined
                  placeholder="Enter your description"
                  :rules="[
                    v => !!v || 'Description is required',
                  ]"
                ></v-textarea>
                <label for="description">Do you have screenshot of this issue that you can share with us</label>
                <!-- add browse file and take file as attachment and save its path -->
                <br>
                <input  style="margin-top:1vh" type="file" accept="image/*" @change="handleFileChange" />

               
                <!-- create submit buttom at center -->
                <v-row>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                  <v-col col="12" sm="4" md="4" lg="4" xs="4" xl="4">
                    <v-btn :class="{ disabled: isDisabled }" :disabled="isDisabled" block color="#009ea5"    
                    style="margin-top:2vh;background-color: #009ea5; color: #ffffff;font-size: 2.1vh;letter-spacing: 0.2px;" @click="getData(user_data)">
                   Submit
                  </v-btn>
                  </v-col>
                  <v-col  col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                </v-row>
              </v-form>
            </v-col>
            </v-row>
          </v-card>

       </v-col>
    
       
       <v-col v-if="!isTabletMob" col="12" sm="1" md="1" lg="1" xs="1" xl="1"></v-col>
    </v-row>
      <!-- </v-card> -->
     
        <v-row v-if="formFilled && !isTabletMob" style="margin-top:20%;">
          <v-col v-if="!isTabletMob" col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
          <v-col v-if="!isTabletMob" col="12" sm="4" md="4" lg="4" xs="4" xl="4" >
         
          <v-card class="Path-359_1" style="padding:6vh 3vh ;">
            <v-row>
             
              <v-col :cols="!isTabletMob ? '12' :'10'"  align="center" :style="isTabletMob?'margin-left:3vh':''" >
                
                <span  style="font-family: Ubuntu;font-size:16px">
                  Thank you for taking the time to report the issue. Your feedback is important to us and helps us improve our platform.
                </span>
              </v-col>
              
            </v-row>
            <v-row>
              <!-- create and anchor tag saying add another response on click will repopen form  -->
              <v-col :cols="!isTabletMob ? '12' :'10'"  align="center" :style="isTabletMob?'margin-left:3vh':''" >
                <a href="javascript:void(0)" @click="reOpenForm()" style="font-family: Ubuntu;font-size:16px;color:#009ea5">
                  Add another response
                </a>
              </v-col>
              
            </v-row>
            
          </v-card>
        </v-col>
        <v-col v-if="!isTabletMob" col="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
        </v-row>
        <div v-if="formFilled && isTabletMob" style="display: flex; flex-direction: column; align-items: center; margin-top: 40%;margin-left:3vh;margin-right:3vh">
          <v-card class="Path-359_1" style="padding: 4vh;">
            <v-row>
              <v-col :cols="!isTabletMob ? '12' :'10'" align="center" :style="isTabletMob ? 'margin-left:3vh' : ''">
                <span style="font-family: Ubuntu; font-size: 16px;">
                  Thank you for taking the time to report the issue. Your feedback is important to us and helps us improve our platform.
                </span>
              </v-col>
            </v-row>
            <v-row>
              <!-- create and anchor tag saying add another response on click will repopen form  -->
              <v-col :cols="!isTabletMob ? '12' :'10'" align="center" :style="isTabletMob ? 'margin-left:3vh' : ''">
                <a href="javascript:void(0)" @click="reOpenForm()" style="font-family: Ubuntu; font-size: 16px; color: #009ea5;">
                  Add another response
                </a>
              </v-col>
            </v-row>
          </v-card>
        </div>
        
    </div>
  </div> 
</template>

<script>
import axios from "axios";
import fs from 'fs';


const accessToken = '1/1203670818179728:f673e96226d68fa215a19364f41064e4';
const workspaceId = '1203019045083878';
const projectId = '1204996309254007';

export default {
  data() {
    return {
      user_data:{
        Description:{
          Name:'',
          Email:'',
          
          
        },
        Name:'',
       
        
      },
      uploadFiles: [],
      Files:[],
      email:'',
      auth_token:'',
      selected_file:null,
      formFilled:false,
      name:'',

    };
  },
  computed: {
    isTabletMob : function() {

      const iPad = /iPad/i.test(navigator.userAgent);
      const iPhone = /iPhone/i.test(navigator.userAgent);
      const Android = /Android/i.test(navigator.userAgent);
      const webOS = /webOS/i.test(navigator.userAgent);
      const iPod = /iPod/i.test(navigator.userAgent);
      const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
      const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);

      return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
    },
    isDisabled() {  
   //check if user_data all fields are filled or not
      if (this.user_data.Description.Name !== "" && this.user_data.Description.Email !== "" && this.user_data.Name !== "" ) {
        return false;
      } else {
        return true;
      }
     
    },
  },
  created() {
  },
  mounted() {
    var auth_token = localStorage.getItem("authtoken");
    if ( auth_token !== null && auth_token !== "" && auth_token !== undefined ) {
      this.auth_token = auth_token
      this.getEmail();
      this.get_profile();
      // this.getdiseases(this.auth_token);
      // this.getpositions(this.auth_token);
      // this.getcompanies(this.auth_token);
      EventBus.$emit("module_name","" );
      localStorage.setItem("selected_module","")
    } else {
      this.auth_token = ""
   
    }

   
  },
  methods: {
    getData(data){
      var data=data
      console.log(data)
      this.formFilled=true
      document.getElementById("form").reset();
     
      //convert data.Description to  pure string
      var description=JSON.stringify(data.Description)
      //remove curly braces and "" from string 
     
   
  
          description=description.replace(/['"]+/g, '')
          description=description.replace(/[\[\]']+/g, '')
          description=description.replace(/[\n\r]+/g, '')
          //remove { and } from string
          description=description.replace("{", "")
          description=description.replace("}", "")
          //add new line after , in string
          description=description.replace(/,/g, '\n')
          console.log(description)
     
      
      var taskData={
        data:{
          "workspace": workspaceId,
          "name":data.Name  ,
          "notes":  description,
          "projects": [projectId]
        }
      }

      
      axios.post('https://app.asana.com/api/1.0/tasks', taskData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then(async response => {
        const task = response.data.data; // Task data object from the response
        const taskId = task.gid; // Get the task ID using "gid" property
        // Upload attachment
        console.log(taskId,"taskid")
         // Create FormData for file upload
      const formData = new FormData();
     
      formData.append('file', this.selected_file);
      formData.append('name', 'Attachment Name'); // Additional data
      console.log(formData,"formdata")
      this.send_email()
      if (this.selected_file!=null){
      try {
        // Upload the file to Asana task
        await axios.post(`https://app.asana.com/api/1.0/tasks/${taskId}/attachments`, formData, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data' // Set the content type for FormData
          }
        });

        console.log('Attachment uploaded successfully.');
       
       
      } catch (error) {
        console.error('Error uploading attachment:', error);
      }
    }
       
      })
      .catch(error => {
        console.error('Error creating task:', error.response.data.errors);
      });
   
    },
    get_profile() {
      axios.get("/userProfile?token=" + this.auth_token).then((res) => {
        if (res.data.Status !== 1) {
          this.user = res.data.Data;
        console.log(this.user,"user")
          this.name=this.user.Name
        } else if (
          res.data.Status == 1 &&
          res.data.Data.error == "Invalid User"
        ) {
          console.log("Invalid User");
        } else {
          console.log("Profile not found");
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);             
        }
      });
    },
    handleFileChange(event) {
      this.selected_file = event.target.files[0];
      this.$forceUpdate();
      console.log(this.selected_file,"file")
    },
    goToHome(){
      this.$router.push("/")
     },
      getEmail(){
      //create a get request to get email
      axios.get('/getEmail?token='+this.auth_token).then((res) => {
        //console.log(res)
        if (res.data.Status != 1) {
          this.email=res.data.Data.Email
         this.user_data.Description.Email=this.email
        }else{
          console.log("not logged in")
        }
      }).catch((err) => {
        console.log(err)
      })
     },
     reOpenForm(){
     
      location.reload();
     },
     send_email() {
          axios
            .post("/send_support_email?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status != 1) {
                console.log("email send successfully")               
              } else {
                console.log("email failed")
              }
          })
        },
       
     
  }
};
</script>


<style lang="scss" scoped>
.margin_align_1{
  overflow-x: hidden;
  overflow-y: auto;
  
  
 
  }
  
  .Path-359_support{
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
   
    background-color: #fff;
   
  }
  .Rectangle-1151 {
    border-radius:16px;
    padding: 4vh;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
   
  }
  .mycart {
    margin: 3vh 0vh 2vh 0vh;
    font-family: Ubuntu;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color:#ffffff;
    text-align: center !important;
    
  }
.row_style{

 
  overflow-y:scroll !important;
}
.Path-359_1 {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius:12px;
  
  background-color: #fff;
 
}  .banner-container {
  position: relative;
  width: 100%;
  max-height: 120px;
  margin-bottom: 20px;
}
.banner-image {
  width: 100%;
  max-height: 90px;
  object-fit: cover;
}
.banner-text {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  text-align: right;
  color: #ffffff;
}

</style>