<template>
  <v-main>
    <div >      
      <div id="gist">
        <div class="margin_align">
        <div :class="expanded ? 'leftPanelExpand' : 'left'">
          <loading :active.sync="loading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
          <div style="font-family: Ubuntu;margin-bottom: -10px;margin-right: -5px;margin-top:50px">
            <v-row>
              <v-col cols="12" md="2" lg="2" sm="2" xs="2">
                <span class="Notes-Centre-101">Notes Center  ({{TotalCount}})</span>
               
              </v-col>
              <v-col cols="12" md="2" lg="2" sm="2" xs="2">
                <v-text-field type="text" v-model="searchme" label="Search"
                    style="background-color: white;font-size:13px !important;font-family: Ubuntu;font-weight: 500;margin-top:-3vh;"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" lg="2" sm="2" xs="2" class="d-flex">
                <div class="d-flex" style="margin-left:4vh">
                  <v-menu offset-y :close-on-content-click="closeOnContentClick">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <span class="Showing-All-Diseases">
                          {{title_disease}}
                        </span>
                        <img src="../assets/Icons/Group 635.svg" style="height: 1vh;margin-left:5px" />
                      </div>
                    </template>
                    <v-list style="height:50vh;overflow-y:scroll;">
                      <v-list-item
                        class="dropdownStyle" @click="select_notes_disease('All Diseases')">
                        <v-list-item-title style="text-transform: capitalize;font-size: 13px;font-family: Ubuntu">All Diseases</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-for="(myd, mkey) in my_diseases" :key="mkey" item-value="art" 
                        class="dropdownStyle" @click="select_notes_disease(myd)">
                        <v-list-item-title style="text-transform: capitalize;font-size: 13px;">{{myd}}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>             
              </v-col>
              <v-col cols="12" md="3" lg="3" sm="3" xs="3">
               <div class="d-flex">
                  <v-menu offset-y :close-on-content-click="closeOnContentClick">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <span class="Showing-All-Diseases">
                          {{title_article_type}}
                        </span>
                        <img src="../assets/Icons/Group 635.svg" style="height: 1vh;margin-left:5px" />
                      </div>
                    </template>
                    <v-list style="height:38vh;overflow-y:scroll;">
                      <v-list-item
                        class="dropdownStyle" @click="select_article_type('All Articles')">
                        <v-list-item-title style="text-transform: capitalize;font-size: 13px;font-family: Ubuntu">All Articles</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-for="(art, aty) in article_types" :key="aty" item-value="art"
                        class="dropdownStyle" @click="select_article_type(art)">
                        <v-list-item-title style="text-transform: capitalize;font-size: 13px;font-family: Ubuntu">{{art}}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="12" md="3" lg="3" sm="3" xs="3">
                
              </v-col>
            </v-row>
            <div v-show="!loading">
              <v-row v-if="show_message && user_plan=='Basic Plan'" wrap style="height:100%;margin-top:20vh">
                <v-col cols="12" md="2" lg="2" sm="2"></v-col>
                <v-col cols="12" md="8" lg="8" sm="8">
                  <div>
                    <img src="../assets/Icons/Group 910.svg" style="display: block;margin-left: auto;margin-right: auto;width:15vh">
                  </div>
                  <div>
                    <p
                      style="text-align: center;margin-top:5vh;margin-bottom:28vh;font-size:16px;font-weight:700;color:#8c8e8e">
                      You have not added any note yet.</p>
                  </div>
                </v-col>
                <v-col cols="12" md="2" lg="2" sm="2"></v-col>
              </v-row>
              <v-row v-if="user_plan!='Basic Plan'" wrap style="height:100%;margin-top:20vh">
                <v-col cols="12" md="2" lg="2" sm="2"></v-col>
                <v-col cols="12" md="8" lg="8" sm="8">
                  <div>
                    <img src="../assets/Icons/Group 910.svg" style="display: block;margin-left: auto;margin-right: auto;width:15vh">
                  </div>
                  <div>
                    <p
                      style="text-align: center;margin-top:5vh;margin-bottom:28vh;font-size:16px;font-weight:700;color:#8c8e8e">
                      Please subscribe to see your notes</p>
                  </div>
                </v-col>
                <v-col cols="12" md="2" lg="2" sm="2"></v-col>
              </v-row>
              <div id="infinite-list1" style="overflow-y:scroll;overflow-x: hidden;height:75vh;" v-if="!show_message && user_plan=='Basic Plan'">
                  <v-row v-masonry="containerId" transition-duration="0.3s" item-selector=".item" wrap>
                    <v-col col="12" sm="3" v-masonry-tile class="item" v-for="(al, key) in filteredList" :key="key">
                      <v-card class="Path-704" @click.stop="show_note_area(al)">
                        <div class="Path-736">
                          <v-row wrap>
                            <v-col cols="12" md="10" lg="10" sm="10" xs="10"><span class="Added-on">Added on {{al.notes_date}}</span></v-col>
                            <v-col cols="12" md="2" lg="2" sm="2" xs="2">
                              <div class="d-flex">
                                <v-menu offset-y :close-on-content-click="closeOnContentClick">
                                  <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on" style="padding-left:0.5vw;padding-right: 2vw;">
                                      <i class="fa fa-ellipsis-v" style="color:#03a6ad !important;margin-top: 1.7vh;"></i>
                                    </div>
                                    <!-- <v-btn
                                      plain
                                      v-bind="attrs"
                                      v-on="on"
                                      style="margin-right: 1vw !important"
                                      
                                    >
                                      <i class="fa fa-solid fa-ellipsis-v" style="float:right;color:#03a6ad !important;margin-top: 1.7vh;"></i>
                                    </v-btn> -->
                                  </template>
                                  <v-list style="max-height:15vh;overflow-y:scroll;">
                                    <v-list-item
                                      class="dropdownStyle">
                                      <v-list-item-title style="text-transform: capitalize;font-size: 13px;cursor: pointer;font-weight: 600;" @click="delete_my_note(al)">Delete</v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </div>
                            </v-col>
                          </v-row>
                          <!-- <v-row style="margin-top: -1vh;">
                            <span class="Article-Type">Article Type</span>
                            <span class="Therapy-Landscape-type">{{al.title}}</span>
                          </v-row> -->
                          <v-row style="margin-top: -1vh;">
                            <!-- <span class="Article-Type">Disease Name</span> -->
                            <span class="Therapy-Landscape-type">{{al.disease}}</span>
                          </v-row>
                        </div>
                        <div style="padding: 2vh">
                          <span class="notes_text">{{ al.Note }}</span>
                        </div>
                      </v-card>                 
                    </v-col>
                  </v-row>
              </div>
            </div>
          </div>
        </div>
          <div class="right notes_section" v-if="!expanded && panels_show" style="overflow-y:scroll;margin-top:-8vh">
            <div>
              <div class="Rectangle-1062">
                <v-row style="margin-top: 3vh;">
                  <v-col cols="12" md="3" lg="3" sm="3">
                    <span class="notes_Added_on">Date Added</span><br>
                    <span class="note_content_type">{{selected_notes_date}}</span>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" sm="3">
                    <span class="notes_Added_on">Article Type</span><br>
                    <span class="note_content_type">{{selected_notes_article_type}}</span>
                  </v-col>
                  <v-col cols="12" md="5" lg="5" sm="5">
                    <span class="notes_Added_on">Disease Name</span><br>
                    <span class="note_content_type">{{selected_notes_disease}}</span>
                  </v-col>
                  <v-col cols="12" md="1" lg="1" sm="1">
                    <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;margin-top:2vh" @click="close_notes_area()">mdi-close</v-icon>
                  </v-col>
                </v-row>
              </div>
              <div style="padding: 2vh 10vh 3vh 3vh;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);max-height: 15vh;overflow-y: auto;">
                <v-row>                          
                  <p style="font-family: Ubuntu; font-size: 14px;text-align: left !important;font-family: Ubuntu;color: #2c2c48;margin: 0vh 5vh 1vh 2vh">{{notes_content_display}}</p>                  
                </v-row>                                       
              </div>
              <div v-if="loading_info  && article_type == 'Web'">
                    <v-row>
                      <v-row wrap style="max-height: 65vh; overflow-y: auto;margin-top:2vh;margin-bottom: 2vh;">
                            <v-col cols="12" md="4" lg="4" sm="4" xs="4" style="padding-left:40px;text-align: left;">
                              <v-row style="margin-bottom: 2vh;">
                                <v-col cols="12" md="12" lg="12" sm="12" xs="12">
                                  <div class="news_heading">{{panels.Left.Header}}</div>
                                </v-col>
                              </v-row>
                              <div v-if="panels.Left.First_Text.length > 0 && panels.Left.First_Text[0] !== ''"> 
                                <div style="font-family:Ubuntu;font-size: 15px;font-weight: 500;color:#384348" class="pre-formatted" v-for="(pa,a) in panels.Left.First_Text" :key="a">
                                  <div v-html="pa"></div>
                                </div>
                                <hr class="line" />
                              </div>
                              <div v-if="panels.Left.Second_Text.length > 0 && panels.Left.Second_Text[0] !== ''">
                                <div style="font-family:Ubuntu;font-size: 15px;font-weight: 500;color:#384348" class="pre-formatted" v-for="(pa,b) in panels.Left.Second_Text" :key="b">
                                  <div v-html="pa"></div>
                                </div>
                                <hr class="line"/>
                              </div>
                              <div v-if="panels.Left.Third_Text.length > 0 && panels.Left.Third_Text[0] !== ''">
                                <div style="font-family:Ubuntu;font-size: 15px;font-weight: 500;color:#384348" class="pre-formatted" v-for="(pa,c) in panels.Left.Third_Text" :key="c">
                                  <div v-html="pa"></div>
                                </div>
                                <hr class="line"/>
                              </div>
                              <div v-if="panels.Left.Forth_Text.length > 0 && panels.Left.Forth_Text[0] !== ''">
                                <div style="font-family:Ubuntu;font-size: 15px;font-weight: 500;color:#384348" class="pre-formatted" v-for="(pa,d) in panels.Left.Forth_Text" :key="d">
                                  <div v-html="pa"></div>
                                </div>
                                <hr class="line"/>
                              </div>
                              <div v-if="panels.Left.Fifth_Text.length > 0 && panels.Left.Fifth_Text[0] !== ''">
                                <div style="font-family:Ubuntu;font-size: 15px;font-weight: 500;color:#384348" class="pre-formatted" v-for="(pa,e) in panels.Left.Fifth_Text" :key="e">
                                  <div v-html="pa"></div>
                                </div>
                                <hr class="line"/>
                              </div>
                            </v-col>
                            <v-col cols="12" md="7" lg="7" sm="7" xs="7" style="margin-left:70px;">
                              <div>
                                <v-row>
                                  <div v-if="panels.Right.Display.Display_Type == 'Card'" style="margin-bottom: 2vh;">
                                    <v-row wrap>
                                      <v-col  cols="12" md="4" lg="4" sm="4" xs="4"
                                        v-for="(pa,key1) in panels.Right.Display.Display_Info" :key="key1">
                                        <v-card style="height:280px;width:95%;padding:0vh 2vh 0vh 2vh;margin-right: 4vh; ">
                                          
                                          <v-row wrap style="height:80px">
                                            <v-col class="mt-4" cols="12" md="3" lg="3" sm="3" xs="3">
                                              <img :src="pa.Logo" basic style="height: 22px; width: 22px;margin-left:1vh" />
                                            </v-col>
                                            <v-col class="mt-4" cols="12" md="9" lg="9" sm="9" xs="9">
                                              <p class="cardTitles">{{pa.Title}}</p>
                                            </v-col>
                                          </v-row>
                                          
                                          <div v-if="pa.Value_Type == 'image'">
                                            <div class="centerAlign" style="height: 190px;">
                                              <img :src="pa.myimage.src" alt="ChannelLogo" />
                                            </div>
                                          </div>
                                          
                                          <div v-if="pa.Value_Type == 'text'" >
                                            <div style="margin-top:15px">
                                              <v-row>
                                                <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                                                <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4">
                                                  <div style="margin-top: 5px">
                                                    <hr class="hrLine">
                                                    <span class="itemCountDesign centerAlign">{{pa.count}}</span>
                                                    <hr class="hrLine">
                                                  </div>

                                                </v-col>
                                                <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                                              </v-row>
                                            </div>
                                            <div class="titleCenter" style="margin-top:40px">
                                              <span class="title1">{{pa.rem_text}}</span>
                                            </div>
                                          </div>
                                        </v-card>
                                      </v-col>

                                    </v-row>
                                  </div>
                                  <div v-if="panels.Right.Display.Display_Type == 'Text Box'">
                                    <div style="margin-bottom: 2vh;">
                                      <div v-for="(pa,key3) in panels.Right.Display.Display_Info" :key="key3">
                                        <h5 class="texttitle">{{pa.Title}}</h5>
                                          <p v-if="!pa.textLength" class="textbox_content pre-formatted" v-html="pa.newText"></p>
                                          <p v-if="pa.textLength" class="textbox_content pre-formatted" v-html="pa.Text"></p>
                                          <span @click="changetextLength(pa)" class="pointer" v-if="!pa.textLength && !pa.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;">Read More</span>
                                          <span @click="changetextLength(pa)" class="pointer" v-if="pa.textLength && !pa.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;">Show Less</span>                               
                                      </div>
                                    </div>
                                  </div>
                                  <div v-if="panels.Right.Display.Display_Type == 'Table'">
                                    <v-row v-for="(pa,key4) in panels.Right.Display.Display_Info" :key="key4">
                                      <v-row wrap>
                                        <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2" v-for="(pbtn,keyyy) in pa.Dropdownnames" :key="keyyy">                       
                                            <v-btn :style="pbtn.selected ? 'background-color: #3fa6a1; color: #ffffff;font-size: 13px;font-weight:700; margin-top:2vh;font-family: Ubuntu;width:7vw;' : 'background-color: #ffffff; color: #3fa6a1; font-size: 13px;font-weight:700; margin-top:2vh; font-family: Ubuntu;width:7vw;'" @click="onChangetrials(pbtn,pa.myTable)">{{pbtn.name}}</v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="7" md="7" lg="7" xs="7" xl="7"></v-col>
                                      </v-row>
                                      <table class="pl-0" :id="pa.myTable">
                                        <span v-if="pa.show_dropdown == true">
                                          <tr>
                                            <th :class="(pa.Table_Data.Table_Type == 'Dual Header' && key7 == 0)? 'dualHeaderRow': 'dualHeaderAnotherRow'" v-for="(p,key7) in pa.Table_Data.Columns" :key="key7" style="min-width: 9vw">{{p}} <span @click="sort_rows(key7, pa.myTable)" v-show="pa.Table_Data.Sort_Enable == 'True'"><i class="fa fa-arrow-down" style="margin-left: 2vh !important;cursor: pointer;"></i></span>
                                            </th>
                                          </tr>
                                          <tr v-for="(p,key5) in pa.Table_Data.show_rows" :key="key5">
                                            <td v-for="(x,k1) in p" :key="k1" :class="(pa.Table_Data.Table_Type == 'Dual Header' && k1 == 0)? 'dualHeaderData':'dualHeaderAnotherData'">
                                              <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                            </td>
                                          </tr>
                                        </span>
                                        <tr v-if="pa.show_dropdown !== true">
                                          <th
                                            :class="(pa.Table_Data.Table_Type == 'Dual Header' && key77 == 0)? 'dualHeaderRow': 'dualHeaderAnotherRow'"
                                            v-for="(p,key77) in pa.Table_Data.Columns" :key="key77">
                                            <v-row wrap style="padding:0">
                                              <v-col cols="12" sm="8" md="8" lg="8" xs="8" style="padding-top:0vh">
                                                <span style="white-space:normal;margin-right:2px">{{p}}</span>
                                              </v-col>
                                              <v-col cols="12" sm="2" md="2" lg="2" xs="2" @click="sort_rows(key77, pa.myTable)" style="padding-top:0vh" v-show="pa.Table_Data.Sort_Enable == 'True'">
                                                  <i class="fa fa-arrow-down"
                                                    style="cursor: pointer;padding:0px"></i>
                                              </v-col>
                                            </v-row>
                                          </th>
                                        </tr>

                                        <tr v-for="(p,key55) in pa.Table_Data.Rows" :key="key55" v-if="pa.show_dropdown !== true">
                                          <td v-for="(x,k11) in p" :key="k11"
                                            :class="(pa.Table_Data.Table_Type == 'Dual Header' && k11 == 0)? 'dualHeaderData':'dualHeaderAnotherData'">
                                            <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                          </td>
                                        </tr>
                                      </table>
                                    </v-row>
                                  </div>
                                  <div v-if="panels.Right.Display.Display_Type == 'Plot'">
                                    <v-row style="margin-top:-2vh;">
                                    <v-col :cols="(panels.Right.Display.Display_Info.length == 1 || key6 ==2 ) ? '12' : '6'"  v-for="(pa,key6) in panels.Right.Display.Display_Info" :key="key6" style="">
                                      
                                        <span v-html="pa.div64"></span>
                                    </v-col>
                                    </v-row>
                                  </div>
                                  <div v-if="panels.Right.Display.Display_Type == 'Financial_Cards'">
                                    <div v-for="(pa,kfin) in panels.Right.Display.Display_Info" :key="kfin" style="padding:5vh">
                                      <v-row class="mt-3">
                                        <v-col cols="12" md="6" lg="6" sm="6" xs="6" v-for="(p,fin) in pa.Fin_Card_Data" :key="fin">
                                          <v-card class="fincard">
                                            <div v-for="(pf,fink) in p" :key="fink">
                                              <p style="margin-top:2vh; margin-bottom:2vh;font-family:Ubuntu;font-size:16px;font-weight: 700">{{pf.Header}}</p>
                                              <v-row>
                                                <v-col cols="12" md="6" lg="6" sm="6" xs="6">
                                                  <span class="finance_text">{{pf.name}}</span>
                                                </v-col>
                                                <v-col cols="12" md="6" lg="6" sm="6" xs="6">
                                                  <span class="finance_text">{{pf.val}}</span>
                                                </v-col>
                                                <hr>
                                              </v-row>
                                            </div>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                      <v-row v-if="pa.Plot == 'Table'">
                                          <table style="">
                                            <tr>
                                              <th class="table_header" v-for="(pfi,ft) in pa.Columns" :key="ft">{{pfi}}</th>
                                            </tr>
                                            <tr v-for="(p,key) in pa.Rows" :key="key">
                                              <td v-for="(x,k) in p" :key="k" class="finaTableWrap">
                                                <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                              </td>
                                            </tr>
                                          </table>
                                      </v-row>
                                    </div>
                                  </div>  
                                </v-row>
                              </div>
                            </v-col>
                          </v-row>
                        </v-row> 
                  </div>
                  <div v-if="loading_info  && article_type == 'L1-L2'">
                    <v-row style="margin-top: 2vh">                      
                      <v-row wrap style="max-height: 70vh;margin-top:2vh;margin-bottom: 2vh;">
                        <v-col cols="12" md="7" lg="7" sm="7" class="Rectangle-721">
                          <div style="overflow-y: auto;overflow-x:hidden;height: 60vh;">
                            <v-row v-for="(finc,key2) in final_statements1" :key="key2">
                              <div style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;padding-right:30px" v-html="finc.div"></div>
                            </v-row>
                          </div>
                        </v-col>
                        <v-col cols="12" md="4" lg="4" sm="4" class="pt-2 ml-5">
                          <div class="Rectangle-905">
                            <div class="Rectangle-903" style="top:0px;overflow:hidden">Impact Assessment</div>
                            <div style="background-color: #f2fcfc;padding: 2vh 1vw 2vh 1vh;overflow-y: auto; max-height: 50vh;">
                               <v-row v-for="(fic,keyc) in final_statements2" :key="keyc">                       
                                <div v-if="fic.type == 'plot'">
                                  <v-row wrap>
                                    <v-col cols="12" md="12" lg="12" sm="12">
                                      <!-- <div style="font-family: Ubuntu;font-size: 16px;font-weight: 500;text-align: left;color: #49575d;margin-bottom: 15vh;" v-html="fic.div"></div> -->
                                    </v-col>
                                  </v-row>
                                </div>
                                <div v-if="fic.type == 'image'">
                                  <v-row wrap>
                                    <v-col cols="12" md="2" lg="2" sm="2"></v-col>
                                    <v-col cols="12" md="8" lg="8" sm="8">
                                      <div style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="fic.div"></div>
                                    </v-col>
                                    <v-col cols="12" md="2" lg="2" sm="2"></v-col>
                                  </v-row>
                                </div>
                                <div  v-if="fic.type == 'table'">
                                  <v-row>
                                    <span @click="show_table(fic.div)" style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #00b2ba;margin-bottom: 3vh;cursor: pointer;">See table here</span>
                                  </v-row>
                                </div>
                                <div style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="fic.div" v-if="fic.type != 'plot' && fic.type != 'image' && fic.type != 'table'"></div>
                              </v-row>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12" md="1" lg="1" sm="1" class="pt-2"></v-col> 
                      </v-row>  
                    </v-row> 
                  </div>             
              </div>
            </div>
            <template>
              <div class="text-center">
                <v-dialog
                  v-model="show_table_modal"
                  width="800"
                >
                  <v-card class="Path-359" style="padding:3vh 3vh 3vh 6vh !important">
                      <div><v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="show_table_modal = false">mdi-close</v-icon></div>
                      <v-row wrap>
                        <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                          <div v-html="table_display"></div>
                        </v-col>   
                      </v-row>
                  </v-card>
                </v-dialog>
              </div>
            </template>
           <template>
              <div class="text-center">
                <v-dialog
                  v-model="delete_confirm"
                  width="600"
                >
                  <v-card class="Path-359" style="padding:3vh 2vh 3vh 2vh">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                          <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;margin-right: 3vh;">
                          <span class="Active_Companies_title">Delete Note !</span>
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                        <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                          <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="delete_confirm = false">mdi-close</v-icon>
                        </v-col>
                      </v-row>
                      <v-row wrap>
                        <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                          <span class="com_nm1">Are you sure you want to delete this note ?</span>
                        </v-col>   
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-row>
                        <v-col sm="6" md="6" lg="6" xs="6" xl="6"></v-col>
                        <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                          <v-btn                          
                            style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh;"
                             @click="delete_note(note_deleted)"
                          >
                            Yes
                          </v-btn>
                        </v-col>
                        <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                          <v-btn
                            style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 2.2vh;font-family: Ubuntu;"
                            @click="delete_confirm = false"
                          >
                            No
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>  
                  </v-card>
                </v-dialog>
              </div>
          </template>
          <template>
              <div class="text-center">
                <v-dialog
                  v-model="note_failed"
                  width="600"
                >
                  <v-card class="Path-359" style="padding:3vh 2vh 3vh 2vh">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                          <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;margin-right: 3vh;">
                          <span class="Active_Companies_title">Delete Note !</span>
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                        <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                          <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="note_failed = false">mdi-close</v-icon>
                        </v-col>
                      </v-row>
                      <v-row wrap style="margin-top:5vh">
                        <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                          <span class="com_nm1">Note deletion failed !</span>
                        </v-col>   
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-row>
                        <v-col sm="6" md="6" lg="6" xs="6" xl="6"></v-col>
                        <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                        </v-col>
                        <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                          <v-btn
                            style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 2.2vh;font-family: Ubuntu;"
                            @click="note_failed = false"
                          >
                            Okay
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>   
                  </v-card>
                </v-dialog>
              </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</v-main>
</template>

<script>
//import { EventBus } from "../event-bus.js";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import postscribe from "postscribe";
export default {
  data() {
    return {
      auth_token: '',
      diseases: [],
      no_authentication: false,
      all_notes: [],
      loading: false,
      fullPage: true,
      page: 1,
      listCount: 0,
      searchme: "",
      panels_show: false,
      panels: {},
      loading_info: false,
      article_type: '',
      article_title: '',
      notes_content_display: '',
      final_statements1: [],
      final_statements2: [],
      title_header: '',
      show_table_modal: false,
      table_display: {},
      containerId: 0,
      closeOnContentClick: false,
      article_types: ["Industry Updates", "Therapy Landscape", "Clinical Review", "Development Pipeline", "Active Companies"],
      selected_type: [],
      alldiseases: [],
      my_diseases: [],
      expanded: true,
      selected_disease: '',
      selected_article_type: '',
      selected_notes_date: '',
      selected_notes_disease: '',
      selected_notes_article_type: '',
      articles: [],
      title_disease : "Showing All Diseases",
      title_article_type : "Showing All Articles",
      disease_clicked: false,
      articletype_clicked: false,
      dis_page: 1,
      art_page: 1,
      show_message: false,
      delete_confirm: false,
      note_deleted: {},
      note_failed: false,
      TotalCount: 0,
      user_plan: '',
      
    }
  },
  components: {
    Loading,
  },
  computed: {
    filteredList() {
      return this.all_notes.filter(item => {
        return JSON.stringify(item).toLowerCase().indexOf(this.searchme.toLowerCase()) > -1
      })
    },
  },
  created() {
  },
  mounted() {
    //this.disease = this.$route.params.disease
    var auth_token = localStorage.getItem("authtoken");

    if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
      this.auth_token = auth_token
      this.no_authentication = false
      // this.save_page_activity()
      this.getdiseases()
      this.getUserPlan()
      var mypage = 1
    } else {
      this.auth_token = ""
      this.no_authentication = true
    }

    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/plotly.js/2.8.3/plotly.min.js')
    document.head.appendChild(recaptchaScript)
  },
  methods: {
    onCancel() {
      console.log('User cancelled the loader.')
    },
    page_scrolling(mypage) { 
      const listElm = document.getElementById('infinite-list1');
      if (listElm !== null) {
        listElm.addEventListener('scroll', e => {
          if(listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
            if (this.auth_token !== null && this.auth_token !== "" && this.auth_token !== undefined) {
              
              if (this.disease_clicked == true) {
                this.dis_page = this.dis_page + 1
                this.get_selected_notes()
              } else if (this.articletype_clicked == true) {
                this.art_page = this.art_page + 1
                this.get_selected_notes()
              } else {
                this.page = this.page + 1
                this.show_all_notes()
              }             
            }
          }
        });
      }
    },
    show_note_area(hist) {
      if (!this.no_authentication) {
        this.expanded = false;
        this.selected_notes_date = hist.notes_date
        this.selected_notes_article_type = hist.title
        this.selected_notes_disease = hist.disease
        this.show_panel(hist)
      }
    },
    getdiseases() {
      this.alldiseases = []
      axios
        .get("/getalldiseases?token=" + this.auth_token)
        .then((res) => {
          //console.log(res.status,"ressss")
          if (res.data.Status != 1) {
              for (var i = 0 ; i < res.data.Data.length; i++) {
                this.alldiseases.push(res.data.Data[i].Name)
              }
              if (this.alldiseases.length > 0) {
                this.get_user_profile()
              }             

            } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
              console.log("invalid user")
            } else {
              console.log("Diseases not found")
            }
      })
      .catch((error) => {
        
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);       
        }
      });
    },
    get_user_profile() {
      axios
        .get("/userProfile?token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status !== 1) {
              this.user = res.data.Data;
                           
              for (var a = 0; a < this.alldiseases.length; a++) {
                for (var b = 0; b < this.user.Diseases.length; b++) {
                  if (this.alldiseases[a] == this.user.Diseases[b]) {
                    this.my_diseases.push(this.alldiseases[a])
                  }
                }
              }             
              var unique_diseases = this.my_diseases.filter(this.onlyUnique);
              this.my_diseases = unique_diseases             
              if (this.my_diseases.length > 0) {
                  var dis = this.my_diseases.sort(function(a, b) {
                    var textA = a.toUpperCase();
                    var textB = b.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                  });
                  this.my_diseases = dis
              }
              this.get_total_count()
              this.show_all_notes()
              var mypage = 1
              this.page_scrolling(mypage) 
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
          } else {
            console.log("profile not found")
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);  
          }
        });
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    get_total_count () {
      axios
        .get("/getallnotescount?token=" + this.auth_token)
        .then((res) => {
          //console.log(res.data,"res.data")
          if (res.data.Status !== 1) {
            this.TotalCount = res.data.Data.total_count
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
          } else {
            console.log("total count not found")
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);       
          }
        });
    },
    select_notes_disease(dis) {
      if (dis == "All Diseases") {
        this.selected_disease = this.my_diseases
        this.title_disease = "Showing All Diseases"
      } else {
        this.selected_disease = dis
        this.title_disease = dis
      }      
      if (this.selected_article_type == '' || this.selected_article_type == undefined || this.selected_article_type == null) {
        this.selected_article_type = this.article_types
      }
      this.closeOnContentClick = true
      this.all_notes = []
      this.listCount = 0
      this.disease_clicked = true
      this.articletype_clicked = false
      this.dis_page = 1
      this.show_message = false
      this.loading = true
      this.get_selected_notes()
    },
    select_article_type(arti) {
      //this.selected_type.push(arti)
      if (arti == "All Articles") {
        this.selected_article_type = this.article_types
        this.title_article_type = "Showing All Articles"
      } else {
        this.selected_article_type = arti
        this.title_article_type = arti
      }
      this.disease_clicked = false
      this.articletype_clicked = true
      if (this.selected_disease == '' || this.selected_disease == undefined || this.selected_disease == null) {
        this.selected_disease = this.my_diseases
      }      
      this.closeOnContentClick = true
      this.all_notes = []
      this.listCount = 0     
      this.articletype_clicked = true
      this.art_page = 1
      this.show_message = false
      this.loading = true
      this.get_selected_notes()
    },
    get_selected_notes() {
      if (this.disease_clicked == true) {
        this.pageno = this.dis_page
      } else if (this.articletype_clicked == true) {
        this.pageno = this.art_page
      } else {
        this.pageno = this.page
      }
      //console.log(this.pageno,"this.pageno")
      axios.get("/getSelectedNotes?token=" + this.auth_token + "&disease=" + this.selected_disease + "&article_title=" + this.selected_article_type + "&page_no=" + this.pageno)
        .then((res) => {
          if (res.data.Status != 1) {
            var all_notes = res.data.Data.notes
            var articles = res.data.Data.articles            
            this.loading = false
            if (all_notes != null) {
              for (var i = 0; i < all_notes.length; i++) {
                for (var j = 0; j < articles.length; j++) {
                  if (all_notes[i].Article_id == articles[j].ID) {
                    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                    var curr_d = new Date(all_notes[i].Created_at),
                      curr_month = '' + (curr_d.getMonth() + 1),
                      curr_day = '' + curr_d.getDate(),
                      curr_year = curr_d.getFullYear();
                    all_notes[i].notes_date = [curr_day, monthNames[curr_d.getMonth()], curr_year].join(' ')
                    all_notes[i].title = articles[j].Title
                    if (all_notes[i].title == "Clinical Outcomes" || all_notes[i].title == "Business Implications" || all_notes[i].title == "Science  &  Technology Development") {
                      all_notes[i].title = "Industry Updates"
                    }
                    all_notes[i].disease = articles[j].Disease
                    if (all_notes[i].Panel !== undefined) {
                      all_notes[i].Panel = JSON.parse(all_notes[i].Panel)
                    }
                  }
                }
                this.all_notes.push(all_notes[i])
              }
              if (this.all_notes !== null && this.all_notes.length > 0) {
                this.listCount = this.all_notes.length
                this.show_message = false
              }

              if (this.listCount == 0) {
                this.show_message = true
              }
              //console.log(this.show_message,"this.show_message")
            } else {
              if (this.listCount == 0) {
                this.show_message = true
              }              
            }
          } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            this.show_message = true
            console.log("Invalid User")
            //this.$router.push("/").catch(() => { })
          } else {
            this.loading = false
            this.show_message = true
            console.log("notes not found")
          }
        })
        .catch((error) => {
          this.show_message = true
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);    
          }
        });
    },
    show_all_notes() {
      //this.all_notes = []
      //this.historyList = []
      this.loading = true
      
      axios.get("/getallnotes?token=" + this.auth_token + "&page_no=" + this.page)
        .then((res) => {
          if (res.data.Status != 1) {
            //console.log(res.data.Data,"dataa")
            var all_notes = res.data.Data.notes
            var articles = res.data.Data.articles            
            this.loading = false
            if (all_notes != null) {
              for (var i = 0; i < all_notes.length; i++) {
                for (var j = 0; j < articles.length; j++) {
                  if (all_notes[i].Article_id == articles[j].ID) {
                    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                    var curr_d = new Date(all_notes[i].Created_at),
                      curr_month = '' + (curr_d.getMonth() + 1),
                      curr_day = '' + curr_d.getDate(),
                      curr_year = curr_d.getFullYear();
                    all_notes[i].notes_date = [curr_day, monthNames[curr_d.getMonth()], curr_year].join(' ')
                    all_notes[i].title = articles[j].Title
                    
                    if (all_notes[i].title == "Clinical Outcomes" || all_notes[i].title == "Business Implications" || all_notes[i].title == "Science  &  Technology Development") {
                      all_notes[i].title = "Industry Updates"
                    }
                   all_notes[i].disease =articles[j].Disease
                    if (all_notes[i].Panel !== undefined) {
                      all_notes[i].Panel = JSON.parse(all_notes[i].Panel)
                    }
                  }
                }
                this.all_notes.push(all_notes[i])
              }

              //console.log(this.all_notes,"this.all_notes")

              if (this.all_notes !== null && this.all_notes.length > 0) {
                this.listCount = this.all_notes.length
                this.show_message = false
              }

              if (this.listCount == 0) {
                this.show_message = true
              }
            } else {
              if (this.listCount == 0) {
                this.show_message = true
              }              
            }
          } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
            //this.$router.push("/").catch(() => { })
          } else {
            this.loading = false
            this.show_message = true
            console.log("notes not found")
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);      
          }
        });
    },
    logout() {
      localStorage.removeItem("authtoken");
      localStorage.removeItem("selectedColorList");
      // localStorage.setItem("selected_article", "Industry Updates");
      this.disesase = "Multiple sclerosis"
      delete axios.defaults.headers.common["Authorization"];
      this.auth_token = null;        

      this.$router.push("/").catch(() => {})
    },
    changetextLength(obj) {
      if (!obj.textLength) {
        obj.textLength = true
      } else {
        obj.textLength = false
      } 
      this.$forceUpdate();
    },
    show_panel(pan) {
      this.notes_content_display = pan.Note
      this.loading_info = false
      // to get article type
      axios
        .get("/getarticletype?token=" + this.auth_token + "&article_id=" + pan.Article_id)
        .then((res) => {
          if (res.data.Status != 1) {
            this.article_type = res.data.Data.Article_type
            this.article_title = res.data.Data.Title
            if (this.article_type == "Web") {
              this.get_article_content(pan.Panel)
            } else {
              this.get_l1l2_content(pan.Panel)
            }            
          } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
          } else {
            console.log("article not found")
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);          
          }
        });
    },
    close_notes_area() {
      this.panels_show = false
      this.notes_content_display = ''
    },
    get_article_content(panel) {
      this.panels_show = true
      this.panels = panel
      this.loading_info = true
      var scripts = ''
      
      scripts = `<script type="text/javascript">
          setTimeout(function(){`

        this.panels.my_panel_id = this.panels.Panel_Id  

        for (var q = 0; q < this.panels.Left.First_Text.length; q++) {
          if (this.panels.Left.First_Text[q].includes("<br>")) {
            var txt1 = this.panels.Left.First_Text[q].split("<br>").join("\n"); 
            this.panels.Left.First_Text[q] = txt1
          }
        }
        for (var q = 0; q < this.panels.Left.Second_Text.length; q++) {
          if (this.panels.Left.Second_Text[q].includes("<br>")) {
            var txt1 = this.panels.Left.Second_Text[q].split("<br>").join("\n"); 
            this.panels.Left.Second_Text[q] = txt1
          }
        }
        for (var q = 0; q < this.panels.Left.Third_Text.length; q++) {
          if (this.panels.Left.Third_Text[q].includes("<br>")) {
            var txt1 = this.panels.Left.Third_Text[q].split("<br>").join("\n"); 
            this.panels.Left.Third_Text[q] = txt1
          }
        }
        for (var q = 0; q < this.panels.Left.Forth_Text.length; q++) {
          if (this.panels.Left.Forth_Text[q].includes("<br>")) {
            var txt1 = this.panels.Left.Forth_Text[q].split("<br>").join("\n"); 
            this.panels.Left.Forth_Text[q] = txt1
          }
        }
        for (var q = 0; q < this.panels.Left.Fifth_Text.length; q++) {
          if (this.panels.Left.Fifth_Text[q].includes("<br>")) {
            var txt1 = this.panels.Left.Fifth_Text[q].split("<br>").join("\n"); 
            this.panels.Left.Fifth_Text[q] = txt1
          }
        }
        for (var i = 0; i < this.panels.Right.Display.Display_Info.length; i++) {
          // to separate count and text for infographic panel 0
          if (this.panels.Right.Display.Display_Type == 'Card') {
            this.panels.Right.Display.plot_display = true
            if (this.panels.Right.Display.Display_Info[i].Text != "" && this.panels.Right.Display.Display_Info[i].Value_Type == "text") {
                var count = this.panels.Right.Display.Display_Info[i].Text.substring(0, this.panels.Right.Display.Display_Info[i].Text.indexOf(' '));
                this.panels.Right.Display.Display_Info[i].count = count
                this.panels.Right.Display.Display_Info[i].count = count              
                var rem = this.panels.Right.Display.Display_Info[i].Text.substring(this.panels.Right.Display.Display_Info[i].Text.indexOf(' ') + 1);
                this.panels.Right.Display.Display_Info[i].rem_text = rem
              }
              
          }

          if (this.panels.Right.Display.Display_Type == 'Text Box' && this.panels.Right.Display.Display_Info[i].Text !== '') {
            if (this.panels.Right.Display.Display_Info[i].Text.length > 1050) {
              var newText = this.panels.Right.Display.Display_Info[i].Text.substring(0, 1050);
              this.panels.Right.Display.Display_Info[i].newText = newText
              this.panels.Right.Display.Display_Info[i].textLength = false
              this.panels.Right.Display.Display_Info[i].no_show = false  
            } else {
              var newText = this.panels.Right.Display.Display_Info[i].Text
              this.panels.Right.Display.Display_Info[i].newText = newText
              this.panels.Right.Display.Display_Info[i].textLength = false
              this.panels.Right.Display.Display_Info[i].no_show = true
            }
            this.panels.Right.Display.plot_display = true
          }

          
          // to get base64 image
          if (this.panels.Right.Display.Display_Info[i].Value_Type == "image") {
            //console.log("image")
            var image = new Image();
            image.src = 'data:image/png;base64,'+this.panels.Right.Display.Display_Info[i].Plot_Data            
            this.panels.Right.Display.Display_Info[i].myimage = image
            //console.log(image,"myimage")
          }

          if (this.panels.Right.Display.Display_Type == "Table") {
            this.panels.Right.Display.Display_Info[i].table_type = this.panels.Right.Display.Display_Info[i].Table_Data.Table_Type
            if (this.panels.Right.Display.Display_Info[i].Additional_Info !== undefined) {
              var myObj = this.panels.Right.Display.Display_Info[i].Additional_Info
              if (Object.keys(myObj).length > 0) {
                this.panels.Right.Display.Display_Info[i].show_dropdown = true
                var drpdons = this.panels.Right.Display.Display_Info[i].Additional_Info.Dropdowns
                this.panels.Right.Display.Display_Info[i].Dropdownnames = []
                for (var d = 0; d < drpdons.length; d++) {
                  var drp = {}
                  if (d == 0) {
                    drp.selected = true
                  } else {
                    drp.selected = false
                  }
                  drp.name = drpdons[d]
                  this.panels.Right.Display.Display_Info[i].Dropdownnames.push(drp)
                }

                this.panels.Right.Display.Display_Info[i].N_Rows = this.panels.Right.Display.Display_Info[i].Additional_Info.N_Rows
                var first_half = this.panels.Right.Display.Display_Info[i].N_Rows/2
                var all_rows = this.panels.Right.Display.Display_Info[i].Table_Data.Rows
                var first_half_rows = []
                for (var v = 0; v < first_half; v++) {
                   first_half_rows.push(all_rows[v])
                }
                this.panels.Right.Display.Display_Info[i].Table_Data.first_half_rows = first_half_rows
                var second_half_rows = []
                for (var w = first_half; w < all_rows.length; w++) {
                   second_half_rows.push(all_rows[w])
                }
                this.panels.Right.Display.Display_Info[i].Table_Data.second_half_rows = second_half_rows
                this.panels.Right.Display.Display_Info[i].Table_Data.show_rows = this.panels.Right.Display.Display_Info[i].Table_Data.first_half_rows

              } else {
                this.panels.Right.Display.Display_Info[i].show_dropdown = false
              }
            } else {
              this.panels.Right.Display.Display_Info[i].show_dropdown = false
            }
            this.panels.Right.Display.Display_Info[i].myTable = "mytable"
            this.panels.Right.Display.Display_Info[i].search_found = false
            var row_data = this.panels.Right.Display.Display_Info[i].Table_Data.Rows
            if (row_data.length > 0) {
              this.panels.Right.Display.plot_display = true
              for (var k = 0; k < row_data.length; k++) {
                var mysrch1 = "\n"
                var matstr = ","
                for (var l = 0; l < row_data[k].length; l++) {
                  if (row_data[k][l].includes(mysrch1)) {
                    const pieces = row_data[k][l].split(mysrch1);
                    const resultingString = pieces.join("\n");
                    // if (resultingString.includes(matstr)) {
                    //   const pieces1 = resultingString.split(matstr);
                    //   const resultingString1 = pieces.join(", ");
                    //   resultingString = resultingString1
                    // }
                    row_data[k][l] = resultingString
                  }
                }
              }
            }
            //this.panels[j].Right.Display.Display_Info[i].Table_Data.Rows = row_data
          }


          // to display financial cards info

          if (this.panels.Right.Display.Display_Type == 'Financial_Cards' && (this.panels.Right.Display.Display_Info[0].Fin_Card_Data !== undefined || this.panels.Right.Display.Display_Info[1].Table_Data !== undefined))  {
            //console.log("hi finance")
            var finance_data = this.panels.Right.Display.Display_Info[i].Table_Data
            this.panels.Right.Display.plot_display = true
            if (finance_data !== undefined){
              //console.log(finance_data.Columns, "columns")
              this.panels.Right.Display.Display_Info[i].Columns = finance_data.Columns 
              this.panels.Right.Display.Display_Info[i].Rows = finance_data.Rows
             }
          }

          // to display plot

          if (this.panels.Right.Display.Display_Type == 'Plot' && this.panels.Right.Display.Display_Info[i].Plot_Data !== "") {
              //console.log("Plooot")
               var double_encode = this.panels.Right.Display.Display_Info[i].Plot_Data.split('.')[0]
               var plt_data = window.atob(double_encode);

               //console.log(plt_data,"plt_data")

               var obj = JSON.parse(plt_data)

               //console.log(obj,"obj")

               var plot_data = obj.div64.split('.')[0]
               var div = window.atob(plot_data);

               div = div.replace(/\\"/g, '"')
               div = div.replace(/\\'/g, "'")
               div = div.replace(/\r?\n|\r/g, "\n")

               this.panels.Right.Display.Display_Info[i].div64 = div
               this.panels.Right.Display.plot_display = true

               //console.log(this.panels.Right.Display.Display_Info[i].div64,"div")

               var legend = obj.legend

               //console.log(legend,"legend")
            
               if (legend !== undefined) {
                var colors = {}   
                var code = Object.keys(legend);
                var name = Object.values(legend);
                var color_name = []
                var color_code = []
                for (var z=0; z < name.length; z++) {
                  if (name[z] == "Phase 1") {
                    color_name[0] = name[z]
                    color_code[0] = code[z]
                  } else if (name[z] == "Phase 2") {
                    color_name[1] = name[z]
                    color_code[1] = code[z]
                  } else if (name[z] == "Phase 3") {
                    color_name[2] = name[z]
                    color_code[2] = code[z]
                  } else if (name[z] == "Phase 4") {
                    color_name[3] = name[z]
                    color_code[3] = code[z]
                  } else if (name[z] == "Early Phase 1") {
                    color_name[4] = name[z]
                    color_code[4] = code[z]
                  } else {
                    color_name.push(name[z])
                    color_code.push(code[z])
                  }
                }

                this.panels.Right.Display.Display_Info[i].code = color_code
                this.panels.Right.Display.Display_Info[i].name = color_name
              }

               var script_data = obj.script64.split('.')[0]
               var script = window.atob(script_data);

               script = script.replace(/\\"/g, '"')
               script = script.replace(/\\'/g, "'")
               script = script.replace(/\r?\n|\r/g, "\n")

               this.panels.Right.Display.Display_Info[i].script64 = script

               // console.log(this.panels[j].Right.Display.Display_Info[i].script64,"script")
               //console.log(this.panels[j].Panel_Id,"panels id")

               var str = this.panels.Right.Display.Display_Info[i].script64.replace('<script type="text/javascript">', "\n")
               var str1 = str.replace("window.PLOTLYENV=window.PLOTLYENV || {};","\n")
               var str2 = str1.replace("<\/script>", "\n")
               scripts += str2
           }
        }

        if (this.panels.Panel_Info == 'Full_Panel') {
          this.all_phases = []
          this.all_companies = []
          if (this.panels.Right.Display.Display_Type == "Table") {
            for (var q = 0; q < this.panels.Right.Display.Display_Info.length; q++) {
              this.all_companies = this.panels.Right.Display.Display_Info[q].Table_Data.Columns
              var rw = this.panels.Right.Display.Display_Info[q].Table_Data.Rows
              for (var r = 0; r < rw.length; r++) {
                var phases = {}
                var key = Object.keys(rw[r]);
                var value = rw[r][key];
                phases.names = key
                phases.details = value
                this.all_phases.push(phases)
              }
            }
          }
      }
      scripts = scripts + `}, 500);<\/script>`
      //console.log(this.panels,"panels")
      postscribe('#gist', scripts)
      
    }, 
    get_l1l2_content(panel) {
      this.panels_show = true
      this.panels = panel
      this.loading_info = true
      this.final_statements1 = panel.first_sec
      this.final_statements2 = panel.second_sec
      //this.title_header = panel.Header
    },   
    format_text(txt) {
      var newStr = ""
      if (txt.includes("<br>")) {
        var txt1 = txt.split("<br>").join("\n");
        newStr = txt1.split("Drug names:").join("\n <b>Drug names:</b>");
      }
      this.formated_text = newStr
    },
    sort_rows(n, mid) {
      //console.log(n, mid, "datatatt")
      var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
      table = document.getElementById(mid);    
      rows = [...table.rows];
      var tableSort = (arr) => Object.keys(arr).map((item, index) => ({item, index})).sort((a,b) => {
        if(this.dir == "asc"){
          var oa = table.rows[a.index].getElementsByTagName("TD")[n];
          var ob = table.rows[b.index].getElementsByTagName("TD")[n];
          if(oa && ob){
            return ob.innerText.toLowerCase().localeCompare(oa.innerText.toLowerCase())
          }
        }else{
          var oa = table.rows[a.index].getElementsByTagName("TD")[n];
          var ob = table.rows[b.index].getElementsByTagName("TD")[n];
          if(oa && ob){
            return oa.innerText.toLowerCase().localeCompare( ob.innerText.toLowerCase())
          }
        }
      }).map(({index})=> table.rows[index]);

      var newrows = tableSort(rows);
      if ( this.dir == "asc"){
        this.dir = "desc"
      }else{
        this.dir = "asc"
      }
      while (table.rows.length > 1) {
        table.rows[1].remove();
        //console.log("hello")
      }
      for(var i=0; i<newrows.length; i++){
        table.rows[0].insertAdjacentElement("afterend",newrows[i])
      }

    },
    onChangetrials(nms,tab_id) {
      //console.log(nms,tab_id,"nmss")
      
        for (var i = 0; i < this.panels.Right.Display.Display_Info.length; i++) {
          if (this.panels.Right.Display.Display_Type == "Table") {
            if (tab_id == this.panels.Right.Display.Display_Info[i].myTable) {
              if (this.panels.Right.Display.Display_Info[i].Additional_Info !== undefined) {
                var drop_nms = this.panels.Right.Display.Display_Info[i].Dropdownnames
                  if (nms.name == drop_nms[0].name) {
                    this.panels.Right.Display.Display_Info[i].Table_Data.show_rows = []
                    this.panels.Right.Display.Display_Info[i].Table_Data.show_rows = this.panels.Right.Display.Display_Info[i].Table_Data.first_half_rows
                    
                  }
                  if (nms.name == drop_nms[1].name) {
                    this.panels.Right.Display.Display_Info[i].Table_Data.show_rows = []
                    this.panels.Right.Display.Display_Info[i].Table_Data.show_rows = this.panels.Right.Display.Display_Info[i].Table_Data.second_half_rows
                  }
                  for (var k = 0; k < drop_nms.length; k++) {
                    if (nms.name == drop_nms[k].name) {
                      drop_nms[k].selected = true
                    } else {
                      drop_nms[k].selected = false
                    }                   
                  }
                }               
              }
            }
          }
        this.$forceUpdate();
    },
    show_table(table_data) {
      this.show_table_modal = true
      this.table_display = table_data
    },
    delete_my_note(mynote) {
      this.delete_confirm = true
      this.note_deleted = mynote
    },
    delete_note(note) {
      //console.log(note,"noteeee")
      this.delete_confirm = false
      axios
        .post("/deletenote?token=" + this.auth_token + "&note_id=" + note.ID)
        .then((res) => {
          if (res.data.Status != 1) {
            //console.log("success")
            if (res.data.Data.Message == "Success") {
              this.all_notes = []
              this.page = 1
              this.get_total_count()
              this.show_all_notes()
            } else {
              this.note_failed = true
              console.log("note deletion failed")
            }            
          } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
          } else {
            this.note_failed = true
            console.log("note deletion failed")
          }
        })
        .catch((error) => {
          this.note_failed = true
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);          
          }
      });
    },
    save_page_activity() {
      //console.log("page activity")
      var disease = ""
      var articleid = ""
      var duration = "0"
      var panel_duration = "0"
      var activity_name = "Page Change"
      var panel_number = "0"
      var pagename = "Notes"
      axios
        .post("/SaveActivity?id=" + articleid + '&token=' + this.auth_token + "&activity_name=" + activity_name + "&disease=" + disease + "&page_name=" + pagename + "&duration=" + duration + "&panel_duration=" + panel_duration)
        .then((res) => {
          if (res.data.Status != 1) {
            //console.log("Activity saved successfully")
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
          } else {
            console.log("Activity save failed")
          }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message); 
        }
      });
    },
    getUserPlan(){
      //create a get request to get the user plan
      axios.get("/getPlan?token=" +this.auth_token ).then((response) => {
        if(response.data.Status !=1)
        console.log(response.data,"response.data")
        this.user_plan = response.data.Data.Plan
        this.$forceUpdate();
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.margin_align {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
}

.left {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 20px;
  padding-top: 20px;
  margin: 0vh 0vh 5vh 0vh !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.leftPanelExpand {
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 20px;
  padding-top: 20px;
}

.right {
  width: 70%;
  position: absolute;
  right: 0;
  overflow-y: hidden;
  background: white;
  padding-bottom: 5px;
  box-shadow: -5px 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.text_notes {
  font-size: 16px;
  font-weight: 500;
  color: #49575d;
  margin-bottom:1vh !important;
}

.show_article {
  font-size: 14px;
  font-weight: 500;
  color: #00b2ba;
  margin-bottom:1vh !important;
  cursor: pointer
}

.v-card-text {
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  float: left;
  color: #49575d;
  padding: 16px 0px 8px;
  background: white;
}

.col-md-2 {
  flex: 1 0 auto;
  width: 12.66666667%;
}

.aa {
  background: #009ea5 !important;
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff !important;
  text-transform: none;
}

.bb {
  background: #009ea5 !important;
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

table tbody {
  display: table;
  width: 100%;
}

table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  width: 98%;
  margin: 2vh;
  max-height: 90vh;
  overflow-y: auto;
  table-layout:fixed; 
  border-collapse:collapse;
}

td {
  padding: 1vh;
  font-size: 16px;
  color: #333;
  border: 1px solid #D7DBDD;
}

tr {
  border-top: 1px solid #D7DBDD;
  font-size: 16px;
  color: #333;
  white-space: pre;
  background-color: #ffffff;
}

th {
  text-align: center;
  padding: 1vh;
  background-color: #ebf3f7;
  font-family: Ubuntu;
  border: 1px solid #D7DBDD; 
}

tr:nth-child(odd) {
  background-color: #f3fbfc;
}

.line {
  border: 1px solid #797C7D;
  background-color: #797C7D !important;
  margin: 0.5rem 0;
}

.pre-formatted {
  white-space: pre-wrap;
  /* 👈 this is the important part */
}

.news_heading {
  margin: 0px 0px 18px 0px;
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #374246;
}

.title1 {
  font-family: Ubuntu;
  font-size: 13px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
 
  color: #384348;
}

.titleCenter {
  text-align: center;
  margin:0 0.5vw;
}

.cardTitles {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #49575d;
}

.cardTitles1 {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #49575d;
}

.itemCountDesign {
  font-family: Ubuntu;
  font-size: 25px;
  font-weight: 500;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #374246;
  margin-left: 4px;
}

.pharmaContent {
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #49575d;
  margin-top: -5vh;
}

.header_text {
  text-transform: capitalize;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #49575d;
  margin-left: 15vh
}

.linktitle {
  margin: 5vh 45px 10px 0;
  font-family: Ubuntu;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #374246;
}

.hrLine {
  border-bottom: 2px solid #374246;
  margin: 0rem 0;
  max-width: 5vw;
  justify-content: center;
  display: flex;
  color:#374246 !important
}

.hrdialogLine {
  border-bottom: 1px solid #374246;
  margin: 0rem 0;
  width:100%;
  justify-content: center;
  display: flex;
  color:#374246 !important
}

.Rectangle-718 {
  width: 30px;
  height: 30px;
  padding: 3px 5px 5px 9px!important;
  background-color: #d5f8fa;
}


.titleCenter {
  margin-top: 3vh;
  text-align: center;
}

.table_header {
  background-color: #3fa6a1;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  word-wrap:break-word !important;
  white-space: -o-pre-wrap; 
  word-wrap: break-word;
  white-space: pre-wrap; 
  white-space: -moz-pre-wrap; 
  white-space: -pre-wrap; 
  word-wrap:break-word !important;
}

.circle {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  border: 2px solid;
  float: left;
  margin: 10px 3px 0px 0px;
}

.text_left {
  font-weight: 600;
  color: #49575d;
  font-size: 16px
}

.appBarBox {
  height: 90px;
  padding-left: 2%;
  padding-right: 1%;
  margin-bottom: 15px;
  margin-top: -3vh !important;
  margin-left: -3vh;
  background-color: #f8fafb;
}

.optionMenu {
  color: #009ea5;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 600;
}

.popUpVersion {
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #404040;
  opacity: 0.86;
  text-align: center !important
}

.popUpDates {
  opacity: 0.86;
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #404040;
  float: left;
}

.breadcrums {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  text-decoration: none;
}

.v-breadcrumbs__item {
  color: #009ea5 !important
}

.v-breadcrumbs__item v-breadcrumbs__item--disabled {
  color: #333333 !important
}

.notes_section {
  height: 100vh !important;
  //background-color: #f9f9fb;
  z-index: 2;
  margin-top:2vh;
  font-family:Ubuntu;
  overflow-x: hidden
}

.comments_list {
  padding: 1vh 1vh 5vh 1vh;
  height: 75vh;
  max-height: 75vh;
  overflow-y: auto;
  background: inherit;
}

.Rectangle-913 {
  margin: 2vh;
  padding: 0vh 2vh 1.5vh 2vh;
  border-radius: 18px;
  border: solid 1px #ccc;
  background-color: #fff;
}

.date_notes {
  font-size: 13px;
  font-weight: 500;
  color: #8c8e91 !important;
  margin-left: 5vh
}

.tablePhases1 {
  color: #333;
  background-color: #f8fafb;
  font-size: 16px;
  border: none !important;
  border-top: 1px solid #f8fafb !important;
}

.tablePhases2 {
  background-color: #f3fbfc;
}

.mech2Btn {
  background-color: rgba(255, 103, 80, .1);
  border-color: #ff6750;
  font-size: 16px;
  font-weight: 500;
  color: #ff6750;
  font-family: Ubuntu;
  border-radius: 10px;
  padding: 1vh !important;
  height: 100% !important;
}

.tablePhases {
  position: sticky;
  z-index: 1;
  left: 0;
  border-top: 1px solid #D7DBDD !important;
  border-bottom: 1px solid #D7DBDD !important;
  border-left: 1px solid #D7DBDD !important;
}

.tablenonphase {
  background-color: inherit;
  font-size: 16px;
  font-weight: 700
}

.fix {
  position: absolute;
  margin-left: -100px;
  width: 100px;
}

.dualHeaderRow {
  background-color: #3fa6a1;
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  text-transform: capitalize;
  border-bottom: 1px solid #D7DBDD !important;
  border-left: 1px solid #D7DBDD !important;
  position: sticky;
  z-index: 1;
  left: 0;
}

.dualHeaderAnotherRow {
  background-color: #3fa6a1;
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  text-transform: capitalize;
}

.dualHeaderData {  
  background-color: #d2edee;
  font-size: 13px;
  font-weight: 700;
  border-top: 1px solid #D7DBDD !important;
  border-bottom: 1px solid #D7DBDD !important;
  border-left: 1px solid #D7DBDD !important;
  position: sticky;
  z-index: 1;
  left: 0;
  word-wrap:break-word !important;
  white-space: -o-pre-wrap; 
  word-wrap: break-word;
  white-space: pre-wrap; 
  white-space: -moz-pre-wrap; 
  white-space: -pre-wrap; 
  word-wrap:break-word !important;
}

.dualHeaderAnotherData {
 
  background-color: inherit;
  font-size: 13px;
  font-weight: 500;
  
  //width: 100%;
  word-wrap:break-word !important;
  white-space: -o-pre-wrap; 
  word-wrap: break-word;
  white-space: pre-wrap; 
  white-space: -moz-pre-wrap; 
  white-space: -pre-wrap; 
  word-wrap:break-word !important;
}

.finaTableWrap{
  word-wrap:break-word !important;
  white-space: -o-pre-wrap; 
  word-wrap: break-word;
  white-space: pre-wrap; 
  white-space: -moz-pre-wrap; 
  white-space: -pre-wrap; 
  word-wrap:break-word !important;
}

.finathWrap{
  word-wrap:break-word !important;
  white-space: -o-pre-wrap; 
  word-wrap: break-word;
  white-space: pre-wrap; 
  white-space: -moz-pre-wrap; 
  white-space: -pre-wrap; 
  word-wrap:break-word !important;
}

.icon {
  font-size: 30px;
}
.finance_text {
  font-family: Ubuntu;
  font-weight: 500;
  font-size: 16px
}
.fincard {
  margin: 0.5vh;
  padding:2vh;
  height:70vh;
  overflow-y:scroll;
}

.Path-359 {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #ff6750 !important;
  background-color: #fff;
  border-radius: 20px !important;
}

.Active_Companies_title {
  margin: 2vh 2vh 2vh 0vh;
  font-family: Ubuntu;
  font-size: 2.8vh;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: left;
  color: #ff6750;
}
.Path-85 {
  box-shadow: none !important;
  margin: 2vh;
  padding: 1vh 3vh 2vh 3vh;
  border: solid 0.6px #d3d3d3 !important;
  background-color: #fdfefe;
  height: 40vh;
  border-radius: 7px !important;
}
.com_nm {
  margin-top: 3vh;
  font-size: 2.2vh;
  font-weight: 700;
  font-family: Ubuntu;
  text-align: center;
  color: #383838 !important;
  height: 4vh;
}
.com_nm1 {
  margin-top: 2vh;
  font-size: 2.3vh;
  font-weight: 500;
  font-family: Ubuntu;
  text-align: center;
  color: #383838 !important;
}
.noteBox {
  position:absolute;
  float:bottom;
  bottom:80px;
}
.even_panels {
  background-color:#f8fafb;
  margin:0px -10px 6px 0px;
  padding: 0px 30px 40px 70px;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.16) !important;
}
.odd_panels {
  background-color:#fff;
  margin:0px -10px 6px 0px;
  padding: 0px 30px 40px 70px;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
}

.Path-499 {
  height: 30px;
  margin: -25px 5.5px 15px 0px;
  padding: 2px 3px 3px 2px;
  box-shadow: 0 2px 3px 0 rgba(88, 88, 88, 0.16) !important;
  border: solid 0.7px #cfd3d5;
  border-radius: 7px;
  background-color: #fff !important;
}
.link_content {
  font-family: Ubuntu;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.34;
  letter-spacing: normal;
  text-align: left;
  color:#32393c !important;
  font-size: 13px;
  text-decoration: none;
}
.textbox_content {
  font-family: Ubuntu;
  font-size: 15px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #384348;
}
.texttitle {
  font-family: Ubuntu;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #374246;
  margin-top:2vh
}
.Rectangle-721 {
  margin-top:0px;
  background-color: #ffffff !important;
  top:0; 
  margin-left:5vh  
}
.Rectangle-905 {
  padding: 2vh 0vh 2vh 2vh;
  border-radius: 16px;
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.22);
  border: solid 2px #222545;
  background-color: #f2fcfc;
  margin-top:5px;
  max-height:70vh;
}
.Rectangle-903 {
    height: 42px;
    margin: -2vh 0vh 0vh -2vh;
    padding: 1vh 0vh 0.5vh 2vw!important;
    font-size: 18px;
    text-align: left;
    font-weight: 700;
    color: #ffffff;
    background-color: #00b2ba;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
}
.Notes-Centre-101 {
  font-family: Ubuntu;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
  margin-left:3vh
}

.Showing-All-Diseases {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #f16753;
}
.Path-704 {
  box-shadow: 0 4px 9px 0 rgba(36, 36, 36, 0.16);
  border: solid 1px #d9d7d7;
  background-color: #fff;
  border-radius: 12px;
}
.Path-736 {
  min-height: 80px;
  padding:0vh 2vh 1vh 2vh;
  background-color: #f5f5f5;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.Added-on {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.53;
  letter-spacing: normal;
  text-align: left;
  color: #a0a0a0;
}
.notes_Added_on {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.53;
  letter-spacing: normal;
  text-align: left;
  color: #a0a0a0;
  margin-left: 3vh;
}
.Article-Type {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: normal;
  text-align: left;
  color: #80808b;
  margin-top:0vh !important
}
.Therapy-Landscape-type {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}
.note_content_type {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
  margin-left: 3vh;
}
.notes_text {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c48;
}

.dropdownStyle {
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 500;
  color: #49575d !important;
  text-transform: capitalize !important;
}
.Rectangle-1062 {
  height: 125px;
  padding: 20px 12px 10px 12px;
  background-color: #f5f5f5 !important;
}
</style>