<template>
  <v-main>
    <div style="margin-left: -4vw;">
    
    <div class="appLayout">
        <div class="margin_align">
          <loading :active.sync="loading" 
            :can-cancel="true" 
            :on-cancel="onCancel"
            :is-full-page="fullPage"></loading>
          <div id="mist">
            <v-row wrap>
              <!--  <v-col cols="12" md="2" lg="2" sm="2" style="height:100vh" >
               </v-col> -->
              <v-col cols="12" md="3" lg="3" sm="3" style="height:100vh;background-color: #edf7f9 !important;" v-show="!my_notes_section">
                <div style="margin: 1.5vh 1.5vw 1.8vh 5.5vw !important;">
                  <h5 class="header_text" v-if="display_articles.length == 0">Article coming soon !</h5>
                </div>
                <v-row wrap style="background-color: inherit;" class="ml-6 mr-6">
                <div class="leftSidePanel"  id="infinite-list">
                  <v-row wrap>
                    <v-col cols="12" md="12" lg="12" sm="12" v-for="(dis,kc) in display_articles" :key="kc" v-bind:class= "dis.selected ?'selectedCard':'unselectedCard'" >
                     <v-card class= "Rectangle-888" @click="view_details_section(dis)">
                      <div :class="dis.selected ?'company_alpha_selected' : 'company_alpha'">{{dis.title_value}}</div>
                      <v-row wrap>
                          <v-col cols="12" md="8" lg="8" sm="8">
                            <span class="date" >{{dis.date}} </span>
                          </v-col>
                          <v-col class="pull-right" cols="12" md="4" lg="4" sm="4" v-if="!no_authentication">
                              <img alt="share" src="../assets/Icons/Path 468.svg" class="Group-505" style="width:0.8vw;height:1.7vh;cursor:pointer;margin-left:2vh" @click.stop="show_share_modal(dis.Article_id)">
                              <img v-show="!dis.Followed" src="../assets/Icons/Path 338.svg" @click.stop="followme(dis)" style="width: 1.7vh;height:1.7vh;cursor:pointer;margin-left:0.5vw">
                              <img src="../assets/Icons/Group 593.svg" v-show="dis.Followed" style="width:1.7vh;height:1.7vh;margin-left:0.5vw">
                           </v-col>
                           <v-col class="pull-right" cols="12" md="4" lg="4" sm="4" v-if="no_authentication">
                              <img alt="share" src="../assets/Icons/Path 468.svg" class="Group-505" style="width:0.8vw;height:1.7vh;cursor:pointer" @click.stop="login()">
                              <img v-show="!dis.Followed" src="../assets/Icons/Path 338.svg" @click.stop="login()" style="width: 1.7vh;height:1.7vh;cursor:pointer;margin-left:0.5vw">
                              <img src="../assets/Icons/Group 593.svg" v-show="dis.Followed" style="width:1.7vh;height:1.7vh;margin-left:0.5vw">
                           </v-col>
                        </v-row>
                      </v-card>                     
                    </v-col>
                  </v-row>
                </div>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" md="3" lg="3" sm="3" v-show="my_notes_section" class="notes_section mb-8 pb-8 mt-3">
            <div style="margin-left:5vw;height:100%">
            <v-row wrap>
              <v-col cols="12" md="10" lg="10" sm="10">
                <div style="font-size: 18px;font-weight: 700;color: #49575d;">Notes</div>
              </v-col>
             
                <v-col cols="12" md="2" lg="2" sm="2">
                        <v-icon style="cursor:pointer;float: right;" color="#009ea5"
                          @click="close_notes_section()">mdi-close</v-icon>
                      </v-col>
             
            </v-row>
            <v-row justify="center" align="center" v-if="all_notes == null" style="margin-top: 20vh;">
              <v-col cols="12" md="2" lg="2" sm="2"></v-col>
              <v-col cols="12" md="8" lg="8" sm="8" class="justify-center align-center" align-self="center"
                        style="display:block; align-items: center; justify-content: center" align="center">

                        <img src="../assets/Icons/Group 598.svg">
                        <p
                          class="emptyNotePara">
                          You have not added any notes yet. Please start making notes for this article </p>

                      </v-col>
              <v-col cols="12" md="2" lg="2" sm="2"></v-col>
            </v-row>
            <v-row v-if="all_notes != null">
              <v-list v-chat-scroll class="comments_list mb-0" >
                        <v-list-item>
                          <v-row no-gutters>
                            <v-col cols="12" sm="12" md="12" lg="12">
                              <v-list v-for="(all, key) in all_notes" v-bind:data="all" v-bind:key="key"
                                class="Rectangle-913">
                                <v-row>
                                  <v-col cols="12" sm="12" md="12" lg="12" xs="12">
                                    <div class="text_notes">{{ all.Note }}</div>
                                    <span class="date_notes">{{ all.notes_date }}</span>
                                  </v-col>
                                </v-row>
                              </v-list>
                            </v-col>
                          </v-row>
                        </v-list-item>
                      </v-list>
            </v-row>
            <div class="noteBox">
                      <v-text-field 
                     v-model="mynote" v-on:keyup="validateNote"
                        class="mb-3 shrink notesTextField" placeholder="Make your notes here...." solo
                        prepend-inner-icon="mdi-note-plus-outline"></v-text-field>
                    </div>
                  </div>
           
          </v-col> -->
          <v-col cols="12" md="9" lg="9" sm="9" class="Rectangle-721" v-show="!loading && display_articles.length > 0">
            <div class="menu1" @click="showqueModal()">
              <!-- <i class="fa fa-copy fa-2x" id="copy-btn"></i> -->
              <img src="../assets/Icons/Group 1102.svg" style="height:3.5vh;width:3.5vh">
            </div>    
            <v-row style="margin-left: 3vh !important;">
              <v-col cols="12" md="8" lg="8" sm="8" class="Rectangle-721">
                <v-row wrap style="margin-top:0vh;top:0px;height:60px;overflow:hidden">
                  <v-col cols="12" md="9" lg="9" sm="9">
                    <span class="summary">Summary</span>
                  </v-col>                
                  <v-col cols="12" md="3" lg="3" sm="3" style="float:right;right:0px;">
                    <img alt="notes" @click="show_notes(final_statements1, final_statements2, dis_article_id, notes_title)" src="../assets/Icons/Path 533.svg" style="width:17px;height:17px;cursor:pointer;margin-left:2vh">
                    <img alt="share" src="../assets/images/share.png" class="Group-505" style="width:15px;height:15px;margin-left: 3vh !important;cursor:pointer" @click="show_share_modal(dis_article_id)">
                    <img v-if="!dis_follow" src="../assets/Icons/Path 338.svg" style="width:15px;height:15px;margin-left: 3vh;" @click="follow_me_right(dis_article_id)">
                    <img src="../assets/Icons/Group 593.svg" v-if="dis_follow" style="width:15px;height:15px;margin-left: 3vh;">
                  </v-col>                 
                </v-row>
                <div style="overflow-y: auto;overflow-x:hidden;margin-top: 10px; height: 82vh;"  @mouseup="showque">
                  <v-row v-for="(finb,key1) in final_statements1" :key="key1">
                    <div style="font-family: Ubuntu;font-size: 16px;font-weight: 500;text-align: left;color: #49575d;" v-html="finb.div" v-if="finb.type !== 'string'"></div>
                    <div v-if="finb.type == 'string'">
                        <span style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="finb.newText" v-if="!finb.textLength"></span>
                        <span style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="finb.Text" v-if="finb.textLength"></span>

                        <span @click="changetextLength(finb)" class="pointer" v-if="!finb.textLength && !finb.no_show">...<span style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;margin-left:1vh">See More</span><br></span>
                        <span @click="changetextLength(finb)" class="pointer" v-if="finb.textLength && !finb.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;">&nbsp;&nbsp;See Less<br></span>
                      </div>                  
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" md="4" lg="4" sm="4">
                <div class="Rectangle-905">
                  <div class="Rectangle-903" style="top:0px;overflow:hidden">Impact Assessment</div>
                  <div style="background-color: #f2fcfc;padding: 2vh 1vw 2vh 1vh;overflow-y: auto; max-height: 80vh;" @mouseup="showque">
                      <v-row v-for="(fib,keyb) in final_statements2" :key="keyb">
                      <!-- <v-row v-for="(fib,keyb) in final_statements2" :key="keyb"> -->
                        <div v-if="fib.type == 'plot'">
                          <v-row wrap>
                            <v-col cols="12" md="12" lg="12" sm="12">
                              <!-- <div style="font-family: Ubuntu;font-size: 16px;font-weight: 500;text-align: left;color: #49575d;margin-bottom: 15vh;" v-html="fib.div"></div> -->
                            </v-col>
                          </v-row>
                        </div>
                        <div v-if="fib.type == 'image'">
                          <v-row wrap>
                            <v-col cols="12" md="2" lg="2" sm="2"></v-col>
                            <v-col cols="12" md="8" lg="8" sm="8">
                              <div style="font-family: Ubuntu;font-size: 16px;font-weight: 500;text-align: left;color: #49575d;" v-html="fib.div"></div>
                            </v-col>
                            <v-col cols="12" md="2" lg="2" sm="2"></v-col>
                          </v-row>
                        </div>
                        <div  v-if="fib.type == 'table'">
                          <v-row>
                            <span @click="show_table(fib.div)" style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #00b2ba;margin-bottom: 3vh;cursor: pointer;">See table here</span>
                          </v-row>
                        </div>
                        <div style="font-family: Ubuntu;font-size: 16px;font-weight: 500;text-align: left;color: #49575d;" v-html="fib.div" v-if="fib.type != 'plot' && fib.type != 'image' && fib.type != 'table' && fib.type !== 'string'"></div>
                        <div v-if="fib.type == 'string'">                           
                          <span style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;" v-html="fib.newText" v-if="!fib.textLength"></span>
                          <span style="font-family: Ubuntu;font-size: 15px;font-weight: 500;text-align: left;color: #49575d;margin-bottom: 5vh;" v-html="fib.Text" v-if="fib.textLength"></span>
                          <span @click="changetextLength(fib)" class="pointer" v-if="!fib.textLength && !fib.no_show">...<span style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;margin-left:1vh">See More<br></span></span>
                          <span @click="changetextLength(fib)" class="pointer" v-if="fib.textLength && !fib.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;">&nbsp;&nbsp;See Less</span>
                        </div>
                      </v-row>
                    </div>
                  </div>
                </v-col>
              </v-row>
              </v-col>
            </v-row>
          </div> 
                
          
          <template>
            <div class="text-center">
              <v-dialog width="600" v-model="dialog">
                <v-card>
                  <v-card-title class="text-h5">
                    <span style="color: #49575d;margin-left: 2vh;;font-size: 18px;">Share</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="close_share()">
                      <v-icon color="#009ea5">mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-row style="margin-top:-3vh">
                      
                      <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                        <v-textarea
                          name="input-7-1"
                          class="mt-2"
                          v-model="quote_text"
                          type="text"
                          placeholder="Enter text and choose your social media platform to share"
                          outlined
                          dense
                          v-on:keyup="validateQuote"
                          style="margin-left:2vh;margin-right:2vh"
                        ></v-textarea>
                        <!-- <p style="font-size: 16px;font-weight:500;text-align:center">{{send_msg}}</p> -->
                      </v-col>
                      
                    </v-row>
                    <v-row wrap class="ml-2" style="margin-top:-5vh">
                      <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
                      <v-col cols="12" sm="8" md="8" lg="8" xs="8" xl="8">
                        <v-row wrap>
                          <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2" v-for="network in networks"
                            :key="network.network"
                          >
                          <ShareNetwork
                            :network="network.name"
                            :url= "myurl"
                            title="Knolens" 
                            :description="quote_text"
                            quote=""
                            hashtags=""
                          >
                            <div @click="share_article(network.name)">
                              <v-avatar
                              size="45"
                              class="mx-3 mb-3"
                              :color="network.color"
                              >
                                <i class="icon" :class="network.icon" color="white"></i>
                              </v-avatar>
                              
                              <!-- <div>
                                <span style="color: #49575d;text-align:center !important;margin-left:1vh"> {{network.name}} </span>
                              </div> -->
                            </div>
                            </ShareNetwork>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2"></v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
        </template>
        <template>
          <div class="text-center">
            <v-dialog
              v-model="show_table_modal"
              width="800"
            >
            <v-card class="Path-359" style="padding:3vh 3vh 3vh 6vh !important">
                <div><v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="show_table_modal = false">mdi-close</v-icon></div>
                <v-row wrap>
                  <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                      <div v-html="table_display"></div>
                    </v-col>   
                  </v-row>
              </v-card>
            </v-dialog>
          </div>
        </template>
        <template>
          <div class="text-center">
            <v-dialog width="600" v-model="show_notes_modal">
                <v-card>
                  <v-card-title class="text-h5">
                    <span style="color: #49575d;margin-left: 2vh;;font-size: 18px;">Notes</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="show_notes_modal = false">
                      <v-icon color="#009ea5">mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12">
                        <v-textarea
                          name="input-7-1"
                          class="mt-2"
                          v-model="mynote"
                          type="text"
                          placeholder="Enter notes here"
                          outlined
                          dense
                          style="margin-left:2vh;margin-right:2vh;font-weight: 500"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                  
                  <v-row style="margin-top: -5vh;margin-bottom: 2vh;margin-right:2vh">
                    <v-col sm="9" md="9" lg="9" xs="9" xl="9">
                      <p style="margin-left:5vh;margin-right:2vh;text-align: left;font-family:Ubuntu;font-size: 15px">{{notes_msg}}</p>
                    </v-col>
                    <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                      <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh;" :class="{ disabled: isDisabled }" :disabled="isDisabled" @click="savenote(notes_panel)">Save</v-btn>
                    </v-col>
                    <!-- <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                      <v-btn style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 2.2vh;font-family: Ubuntu;" @click="show_notes_modal = false">Cancel
                      </v-btn>
                    </v-col> -->
                  </v-row>
                </v-card-actions>  
                </v-card>
              </v-dialog>
          </div>
        </template>
        
       
          <QnAModal ref="QnAModal"></QnAModal>
       
      
       </div>
       </div>
    </div>
  </v-main>
</template> 
<script>
//import { EventBus } from "../event-bus.js";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import postscribe from "postscribe";
import QnAModal from "@/components/QnAModal.vue";
export default {
  data() {
    return {
      items_bread: [
        {
          text: 'Home',
          disabled: false,
          href: '/#/',
        },
      ],
      loading: false,
      myjson: {},
      my_new_json: {},
      page_title: '',
      panels: [],
      version: '',
      page_type: '',
      link: '',
      templates: [],
      data_columns:[],
      data_rows: [],
      first_image: {},
      initial_version: "1.0",
      show: false,
      final_statements1: [],
      final_statements2: [],
      below:  false,
      fullPage: true,
      disesase: '',
      my_title: '',
      follows: [],
      dis_display_date : '',
      dis_follow: false,
      dis_article_id: '',
      mynote: "",
      all_notes: [],
      my_notes_section: false,
      notes_article_id: '',
      dialog: false,
      networks: [
        // {
        //   network: "facebook",
        //   name: "Facebook",
        //   icon: "fab fa-facebook-f",
        //   color: "#1877f2",
        // },

        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "fab fa-linkedin",
          color: "#007bb5",
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "fab fa-whatsapp",
          color: "#25d366",
        },
        // {
        //   network: "messenger",
        //   name: "Messenger",
        //   icon: "fab fa-facebook-messenger",
        //   color: "#0084ff",
        // },
        {
          network: "skype",
          name: "Skype",
          icon: "fab fa-skype",
          color: "#00aff0",
        },
        // {
        //   network: "telegram",
        //   name: "Telegram",
        //   icon: "fab fa-telegram-plane",
        //   color: "#0088cc",
        // },
        {
          network: "twitter",
          name: "Twitter",
          icon: "fab fa-twitter",
          color: "#1da1f2",
        },
        {
          network: "email",
          name: "Email",
          icon: "fa fa-envelope",
          color: "#1da1f2",
        },
      ],
      myurl: "",
      modal: false,
      shared_article_id: '',
      quote_text: '',
      quotetext: '',
      send_msg: '',
      article_number: '',
      display_articles : [],
      no_authentication: false,
      show_clinical: false,
      show_business: false,
      show_science: false,
      show_table_modal: false,
      table_display: {},
      show_notes_modal: false,
      notes_panel: {},
      notes_msg: "",
      notes_title: "",
      //qna
      queans_modal: false,
      my_word: "",
      queans: [],
      myquestion: "",
      show_que: false,
      pageX: 0,
      pageY: 0,
      outputcpy: "",
      checkboxData:[],
      loadingModal: true,
      questionIds: [],
      option:"fda",
      question: "",
      loadingAnswer: false,
      option_lables: [
        {
          name: "Labels",
          status: "selected"
        },
        {
          name: "Clinical Studies",
          status: "notselected"
        },
        {
          name: "PubMed",
          status: "notselected"
        },
        {
          name: "Patents",
          status: "notselected"
        },
      ],
      resetCheckbox: false,
      user_plan: '',
    };
  },

  components: {
    QnAModal,
    //   AppBar,
    //   AppBar,
    Loading
  },
  computed: {
    isDisabled() {
      if (this.mynote !== '' && this.mynote !== ' ') {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    //this.auth_token = Vue.prototype.$AuthToken
  
    var title = this.$route.query.article_title
    var disesase = this.$route.query.disease
    this.article_number = this.$route.query.article_number

    var auth_token = localStorage.getItem("authtoken");

    this.domain_origin = window.location.origin

    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/plotly.js/2.8.3/plotly.min.js')
    document.head.appendChild(recaptchaScript)

    this.title = title.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');

    if (this.title == "Clinical Outcomes") {
      this.show_clinical = true
    }
    if (this.title == "Business Implications") {
      this.show_business = true
    }
    if (this.title == "Science  &  Technology Development") {
      this.show_science = true
    }

    this.disesase = disesase.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
      this.auth_token = auth_token
      this.no_authentication = false

      this.get_breadcrum_item(this.title)
      this.get_articles_subtype(this.title)
      this.getUserPlan()
    } else {
      this.auth_token = ""
      this.no_authentication = true
      this.disesase = 'Multiple Sclerosis'
      //this.get_articles_subtype_no_auth(this.title,mypage)
    }

  },
  methods: {
    changetextLength(obj) {
      if (!obj.textLength) {
        obj.textLength = true
      } else {
        obj.textLength = false
      } 
      this.$forceUpdate();
    },
    save_page_activity(article_id) {
      var disease = this.disesase
      var articleid = article_id
      var duration = "0"
      var panel_duration = "0"
      var activity_name = "Page Change"
      var panel_number = "0"
      var pagename = this.title
      //console.log(pagename,"pagename")
      axios
        .post("/SaveActivity?id=" + articleid + '&token=' + this.auth_token + "&activity_name=" + activity_name + "&disease=" + disease + "&page_name=" + pagename + "&duration=" + duration + "&panel_duration=" + panel_duration)
        .then((res) => {
          if (res.data.Status != 1) {
          console.log("Activity saved successfully")
        } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
          console.log("Invalid User")
          //this.$router.push("/").catch(() => {})
        } else {
          console.log("Activity save failed")
        }
      })
      .catch((error) => {        
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);     
        }
      });
    },
    onCancel() {
      console.log('User cancelled the loader.')
    },
    gotopage(link) {
      //console.log(link,"link")
      if (link !== '') {
        if (link.includes("Disease")) {
          this.$router.push("/Disease").catch(() => { })
        } else {
          this.$router.push("/").catch(() => { })
        }
      }
    },
    // To display breadcrums
    get_breadcrum_item (title) {
      for (var i = 0; i < 2; i++) {
        var bread = {}
        if (i == 0) {
          bread.text = this.disesase
          bread.href = "/#/Disease"
        } else {
          bread.text = title
          bread.href = ""
        }
        bread.disabled = false
        this.items_bread.push(bread)
      }
    },
    view_details_section (article) {
      this.dis_display_date = article.date
      this.dis_follow = article.Followed
      this.dis_article_id = article.Article_id
      this.notes_title = article.title_value
      //console.log(this.dis_article_id,"this.dis_article_id")
      this.get_article_link(article)
    },

    // To get link of json file from aws
    get_article_link(art){
      //console.log(art.ID,"article")
      var activity_name = ''
      if (art.Title == "Business Implications") {
        activity_name = "Business Implications"
      } else if (art.Title == "Clinical Outcomes") {
        activity_name = "Clinical Outcomes"
      } else if (art.Title == "Science  &  Technology Development") {
        activity_name = "Science  &  Technology Development"
      } else {
        console.log("Title is not matching")
      }
      for (var i = 0 ; i < this.display_articles.length; i++) {
        if (art.ID == this.display_articles[i].ID) {
          art.selected = true
        } else {
          this.display_articles[i].selected = false
        }
      }     
      this.loading = true
      this.link = art.S3_key
      //console.log(this.link,"link")

      // To save activity
      // this.save_page_activity(art.ID)
    
      // To get json link
      axios
        .get("/getArticleUrlL1L2?s3_link=" + this.link +"&token=" + this.auth_token)
        .then((res) => {
          if (res.data.Status != 1) {
            //console.log(res.data.Data.Url,"article link found")
            fetch(res.data.Data.Url)
            .then(res => res.json())
            .then((out) => {
              var my_json = out
              this.get_article_content(my_json, art.Title)
            })
            .catch(err => { throw err });
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
            //this.$router.push("/").catch(() => {})
          } else {
            console.log("Article link not found")
          }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);             
        }
      });
    },

    // To display article content 
    get_article_content(myjson, pgtitle) {
      this.myjson = myjson
      //var scripts = ''
      this.loading = true
      var len = Object.keys(this.myjson).length;
      var myval = Object.values(this.myjson)
      this.version = this.myjson.Software_Version
      this.page_type = this.myjson.Type

      // this.page_title = this.myjson.Article_Title.Title
      // this.article_title = this.myjson.Article_Title.Template
      // this.mandatory_var = this.myjson.Article_Title.Mandatory_Variables
      // var img = this.myjson.Article_Title.Image

      this.page_title = this.myjson.Card.Title
      this.article_title = this.myjson.Card.Template
      this.mandatory_var = this.myjson.Card.Mandatory_Variables
      var img = this.myjson.Card.Image

      var image = new Image();
      //image.src = 'data:image/png;base64,'+ img
      image.src = img
      this.first_image = image
      
      //console.log(pgtitle,"pgtitle")
      this.final_statements1 = []
      this.final_statements2 = []

      this.templates = this.myjson.Templates
      var scripts = `<script type="text/javascript">setTimeout(function(){`
      
      // to get values of article title

      for (var i = 0; i < this.mandatory_var.length; i++) {
        var name = this.mandatory_var[i].Name
        var type = this.mandatory_var[i].Type
        var default_value = this.mandatory_var[i].Default_Values
        var match_string = '{{' + type + '__'+ name + '}}'
        //var match_string = '%%{' + type + '__'+ name + '}%%'
        //console.log(match_string,"match_string")
        if (this.article_title.includes(match_string)) {
          if (type == "string") {
            default_value = this.capitalizeFirstLetter(default_value)
          }
          let result = this.article_title.replace(match_string, default_value);
          this.article_title = result
        }
      }
        

      this.l1 = this.templates[0]

      // to make string new lines separate

      var mysrch1 = "\\n"

      // to get values of L1 template
      this.l1_man_var = this.l1.Mandatory_Variables
      
      var statement = this.l1.Template.split(mysrch1)
      
      for (var k = 0;  k < statement.length; k++) {
        var resultingString1 = statement[k]
        var exp_obj = {}
        for (var j = 0; j < this.l1_man_var.length; j++) {
          var name = this.l1_man_var[j].Name
          var type = this.l1_man_var[j].Type      
          var default_value = this.l1_man_var[j].Default_Values
          var match_string = '{{' + type + '__'+ name + '}}'
          //var match_string = '%%{' + type + '__'+ name + '}%%'
          if (type == "int" || type == "string" || type == "date") {
            if (statement[k].includes(match_string)){
              var pieces1 = resultingString1.split(match_string);
              if (type == "string") {
                default_value = this.capitalizeFirstLetter(default_value)
              }
              resultingString1 = pieces1.join(default_value);
              exp_obj.div = '<p>'+resultingString1+'</p>'
              exp_obj.type = 'string'
              
              var whole_string = '<br><span style="font-size:15px;">'+resultingString1+'</span>'
              exp_obj.Text = whole_string

              var newText = whole_string
              exp_obj.newText = newText
              exp_obj.textLength = false
              exp_obj.no_show = true


              // var charCount = 0
              // var comma_position = 0
              // for (var c = 0; c < exp_obj.Text.length; c++) {
              //   if (exp_obj.Text[c] == ',') {
              //     charCount = charCount + 1
              //     if (charCount == 10) {
              //       comma_position = c
              //     }
              //   }
              // }
              
              // if (charCount >= 10) {
              //   var newText = whole_string.substring(0, comma_position);
              //   exp_obj.newText = newText
              //   exp_obj.textLength = false
              //   exp_obj.no_show = false
              // } else {
              //   var newText = whole_string
              //   exp_obj.newText = newText
              //   exp_obj.textLength = false
              //   exp_obj.no_show = true
              // }

              if (exp_obj.div.includes(below_string) ) {
                exp_obj.below = true
              } else {
                exp_obj.below = false
              }
            }
          } else if (type == "hyperlink") {
              if (statement[k].includes(match_string)){
                var pieces1 = resultingString1.split(match_string);
                //console.log(this.domain_origin,"this.domain_origin")

                var lnk = this.domain_origin+ '/#/' + default_value.Link

                if (default_value.Link.includes("https://")) {
                  lnk = default_value.Link
                }

                //console.log(lnk,"lnk")

                var  hyp1 = `<a target='_blank' href="${lnk}">${default_value.Text}</a>`;

                resultingString1 = pieces1.join(hyp1); 
                exp_obj.div =  resultingString1 
                exp_obj.type = 'link'       
              }
          } else if (type == "table") {
              if (statement[k].includes(match_string)){
                var data_columns1 =  default_value.Table_Data.Columns
                var data_rows1 = default_value.Table_Data.Rows
                var div333 = this.displayShelfItemPairs(data_columns1, data_rows1)
                var div444 = div333.outerHTML
                exp_obj.type = 'table'
                exp_obj.div = `${div444}`
                //console.log(div444,"d444") 
              }
          } else if (type == "image") {
            if (statement[k].includes(match_string)){
              //console.log("imagess")
              var image1 = new Image();

              image1.src = 'data:image/png;base64,'+ default_value
              exp_obj.div = `<img
                          src="${image1.src}"
                          alt="image"
                          style="height: 200px;200px;margin-top:3vh;margin-bottom:3vh"
                          />`
              exp_obj.type = "image"
            }
          } else if (type == "plot"){
              if (statement[k].includes(match_string)) {
                //console.log(default_value,"plttt")
                var double_encode = default_value.split('.')[0]
                var plt_data = window.atob(double_encode);

                //console.log(plt_data,"plt_data")
                if (plt_data != '') {
                  var obj = JSON.parse(plt_data) 

                  var plot_data = obj.div64.split('.')[0]
                  var div = window.atob(plot_data);

                  div = div.replace(/\\"/g, '"')
                  div = div.replace(/\\'/g, "'")
                  div = div.replace(/\r?\n|\r/g, "\n")

                  var div64 = div

                  //console.log(div64,"div")

                  var script_data = obj.script64.split('.')[0]
                  var script = window.atob(script_data);

                  script = script.replace(/\\"/g, '"')
                  script = script.replace(/\\'/g, "'")
                  script = script.replace(/\r?\n|\r/g, "\n")

                  var script64 = script

                  //console.log(script64,"script")
                  var str = script64.replace('<script type="text/javascript">', "\n")
                  var str1 = str.replace("window.PLOTLYENV=window.PLOTLYENV || {};","\n")
                     var str2 = str1.replace("<\/script>", "\n")
                     scripts += str2

                  var pieces1 = resultingString1.split(match_string);
                  resultingString1 = pieces1.join(div64);
                  exp_obj.div = resultingString1
                  exp_obj.type = "plot"
                }
              }
            }  
        }
        this.final_statements1.push(exp_obj)  
                
      }
      this.loading = false

      //console.log(this.final_statements1,"final_statements1")

      // to get values of L2 template
      this.l2 = this.templates[1] 
      
      this.l2_man_var = this.l2.Mandatory_Variables

      var statement2 = this.l2.Template.split(mysrch1)
      var below_string = "Below is the plot"
      
      for (var k = 0;  k < statement2.length; k++) {
        var resultingString2 = statement2[k]
        var exp_obje = {}
        for (var j = 0; j < this.l2_man_var.length; j++) {
          var name = this.l2_man_var[j].Name
          var type = this.l2_man_var[j].Type      
          var default_value = this.l2_man_var[j].Default_Values
          var match_string = '{{' + type + '__'+ name + '}}'
          //var match_string = '%%{' + type + '__'+ name + '}%%'
          if (type == "int" || type == "string" || type == "date") {
            if (statement2[k].includes(match_string)){
              var pieces2 = resultingString2.split(match_string);
              if (type == "string") {
                default_value = this.capitalizeFirstLetter(default_value)
              }
              resultingString2 = pieces2.join(default_value);
              exp_obje.div = '<p>'+resultingString2+'</p>'
              exp_obje.type = 'string'

              var whole_string = '<br><span style="font-size:15px;">'+resultingString2+'</span>'
              exp_obje.Text = whole_string
              var charCount = 0
              var comma_position = 0
              for (var c = 0; c < exp_obje.Text.length; c++) {
                if (exp_obje.Text[c] == ',') {
                  charCount = charCount + 1
                  if (charCount == 3) {
                    comma_position = c
                  }
                }
              }
              
              if (charCount >= 4) {
                var newText = whole_string.substring(0, comma_position);
                exp_obje.newText = newText
                exp_obje.textLength = false
                exp_obje.no_show = false
              } else {
                var newText = whole_string
                exp_obje.newText = newText
                exp_obje.textLength = false
                exp_obje.no_show = true
              }

              if (exp_obje.div.includes(below_string) ) {
                exp_obje.below = true
              } else {
                exp_obje.below = false
              }  
            }
          } else if (type == "hyperlink") {
            if (statement2[k].includes(match_string)){
              var pieces2 = resultingString2.split(match_string);

              //console.log(this.domain_origin,"this.domain_origin")
              var lnk = this.domain_origin+ '/#/' + default_value.Link

              if (default_value.Link.includes("https://")) {
                lnk = default_value.Link
              }
              //console.log(lnk,"lnk")
              var  hyp2 = `<a target='_blank' href="${lnk}">${default_value.Text}</a>`;

              resultingString2 = pieces2.join(hyp2); 
              exp_obje.div =  resultingString2 
              exp_obje.type = 'link'       
            }
          } else if (type == "table") {
            if (statement2[k].includes(match_string)){
              //exp_obje.data_columns = default_value.Table_Data.Columns
              //exp_obje.data_rows = default_value.Table_Data.Rows
              exp_obje.type = 'table'
              var data_columns2 =  default_value.Table_Data.Columns
              var data_rows2 = default_value.Table_Data.Rows
              var div111 = this.displayShelfItemPairs(data_columns2, data_rows2)
              var div222 = div111.outerHTML
              exp_obje.div = `${div222}`
              //console.log(div111,"dvvvvv") 
            }
          } else if (type == "image") {
            if (statement2[k].includes(match_string)){
              //console.log("imagess")
              var image2 = new Image();

              image2.src = 'data:image/png;base64,'+ default_value
              exp_obje.div = `<img
                          src="${image2.src}"
                          alt="image"
                          style="height: 200px;200px;margin-top:3vh;margin-bottom:3vh"
                          />`
              exp_obje.type = "image"
            }
          } else if (type == "plot"){
            if (statement2[k].includes(match_string)) {
              //console.log(default_value,"plottttttttt")
              var double_encode = default_value.split('.')[0]
              var plt_data = window.atob(double_encode);

              //console.log(plt_data,"plt_data")
              if (plt_data != '') {
                var obj = JSON.parse(plt_data) 

                var plot_data = obj.div64.split('.')[0]
                var div = window.atob(plot_data);

                div = div.replace(/\\"/g, '"')
                div = div.replace(/\\'/g, "'")
                div = div.replace(/\r?\n|\r/g, "\n")

                var div64 = div

                //console.log(div64,"div")

                var script_data = obj.script64.split('.')[0]
                var script = window.atob(script_data);

                script = script.replace(/\\"/g, '"')
                script = script.replace(/\\'/g, "'")
                script = script.replace(/\r?\n|\r/g, "\n")

                var script64 = script

                //console.log(script64,"script")
                var str = script64.replace('<script type="text/javascript">', "\n")
                var str1 = str.replace("window.PLOTLYENV=window.PLOTLYENV || {};","\n")
                   var str2 = str1.replace("<\/script>", "\n")
                   scripts += str2

                var pieces2 = resultingString2.split(match_string);
                resultingString2 = pieces2.join(div64);
                exp_obje.div = resultingString2
                exp_obje.type = "plot"
              }
            }
          }
          
        }

        this.final_statements2.push(exp_obje)
      }

      //console.log(this.final_statements2,"final_statements2")
      scripts = scripts + `}, 3000);<\/script>`
      //console.log(scripts,"scripts")
      postscribe('#mist', scripts)
    },

    // To display content in table
    displayShelfItemPairs(col, rw) {
      this.data_columns = col
      this.data_rows = rw
      // To make table
      var table = document.createElement("table");
      table.setAttribute('id', 'bom_table');
      table.setAttribute("style", "display: block;overflow-x: auto; margin-top:2vh; margin-bottom:2vh; border-collapse:collapse; table-layout:fixed;max-height:60vh");
      var tr = table.insertRow(-1);
      for (var i = 0; i < this.data_columns.length; i++) {
        var th = document.createElement("th");
        th.innerHTML = this.data_columns[i];
        th.setAttribute("style", "min-width:10vw;padding:2vh;font-family:Ubuntu;font-size: 14px;color: #ffffff;border: 1px solid #ffffff;background-color: #3fa6a1;text-transform: capitalize;margin-top:2vh !important");
        tr.appendChild(th);
      }

      for (var i = 0; i < this.data_rows.length; i++) {
        tr = table.insertRow(-1);
        for (var j = 0; j < this.data_rows[i].length; j++){
          var tabCell = tr.insertCell(-1);
          tabCell.innerHTML = this.data_rows[i][j]
          tabCell.setAttribute("style", "min-width:10vw;padding:2vh;font-family:Ubuntu;font-size: 14px;color: #333;border: 1px solid #D7DBDD;text-transform: capitalize;word-wrap:break-word;");
        }
      }                    
      return(table); 
    },

    // To get articles subtype wise
    get_articles_subtype(mytitle) {
      //this.loading = true;
      setTimeout(e => {
        this.my_title = mytitle
        var all_articles = []
        var all_metadata = []
        var art_title = ''

        axios
          .get("/getAllL1L2ArticlesLatest?token=" + this.auth_token + "&article_title="+ this.my_title + "&disease=" + this.disesase + "&article_number=" + this.article_number)
          .then((res) => {
            //console.log(res.data.Data,"res.data.Data")
            if (res.data.Status != 1) {
              this.loading = false
              all_articles = res.data.Data.articles
              all_metadata = res.data.Data.articles_metadata
              art_title = res.data.Data.article_title
              
              if (all_articles != null) {
                for (var i = 0; i < all_articles.length; i++) {
                  for (var j = 0; j < all_metadata.length; j++) {
                    if (all_articles[i].ID === all_metadata[j].Article_id) {
                      var my_date = new Date (all_articles[i].Created_at)
                      const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
                      const mon = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sept","Oct","Nov","Dec"];
                      var year = my_date.getFullYear();
                      var mes = my_date.getMonth();
                      let name = month[mes];
                      var mon_name = mon[mes]
                      var dia = my_date.getDate();
                      var fecha = dia+"-"+name+"-"+year;
                      var fecha_mon = dia+" "+mon_name+" "+year;

                      var image2 = new Image();

                      image2.src = all_metadata[j].Tiles[0].Value.Image
                      
                      all_metadata[j].display_img = image2

                      var arti_titl = all_metadata[j].Tiles[0].Text

                      var manditory_var = all_metadata[j].Tiles[0].Value.Mandatory_Variables
                      var title_value

                      for (var s = 0; s < manditory_var.length; s++) {
                        var namee = manditory_var[s].Name
                        var typee = manditory_var[s].Type
                        var default_valuee = manditory_var[s].Default_Values
                        var match_string = '{{' + typee + '__'+ namee + '}}'
                        if (arti_titl.includes(match_string)) {
                          var pieces1 = arti_titl.split(match_string);
                          arti_titl = pieces1.join(default_valuee);
                          all_metadata[j].title_value = arti_titl
                        }
                      }
                      
                      all_metadata[j].date = fecha
                      all_metadata[j].date_sm = fecha_mon
                      all_metadata[j].selected = false
                      all_metadata[j].title_value = all_metadata[j].title_value
                      all_metadata[j].Title = all_articles[i].Title
                      all_metadata[j].Followed = false
                      this.display_articles.push(all_metadata[j])
                    }
                  }
                }
                //console.log(this.display_articles.length,"display_articles len")

                this.dis_display_date = this.display_articles[0].date
                this.dis_follow = this.display_articles[0].Followed
                this.dis_article_id = this.display_articles[0].Article_id
                this.notes_title = this.display_articles[0].title_value
                this.get_article_link(this.display_articles[0])
                
              } else {
                this.display_articles = []
                console.log("Latest articles not found")
              }
              this.get_all_follows()
            } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
              console.log("Invalid User")
              //this.$router.push("/").catch(() => {})
            } else {
              console.log("Articles not found")
              this.loading = false
            }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);        
          }
        });
      //this.loading = false;
      }, 200);
    },

    // To create follow article
    followme(art) {
      //console.log(art,"article")
      axios
        .post("/createfollow?token=" + this.auth_token + "&article_id="+ art.Article_id + "&disease=" + this.disesase)
        .then((res) => {
          if (res.data.Status != 1) {
            //console.log("success follow")
            art.Followed = true
            this.dis_follow = true
            for (var i = 0; i < this.display_articles.length; i++){
                if (art.Article_id == this.display_articles[i].Article_id) {
                  this.display_articles[i].Followed = true
              }
            }
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
            //this.$router.push("/").catch(() => {})
          } else {
            art.Followed = false
            console.log("failure follow")
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);        
          }
        });
    },
    follow_me_right(artid) {  
      //console.log(artid,"artid")    
      axios
        .post("/createfollow?token=" + this.auth_token + "&article_id="+ artid + "&disease=" + this.disesase)
        .then((res) => {
          if (res.data.Status != 1) {
            this.dis_follow = true
            for (var i = 0; i < this.display_articles.length; i++){
                if (artid == this.display_articles[i].Article_id) {
                  this.display_articles[i].Followed = true
              }
            }
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
            //this.$router.push("/").catch(() => {})
          } else {
            console.log("failure follow")
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);      
          }
        });
    },

    // To get follows
    get_all_follows () {
      axios
        .get("/getAllFollows?token=" + this.auth_token + "&disease=" + this.disesase) 
        .then((res) => {
          if (res.data.Status != 1) {
            this.follows = res.data.Data.follows
            //console.log(this.follows,"Follows found")
            if (this.follows !== null) {
              for (var i = 0; i < this.display_articles.length; i++) {
                  for (var j = 0; j < this.follows.length; j++) {
                    if (this.display_articles[i].Article_id == this.follows[j].Article_id && this.follows[j].Followed == true) {
                      this.display_articles[i].Followed = true
                    }
                  }
                }

              if (this.display_articles[0] !== undefined) {
                this.dis_follow = this.display_articles[0].Followed
              }
            }
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
            //this.$router.push("/").catch(() => {})
          } else {
            console.log("Follows not found")
          }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);        
        }
      });
    },
    show_notes (st1,st2,artid,notestitle) {
      if (!this.no_authentication) {
        var panell = []
        this.notes_article_id = artid
        this.show_notes_modal = true;
        this.mynote = ""
        this.notes_msg = ""
        this.notes_panel.first_sec = st1
        this.notes_panel.second_sec = st2
        this.notes_panel.Header = notestitle
        //console.log(this.notes_panel,"this.notes_panel")
      }
    },
    savenote(notespanel) {
      //console.log(this.mynote,this.disesase,this.notes_article_id,notespanel,"this.note")
      var notespanel = JSON.stringify(notespanel)

      let senddata = {
        article_id : this.notes_article_id,
        disease : this.disesase,
        note: this.mynote,
        notes_panel: notespanel
      }

      let url = `/createnote?token=` + this.auth_token
      let config = { headers: { 'Content-Type': 'application/json' } }
      axios.post(url, JSON.stringify(senddata), config).then((res) => {

          if (res.data.Status != 1) {
            //console.log("note saved successfully")
            //this.show_all_notes()
            this.show_notes_modal = false
            this.notes_msg = "Your note have been saved successfully !"
          } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            console.log("Invalid User")
            //this.$router.push("/").catch(() => { })
          } else {             
            console.log("could not save notes")
            this.notes_msg = "Could not save your note !"
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 500") {
            console.log(error.message);        
          }
        });
    },
    close_notes_section() {
      console.log("close notes")
      this.my_notes_section = false
    },
    copyText() {
      navigator.clipboard.writeText(this.url);
    },
    show_share_modal(art_id) {
      this.quote_text = ''
      this.send_msg = ''
      this.dialog = true
      this.shared_article_id = art_id
      this.myurl= this.domain_origin + "/#/Share/" + this.shared_article_id
      //this.myurl= "http://localhost:8080/#/Share/" + this.shared_article_id
    },
    close_share() {
      this.dialog = false
      this.network_items = false
    },
    share_article(social) {
      this.dialog = false
      var medium = "social media " + social
      var panel_shared = ""
      var duration = 0
      var panel_duration = 0
      axios
        .post("/SaveActivity?id=" + this.shared_article_id + '&token=' + this.auth_token + "&activity_name=" + medium + "&disease=" + this.disesase + "&panel_number=" + panel_shared + "&page_name" + this.article_title + "&panel_duration=" + panel_duration + "&duration=" + duration)
        .then((res) => {
          if (res.data.Status != 1) {
          console.log("Activity saved successfully")
          this.network_items = false
        } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
          console.log("Invalid User")
          //this.$router.push("/").catch(() => {})
        } else {
          console.log("Activity save failed")
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 500") {
          console.log(error.message);     
        }
      });
    },
    validateQuote(e) {
      if (e.keyCode === 13) {
        this.quotetext = this.quote_text
        this.send_msg = "Your message has been added. Please choose social media platform"
      } else {
        console.log("entering quote");
      }
    },
    addQuote() {
      this.quotetext = this.quote_text
      this.send_msg = "Your message has been added. Please choose social media platform"
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    logout() {
      localStorage.removeItem("authtoken");
      localStorage.removeItem("selectedColorList");
      // localStorage.setItem("selected_article", "Industry Updates");
      this.disesase = "Multiple sclerosis"
      delete axios.defaults.headers.common["Authorization"];
      this.auth_token = null;        
      
      this.$router.push("/").catch(() => {})
    },
    show_table(table_data) {
      this.show_table_modal = true
      this.table_display = table_data
    },
    //qna section
   
    showque(c) {
      if (this.auth_token !== null && this.auth_token !== "" &&this.auth_token !== undefined) {
      console.log("showque")
      this.my_word = this.getSelectedText()
      // localStorage.setItem("my_word", this.my_word);
      let selectedText = this.my_word.toString();
      console.log(selectedText,"selectedText")
      var menu = document.querySelector(".menu1");
      if (selectedText !== "") {
        
        // if (c==1){
        //  rect = document.querySelector(".text").getBoundingClientRect();
        // //  menu.style.left = this.pageX - Math.round(rect.left) + "px";
        // //  menu.style.top = this.pageY - Math.round(rect.top) - 5 + "px";
        // }else if (c==2){
        //    rect = document.querySelector(".text2").getBoundingClientRect();
        //   //  menu.style.left = this.pageX - Math.round(rect.left)+60 + "vh";
        //   //  menu.style.top = this.pageY - Math.round(rect.top) - 5 + "px";
        // }else if(c==3){
        //   rect = document.querySelector(".text3").getBoundingClientRect();
        // }else if(c==4){
        //   rect = document.querySelector(".text4").getBoundingClientRect();
        // }

        let sel = window.getSelection();
        let getRange      = sel.getRangeAt(0);
        let rect = getRange.getBoundingClientRect();
        menu.style.top=rect.top - 92 + 'px',
        menu.style.left=( rect.left + (rect.width * 0.5)-410) + 'px'
        menu.style.display = "block";
  
        menu.style.display = "block";
       
        console.log(menu.style.display)
        
      } else {
        menu.style.display = "none";
      }

      //document.getElementById("output").innerHTML = selectedText;

      if (selectedText !== "") {
        this.outputcpy = selectedText;
      } else {
        this.outputcpy = "";
      }
     
    // console.log(this.outputcpy,"outputcpy")

      // var popup = document.getElementById("mypopup");
      // var copybtn = document.getElementById("copy-btn");

      // copybtn.addEventListener("click", () => {
      //   this.copyfieldvalue();
      //   popup.style.display = "block";
      // });
      // var span = document.getElementsByClassName("close-btn")[0];

      // span.addEventListener("click", () => {
      //   popup.style.display = "none";
      // });

      // window.addEventListener("click", (event) => {
      //   if (event.target == popup) {
      //     popup.style.display = "none";
      //   }
      // });
     // console.log(selectedText,".....")

      // var highbtn = document.getElementById("highlight-btn");
      // highbtn.addEventListener("click", () => {
       

      // });
      console.log("showqueModal")
     
        //call viraj's api here
        // console.log(selectedText,".....")
        // console.log(this.outputcpy,"outputcpy")
      // if (myy !== "") {
      //   this.queans_modal = true
      // }      
      this.$forceUpdate();
      }
    },
    showqueModal(){
      // this.queans_modal = true
      console.log(this.queans_modal)
      var menu = document.querySelector(".menu1");
      menu.style.display = "none";

      if(this.user_plan=='Basic Plan'){
        this.queans_modal = true
      }else{
        this.queans_modal = false
      }
      this.$refs.QnAModal.showquesModal(this.outputcpy,this.queans_modal);


    },
    getSelectedText() {
      let selection = document.getSelection();
      console.log(selection,"selection")
      let selRange = selection.getRangeAt(0);
      console.log(selRange,"selRange")
      return selRange;  
    },
    getUserPlan(){
      //create a get request to get the user plan
      axios.get("/getPlan?token=" +this.auth_token ).then((response) => {
        if(response.data.Status !=1)
        console.log(response.data,"response.data")
        this.user_plan = response.data.Data.Plan
        this.$forceUpdate();
      })
    }
  }
};
</script>
<style lang="scss" scoped>
  .margin_align {
    margin: 50px 0vw 0vh 0vh !important
  }
  .Line-242 {
    height: 1px;
    margin: 15px 0px 15px 0px;
    background-color: #cdcdd3;
  }
  .newall {
    margin: 50px 0vw 0vh 0vh !important;
    border-radius: 6px;
    background-color: #00b2ba;
    color: #ffffff;
    text-align: center;
    height:35px;
  }
  .all {
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 700 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #525252 !important;
  }
  .hr_all {
    height: 1.5px;
    width: 50% !important;
    margin-left: 0vh;
    background-color: #ff6750 !important;
  }
  .hr_business {
    height: 1.5px;
    width: 90% !important;
    margin-left: 1vh;
    background-color: #f66b67 !important;
  }
  .hr_clinical {
    height: 1.5px;
    width: 90% !important;
    margin-left: 1vh;
    background-color: #ffb345 !important;
  }
  .hr_tech {
    height: 1.5px;
    width: 90% !important;
    margin-left: 1vh;
    background-color: #44b3b8 !important;
  }
  .Rectangle-721 {
    //height: 90vh;
    margin-top:0px;
    background-color: #ffffff !important;
    top:0;   
  }
  .cards_section {
    max-height:100vh;
    overflow-y: auto;
    padding: 2vh;
    background-color: #f9f9fb !important;
  }
  .impact_crds {
    background-color:  #f9ffff;
    height: 100vh;
    overflow-y: auto
  }
  .SHOWING-DETAILS {
    margin: 1vh;
    font-family: Ubuntu;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #f66b67;
  }
  .SHOWING-IMPACTS {
    margin: 1vh;
    font-family: Ubuntu;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right !important;
    color: #4eaaae;
  } 
  .show_impacts {
    padding: 15.5px 0 15.5px 1.5px;
    border-radius: 7px;
    background-color: #44b3b8 !important;
    color: #ffffff !important;
    font-size:17px;
    font-weight: 700
  }
  .show_impacts1 {
    width: 95%;
    padding: 15.5px 0 15.5px 1.5px;
    border-radius: 7px;
    background-color: #44b3b8 !important;
    color: #ffffff !important
  }
  .details {
    margin: 2vh 1vh 1vh 1vh;
    opacity: 0.85;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #0d294a;
  }
  .Path-338 {
    width: 12.1px;
    height: 13.9px;
    margin: 2.1px 13.5px 33px 250.4px;
    background-color: #a2a2a2;
  }
  .date {
    margin: 50px 0vw 0vh 0vw !important;
    font-family: Ubuntu;
    font-size: 1.7vh;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #8c8e91 !important;

  }
  .datee {
    margin: 0vh 1vh 0vh 0vh !important;
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #8c8e91 !important;

  }
  .image_123 {
    height: 10vh;
    margin: 0vh 1vh 0vh 0vh !important;
  }
  .company_alpha {
    margin: 1vh 0vh 0vh 1vh !important;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c48;
  }
  .company_alpha_selected {
    margin: 1vh 0vh 0vh 1vh !important;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c48;
  }
  .Rectangle-727 {
    width: 1px !important;
    height: inherit;
    margin: -3vh 0vh 0vh -3vh;
    background-color: #44b3b8;
  }
  .Rectangle-720 {
    margin-top:-2vh;
    height: 20vh;
    padding: 2vh;
    background-color: #f7f7f7;
  }
  .Rectangle-722 {
    height: 20vh;
    padding: 2vh;
    background-color: inherit !important;
  }
  .Rectangle-709 {
    width:100%;
    height: 5vh !important;
    padding: 1vh;
    background-color: #f7f7f7 !important;
  }
  .view_details {
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #44b3b8 !important;
    margin-left: 1vh;
    margin-top: 1vh;
    cursor: pointer
  }

  .view_share {
    font-family: Ubuntu;
    font-size: 18px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #44b3b8 !important;
    margin-left: 1vh;
    margin-top: 1vh;
  }

  .view_impact {
    font-family: Ubuntu;
    font-size: 18px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #44b3b8 !important;
    margin-left: 1vh;
    text-align: right !important
  }
  .view_details_btn {
    width:100% !important;
    margin: -1vh 0vh 0vh 1vh;
    padding: 1vh !important;
    background-color: #44b3b8 !important;
    color: #ffff;
    font-size: 17px;
    font-weight: 700
  }
  .breadcrums {
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    text-decoration: none;
    color: #009ea5 !important
  }
  .v-breadcrumbs__item {
    color: #009ea5 !important
  }
  .v-breadcrumbs__item v-breadcrumbs__item--disabled {
    color: #333333 !important
  }
  .solid_line {
    border:none;
    border-top:1px dashed #5a5a5a;
    color:#fff;
    background-color:#fff;
    height:1px;
    width:100%;
    margin-top:1.5vh;
    margin-left:-1.5vh;
  }
  .Rectangle-777 {
    padding: 2vh;
    background-color: #d9ffff !important;
    border: solid 2px #00b2ba;
    cursor: pointer;
    border-top-left-radius : 0px;
    border-top-right-radius : 4px;
    border-bottom-left-radius : 4px;
    border-bottom-right-radius : 4px;
  }
  .Rectangle-888 {
    padding: 0.8vh 0.5vw 0.8vh 0.5vw;
    border: solid 1px #dfe5e3;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff !important;
    cursor: pointer;
    border-radius : 7px;
    font-weight: 500
  }
  .Rectangle-903 {
    height: 42px;
    margin: -2vh 0vh 0vh -2vh;
    padding: 1vh 0vh 0.5vh 2vw!important;
    font-size: 18px;
    text-align: left;
    font-weight: 700;
    color: #ffffff;
    background-color: #00b2ba;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }
  .Path-469 {
    display:inline-block;
    border-top:4.5vh solid #00b2ba;
    border-right:4.5vh solid transparent;
    margin: -2.2vh 0vh 0vh -2vh !important
  }

  .second_items {
    font-size: 16px;
    font-weight: 700;
    color:#44b3b8;
    text-align:right
  }
  .notes_section {
    // height:100vh !important;
    max-height: 100%;
    height: 98vh;
    overflow-y: auto;
    background-color: #f9f9f9;
    box-shadow: -5px 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
  .comments_list {
    padding:1vh 0.1vw 0vh 1.4vw;
    min-height: 65vh;
    max-height: 78vh;
    overflow-y: scroll;
    background: inherit;
  }
  .Rectangle-913 {
    margin: 1vh 0.2vw 0.5vh 0vw;
    padding: 0vh 0.8vw 1vh 0.9vw !important;
    border-radius: 18px;
    border: solid 1px #ccc;
    background-color: #fff;
  }
  .text_notes {
    font-size: 17px;
    font-weight: 500;
    color: #49575d;
    margin-bottom:1vh !important;
  }
  .date_notes {
    font-size: 16px;
    font-weight: 500;
    color: #8c8e91 !important;
  }
  .icon {
    font-size: 30px;
  }
  .sline {
    border: none;
    border-top: 2px dotted #bcbcbc;
    color: #fff;
    background-color: #fff;
    height: 1px;
    width: 95%;
  }
  .msg {
    text-align:left; 
    font-weight:500;
    font-size:17px;
    font-family:Ubuntu;
    margin-top: 4vh;
    margin-left: 1.5vw;
    color: #ffffff;
  }
  .card_img {
    object-fit:fill;
    border-radius: 9px;
  }

  .summary {
    font-family: Ubuntu;
    font-size: 18px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c48;
  }
  .Rectangle-905 {
    padding: 2vh 0vh 2vh 2vh;
    border-radius: 16px;
    box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.22);
    border: solid 2px #222545;
    background-color: #f2fcfc;
    margin-top:10px;
    max-height:88vh;
  }
  .leftSidePanel {
    width:100%;
    overflow-y:auto;
    overflow-x: hidden;
    height:87vh;
    padding-bottom: 5vh;
    margin-left: 2.3vw;
  }
  .selectedCard {
    background-color: #c5edef !important;
    margin-top:10px;
    margin-left:0.1vw
  }
  .unselectedCard {
    background-color: inherit;
    margin-bottom:-1.5vh;
    margin-left:0.1vw
  }
  .emptyNotePara {
    text-align: center;
    margin-top:30px;
    margin-bottom:20vh;
    font-size:16px;
    font-weight:700;
    color:#8c8e8e;
  }
  .noteBox {
    position:absolute;
    float:bottom;
    bottom:70px;
    margin:0 10px 45px 0;
  }
  .notesTextField {
    width: 16.8vw !important;
    max-width: 30vw;
    font-weight: 600;
    font-size: 14px;
    font-family: Ubuntu;
    color: #383838;
    position: absolute;
  }
  .header_text {
    text-transform: capitalize;
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: #49575d;
    margin-left: 5vh;
    margin-top:5vh
  }
  .Path-359 {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ff6750 !important;
    background-color: #fff;
    border-radius: 20px !important;
  }
  .ask_que_sec {
    font-family: Ubuntu;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: normal;
    text-align: left;
    color: #202527;
    margin-left: 2vh
  }
  
  .Rectangle-1232 {
    margin: 2.5vh -5vh 0vh 0vh;
    padding: 1vh;
    background-color: #f7f7f7;
    height:402px;
  }
  
  .que-text {
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.79;
    letter-spacing: normal;
    text-align: left;
    color: #9b9b9b;
  }
  .que_txt {
    font-family: Ubuntu;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 2.43;
    letter-spacing: normal;
    text-align: left;
    color: #676772;
    margin-left: 2vh
  }
  
  .Path-868 {
    margin: 0vh 2vh 0vh 1vh;
    padding: 1vh;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #b1b1b1;
    background-color: #fff;
  }
  
  .question {
    font-family: Ubuntu;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #202527;
  }
  
  .circle {
    width: 25px;
    height: 25px;
    margin: 0vh;
    padding: 3px 5px 3px 8px;
    border-radius: 25px;
    border: solid 1px #eaeaea;
    background-color: #eaeaea;
  }
  .answer {
    font-family: Ubuntu;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #202527;
  }
  
 
  .Searching-For {
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: normal;
    text-align: left;
    color: #676772;
  }
  
  .Rectangle-1204 {
    padding: 0 2vh 0 2vh;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #b1b1b1;
    background-color: #f7f7f7;
  }
  .search_context {
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c48;
  }
  ::v-deep .chkbox_content .v-label{
    font-family: Ubuntu;
    font-size: 12px !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    text-align: left;
    color: #2c2c48;
  }
  .Label_btn {
    font-family: Ubuntu;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #00b2ba !important;
    border-radius: 9px;
  }
  .Label_btnn {
    font-family: Ubuntu;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #202527;
  }
  
  .menu1 {
    display: none;
    position: absolute;
    
    border-radius: 6px;
    
    padding:1vh 2vh 1vh 2vh;
    cursor: pointer;
    z-index: 1;
  
  }
  .menu1 i {
    color: #03a6ad;
    cursor: pointer;
    margin: 8px;
  }
  </style>


