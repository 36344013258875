<template>
    <v-main>
      <div>      
        <div class="margin_align">
          <div>
          <loading :active.sync="loading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
          <v-row v-if="!loading">
            <v-col class="mt-2" cols="12" md="4" lg="4" sm="4" xs="4"></v-col>
            <v-col class="mt-4" cols="12" md="4" lg="4" sm="4" xs="4">
              <v-card class="Rectangle-1203">
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3">
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6" align="center" justify="center">
                    <img src="../assets/Icons/Group 959.svg"
                      style="height: 12vh;object-fit: contain;">
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xs="3" xl="3">
                  </v-col>
                </v-row>
                <div style="text-align: center;margin-top: 2vh;">
                  <span class="Error-Occurred">Error Occurred!</span>
                  <p class="description">We will get back to you very soon. <br>Please click on continue to proceed further</p>
                  <hr>
                  <span class="No-Need-to-Worry">No Need to Worry!</span>
                  <br>
                  <span class="contact_person">Our Customer Service Representative will contact you or you can send an email to</span>
                  <br>
                  <span class="supportknolenscom">support@pienomial.com</span>
                  <v-row>
                    <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4">
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4">
                      <v-btn class="Path-703" @click="goback()">Continue</v-btn>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4">
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
            <v-col class="mt-2" cols="12" md="4" lg="4" sm="4" xs="4"></v-col>
          </v-row>
          </div>
        </div>
      </div>
    </v-main>
  </template>
  
  <script>
  import axios from "axios";
  import { EventBus } from "../event-bus.js";
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  export default {
    data() {
      return {
        auth_token: '',
        disease: '',
        loading: false,
        fullPage: true,
        myplan: '',
        sessionId: '',
        customerId: '',
        paymentId: '',
      };
    },
    components: {
      Loading,
    },
    created () {
    },
    mounted() {
      console.log("tranzaction failed")
      this.sessionId = this.$route.query.session_id
      this.customerId = this.$route.query.customer_id
      this.paymentId = this.$route.query.payid
      
      var auth_token = localStorage.getItem("authtoken");
      if (auth_token !== null && auth_token !== "" && auth_token !== undefined) {
        this.auth_token =  auth_token
       this.get_page_activity()
       
      } else {
        this.auth_token = ""
      }
  
      // var disesase = localStorage.getItem("selected_disease")
      var disease=this.disease

      if (disease !== null && disease !== "undefined" && disease !== "") {
        this.disease = disease
      } else {
        this.disease = ""
      }
  
      this.updateFailureStatus()
      this.email = this.$route.query.email
      this.customerId = this.$route.query.customer_id
      //console.log(this.email, this.customerId, "customer infooo")
    },
    computed: {
     
    },
    methods: {
      updateFailureStatus(){
        axios.post('/updateFailureStatus?token='+this.auth_token+"&sid="+this.sessionId+"&cid="+this.customerId+"&pid="+this.paymentId)
        .then(res => {
          if(res.data.Status != 1) {
           
            //this.save_subscription_info(this.sessioninfo)
          } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
            this.logOut()
          } else {
            console.log("subscription not found")
          }
        })
      },
      onCancel() {
        console.log('User cancelled the loader.')
      },
  
      goback() {
        // var old_selected = localStorage.getItem("selected_article");
        // if (old_selected !== null && old_selected !== "" && old_selected !== undefined) {
        //   localStorage.setItem("old_selected_article", old_selected);
        // } else {
        //   old_selected = "Industry Updates"
        //   localStorage.setItem("old_selected_article", old_selected);
        // }
      // localStorage.setItem("selected_article", "Modules Library");
        this.$router.push("/").catch(() => {})           
              
      },
      get_page_activity(){
    axios
            .get("/fetchIndicationSelected?token=" + this.auth_token)
            .then((res) => {
              if (res.data.Status != 1) {
                console.log(res.data,"response.data")
                this.disease = res.data.Data.Indication
                this.$forceUpdate();          
              } 
          })
  },
    }
  };
  </script>
  
  <style scoped>
    .margin_align {
      overflow-x: hidden;
      overflow-y: auto;
      height: 100vh !important;
      background-color: #f7f7f7 !important;
    }
  
    .Error-Occurred {
      margin: 2vh;
      font-family: Ubuntu;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.47;
      letter-spacing: normal;
      text-align: center;
      color: #f03b4c;
    }
  
  
    .description {
      margin: 2vh;
      font-family: Ubuntu;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.89;
      letter-spacing: normal;
      text-align: center;
      color: #676772;
    }
  
    .Path-703 {
      height: 30px;
      padding: 2vh;
      background-color: #03a6ad !important;
      color: #fff;
    }
  
    .Rectangle-1203 {
      margin-top: 10vh;
      padding: 3vh;
      border-radius: 31px;
      box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px #707070;
      background-color: #fff;
    }
  
    .No-Need-to-Worry {
      margin: 2vh;
      font-family: Ubuntu;
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.78;
      letter-spacing: normal;
      text-align: center;
      color: #202527;
    }
  
    .contact_person {
      margin: 2vh;
      font-family: Ubuntu;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.89;
      letter-spacing: normal;
      text-align: center;
      color: #676772;
    }
    .supportknolenscom {
      margin: 2vh;
      font-family: Ubuntu;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.89;
      letter-spacing: normal;
      text-align: center;
      color: #006cff;
    }
  
  
  </style>
  
  