<template>
  <v-main>
   <div style="margin-left:-4vw;">    
     <div> 
        <div @scrolling="scrollpage()" class="margin_align" id="topDiv"  :style="!isTabletMob ? '': 'height:101vh'">
          <div v-if="loading && !module_not_found"  style="display:flex;align-items:center;justify-content:center;height:100%;margin-left:4vw">
            <v-progress-circular
              indeterminate
              size="100"
              width="7"
              color="teal"
          >
            {{ progressPercent }}%
          </v-progress-circular>
          
          </div>
        <div :class="expanded ? 'leftPanelExpand' : 'left'">
         <!-- <loading :active.sync="loading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading> -->

            <div v-if="!loading &&  module_not_found" style="font-family: Ubuntu;margin-bottom: -50px;margin-right: -5px;margin-top:20px">
              <v-row>
                <v-col cols="12" md="12" lg="12" sm="12" class="Rectangle-721">
                    <p class="No_article" style="text-align:center;padding-top:30vh">
                      Not found.
                    </p>
                </v-col>
              </v-row>
          </div>
      
          <div id="gist" style="font-family: Ubuntu;margin-bottom: -50px;margin-right: -5px;margin-top:40px">
            <div v-if="!loading && !module_not_found">
              <div class="menu1" @click="showqueModal()">
                <!-- <i class="fa fa-copy fa-2x" id="copy-btn"></i> -->
                <img src="../assets/Icons/Group 1102.svg" style="height:3.5vh;width:3.5vh">
              </div>
             
               
              <div v-if="iscustom==false">
                <div v-for="(pan,kp) in panels" :key="kp" style="" @mouseenter="startTracking(pan.my_panel_id)" @mouseleave="endTracking(pan.my_panel_id)"  @click="handlePanelClick(pan.my_panel_id)" >
                  <div @mouseup="showque">
                    <div :id="pan.my_panel_id">
                      <v-row wrap v-if="pan.Panel_Info !== 'Full_Panel' && pan.Panel_Info == '' && (pan.Right.Display.plot_display == true && (pan.Right.Display.Display_Type == 'Plot' || pan.Right.Display.Display_Type == 'Table' || pan.Right.Display.Display_Type == 'Text Box' || pan.Right.Display.Display_Type == 'Card' || pan.Right.Display.Display_Type == 'Financial_Cards'))" :class="kp%2==0 ? 'even_panels': 'odd_panels'" :style="kp === 0 ? (isTabletMob ? 'padding-top: 60px;' : 'padding-top: 30px;') : 'padding-top: 20px;'"> 
                        <v-col :cols="!isTabletMob? '4' : '12'" :style="!isTabletMob ? 'margin-top:0vh': 'margin-top:0vh'">
                          <v-row style="margin-bottom:2vh">
                            <v-col cols="12" >                           
                              <v-menu fixed :absolute="false"  offset-y :close-on-content-click="true">
                                <template v-slot:activator="{ on, attrs }">
                                  <div v-bind="attrs" v-on="on">
                                    <span class="news_heading">
                                      {{ pan.Left.Header }}
                                    </span>
                                    <img  src="../assets/Icons/Group 635_black.svg" style="height: 1vh;margin-left:5px" />
                                  </div>
                                </template>
                                <v-card style="width:62vh;border: #03a6ad solid 1px;border-radius:16px;padding:1.5vh 0">
                                  <v-row wrap no-gutters :id="pan.Left.HeaderPanel.id+'panelId'" v-for="(p, key1) in  getPanelIds(pan.Left.HeaderPanel.id)" :key="key1" style="cursor:pointer"
                                  @click="handleSelectionChange(p)"> 
                                    <v-col>
                                      <v-divider v-if="key1!=0" color="#c9c8c8"  style=""></v-divider>                                  
                                          <span :id="pan.Left.HeaderPanel.id+'panelText'" style="text-transform: capitalize;font-size: 13px;padding:0 1vh;"  onmouseover="this.style.color='#03a6ad';this.style.fontWeight='bold';" onmouseout="this.style.color='black';this.style.fontWeight='normal'">{{ p.title }}</span>       
                                                                                                       
                                          <!-- <v-expansion-panels :id="pan.Left.HeaderPanel.id+'panelText'" flat style="background:#ffff;box-shadow: none !important;border-radius:12px;">
                                            <v-expansion-panel style="background:#ffff;box-shadow: none !important;border-radius:12px;">
                                                <v-expansion-panel-header  expand-icon="mdi-menu-down" style="font-size: 1.5vh !important;line-height: 1.5;" @click.stop><b>{{ p.title }}</b></v-expansion-panel-header>
                                                <v-expansion-panel-content style="font-size: 1.5vh !important; color: #6e6e6e;padding:0vh !important">               
                                                  <div>
                                                    <span>{{ p.description }}</span>
                                                  </div>                                                    
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels> -->
                                    </v-col>
                                  </v-row>
                                </v-card>
                              </v-menu>                  
                            </v-col>                        
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="3" lg="3" sm="3" xs="3" align="center" justify="center">
                              <div :class="!isTabletMob ? 'Path-499' : 'Path-499_111'">
                                <span >
                                  <img src="../assets/images/share.png" alt="share" :style="!isTabletMob ? 'height: 1.8vh; width: 1.8vh;' : 'height: 2.1vh; width: 2.1vh;'" @click="show_share_modal(pan.Panel_Id)"/>
                                </span>
                              
                                <span >
                                  <a @click="showExpand()" v-show="!expanded">
                                    <img src="../assets/Icons/expand.svg" alt="expandPanel"
                                    :style="!isTabletMob ? 'height: 1.8vh; width: 1.8vh; margin-left: 1.4vw' : 'height: 2.1vh; width: 2.1vh;margin-left: 3vw'" />
                                  </a>
                                  <a @click="showShrink(pan.my_panel_id)" v-show="expanded">
                                    <img src="../assets/Icons/Path 533.svg" alt="expandPanel"
                                    :style="!isTabletMob ? 'height: 1.8vh; width: 1.8vh; margin-left: 1.4vw' : 'height: 2.1vh; width: 2.1vh;margin-left: 3vw'" />
                                  </a>
                                </span>
                              </div>
                             
                            </v-col>
                            <v-col v-if="!isTabletMob" cols="12" md="8" lg="8" sm="8" xs="8"></v-col>
                          </v-row>
                         
                          <div v-if="pan.Left.First_Text.length > 0 && pan.Left.First_Text[0] !== ''">
                            <div v-if="pan.Left.first_links_href.length>0" style="font-size: 15px;color:#384348;padding-top: 3vh;" class="pre-formatted" >
                              <v-sheet outlined   v-for="(link,a) in pan.Left.first_links_href" :key="a" class="links">
                                <v-row wrap class="ma-0 pa-0" style="padding:0vh">
                                <v-col  cols="12" md="1" lg="1" sm="1" xs="1">
                                  <div class="Rectangle-718">
                                    <img alt="Link" 
                                      src="../assets/Icons/Path 344.svg"
                                      style="text-align: center;height:15px"
                                      >
                                  </div>
                                  </v-col>
                                <v-col  cols="12" md="10" lg="10" sm="10" xs="10">
                                  <div style="margin-top:2px;margin-left:2vh">
                                    <a :href="link.link" class="scroll-link"  style="text-decoration: none;color:#009ea5 !important">{{link.linktext}}</a>
                                  </div>
                                </v-col>
                              </v-row>
                              </v-sheet>
                              </div>
                            <div v-else style="font-size: 15px;color:#384348" class="pre-formatted" v-for="(pa,a) in pan.Left.First_Text" :key="a">
                              <span v-html="pa"></span>
                            </div>
                            <!-- <div v-if="pan.Left.First_Text.length > 0 && pan.Left.First_Text[0] !== ''" class="line"></div> -->
                          </div>
                          <div v-if="pan.Left.Second_Text.length > 0 && pan.Left.Second_Text[0] !== ''">
                            <div v-if="pan.Left.second_links_href.length>0" style="font-size: 15px;color:#384348;padding-top: 3vh;" class="pre-formatted" >
                            <v-sheet outlined v-for="(link,a) in pan.Left.second_links_href" :key="a" class="links">
                                <v-row wrap class="ma-0 pa-0" style="padding:0vh">
                                <v-col  cols="12" md="1" lg="1" sm="1" xs="1">
                                  <div class="Rectangle-718">
                                    <img alt="Link" 
                                      src="../assets/Icons/Path 344.svg"
                                      style="text-align: center;height:15px"
                                      >
                                  </div>
                                  </v-col>
                                <v-col  cols="12" md="10" lg="10" sm="10" xs="10">
                                  <div style="margin-top:2px;margin-left:2vh">
                                    <a :href="link.link" class="scroll-link"  style="text-decoration: none;color:#009ea5 !important">{{link.linktext}}</a>
                                  </div>
                                </v-col>
                              </v-row>
                              </v-sheet>
                            </div>
                            <div v-else style="font-size: 15px;color:#384348" class="pre-formatted" v-for="(pa,b) in pan.Left.Second_Text" :key="b">
                              <span v-html="pa"></span>
                            </div>
                            <!-- <div v-if="pan.Left.Second_Text.length > 0 && pan.Left.Second_Text[0] !== ''" class="line"></div> -->
                          </div>
                          <div v-if="pan.Left.Third_Text.length > 0 && pan.Left.Third_Text[0] !== ''">
                            <div v-if="pan.Left.third_links_href.length>0" style="font-size: 15px;color:#384348;padding-top: 3vh;" class="pre-formatted" >
                              <v-sheet outlined   v-for="(link,a) in pan.Left.third_links_href" :key="a" class="links">
                                <v-row wrap class="ma-0 pa-0" style="padding:0vh">
                                <v-col  cols="12" md="1" lg="1" sm="1" xs="1">
                                  <div class="Rectangle-718">
                                    <img alt="Link" 
                                      src="../assets/Icons/Path 344.svg"
                                      style="text-align: center;height:15px"
                                      >
                                  </div>
                                  </v-col>
                                <v-col  cols="12" md="10" lg="10" sm="10" xs="10">
                                  <div style="margin-top:2px;margin-left:2vh">
                                    <a :href="link.link" class="scroll-link"  style="text-decoration: none;color:#009ea5 !important">{{link.linktext}}</a>
                                  </div>
                                </v-col>
                              </v-row>
                              </v-sheet>
                              </div>
                            <div v-else style="font-size: 15px;color:#384348" class="pre-formatted" v-for="(pa,c) in pan.Left.Third_Text" :key="c">
                              <span v-html="pa"></span>
                            </div>
                            <!-- <div v-if="pan.Left.Third_Text.length > 0 && pan.Left.Third_Text[0] !== ''" class="line"></div> -->
                          </div>
                          <div v-if="pan.Left.Forth_Text.length > 0 && pan.Left.Forth_Text[0] !== ''">
                            <div v-if="pan.Left.forth_links_href.length>0" style="font-size: 15px;color:#384348;padding-top: 3vh;" class="pre-formatted" >
                              <v-sheet outlined   v-for="(link,a) in pan.Left.forth_links_href" :key="a" class="links">
                                <v-row wrap class="ma-0 pa-0" style="padding:0vh">
                                <v-col  cols="12" md="1" lg="1" sm="1" xs="1">
                                  <div class="Rectangle-718">
                                    <img alt="Link" 
                                      src="../assets/Icons/Path 344.svg"
                                      style="text-align: center;height:15px"
                                      >
                                  </div>
                                  </v-col>
                                <v-col  cols="12" md="10" lg="10" sm="10" xs="10">
                                  <div style="margin-top:2px;margin-left:2vh">
                                    <a :href="link.link" class="scroll-link"  style="text-decoration: none;color:#009ea5 !important">{{link.linktext}}</a>
                                  </div>
                                </v-col>
                              </v-row>
                              </v-sheet>
                              </div>
                            <div v-else style="font-size: 15px;color:#384348" class="pre-formatted" v-for="(pa,d) in pan.Left.Forth_Text" :key="d">
                              <span v-html="pa"></span>
                            </div>
                            <!-- <div class="line"></div> -->
                          </div>
                          <div v-if="pan.Left.Fifth_Text.length > 0 && pan.Left.Fifth_Text[0] !== ''">
                            <div v-if="pan.Left.fifth_links_href.length>0" style="font-size: 15px;color:#384348;padding-top: 3vh;" class="pre-formatted" >
                              <v-sheet outlined   v-for="(link,a) in pan.Left.fifth_links_href" :key="a" class="links">
                                <v-row wrap class="ma-0 pa-0" style="padding:0vh">
                                <v-col  cols="12" md="1" lg="1" sm="1" xs="1">
                                  <div class="Rectangle-718">
                                    <img alt="Link" 
                                      src="../assets/Icons/Path 344.svg"
                                      style="text-align: center;height:15px"
                                      >
                                  </div>
                                  </v-col>
                                <v-col  cols="12" md="10" lg="10" sm="10" xs="10">
                                  <div style="margin-top:2px;margin-left:2vh">
                                    <a :href="link.link" class="scroll-link"  style="text-decoration: none;color:#009ea5 !important">{{link.linktext}}</a>
                                  </div>
                                </v-col>
                              </v-row>
                              </v-sheet>
                            </div>
                            <div v-else style="font-size: 15px;color:#384348" class="pre-formatted" v-for="(pa,e) in pan.Left.Fifth_Text" :key="e">
                              <span v-html="pa"></span>
                            </div>
                            <!-- <div v-if="pan.Left.Fifth_Text.length > 0 && pan.Left.Fifth_Text[0] !== ''" class="line"></div> -->
                          </div>
                          <div v-if="!isTabletMob">
                            <p class="linktitle">{{pan.Left.Link_Title}}</p>
                            <v-sheet outlined v-if="pa.Link !== '' || pa.Link_Text !== ''" class="links" v-for="(pa,ke) in pan.Left.Links" :key="ke" style="">
                            
                              <v-row wrap class="ma-0 pa-0" style="padding:0vh">
                                <v-col  cols="12" md="1" lg="1" sm="1" xs="1">
                                  <div class="Rectangle-718">
                                    <img alt="Link" 
                                      src="../assets/Icons/Path 344.svg"
                                      style="text-align: center;height:15px"
                                      >
                                  </div>
                                  </v-col>
                                <v-col  cols="12" md="10" lg="10" sm="10" xs="10">
                                  <div style="margin-top:2px;margin-left:2vh">
                                    <a class="link_content" @click="takemeto(pa.Link)" style="text-decoration: none">{{pa.Link_Text}}</a>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-sheet>
                          </div>
                        </v-col>
                        
                        <v-col :cols="!isTabletMob ? '7' : '12'" :style="!isTabletMob ? 'margin-left:70px' : 'margin-left:0vh'">
                          <div>
                            <v-row wrap no-gutters>
                              <v-col cols="12"  md="6" lg="6" sm="6" xs="6"></v-col>
                              <v-col  cols="12" md="6" lg="6" sm="6" xs="6" align="right">                            
                              </v-col>                    
                            </v-row>                         
                            <v-row>
                              <div v-if="pan.Right.Display.Display_Type == 'Card'">
                                <v-row wrap>
                                  <v-col  cols="12" md="4" lg="4" sm="4" xs="4"
                                    v-for="(pa,key1) in pan.Right.Display.Display_Info" :key="key1">
                                    <v-card style="height:280px;width:95%;padding:0vh 2vh 0vh 2vh;margin-right: 4vh; ">
                                      <!-- card title -->
                                      <v-row wrap style="height:80px">
                                        <v-col class="mt-4" cols="12" md="3" lg="3" sm="3" xs="3">
                                          <img :src="pa.Logo" basic style="height: 22px; width: 22px;margin-left:1vh" />
                                        </v-col>
                                        <v-col class="mt-4" cols="12" md="9" lg="9" sm="9" xs="9">
                                          <p class="cardTitles">{{pa.Title}}</p>
                                        </v-col>
                                      </v-row>
                                      <!-- card contents  if Image-->
                                      <div v-if="pa.Value_Type == 'image'">
                                        <div class="centerAlign" style="height: 190px;">
                                          <img :src="pa.myimage.src" alt="ChannelLogo" />
                                        </div>
                                      </div>
                                      <!-- if text -->
                                      <div v-if="pa.Value_Type == 'text'" >
                                        <div style="margin-top:20px">
                                          <v-row>
                                            <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                                            <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4">
                                              <div style="margin-top: 10px">
                                                <hr class="hrLine">
                                                <span class="itemCountDesign centerAlign">{{pa.count}}</span>
                                                <hr class="hrLine">
                                              </div>

                                            </v-col>
                                            <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                                          </v-row>
                                        </div>
                                        <div class="titleCenter" style="margin-top:40px">
                                          <span class="title1">{{pa.rem_text}}</span>
                                        </div>
                                      </div>
                                    </v-card>
                                  </v-col>

                                </v-row>
                              </div>
                              <div v-if="pan.Right.Display.Display_Type == 'Text Box'">
                                <div>
                                  <div v-for="(pa,key3) in pan.Right.Display.Display_Info" :key="key3">
                                    <h5 class="texttitle">{{pa.Title}}</h5>
                                      <p v-if="!pa.textLength" class="textbox_content pre-formatted" v-html="pa.newText"></p>
                                      <p v-if="pa.textLength" class="textbox_content pre-formatted" v-html="pa.Text"></p>
                                      <span @click="changetextLength(pa)" class="pointer" v-if="!pa.textLength && !pa.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;">Read More</span>
                                      <span @click="changetextLength(pa)" class="pointer" v-if="pa.textLength && !pa.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;cursor: pointer;">Show Less</span>                               
                                  </div>
                                </div>
                              </div>
                              <div v-if="pan.Right.Display.Display_Type == 'Table'">
                                <v-row v-for="(pa,key4) in pan.Right.Display.Display_Info" :key="key4">
                                  <v-row wrap>
                                    <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2" v-for="(pbtn,keyyy) in pa.Dropdownnames" :key="keyyy">                       
                                        <v-btn :style="pbtn.selected ? 'background-color: #3fa6a1; color: #ffffff;font-size: 13px;font-weight:700; margin-top:0vh;font-family: Ubuntu;width:9vw;' : 'background-color: #ffffff; color: #3fa6a1; font-size: 13px;font-weight:700; margin-top:0vh; font-family: Ubuntu;width:9vw;'" @click="onChangetrials(pbtn,pa.myTable)">{{pbtn.name}}</v-btn>
                                    </v-col>
                                    <v-col cols="12" sm="7" md="7" lg="7" xs="7" xl="7"></v-col>
                                  </v-row>
                                
                                  <table class="pl-0" :id="pa.myTable">
                                    <span v-if="pa.show_dropdown == true">
                                      <tr>
                                        <th :class="(pa.Table_Data.Table_Type == 'Dual Header' && key7 == 0)? 'dualHeaderRow': 'dualHeaderAnotherRow'" v-for="(p,key7) in pa.Table_Data.Columns" :key="key7" style="min-width: 9.5vw">{{p}} <span @click="sort_rows(key7, pa.myTable)" v-show="pa.Table_Data.Sort_Enable == 'True'"><i class="fa fa-arrow-down" style="margin-left: 2vh !important;cursor: pointer;"></i></span>
                                        </th>
                                      </tr>
                                      <tr v-for="(p,key5) in pa.Table_Data.show_rows" :key="key5">
                                        <td v-for="(x,k1) in p" :key="k1" :class="(pa.Table_Data.Table_Type == 'Dual Header' && k1 == 0)? 'dualHeaderData':'dualHeaderAnotherData'">
                                          <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                        </td>
                                      </tr>
                                    </span>
                                    <tr v-if="pa.show_dropdown !== true">
                                      <th
                                        :class="(pa.Table_Data.Table_Type == 'Dual Header' && key77 == 0)? 'dualHeaderRow': 'dualHeaderAnotherRow'"
                                        v-for="(p,key77) in pa.Table_Data.Columns" :key="key77">
                                        <v-row wrap style="padding:0">
                                          <v-col cols="12" sm="8" md="8" lg="8" xs="8" style="padding-top:0vh">
                                            <span style="white-space:normal;margin-right:2px">{{p}}</span>
                                          </v-col>
                                          <v-col cols="12" sm="2" md="2" lg="2" xs="2" @click="sort_rows(key77, pa.myTable)" style="padding-top:0vh" v-show="pa.Table_Data.Sort_Enable == 'True'">
                                              <i class="fa fa-arrow-down"
                                                style="cursor: pointer;padding:0px"></i>
                                          </v-col>
                                        </v-row>
                                      </th>
                                    </tr>
                                    <tr v-for="(p,key55) in pa.Table_Data.Rows" :key="key55" v-if="pa.show_dropdown !== true">
                                      <td v-for="(x,k11) in p" :key="k11"
                                        :class="(pa.Table_Data.Table_Type == 'Dual Header' && k11 == 0)? 'dualHeaderData':'dualHeaderAnotherData'">
                                        <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                      </td>
                                    </tr>
                                  </table>
                                  </v-row>
                              </div>
                              
                              <div v-if="pan.Right.Display.Display_Type == 'Plot'">
                                <v-row>
                                <v-col :cols="(pan.Right.Display.Display_Info.length == 1 || key6 ==2 ) ? '12' : '6'"  v-for="(pa,key6) in pan.Right.Display.Display_Info" :key="key6" style="">                             
                                    <span v-html="pa.div64"></span>
                                </v-col>
                                </v-row>
                              </div>
                              <div v-if="pan.Right.Display.Display_Type == 'Financial_Cards'">
                                <div v-for="(pa,kfin) in pan.Right.Display.Display_Info" :key="kfin" style="padding:5vh">
                                  <v-row class="mt-3">
                                    <v-col cols="12" md="6" lg="6" sm="6" xs="6" v-for="(p,fin) in pa.Fin_Card_Data" :key="fin">
                                      <v-card class="fincard">
                                        <div v-for="(pf,fink) in p" :key="fink">
                                          <p style="margin-top:2vh; margin-bottom:2vh;font-family:Ubuntu;font-size:16px;font-weight: 700">{{pf.Header}}</p>
                                          <v-row>
                                            <v-col cols="12" md="6" lg="6" sm="6" xs="6">
                                              <span class="finance_text">{{pf.name}}</span>
                                            </v-col>
                                            <v-col cols="12" md="6" lg="6" sm="6" xs="6">
                                              <span class="finance_text">{{pf.val}}</span>
                                            </v-col>
                                            <hr>
                                          </v-row>
                                        </div>
                                      </v-card>
                                    </v-col>
                                  </v-row>
                                  <v-row v-if="pa.Plot == 'Table'">
                                      <table style="">
                                        <tr>
                                          <th class="table_header" v-for="(pfi,ft) in pa.Columns" :key="ft">{{pfi}}</th>
                                        </tr>
                                        <tr v-for="(p,key) in pa.Rows" :key="key">
                                          <td v-for="(x,k) in p" :key="k" class="finaTableWrap">
                                            <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                          </td>
                                        </tr>
                                      </table>
                                  </v-row>
                                </div>
                              </div>  
                            </v-row>
                          </div>
                        </v-col>
                      </v-row>
                      <!-- full panel -->
                      <v-row  wrap v-if="pan.Panel_Info == 'Full_Panel'" :class="kp%2==0 ? 'even_panels_full': 'odd_panels_full'" >
                        <div>
                          <v-row>
                          
                            <v-col v-if="!isTabletMob" cols="12" md="11" lg="11" sm="11" xs="11"></v-col>
                            <v-col cols="12" md="1" lg="1" sm="1" xs="1" align="center" justify="center">
                              <div :class="!isTabletMob ? 'Path-499' : 'Path-499_111'">
                                <span >
                                  <img src="../assets/images/share.png" alt="share" :style="!isTabletMob ? 'height: 1.8vh; width: 1.8vh;' : 'height: 2.1vh; width: 2.1vh;'" @click="show_share_modal(pan.Panel_Id)"/>
                                </span>
                              
                                <span >
                                  <a @click="showExpand()" v-show="!expanded">
                                    <img src="../assets/Icons/expand.svg" alt="expandPanel"
                                    :style="!isTabletMob ? 'height: 1.8vh; width: 1.8vh; margin-left: 1.4vw' : 'height: 2.1vh; width: 2.1vh;margin-left: 3vw'" />
                                  </a>
                                  <a @click="showShrink(pan.my_panel_id)" v-show="expanded">
                                    <img src="../assets/Icons/Path 533.svg" alt="expandPanel"
                                    :style="!isTabletMob ? 'height: 1.8vh; width: 1.8vh; margin-left: 1.4vw' : 'height: 2.1vh; width: 2.1vh;margin-left: 3vw'" />
                                  </a>
                                </span>
                              </div>
                             
                            </v-col>
                          </v-row>
                        </div>
                        <br>
                        <div style="margin-bottom:4vh">
                          <v-menu fixed :absolute="false"  offset-y :close-on-content-click="true">
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <span class="news_heading">
                                  {{ pan.Left.Header }}
                                </span>
                                <img  src="../assets/Icons/Group 635_black.svg" style="height: 1vh;margin-left:5px" />
                              </div>
                            </template>
                            <v-card style="width:62vh;border: #03a6ad solid 1px;border-radius:16px;padding:1.5vh 0">
                              <v-row wrap no-gutters v-for="(p, key1) in  getPanelIds(pan.Left.HeaderPanel.id)" :key="key1" style="cursor:pointer"
                              @click="handleSelectionChange(p)"> 
                                <v-col>
                                  <v-divider v-if="key1!=0" color="03a6ad" style=""></v-divider>                                                         
                                      <span :id="pan.Left.HeaderPanel.id+'panelText'" style="text-transform: capitalize;font-size: 13px;padding:0 1vh;"  onmouseover="this.style.color='#03a6ad';this.style.fontWeight='bold';" onmouseout="this.style.color='black';this.style.fontWeight='normal'" v-bind="attrs" v-on="on">{{ p.title }}</span>                                                                              
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-menu>  
                        </div>
                        <div v-if="pan.Left.First_Text.length > 0 ">
                          <v-row style="margin-top:-5vh">
                            <!-- <div class="news_heading">{{pan.Left.Header}}</div>  -->
                         
                            <v-col class="mt-4" cols="12" md="2" lg="2" sm="2" xs="2"></v-col>            
                            <v-col class="mt-4" cols="12" md="8" lg="8" sm="8" xs="8">
                                <div v-if="pan.Left.First_Text.length > 0 && pan.Left.First_Text[0] !== ''"> 
                                  <div style="font-size: 16px;" v-for="(ppaf,a) in pan.Left.First_Text" :key="a">
                                    <span v-html="ppaf"></span>
                                  </div>
                            
                                </div>
                                <div v-if="pan.Left.Second_Text.length > 0 && pan.Left.Second_Text[0] !== ''">
                                  <div style="font-size: 16px;" class="" v-for="(ppas,b) in pan.Left.Second_Text" :key="b">
                                    <span v-html="ppas"></span>
                                  </div>
                                 
                                </div>
                                <div v-if="pan.Left.Third_Text.length > 0 && pan.Left.Third_Text[0] !== ''">
                                  <div style="font-size: 16px;" class="" v-for="(ppat,c) in pan.Left.Third_Text" :key="c">
                                    <span v-html="ppat"></span>
                                  </div>
                               
                                </div>
                                <div v-if="pan.Left.Forth_Text.length > 0 && pan.Left.Forth_Text[0] !== ''">
                                  <div style="font-size: 16px;" class="" v-for="(ppafo,d) in pan.Left.Forth_Text" :key="d">
                                    <span v-html="ppafo"></span>
                                  </div>
                                 
                                </div>
                                <div v-if="pan.Left.Fifth_Text.length > 0 && pan.Left.Fifth_Text[0] !== ''">
                                  <div style="font-size: 16px;" class="" v-for="(ppafi,e) in pan.Left.Fifth_Text" :key="e">
                                    <span v-html="ppafi"></span>
                                  </div>
                                  
                                </div>
                                <div v-if="pan.Left.Sixth_Text.length > 0 && pan.Left.Sixth_Text[0] !== ''">
                                  <div style="font-size: 16px;" class="" v-for="(ppafs,f) in pan.Left.Sixth_Text" :key="f">
                                    <span v-html="ppafs"></span>
                                  </div>
                                 
                                </div>
                                <div v-if="pan.Left.Seventh_Text.length > 0 && pan.Left.Seventh_Text[0] !== ''">
                                  <div style="font-size: 16px;" class="" v-for="(ppafss,g) in pan.Left.Seventh_Text" :key="g">
                                    <span v-html="ppafss"></span>
                                  </div>
                                  
                                </div>
                                <div v-if="pan.Left.Eighth_Text.length > 0 && pan.Left.Eighth_Text[0] !== ''">
                                  <div style="font-size: 16px;" class="" v-for="(ppafse,h) in pan.Left.Eighth_Text" :key="h">
                                    <span v-html="ppafse"></span>
                                  </div>
                                 
                                </div>
                                <div v-if="pan.Left.Ninth_Text.length > 0 && pan.Left.Ninth_Text[0] !== ''">
                                  <div style="font-size: 16px;" class="" v-for="(ppafn,i) in pan.Left.Ninth_Text" :key="i">
                                    <span v-html="ppafn"></span>
                                  </div>
                                  
                                </div>
                                <div v-if="pan.Left.Tenth_Text.length > 0 && pan.Left.Tenth_Text[0] !== ''">
                                  <div style="font-size: 16px;" class="" v-for="(ppaft,j) in pan.Left.Tenth_Text" :key="j">
                                    <span v-html="ppaft"></span>
                                  </div>
                                 
                                </div>
                            </v-col>
                            <v-col class="mt-4" cols="12" md="2" lg="2" sm="2" xs="2"></v-col>
                          </v-row>
                        </div>
                        <div v-if="pan.Right.Display.Display_Type == 'Plot'">
                          <v-row>
                           
                              <v-col cols="12"  md="auto" lg="auto" sm="auto" xs="auto">
                              <!-- <div class="news_heading">{{pan.Left.Header}}</div> -->
                              <!-- <v-menu fixed :absolute="false"  offset-y :close-on-content-click="true">
                                <template v-slot:activator="{ on, attrs }">
                                  <div v-bind="attrs" v-on="on">
                                    <span class="news_heading">
                                      {{ pan.Left.Header }}
                                    </span>
                                    <img  src="../assets/Icons/Group 635_black.svg" style="height: 1vh;margin-left:5px" />
                                  </div>
                                </template>
                                <v-card style="width:62vh;border: #03a6ad solid 1px;border-radius:16px;padding:1.5vh 0">
                                  <v-row wrap no-gutters v-for="(p, key1) in  getPanelIds(pan.Left.HeaderPanel.id)" :key="key1" style="cursor:pointer"
                                  @click="handleSelectionChange(p)"> 
                                    <v-col>
                                      <v-divider v-if="key1!=0" color="03a6ad" style=""></v-divider>                                                          
                                          <span :id="pan.Left.HeaderPanel.id+'panelText'" style="text-transform: capitalize;font-size: 13px;padding:0 1vh;"  onmouseover="this.style.color='#03a6ad';this.style.fontWeight='bold';" onmouseout="this.style.color='black';this.style.fontWeight='normal'" >{{ p.title }}</span>                                                                                    
                                    </v-col>
                                  </v-row>
                                </v-card>
                              </v-menu>      -->
                            
                            </v-col>
                            <v-col cols="12"  md="6" lg="6" sm="6" xs="6"></v-col>
                          </v-row>
                          <div v-for="(pa,keyy) in pan.Right.Display.Display_Info" :key="keyy" style="min-height:90vh !important;padding-bottom:5vh" :style="getPanelStyle(pan)">
                           
                            <v-row  style=""  v-if="pan.my_panel_id=='a1_1' && showArticle">
                              <v-col cols="12"> 
                                <span v-html="pa.div64"></span>
                              </v-col>
                              <!-- <v-col  cols="12" md="4" lg="4" sm="4" xs="4">
                                <div style="margin-right:2vh;margin-top:4vh;" v-if="showArticle">
                                  <v-card  style="box-shadow: 0 2px 3px 0 rgba(88, 88, 88, 0.16) !important;width:100% !important">
                                    <v-card-title style="background-color:  #f9f9fb; display: flex; flex-direction: column; align-items: flex-start;justify-content:space-between;flex-wrap: nowrap;padding:1vh 2vh">
                                      <div> 
                                        <span v-if="releases.nct!='' && releases.nct!=undefined && releases.nct!=null" style="font-size: 1.8vh;line-height:0;word-break:keep-all" v-html="releases.nct"></span>
                                        <span style="font-size: 1.8vh;line-height:0 ;word-break:keep-all" v-else><b>{{selectedRelease}}</b></span>
                                      
                                      </div>
                                      <div style=" display: flex; flex-direction: row; align-items: center;justify-content:space-between;width:100%">
                                        <v-menu 
                                          :close-on-content-click="false"
                                          :close-on-click="false"
                                          :nudge-left="0"
                                          bottom
                                          left
                                          transition="scale-transition"
                                          offset-y
                                          offset-x
                                         
                                        >
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-btn  v-bind="attrs"
                                            v-on="on" style="font-size:12px;color:#009ea5;box-shadow: none!important;
                                            border: none;border-radius:5px;background:transparent;width:auto" @click="showDatePicker=true">
                                            <span v-html="dateRange">
                                            </span>
                                            </v-btn>
                                            
                                          </template>
                                          <v-card style="background:#ffff;border: solid 0.7px #cfd3d5;" v-if="showDatePicker">
                                            <v-card-title style="background-color:  #f9f9fb; display: flex; flex-direction: row; align-items: flex-start;justify-content:space-between;padding:0 1vh" >
                                              <span style="font-size: 1.7vh;color:#384348;padding:1vh 2vh"> Select Date Range</span>
                                              <div style="margin-left:2vh"> 
                                                <v-icon color="#009ea5" style="cursor: pointer;font-size:2.5vh;padding:1.7vh 0" @click="showDatePicker=false">mdi-close</v-icon>
                                              </div>
                                            </v-card-title>
                                            <div style="margin:0.5vh 0 0 2vh ; display: flex; flex-direction: row; align-items: flex-start;justify-content:center">
                                          
                                            <v-text-field type="date" hide-details class="text-xs-center ml-2 mr-4" v-model="startDate"></v-text-field>
                                            <v-text-field type="date" hide-details class="text-xs-center ml-2 mr-4" v-model="endDate"></v-text-field>
                                            </div>
                                            <div style="display:flex;justify-content:space-between;align-items:center;padding:3vh 2vh">
                                              <span style="font-size:10px;color:#ff6750;padding:0 1vh">{{alertmsg}}</span>
                                              <v-btn
                                        
                                              style="font-size:10px;color:#ffff;box-shadow: none!important;border: solid 0.7px #cfd3d5;border-radius:5px;background:#009ea5;width:auto;margin-right:1vh;"
                                              @click="saveDate()" >Confirm</v-btn>
                                            </div>
                                            
                                          </v-card>
                                  
                                        </v-menu>
                                        <div style="margin-left:0.5vh;" v-if="showrevenueOption">
                                          <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                              <div v-if="!revenueSelected" @click="sendRevenueRequest()" class="Ellipse-revenue" style="display: flex; justify-content: center; align-items: center;" v-on="on">
                                                <span  style="font-size: 1.5vh; color: #009ea5;padding-bottom:0.2vh">$</span>
                                               
                                              </div>
                                              <div v-else @click="sendRevenueRequest()" class="Ellipse-revenue-select" style="display: flex; justify-content: center; align-items: center;" v-on="on">
                                                <span  style="font-size: 1.5vh; color: #ffff;;padding-bottom:0.2vh">$</span>
                                               
                                              </div>
                                            </template>
                                            <span>Press releases for revenue</span>
                                          </v-tooltip>
                                        </div>
                                      </div>
                                     
                                    
          
                                    </v-card-title>
                                    
                                    <v-card-actions v-if="pan.showArticle"  class="alert_right alert_notes_section" style="overflow-y: hidden; overflow-x: hidden;  display: flex; flex-direction: row; align-items: flex-start; padding:0 1vh 4vh 1vh; z-index: 1000;justify-content: center;">
                                      <div v-if="pan.keywordLoading" style="display: flex; align-items: center; justify-content: center; height: 100%; width: 100%;">
                                          <v-progress-circular indeterminate size="30" color="#d0d0d0"></v-progress-circular>
                                      </div>
                                      <div v-else-if="pan.keywordSearchResults == undefined" style="font-size: 2vh; color: #6e6e6e; display: flex; align-items: center; justify-content: center; height: 100%; width: 100%;">
                                          <span>{{ "No releases found" }}</span>
                                      </div>
                                      <div v-else-if="pan.keywordSearchResults.length == 0" style="font-size: 2vh; color: #6e6e6e; display: flex; align-items: center; justify-content: center; height: 100%; width: 100%;">
                                        <span>{{ "No releases found" }}</span>
                                    </div>
                                    <div  style="display:flex;align-items:center;justify-content:center;flex-direction:column"  v-else>
                                    <div style="padding: 2vh 1vh 1vh 2vh;height: 490px; overflow-y: scroll; overflow-x: hidden;">
                                          <div v-for="(keyword, kk) in pan.keywordSearchResults" :key="kk" style="display: flex; flex-direction: column; margin-bottom: 2vh; margin-right: 1vh;border:solid 1.5px #d5d6d6;border-radius:12px;padding-top:1vh position: relative;">
                                           
                                              <v-expansion-panels flat style="background:#ffff;box-shadow: none !important;border-radius:12px;">
                                                <v-expansion-panel style="background:#ffff;box-shadow: none !important;border-radius:12px;">
                                                    <v-expansion-panel-header  expand-icon="mdi-menu-down" style="font-size: 1.5vh !important;line-height: 1.5;padding:1.5vh!important"><b>{{ keyword.title }}</b></v-expansion-panel-header>
                                                    <v-expansion-panel-content style="font-size: 1.5vh !important; color: #6e6e6e;padding:0vh !important">
                                                      <div>
                                                        <span><b>{{ keyword.date }}</b></span>
                                                      </div> 
                                                      <div>
                                                        <span>{{ keyword.summary }}</span>
                                                      </div> 
                                                      
                                                        
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                              <div style="background:#edeeee ;position:relative;bottom: 0;left:0;;right:0;display: flex; align-items: center; justify-content: flex-start;padding: 1.5vh;border-radius: 0 0 10px 10px;word-break:break-all">
                                                  <a :href="keyword.url" target="_blank" style="font-size: 1.5vh;color:#009ea5; text-decoration: underline;">{{ keyword.url }}</a>
                                              </div>
                                          </div>
                                      </div>
                                      <div style="display:flex;align-items:center;justify-content:center;padding:1vh 0">
                                        <v-pagination color="#03a6ad" v-model="currentPage" :total-visible="5" :length="totalPages" @input="goToPage"></v-pagination>
                                      </div>
                                    </div>
                                
                                  </v-card-actions>
                                  <v-card-actions v-else  class="alert_right alert_notes_section">
                                   
                                    <div style="font-size: 1.5vh; color: #6e6e6e; display: flex;flex-direction:column; align-items: center; justify-content: center; height: 100%; width: 100%;margin-bottom:7vh">
                                      <div  @click="getReleaseData(releases,'a1_1',startDate,endDate,false)" class="Ellipse-press-release" style="display: flex; align-items: center; justify-content: center;">
                                        <img src="../assets/Icons/press-release.svg" style="height:3.5vh;width:3.5vh;">
                                      </div>
                                      <span style="margin-top:2vh">
                                       <b> Click to get Press Releases</b>
                                      </span>
                                    
                                    </div>
                                  
                                  </v-card-actions>
                                  
                                  </v-card>
          
                                </div>
                              </v-col> -->
                            </v-row>
                            <v-row  class="mt-3" v-else>
                              <v-col> 
                                <span v-html="pa.div64"></span>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      
                        <div v-if="pan.Right.Display.Display_Type == 'Table'">
                          <v-row>
                            <!-- <div class="news_heading">{{pan.Left.Header}}</div>
                            <v-menu fixed :absolute="false"  offset-y :close-on-content-click="true">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                  <span class="news_heading">
                                    {{ pan.Left.Header }}
                                  </span>
                                  <img  src="../assets/Icons/Group 635_black.svg" style="height: 1vh;margin-left:5px" />
                                </div>
                              </template>
                              <v-card style="width:62vh;border: #03a6ad solid 1px;border-radius:16px;padding:1.5vh 0">
                                <v-row wrap no-gutters v-for="(p, key1) in  getPanelIds(pan.Left.HeaderPanel.id)" :key="key1" style="cursor:pointer"
                                @click="handleSelectionChange(p)"> 
                                  <v-col>
                                    <v-divider v-if="key1!=0" color="03a6ad" style=""></v-divider>                                                        
                                   

                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-menu>      -->
                          </v-row>
                          <v-row v-for="(pa,keytt) in pan.Right.Display.Display_Info" :key="keytt">
                            <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2" v-for="(pbtn,keyyy) in pa.Dropdownnames" :key="keyyy">                       
                              <v-btn :style="pbtn.selected ? 'background-color: #3fa6a1; color: #ffffff;font-size: 14px;margin-top:2vh;font-weight:700;font-family: Ubuntu;' : 'background-color: #ffffff; color: #3fa6a1;font-size: 14px;margin-top:2vh;font-weight:700;font-family: Ubuntu;'" @click="onChangetrials(pbtn,pa.myTable)">{{pbtn.name}}</v-btn>                     
                            </v-col>                     
                            <table class="pl-0" :id="pa.myTable">
                                    <span v-if="pa.show_dropdown == true">
                                      <tr>
                                        <th :class="(pa.Table_Data.Table_Type == 'Dual Header' && key7 == 0)? 'dualHeaderRow': 'dualHeaderAnotherRow'" v-for="(p,key7) in pa.Table_Data.Columns" :key="key7">{{p}} <span @click="sort_rows(key7, pa.myTable)" v-show="pa.Table_Data.Sort_Enable == 'True'"><i class="fa fa-arrow-down" style="margin-left: 2vh !important;cursor: pointer;"></i></span></th>
                                      </tr>
                                      <tr v-for="(p,key5) in pa.Table_Data.show_rows" :key="key5">
                                        <td v-for="(x,k1) in p" :key="k1" :class="(pa.Table_Data.Table_Type == 'Dual Header' && k1 == 0)? 'dualHeaderData':'dualHeaderAnotherData'">
                                          <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                        </td>
                                      </tr>
                                    </span>
                                    
                              <tr v-if="pa.show_dropdown !== true">
                                <th :class="(pa.Table_Data.Table_Type == 'Dual Header' && key77 == 0)? 'dualHeaderRow': 'dualHeaderAnotherRow'"
                                  v-for="(p,key77) in pa.Table_Data.Columns" :key="key77">{{p}}<span
                                  @click="sort_rows(key77, pa.myTable)" v-show="pa.Table_Data.Sort_Enable == 'True'"><i class="fa fa-arrow-down" style="margin-left: 2vh !important;cursor: pointer;"></i></span>
                                </th>
                                </tr>
                                <tr v-for="(p,key55) in pa.Table_Data.Rows" :key="key55" v-if="pa.show_dropdown !== true">
                                  <td v-for="(x,k11) in p" :key="k11" :class="(pa.Table_Data.Table_Type == 'Dual Header' && k11 == 0)? 'dualHeaderData':'dualHeaderAnotherData'">
                                    <span style="text-transform: capitalize;color: #49575d !important">{{x}}</span>
                                  </td>
                              </tr>                                
                            </table>
                          </v-row>
                        </div>  
                      </v-row>
                    </div>
                  </div>
              </div>
              </div>
              <div v-if="iscustom==true" >
                <div v-for="(slide, slideIndex) in panels" :key="slideIndex" :id="slide.slideid"  :style="slideIndex === 0 ? (isTabletMob ? 'padding-top: 60px;' : 'padding-top: 70px;padding-bottom:30px;') : 'padding-top: 20px;padding-bottom:30px'"  class="slide even_panels_custom">

                  <!-- :style="getSlideStyle(slide)"> -->
                  <!-- Display slide content here -->
                  <div class="relative-div" >
                    <div v-for="shape in slide.slideshapes" :key="shape.id" class="shape" :style="getShapeStyle(shape)">
                      <!-- If shape query is not empty, create span with query -->
                      <!-- <span v-if="shape.query !== ''" style="padding: 10px">{{ shape.query }}</span> -->
        
                      <!-- If shape type is GeometricShape and text is not empty, create a div with text -->
                      <div v-if="shape.type === 'GeometricShape' && shape.text !== '' && !shape.name.includes('Picture')"
                        :id="shape.id + '_' + slide.slideid" class="text-shape">
                        <span v-if="!shape.textLength" v-html="shape.textnew"></span>
                        <span v-if="shape.textLength" v-html="shape.text"></span>
                        <span @click="changetextLength(shape, slide.slideid)" class="pointer"
                          v-if="!shape.textLength && !shape.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;">Read
                          More</span>
                        <span @click="changetextLength(shape, slide.slideid)" class="pointer"
                          v-if="shape.textLength && !shape.no_show" style="color:#009ea5;font-size: 14px;font-weight: 700;">Show
                          Less</span>
                      </div>
                      <!-- If shape type is GeometricShape and shape name includes Line and create shape line using pptxgenjs -->
                      <div v-if="shape.type === 'Line'" :id="shape.id + '_' + slide.slideid" class="line-shape">
                      </div>
        
                      <div v-if="shape.type === 'GeometricShape' && shape.name.includes('Pentagon')"
                        :id="shape.id + '_' + slide.slideid" class="pentagon-shape">
                      </div>
        
                      <div v-if="shape.type === 'GeometricShape' && shape.name.includes('Oval')"
                        :id="shape.id + '_' + slide.slideid" class="oval-shape">
                      </div>
        
                      <div v-if="shape.type === 'GeometricShape' && shape.name.includes('Arrow')"
                        :id="shape.id + '_' + slide.slideid" class="arrow-shape">
                      </div>
        
                      <span
                        v-if="shape.type === 'Image' && shape.answer != '' && shape.base64 != '' || shape.name.includes('Picture')"
                        v-html="shape.div64" :id="'plot' + '_' + slide.slideid + '_' + shape.id"
                        :style="{ width: '100%', height: 'auto', marginTop: shape.top - slide.top + 'px', marginLeft: shape.left - slide.left + 'px' }"></span>
                      <img
                        v-if="shape.type === 'Image' && shape.base64 !== '' && shape.answer == '' || (shape.name.includes('Picture') && shape.answer == '')"
                        :src="'data:image/png;base64,' + shape.base64"
                        :style="{ width: '100%', height: '100%', marginTop: shape.top - slide.top + 'px', marginLeft: shape.left - slide.left + 'px' }" />
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <div class="right notes_section" v-if="!expanded" >
          <v-row wrap style="margin-top:8vh">
            <v-col cols="12" md="10" lg="10" sm="10">
              <div style="font-size: 18px;font-weight: 700;margin-left:4vh;color: #49575d;">Notes</div>
            </v-col>
            <v-col cols="12" md="1" lg="1" sm="1">
              <v-icon color="#009ea5" style="cursor:pointer" @click="close_notes_section()">mdi-close</v-icon>
            </v-col>
          </v-row>
          <v-row v-if="all_notes == null" style="margin-top: 20vh">
            <v-col cols="12" md="2" lg="2" sm="2"></v-col>
            <v-col cols="12" md="8" lg="8" sm="8">
              <div>
                <img src="../assets/Icons/Group 598.svg" style="display: block;margin-left: auto;margin-right: auto;">
              </div>
              <div>
                <p
                  style="text-align: center;margin-top:5vh;margin-bottom:28vh;font-size:16px;font-weight:700;color:#8c8e8e">
                  You have not added any notes yet. Please start making notes for this article </p>
              </div>
            </v-col>
            <v-col cols="12" md="2" lg="2" sm="2"></v-col>
          </v-row>
          <v-row v-if="all_notes != null">
            <v-list v-chat-scroll class="comments_list mb-auto">
              <v-list-item>
                <v-row no-gutters>
                  <v-col cols="12" sm="11" md="11" lg="11">
                    <v-list v-for="(all, key) in all_notes" v-bind:data="all" v-bind:key="key" class="Rectangle-913">
                      <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12" xs="12">
                          <div class="text_notes">{{ all.Note}}</div>
                          <span class="date_notes">{{ all.notes_date }}</span>
                        </v-col>
                      </v-row>
                    </v-list>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-row>
          <v-row>
            <div class="noteBox">
              <v-text-field 
                style="width: 90% !important;
                font-weight: 600;
                font-size: 14px;
                font-family: Ubuntu;
                color: #383838;position: absolute;margin-left:4vh !important" v-model="mynote"
                class="mx-2 mb-3 shrink" placeholder=" Make your notes here...." solo
                prepend-inner-icon="mdi-note-plus-outline"></v-text-field>
            </div>
          </v-row>
        </div>
    
      
        <template>
          <div class="text-center">
            <v-dialog width="500" style=" box-shadow: none !important overflow: visible !important;"  v-model="show_notes_modal">
                <v-card style="border-radius:40px;border: solid 1px #cbcbcb;">
                  <v-card-title class="text-h5"  >
                    <span style="color: #f16753;margin-left: 2vh;font-size: 18px;">Notes</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="show_notes_modal = false">
                      <v-icon color="#009ea5" style="padding-right:2vh;margin-top:0.5vh">mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-row wrap no-gutters>
                      <v-col cols="12" sm="12" md="12" lg="12" xs="12" xl="12" style="">
                        <v-textarea
                          name="input-7-1"
                          class="mt-1"
                          v-model="mynote"
                          type="text"
                          placeholder="Enter notes here"
                          outlined
                          dense
                          rows="8"
                          style="margin-left:2vh;margin-right:2vh;font-weight: 500;"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                  
                  <v-row style="margin-top: -5vh;margin-bottom: 0vh;margin-right:2vh">
                    <v-col sm="9" md="9" lg="9" xs="9" xl="9">
                      <p style="margin-left:5vh;margin-right:2vh;text-align: left;font-family:Ubuntu;font-size: 15px">{{notes_msg}}</p>
                    </v-col>
                    <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                      <v-btn style="background-color: #f16753; color: #ffffff;font-size: 2vh;border-radius: 6px;" @click="savenote(notes_panel)" class="{ disabled: isDisabled }" :disabled="isDisabled">Save</v-btn>
                    </v-col>
                    <!-- <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                      <v-btn style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 2.2vh;font-family: Ubuntu;" @click="show_notes_modal = false">Cancel
                      </v-btn>
                    </v-col> -->
                  </v-row>
                </v-card-actions>  
                </v-card>
              </v-dialog>
          </div>
        </template>

        
        <template>
          <div class="text-center">
            <v-dialog
              v-model="msg_modal"
              width="600"
            >
            <v-card class="Path-359" style="padding:3vh 2vh 3vh 2vh">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="6" lg="6" xs="6" xl="6">
                    <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;margin-right: 3vh;">
                    <span class="Active_Companies_title">Sign Up !</span>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" xs="4" xl="4"></v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xs="2" xl="2">
                    <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right;" @click="msg_modal = false">mdi-close</v-icon>
                  </v-col>
                </v-row>
                <v-row wrap>
                                        
                  <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                    <!-- <img src="../assets/Icons/Path 270.svg" style="height: 3vh;width: 3vh;"> -->
                    <span class="com_nm1" style="margin-left:0vh">Please sign up to get this functionality.</span>
                  </v-col>
                </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-row>
                    <v-col sm="6" md="6" lg="6" xs="6" xl="6"></v-col>
                    <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                      <v-btn style="background-color: #009ea5; color: #ffffff;font-size: 2.2vh;" @click="goto_login()">Sign Up</v-btn>
                    </v-col>
                    <v-col sm="3" md="3" lg="3" xs="3" xl="3">
                      <v-btn style="text-decoration: none;color:#009ea5;font-weight: 600;font-size: 2.2vh;font-family: Ubuntu;" @click="msg_modal = false">Cancel
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>  
              </v-card>
            </v-dialog>
          </div>
        </template>
        
          <SubscriptionModal ref="SubscriptionModal"></SubscriptionModal>
          <ShareModal ref="ShareModal"></ShareModal>
          
        </div>
        </div>
      </div>
    </div>
  </div>
</v-main>
</template>
   

<script>
import { EventBus } from "../event-bus.js";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import postscribe from "postscribe";
import SubscriptionModal from "@/components/subscription_modal.vue";
import ShareModal from "@/components/Share_modal.vue";
 import unorm from 'unorm';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';




export default {
 
 data() {
   return {
     auth_token: '',
      no_authentication: false,
      myjson: {},
      panels: [],
      page_type:'',
      compare_panels: [],
      article_content:'',
      page_title:'',
      expanded: true,
      loading: false,
     fullPage: true,
     quote_text:'',
       msg_modal: false,
         dialog: false,
          networks: [
       // {
       //   network: "facebook",
       //   name: "Facebook",
       //   icon: "fab fa-facebook-f",
       //   color: "#1877f2",
       // },

       {
         network: "linkedin",
         name: "LinkedIn",
         icon: "fab fa-linkedin",
         color: "#007bb5",
       },
       {
         network: "whatsapp",
         name: "Whatsapp",
         icon: "fab fa-whatsapp",
         color: "#25d366",
       },
       // {
       //   network: "messenger",
       //   name: "Messenger",
       //   icon: "fab fa-facebook-messenger",
       //   color: "#0084ff",
       // },
       {
         network: "skype",
         name: "Skype",
         icon: "fab fa-skype",
         color: "#00aff0",
       },
       // {
       //   network: "telegram",
       //   name: "Telegram",
       //   icon: "fab fa-telegram-plane",
       //   color: "#0088cc",
       // },
       {
         network: "twitter",
         name: "Twitter",
         icon: "fab fa-twitter",
         color: "#1da1f2",
       },
       {
         network: "email",
         name: "Email",
         icon: "fa fa-envelope",
         color: "#1da1f2",
       },
     ],
      myurl: "",
      mynote: "",
       show_notes_modal: false,
        notes_msg: "",
        moduleName:"",
        my_word: "",
    queans: [],
    myquestion: "",
    show_que: false,
    pageX: 0,
    pageY: 0,
    outputcpy: "",
    checkboxData:[],
    loadingModal: true,
    questionIds: [],
    option:"fda",
    question: "",
    loadingAnswer: false,
    module_not_found: false,
    option_lables: [
      {
        name: "Labels",
        status: "selected"
      },
      {
        name: "Clinical Studies",
        status: "notselected"
      },
      {
        name: "PubMed",
        status: "notselected"
      },
      {
        name: "Patents",
        status: "notselected"
      },
    ],
   moduleID: "",
   notes_panel: '',
   notes_msg: "",
   domain_origin: "",
   user_plan: "",
   input_id:"",
   pageLoadStart: null,
   loadTime: 0,
   trackingData:{},
   totalPageViewDuration: 0,
   blurTime:0,
   iscustom:false,
   dynamicShapeName: "Rectangle", // Replace with the dynamic shape name you receive at runtime
   shapeOptions: {
      left: 0,
      top: 0,
      width: 100,
      height: 50,
      line: { color: 'FF0000', width: 2 },
      fillColor: 'black', // Add this line
    },
    releases:{},
    alert1:"",
    releaseData:{},
    specificId : 'indi_land_p1_plot1',
    x:0,
    y:0,
    showArticle:false,
    showmenu:true,
    currentPage: 1,
    totalPages: null,
    pageSize: 4 ,
    paginatedReleaseData: [],
    options: ["Past Month","Custom date range"],
    selectedOption: "Past Month",
    selectedDateOption:"",
    startDate: "",
    endDate: "",
    showDatePicker:false,
    alertmsg:"",
    revenueSelected:false,
    showrevenueOption:false,
    dateRange:"",
    showReleases:false,
    selectedIndication:"",
    selectedRelease:"",
    selectedPanel:{"id":"","title":"Go to panel"},
    panels_ids:[],
    progressPercent:0,
   
    
   }
 },
 beforeRouteLeave(to, from, next) {
    // Calculate page view duration
    const pageViewDuration = Date.now() - this.pageLoadStart;
    //convert to seconds
   //console.log("pageViewDuration",pageViewDuration/1000)

    // Log the duration (you can customize this part based on your logging strategy)
   

    // You can also send this information to a server if needed
    this.sendDurationToServer(pageViewDuration);

    next();
  },
  beforeRouteEnter(to, from, next) {
    // Use a local variable to store the timestamp
    //start timer only when page is loaded
    let pageLoadStart = Date.now();

    // Assign the local variable to the component's data property
    next(vm => {
      vm.pageLoadStart = pageLoadStart;
    });
  },
 components: {
  Loading,
  SubscriptionModal,
  ShareModal,
  DatePicker
 
 },
 watch:{
  shapeOptions: {
      handler() {
        this.$forceUpdate(); // Force a re-render when shapeOptions change
      },
      deep: true,
    },
   

 },
 computed: {
   
    isDisabled() {
      if (this.mynote !== '' && this.mynote !== ' ') {
        return false;
      } else {
        return true;
      }
    },
   
    isTabletMob : function() {
      const iPad = /iPad/i.test(navigator.userAgent);
      const iPhone = /iPhone/i.test(navigator.userAgent);
      const Android = /Android/i.test(navigator.userAgent);
      const webOS = /webOS/i.test(navigator.userAgent);
      const iPod = /iPod/i.test(navigator.userAgent);
      const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
      const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);

      return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
    },
    
 },
 created() {
  document.addEventListener('visibilitychange', this.handleVisibilityChange);
  document.addEventListener('sendData', this.handleCustomEvent);
  document.addEventListener('loading', this.handlePressReleases);

    

 },
 mounted() {
  var auth_token = localStorage.getItem("authtoken");
  if (auth_token !== null && auth_token !== "" && auth_token !== undefined && auth_token !== "undefined") {
    this.auth_token = auth_token
    var data={}
    
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/plotly.js/2.8.3/plotly.min.js')
    let sortableScript=document.createElement('script')
    document.head.appendChild(recaptchaScript)
    sortableScript.setAttribute('src','https://cdnjs.cloudflare.com/ajax/libs/Sortable/1.14.0/Sortable.min.js')
    document.head.appendChild(sortableScript)
 
    
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
   
   
    var observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        var arrowDownButton = document.getElementById("arrowDownButton");
        if (this.isTabletMob === false) {
          if (arrowDownButton !== null) {

             arrowDownButton.style.display = "none";
             observer.disconnect();
             
          }
          if (this.moduleID=="659388bf725997546690e306"){
            var downloadDivs = document.querySelectorAll("div[id='download']");
            if (downloadDivs !== null && downloadDivs.length > 0) {
              // console.log("downloadDivs", downloadDivs);
              for (var i = 0; i < downloadDivs.length; i++) {
                downloadDivs[i].style.bottom = "-3vh";
                downloadDivs[i].style.marginRight = "-8vh";
                // console.log("downloadDivs[i]");
              }
              observer.disconnect();
            }
          }
         
        }else{
         //collect all divs with id download 
          var downloadDivs = document.querySelectorAll("div[id='download']");
          if (downloadDivs !== null && downloadDivs.length > 0) {
            // console.log("downloadDivs", downloadDivs);
            for (var i = 0; i < downloadDivs.length; i++) {
              if (this.isTabletMob){
                downloadDivs[i].style.bottom = "-9vh";
                downloadDivs[i].style.marginRight = "-9vh";
                // console.log("downloadDivs[i]");
                
              }

            }
            observer.disconnect();
          }
        }
      });
    });
    

    observer.observe(document.body, { childList: true, subtree: true });
  
    this.domain_origin = window.location.origin
    this.input_id=this.$route.query.input_id

//  console.log(this.$route.query.input_value)
  
  this.moduleName=this.$route.query.input_value
  this.moduleID=this.$route.query.module_id
  this.selectedIndication=this.$route.query.input_value
  this.getUserPlan()
  EventBus.$emit('module_id', this.moduleID)
  this.showGeknowMeModule()
   
  
  }else{
    console.log("no token")
  this.$router.push("/")
 }
   
 },
 beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    document.removeEventListener('sendData', this.handleCustomEvent);
    document.removeEventListener('loading', this.handlePressReleases);
},
 methods: {
  getPanelIds(id){
     //iterate over panels_ids and remove the id from it
     var p_ids=[]
      for (var i = 0; i < this.panels.length; i++) {
        if (this.panels[i].Left.HeaderPanel.id != id) {
          p_ids.push(this.panels[i].Left.HeaderPanel)
        }
      }
      return p_ids
      

  },
  handlePressReleases(event) {
    // console.log("handlePressReleases", event.detail);
  
      this.showArticle = true
      this.showReleases = true
      this.updatePanel1Styles()
      // Handle the storage event here
      this.selectedRelease=this.$route.query.input_value
      this.selectedIndication=this.$route.query.input_value
  
      //save end date as  today's date
      this.endDate = new Date().toISOString().substr(0, 10);
      //save start date as  6 months before today's date
      var date = new Date();
      date.setMonth(date.getMonth() - 6);
      this.startDate = date.toISOString().substr(0, 10);
      var start_date = new Date(this.startDate);

      //take date in format 01-Jan-2021
      start_date=start_date.getDate()+" "+start_date.toLocaleString('default', { month: 'short' })+" "+start_date.getFullYear()
      var end_date = new Date(this.endDate);
      end_date=end_date.getDate()+" "+end_date.toLocaleString('default', { month: 'short' })+" "+end_date.getFullYear()
      this.dateRange="Last 6 months &#9662;"
      //save start date in this.startDate in 2024-01-24 format
      // console.log(start_date,end_date)
      // this.getReleaseData(this.releases, "a1_1",this.startDate, this.endDate,"")
     
  },
  handleVisibilityChange() {
    if (document.hidden) {
      // console.log("hidden")
     this.blurTime=Date.now()
      //console.log(this.blurTime,"blurTime")
    } else  {
      // console.log("visible")
       this.totalPageViewDuration = this.totalPageViewDuration + (Date.now() - this.blurTime)
      //console.log(this.totalPageViewDuration,"totalPageViewDuration")
    }
   
  },
  scrollpage(){
    this.$refs.Scroll.handleScroll()

  },
   updatePanel1Styles()
  {
    if(this.showArticle){
      const download= document.querySelector("#download");
      if (download) {
        download.style.bottom="-2vh"
        download.style.marginRight="1vh"
      }
    }
        

  },
  goBack(){
    this.$router.push("/")
  },
  showGeknowMeModule() {
    // console.log("showGeknowMeModule")
      this.loading=true
       axios
       .get("/userProfile?token=" + this.auth_token)
       .then((res) => {
       if (res.data.Status !== 1) {
           this.user = res.data.Data;
           this.user_approved = res.data.Data.User_approved
           if (this.user_approved == undefined && this.user_approved == null && this.user_approved == '') {
             this.user_approved = false
           }
           //console.log(this.user_approved,"user_approved")
       } else {
        this.loading=false
        this.loadTime=  Date.now()-this.pageLoadStart

         console.log("invalid user")
         this.logout()
       }
     })
     .catch((error) => {
      this.loading=false
      this.loadTime=  Date.now()-this.pageLoadStart

       console.log(error.message);
       if (error.message == "Request failed with status code 500") {
         this.logout()             
       }
     });
     setTimeout(e => {
      // console.log("getMechanismModule")
      axios.post("/getMechanismModule?input_id="+this.$route.query.input_id+"&module_id="+this.$route.query.module_id+"&token="+this.auth_token+"&category="+this.$route.query.category)
      .then(async (res) => {
        try {
          const data = await this.fetchDataWithProgress(res.data.Data.Url);
          this.myjson = data;
          this.get_module_content(this.myjson);

        } catch (err) {
          console.error('Error fetching data:', err);
          this.module_not_found = true;
          throw err;
        }
      })
      .catch((error) => {
        console.error('Error:', error.message);
        this.module_not_found = true;
        if (error.message === "Request failed with status code 500") {
          this.logout();
        }
      });
     },500)
   },
   async fetchDataWithProgress(url) {
    const response = await fetch(url);
    const contentLength = parseInt(response.headers.get('content-length'), 10);

    let receivedBytes = 0;
    const reader = response.clone().body.getReader(); // Clone the response

    const COMPRESSION_RATIO = 4.9;

    const estimatedUncompressedSize = contentLength * COMPRESSION_RATIO;

   

    while (true) {
        const { done, value } = await reader.read();
        if (done) {
            break;
        }
        const bytesReceived = value.length;
        receivedBytes += bytesReceived;
   

        if (receivedBytes >= estimatedUncompressedSize) {
         
            this.progressPercent = 100; // Update progressPercent with the final value
            //sleep for 2 seconds
            break;
        }

        const progress = Math.min(Math.round((receivedBytes / estimatedUncompressedSize) * 100), 100); // Calculate progress 
        this.progressPercent = progress; // Update progressPercent
    }
    this.progressPercent = 100; 
    return response.json();
},

  get_module_content(myjson) {
    // console.log("get_module_content")
   
    this.loading = false
    this.loadTime=  Date.now()-this.pageLoadStart

    var scripts = ''
    //console.log("hello json")
    var len = Object.keys(myjson).length;
    var myval = Object.values(myjson)
    //this.version = myval[0]
    this.page_type = myjson.Type
    this.article_content = myjson.Article
    this.page_title = this.article_content.Header
    this.compare_panels = this.article_content.Panel
    this.panels = this.article_content.Panel
    this.$nextTick(() => {
    this.adjustSlideHeight(true);
  });
  
    
    
    //console.log(this.page_title,"this.panels")
    scripts = `<script type="text/javascript">
        setTimeout(function(){`

        

    //var weblink = "https://www.knolens.io/"
    //var weblink1 = "http://localhost:8080/"
  
    for (var j = 0; j < this.panels.length; j++) {
      
      
     
     
      //this.panels[j].my_panel_id = this.article_prefix + "_" + j
      if (this.panels[j].Right !== undefined){
        this.panels[j].showArticle = false
        var panel ={}
        if (this.panels[j].Left.Header=="Overall state of the indication"){
          panel.description="Quick overview of pipeline and state of trials, including perspectives from the press. User can filter and interactively slice the data in different ways"
        }else if (this.panels[j].Left.Header=="State of approved care"){
          panel.description="State of therapy based on approved drugs."
        }else if (this.panels[j].Left.Header=="Landscape of approved drugs"){
          panel.description="Landscape of approved drugs, their dates, sponsors, and other related information."

        }else if (this.panels[j].Left.Header=="State of trials of approved drugs"){
          panel.description="History of trials associated with approved drugs."

        }else if (this.panels[j].Left.Header=="State of trials of approved drugs- Graphical analysis"){
          panel.description="Graphical view of key Mechanisms of Action and their import over time, along with key sponsors."

        }else if (this.panels[j].Left.Header=="State of Trials of Approved Drugs - Graphical Analysis Company wise"){
          panel.description="Graphical view of key Mechanisms of Action and their import over time, along with key sponsors - an alternative view."

        }else if (this.panels[j].Left.Header=="State of Trials of Drugs in Development"){
          panel.description="This panel displays drug names, status, and phase of all the clinical trials conducted by various sponsors and collaborators for the selected indication."

        }else if (this.panels[j].Left.Header=="State of Trials of Drugs in Development - Graphical Analysis"){
          panel.description="The graph here displays status-wise count of the clinical trials for drugs in development leveraging various mechanism of actions."

        }else if (this.panels[j].Left.Header=="State of Trials of Drugs in Development - Graphical Analysis Company wise"){
          panel.description="The graph here displays status-wise count of the clinical trials conducted by various companies for drugs in development. Upon selection of a mechanism of action all clinical trials based on it for various companies will be displayed."

        } else if (this.panels[j].Left.Header=="Comparison of Trials for Approved Drugs"){
          panel.description="The table here shows phase-wise count of clinical trials associated with an approved drug along with its mechanism of action and company conducting that trial ."

        } else if (this.panels[j].Left.Header=="Comparison of Trials for Drugs in Development"){
          panel.description="The table here shows phase-wise count of clinical trials associated with a drug in development along with its mechanism of action and company conducting that trial."

        }     
        panel.id = this.panels[j].Panel_Id
        panel.title=this.panels[j].Left.Header
        this.panels[j].Left.HeaderPanel=panel
        this.panels_ids.push(panel)
      
        this.panels[j].my_panel_id = this.panels[j].Panel_Id
        if (this.panels[j].Left.First_Text!=null && this.panels[j].Left.First_Text.length>0){
          var href_links = []
          for (var q = 0; q < this.panels[j].Left.First_Text.length; q++) {
            var htmlString = this.panels[j].Left.First_Text[q];

            // Parse the HTML string into a DOM document
            var parser = new DOMParser();
            var doc = parser.parseFromString(htmlString, 'text/html');

            // Select all <a> elements in the DOM document
            var aElements = doc.querySelectorAll('a');

            for (var i = 0; i < aElements.length; i++) {
                var a = aElements[i];
                var href = a.getAttribute('href');
                var abc={}
                // console.log(href);
                abc.link=href
                var text=a.textContent.trim()
                text = text.replace(/^[\s•]+/, ''); 
                abc.linktext=text
                href_links.push(abc)
            // this.panels[j].Left.links_href[i] = abc; // You can perform other actions with 'href' here
                

                // You can perform other actions with 'href' here
            }

            this.panels[j].Left["first_links_href"]=href_links
            // console.log(this.panels[j].Left.links_href,"this.panels[j].Left.links_href")
            // console.log(this.panels,"this.panels")
            if (this.panels[j].Left.First_Text[q].includes("<br>")) {
              var txt1 = this.panels[j].Left.First_Text[q].split("<br>").join("\n"); 
              this.panels[j].Left.First_Text[q] = txt1
            }
          } 
        }
        if (this.panels[j].Left.Second_Text!=null && this.panels[j].Left.Second_Text.length>0){
          var href_links = []
          for (var q = 0; q < this.panels[j].Left.Second_Text.length; q++) {
            var htmlString = this.panels[j].Left.Second_Text[q];

            // Parse the HTML string into a DOM document
            var parser = new DOMParser();
            var doc = parser.parseFromString(htmlString, 'text/html');

            // Select all <a> elements in the DOM document
            var aElements = doc.querySelectorAll('a');

            for (var i = 0; i < aElements.length; i++) {
                var a = aElements[i];
                var href = a.getAttribute('href');
                var abc={}
                // console.log(href);
                abc.link=href
                var text=a.textContent.trim()
                text = text.replace(/^[\s•]+/, ''); 
                abc.linktext=text
                href_links.push(abc)
          // this.panels[j].Left.links_href[i] = abc; // You can perform other actions with 'href' here
                

                // You can perform other actions with 'href' here
            }
            
            this.panels[j].Left["second_links_href"]=href_links
            // console.log(this.panels[j].Left.links_href,"this.panels[j].Left.links_href")
            // console.log(this.panels,"this.panels")
            if (this.panels[j].Left.Second_Text[q].includes("<br>")) {
              var txt1 = this.panels[j].Left.Second_Text[q].split("<br>").join("\n"); 
              this.panels[j].Left.Second_Text[q] = txt1
            }
          }
        }
        if (this.panels[j].Left.Third_Text!=null && this.panels[j].Left.Third_Text.length>0){
          var href_links = []
          for (var q = 0; q < this.panels[j].Left.Third_Text.length; q++) {
            var htmlString = this.panels[j].Left.Third_Text[q];

          // Parse the HTML string into a DOM document
          var parser = new DOMParser();
          var doc = parser.parseFromString(htmlString, 'text/html');

          // Select all <a> elements in the DOM document
          var aElements = doc.querySelectorAll('a');

          for (var i = 0; i < aElements.length; i++) {
              var a = aElements[i];
              var href = a.getAttribute('href');
              var abc={}
              // console.log(href);
              abc.link=href
              var text=a.textContent.trim()
              text = text.replace(/^[\s•]+/, ''); 
              abc.linktext=text
              href_links.push(abc)
          // this.panels[j].Left.links_href[i] = abc; // You can perform other actions with 'href' here
              

              // You can perform other actions with 'href' here
          }

          this.panels[j].Left["third_links_href"]=href_links
          // console.log(this.panels[j].Left.links_href,"this.panels[j].Left.links_href")
          // console.log(this.panels,"this.panels")
            if (this.panels[j].Left.Third_Text[q].includes("<br>")) {
              var txt1 = this.panels[j].Left.Third_Text[q].split("<br>").join("\n"); 
              this.panels[j].Left.Third_Text[q] = txt1
            }
          }
        }
        //console.log(this.panels[j].Left.Forth_Text,"Forth_Text")
        if (this.panels[j].Left.Forth_Text!=null && this.panels[j].Left.Forth_Text.length>0){
          var href_links = []
          for (var q = 0; q < this.panels[j].Left.Forth_Text.length; q++) {
            var htmlString = this.panels[j].Left.Forth_Text[q];

          // Parse the HTML string into a DOM document
          var parser = new DOMParser();
          var doc = parser.parseFromString(htmlString, 'text/html');

          // Select all <a> elements in the DOM document
          var aElements = doc.querySelectorAll('a');

          for (var i = 0; i < aElements.length; i++) {
              var a = aElements[i];
              var href = a.getAttribute('href');
              var abc={}
              // console.log(href);
              abc.link=href
              var text=a.textContent.trim()
              text = text.replace(/^[\s•]+/, ''); 
              abc.linktext=text
              href_links.push(abc)
          // this.panels[j].Left.links_href[i] = abc; // You can perform other actions with 'href' here
              

              // You can perform other actions with 'href' here
          }

          this.panels[j].Left["forth_links_href"]=href_links
          // console.log(this.panels[j].Left.links_href,"this.panels[j].Left.links_href")
          // console.log(this.panels,"this.panels")
            if (this.panels[j].Left.Forth_Text[q].includes("<br>")) {
              //console.log("found")
              var txt1 = this.panels[j].Left.Forth_Text[q].split("<br>").join("\n"); 
              this.panels[j].Left.Forth_Text[q] = txt1
            }
          }
        }
        if (this.panels[j].Left.Fifth_Text!=null && this.panels[j].Left.Fifth_Text.length>0){
          var href_links = []
          for (var q = 0; q < this.panels[j].Left.Fifth_Text.length; q++) {
            var htmlString = this.panels[j].Left.Fifth_Text[q];

          // Parse the HTML string into a DOM document
          var parser = new DOMParser();
          var doc = parser.parseFromString(htmlString, 'text/html');

          // Select all <a> elements in the DOM document
          var aElements = doc.querySelectorAll('a');

          for (var i = 0; i < aElements.length; i++) {
              var a = aElements[i];
              var href = a.getAttribute('href');
              var abc={}
              // console.log(href);
              abc.link=href
              var text=a.textContent.trim()
              text = text.replace(/^[\s•]+/, ''); 
              abc.linktext=text
              href_links.push(abc)
          // this.panels[j].Left.links_href[i] = abc; // You can perform other actions with 'href' here
              

              // You can perform other actions with 'href' here
          }

          this.panels[j].Left["fifth_links_href"]=href_links
          // console.log(this.panels[j].Left.links_href,"this.panels[j].Left.links_href")
          // console.log(this.panels,"this.panels")
            if (this.panels[j].Left.Fifth_Text[q].includes("<br>")) {
              var txt1 = this.panels[j].Left.Fifth_Text[q].split("<br>").join("\n"); 
              this.panels[j].Left.Fifth_Text[q] = txt1
            }
          }
        }
        for (var i = 0; i < this.panels[j].Right.Display.Display_Info.length; i++) {
          // to separate count and text for infographic panel 0
          if (this.panels[j].Right.Display.Display_Type == 'Card') {
            this.panels[j].Right.Display.plot_display = true
            if (this.panels[j].Right.Display.Display_Info[i].Text != "" && this.panels[j].Right.Display.Display_Info[i].Value_Type == "text") {
                var count = this.panels[j].Right.Display.Display_Info[i].Text.substring(0, this.panels[j].Right.Display.Display_Info[i].Text.indexOf(' '));
                this.panels[j].Right.Display.Display_Info[i].count = count
                this.panels[j].Right.Display.Display_Info[i].count = count              
                var rem = this.panels[j].Right.Display.Display_Info[i].Text.substring(this.panels[j].Right.Display.Display_Info[i].Text.indexOf(' ') + 1);
                this.panels[j].Right.Display.Display_Info[i].rem_text = rem
              }
              //console.log(this.panels[j].Right.Display.plot_display,"hiiiii card")
          }

          if (this.panels[j].Right.Display.Display_Type == 'Text Box' && this.panels[j].Right.Display.Display_Info[i].Text !== '') {
            if (this.panels[j].Right.Display.Display_Info[i].Text.length > 1050) {
              var newText = this.panels[j].Right.Display.Display_Info[i].Text.substring(0, 1050);
              this.panels[j].Right.Display.Display_Info[i].newText = newText
              this.panels[j].Right.Display.Display_Info[i].textLength = false
              this.panels[j].Right.Display.Display_Info[i].no_show = false  
            } else {
              var newText = this.panels[j].Right.Display.Display_Info[i].Text
              this.panels[j].Right.Display.Display_Info[i].newText = newText
              this.panels[j].Right.Display.Display_Info[i].textLength = false
              this.panels[j].Right.Display.Display_Info[i].no_show = true
            }
            this.panels[j].Right.Display.plot_display = true
          }

          
          // to get base64 image
          if (this.panels[j].Right.Display.Display_Info[i].Value_Type == "image") {
            //console.log("image")
            var image = new Image();
            image.src = 'data:image/png;base64,'+this.panels[j].Right.Display.Display_Info[i].Plot_Data            
            this.panels[j].Right.Display.Display_Info[i].myimage = image
            //console.log(image,"myimage")
          }

          if (this.panels[j].Right.Display.Display_Type == "Table") {
            this.panels[j].Right.Display.Display_Info[i].table_type = this.panels[j].Right.Display.Display_Info[i].Table_Data.Table_Type
            if (this.panels[j].Right.Display.Display_Info[i].Additional_Info !== undefined) {
              var myObj = this.panels[j].Right.Display.Display_Info[i].Additional_Info
              if (Object.keys(myObj).length > 0) {
                this.panels[j].Right.Display.Display_Info[i].show_dropdown = true
                var drpdons = this.panels[j].Right.Display.Display_Info[i].Additional_Info.Dropdowns
                this.panels[j].Right.Display.Display_Info[i].Dropdownnames = []
                for (var d = 0; d < drpdons.length; d++) {
                  var drp = {}
                  if (d == 0) {
                    drp.selected = true
                  } else {
                    drp.selected = false
                  }
                  drp.name = drpdons[d]
                  this.panels[j].Right.Display.Display_Info[i].Dropdownnames.push(drp)
                }

                this.panels[j].Right.Display.Display_Info[i].N_Rows = this.panels[j].Right.Display.Display_Info[i].Additional_Info.N_Rows
                var first_half = this.panels[j].Right.Display.Display_Info[i].N_Rows/2
                var all_rows = this.panels[j].Right.Display.Display_Info[i].Table_Data.Rows
                var first_half_rows = []
                for (var v = 0; v < first_half; v++) {
                    first_half_rows.push(all_rows[v])
                }
                this.panels[j].Right.Display.Display_Info[i].Table_Data.first_half_rows = first_half_rows
                var second_half_rows = []
                for (var w = first_half; w < all_rows.length; w++) {
                    second_half_rows.push(all_rows[w])
                }
                this.panels[j].Right.Display.Display_Info[i].Table_Data.second_half_rows = second_half_rows
                this.panels[j].Right.Display.Display_Info[i].Table_Data.show_rows = this.panels[j].Right.Display.Display_Info[i].Table_Data.first_half_rows

              } else {
                this.panels[j].Right.Display.Display_Info[i].show_dropdown = false
              }
            } else {
              this.panels[j].Right.Display.Display_Info[i].show_dropdown = false
            }
            this.panels[j].Right.Display.Display_Info[i].myTable = "mytable" + j
            this.panels[j].Right.Display.Display_Info[i].search_found = false
            var row_data = this.panels[j].Right.Display.Display_Info[i].Table_Data.Rows
            if (row_data.length > 0) {
              this.panels[j].Right.Display.plot_display = true
              for (var k = 0; k < row_data.length; k++) {
                var mysrch1 = "\n"
                var matstr = ","
                for (var l = 0; l < row_data[k].length; l++) {
                  if (row_data[k][l].includes(mysrch1)) {
                    const pieces = row_data[k][l].split(mysrch1);
                    const resultingString = pieces.join("\n");
                    // if (resultingString.includes(matstr)) {
                    //   const pieces1 = resultingString.split(matstr);
                    //   const resultingString1 = pieces.join(", ");
                    //   resultingString = resultingString1
                    // }
                    row_data[k][l] = resultingString
                  }
                }
              }
            }
            //this.panels[j].Right.Display.Display_Info[i].Table_Data.Rows = row_data
          }


          // to display financial cards info

          if (this.panels[j].Right.Display.Display_Type == 'Financial_Cards' && (this.panels[j].Right.Display.Display_Info[0].Fin_Card_Data !== undefined || this.panels[j].Right.Display.Display_Info[1].Table_Data !== undefined))  {
            //console.log("hi finance")
            var finance_data = this.panels[j].Right.Display.Display_Info[i].Table_Data
            this.panels[j].Right.Display.plot_display = true
            if (finance_data !== undefined){
              //console.log(finance_data.Columns, "columns")
              this.panels[j].Right.Display.Display_Info[i].Columns = finance_data.Columns 
              this.panels[j].Right.Display.Display_Info[i].Rows = finance_data.Rows
              }
          }

          // to display plot

          if (this.panels[j].Right.Display.Display_Type == 'Plot' && this.panels[j].Right.Display.Display_Info[i].Plot_Data !== "") {
              //console.log("Plooot")
                var double_encode = this.panels[j].Right.Display.Display_Info[i].Plot_Data.split('.')[0]
                var plt_data = window.atob(double_encode);

                //console.log(plt_data,"plt_data")

                var obj = JSON.parse(plt_data)

                //console.log(obj,"obj")

                var plot_data = obj.div64.split('.')[0]
                var div = window.atob(plot_data);
                div = div.replace(/\\"/g, '"')
                div = div.replace(/\\'/g, "'")
                div = div.replace(/\r?\n|\r/g, "\n")

                this.panels[j].Right.Display.Display_Info[i].div64 = div
                this.panels[j].Right.Display.plot_display = true

                //console.log(this.panels[j].Right.Display.Display_Info[i].div64,"div")

                var legend = obj.legend

                //console.log(legend,"legend")
            
                if (legend !== undefined) {
                var colors = {}   
                var code = Object.keys(legend);
                var name = Object.values(legend);
                var color_name = []
                var color_code = []
                for (var z=0; z < name.length; z++) {
                  if (name[z] == "Phase 1") {
                    color_name[0] = name[z]
                    color_code[0] = code[z]
                  } else if (name[z] == "Phase 2") {
                    color_name[1] = name[z]
                    color_code[1] = code[z]
                  } else if (name[z] == "Phase 3") {
                    color_name[2] = name[z]
                    color_code[2] = code[z]
                  } else if (name[z] == "Phase 4") {
                    color_name[3] = name[z]
                    color_code[3] = code[z]
                  } else if (name[z] == "Early Phase 1") {
                    color_name[4] = name[z]
                    color_code[4] = code[z]
                  } else {
                    color_name.push(name[z])
                    color_code.push(code[z])
                  }
                }

                this.panels[j].Right.Display.Display_Info[i].code = color_code
                this.panels[j].Right.Display.Display_Info[i].name = color_name
              }

                var script_data = obj.script64.split('.')[0]
                // var decodedString = atob(script_data);
                // Convert the decoded string to UTF-8
               
                var script = new TextDecoder("utf-8").decode(Uint8Array.from(atob(script_data), c => c.charCodeAt(0)));
                // console.log(script,"script")

                script = script.replace(/\\"/g, '"')
                script = script.replace(/\\'/g, "'")
                script = script.replace(/\r?\n|\r/g, "\n")

                this.panels[j].Right.Display.Display_Info[i].script64 = script

                // console.log(this.panels[j].Right.Display.Display_Info[i].script64,"script")
                //console.log(this.panels[j].Panel_Id,"panels id")

                var str = this.panels[j].Right.Display.Display_Info[i].script64.replace('<script type="text/javascript">', "\n")
                var str1 = str.replace("window.PLOTLYENV=window.PLOTLYENV || {};","\n")
                var str2 = str1.replace("<\/script>", "\n")
              
                str2=this.unicodeToAscii(str2)
                scripts += str2
            }
        }

        if (this.panels[j].Panel_Info == 'Full_Panel') {
          this.all_phases = []
          this.all_companies = []
          if (this.panels[j].Right.Display.Display_Type == "Table") {
            for (var q = 0; q < this.panels[j].Right.Display.Display_Info.length; q++) {
              this.all_companies = this.panels[j].Right.Display.Display_Info[q].Table_Data.Columns
              var rw = this.panels[j].Right.Display.Display_Info[q].Table_Data.Rows
              for (var r = 0; r < rw.length; r++) {
                var phases = {}
                var key = Object.keys(rw[r]);
                var value = rw[r][key];
                phases.names = key
                phases.details = value
                this.all_phases.push(phases)
              }
            }
          }
        }
      }else{
        this.iscustom=true
        
          localStorage.setItem("submodulepage",true)
          localStorage.setItem("input_id", this.$route.query.input_id)
          localStorage.setItem("input_value", this.$route.query.input_value)

        
        //  console.log(this.panels[j].slideshapes.length, "this.panels[j].slideshapes")
        for (let s = 0; s < this.panels[j].slideshapes.length; s++) {
        // now find shapes with the same left
        let shapes = this.panels[j].slideshapes.filter(shape => shape.left === this.panels[j].slideshapes[s].left);
        // now make div other than the first shape with the same left relative to its previous shape
        // for (let i = 1; i < shapes.length; i++) {
        //   shapes[i].top = 0 + shapes[i - 1].height + 10;
        // }

        // console.log(shapes, " shapes");

        // if shape type is Image and text is not empty
        if ((this.panels[j].slideshapes[s].type === 'Image' || this.panels[j].slideshapes[s].name.includes('Picture')) && this.panels[j].slideshapes[s].answer != '') {
          // Decode the qs.Answer from base64
          var answer = window.atob(this.panels[j].slideshapes[s].answer);
          var obj = JSON.parse(answer);

          var plot_data = obj.div64.split('.')[0];
          var div = window.atob(plot_data);
          div = div.replace(/\\"/g, '"');
          div = div.replace(/\\'/g, "'");
          div = div.replace(/\r?\n|\r/g, "\n");

          this.panels[j].slideshapes[s].Plot_div = "Plot_div_" + this.panels[j].slideid + "_" + this.panels[j].slideshapes[s].id;
          this.panels[j].slideshapes[s].div64 = div;

          var div64 = this.panels[j].slideshapes[s].div64;
          // console.log(div64, "div64")
          var div64_dom = new DOMParser().parseFromString(div64, 'text/html');
          var plotdiv = div64_dom.getElementsByClassName('plotly-graph-div')[0];
          var plotdiv_id = plotdiv.id;

          this.panels[j].slideshapes[s].Plot_id = plotdiv_id;
        
          var script_data = obj.script64.split('.')[0];
          var script =  new TextDecoder("utf-8").decode(Uint8Array.from(atob(script_data), c => c.charCodeAt(0)));

          script = script.replace(/\\"/g, '"');
          script = script.replace(/\\'/g, "'");
          script = script.replace(/\r?\n|\r/g, "\n");
          this.panels[j].slideshapes[s].script64 = script;
          // console.log(this.panels[j].slideshapes[s].script64, "script64")

          var str = this.panels[j].slideshapes[s].script64.replace('<script type="text/javascript">', "\n");
          var str1 = str.replace("window.PLOTLYENV=window.PLOTLYENV || {};", "\n");
          var str2 = str1.replace("<\/script>", "\n");
          scripts += str2;

          this.$forceUpdate();
        } else if (this.panels[j].slideshapes[s].type === 'GeometricShape' && this.panels[j].slideshapes[s].text != '' && !this.panels[j].slideshapes[s].name.includes('Picture')) {
          //take text and replace \n with <br>
          // this.panels[j].slideshapes[s].text = this.panels[j].slideshapes[s].text.replace(/\n/g, "<br>");
          var hiddenCharacter = document.createElement('span');
          hiddenCharacter.style.fontSize = '15px';
          hiddenCharacter.id = 'hiddenCharacter';
          document.body.appendChild(hiddenCharacter);
          // Set a reference string for measurement
          var referenceString = ' ';
          // Set the content of the hidden character element
          hiddenCharacter.textContent = referenceString;
          // Calculate the number of characters that can fit on a single line
          var lineHeight = hiddenCharacter.offsetHeight;
          // Calculate the number of lines that can fit within the container height
          var linesInContainer = Math.floor(this.panels[j].slideshapes[s].height / lineHeight);
          // Calculate the number of characters that can fit on a single line
          var charsOnSingleLine = Math.floor(this.panels[j].slideshapes[s].width / hiddenCharacter.offsetWidth);
          // Calculate the number of characters that can fit in total
          var approximateChars = charsOnSingleLine * linesInContainer;
          // console.log("Approximate total characters that can fit:", approximateChars ,this.panels[j].slideshapes[s].text.length);
          // console.log("Approximate characters on a single line:", charsOnSingleLine);
          if (this.panels[j].slideshapes[s].text.length > approximateChars){
            approximateChars = approximateChars - charsOnSingleLine;
          }

          document.body.removeChild(hiddenCharacter);
          var textnew = ""
          if (this.panels[j].slideshapes[s].text.length > approximateChars) {
            textnew = this.panels[j].slideshapes[s].text.substring(0, approximateChars);
            this.panels[j].slideshapes[s].textnew = textnew.replace(/\n/g, "<br>");
            this.panels[j].slideshapes[s].textnew = textnew.replace(/\r/g, "<br>");
            this.panels[j].slideshapes[s].textLength = false;
            this.panels[j].slideshapes[s].no_show = false;
          } else {
            textnew = this.panels[j].slideshapes[s].text;
            this.panels[j].slideshapes[s].textnew = textnew.replace(/\n/g, "<br>");
            this.panels[j].slideshapes[s].textnew = textnew.replace(/\r/g, "<br>");
            this.panels[j].slideshapes[s].textLength = false;
            this.panels[j].slideshapes[s].no_show = true;
          }
        }
      }

      }

    }
  
    scripts = scripts + `}, 2000);<\/script>`
    //console.log(this.panels,"panels")

    postscribe('#gist', scripts,{done: () => {
      // Script loaded, you can now handle its events
      // this.handleExternalScriptEvents(); 
    }});

    //iterate over panels and call drawShape for shapes
    setTimeout(() => {
    // Iterate over panels and call drawShape for shapes
    for (var j = 0; j < this.panels.length; j++) {
      for (let s = 0; s < this.panels[j].slideshapes.length; s++) {
        if (this.panels[j].slideshapes[s].type === 'Line' || this.panels[j].slideshapes[s].name.includes('Pentagon')  || this.panels[j].slideshapes[s].name.includes('Hexagon') || this.panels[j].slideshapes[s].name.includes('Diamond') || this.panels[j].slideshapes[s].name.includes('Star') || this.panels[j].slideshapes[s].name.includes('Ellipse') || this.panels[j].slideshapes[s].name.includes('Circle')) {
          this.drawShape(this.panels[j].slideshapes[s]);
        }
      }
    }
  }, 2100);
  
    if (this.$route.query.panel !== undefined) {
      //var count = this.$route.query.panel.substring(this.$route.query.panel.indexOf('_') + 1)

      if (this.panels.length > 0) {
        //var panelid = this.article_prefix + '_' + count
        var panelid = this.$route.query.panel
        if (this.$route.query.panel !== undefined && this.$route.query.panel !== null && this.$route.query.panel !== '') {
          const myTimeout = setTimeout(function () {
            var element = document.getElementById(panelid);
            if (element !== null) {
              element.scrollIntoView();
            }
          }, 2000)
        }
      }
      
    }
    var myDiv = document.getElementById('topDiv');
    myDiv.scrollTop = 0;
  },
  
  handleExternalScriptEvents() {
    // console.log('Handling external script events');
    document.addEventListener('sendData', this.onMyEvent);
    document.addEventListener('loading', this.handlePressReleases);
  },
  onMyEvent(event) {
    // Handle the 'myEvent' here
  
    // Do whatever you need to do with the event data
  },
  extractAndRenderShape(slide) {
  // console.log(slide, 'ppttttt');

  if (!slide || !slide._slideObjects || !Array.isArray(slide._slideObjects)) {
    console.error('Invalid slide or missing _slideObjects property:', slide);
    return;
  }

  const shapeContainer = this.$refs.shapeContainer;

  slide._slideObjects.forEach((pptxShape) => {
    if (!pptxShape.options) {
      console.error('Invalid pptxShape or missing options property:', pptxShape);
      return;
    }
  // console.log(pptxShape.shape,"bhjv")
  if (pptxShape.shape === 'LINE') {
    // Handle line shapes separately
    const lineElement = document.createElement('div');
    lineElement.style.position = 'absolute';
    lineElement.style.left =`${pptxShape.options.x}px`; // Adjust the left position as needed
    lineElement.style.top =  `${pptxShape.options.y}px`;  // Adjust the top position as needed
    lineElement.style.width =  `40px`; // Adjust the width as needed
    lineElement.style.height =  `1px`;   // A thin line, adjust as needed
    lineElement.style.backgroundColor = 'black'; // Set line color

    shapeContainer.appendChild(lineElement);
  } else {
    // Handle other shapes
    const divElement = document.createElement('div');
    divElement.style.position = 'absolute';
    divElement.style.left = `${pptxShape.options.x }px`; // Convert to percentage for simplicity
    divElement.style.top = `${pptxShape.options.y}px`; // Convert to percentage for simplicity
    divElement.style.width = `${pptxShape.options.w }px`; // Convert to percentage for simplicity
    divElement.style.height = `${pptxShape.options.h}px`; // Convert to percentage for simplicity
    divElement.style.backgroundColor = 'black';

    

    // Other styling based on pptxShape.options properties
    // Note: Some properties might not be directly translatable

    // console.log(shapeContainer, 'shapecontainer');


    shapeContainer.appendChild(divElement);
  }
});
  },
  showExpand() {
    if (!this.no_authentication) {
      this.expanded = true;
    } else {
      this.msg_modal = true
    }      
  },
  showShrink(panell) {
    // if (!this.no_authentication) {
    //   this.expanded = false;
    // } else {
    //   this.msg_modal = true
    // }

    if (!this.no_authentication) {

    //  const panel = panell
    //  let my_panel = JSON.stringify(panel)

    // //  localStorage.setItem("my_panel", JSON.stringify(my_panel));

    //  var display_info = panell.Right.Display.Display_Info

    //  for (var d = 0; d < display_info.length; d++) {
    //    if (display_info[d].hasOwnProperty("div64")) {
    //      delete display_info[d].div64
    //    } else {
          
    //    }

    //    if (display_info[d].hasOwnProperty("script64") ){
    //      delete display_info[d].script64
    //    } else {
          
    //    }
    //  }

    //  panell.Right.Display.Display_Info = display_info  
    
    let my_panel = ""

      this.show_notes_modal = true;
      this.mynote = ""
      this.notes_msg = ""
      this.notes_panel = panell
      var selectedpan = my_panel
      var mypan = JSON.parse(selectedpan)
      for (var k = 0; k < this.panels.length; k++) {
        if (mypan.my_panel_id == this.panels[k].my_panel_id) {
          this.panels[k] = mypan
        }
      } 
      this.$forceUpdate();
    //  localStorage.removeItem("my_panel");
    } else {
      this.msg_modal = true
    }
  },
  show_share_modal(panelid) {
    if (!this.no_authentication) {
    //  this.quote_text = ''
    //  this.send_msg = ''
      this.dialog = true
      this.shared_article_id = this.moduleID
      this.panel_shared = panelid
      this.$refs.ShareModal.share_panel(this.shared_article_id, this.panel_shared,this.input_id,this.page_title,this.$route.query.input_value);

    //  this.myurl= this.domain_origin +"/#/sharewebarticle/" + this.shared_article_id + "/" + panelid
    } else {
      this.msg_modal = true
    }
  },
  close_share() {
    this.dialog = false
    this.network_items = false
  },
  share_article(social) {
    this.dialog = false
    var medium = "social media " + social
    var duration = 0
    var panel_duration = 0
    axios
      .post("/SaveActivity?id=" + this.shared_article_id + '&token=' + this.auth_token + "&activity_name=" + medium + "&disease=" + this.disesase + "&panel_number=" + this.panel_shared + "&page_name" + this.article_title + "&panel_duration=" + panel_duration + "&duration=" + duration)
      .then((res) => {
        if (res.data.Status != 1) {
        // console.log("Activity saved successfully")
        this.network_items = false
      } else if(res.data.Status == 1 && res.data.Data.error == "Invalid User") {
        this.logout()
        //this.$router.push("/").catch(() => {})
      } else {
        console.log("Activity save failed")
      }
    })
    .catch((error) => {
      console.log(error.message);
      if (error.message == "Request failed with status code 500") {
        this.logout()             
      }
    });
  },
  savenote(notespanel) {
     //console.log(this.mynote,this.disesase,this.article_id,notespanel,"this.note")
    //  var notespanel = JSON.stringify(notespanel)

     let senddata = {
        module_id :this.moduleID,
       input_id:this.input_id,
       note: this.mynote,
       notes_panel: notespanel
     }

     let url = `/createmodulenote?token=` + this.auth_token
     let config = { headers: { 'Content-Type': 'application/json' } }
     axios.post(url, JSON.stringify(senddata), config).then((res) => {
         if (res.data.Status != 1) {
           console.log("note saved successfully")
           //this.show_all_notes()
           this.show_notes_modal = false
           //this.notes_msg = "Your note have been saved successfully !"
         } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
           this.logout()
           //this.$router.push("/").catch(() => { })
         } else {             
           console.log("could not save notes")
           this.notes_msg = "Could not save your note !"
         }
       })
       .catch((error) => {
         console.log(error.message);
         if (error.message == "Request failed with status code 500") {
           this.logout()             
         }
       });
  },
  show_all_notes() {
    this.notes_count = 0
    this.all_notes = []
    this.mynote = ''
    axios
      .get("/getallnotes?token=" + this.auth_token + "&article_id=" + this.article_id + "&disease=" + this.disesase)
      .then((res) => {
        if (res.data.Status != 1) {
          this.all_notes = res.data.Data.notes
          //console.log(this.all_notes,"this.all_notes")
          if (this.all_notes != null) {
            this.notes_count = this.all_notes.length
            for (var i = 0; i < this.all_notes.length; i++) {
              const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
              var curr_d = new Date(this.all_notes[i].Created_at),
                curr_month = '' + (curr_d.getMonth() + 1),
                curr_day = '' + curr_d.getDate(),
                curr_year = curr_d.getFullYear();
              this.all_notes[i].notes_date = [curr_day, monthNames[curr_d.getMonth()], curr_year].join(' ')
            }
          }
        } else if (res.data.Status == 1 && res.data.Data.error == "Invalid User") {
          this.logout()
          //this.$router.push("/").catch(() => { })
        } else {
          console.log("notes not found")
        }
      })
      .catch((error) => {
        console.log(error.message);
        if (error.message == "Request failed with status code 500") {
          this.logout()             
        }
      });
  },
  close_notes_section() {
    this.expanded = true
  },
  changetextLength(obj) {
    if (!obj.textLength) {
      obj.textLength = true
    } else {
      obj.textLength = false
    } 
    this.$forceUpdate();
  },
  validateQuote(e) {
    if (e.keyCode === 13) {
      this.quotetext = this.quote_text
      //this.send_msg = "Your message has been added. Please choose social media platform"
    } else {
      // console.log("entering quote");
    }
  },
  onCancel() {
     console.log('User cancelled the loader.')
  },
  takemeto(link) {
   // console.log(link,"link")
    if (!this.no_authentication) {
      //var weblink = "https://www.knolens.io/#"
      //var weblink = "http://localhost:8080/#"
      var link1 = ''
      if (link.includes("%20")) {
        var pieces = link.split("%20");
        var result = pieces.join(" ");
        link1 = result
      } else {
        link1 = link
      }
      var rem = link1.substring(link1.indexOf('&panel=') + 7);

      if (link1.includes(this.article_title)) {
        document.getElementById(rem).scrollIntoView();
      } else {
        //var results = link1.replace(weblink, "");
        var results = link1
       // console.log(results, "results")
        this.$router.push(results).catch(() => { })
        location.reload()
      }
    } else {
      this.msg_modal = true
    }
  },
  showque() {
    if (this.showmenu==false){
      return
    }
    // console.log("showque")
    if (this.auth_token !== null && this.auth_token !== "" &&this.auth_token !== undefined) {
    this.my_word = this.getSelectedText()
    if (this.my_word){
      // console.log(this.my_word,"this.my_word")
      // localStorage.setItem("my_word", this.my_word);
      let selectedText = this.my_word.toString();
      // console.log(selectedText,"selectedText")

      var menu = document.querySelector(".menu1");
      if (selectedText !== "") {
        
        let sel = window.getSelection();
        let getRange = sel.getRangeAt(0);
        let rect = getRange.getBoundingClientRect();
        menu.style.top=rect.top - 32 + 'px',
        menu.style.left=( rect.left + (rect.width * 0.5)-35) + 'px'
        menu.style.display = "block";
        
        // menu.style.left = this.pageX - Math.round(rect.left) + "px";
        // menu.style.top = this.pageY - Math.round(rect.top) - 5 + "px";
        // console.log(menu.style.display,"menu.style.display")
      
        
      } else {
        
        menu.style.display = "none";
      }

      //document.getElementById("output").innerHTML = selectedText;

      if (selectedText !== "") {
        this.outputcpy = selectedText;
      } else {
        this.outputcpy = "";
      }
      // console.log("showqueModal")
        
      this.$forceUpdate();
      }
    }
  },
  showqueModal(){
    if(this.showArticle){
      return
    }
    this.queans_modal = true
    // console.log(this.queans_modal)
    var menu = document.querySelector(".menu1");
    menu.style.display = "none";

    if(this.user_plan=='Basic Plan' || this.user_plan=='Trial'){
        this.queans_modal = true
        this.$router.push({ name: 'Research_Chat', params: { outputcpy: this.outputcpy,queans_modal:this.queans_modal } })
      }else{
         this.$refs.SubscriptionModal.openPopup();
      }

  },
  getSelectedText() {
    let selection = document.getSelection();
    // console.log(selection,"selection")
    if (selection.rangeCount === 0) return;
    let selRange = selection.getRangeAt(0);
    // console.log(selRange,"selRange")
    return selRange;  
  },
  getUserPlan(){
      //create a get request to get the user plan
      axios.get("/getPlan?token=" +this.auth_token ).then((response) => {
        if(response.data.Status !=1)
        // console.log(response.data,"response.data")
        this.user_plan = response.data.Data.Plan
        this.$forceUpdate();
      })
  },
  getPanelStyle(pan){
    if(!this.isTabletMob){
      if(pan.Left.Header=='State of Approved Care'){
        //  set padding left and right 10vh
        return 'padding-left:12em;padding-right:12em'

      }
    }

      
    
  },
  startTracking(divId) {
    if (!this.trackingData[divId]) {
        this.$set(this.trackingData, divId, { startTime: Date.now(), totalTime: 0, clickCount: 0 });
    } else {
        this.trackingData[divId].startTime = Date.now();
    }
    
    
  },
  endTracking(divId) {
      if (this.trackingData[divId]) {
          const endTime = Date.now();
          const duration = Math.round((endTime - this.trackingData[divId].startTime) / 1000);

          // Update the total time spent on this panel
          this.trackingData[divId].totalTime += duration;

          // Log the total time and click count
          // console.log(`User spent ${duration} seconds and clicked ${this.trackingData[divId].clickCount} times on ${divId}`);

          // Reset the start time for the next interaction
          this.trackingData[divId].startTime = null;
      }
  },
  handlePanelClick(divId) {
    // console.log(divId,"divId")
      if (this.trackingData[divId]) {
          // Make sure clickCount is initialized if it doesn't exist
          if (!this.trackingData[divId].clickCount) {
              this.$set(this.trackingData[divId], 'clickCount', 0);
          }

          // Increment the click count
          this.trackingData[divId].clickCount++;
      }
      
  },
  sendDurationToServer(duration) {
    var value=this.$route.query.input_value

    var tracking_data=[]
    //get tracking data trackingData and save in array
    for (var key in this.trackingData) {
      var obj={}
      
      obj.panel_id=key
      obj.duration=this.trackingData[key].totalTime+" seconds"
      obj.clicks=this.trackingData[key].clickCount
      tracking_data.push(obj)
    }

    for(var i=0;i<tracking_data.length;i++){
      //check if panel id is in tracking data
      var panel_id=tracking_data[i].panel_id.split("_")  
      panel_id=panel_id[panel_id.length-1]
      //convert into integer and add 1
      panel_id=parseInt(panel_id)+1
      //again convert into string 
      tracking_data[i].panel_id=panel_id
    }
    
    //convert loadTime into seconds
  //  console.log(this.totalPageViewDuration/1000,"this.totalPageViewDuration",duration/1000,"duration",this.loadTime/1000,"this.loadTime")
    // console.log(this.loadTime,"this.loadTime")
    duration=duration-this.loadTime
    duration=duration-this.totalPageViewDuration
    this.loadTime=0
    //add all panel durations
    

    //stringify tracking data 
    var tracking_data=JSON.stringify(tracking_data)



    // Example using fetch API
    axios.post("/savePageActivity?module_id="+this.moduleID+"&token="+this.auth_token+"&input_id="+value+"&duration="+duration+"&panel_durations="+tracking_data).then(response => {
      if (response.data.Status != 1) {
        console.log("Activity saved successfully")
      } 
      
    }).catch((error) => {
      console.log(error.message);
      if (error.message == "Request failed with status code 500") {
        this.logout()             
      }
    });
  },
  async changetextLengthCustom(obj, slideid) {
    let oldheightchange = true
    let slide_container = null;
    let changeoldlength = true;
    // console.log(slideid, "slideid")


    // console.log("this.panels", this.panels)
    if (!obj.textLength) {
      this.panels.forEach(panel => {
        panel.slideshapes.forEach(shape => {
          // check shape.textLength is true or false
          if (shape.textLength) {
            // set changeoldlength to true
            changeoldlength = false;

          }
        });
      });
      if (changeoldlength) {
        slide_container = document.getElementById(slideid);
        const relativeDivs = slide_container.querySelectorAll('.relative-div');
        let totalHeight = 0;
        relativeDivs.forEach(div => {
          const computedStyle = getComputedStyle(div);
          totalHeight += div.scrollHeight + parseInt(computedStyle.marginTop) + parseInt(computedStyle.marginBottom);
        });
        this.panels.forEach(panel => {
          if (panel.slideid == slideid) {
            panel.oldheight = totalHeight
          }
        });

      }

      obj.textLength = true;
      oldheightchange = true
    } else {
      obj.textLength = false;
      oldheightchange = false
      var oldheight = 0
      this.panels.forEach(panel => {
        panel.slideshapes.forEach(shape => {
          // check shape.textLength is true or false
          if (shape.textLength) {
            // set changeoldlength to true
            changeoldlength = false;

          }
        });
      });
      if (changeoldlength) {
        this.panels.forEach(panel => {
          if (panel.slideid == slideid) {
            oldheight = panel.oldheight
            slide_container = document.getElementById(slideid);
            slide_container.style.height = `${oldheight}px`;
            // console.log(" slide_container.style.height", slide_container.style.height)
          }
        });
      }

    }
    this.$forceUpdate();

    await this.$nextTick();
    this.adjustSlideHeight(oldheightchange);
  },
  getShapeStyle(shape) {
    var fontSize = 0;
    var lineStyles = {};
    var pentagonStyles = {};
    var OvalStyles = {};
    var ArrowStyles = {};
    var TextStyle = {};
    
    if((shape.type === 'Image' ||  shape.name.includes('Picture')) && shape.query!="" && shape.answer!=""  ){
        shape.width = 1130
    }
    if (shape.type === 'Line') {
      const rotationDeg = Math.atan(shape.height / shape.width) * 180 / Math.PI;
      if (rotationDeg !== 90) {
        lineStyles = {
          transform: `rotate(${rotationDeg}deg)`,
          borderTop: `1px solid ${shape.backgroundColor}`,
        };
      } else {
        lineStyles = {
          border: `1px solid ${shape.backgroundColor}`,
        };
      }
    }

    if (shape.name.includes("Pentagon")) {
      pentagonStyles = {
        clipPath: 'polygon(96% 0, 100% 45%, 96% 100%, 0 100%, 0 0)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      };
    }

    if (shape.name.includes("Oval")) {
      // calculate the border radius
      // Calculate border-radius for oval shape
      const borderRadiusX = shape.width / 2;
      const borderRadiusY = shape.height / 2;
      OvalStyles = {
        borderRadius: `${borderRadiusX}px / ${borderRadiusY}px`,
      };

    }

    if (shape.name.includes("Arrow")) {
      // calculate transform for arrow shape
      const rotationDeg = Math.atan(shape.height / shape.width) * 180 / Math.PI;
      ArrowStyles = {
        transform: `rotate(${rotationDeg}deg)`,
        borderLeft: `23.641784776902867px solid transparent`,
        borderRight: `23.641784776902867px solid transparent`,
        borderTop: `28.439790026246733px solid #D9D9D9`,
      };

    }

    if (shape.name.includes("Title")) {
      fontSize = 20 + 'px';
      
    } else if (shape.name.includes("SubTitle") || shape.name.includes("Content Placeholder") || shape.name.includes("Text Placeholder") || shape.name.includes("TextBox")) {
      fontSize = 15 + 'px';
    
    } else {
      fontSize = 15 + 'px';
    }
    if(shape.type=="GeometricShape" && shape.text!=""){
      //check if text has word <a href or <a
      if (shape.text.includes('<a href') || shape.text.includes('<a')) {
        //if yes then add anchor tag to text
        TextStyle = {
          wordBreak: 'break-all',
        };
      }
      // TextStyle = {
      //   wordBreak: 'break-all',
      // }; 
    }

    return {
      position: 'absolute',
      left: shape.left + 'px',
      top: shape.top + 'px',
      width: shape.width + 'px',
      height: shape.height + 'px',
      textAlign: shape.aligntext,
      fontSize: fontSize,
      backgroundColor: shape.backgroundcolor,
      color: shape.textcolor,
      ...TextStyle, // Merge line specific styles
      ...lineStyles, // Merge line specific styles
      ...pentagonStyles, // Merge pentagon specific styles
      ...OvalStyles, // Merge Oval specific styles
      ...ArrowStyles, // Merge Arrow specific styles
    };
  },
  adjustSlideHeight(run) {
    // check if run is true or false
    if (!run) {
      return;
    }
    // console.log("adjustSlideHeight")
    const slideContainers = document.querySelectorAll('.slide');

    slideContainers.forEach((slideContainer, index) => {
      const relativeDivs = slideContainer.querySelectorAll('.relative-div');

      // Initialize total height for each slide
      let totalHeight = 0;

      // Loop through each relative div and sum up their scroll heights
      relativeDivs.forEach((div, divIndex) => {
        // Adjust for margin or padding if present
        const computedStyle = getComputedStyle(div);
        totalHeight += div.scrollHeight + parseInt(computedStyle.marginTop) + parseInt(computedStyle.marginBottom) + 80;

        // Add an additional 60px during the first iteration
        if (index === 0 && divIndex === 0) {
          totalHeight += 60;
        }
      });

      // Set the height of the slide container
      slideContainer.style.height = `${totalHeight}px`;
    });
  },
  unicodeToAscii(input) {
      const asciiString= input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

      // console.log(asciiString,"asciiString")

      return asciiString;
  },
 
  handleCustomEvent(event) {
    if (this.showArticle==false){
      this.showArticle=true
     
    }
  
    this.selectedIndication= ", "+this.$route.query.input_value
    this.showReleases=true
   
    this.updatePanel1Styles()
    const element = document.querySelector("#indi_land_p1_Plot1");
    // if (element) {
    //   element.style.marginLeft="30vh"
    //   this.$forceUpdate();
    //   // element.style.marginLeft="300px"

    // }
    
      this.releases = event.detail;
      this.revenueSelected=false
      this.showDatePicker=false
      this.endDate = new Date().toISOString().substr(0, 10);
      var date = new Date();
      date.setMonth(date.getMonth() - 6);
      this.startDate = date.toISOString().substr(0, 10);
      var start_date = new Date(this.startDate);
      //take date in format 01-Jan-2021
      start_date=start_date.getDate()+" "+start_date.toLocaleString('default', { month: 'short' })+" "+start_date.getFullYear()
      var end_date = new Date(this.endDate);
      end_date=end_date.getDate()+" "+end_date.toLocaleString('default', { month: 'short' })+" "+end_date.getFullYear()
      // this.dateRange=start_date+" - "+end_date +" &#9662; "
      this.dateRange="Last 6 months &#9662;"
      //replace[ and ] with space
     
      this.releases.acronym = this.releases.acronym.replace(/<br>/g, ' ');


      //check if releases.acronym contains Company 
      if (this.releases.acronym.includes("Company:")) {
        this.releases.type="company"
        //remove Company:from releases.acronym
        this.releases.acronym = this.releases.acronym.replace("Company:", '');
      }

      if (this.releases.acronym.includes("NCT") && this.releases.type=="nct") {
          this.releases.acronym = this.releases.acronym.split('[')[0];
       
       
        //store only word staring with NCT in releases.nct
        this.releases.nct = this.releases.acronym.match(/\bNCT\w+/g);
        // console.log(this.releases.nct,"this.releases.nct")
        var nct="<a href='https://clinicaltrials.gov/ct2/show/"+this.releases.nct+"'  style='text-decoration: underline;' target='_blank'><b>"+this.releases.nct+"</b></a>"
        this.releases.nct= this.releases.acronym.replace(this.releases.nct,nct)
        this.releases.nct=this.releases.nct + this.selectedIndication
        this.showrevenueOption=false
        this.selectedRelease=this.releases.acronym + this.selectedIndication
      }else if (this.releases.type=="company"){
        this.releases.acronym = this.releases.acronym.split('[')[0];
      
        if (this.releases.acronym.includes("NCT")) {
          this.releases.acronym = this.releases.acronym.replace(/NCT\w+/g, '');
        }
       
        this.showrevenueOption=true
        this.selectedRelease=this.releases.acronym + this.selectedIndication
      }else if (this.releases.type=="root")  {
        this.showrevenueOption=false
        this.selectedIndication=""
        this.selectedRelease=this.releases.acronym
      }else{
        this.releases.acronym = this.releases.acronym.split('[')[0];
     
        this.showrevenueOption=false
        this.selectedRelease=this.releases.acronym + this.selectedIndication
        if (this.releases.acronym.includes("NCT")) {
          this.releases.acronym = this.releases.acronym.replace(/NCT\w+/g, '');
        }
      }
        
      for (var i = 0; i < this.panels.length; i++) {
        if (this.panels[i].my_panel_id == "a1_1") {
          this.panels[i].showArticle=false;
          this.$forceUpdate();
          break;
        }
     }

  },
  getReleaseData(data,id,start_date,end_date,revenue){
    this.showReleases=false
    // this.releases.acronym = this.releases.acronym.replace(/[\[\]]+/g, ' ');

    this.currentPage=0
    for (var i = 0; i < this.panels.length; i++) {
      if (this.panels[i].my_panel_id == "a1_1") {
        // console.log("panel id matched")
        this.panels[i].keywordLoading=true;
        this.panels[i].showArticle=true;
        //get elemeny by id indi_land_p1_Plot1
        
        this.$forceUpdate();
        break;
      }
    }
    this.showmenu=false
    let url = `/searchKeywords?token=` + this.auth_token + "&start_date=" +start_date+ "&end_date=" +end_date + "&revenue=" +revenue+"&indication="+this.selectedIndication
    let config = { headers: { 'Content-Type': 'application/json' } }

    axios.post(url,data,config).then((res) => {
      this.releaseData = res.data.Data.Answer
      console.log(this.releaseData,"this.releaseData")
      if (this.releaseData.length==0){
        for (var j = 0; j < this.panels.length; j++) {
          if(this.panels[j].my_panel_id==id){
            this.panels[j].keywordLoading=false
            this.panels[j].keywordSearchResults=[]
            this.$forceUpdate();
          }
        }
        return
      }else if (this.releaseData.length==1){
        if (this.releaseData[0].title==""){
          for (var j = 0; j < this.panels.length; j++) {
            if(this.panels[j].my_panel_id==id){
                // console.log("No data found")
                this.panels[j].keywordLoading=false
                this.panels[j].keywordSearchResults=[]
                this.$forceUpdate();
              }
          }
          return
        }
      }
      //loop over the releasedata and call asciiToUnicode for each keyword and store it in keyword
      for (var i = 0; i < this.releaseData.length; i++) {
        this.releaseData[i].title = this.unicodeToAscii(this.releaseData[i].title)
        this.releaseData[i].summary = this.unicodeToAscii(this.releaseData[i].summary)
      }
      this.totalPages = Math.ceil(this.releaseData.length / this.pageSize);
      this.goToPage(1); // Go to the first page after fetching data
      //iterate over panels and call drawShape for shapes
    
      
    }).catch((error) => {
    for (var j = 0; j < this.panels.length; j++) {
        if(this.panels[j].my_panel_id==id){
        
        this.panels[j].keywordLoading=false
        this.$forceUpdate();
        }
      }
      console.log(error.message);
      if (error.message == "Request failed with status code 500") {
        this.logout()             
      }
    });

    
  },
  goToPage(pageNumber) {
    if (pageNumber < 1 || pageNumber > this.totalPages) {
      return;
    }
    const startIndex = (pageNumber - 1) * this.pageSize;
    const endIndex = pageNumber * this.pageSize;
    this.paginatedReleaseData = this.releaseData.slice(startIndex, endIndex);
    this.currentPage = pageNumber;
    // Additional logic as needed
    for (var j = 0; j < this.panels.length; j++) {
      if (this.panels[j].my_panel_id == "a1_1") {
      
        this.panels[j].keywordSearchResults = this.paginatedReleaseData;

        this.panels[j].keywordLoading = false;
        this.$forceUpdate();
      }
    }

  },
  saveDate(){
    // console.log("saveDate",this.startDate)
    if(this.startDate==""){
      this.alertmsg="Please select start date"
      // console.log("Please select some date")
      return
    }else if (this.endDate==""){
      this.alertmsg="Please select end date"
      // console.log("Please select some date")
      return
    }
    if (new Date(this.startDate) > new Date(this.endDate)) {
        this.alertmsg="Start date should be less than end date"
        this.endDate = ""
        return;
    }
    //check if end date is greater than today's date
    if (new Date(this.endDate) > new Date()) {
      this.endDate = new Date().toISOString().substr(0, 10);
    }

    this.showDatePicker=false
    this.alertmsg=""
    if (this.startDate != "" && this.endDate != ""){
      this.getReleaseData(this.releases,"a1_1",this.startDate,this.endDate,this.revenueSelected)
    }
    //convert date into string and store it in dateRange
    var start_date = new Date(this.startDate);
    //take date in format 01-Jan-2021
    start_date=start_date.getDate()+" "+start_date.toLocaleString('default', { month: 'short' })+" "+start_date.getFullYear()
    var end_date = new Date(this.endDate);
    end_date=end_date.getDate()+" "+end_date.toLocaleString('default', { month: 'short' })+" "+end_date.getFullYear()
    this.dateRange=start_date+" - "+end_date +" &#9662; "

  },
  sendRevenueRequest(){
    if (this.revenueSelected==true){
      this.revenueSelected=false
      this.getReleaseData(this.releases,"a1_1",this.startDate,this.endDate,false)
    }else{
      this.revenueSelected=true
      this.getReleaseData(this.releases,"a1_1",this.startDate,this.endDate,true)
    }
    

  },
  handleSelectionChange(p) {
    this.selectedPanel = p;
    // Scroll to the selected panel
    var element = document.getElementById(this.selectedPanel.id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    //close v-menu attached to p.id
    for (var i = 0; i < this.panels.length; i++) {
        if (this.panels[i].Left.HeaderPanel.id === p.id) {
          this.$set(this.panels[i].Left.HeaderPanel, 'show', false);
          this.$forceUpdate();
            
        }
    }


    
  },

  updateDropdownState(p, state) {
   
    // Find the panel object and update its show property
    for (var i = 0; i < this.panels.length; i++) {
      if (this.panels[i].Left.HeaderPanel.id == p.id) {    
        
          this.$set(this.panels[i].Left.HeaderPanel, 'show', true);
          this.$forceUpdate();
      }
    }
  },
  },
  
}
</script>


<style lang="scss" >
.margin_align {
 overflow-x: hidden;
 overflow-y: auto;
 height: 100vh !important;
}
.left {
 height: 100%;
 width: 100%;
 position: absolute;
 left: 0;
 padding-left: 2%;
 padding-right: 1.5%;
 padding-bottom: 20px;
 padding-top: 0px;
 margin: 0vh 0vh 5vh -5vh !important;
 overflow-y: auto
}

.leftPanelExpand {
 width: 100%;
 padding-left: 2%;
 padding-right: 1%;
 padding-bottom: 20px;
 padding-top: 20px;
}

.right {
 width: 40%;
 position: absolute;
 right: 0;
 overflow-y: hidden;
 background: white;
 padding-bottom: 5px;
 box-shadow: -5px 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.alert_right {
  
  height: 100%;
 
  overflow-y: hidden;
  
  padding-bottom: 5px;
  background: white;
  
}
.v-card-text {
 font-family: Ubuntu;
 font-size: 16px;
 font-weight: 600;
 font-stretch: normal;
 font-style: normal;
 line-height: normal;
 letter-spacing: normal;
 float: left;
 color: #49575d;
 padding: 16px 0px 8px;
 background: white;
}

.col-md-2 {
 flex: 1 0 auto;
 width: 12.66666667%;
}
.My-Modules {
 font-family: Ubuntu;
 font-size: 20px;
 
 font-weight: normal;
 font-stretch: normal;
 font-style: normal;
 line-height: 1.43;
 letter-spacing: normal;
 text-align: left;
 color: #2c2c48;
 margin-left:3vh
}

.aa {
 background: #009ea5 !important;
 font-family: Ubuntu;
 font-size: 18px;
 font-weight: 500 !important;
 font-stretch: normal;
 font-style: normal;
 line-height: normal;
 letter-spacing: normal;
 text-align: left;
 color: #fff !important;
 text-transform: none;
}

.bb {
 background: #009ea5 !important;
 font-family: Ubuntu;
 font-size: 18px;
 font-weight: 500 !important;
 font-stretch: normal;
 font-style: normal;
 line-height: normal;
 letter-spacing: normal;
 text-align: left;
 color: #fff;
}
/*

td {
 padding: 1vh;
 font-size: 16px;
 color: #333;
 border: 1px solid #D7DBDD;
}

tr {
 border-top: 1px solid #D7DBDD;
 font-size: 16px;
 color: #333;
 white-space: pre;
 background-color: #ffffff;
}

th {
 text-align: center;
 padding: 1vh;
 background-color: #ebf3f7;
 font-family: Ubuntu;
 border: 1px solid #D7DBDD; 
}

tr:nth-child(odd) {
 background-color: #f3fbfc;
}
*/

.line {
 border: 1px solid #797C7D;
 background-color: #797C7D !important;
 margin: 0.5rem 0;
}

.pre-formatted {
 white-space: pre-wrap;
 /* 👈 this is the important part */
}

.news_heading {
 margin: 0px 0px 18px 0px;
 font-family: Ubuntu;
 font-size: 20px !important;
 font-weight: 700;
 font-stretch: normal;
 font-style: normal;
 line-height: normal;
 letter-spacing: normal;
 text-align: left;
 color: #374246;
}

.title1 {
 font-family: Ubuntu;
 font-size: 14px !important;
 font-weight: 500;
 font-stretch: normal;
 font-style: normal;
 line-height: 1.4;
 letter-spacing: normal;
 text-align: left;

 color: #384348;
}

.titleCenter {
 text-align: center;
 margin:0 0.5vw;
}

.cardTitles {
 font-family: Ubuntu;
 font-size: 13px;
 font-weight: 700;
 font-stretch: normal;
 font-style: normal;
 line-height: 1.22;
 letter-spacing: normal;
 color: #49575d;
}

.cardTitles1 {
 font-family: Ubuntu;
 font-size: 15px;
 font-weight: 500;
 font-stretch: normal;
 font-style: normal;
 line-height: 1.22;
 letter-spacing: normal;
 color: #49575d;
}

.itemCountDesign {
 font-family: Ubuntu;
 font-size: 25px;
 font-weight: 500;
 font-weight: normal;
 font-stretch: normal;
 font-style: normal;
 line-height: normal;
 letter-spacing: normal;
 text-align: left;
 color: #374246;
 margin-left: 4px;
}

.pharmaContent {
 font-family: Ubuntu;
 font-size: 18px;
 font-weight: 600;
 font-stretch: normal;
 font-style: normal;
 line-height: 1.46;
 letter-spacing: normal;
 text-align: left;
 color: #49575d;
 margin-top: -5vh;
}

.header_text {
 text-transform: capitalize;
 font-family: Ubuntu;
 font-size: 15px;
 font-weight: 500;
 font-stretch: normal;
 font-style: normal;
 line-height: 1.46;
 letter-spacing: normal;
 text-align: left;
 color: #49575d;
 margin-left: 15vh
}

.linktitle {
 margin: 5vh 45px 10px 0;
 font-family: Ubuntu;
 font-size: 17px;
 font-weight: normal;
 font-stretch: normal;
 font-style: normal;
 line-height: normal;
 letter-spacing: normal;
 text-align: left;
 color: #374246;
}

.hrLine {
 border-bottom: 2px solid #374246;
 margin: 0rem 0;
 max-width: 5vw;
 justify-content: center;
 display: flex;
 color:#374246
}

.Rectangle-718 {
 width: 30px;
 height: 30px;
 padding: 3px 5px 5px 9px!important;
 background-color: #d5f8fa;
}


.titleCenter {
 margin-top: 3vh;
 text-align: center;
}

.table_header {
 background-color: #3fa6a1;
 color: #ffffff;
 font-size: 16px;
 font-weight: 700;
 text-transform: capitalize;
 word-wrap:break-word !important;
 white-space: -o-pre-wrap; 
 word-wrap: break-word;
 white-space: pre-wrap; 
 white-space: -moz-pre-wrap; 
 white-space: -pre-wrap; 
 word-wrap:break-word !important;
}

.circle {
 border-radius: 50%;
 height: 10px;
 width: 10px;
 border: 2px solid;
 float: left;
 margin: 10px 3px 0px 0px;
}

.text_left {
 font-weight: 600;
 color: #49575d;
 font-size: 16px
}

.appBarBox {
 height: 90px;
 padding-left: 2%;
 padding-right: 1%;
 margin-bottom: 15px;
 margin-top: -3vh !important;
 margin-left: -3vh;
 background-color: #f8fafb;
}

.optionMenu {
 color: #009ea5;
 font-family: Ubuntu;
 font-size: 16px;
 font-weight: 600;
}

.popUpVersion {
 font-family: Ubuntu;
 font-size: 16px;
 font-weight: 600;
 text-align: left;
 color: #404040;
 opacity: 0.86;
 text-align: center !important
}

.popUpDates {
 opacity: 0.86;
 font-family: Ubuntu;
 font-size: 16px;
 font-weight: 300;
 text-align: left;
 color: #404040;
 float: left;
}

.breadcrums {
 font-family: Ubuntu;
 font-size: 15px;
 font-weight: 700;
 text-align: left;
 text-decoration: none;
}

.v-breadcrumbs__item {
 color: #009ea5 !important
}

.v-breadcrumbs__item v-breadcrumbs__item--disabled {
 color: #333333 !important
}

.Rectangle-913 {
 margin: 2vh;
 padding: 23.1px 30.5px 23.9px 23.5px;
 border-radius: 18px;
 border: solid 1px #ccc !important;
 background-color: #f9f9f9 !important;
}

.alert_notes_section {
 height: 550px !important;
 background-color: #f9f9fb;
 z-index: 2;
 font-family:Ubuntu;
 overflow: hidden !important;
}

.comments_list {
 padding: 1vh 1vh 5vh 1vh;
 height: 75vh;
 max-height: 75vh;
 overflow-y: auto;
 background: inherit;
}

.Rectangle-913 {
 margin: 2vh;
 padding: 0vh 1vh 1vh 1vh;
 border-radius: 18px;
 border: solid 1px #ccc;
 background-color: #fff;
}

.text_notes {
 font-size: 16px;
 font-weight: 500;
 color: #49575d;
 margin-bottom:1vh !important;
}

.date_notes {
 font-size: 16px;
 font-weight: 500;
 color: #8c8e91 !important
}

.tablePhases1 {
 color: #333;
 background-color: #f8fafb;
 font-size: 16px;
 border: none !important;
 border-top: 1px solid #f8fafb !important;
}

.tablePhases2 {
 background-color: #f3fbfc;
}

.mech2Btn {
 background-color: rgba(255, 103, 80, .1);
 border-color: #ff6750;
 font-size: 16px;
 font-weight: 500;
 color: #ff6750;
 font-family: Ubuntu;
 border-radius: 10px;
 padding: 1vh !important;
 height: 100% !important;
}

.tablePhases {
 position: sticky;
 z-index: 1;
 left: 0;
 border-top: 1px solid #D7DBDD !important;
 border-bottom: 1px solid #D7DBDD !important;
 border-left: 1px solid #D7DBDD !important;
}

.tablenonphase {
 background-color: inherit;
 font-size: 16px;
 font-weight: 700
}

.fix {
 position: absolute;
 /*margin-left: -100px;*/
 width: 100px;
}

.dualHeaderRow {
 background-color: #3fa6a1;
 color: #ffffff;
 font-size: 13px;
 font-weight: 700;
 text-transform: capitalize;
 border-bottom: 1px solid #D7DBDD !important;
 border-left: 1px solid #D7DBDD !important;
 position: sticky;
 z-index: 1;
 left: 0;
}

.dualHeaderAnotherRow {
 background-color: #3fa6a1;
 color: #ffffff;
 font-size: 13px;
 font-weight: 700;
 text-transform: capitalize;
}

.dualHeaderData {  
 background-color: #d2edee;
 font-size: 13px;
 font-weight: 700;
 border-top: 1px solid #D7DBDD !important;
 border-bottom: 1px solid #D7DBDD !important;
 border-left: 1px solid #D7DBDD !important;
 position: sticky;
 z-index: 1;
 left: 0;
 word-wrap:break-word !important;
 white-space: -o-pre-wrap; 
 word-wrap: break-word;
 white-space: pre-wrap; 
 white-space: -moz-pre-wrap; 
 white-space: -pre-wrap; 
 word-wrap:break-word !important;
}

.dualHeaderAnotherData {

 background-color: inherit;
 font-size: 13px;
 font-weight: 500;
 
 //width: 100%;
 word-wrap:break-word !important;
 white-space: -o-pre-wrap; 
 word-wrap: break-word;
 white-space: pre-wrap; 
 white-space: -moz-pre-wrap; 
 white-space: -pre-wrap; 
 word-wrap:break-word !important;
}

.finaTableWrap{
 word-wrap:break-word !important;
 white-space: -o-pre-wrap; 
 word-wrap: break-word;
 white-space: pre-wrap; 
 white-space: -moz-pre-wrap; 
 white-space: -pre-wrap; 
 word-wrap:break-word !important;
}

.finathWrap{
 word-wrap:break-word !important;
 white-space: -o-pre-wrap; 
 word-wrap: break-word;
 white-space: pre-wrap; 
 white-space: -moz-pre-wrap; 
 white-space: -pre-wrap; 
 word-wrap:break-word !important;
}
/*
.icon {
 font-size: 30px;
}
*/
.finance_text {
 font-family: Ubuntu;
 font-weight: 500;
 font-size: 16px
}
.fincard {
 margin: 0.5vh;
 padding:2vh;
 height:70vh;
 overflow-y:scroll;
}

.Path-359 {
 box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
 border: solid 1px #ff6750 !important;
 background-color: #fff;
 border-radius: 20px !important;
}

.Active_Companies_title {
 margin: 2vh 2vh 2vh 0vh;
 font-family: Ubuntu;
 font-size: 2.8vh;
 font-weight: 600;
 font-stretch: normal;
 font-style: normal;
 line-height: 1.24;
 letter-spacing: normal;
 text-align: left;
 color: #ff6750;
}
.Path-85 {
 box-shadow: none !important;
 margin: 2vh;
 padding: 1vh 3vh 2vh 3vh;
 border: solid 0.6px #d3d3d3 !important;
 background-color: #fdfefe;
 height: 40vh;
 border-radius: 7px !important;
}
.com_nm {
 margin-top: 3vh;
 font-size: 2.2vh;
 font-weight: 700;
 font-family: Ubuntu;
 text-align: center;
 color: #383838 !important;
 height: 4vh;
}
.com_nm1 {
 margin-top: 2vh;
 font-size: 2.3vh;
 font-weight: 500;
 font-family: Ubuntu;
 text-align: center;
 color: #383838 !important;
}
.noteBox {
 position:absolute;
 float:bottom;
 bottom:80px;
}
.even_panels_custom {
  background-color:#f8fafb;
 //  margin:0px -10px 6px 0px;
 margin:0px -10px 0px -14px;
 //  padding: 0px 6vw 40px 9vw;
 padding: 0px 5vw 0px 8vw;
 box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.16) !important;
 }
.even_panels {
 background-color:#f8fafb;
//  margin:0px -10px 6px 0px;
margin:0px -10px 6px -14px;
//  padding: 0px 6vw 40px 9vw;
padding: 0px 6vw 50px 9vw;
box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.16) !important;
}
.odd_panels {
 background-color:#fff;
//  margin:0px -10px 6px 0px;
 margin:0px -10px 6px -14px;
 //  padding: 0px 6vw 40px 9vw;
 padding: 0px 6vw 50px 9vw;
 box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
}
.even_panels_full {
  background-color:#f8fafb;
 //  margin:0px -10px 6px 0px;
 margin:0px -10px 6px -14px;
 //  padding: 0px 6vw 40px 9vw;
 padding: 4vw 9vw 50px 9vw;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.16) !important;
 
 }
 .odd_panels_full {
  background-color:#fff;
 //  margin:0px -10px 6px 0px;
  margin:0px -10px 6px -14px;
  //  padding: 0px 6vw 40px 9vw;
  padding: 4vw 7vw  50px 7vw;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
 
 }

.Path-499 {
  height: 30px;
  margin: -25px 5.5px 15px 0px;
  padding: 2px 3px 3px 2px;
  box-shadow: 0 2px 3px 0 rgba(88, 88, 88, 0.16) !important;
  border: solid 0.7px #cfd3d5;
  border-radius: 7px;
  background-color: #fff !important;
}

.Path-499_111 {
  width:20vw !important;
  height: 30px;
  margin: -25px 5.5px 15px 0vh;
  padding: 2px 3px 3px 2px;
  box-shadow: 0 2px 3px 0 rgba(88, 88, 88, 0.16) !important;
  border: solid 0.7px #cfd3d5;
  border-radius: 7px;
  background-color: #fff !important;
  float:left
}
.link_content {
 font-family: Ubuntu;
 font-weight: 500;
 font-stretch: normal;
 font-style: normal;
 line-height: 1.34;
 letter-spacing: normal;
 text-align: left;
 color:#32393c !important;
 font-size: 13px;
 text-decoration: none;
}
.textbox_content {
 font-family: Ubuntu;
 font-size: 15px !important;
 font-weight: normal;
 font-stretch: normal;
 font-style: normal;
 line-height: 1.67;
 letter-spacing: normal;
 text-align: left;
 color: #384348;
}
.texttitle {
 font-family: Ubuntu;
 font-size: 20px;
 font-weight: normal;
 font-stretch: normal;
 font-style: normal;
 line-height: normal;
 letter-spacing: normal;
 text-align: left;
 color: #374246;
 margin-top:2vh
}
.menu1 {
  display: none;
  position: absolute;
  
  border-radius: 6px;
  
  padding:1vh 2vh 1vh 2vh;
  cursor: pointer;
  z-index: 1;

}
.alert_button{
  display: none;
  position: absolute;
  border-radius: 6px;
  padding:1vh 2vh 1vh 2vh;
  cursor: pointer;
  z-index: 1;

}
.menu1 i {
  color: #03a6ad;
  cursor: pointer;
  margin: 8px;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

.slide {
  /* Define the size and position of your slides */
  //min-height: 100vh;
  //height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  /* Center horizontally */
}

.relative-div {
  /* Ensure the relative div occupies the whole height of the content */
  position: relative;
  //height: auto;
  width: 100%;
  padding-right:3vh !important;
  //max-width: 120vh;
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  


  /* Set your desired max-width */
}

.shape {
  /* Add styles for your shapes (GeometricShape) */
  position: absolute;
  /* Example border for illustration */
}

.text-shape {
  /* Additional styles for the text shape to prevent overlapping 
  position: absolute;
  width: 100%;
  // height: auto;
  top: 0;
  left: 0;
  */
}
.full-width-height-canvas {
  width: 100%;
  height: 100%;
  display: block;
}
 /* Define the width of the scrollbar */
 ::-webkit-scrollbar {
  width: 4px; /* Adjust this value to your desired width */
}

/* Define the appearance of the scrollbar track */
::-webkit-scrollbar-track {
  background: none; /* Change this color as needed */
}

/* Define the appearance of the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: #d6d8d8; /* Change this color as needed */
  border-radius: 10px; /* Add border radius to the scrollbar thumb */
}

/* Define the appearance of the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background:#d6d8d8; /* Change this color as needed */
}
.v-menu {
  box-shadow: none !important;
}
.list_display {
  border:solid; 
  border-radius: 10px; 
  border-color: #ff6750;
  overflow-y:auto;
}
//remove the default box shadow for v-menu
.v-menu__content {
  box-shadow: none !important;
}
.Ellipse-press-release{
  width: 9vh;
  height: 9vh;
  background-color: #ffff;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  font-size:15px;
 
  border:solid 1px #68adb7 !important;
  cursor:pointer;
 
 
}

.Ellipse-revenue{
  width: 3vh;
  height: 3vh;
  background-color: #ffff;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  font-size:15px;
  margin:auto;
  border:solid 1px #68adb7 !important;
  cursor:pointer;
 
 
}
.Ellipse-revenue-select{
  width: 3vh;
  height: 3vh;
  background-color: #68adb7;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  font-size:15px;
  margin:auto;
  border:solid 1px #ffff !important;
  cursor:pointer;
 
 
}


.v-pagination__item {
  background-color: transparent !important;
  box-shadow:none !important;
}
.theme--light.v-pagination .v-pagination__item--active {
  color: #03a6ad !important;
}
.theme--light.v-pagination .v-pagination__navigation {
  background: transparent !important;
  box-shadow: none !important;
}


.dropdownStyle_panel {
  font-family: Ubuntu;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize !important;
}
.active_com {
  font-size: 16px;
  color: #ff6750 !important;
  font-family: Ubuntu;
  font-weight: 500;
  text-transform: capitalize;
  text-align:left;
  cursor: pointer;
}
</style>