<template>
    <div>
     
    
      
    <div v-if="!showpopup" class="margin_align" style="margin-top:10vh;">
        <iframe style="height:100vh"
            :src="(`flutter.html?email=${email}&token=${token}`)"
            width="100%"
            height="100%"
            frameborder="0"
        ></iframe>
    </div>
    <template>
      <v-dialog v-model="showpopup" width="100%"  :retain-focus="false" >
        <v-card class="notification_dialog" style="border-radius:0;" >
          <div style="100%;
          height: 8px;
          margin: 0 0 0px;
          background-color: #029ea5;">

          </div>
        <v-row style="padding:1vh;padding-bottom:2vh" >
          <v-col cols=12 xs="2" md="2" xl="2" sm="2" ></v-col>
          <v-col cols=12 xs="1" md="1" xl="1" sm="1" class="d-flex justify-center align-center" >
            <img src="../assets/Icons/Group 1358.svg" alt=user style="width:9vh;height:9vh" >
          </v-col>
          <v-col cols=12 xs="5" md="5" xl="5" sm="5" >
            <div style="margin-bottom:1vh">
              <span style="font-family: Ubuntu;
              font-size: 2.5vh;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.55;
              letter-spacing: normal;
              text-align: left;
              color: #0a5053;
             ">
                Give us the green light to share updates with you.
              </span>
            </div>
            <div>
              <span style="object-fit: contain;
              font-family: Ubuntu;
              font-size:1.6vh;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.59;
              letter-spacing: normal;
              text-align: left;
              color: #2c2c48;">
                Please allow notifications to get Alerts. After allowing notifications, please reload page. 
                Firefox users may need to click on notifications icon in the addresses bar and click on allow
              </span>
            </div>
          </v-col>
          <v-col cols=12 xs="2" md="2" xl="2" sm="2" align=center >
            <div style="margin-bottom:2vh;margin-top:0.5vh">
              <span style=" font-family: Ubuntu;
              font-size: 1.8vh;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.38;
              letter-spacing: normal;
              text-align: center;
              color: #929298;
              cursor:pointer" @click="allowNotification=false">

                Close
              </span>
            </div>
           
            <div>
              <v-btn
              
              style="padding:5px;background-color:#03a6ad;color:#fff;cursor:pointer" @click="asktoAllowNotification()"
            >
              Allow Notifications
            </v-btn>
            </div>
          </v-col>
          <v-col cols=12 xs="2" md="2" xl="2" sm="2" ></v-col>
         
        </v-row>
        
      </v-card>
      </v-dialog>
     </template>
  
     
    </div>
  </template>
  
  <script>
    import axios from 'axios'
  export default {
    data() {
      return {
        email: '',
        token: '',
        showpopup:true
      };
    },

    mounted() {
      var auth_token =localStorage.getItem('authtoken')
      if (auth_token !== null && auth_token !== "" && auth_token !== undefined && auth_token !== "undefined") {
        this.token=auth_token
        if (this.isTabletMob==false) {
          this.showpopup=false
         this.$forceUpdate();
          console.log("mobile")
        }


        this.getEmail()
        console.log(this.email,"email")
      }
      // Fetch Flutter component from your Flutter web server
    //   fetch('https://vuetifyjs.com/en/components/toolbars/')
    //     .then(response => response.text())
    //     .then(flutterComponentHTML => {
    //       // Render the Flutter component inside the div
    //       document.getElementById('flutter-component').innerHTML = flutterComponentHTML;
    //     })
    //     .catch(error => console.error(error));
    },
    computed: {
        isTabletMob : function() {
        const iPad = /iPad/i.test(navigator.userAgent);
        const iPhone = /iPhone/i.test(navigator.userAgent);
        const Android = /Android/i.test(navigator.userAgent);
        const webOS = /webOS/i.test(navigator.userAgent);
        const iPod = /iPod/i.test(navigator.userAgent);
        const BlackBerry = /BlackBerry/i.test(navigator.userAgent);
        const Windows_Phone = /Windows Phone/i.test(navigator.userAgent);

        return iPad ||  iPhone || Android || webOS || iPod || BlackBerry || Windows_Phone;
      }  
    },
    methods:{
      getEmail(){
      //create a get request to get email
      axios.get('/getEmail?token='+this.token).then((res) => {
        //console.log(res)
        if (res.data.Status != 1) {
          this.email=res.data.Data.Email

          //convert email to base64
          this.email= btoa(this.email)
          console.log(this.email,"emailllll")
          
        }else{
          console.log("not logged in")
        }
      }).catch((err) => {
        console.log(err)
      })
     },
    }
  };
  </script>
  
  
  <style>
  .margin_align {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
  }
  
</style>
  
  
  
 
  
  
  
  
  
  